const angular = window.angular;

collaborateController.$inject = [
  "$uibModal",
  "$rootScope",
  "collaborateService",
  "commonService",
  "$state",
  "friendService",
  "$location",
  "$scope",
  "Store",
  "$stateParams",
];

function collaborateController(
  $uibModal,
  $rootScope,
  collaborateService,
  commonService,
  $state,
  friendService,
  $location,
  $scope,
  Store,
  $stateParams
) {
  let vm = this;

  vm.chats = [];
  vm.contacts = [];
  vm.recentChats = [];
  vm.searchText = $location.search().searchText;
  vm.disableSearch = false;
  /**
   * Flag to indicate whether the user profile is completed or not.
   */
  vm.isProfileComplete = true;
  // set collaborate window as open in localstorage
  let collaborateWindowCount = Store.get("collaborateWindowCount");
  // commonService.fireIntercomEvent("collaborate-entered");
  Store.set(
    "collaborateWindowCount",
    parseInt(collaborateWindowCount) ? parseInt(collaborateWindowCount) + 1 : 1
  );

  vm.selection = "chats";
  vm.selectionPlaceholder = "Search for Contacts";
  vm.searchContactText = "";
  vm.disableContactSearch = true;

  vm.currentChatId = "";

  //Search Values
  vm.name = "";
  vm.location = "";
  vm.interests = "";
  vm.teachingStyle = "";
  vm.faith = "";
  vm.gender = "";
  angular.extend(this, {
    getOnlineStatus,
    notCurrentUser,
    searchUsers,
    checkTextLength,
    openInfoModal,
    changeSelection,
    contactClick,
    searchContactUsers,
    checkCurrentChat,
    searchUsersFilter,
    reset,
  });

  $rootScope.profileComplete = function (value) {
    vm.isProfileComplete = value;
  };

  function openInfoModal() {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "hspInfoModalController",
      controllerAs: "hspInfoModalCtrl",
      template: require("../../pug/components/hsp-info-modal.pug").default,
      resolve: {
        infoParam: {
          data: `<div class="info-modal">
								<h4>Panda Messenger</h4>
								<ul class="ul">
									<li>Panda Messenger allows you to interact with other homeschooling families who are using the Homeschool Panda application. It is secure, safe and allows you to communicate with your HSP friends across the globe. Panda Messenger is <u>end</u> to end encrypted.</li>
									<li>You can search for other homeschoolers, start a group chat and/or chat individually with friends.</li>
								</ul>
								<div>
									<h4>Using the Panda Messenger</h4>
								</div>
								<ul class="ul">
									<li>Search for other homeschoolers by Name, Location (City or Country), Interest, Teaching Style and Faith.</li>
									<li>Once you find a homeschooler to connect with, you can send them a friend request.</li>
									<li>You can include a note with your request. Once you’ve sent a request, the individual will appear on the right side on the Panda Messenger screen. Your chats will be displayed on the blank space once you start communicating with them.</li>
								</ul>
								<p class=\"text-align-tooltip-p\"><u>Remember, you will only be able to PM (panda message) them once they accept your request</u>. Once the request is accepted, you can pm, send attachments (files, pictures and/or videos) and even communicate through emojis!</p>
								<ul>
									<li>You can start a group chat by clicking on the plus icon next to <b>Start a Group Chat</b>. Search for the names in the search bar and add them to the chat by selecting them and clicking the blue <b>Create button</b>.</li>
								</ul>
								<p class=\"text-align-tooltip-p\">The Panda makes communication that easy! It’s a whole new social feed made by homeschoolers for homeschoolers! When you go back to the dashboard, you’ll be able to see your recent messages under the Panda Messenger box.</p>
							  </div>`,
        },
      },
    });
  }

  $scope.$on("$destroy", function () {
    let collaborateWindowCount = Store.get("collaborateWindowCount");
    Store.set(
      "collaborateWindowCount",
      parseInt(collaborateWindowCount)
        ? parseInt(collaborateWindowCount) - 1
        : 0
    );
  });

  window.onbeforeunload = function () {
    if (window.location.href.includes("collaborate")) {
      let collaborateWindowCount = Store.get("collaborateWindowCount");
      Store.set(
        "collaborateWindowCount",
        parseInt(collaborateWindowCount)
          ? parseInt(collaborateWindowCount) - 1
          : 0
      );
    }
  };

  function checkTextLength(searchText) {
    if (searchText && searchText.length >= 1) {
      vm.disableSearch = false;
      return;
    }
    vm.disableSearch = true;
    return;
  }

  function searchUsers(searchText) {
    if (
      $rootScope.user.userRole == "PARENT" &&
      (!$rootScope.user.dob ||
        !$rootScope.user.gender ||
        !$rootScope.user.zipCode)
    ) {
      vm.isProfileComplete = false;
      $state.go("collaborateCreate");
      return;
    }

    if (
      $rootScope.user.userRole == "PARENT" &&
      $rootScope.user.country == null &&
      $rootScope.user.state == null &&
      $rootScope.user.city == null &&
      $rootScope.user.zipCode == null
    ) {
      vm.isProfileComplete = false;
      $state.go("collaborateCreate");
      return;
    }

    if (vm.disableSearch == false) {
      $state.go("collaborateSearch", { searchText: searchText });
    }
  }

  function searchUsersFilter() {
    if (
      $rootScope.user.userRole == "PARENT" &&
      (!$rootScope.user.dob ||
        !$rootScope.user.gender ||
        !$rootScope.user.zipCode)
    ) {
      vm.isProfileComplete = false;
      $state.go("collaborateCreate");
      return;
    }

    if (
      $rootScope.user.userRole == "PARENT" &&
      $rootScope.user.country == null &&
      $rootScope.user.state == null &&
      $rootScope.user.city == null &&
      $rootScope.user.zipCode == null
    ) {
      vm.isProfileComplete = false;
      $state.go("collaborateCreate");
      return;
    }

    $state.go("collaborateSearch", {
      searchText: vm.name,
      location: vm.location,
      interests: vm.interests,
      faith: vm.faith,
      teachingStyle: vm.teachingStyle,
      gender: vm.gender,
    });
  }

  function reset() {
    vm.location = "";
    vm.interests = "";
    vm.faith = "";
    vm.teachingStyle = "";
    vm.gender = "";
    vm.name = "";
  }
  function searchContactUsers(searchText) {
    if (searchText) {
      let contactList = [];
      if (vm.disableContactSearch) {
        contactList = Object.assign([], vm.chats);
      } else {
        contactList = Object.assign([], vm.contacts);
      }
      let searchContact = _.filter(contactList, (contact) => {
        if (contact.noRecord) {
          let name = contact.friend.user.name.toLowerCase();
          return name.includes(searchText.toLowerCase());
        } else {
          let checkUser = false;
          if (contact.type !== "group") {
            contact.users.map((user, n) => {
              if (user.id !== $rootScope.user.id) {
                let name = user.name.toLowerCase();
                if (name.includes(searchText.toLowerCase())) {
                  checkUser = true;
                }
              }
            });
          } else {
            let name = contact.name.toLowerCase();
            return name.includes(searchText.toLowerCase());
          }
          return checkUser;
        }
      });

      vm.contacts = contactList;
      vm.chats = searchContact;
      vm.disableContactSearch = false;
    } else {
      vm.disableContactSearch = true;
      vm.chats = Object.assign([], vm.contacts);
    }
  }

  function notCurrentUser(user) {
    if (!user || !$rootScope.user) {
      return false;
    }
    if ($rootScope.user.userRole === "HELPER") {
      return user.id !== $rootScope.user.helperId;
    }
    return user.id !== $rootScope.user.id;
  }

  function getOnlineStatus(userId) {
    if (~collaborateService.onlineUsers.indexOf(userId)) {
      return $rootScope.getFriendDetails(userId).status;
    }
    if (userId === $rootScope.user.id) {
      return $rootScope.user.status || "online";
    }
    return "offline";
  }

  function initialize() {
    // commonService.fireIntercomEvent("collaborate-entered");

    checkTextLength(vm.searchText);
    if (
      $rootScope.user.userRole == "PARENT" &&
      (!$rootScope.user.dob ||
        !$rootScope.user.gender ||
        !$rootScope.user.zipCode)
    ) {
      vm.isProfileComplete = false;
      $state.go("collaborateCreate");
      return;
    }

    $rootScope.chatIndexPromise.then((successData) => {
      vm.chats = collaborateService.chatsList;
      commonService.chats = vm.chats;
      if (vm.chats && vm.chats.length) {
        collaborateService.sideMenuChatId = vm.chats[0].id;
        // vm.currentChatId=vm.chats[0].id;
      }
    });

    // HelpCrunch('updateUser', $rootScope.helpCrunchuser, 'e8aa8af95cd6a05b80308dcb3dd6901d', function() {});
  }

  function changeSelection(value) {
    vm.selection = value;
    if (value == "contacts") {
      vm.selectionPlaceholder = "Search for Contacts";
    }
    if (value == "chats") {
      vm.selectionPlaceholder = "Search for Chats";
    }
  }

  function checkCurrentChat(id) {
    if (id == collaborateService.sideMenuChatId) return true;
    return false;
  }
  function contactClick(chat) {
    // if((!chat.chatStatus || chat.noRecord) && chat.type!=="group" ) {
    // 	collaborateService.updateContactChatAgain({friend:chat.friend}).then(res=>{
    // 		setTimeout(()=>{
    // 			$state.go('collaborateViewChat',{chatId:res.id});
    // 			collaborateService.sideMenuChatId=res.id;
    // 		},10);
    // 	});
    // }
    // else{
    // 	// vm.currentChatId=chat.id;
    // 	collaborateService.sideMenuChatId=chat.id;
    // 	$state.go('collaborateViewChat',{
    // 			chatId:chat.id
    // 		}
    // 	);
    // }
    collaborateService.sideMenuChatId = chat.id;
    $state.go("collaborateViewChat", {
      chatId: chat.id,
    });
  }

  $scope.$on("deleteEvent", function (event, data) {
    if (collaborateService.chatsList.length) {
      // vm.currentChatId=collaborateService.chatsList[0].id;
      collaborateService.sideMenuChatId = collaborateService.chatsList[0].id;
    }
  });
  this.$onInit = initialize;
}

export default collaborateController;
