


toastService.$inject = [];

function toastService() {

    let toast = angular.element('#toast'),
        nointernetContainer = angular.element('#nointernet-container'),
        nointernetToast = angular.element('#nointernet-toast');

    /**
     * Create a toast
     * @param {data} The configuration object
     * @param {data.message} type string : The message to show in toast
     * @param {data.type} type string : either 'success' or 'error'
     * @param {data.delay} type integer : Time duration to show toast in miliseconds
     */
    this.showNoInternetToast = function () {
        nointernetContainer.addClass('showing');
        nointernetToast.addClass('showing');
    };

    this.hideNoInternetToast = function () {

        nointernetToast.removeClass('showing')
            .delay(1000)
            .queue(function (next) {
                nointernetContainer.removeClass('showing');
                next();
            });
    };

    this.toast = function (config) {


        let toast = angular.element('#toast');

        if (config !== null && typeof config === 'object') {
            let message, type, delay;
            if (config.message) {
                message = config.message;
            } else {
                console.error('No title is provided for toast, Empty toasts will not be created.');
                return false;
            }

            if (config.type && config.type === 'success' || config.type === 'error' || config.type === 'budgeterror') {
                type = config.type;
                if (type === 'error') type = "error budget-error";
            } else {
                console.error('Invalid type provided for toast, The type can either be "success" or "error"');
                return false;
            }

            delay = config.delay || 5000;

            toast.html(message)
                .removeClass()
                .addClass(type)
                .addClass('showing')
                .delay(delay)
                .queue(function (next) {
                    toast.removeClass('showing');
                    next();
                });
        } else {
            console.error("Please provide a valid configuration object while creating a toast");
        }
    };
}

export default toastService;

