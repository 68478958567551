function stringToNumber() {
  return {
    require: "ngModel",
    link: function (scope, element, attrs, ngModel) {
      ngModel.$formatters.push(function (value) {
        return parseFloat(value);
      });
    },
  };
}

export default stringToNumber;
