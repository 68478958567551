let angular = window.angular;
	changePasswordController.$inject = ['$rootScope', '$uibModalInstance', 'userService', 'toastService','userConstants'];
	function changePasswordController($rootScope, $uibModalInstance, userService, toastService,userConstants) {
		let vm = this;

		vm.oldPassword = null;
		vm.newPassword = null;
		vm.confirmPassword = null;

		vm.close = function () {
			$uibModalInstance.close();
		};

		vm.changePassword = function () {

			if (!vm.form.$valid) {
				return false;
			}
			if (vm.newPassword.length < 6 || vm.oldPassword.length < 6) {
				toastService.toast({
					message: userConstants.messages.passwordLengthValidation,
					type: 'error',
					delay: 5000
				});
				return false;
			}
			if (vm.newPassword !== vm.confirmPassword) {
				toastService.toast({
					message: userConstants.messages.confirmPasswordValidation,
					type: 'error',
					delay: 5000
				});
				return false;
			}

			let data = {};
			data.oldPassword = vm.oldPassword;
			data.newPassword = vm.newPassword;

			userService.changePassword(data)
				.then((response) => {
					toastService.toast({
						message: userConstants.messages.changePasswordSuccess,
						type: 'success',
						delay: 5000
					});
					vm.close();
				})
				.catch((response) => {
					if (response.message === 'Invalid existing password.') {
						toastService.toast({
							message: userConstants.messages.changePasswordFailure,
							type: 'error',
							delay: 8000
						});
					}
					// console.log(response);
				});
		}

	}
export default changePasswordController;
