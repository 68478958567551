/**
 *
 *
 *  Options Includes,   {
 *                          canGoBack:Boolean,
 *                          entity: Object (i.e. User or Student),
 *                          modelName: String (i.e. 'user' or 'student')
 *                      }
 *
 *
 **/

let angular = window.angular;

ImgUploadWithCropModalController.$inject = [
  "$uibModalInstance",
  "media",
  "options",
  "fileService",
  "$rootScope",
  "$scope",
  "Upload",
];

function ImgUploadWithCropModalController(
  $uibModalInstance,
  media,
  options,
  fileService,
  $rootScope,
  $scope,
  Upload
) {
  /* jshint validthis:true */
  let vm = this;

  activate();

  function activate() {
    vm.croppedImage = "";
    let reader = new FileReader();
    vm.fileName = media.name;
    vm.cropShape = getCropShape(options.modelName);
    reader.onloadend = function () {
      $scope.$apply(function () {
        vm.media = reader.result;
        vm.onCompleteLoading = true;
      });
    };

    reader.readAsDataURL(media);

    if (angular.isDefined(options.canGoBack) && options.canGoBack) {
      vm.canGoBack = true;
    } else {
      vm.canGoBack = false;
    }
  }

  angular.extend(vm, {
    cancel,
    upload,
    crop,
    goBack,
  });

  function cancel() {
    $uibModalInstance.dismiss("cancel");
  }

  function getCropShape(model) {
    switch (model) {
      case "book":
        vm.title = "Uplaod Book Cover";
        return "square";
      default:
        vm.title = "Change Profile Pic";
        return "circle";
    }
  }

  function upload() {
    $rootScope.loadingBtn("uploadPic", "Uploading");
    let file = Upload.dataUrltoBlob(vm.croppedImage, vm.fileName);
    fileService
      .uploadFile(file, options.entity.id, options.modelName)
      .then(uploadFileSuccess)
      .catch(uploadFileError);
  }

  function uploadFileSuccess(response) {
    if (response.data) {
      options.entity.mediaUrl = response.data.data[0].mediaUrl;
    }
    $rootScope.loadingBtnComplete("uploadPic", "Upload");
    let returnObject = {};

    returnObject[options.modelName] = options.entity;
    $uibModalInstance.close(returnObject);
  }

  function uploadFileError(error) {
    $uibModalInstance.dismiss(error);
  }

  function crop() {
    if (vm.canGoBack) {
      vm.profilePicCropped = true;
    } else {
      let file = Upload.dataUrltoBlob(vm.croppedImage, vm.fileName);
      $uibModalInstance.close({
        file: file,
      });
    }
  }

  function goBack() {
    vm.profilePicCropped = false;
  }
}
export default ImgUploadWithCropModalController;
