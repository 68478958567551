export function hspAddMultipleFiles() {
  let directive = {
    restrict: "E",
    template: require("../../../pug/components/hsp-file-add.pug").default,
    scope: {
      files: "=",
      limit: "=",
      addFilesCallback: "&",
      allowMultiple: "=",
      isDisabled: "=",
    },
    controller: "HspAddFilesController",
    controllerAs: "hspAddFilesCtrl",
    transclude: {
      uploadTemplate: "?uploadTemplate",
      dropTemplate: "?dropTemplate",
    },
  };

  return directive;
}

HspAddFilesController.$inject = [
  "$scope",
  "_",
  "appConstants",
  "$timeout",
  "toastService",
];

export function HspAddFilesController(
  $scope,
  _,
  appConstants,
  $timeout,
  toastService
) {
  let vm = this;
  vm.maxUploadSize = appConstants.maxUploadSize;

  angular.extend(vm, {
    filesSelected: filesSelected,
    dragging: dragging,
  });

  function filesSelected(files, invalidFiles) {
    if (invalidFiles.length) {
      let message;
      switch (invalidFiles[0].$error) {
        case "maxSize":
          message = appConstants.messages.maxFileSizeError + vm.maxUploadSize;
          break;
        case "minWidth":
        case "minHeight":
          message = appConstants.messages.errorFileDimension;
          break;
        case "maxFiles":
          message = "Maximum of " + $scope.limit + " files can be uploaded.";
          break;
        default:
          message = appConstants.messages.selectValidFile;
      }
      toastService.toast({
        message: message,
        type: "error",
        delay: 8000,
      });
    }

    if (!_.isEmpty(files)) {
      $scope.addFilesCallback({ files: files });
    }
  }

  function dragging(isDragging, elemClass, event) {
    event.preventDefault();
    event.stopPropagation();
    $timeout(function () {
      $scope.addDragging = Boolean(isDragging || elemClass);
      // console.log('addDraging', $scope.addDragging);
    }, 10);
  }
}
