placesAutocomplete.$inject = ["_", "$timeout"];

function placesAutocomplete(_, $timeout) {
  return {
    require: "ngModel",
    restrict: "A",
    scope: {
      lat: "=?",
      long: "=?",
      city: "=?",
      state: "=?",
      country: "=?",
      zip: "=?",
      locationSelect: "&",
    },
    link: function (scope, element, attrs, model) {
      let options = {
        types: [],
      };
      let selectedPlace = {},
        address = {};

      scope.autocomplete = new google.maps.places.Autocomplete(
        element[0],
        options
      );

      element.on("keydown", function (event) {
        if (event.keyCode == 13) {
          event.stopPropagation();
          event.preventDefault();
        }
      });

      google.maps.event.addListener(
        scope.autocomplete,
        "place_changed",
        function () {
          selectedPlace = scope.autocomplete.getPlace();
          address = {};
          // Create an address object from google autocomplete response
          _.each(selectedPlace.address_components, function (item) {
            address[item.types[0]] = item.long_name;
          });

          $timeout(function () {
            if (scope.hasOwnProperty("lat")) {
              scope.lat = selectedPlace.geometry.location.lat();
            }

            if (scope.hasOwnProperty("long")) {
              scope.long = selectedPlace.geometry.location.lng();
            }

            if (
              scope.hasOwnProperty("city") &&
              (address.locality || address.postal_town)
            ) {
              scope.city = address.locality || address.postal_town;
            }
            if (
              scope.hasOwnProperty("state") &&
              (address.administrative_area_level_1 ||
                address.administrative_area_level_2)
            ) {
              scope.state =
                address.administrative_area_level_1 ||
                address.administrative_area_level_2;
            }

            if (scope.hasOwnProperty("country") && address.country) {
              scope.country = address.country;
            }

            if (scope.hasOwnProperty("zip") && address.postal_code) {
              scope.zip = address.postal_code;
            }

            scope.locationSelect({
              country: scope.country,
              state: scope.state,
              city: scope.city,
              zip: scope.zip,
            });

            model.$setViewValue(element.val());
          }, 0);
        }
      );
    },
  };
}

export default placesAutocomplete;
