export const appConstants = {
  baseUrl: process.env.API_URL,
  calendarBaseUrl:  process.env.API_URL,
  graphQLUrl: process.env.GRAPHQL_URI,
  gradeBookTutorial: 'https://intercom.help/homeschool-panda/en/articles/6967994-how-do-i-use-the-grade-book',
  sidebarOpenByDefault: true,
  defaultNotificationTab: "reminders",
  emailPattern:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  defaultAvatar: "/images/default-avatar.png",
  defaultBookAvatar: "/images/book.svg",
  maxUploadSize: "10MB",
  maxMultipleFilesAllowed: 10,
  DAY: 86340000, // 23 hrs and 59 minutes
  shareUrl: "https://www.homeschoolpanda.com",
  fbAppId: "1677493929218601",
  sentryId: "https://1a4805a3092848a5a4297fdaffd48c43@sentry.io/273321",
  socialSharingMessage:
    "Hi, Homeschool Panda offers a Lesson Planner, Social Media for Homeschoolers, Instant Messaging Tool, Budget and more. You should give Homeschool Panda a try.",
  socialSharingHashTag: "Homeschooling",
  widgets: [
    {
      template: "agenda-card",
      displayName: "Agenda",
    },
    {
      template: "budget-card",
      displayName: "Budget",
    },
    {
      template: "collaborate-card",
      displayName: "Collaborate",
    },
    {
      template: "todo-card",
      displayName: "Todo",
    },
    {
      template: "overdue-card",
      displayName: "Overdue",
    },
    {
      template: "gradebook-card",
      displayName: "Gradebook",
    },
  ],
  colors: [
    {
      name: "Cornell Red",
      code: "#B71C1C",
    },
    {
      name: "Ruby",
      code: "#E91E63",
    },
    {
      name: "Dark Orchid",
      code: "#9C27B0",
    },
    {
      name: "Vivid Orange",
      code: "#FFA000",
    },
    {
      name: "Dark Cyan",
      code: "#009688",
    },
    {
      name: "Navy Blue",
      code: "#01579B",
    },
  ],
  newColors: [
    [
      {
        name: "Cornell Red",
        code: "#B71C1B",
      },
      {
        name: "Ruby",
        code: "#E91F63",
      },
      {
        name: "Dark Orchid",
        code: "#9C27B0",
      },
      {
        name: "Royal Purple",
        code: "#4A148C",
      },
      {
        name: "MSU Green",
        code: "#273F35",
      },
      {
        name: "Vivid Orange",
        code: "#FF9F01",
      },
    ],

    [
      {
        name: "Dark Cyan",
        code: "#009688",
      },
      {
        name: "Minion Yellow",
        code: "#C4B541",
      },
      {
        name: "Xanadu",
        code: "#7E9181",
      },
      {
        name: "Malachite",
        code: "#43BB6B",
      },
      {
        name: "Cyber Grape",
        code: "#504884",
      },
      {
        name: "Cyan Process",
        code: "#01BFF4",
      },
    ],
    [
      {
        name: "Tiffany Blue",
        code: "#2EC4B6",
      },
      {
        name: "CG Blue",
        code: "#0081A7",
      },
      {
        name: "Mauve",
        code: "#DEAAFF",
      },
      {
        name: "Brown",
        code: "#955867",
      },
      {
        name: "Bittersweet",
        code: "#FF6B6B",
      },
      {
        name: "Amethyst",
        code: "#9B5DE5",
      },
    ],
    [
      {
        name: "Eggplant",
        code: "#684756",
      },
      {
        name: "Coyote Brown",
        code: "#8B5E34",
      },
      {
        name: "Indigo Dye",
        code: "#01497C",
      },
      {
        name: "Darkseek Green",
        code: "#7EBC89",
      },
      {
        name: "Orange Aerospace",
        code: "#FE5D26",
      },
      {
        name: "Fern Green",
        code: "#588157",
      },
    ],
    [
      {
        name: "Burnished Brown",
        code: "#AB8476",
      },
      {
        name: "Navy Blue",
        code: "#00579B",
      },

      {
        name: "Cornflower Blue",
        code: "#7B8CDE",
      },
      {
        name: "Purple",
        code: "#7209B7",
      },
      {
        name: "Flickr Pink",
        code: "#F72585",
      },
      {
        name: "Rosewood",
        code: "#540B0E",
      },
    ],
  ],
  defaultColor: {
    studentAssignment: "#01579B",
    appointment: "#65696f",
  },
  messages: {
    planUpgrade: "Kindly upgrade your plan",
    enterValidFile: "Please enter a valid file.",
    selectValidFile: "Please select a valid file.",
    errorFileSize: "Maximum file size allowed is 25MB",
    errorFileDimension: "File should be atleast 100px",
    maxFileSizeError: "Maximum file size allowed is ",
    checkoutApp:
      "Hi, Homeschool Panda offers a Lesson Planner, Social Media for Homeschoolers, Instant Messaging Tool, Budget and more. You should give Homeschool Panda a try.",
    leavePageConfirmation:
      "The changes you made will be lost if you leave this page.\nContinue with unsaved changes?",
    referralMessage:
      "Use my referral link to sign up or if you are already a Homeschool Panda user, add my referral link to your account. Link: ",
    deleteAcademicDetailsWarning:
      "Are you sure you want to delete this academic details?",
  },
};
