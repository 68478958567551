let angular = window.angular;

helperEditController.$inject = ['$scope', '$rootScope', 'studentService', '$state', '$stateParams', '_', 'commonService', 'fileService', '$q', 'moment', 'toastService', 'appConstants', 'studentConstants'];

function helperEditController($scope, $rootScope, studentService, $state, $stateParams, _, commonService, fileService, $q, moment, toastService, appConstants, studentConstants) {

    let vm = this;
    let studentId = $stateParams.studentId;
    let sortedAcademicYearArray = [];
    vm.student = {};
    vm.colors = appConstants.colors;
    vm.submitClicked = false;
    vm.gradeCheck = {};
    vm.addAcademicDetails = function () {
        let tempAcademics = [...vm.student.academics];

        tempAcademics.push({
            'academicYearStart': '',
            'academicYearEnd': '',
            'grade': '',
            'academicDays': ''
        });
        vm.student.academics = [...tempAcademics];

    }

    vm.checkAcademicYearInteraction = function ($index) {
        sortedAcademicYearArray = commonService.sortDateByStartDate(_.clone(vm.student.academics));
        // console.log("sorted----",sortedAcademicYearArray);
        let isIntersected = false,
            exitFunc = false;
        for (let i = 1; i < sortedAcademicYearArray.length; i++) {
            let currentStartDate = sortedAcademicYearArray[i].academicYearStart;
            let previousEndDate = sortedAcademicYearArray[i - 1].academicYearEnd;
            // if(!currentStartDate || !previousEndDate){
            //     return;
            // }
            if (currentStartDate < previousEndDate) {
                isIntersected = true;
                break;
            }
        }

        if (isIntersected) {
            vm.student.academics[$index].invalid = true;
            toastService.toast({
                message: studentConstants.messages.academicYearIntersection,
                type: 'error',
                delay: 2000
            });
        } else {
            vm.student.academics[$index].invalid = false;
        }


        return;
    }


    function getStudentDetails() {
        studentService.getHelperDetail(studentId)
            .then((response) => {
                // vm.student = response;
                vm.student = response.helperUserId;

                let permission = vm.student.rolePermission;
                let permissions = {};
                for (const key of Object.keys(permission)) {
                    switch (key) {
                        case key:
                            if (permission[key] === 'EDIT') {
                                let editData = {
                                    [key]: {
                                        view: true,
                                        edit: true
                                    }
                                }
                                permissions = Object.assign({}, permissions, editData);
                            }
                            if (permission[key] === 'VIEW') {
                                let viewData = {
                                    [key]: {
                                        view: true,
                                    }
                                }
                                permissions = Object.assign({}, permissions, viewData)
                            }
                            break;

                        default:
                    }

                }
                vm.student.permission = permissions;
                vm.student.relation = response.relation;
                vm.student.color = response.color;
                vm.student.nickName = response.nickName;
                // vm.student.academics = [...response.academicDetails];
                vm.studentImage = vm.student.mediaUrl || appConstants.defaultAvatar;
                //Check if country-code and number both are set.
                if (!vm.student.countryCode || !vm.student.contactNumber) {
                    vm.student.countryCode = 0;
                    vm.student.contactNumber = null;
                }

                if (!vm.student.parentCountryCode || !vm.student.parentContactNumber) {
                    vm.student.parentCountryCode = 0;
                    vm.student.parentContactNumber = null;
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }

    vm.selectStudentPic = function (media) {
        return fileService.imageUploadWithCrop(media)
            .then(selectStudentPicSuccess)
            .catch(selectStudentPicError);

        function selectStudentPicSuccess(response) {
            if (response.file) {
                vm.studentImageFile = response.file;
            }
        }

        function selectStudentPicError(error) {
            console.error('Closed modal', error);
        }
    };

    vm.editUser = function () {
        let exitFunc = false,
            multiGrade = false,
            dobCheck = false;
        _.forEach(vm.student, function (value, key) {
            if (key === 'firstName' || key === 'lastName' || key === 'dob') {
                if ((value === '' || value === null || !value)) {
                    vm.show = true;
                    exitFunc = true;
                }
            }
        });

        if (!vm.student.firstName || !vm.student.lastName || !vm.student.email || !vm.student.relation) {
            vm.show = true;
            exitFunc = true;
            return;
        }

        // Check if dob is null ..
        if (!vm.student.dob || vm.student.dob === null) {
            delete vm.student.dob;
        }

        // for (let i = 0; i < vm.student.academics.length; i++) {
        //     if (!vm.student.academics[i].academicYearStart || !vm.student.academics[i].academicYearEnd || !vm.student.academics[i].grade) {
        //         exitFunc = true;
        //     }
        //     if (vm.student.dob && moment(vm.student.academics[i].academicYearStart).isBefore(moment(vm.student.dob))) {
        //         dobCheck = true;
        //         break;
        //     }
        // }

        if (dobCheck) {
            toastService.toast({
                message: studentConstants.messages.dobWarning,
                type: 'error',
                delay: 2000
            });
            exitFunc = true;
        }

        if (!vm.student.dob === "" && (exitFunc || !vm.form.$valid || vm.form.dob.$invalid)) {
            return;
        }

        $rootScope.loadingBtn('save', 'Loading');
        vm.submitClicked = true;

        let data = {};
        data = _.clone(vm.student);

        //Check if country-code and number both are set.
        if (!data.countryCode || !data.contactNumber) {
            data.countryCode = 0;
            data.contactNumber = null;
        }

        if (!data.parentCountryCode || !data.parentContactNumber) {
            data.parentCountryCode = 0;
            data.parentContactNumber = null;
        }

        data.rolePermission = vm.student.permission;
        studentService.editUser(data)
            .then(editStudentSuccess)
            .then(fileUploadResponse)
            .catch(function (error) {
                console.error(error);
                toastService.toast({
                    message: error.data.message || 'Please fill the form and try again',
                    type: 'error',
                    delay: 2000
                });
                $rootScope.loadingBtnComplete('save');
            })
            .finally(() => {
                $rootScope.loadingBtnComplete('save', 'Save');
            });
    };

    vm.setStudentLabelColor = function (color) {
        vm.student.color = color;
    };

    function editStudentSuccess(response) {
        let deferred = $q.defer();
        if (response && response.data.data.Student.length > 0)
            update(response.data.data.Student[0].id, response.data.data.Student[0]);
        // Upload Image, if selected by User
        if (_.isObject(vm.studentImageFile) && _.isString(vm.studentImageFile.name) && (vm.studentImageFile.size > 0)) {
            $rootScope.loadingBtn('save', 'Uploading Profile Picture');
            return fileService.uploadFile(vm.studentImageFile, vm.student.id, 'user');
        } else {
            deferred.resolve();
        }

        return deferred.promise;
    }

    vm.removeAcademicDetails = function ($index) {
        let index = commonService.convertToInteger($index);
        if (index < 0) {
            return false;
        }
        if (vm.student.academics.length <= 1) {
            toastService.toast({
                message: studentConstants.messages.academicYearRequire,
                type: 'error',
                delay: 2000
            });
            return;
        }

        let confirmData = {
            message: studentConstants.messages.deleteAcademicDetailsWarning,
            modalSize: 'md',
            type: 'danger'
        };

        let academicId = vm.student.academics[index].id || 0;
        commonService.confirm(confirmData)
            .then(function (confirmation) {
                if (confirmation.result) {
                    vm.student.academics.splice(index, 1);
                }
            });

    }

    function fileUploadResponse(response) {
        if (response) {
            updatemediaUrl(response);
        }
        $state.go('userInfo', { studentId: vm.student.id });
    }

    function update(studentId, newVal) {
        studentService.saveStudent(studentId, newVal);
    }

    function updatemediaUrl(response) {
        let index = commonService.findInArray(vm.student.id, 'id', studentService.students);
        if (~index) {
            studentService.students[index].mediaUrl = response.data.data[0].mediaUrl;
        }
    }

    this.$onInit = getStudentDetails;

    vm.fillingForm = function () {
        vm.isFillingForm = true;
    };

    vm.checkMultigrade = function ($index) {
        let multiGrade = false;
        vm.gradeCheck = {};
        for (let i = 0; i < vm.student.academics.length; i++) {
            let grd = vm.student.academics[i].grade;

            if (grd && grd in vm.gradeCheck) {
                multiGrade = true;
                break;
            } else {
                if (grd !== 'Grade' || grd || grd !== null) {
                    vm.gradeCheck[grd] = i;
                }
            }
        }
        if (multiGrade) {
            vm.student.academics[$index].invalidGrade = true;
            toastService.toast({
                message: studentConstants.messages.multiGradeError,
                type: 'error',
                delay: 2000
            });
        } else {
            vm.student.academics[$index].invalidGrade = false;
        }
        return;
    }

    // vm.checkAcademicYearInteraction = function ($index){
    //     let index = commonService.convertToInteger($index);
    //     sortedAcademicYearArray = commonService.sortDateByStartDate(vm.student.academics);
    //     console.log("sorted----",sortedAcademicYearArray);
    //     console.log("index----",index);
    //     let isIntersected = false, exitFunc = false;
    //     for(let i = 1; i < sortedAcademicYearArray.length; i++){
    //         let currentStartDate = sortedAcademicYearArray[i].academicYearStart;
    //         let previousEndDate = sortedAcademicYearArray[i-1].academicYearEnd;
    //         if(!currentStartDate || !previousEndDate){
    //             return;
    //         }
    //         if(currentStartDate < previousEndDate) {
    //             isIntersected = true;
    //             break;
    //         }
    //     }

    //     if(isIntersected){
    //         vm.student.academics[index].invalid = true;
    //         toastService.toast({
    //             message: studentConstants.messages.academicYearIntersection,
    //             type: 'error',
    //             delay: 2000
    //         });
    //     }
    //     else{
    //         vm.student.academics[index].invalid = false;
    //     }
    //     return;
    // }
    vm.permissionChange = function (type, action) {
        if (vm.student.permission[type].edit && !vm.student.permission[type].view && action === 'edit')
            vm.student.permission[type].view = true;
        else if (vm.student.permission[type].edit && !vm.student.permission[type].view && action === 'view')
            vm.student.permission[type].edit = false;
    }

    /**
     * If a state change is detected, when the task controller is active, then it is checked whether the modal window is open, if yes then it is closed
     */
    $scope.$on("$stateChangeStart", function (evt, toState, toParams, fromState, fromParams) {
        if (vm.isFillingForm && !vm.submitClicked) {

            evt.preventDefault();
            $rootScope.isLoading = false;
            let confirmData = {
                message: studentConstants.messages.leavePageConfirmation,
                modalSize: 'md',
                type: 'danger'
            };

            commonService.confirm(confirmData)
                .then(function (confirmation) {
                    if (confirmation.result) {
                        vm.submitClicked = true;
                        $state.go(toState, toParams);
                    }
                });
        }
    });
}

export default helperEditController
