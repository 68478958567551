let angular = window.angular;
ConfirmModalController.$inject = ["$uibModalInstance", "data"];

function ConfirmModalController($uibModalInstance, data) {
  let vm = this;
  vm.isChecked = false;

  angular.extend(vm, {
    close,
    confirm,
  });

  let defaults = {
    message: "Are you sure?",
    closeLabel: "No",
    confirmLabel: "Yes",
    modalSize: "sm",
    type: "primary",
    prompt: false,
    promptMessage: "Confirm",
  };

  angular.extend(vm, defaults, data);
  vm.buttonType = "btn-" + vm.type.toLowerCase();

  function close() {
    $uibModalInstance.close({ result: false, isChecked: vm.isChecked });
  }

  function confirm() {
    $uibModalInstance.close({ result: true, isChecked: vm.isChecked });
  }
}
export default ConfirmModalController;
