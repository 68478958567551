let angular = window.angular;
	pendingCancellationsController.$inject = ['userService', 'toastService','Socialshare','appConstants','$uibModal','$rootScope'];

	function pendingCancellationsController(userService, toastService, Socialshare, appConstants,$uibModal,$rootScope) {
        let vm = this;
        vm.isFetchingCancellationData = false;
        vm.cancelList=[];
        vm.page = 1;

		angular.extend(vm, {
            confirm,
            unconfirm,
            nextPage,
            previousPage
        });

        function confirm(user){
            return userService.confirmUserCancellation(user, true, "cancel")
            .then(response => {
                if (response) {
                    let updateUserIndex = _.findIndex(vm.cancelList, function (el) {
                        return el.userId.id === response.data.userId;
                    });
                    if (updateUserIndex > -1) {
                        vm.cancelList[updateUserIndex].cancel_status = response.data.cancel_status;
                    }
                    toastService.toast({
                        message: response.message,
                        type: 'success',
                        delay: 2000
                    });
                }
            })
            .catch(err => {
                toastService.toast({
                    message: response.message,
                    type: 'error',
                    delay: 2000
                });
            })
        }

        function unconfirm(user){
            return userService.confirmUserCancellation(user, false, "cancel")
            .then(response => {
                if (response) {
                    let updateUserIndex = _.findIndex(vm.cancelList, function (el) {
                        return el.userId.id === response.data.userId;
                    });
                    if (updateUserIndex > -1) {
                        vm.cancelList[updateUserIndex].cancel_status = response.data.cancel_status;
                    }
                    toastService.toast({
                        message: response.message,
                        type: 'success',
                        delay: 2000
                    });
                }
            })
            .catch(err => {
                toastService.toast({
                    message: response.message,
                    type: 'error',
                    delay: 2000
                });
            })
        }

        function getSubscriptionCancellations() {
            vm.isFetchingCancellationData = true;
            userService.getPendingCancellations(vm.page - 1)
                .then(response => {
                    if (response && response.cancellations) {
                        vm.cancelList = response.cancellations;
                        vm.loadMore = response.isLoadMore;
                    }
                    else
                        throw err;
                })
                .catch(() => {
                    toastService.toast({message: 'Subscription Cancellations Not Found', type: 'error', delay: 2000});
                })
                .finally(() => {
                    vm.isFetchingCancellationData = false;
                })
        }

		function init() {
			return getSubscriptionCancellations();
        }

        function previousPage() {
            if (vm.page > 1) {
                --vm.page;
                getSubscriptionCancellations();
            }
        }

        function nextPage() {
            if (vm.loadMore) {
                ++vm.page;
                getSubscriptionCancellations();
            }
        }

		this.$onInit = init;
	}
export default pendingCancellationsController;
