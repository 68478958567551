let angular = window.angular;
	userReportPostsController.$inject = ['$rootScope','userService', '$uibModal', 'commonService', 'toastService'];

	function userReportPostsController($rootScope,userService, $uibModal, commonService, toastService) {
		let vm = this;
		vm.isFetchingPostsData = true;

		angular.extend(this, {
			// lessonLoadMore,
			// lessonView,
			showFeed,
			reportStatusPost
		});

		function getReportedPosts(){
			vm.loadingPosts = true;
			vm.isFetchingPostsData = true;
			userService.getAllReportedPosts($rootScope.user.id)
				.then((response)=>{
					vm.userReportedPosts = response.data;
					vm.loadingPosts = false;
					vm.showResults = true;
				})
				.catch(()=>{
					toastService.toast({message: 'Reported Posts Not Found', type: 'error', delay: 2000});
				})
				.finally(() => {
					vm.isFetchingPostsData = false;
				})
		}

		// function lessonLoadMore(){
		// 	let pageNumber = vm.lessonsDetails.nextPage || 1;
		// 	let url = null;
		// 	let request = 'disapprove';
		// 	let	filterData = {
		// 		grade: null,
		// 		weeks: 0,
		// 		days: 0
		// 	}
		// 	lessonService.getPublicLessons(url, filterData, pageNumber, request)
		// 		.then((response)=>{
		// 			if(response.lessons.length >= 1){
		// 				vm.lessonsDetails.lessons = vm.lessonsDetails.lessons.concat(response.lessons);
		// 			}
		//
		// 			vm.lessonsDetails.page = response.page;
		// 			vm.lessonsDetails.nextPage = response.nextPage;
		// 			vm.lessonsDetails.loadMore = response.loadMore;
		// 			vm.loadingLessons = false;
		// 			vm.showResults = true;
		//
		// 		})
		// 		.catch(()=>{
		// 			toastService.toast({message: 'Lesson Not Found', type: 'error', delay: 2000});
		// 		});
		// }

		function showFeed(feed) {
			let modelInstance = $uibModal.open({
				animation: true,
				size: "lg",
				controller: "feedViewController",
				controllerAs: "feedViewCtrl",
				template : require('../../pug/feed/view-feed.pug').default,
				resolve: {
					viewFeedParam: {
						feed: feed,
						admin:true,
					},
				},
			});

			modelInstance.result.catch(function () {
				modelInstance.close();
			});
		}
		function reportStatusPost(feedId,reportId,status,index){
			let data={
				report:{
					reportId,
					feedId,
					status
				}
			};
			if(!feedId || !reportId ){
				toastService.toast({message: 'Params Missing', type: 'error', delay: 2000});
				return;
			}
			// _.find(vm.userReportedPosts.reportedPosts)

			userService
				.updatePostStatus(data)
				.then(res=>{
					let msg="User Post Blocked";
					if(status=="UNBLOCKED"){
						msg="User Post Unblocked";
					}
					vm.userReportedPosts.reportedPosts[index].status=status;
					toastService.toast({message: msg, type: 'success', delay: 2000});

				});
		}

		//
		// function lessonStatusChange(lessonId, ispublic, status, userId, index) {
		// 	let request = 'admin';
		// 	lessonId = commonService.convertToInteger(lessonId);
		// 	if (!lessonId) {
		// 		return;
		// 	}
		// 	lessonService.lessonStatusChange(lessonId, ispublic, status, userId, request)
		// 		.then(response => {
		// 			vm.lessonsDetails.lessons[index].status = response.status;
		// 		});
		// }

		function init() {
			return getReportedPosts();
		}

		this.$onInit = init;
	}
export default userReportPostsController;
