afterRender.$inject = ["$timeout"];

function afterRender($timeout) {
  let directive = {
    restrict: "A",
    terminal: true,
    transclude: false,
    link: function (scope, element, attrs) {
      $timeout(scope.$eval(attrs.afterRender), 0); //Calling a scoped method
    },
  };
  return directive;
}

export default afterRender;
