let angular = window.angular;

    bookAssignController.$inject = ['bookConstants', 'bookData', '$scope', '$uibModalInstance', 'bookService', 'studentService', '$state', '$stateParams', '_', 'commonService', 'toastService', 'moment', 'lessonConstants', '$uibModal', 'appConstants', 'Store'];

    function bookAssignController(bookConstants, bookData, $scope, $uibModalInstance, bookService, studentService, $state, $stateParams, _, commonService, toastService, moment, lessonConstants, $uibModal, appConstants, Store) {

        let vm = this;
        let lessonId = 0;
        let bookId, data;
        let returnData;
        vm.book = {};
        vm.assignedStudents = [];
        vm.studentsToAssign = [];
        vm.assignmentDays = [];
        $scope.assignmentForm = {};
        vm.isDeleteAssignment = false;
        vm.assigningStudentId = 0;
        vm.isMaximumDaysSelected = false;
        vm.isFormInvalid = false;
        vm.formErrorMessage = '';
        vm.oldOpenedReview;
        vm.assignmentEndDate = null;
        vm.comment = ' ';
        $scope.maxRating = 5;
        vm.isFetchingData = false;
        vm.bookData = null;
        vm.lesson = null;
        $scope.ratedBy = 0;
        vm.dateWarning = false;
        vm.openEdit = false;
        vm.copyStudentId = null;
		// vm.modelName = 'mybooks';
		// vm.media = vm.book.media;
		// vm.multipleFiles = {
		// 	toUpload: [],
		// 	uploaded: vm.media,
		// 	toDelete: []
		// };
		vm.rating=0;
        $scope.rateBy = function(star) {
            $scope.ratedBy = star;
        }

        angular.extend(this, {
            edit,
            done,
            remove,
            toggleTab,
            close,
            rate,
            addReview,
            readStatusChange,
            toggleEditCategoryForm,
            assignTo,
            addAssignee,
            removeReview,
            confirmUnassignBook,
			changeImageUrl,
			autoExpand,
			addStudentStatus,
        });

        $scope.data = {};

		function changeImageUrl(img){
			if(img){
				img=img.replace('http:','https:');
			}
			return img;
		}

        function edit() {
            vm.book.averageRating = bookData.book.averageRating;
            let modelInstance = $uibModal.open({
                animation: true,
                size: 'lg',
                controller: 'bookEditController',
                controllerAs: 'bookEditCtrl',
                template:require("../../pug/book/edit-book.pug").default,
                resolve: {
                    bookData: {
                        book: vm.book
                    }
                }
            });
            modelInstance.result.then(function(options) {
                    // options.studentsAssigned = vm.studentsToAssign;
                    close(options);
                })
                .catch(function() {
                    modelInstance.close();
                });
        }

        function removeReview(student) {
            commonService.confirm({
                    message: bookConstants.messages.deleteReviewConfirmation,
                    type: 'danger',
                    modalSize: "md"
                })
                .then((confirmation) => {
                    if (confirmation.result === true) {
                        bookService.deleteReview(student.studentId, bookId)
                            .then((response) => {
                                vm.removedReview = response.data.data[0];
                                toastService.toast({
                                    message: bookConstants.messages.reviewDeleted,
                                    type: 'success',
                                    delay: 3000
                                });
                            })
                            .catch((err) => {
                                // console.log("errror", err);
                            })
                            .finally(() => {
                                close({ removeEvent: true, revmovedReview: vm.removedReview, bookData, reviewRemove: true });
                            })
                    } else return;
                })
                .catch((err) => {
                    // console.log("errror", err);
                });
        }

        function addAssignee() {
            let modelInstance = $uibModal.open({
                animation: true,
                size: 'lg',
                controller: 'bookAssignmentModalController',
                controllerAs: 'bookAssignmentModalCtrl',
                template:require("../../pug/book/add-assignee.pug").default,
                resolve: {
                    bookData: {
                        book: vm.book
                    }
                }
            });
            modelInstance.result
                .then(afterView)
                .catch(function() {
                    modelInstance.close();
                });
        }

        function confirmUnassignBook(assignment) {
            if (!assignment.studentLessonPlanId) {
                /**
                 * open modal window with option to unassign assignment for individual assignments
                 */
                let modelInstance = $uibModal.open({
                    animation: true,
                    size: 'md',
                    controller: 'confirmationUnassignBookModalController',
                    controllerAs: 'confirmationUnassignBookModalCtrl',
                    template:require("../../pug/book/unassign-confirmation.pug").default,
                    resolve: {
                        bookData: {
                            assignment: assignment
                        }
                    }
                });
                modelInstance.result
                    .then(function() {
                        close();
                    })
                    .catch(function() {
                        modelInstance.close();
                    });

                // commonService.confirm({
                //         message: bookConstants.messages.unassignBookConfirmation,
                //         type: 'danger',
                //         modalSize: "md"
                //     })
                //     .then((confirmation) => {
                //         if (confirmation.result === true) {
                //             vm.isDeleteAssignment = true;
                //         }

                //         bookService.unassignBook(assignment.assignId, vm.isDeleteAssignment)
                //             .then((response) => {
                //                 toastService.toast({
                //                     message: bookConstants.messages.bookUnassigned,
                //                     type: 'success',
                //                     delay: 3000
                //                 });
                //             })
                //             .catch((err) => {
                //                 console.log("errror", err);
                //             })
                //             .finally(() => {
                //                 close();
                //             })
                //     })
                //     .catch((err) => {
                //         console.log("errror", err);
                //     });

            } else {
                /**
                 * code to unassign book from assignments of lesson
                 */

                bookService.unassignBook(assignment.assignId, false)
                    .then((response) => {
                        toastService.toast({
                            message: bookConstants.messages.bookUnassigned,
                            type: 'success',
                            delay: 3000
                        });
                    })
                    .catch((err) => {
                        // console.log("errror", err);
                    })
                    .finally(() => {
                        close();
                    });
            }
        }

        function addStudentStatus(status, student){
			if(student.status==status){
				return;
			}
			let data={
				studentId:student.studentId,
				bookId:vm.bookData.id,
				status:status
			};
			bookService.addStudentStatus(data)
				.then(response=>{
					student.status=status;
					let studentsAssign = JSON.parse(Store.get('studentsAssigned'));
					if(studentsAssign && studentsAssign.length) {
						let findStudentIndex = _.findIndex(studentsAssign, obj => obj.studentId == student.studentId);
						if (findStudentIndex!==-1) {
							studentsAssign[findStudentIndex].status = status;
						}
						Store.set('studentsAssigned', JSON.stringify(studentsAssign));
					}

				});
		}

        function afterView(options) {
                // close(options);
            toggleTab('reviews');
            if (Object.keys(options).length) {
                vm.studentsToAssign = options.assignStudents;
                vm.bookAssignees = options.assignments;

                _.map(vm.studentsToAssign, function(reviewStudent) {
                    _.map(studentService.students, function(student) {
                        if (student.id == reviewStudent.studentId) {
                            if (!student.mediaUrl) {
                                student.mediaUrl = appConstants.defaultAvatar;
                            }
                            reviewStudent.studentData = student;
                        }
                    })
                })

                _.map(vm.bookAssignees, function(bookAssignee) {

                    bookAssignee.startTime = moment(bookAssignee.startDate).format("LT");
                    bookAssignee.endTime = moment(bookAssignee.endDate).format("LT");
                    if (bookAssignee.startTime === '12:00 AM' && bookAssignee.endTime === '11:59 PM') {
                        bookAssignee.allDayEvent = true;
                    }

                    _.map(bookAssignee.students, function(student) {
                        if (!student.mediaUrl) {
                            student.mediaUrl = appConstants.defaultAvatar;
                        }
                    })
                })

                Store.set('studentsAssigned', JSON.stringify(vm.studentsToAssign));
            }
        }

        function remove() {

            if (vm.bookAssignees.length) {
                let modelInstance = $uibModal.open({
                    animation: true,
                    size: 'md',
                    controller: 'confirmationUnassignEntireBookModalController',
                    controllerAs: 'confirmationUnassignEntireBookModalCtrl',
                    template:require("../../pug/book/unassign-book-confirmation.pug").default,
                    resolve: {
                        bookData: {
                            assignment: {
                                assignId: bookId
                            }
                        }
                    }
                });
                modelInstance.result
                    .then(function(action) {
                        /*
                         If book successfully deleted then action will be true
                         otherwise false
                         */
                        close(action);
                    })
                    .catch(function() {
                        modelInstance.close();
                    });
            } else {
                commonService.confirm({
                        message: bookConstants.messages.deleteBookConfirmation,
                        type: 'danger',
                        modalSize: "md"
                    })
                    .then((confirmation) => {
                        if (confirmation.result === true) {
                            bookService.deleteBook(bookId, false)
                                .then((deleted) => {
                                    toastService.toast({
                                        message: bookConstants.messages.bookDeleted,
                                        type: 'success',
                                        delay: 3000
                                    });
                                })
                                .catch((err) => {
                                })
                                .finally(() => {
                                    close({ removeEvent: true, bookData });
                                })
                        } else return;
                    })
                    .catch((err) => {
                    });
            }
        }

        function readStatusChange(id, status) {

            id = commonService.convertToInteger(id);
            for (let i = 0; i < vm.studentsToAssign.length; i++) {
                if (vm.studentsToAssign[i].studentId == id)
                    vm.studentsToAssign[i].isRead = status;
            }
        }

        function rate(id, star, studIndex) {
			if(!vm.openEdit || id != vm.copyStudentId){
				vm.copyStudentId = id;
				toggleEditCategoryForm(studIndex);
			}
            id = commonService.convertToInteger(id);
            let studentIndex = _.findIndex(vm.studentsToAssign, { studentId: id });

            if (studentIndex > -1) {
                vm.studentsToAssign[studentIndex].rating = star;
            }
        }

        // Get students to assign the book to
        function getStudentsToAssign() {
            return bookService.getAssignedStudent(bookId)
                .then(function(response) {
                    vm.studentsToAssign = response;
                    for (let i = 0; i < vm.studentsToAssign.length; i++) {
                        vm.studentsToAssign[i].rating = 0;
                        vm.studentsToAssign[i].comment = '';
                    }

                })
                .catch(function(response) {
                    console.error(response);
                });
        }

        function addReview(id, comment) {
            vm.openEdit = false;
            let ratedStudents = [];
            _.forEach(vm.studentsToAssign, student => {
                if(student.rating !==0){
                	student.status=bookConstants.studentBookCompletdStatus;
                    ratedStudents.push(student);
                }
            });
            bookService.addReview(bookId, ratedStudents)
                .then((response) => {
                    let ratingData = {
                        bookId: bookId,
                        rating: response.data.data.averageRating,
                        studentsAssigned: JSON.parse(Store.get('studentsAssigned')),
                    }
                    Store.set('ratingData', JSON.stringify(ratingData));
                    Store.set('studentsAssigned', JSON.stringify(vm.studentsToAssign));
                    // isOpenReview = false;
                    let rating=response.data.data.averageRating;
                    if(rating){
                        vm.rating=rating;
                    }

                });
            toggleEditCategoryForm();
        }

        function toggleEditCategoryForm(index, action) {
			if(index > -1 && action != 'cancel') {
				vm.openEdit = true;
			} else {
				vm.openEdit = false;
			}

            // if (!angular.isDefined(action)) {
            //     /**
            //      * index will be given for opening a new review
            //      */
            //     if (angular.isNumber(index)) {
            //         /**
            //          * check if already any review is opened
            //          */
            //         if (!isOpenReview) {
            //             vm.editCategoryIndex = commonService.convertToInteger(index);
            //         }
            //         /**
            //          * keep old review opened if one is opened and new one is requested
            //          */
            //         else {
            //             vm.editCategoryIndex = vm.oldOpenedReview;
            //         }
            //     }
            //     /**
            //      * close an opened review
            //      */
            //     else {
            //         vm.editCategoryIndex = -1;
            //     }
            // }

            if (angular.isNumber(index)) {
                vm.studentsToAssign = JSON.parse(Store.get('studentsAssigned'));
                vm.editCategoryIndex = index;
            } else {
                vm.editCategoryIndex = -1;
            }

            // vm.editCategoryIndex = angular.isNumber(index) ? commonService.convertToInteger(index) : -1;

            vm.editCategory = {
                name: ''
            };

            if (vm.editCategoryIndex > -1) {
                vm.studentsToAssign[vm.editCategoryIndex].isRead = true;
            }

            if (angular.isDefined(action) && action == 'cancel') {
                vm.studentsToAssign = JSON.parse(Store.get('studentsAssigned'));
                vm.editCategoryIndex = -1;
                // isOpenReview = false;
            }

            // if (!angular.isDefined(action) && !isOpenReview) {
            //     isOpenReview = true;
            //     vm.oldOpenedReview = index;
            // }
        }

        function toggleTab(tab) {
            vm.activeTab = tab;
        }

        function close(data = returnData) {
            if (!data) {
                if(vm.rating){
                    bookData.book.averageRating = vm.rating;
                }
                //let student = JSON.parse(Store.get('studentsAssigned'));
                let studentData = {
                    studentsAssigned: JSON.parse(Store.get('studentsAssigned')),
                    bookId: bookId,
					rating: bookData.book.averageRating
                }
                $uibModalInstance.close(studentData);
            } else {
                $uibModalInstance.close(data);
            }
        }

        function done() {
            bookService.assignBook(vm.studentsBeforeAssign, bookId)
                .then((completed) => {
                    close();
                })
                .catch((err) => {
                    // console.log("Studens not assigned", err);
                })
        }



        // Get details about selected book
        function getBookDetails() {
            return bookService.viewBook(bookId)
                .then(function(response) {
					let currentDate = moment(new Date());
					var isafter = moment(response.dueDate).isAfter(currentDate);
					vm.dateWarning = !isafter;
                    vm.book = response;
					vm.book.dueDate = response.dueDate ? moment(response.dueDate).format('MMM DD, YYYY'): response.dueDate;
                    vm.lesson = vm.bookData.lesson;
                    vm.studentsToAssign = response.assignStudents;
					vm.modelName = 'mybooks';
					vm.media = vm.book.media;
					vm.multipleFiles = {
						toUpload: [],
						uploaded: vm.media,
						toDelete: []
					};

					if(vm.book.type) {
						let types = vm.book.type.split(",");
						vm.book.bookType = null;
						vm.book.inLesson = '';
						for(var i=0; i<types.length; i++) {
								if(types[i] == 'inLesson') {
									vm.book.inLesson = 'inLesson';
									vm.book.bookType = 'In Lesson';
								} if(vm.book.bookType && types[i] == 'readIndependent') {
										vm.book.bookType += ", Independent Reading";
									} else if(types[i] == 'readIndependent') {
										vm.book.bookType = "Independent Reading";
									}
									if(vm.book.bookType && types[i] == 'readWithHelp') {
										vm.book.bookType += ", Read with help";
									} else if(types[i] == 'readWithHelp') {
										vm.book.bookType = "Read with help";
									}

						}
					}
                    /**
                     * save state of assigned student for 'cancel' action
                     */
                    Store.set("studentsAssigned", JSON.stringify(vm.studentsToAssign));
                    vm.bookAssignees = response.assignments;
                    let assignedStudents = [];
                    _.map(vm.bookAssignees, function(bookAssignee) {
                        bookAssignee.allDayEvent = false;
                        bookAssignee.startTime = moment(bookAssignee.startDate).format("LT");
                        bookAssignee.endTime = moment(bookAssignee.endDate).format("LT");
                        if (bookAssignee.startTime === '12:00 AM' && bookAssignee.endTime === '11:59 PM') {
                            bookAssignee.allDayEvent = true;
                        }

                        _.map(bookAssignee.students, function(student) {
                            let assignee = _.clone(student);
                            assignee.displayTitle = bookAssignee.displayTitle;
                            assignee.title = bookAssignee.title;
                            assignee.name = bookAssignee.name;
                            assignedStudents.push(assignee);
                            if (!student.mediaUrl) {
                                student.mediaUrl = appConstants.defaultAvatar;
                            }
                        })
                    })
                    vm.assignedStudents = assignedStudents;
                    _.map(vm.studentsToAssign, function(reviewStudent) {
                        _.map(studentService.students, function(student) {
                            if (student.id == reviewStudent.studentId) {
                                if (!student.mediaUrl) {
                                    student.mediaUrl = appConstants.defaultAvatar;
                                }
                                reviewStudent.studentData = student;
                            }
                        })
                    })
                        // return response;
                })
                .catch(function(response) {
                    console.error(response);
                })
                .finally(() => {
                    vm.isFetchingData = false;
                });
        }

        function assignTo(index) {
            vm.studentsToAssign[index].isAssign = !vm.studentsToAssign[index].isAssign;
        }

		function getBookIdFromResources() {
			vm.resourceBookIds = _.map(vm.books, 'bookId');
		}

		vm.openBookSearchModal = function () {
			getBookIdFromResources();
			let modelInstance = $uibModal.open({
				animation: true,
				size: 'lg',
				controller: 'lessonBookSearchController',
				controllerAs: 'lessonBookSearchCtrl',
                template:require("../../pug/lesson/search-book.pug").default,
				resolve : {
					bookData: {
						bookIds: vm.resourceBookIds,
						// addGlobally: true
					}
				}
			});
			modelInstance.result.then(function(books) {
				// console.log("books-,books,",books)
				books = getDefaultResourceData(books);

				// if(vm.books.length) {
				//
				// 	let mergedBooks = [...vm.books, ...books];
				// 	vm.books = mergedBooks;
				// }
				// else {
				vm.books = angular.copy(books);
				// }


			}).catch(function() {
				modelInstance.close();
			});
		}

		function autoExpand(e) {
			var element =
				typeof e === "object" ? e.target : document.getElementById(e);
			var scrollHeight = element.scrollHeight; // replace 60 by the sum of padding-top and padding-bottom
			element.style.height = scrollHeight + "px";
		}

        this.$onInit = function() {
            vm.bookData = bookData.book;
            vm.activeTab = bookData.activeTab;
            vm.isFetchingData = true;
            bookId = commonService.convertToInteger(bookData.bookId);
            return [getBookDetails()];
        }
    }
export default bookAssignController;
