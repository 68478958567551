let angular = window.angular;

userViewController.$inject = [
  "$scope",
  "bookService",
  "studentService",
  "commonService",
  "$state",
  "$stateParams",
  "_",
  "lessonService",
  "appConstants",
  "toastService",
  "studentConstants",
  "moment",
  "$uibModal",
  "$rootScope",
  "$q",
];

function userViewController(
  $scope,
  bookService,
  studentService,
  commonService,
  $state,
  $stateParams,
  _,
  lessonService,
  appConstants,
  toastService,
  studentConstants,
  moment,
  $uibModal,
  $rootScope,
  $q
) {
  let vm = this;
  let studentId;
  vm.colors = appConstants.colors;
  vm.groupedAssignedLessons = [];
  vm.isFetchingAssignedLessons = false;

  vm.isEditAcademicInformation = false;
  vm.viewEditAcademicInformation = true;
  vm.viewAddAcademicInformation = false;
  vm.ishideEditDeleteButtons = false;
  // vm.student = {
  //     countryCode: 1,
  //     parentCountryCode: 1,
  //     academicDays: 180
  // };
  vm.academics = {
    academicYearStart: "",
    academicYearEnd: "",
    grade: "",
    academicDays: "",
    goals: "",
    reflections: "",
  };
  vm.availableLessons = [];
  vm.student = {};
  vm.currentEdited = {
    id: 0,
  };

  vm.isFormInvalid = false;
  vm.formErrorMessage = "";

  vm.days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  vm.assignmentAllDay = false;
  $scope.maxRating = 5;
  angular.extend(vm, {
    editFeedback,
    rate,
    readStatusChange,
    removeBookAssignment,
    toggleBook,
    deleteStudent,
    toggleTab,
    assignLesson,
    removeLessonAssignment,
    editLessonAssignment,
    onSelectDay,
    setStudentLabelColor,
    setStudentLessonLabelColor,
    loginForStudent,
    loginForStudentToggle,
    changeImageUrl,
    editAcademicInformation,
    updateAcademicInformation,
    cancelAcademicInformation,
    deleteAcademicInformation,
    selectLesson,
    changePermissionStatus,
    changeSearchPermission,
    updatePermission,
    // addStudentAcademicInformation,
    // checkMultigrade,
    // checkAcademicYearInteraction
  });

  function selectLesson(selectedLesson) {
    if (selectedLesson.duration == "23:59") {
      vm.assignmentTime = "00:00";
    }
  }

  function changeImageUrl(img) {
    if (img) {
      img = img.replace("http:", "https:");
    }
    return img;
  }

  function readStatusChange(bookId, status) {
    let id = commonService.convertToInteger(bookId);
    for (let i = 0; i < vm.books.length; i++) {
      if (vm.books[i].book.id == bookId) vm.books[i].isRead = status;
    }
  }

  function editFeedback(bookId, comment, rating, status) {
    let student = {
      studentId: studentId,
      comment: comment,
      rating: rating,
      isRead: status,
    };
    bookService
      .addReview(bookId, student)
      .then((completed) => {
        toggleBook();
        // close();
      })
      .catch((err) => {
        // console.log("Studens not assigned");
      });
  }

  function toggleBook(index) {
    vm.editBookIndex = angular.isNumber(index)
      ? commonService.convertToInteger(index)
      : -1;
  }

  function rate(bookId, star) {
    id = commonService.convertToInteger(bookId);
    for (let i = 0; i < vm.books.length; i++) {
      if (vm.books[i].book.id == id) vm.books[i].rating = star;
    }
  }

  function removeBookAssignment(bookId) {
    bookService
      .setStudentBook(bookId, studentId)
      .then((res) => {
        // vm.books = null;
        toastService.toast({
          message: "Unassigned Successfully",
          type: "success",
          delay: 2000,
        });
        getUserBooks();
      })
      .catch((err) => {
        // console.log("err", err);
      });
  }

  function toggleTab(tab) {
    vm.activeTab = tab;
  }

  function removeAssignedLessonToList(studentLessonId) {
    for (let i = vm.groupedAssignedLessons.length - 1; ~i; --i) {
      let oldlength = vm.groupedAssignedLessons[i].lesson.length;
      commonService.removeFromArray(
        studentLessonId,
        "id",
        vm.groupedAssignedLessons[i].lesson
      );
      if (oldlength !== vm.groupedAssignedLessons[i].lesson.length) {
        // If the course doesnot have any lesson in the course, remove the course
        !vm.groupedAssignedLessons[i].lesson.length &&
          vm.groupedAssignedLessons.splice(i, 1);
        return true;
      }
    }
    return false;
  }

  function saveAssignedLessonToList(studentLessonId, lesson) {
    lesson = correctEndLessonDate(lesson);

    removeAssignedLessonToList(studentLessonId);
    let index = commonService.findInArray(
      lesson.courseId,
      "id",
      vm.groupedAssignedLessons
    );
    if (!~index) {
      // angular.copy is used to prevent circular dependency
      vm.groupedAssignedLessons.push(angular.copy(lesson.course));
      index = vm.groupedAssignedLessons.length - 1;
    }
    if (~index) {
      vm.groupedAssignedLessons[index].lesson = angular.isArray(
        vm.groupedAssignedLessons[index].lesson
      )
        ? vm.groupedAssignedLessons[index].lesson
        : [];
      return commonService.saveInArray(
        studentLessonId,
        "id",
        vm.groupedAssignedLessons[index].lesson,
        lesson
      );
    }
    return false;
  }

  function setAssignLessonDefaults() {
    vm.isAssigningLesson = false;
    vm.isMaximumDaysSelected = false;
    vm.assignmentDate = null;
    vm.assignmentTime = null;
    vm.assigningLesson = false;
    vm.assignmentDays = [];
    vm.isFormInvalid = false;
    vm.formErrorMessage = "";
  }

  function deleteStudent(studentId) {
    if (!studentId) {
      return;
    }

    let confirmData = {
      message: `All data associated with ${vm.student.name} will be removed if you delete a user. Are you sure you want to delete this user?`,
      type: "danger",
      modalSize: "md",
    };

    commonService.confirm(confirmData).then((confirmation) => {
      if (confirmation.result) {
        studentService
          .deleteUser(studentId)
          .then((response) => {
            // Go to the first student after the student is deleted or go to student add page
            let users = studentService.students.filter(
              (user) => user.userRole === "HELPER"
            );
            if (users && users.length > 0) {
              $state.go(
                "userInfo",
                {
                  studentId: users[0].id,
                },
                {
                  location: "replace",
                }
              );
            } else {
              $state.go(
                "userAdd",
                {},
                {
                  location: "replace",
                }
              );
            }
          })
          .catch((response) => {
            console.error(response);
          });
      }
    });
  }

  function onSelectDay() {
    if (vm.assigningLesson) {
      if (vm.assignmentDays.length >= vm.assigningLesson.days) {
        vm.isMaximumDaysSelected = true;
      } else {
        vm.isMaximumDaysSelected = false;
      }
    }
  }

  function validateAssignLessonForm() {
    if (!vm.assigningLesson) {
      vm.isFormInvalid = true;
      vm.formErrorMessage = studentConstants.messages.selectStudentToAssign;
      return false;
    }

    if (!vm.assignmentDate) {
      vm.isFormInvalid = true;
      vm.formErrorMessage = studentConstants.messages.selectStartDateLesson;
      return false;
    }

    if (!vm.assignmentTime) {
      vm.isFormInvalid = true;
      vm.formErrorMessage = studentConstants.messages.selectStartTimeLesson;
      return false;
    }

    if (vm.assignmentDays.length !== vm.assigningLesson.days) {
      vm.isFormInvalid = true;
      vm.formErrorMessage =
        "Please select at least " +
        vm.assigningLesson.days +
        " days to assign lesson to the student.";
      return false;
    }

    return true;
  }

  function assignLesson() {
    if (!validateAssignLessonForm()) {
      return;
    }

    let data = {},
      dateTime;
    data.studentId = vm.student.id;
    data.lessonId = vm.assigningLesson.id;
    data.assignmentDays = vm.assignmentDays;
    data.startDate = vm.assignmentDate;

    dateTime = commonService.convertToUTC(
      vm.assignmentDate + " " + vm.assignmentTime
    );
    data.utcStartDateTime = dateTime.format("Y-MM-DD HH:mm:00");

    lessonService
      .assignStudent(data.lessonId, data)
      .then((response) => {
        saveAssignedLessonToList(response.id, response);
        setAssignLessonDefaults();
      })
      .catch((response) => {
        console.error(response);
      });
  }

  function editLessonAssignment(studentLessonPlanId) {
    $state.go("studentLessonEdit", {
      lessonId: studentLessonPlanId,
    });
    close();
  }

  function removeLessonAssignment(studentLessonId) {
    if (!studentLessonId) {
      return;
    }

    let modalInstance = $uibModal.open({
      animation: true,
      size: "md",
      controller: "EditLessonConfirmationController",
      controllerAs: "confirmLessonCtrl",
      template: require("../../pug/calendar/edit-lesson-confirmation.pug")
        .default,
    });

    let confirmPromise = modalInstance.result
      .then(
        function (option) {
          if (option) {
            $rootScope.loadingBtn("unassignBtn-" + studentLessonId, "Updating");
            studentService
              .removeAssignment(studentLessonId, option)
              .then((response) => {
                removeAssignedLessonToList(studentLessonId);
                toastService.toast({
                  message: response.message,
                  type: "success",
                  delay: 2000,
                });

                // close({ removeEvent: true, model: 'lesson', search: true, deleteLessonId: studentLessonId });
              })
              .catch((response) => {
                toastService.toast({
                  message: response.message,
                  type: "error",
                  delay: 2000,
                });
              })
              .finally(() => {
                $rootScope.loadingBtnComplete(
                  "unassignBtn-" + studentLessonId,
                  "Update"
                );
              });
          }
          modalInstance.close();
        },
        function () {
          return $q.reject(false);
        }
      )
      .catch(function () {
        modalInstance.close();
      });

    // let confirmData = {
    // 	message: 'Are you sure to unassign this lesson from ' + vm.student.firstName + '\'s Lesson Plan?',
    // 	type: 'danger',
    // 	modalSize: 'md',
    // };

    // commonService.confirm(confirmData)
    // 	.then((confirmation) => {
    // 		if (confirmation.result) {
    // 			studentService.removeAssignment(studentLessonId)
    // 				.then((response) => {
    // 					removeAssignedLessonToList(studentLessonId);
    // 					toastService.toast({message: response.message, type: 'success', delay: 2000});
    // 				})
    // 				.catch((response) => {
    // 					toastService.toast({message: response.message, type: 'error', delay: 2000});
    // 				});
    // 		}
    // 	});
  }

  function setStudentLabelColor(color) {
    vm.student.color = color;
    vm.student.rolePermission = vm.student.permission;
    studentService
      .editUser(vm.student)
      .then((response) => {
        if (response && response.data.data.Student.length > 0)
          studentService.saveStudent(
            response.data.data.Student[0].id,
            response.data.data.Student[0]
          );
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  function setStudentLessonLabelColor(studentLessonId, color) {
    lessonService
      .editStudentLessonColor(studentLessonId, color)
      .then((studentLesson) => {
        if (!vm.groupedAssignedLessons) {
          toastService.toast({
            message: "Something went wrong, please try again",
            type: "error",
            delay: 5000,
          });
          return;
        }

        for (let j = 0; j < vm.groupedAssignedLessons.length; j++) {
          let studentLessonList = vm.groupedAssignedLessons[j].lesson;
          for (let i = 0; i < studentLessonList.length; i++) {
            if (studentLessonList[i].id == studentLesson.id) {
              studentLessonList[i].color = studentLesson.color;
            }
          }
        }

        toastService.toast({
          message: "Successfully changed lesson color",
          type: "success",
          delay: 5000,
        });
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  function getStudentDetails() {
    studentService
      .getHelperDetail(studentId)
      .then((response) => {
        vm.student = response.helperUserId;
        let permission = vm.student.rolePermission;
        let permissions = {};
        for (const key of Object.keys(permission)) {
          switch (key) {
            case key:
              if (permission[key] === "EDIT") {
                let editData = {
                  [key]: {
                    view: true,
                    edit: true,
                  },
                };
                permissions = Object.assign({}, permissions, editData);
              }
              if (permission[key] === "VIEW") {
                let viewData = {
                  [key]: {
                    view: true,
                  },
                };
                permissions = Object.assign({}, permissions, viewData);
              }
              break;

            default:
          }
        }
        vm.student.permission = permissions;
        vm.student.relation = response.relation;
        vm.student.color = response.color;
        vm.student.nickName = response.nickName || null;
        vm.studentImage = vm.student.mediaUrl || appConstants.defaultAvatar;
        if (!response.chatPermission) {
          vm.student.chatPermission = "PRIVATE";
        }
        vm.student.userId = response.userId;
        vm.student.helperUserId = response.id;
        vm.student.id = response.id;
        vm.student.loginStatus = response.loginStatus;
      })
      .catch((response) => {
        $state.go("userAdd");
        // console.error(response);
      });
  }

  function correctEndLessonDate(lesson) {
    // lesson.endDate = lessonService.getEndDateOfLesson(lesson.assignmentDays, lesson.startDate, lesson.weeks, lesson.days, lesson.startTime, lesson.duration);

    if (moment(lesson.startTime, "HH:mm").isValid()) {
      lesson.startTime = commonService.convertFromUTC(
        lesson.startDate + " " + lesson.startTime + ":00"
      );
      lesson.endTime = moment(lesson.startTime)
        .add(lesson.duration.split(":")[0], "hour")
        .add(lesson.duration.split(":")[1], "minute");
    }

    return lesson;
  }

  function sortLessons() {
    vm.groupedAssignedLessons.sort((prev, next) => {
      return prev.name > next.name;
    });
    for (let i = 0; i < vm.groupedAssignedLessons.length; i++) {
      vm.groupedAssignedLessons[i].lesson.sort((prev, next) => {
        return prev.name > next.name;
      });
    }
  }

  function getLessonDetails() {
    vm.isFetchingAssignedLessons = true;
    studentService
      .getLessons(studentId)
      .then((response) => {
        vm.groupedAssignedLessons = _.map(response, (groupLesson) => {
          groupLesson.lesson = _.map(groupLesson.lesson, correctEndLessonDate);

          for (let i = 0; i < groupLesson.lesson.length; i++) {
            if (!groupLesson.lesson[i].color) {
              groupLesson.lesson[i].color =
                appConstants.defaultColor.studentAssignment;
            }
          }
          return groupLesson;
        });
        sortLessons();
      })
      .catch((response) => {
        console.error(response);
      })
      .finally(() => {
        vm.isFetchingAssignedLessons = false;
      });
  }

  function getUserBooks() {
    // vm.isFetchingData = true;
    bookService
      .getStudentBook(studentId)
      .then((response) => {
        vm.books = response;
        for (let i = 0; i < vm.books.length; i++) {
          if (vm.books[i].book.description) {
            if (vm.books[i].book.description.length > 170) {
              vm.books[i].book.desc =
                vm.books[i].book.description
                  .toLowerCase()
                  .substring(0, 170)
                  .trim() + "...  ";
            } else {
              vm.books[i].book.desc = vm.books[i].book.description;
            }
          }
          // console.log("length",vm.books[i].description.length);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        vm.isFetchingData = false;
      });
  }

  function getLessonsToAssign() {
    lessonService
      .getAllLessons()
      .then((response) => {
        // Flatten all the lessons

        let allLessons = response;
        for (let i = 0; i < response.length; i++) {
          vm.availableLessons.push(...allLessons[i].lesson);
        }
      })
      .catch((response) => {
        console.error(response);
      });
  }

  function loginForStudentToggle(studentData, loginStatus) {
    if (!vm.student.loginStatus) {
      loginForStudent(studentData, loginStatus);
    }
  }

  function loginForStudent(studentData, loginStatus) {
    /**
     * Validate student input
     */
    if (!studentData.email) {
      toastService.toast({
        message: "Please enter correct email address.",
        type: "error",
        delay: 5000,
      });
      exitFunc = true;
      return;
    }
    /**
     * Password is only for the add user
     */

    if (!studentData.password) {
      if (loginStatus == "ENABLE") {
        toastService.toast({
          message: "Please enter password",
          type: "error",
          delay: 5000,
        });
        exitFunc = true;
        return;
      } else {
        studentData.password = "";
      }
    }

    $rootScope.loadingBtn("save", "loading");
    studentData.loginStatus = loginStatus;
    studentData.chatPermission = "PRIVATE";

    studentService
      .userLoginStatusChange(studentData)
      .then((studentDataResponse) => {
        if (vm.student && vm.student.loginStatus == "ENABLE") {
          vm.student.loginStatus = true;
        } else {
          vm.student.loginStatus = false;
        }

        $rootScope.loadingBtnComplete("save");
        toastService.toast({
          message:
            studentDataResponse.message || "Please fill the form and try again",
          type: "success",
          delay: 5000,
        });
      })
      .catch((error) => {
        toastService.toast({
          message: error.message || "Please fill the form and try again",
          type: "error",
          delay: 5000,
        });
        $rootScope.loadingBtnComplete("save");
      });
  }

  //addStudentAcademicInformation
  vm.addStudentAcademicInformation = function () {
    let data = {};
    data = _.clone(vm.academics);
    data.studentId = $stateParams.studentId;
    studentService
      .addStudentAcademics(data)
      .then(addStudentAcademicInformationSuccess)
      .catch(function (error) {
        console.error(error);
        toastService.toast({
          message: error.data.message || "Please fill the form and try again",
          type: "error",
          delay: 2000,
        });
        $rootScope.loadingBtnComplete("save");
      })
      .finally(() => {
        $rootScope.loadingBtnComplete("save", "Save");
        vm.viewAddAcademicInformation = false;
        getStudentDetails();
      });
  };

  function setAcademicInformationDefaults() {
    vm.academics.academicYearStart = null;
    vm.academics.academicYearEnd = null;
    vm.academics.grade = null;
    vm.academics.academicDays = null;
    vm.academics.goals = null;
    vm.academics.reflections = null;
  }

  function addStudentAcademicInformationSuccess(response) {
    let deferred = $q.defer();
    setAcademicInformationDefaults();
    studentService.academics.push(response.data.data.studentAcademcis);

    return deferred;
  }

  function editAcademicInformation(acad) {
    vm.academics.academicYearStart = acad.academicYearStart;
    vm.academics.academicYearEnd = acad.academicYearEnd;
    vm.academics.grade = acad.grade;
    vm.academics.academicDays = acad.academicDays;
    vm.academics.goals = acad.goals;
    vm.academics.reflections = acad.reflections;

    vm.academicInformationId = acad.id;
    vm.viewAddAcademicInformation = true;
    vm.isEditAcademicInformation = true;
  }

  function updateAcademicInformation() {
    let data = {};
    data = _.clone(vm.academics);
    data.studentId = $stateParams.studentId;
    data.academicId = vm.academicInformationId;

    studentService
      .updateStudentAcademics(data)
      .then(updateAcademicInformationSuccess)
      .catch(function (error) {
        console.error(error);
        toastService.toast({
          message: error.data.message || "Please fill the form and try again",
          type: "error",
          delay: 4000,
        });
        $rootScope.loadingBtnComplete("save");
      })
      .finally(() => {
        $rootScope.loadingBtnComplete("save", "Save");
        vm.viewAddAcademicInformation = false;
        getStudentDetails();
        _.map(vm.student.academicDetails, (academic, i) => {
          academic.ishideEditDeleteButtons = false;
        });
      });
  }

  function updateAcademicInformationSuccess(response) {
    let deferred = $q.defer();
    setAcademicInformationDefaults();
    studentService.academics.push(response.data.data.academicInfo);
    return deferred;
  }

  function cancelAcademicInformation() {
    _.map(vm.student.academicDetails, (academic, i) => {
      academic.ishideEditDeleteButtons = false;
    });
    vm.viewAddAcademicInformation = false;
    setAcademicInformationDefaults();
  }

  function deleteAcademicInformation(acad) {
    let confirmData = {
      message: studentConstants.messages.deleteAcademicDetailsWarning,
      modalSize: "md",
      type: "danger",
    };

    commonService.confirm(confirmData).then(function (confirmation) {
      if (confirmation.result) {
        studentService
          .deleteStudentAcademics(acad)
          .then(deleteAcademicInformationSuccess)
          .catch(function (error) {
            console.error(error);
            toastService.toast({
              message:
                error.data.message || "Please fill the form and try again",
              type: "error",
              delay: 4000,
            });
            $rootScope.loadingBtnComplete("save");
          })
          .finally(() => {
            getStudentDetails();
          });
      }
    });
  }

  function deleteAcademicInformationSuccess(response) {
    let deferred = $q.defer();
    studentService.academics.push(response.data.data.academicInfo);
    return deferred;
  }

  function changePermissionStatus(permissionType) {
    studentService
      .permissionChange(vm.student, permissionType)
      .then((data) => {
        toastService.toast({
          message: data.message,
          type: "success",
          delay: 3000,
        });
      })
      .catch(() => {
        toastService.toast({
          message: "Error Occured",
          type: "error",
          delay: 3000,
        });
      });
  }
  function changeSearchPermission() {
    let inputData = {
      searchPermission: vm.student.searchPermission,
    };
    studentService
      .studentPermission(vm.student.id, inputData)
      .then((data) => {
        toastService.toast({
          message: data.message,
          type: "success",
          delay: 3000,
        });
      })
      .catch(() => {
        toastService.toast({
          message: "Error Occured",
          type: "error",
          delay: 3000,
        });
      });
  }

  function updatePermission(type, action) {
    if (
      vm.student.permission[type].edit &&
      !vm.student.permission[type].view &&
      action === "edit"
    )
      vm.student.permission[type].view = true;
    else if (
      vm.student.permission[type].edit &&
      !vm.student.permission[type].view &&
      action === "view"
    )
      vm.student.permission[type].edit = false;
    let data = {};
    let userData = {};
    data = _.clone(vm.student);
    userData.id = data.id;
    userData.relation = data.relation;
    userData.color = data.color;
    userData.rolePermission = vm.student.permission;
    studentService
      .editUser(userData)
      .then((response) => {
        if (response && response.data.data.Student.length > 0) {
          let permission = response.data.data.Student[0].rolePermission;
          let permissions = {};
          for (const key of Object.keys(permission)) {
            switch (key) {
              case key:
                if (permission[key] === "EDIT") {
                  let editData = {
                    [key]: {
                      view: true,
                      edit: true,
                    },
                  };
                  permissions = Object.assign({}, permissions, editData);
                }
                if (permission[key] === "VIEW") {
                  let viewData = {
                    [key]: {
                      view: true,
                    },
                  };
                  permissions = Object.assign({}, permissions, viewData);
                }
                break;

              default:
            }
          }
          vm.student.permission = permissions;
        }
        toastService.toast({
          message: "Permission Updated Successfully!",
          type: "success",
          delay: 3000,
        });
      })
      .catch(() => {
        toastService.toast({
          message: "Error Occured",
          type: "error",
          delay: 3000,
        });
      });
  }
  function init() {
    /**
     * Varify permissions
     */
    if (!commonService.validRequest(studentConstants.studentModel)) {
      return false;
    }
    studentId = commonService.convertToInteger($stateParams.studentId);
    vm.activeTab = "permissions";

    if (!studentId) {
      $state.go(
        "studentAdd",
        {},
        {
          location: "replace",
        }
      );
      return;
    }

    setAssignLessonDefaults();
    getStudentDetails();
    getUserBooks();
    getLessonDetails();
    getLessonsToAssign();
  }

  this.$onInit = init;
}

export default userViewController;
