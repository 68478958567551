export const durationDirective = (commonService) => {
  return {
    restrict: "E",
    template: require("../../pug/components/duration.pug").default,
    scope: {
      ngModel: "=",
    },
    require: "ngModel",
    replace: true,
    link: function (scope, iElement, iAttrs, ngModelCtrl) {
      let tmp = ngModelCtrl.$setDirty;
      ngModelCtrl.$setDirty = angular.noop;

      ngModelCtrl.$formatters.push(function (modelValue) {
        if (modelValue) {
          let duration = modelValue.split(":");
          return {
            hours: commonService.convertToInteger(duration[0]) + "",
            minutes: commonService.convertToInteger(duration[1]) + "",
          };
        }
      });

      ngModelCtrl.$render = function () {
        scope.hours = ngModelCtrl.$viewValue.hours;
        scope.minutes = ngModelCtrl.$viewValue.minutes;
      };

      scope.$watch("hours + minutes", function () {
        ngModelCtrl.$setViewValue({
          hours: commonService.convertToInteger(scope.hours) + "",
          minutes: commonService.convertToInteger(scope.minutes) + "",
        });

        ngModelCtrl.$setDirty = tmp;
      });

      ngModelCtrl.$parsers.push(function (viewValue) {
        if (
          !commonService.convertToInteger(viewValue.hours) &&
          !commonService.convertToInteger(viewValue.minutes)
        ) {
          return undefined;
        } else {
          return [viewValue.hours, viewValue.minutes].join(":");
        }
      });
    },
  };
};
durationDirective.$inject = ["commonService"];
