let angular = window.angular;
hspShareController.$inject = [
  "appConstants",
  "$uibModalInstance",
  "commonService",
  "$state",
  "toastService",
  "Socialshare",
  "userService",
];

function hspShareController(
  appConstants,
  $uibModalInstance,
  commonService,
  $state,
  toastService,
  Socialshare,
  userService
) {
  let vm = this;

  angular.extend(vm, {
    shareOnGooglePlus,
    shareOnFb,
    shareOnTwitter,
    close,
  });

  function close() {
    $uibModalInstance.close(true);
  }

  function shareOnGooglePlus() {
    Socialshare.share({
      provider: "google",
      attrs: {
        socialshareUrl: appConstants.shareUrl,
        socialshareText:
          appConstants.socialSharingMessage +
          "\xa0" +
          "#" +
          appConstants.socialSharingHashTag,
      },
    });
    userService.shareHSP("google");
  }

  function shareOnFb() {
    Socialshare.share({
      provider: "facebook",
      attrs: {
        socialshareUrl: appConstants.shareUrl,
        socialshareVia: appConstants.fbAppId,
        socialshareType: "feed",
        socialshareQuote:
          appConstants.socialSharingMessage +
          "\xa0" +
          "#" +
          appConstants.socialSharingHashTag,
        socialshareDisplay: "popup",
      },
    });
    userService.shareHSP("facebook");
  }

  function shareOnTwitter() {
    Socialshare.share({
      provider: "twitter",
      attrs: {
        socialshareText: appConstants.socialSharingMessage,
      },
    });
    userService.shareHSP("twitter");
  }
}

export default hspShareController;
