let angular = window.angular;
lessonService.$inject = [
  "$http",
  "$q",
  "commonService",
  "studentService",
  "_",
  "toastService",
  "lessonConstants",
  "appConstants",
];

function lessonService(
  $http,
  $q,
  commonService,
  studentService,
  _,
  toastService,
  lessonConstants,
  appConstants
) {
  const graphqlUri = process.env.GRAPHQL_URI;
  const token = localStorage.getItem("authorizationToken");
  let basePath = appConstants.baseUrl + "/api/lessons/";
  let groupedLessonByCourse = [];
  let groupedLessonByTitle = [];
  const days = lessonConstants.days;
  let allLessonsFetched = false;
  let loadMoreCourseStatus = false;
  let page = 1;

  angular.extend(this, {
    getAssignedStudent,
    assignStudent,
    deleteLesson,
    archiveLesson,
    changeCourseVisibility,
    viewLesson,
    saveLesson,
    saveAssignmentToLesson,
    getLessons,
    getAllLessons,
    getPublicLessons,
    getLessonsByTitle,
    removeLessonFromList,
    generateExportUrl,
    importLesson,
    importPublicLesson,
    copyLesson,
    getEndDateOfLesson,
    getStudentAvailability,
    getAssignedLessons,
    lessonStatusChange,
    lessonColorChange,
    previewLesson,
    removeAssignedStudentFromLessonList,
    unassignBook,
    getElectronicMediaName,
    publicLessonDetails,
    addReview,
    filterSearch,
    editStudentLessonColor,
    changeFavoriteStatus,
    getAddAssignmentDefaultData,
    addCustomType,
    editCustomType,
    deleteCustomType,
    addLessonTime,
    getCourseLessons,
    removeAllLessonsFetchedStatus,
    addResources,
    editNotepad,
    getAdHocAssignments,
    urlFind,
    saveLessonToList,
    saveLessonToListV1,
    getLessonByStudentId,
  });
  // const backendV2 = "https://hspbackendv2.homeschoolpanda.com/graphql";
  // const localV2 = "http://localhost:4000/graphql";
  function getLessonByStudentId(studentId) {
    const query = `query GetLessonByStudent($studentId:Int!) {
  getLessonByStudent(studentId:$studentId){
    id
    userId
    name
    isVisible
    lesson {
      id
      startDate
      endDate
      assignmentDays {
        name
      }
      studentId
      course {
        id
        name
        userId
        isVisible
      }
      lessonId
      days
      weeks
      duration
      name
      color
      isGroup
    }
  }
}`;
    let defer = $q.defer();
    let request = {
      headers: {
        authorization: "Bearer " + token,
      },
      method: "POST",
      url: graphqlUri,
      data: JSON.stringify({
        query,
        variables: { studentId: studentId },
      }),
    };

    $http(request)
      .then((response) => {
        let res = [];
        // console.log(response);
        if (response.data.data.getLessonByStudent.length) {
          const { getLessonByStudent } = response.data.data;
          getLessonByStudent.forEach((course) => {
            let mappedLessons = course.lesson.map((el) => {
              return {
                ...el,
                assignmentDays: el.assignmentDays.map((el) => el.name),
              };
            });
            res.push({
              ...course,
              lesson: mappedLessons,
            });
          });
        }
        return defer.resolve(res);
      })
      .catch((err) => defer.reject(err));
    return defer.promise;
  }

  function urlFind(str) {
    if (str) {
      let urlRegex = /(https?:\/\/[^\s]+)/g;
      return str.replace(urlRegex, (url) => {
        return '<a target=”_blank” href="' + url + '">' + url + "</a>";
      });
    } else {
      return str;
    }
  }
  function getAdHocAssignments(lessonId) {
    let defer = $q.defer();
    $http({
      method: "GET",
      url: basePath + "getAdHocAssignments/" + lessonId,
    })
      .then((response) => defer.resolve(response))
      .catch((err) => defer.reject(err));
    return defer.promise;
  }

  function addResources(resources) {
    let defer = $q.defer();
    $http({
      method: "POST",
      url: appConstants.baseUrl + "/api/students/assignments/addResources",
      data: resources,
    })
      .then(function (res) {
        defer.resolve(res);
      })
      .catch(function (err) {
        defer.reject(err);
      });
    return defer.promise;
  }
  function unassignBook(bookAssignId) {
    // api/books/unassignBook/90
    var defer = $q.defer();

    $http({
      method: "DELETE",
      url: appConstants.baseUrl + "/api/books/unassignBook/" + bookAssignId,
    })
      .then(function (resp) {
        if (resp.data.data.length) {
          let lessonId = resp.data.data[0].entityId;
          if (lessonId) {
            removeResourceFromLessonList(bookAssignId, lessonId);
          }
        }
        defer.resolve(resp);
      })
      .catch((err) => {
        defer.reject(err);
      });
    return defer.promise;
  }

  function removeResourceFromLessonList(bookAssignId, lessonId) {
    let index2d = findLessonInList(lessonId);

    if (!~index2d) {
      return false;
    }

    let resourseIndex = _.findIndex(
      groupedLessonByCourse[index2d[0]].lesson[index2d[1]].books,
      { assignId: bookAssignId }
    );
    // let resourseIndex = 0;

    if (resourseIndex >= 0) {
      resourseIndex = commonService.convertToInteger(resourseIndex);
      groupedLessonByCourse[index2d[0]].lesson[index2d[1]].books.splice(
        resourseIndex,
        1
      );
    }

    return false;
  }

  function getLessonsByTitle(lessonTitle) {
    let deferred = $q.defer(),
      getLessonsPromise;

    if (groupedLessonByTitle.length && allLessonsFetched) {
      deferred.resolve(groupedLessonByTitle);
    } else {
      allLessonsFetched = true;
      getLessonsPromise = $http.get(basePath + "indexByTitle");
      getLessonsPromise
        .then((response) => {
          groupedLessonByTitle = response.data.data;
          deferred.resolve(groupedLessonByTitle);
        })
        .catch((response) => {
          deferred.reject(response.data);
        });
    }

    return deferred.promise;
  }

  function findLessonInList(lessonId) {
    let i,
      index = -1;
    for (i = groupedLessonByCourse.length - 1; ~i; --i) {
      index = commonService.findInArray(
        lessonId,
        "id",
        groupedLessonByCourse[i].lesson
      );
      if (~index) {
        break;
      }
    }
    return (!~index && index) || (~index && [i, index]);
  }

  function saveLessonToListV1(lessonId, lesson) {
    if (!lessonId) {
      return;
    }
    removeLessonFromList(lessonId);

    const newLessonList = [];
    const filteredCourse = groupedLessonByCourse.filter((el) => {
      if (el.id !== lesson.courseId) {
        newLessonList.push(el);
      }

      return el.id === lesson.courseId;
    });

    if (filteredCourse.length) {
      filteredCourse[0].name = lesson.course.name;
      filteredCourse[0].lesson.unshift(lesson);
      newLessonList.unshift(filteredCourse[0]);

      groupedLessonByCourse = angular.copy(newLessonList);
    }
  }

  function saveLessonToList(lessonId, lesson) {
    let index = commonService.findInArray(
      lesson.courseId,
      "id",
      groupedLessonByCourse
    );

    if (!~index) {
      groupedLessonByCourse.unshift(lesson.course);
      index = groupedLessonByCourse.length - 1;
    }
    if (~index) {
      groupedLessonByCourse[index].lesson = angular.isArray(
        groupedLessonByCourse[index].lesson
      )
        ? groupedLessonByCourse[index].lesson
        : [];
      return commonService.saveInArray(
        lessonId,
        "id",
        groupedLessonByCourse[index].lesson,
        lesson
      );
    }

    return false;
  }

  function removeAssignedStudentFromLessonList(studentLesson) {
    let index2d = findLessonInList(studentLesson.lessonId);
    if (!~index2d) {
      return false;
    }

    // // groupedLessonByCourse[index2d[0]].lesson[index2d[1]].students = angular.isArray(groupedLessonByCourse[index2d[0]].lesson[index2d[1]].students) ? groupedLessonByCourse[index2d[0]].lesson[index2d[1]].students : [];

    let studentIndex = _.findIndex(
      groupedLessonByCourse[index2d[0]].lesson[index2d[1]].students,
      { studentLessonPlanId: studentLesson.studentLessonPlanId }
    );
    // let studentIndex = 0;

    if (studentIndex >= 0) {
      studentIndex = commonService.convertToInteger(studentIndex);

      groupedLessonByCourse[index2d[0]].lesson[index2d[1]].students.splice(
        studentIndex,
        1
      );
    }
  }

  function addStudentToLesson(studentLesson) {
    let index2d = findLessonInList(studentLesson.lessonId);
    if (!~index2d) {
      return false;
    }

    groupedLessonByCourse[index2d[0]].lesson[index2d[1]].students =
      angular.isArray(
        groupedLessonByCourse[index2d[0]].lesson[index2d[1]].students
      )
        ? groupedLessonByCourse[index2d[0]].lesson[index2d[1]].students
        : [];
    let studentIndex = _.findIndex(
      groupedLessonByCourse[index2d[0]].lesson[index2d[1]].students,
      { studentId: studentLesson.studentId }
    );

    if (!~studentIndex) {
      groupedLessonByCourse[index2d[0]].lesson[index2d[1]].students.push({
        studentId: studentLesson.studentId,
        lessonId: studentLesson.lessonId,
        studentLessonPlanId: studentLesson.id,
      });
    }
  }

  function removeAllLessonsFetchedStatus() {
    allLessonsFetched = false;
  }

  /**
   * To get lessons using skip, limit, loadmore in Lesson Planner
   */
  function getLessons(skip, limit, loadMore, fetchArchiveLessons = false) {
    let deferred = $q.defer(),
      getLessonsPromise;

    if (!page) {
      page = 1;
    }
    allLessonsFetched = false;
    if (
      groupedLessonByCourse.length &&
      allLessonsFetched &&
      !fetchArchiveLessons
    ) {
      deferred.resolve({
        lessons: groupedLessonByCourse,
        loadMore: loadMoreCourseStatus,
      });
    } else {
      allLessonsFetched = true;
      getLessonsPromise = $http.get(
        basePath +
          "indexLite" +
          "?skip=" +
          skip +
          "&limit=" +
          limit +
          "&fetchArchiveLessons=" +
          fetchArchiveLessons
      );
      getLessonsPromise
        .then((response) => {
          /**
           * Load more lesson logic
           */
          // if (groupedLessonByCourse.length > 0 && fetchArchiveLessons == false) {
          //     groupedLessonByCourse = groupedLessonByCourse.concat(response.data.data.lessons);
          // } else {
          //     groupedLessonByCourse = response.data.data.lessons;
          // }

          /**
           * Check if archive lessons are fetched then next time we need to fetch all lessons
           */
          if (fetchArchiveLessons) {
            allLessonsFetched = false;
          }

          groupedLessonByCourse = response.data.data.lessons;

          loadMoreCourseStatus = response.data.data.loadMore;

          deferred.resolve(response.data.data);
        })
        .catch((response) => {
          deferred.reject(response.data);
        });
    }

    return deferred.promise;
  }

  /**
   * To fetch all lessons.
   * Without Skip, Limit, loadmore.
   */
  function getAllLessons() {
    let deferred = $q.defer(),
      getLessonsPromise;

    allLessonsFetched = true;
    getLessonsPromise = $http.get(basePath + "index");
    getLessonsPromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function viewLesson(lessonId) {
    let deferred = $q.defer(),
      lessonViewPromise;

    let index2d = findLessonInList(lessonId);

    if (~index2d) {
      // lessonViewPromise = $http.get(basePath + 'getAssignmentDefaultData' + '?lessonId=' + lessonId);
      lessonViewPromise = $http.get(basePath + "view/" + lessonId);
      lessonViewPromise.then((response) => {
        let defaultAssignmentData = response.data.data;
        let editLesson = groupedLessonByCourse[index2d[0]].lesson[index2d[1]];

        editLesson = defaultAssignmentData;
        // editLesson.lessonAssignment = defaultAssignmentData.lessonAssignment;
        deferred.resolve(editLesson);
      });
    } else {
      lessonViewPromise = $http.get(basePath + "view/" + lessonId);
      lessonViewPromise
        .then((response) => {
          let lesson = response.data.data;
          saveLessonToList(lesson.id, lesson);
          deferred.resolve(lesson);
        })
        .catch((response) => {
          // console.log(response);
          deferred.reject(response.data.message);
        });
    }

    return deferred.promise;
  }

  function getElectronicMediaName() {
    let deferred = $q.defer();
    let electronicResource = [];

    $http.get(basePath + "resourceElectronicMediaName/").then((response) => {
      electronicResource = response.data.data;
      deferred.resolve(electronicResource);
    });
    return deferred.promise;
  }

  function removeLessonFromList(lessonId) {
    var flags = {};
    groupedLessonByCourse = groupedLessonByCourse.filter(function (
      groupLesson
    ) {
      if (flags[groupLesson.id]) {
        return false;
      }
      flags[groupLesson.id] = true;
      return true;
    });

    for (let i = groupedLessonByCourse.length - 1; ~i; --i) {
      let oldlength = groupedLessonByCourse[i].lesson.length;
      commonService.removeFromArray(
        lessonId,
        "id",
        groupedLessonByCourse[i].lesson
      );

      if (oldlength !== groupedLessonByCourse[i].lesson.length) {
        !groupedLessonByCourse[i].lesson.length &&
          groupedLessonByCourse.splice(i, 1);
        return true;
      }
    }
    return false;
  }

  function changeCourseVisibility(courseId, visibilityStatus) {
    let deferred = $q.defer(),
      getLessonsPromise;

    getLessonsPromise = $http({
      method: "POST",
      url: basePath + "changeCourseVisibility",
      data: {
        courseId: courseId,
        visibilityStatus: visibilityStatus,
      },
    });

    getLessonsPromise
      .then(function (resp) {
        deferred.resolve(resp.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });
    return deferred.promise;
  }

  function addReview(lessonId, ratedLesson) {
    var defer = $q.defer();

    $http({
      method: "POST",
      url: basePath + "review",
      //data: { bookId, studentId: student.studentId, comment: student.comment, rating: student.rating, isRead: student.isRead }
      data: { lessonId, ratedLesson },
    })
      .then(function (resp) {
        defer.resolve(resp);
      })
      .catch((err) => {
        defer.reject(err);
      });
    return defer.promise;
  }

  function filterSearch(filterData) {
    var defer = $q.defer();

    $http({
      method: "POST",
      url: basePath + "filterSearch",
      //data: { bookId, studentId: student.studentId, comment: student.comment, rating: student.rating, isRead: student.isRead }
      data: {
        grade: filterData.grade,
        weeks: filterData.weeks,
        days: filterData.days,
        rating: filterData.rating,
      },
    })
      .then(function (resp) {
        defer.resolve(resp);
      })
      .catch((err) => {
        defer.reject(err);
      });
    return defer.promise;
  }

  function onDeleteConfirm(
    lessonId,
    deleteAssigned = false,
    isDelete = true,
    archivedStatus = false
  ) {
    lessonId = commonService.convertToInteger(lessonId);
    deleteAssigned = commonService.convertToInteger(deleteAssigned);
    if (!lessonId) {
      return $q.reject("Some error occurred please try again later.");
    }

    let deferred = $q.defer(),
      lessonDeletePromise;

    if (isDelete) {
      lessonDeletePromise = $http({
        method: "DELETE",
        url: basePath + "delete/" + lessonId,
        params: { deleteAssignedLesson: deleteAssigned },
      });
    } else {
      lessonDeletePromise = $http({
        method: "POST",
        url: basePath + "archive",
        data: {
          lessonId: lessonId,
          isArchived: archivedStatus,
        },
      });
    }

    lessonDeletePromise
      .then((response) => {
        removeLessonFromList(lessonId);
        deferred.resolve(response.data.message);
      })
      .catch((response) => {
        deferred.reject(response.data.message);
      });

    return deferred.promise;
  }

  function archiveLesson(lessonId, isArchived) {
    let deferred = $q.defer(),
      confirmData = {
        message: isArchived
          ? lessonConstants.messages.archiveLessonConfirmation
          : lessonConstants.messages.unarchiveLessonConfirmation,
        type: "danger",
        modalSize: "md",
        prompt: false,
        promptMessage: isArchived
          ? lessonConstants.messages.archiveAssignedLessonConfirmation
          : lessonConstants.messages.unarchiveAssignedLessonConfirmation,
      };

    commonService.confirm(confirmData).then((confirmation) => {
      if (!confirmation.result) {
        return $q.reject();
      }
      let deleteAssigned = confirmation.isChecked;
      onDeleteConfirm(lessonId, isArchived, false, isArchived)
        .then((response) => {
          toastService.toast({
            message: response,
            type: "success",
            delay: 2000,
          });
          return deferred.resolve(groupedLessonByCourse);
        })
        .catch((response) => {
          toastService.toast({
            message: response,
            type: "error",
            delay: 2000,
          });
          return deferred.reject(response);
        });
    });

    return deferred.promise;
  }

  function deleteLesson(lessonId, deleteAssigned = true) {
    let deferred = $q.defer();
    // let deleteAssigned = true;
    //console.log("removed", groupedLessonByCourse);
    onDeleteConfirm(lessonId, deleteAssigned, true, false)
      .then((response) => {
        toastService.toast({
          message: response,
          type: "success",
          delay: 2000,
        });
        return deferred.resolve(groupedLessonByCourse);
      })
      .catch((response) => {
        toastService.toast({
          message: response,
          type: "error",
          delay: 2000,
        });
        return deferred.reject(response);
      });

    return deferred.promise;
  }

  function changeFavoriteStatus(lessonId, favStatus) {
    let deferred = $q.defer(),
      savePromise,
      options,
      data;

    data = {
      lessonId: lessonId,
      favStatus: favStatus,
    };

    options = {
      method: "POST",
      data: data,
      url: basePath + "changeFavoriteStatus",
    };

    savePromise = $http(options);
    savePromise
      .then((response) => {
        let lesson = response.data.data;

        deferred.resolve(lesson);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function getCourseLessons(course) {
    let deferred = $q.defer(),
      getLessonsPromise;

    getLessonsPromise = $http.get(
      basePath + "getCourseLessons" + "?course=" + course
    );
    getLessonsPromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function getAddAssignmentDefaultData(lessonId = 0) {
    let deferred = $q.defer(),
      getLessonsPromise;

    allLessonsFetched = true;
    getLessonsPromise = $http.get(
      basePath + "getAssignmentDefaultData" + "?lessonId=" + lessonId
    );
    getLessonsPromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function addCustomType(customType, lessonId = 0) {
    var defer = $q.defer();

    $http({
      method: "POST",
      url: basePath + "addAssignmentType",
      data: { customType: customType, lessonId: lessonId },
    })
      .then(function (resp) {
        defer.resolve(resp.data);
      })
      .catch((err) => {
        defer.reject(err);
      });
    return defer.promise;
  }

  function editCustomType(customType, lessonId = 0, assignmentTypeId = 0) {
    var defer = $q.defer();

    $http({
      method: "POST",
      url: basePath + "editAssignmentType",
      data: {
        customType: customType,
        lessonId: lessonId,
        assignmentTypeId: assignmentTypeId,
      },
    })
      .then(function (resp) {
        defer.resolve(resp.data);
      })
      .catch((err) => {
        defer.reject(err);
      });
    return defer.promise;
  }

  function deleteCustomType(assignmentTypeId = 0) {
    var defer = $q.defer();

    $http({
      method: "POST",
      url: basePath + "deleteAssignmentType",
      data: { assignmentTypeId: assignmentTypeId },
    })
      .then(function (resp) {
        defer.resolve(resp.data);
      })
      .catch((err) => {
        defer.reject(err);
      });
    return defer.promise;
  }

  function lessonColorChange(lessonId, color) {
    let deferred = $q.defer(),
      savePromise,
      options,
      data;

    data = {
      lessonId: lessonId,
      color: color,
    };

    options = {
      method: "POST",
      data: data,
      url: basePath + "changeLessonColor",
    };

    savePromise = $http(options);

    savePromise
      .then((response) => {
        let lesson = response.data.data;
        deferred.resolve(lesson);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function lessonStatusChange(
    lessonId,
    ispublic,
    status,
    userId,
    requestFromAdmin
  ) {
    let deferred = $q.defer(),
      savePromise,
      options,
      data;

    data = {
      lessonId: lessonId,
      isPublic: ispublic,
      status: status,
      userId: userId,
      requestFromAdmin: requestFromAdmin,
      // grade: grade,
      // price: price,
      // description: description
    };

    options = {
      method: "POST",
      data: data,
      url: basePath + "changeLessonStatus",
    };

    savePromise = $http(options);

    savePromise
      .then((response) => {
        let lesson = response.data.data;

        deferred.resolve(lesson);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function publicLessonDetails(lesson) {
    let deferred = $q.defer(),
      savePromise,
      options,
      data;

    data = {
      lessonId: lesson.id,
      //isPublic: lesson.isPublic,
      grade: lesson.selectedGrades,
      //price: lesson.price,
      description: lesson.description,
    };

    options = {
      method: "POST",
      data: data,
      url: basePath + "publicLessonDetails",
    };

    savePromise = $http(options);

    savePromise
      .then((response) => {
        let lesson = response.data.data;

        deferred.resolve(lesson);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function previewLesson(exportId, lessonId, request, userId) {
    let deferred = $q.defer(),
      savePromise,
      options,
      data;
    let queryParams = {
      lessonId: lessonId,
      request: request,
      userId: userId,
    };

    if (!exportId && !lessonId && !request) {
      return;
    }

    options = {
      method: "get",
      data: data,
      url: basePath + "viewPublicLesson?exportId=" + exportId,
      params: queryParams,
    };

    savePromise = $http(options);

    savePromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function addLessonTime(lesson) {
    let deferred = $q.defer(),
      savePromise,
      options;
    options = {
      method: "POST",
      url: basePath + "editTime",
      data: { lesson },
      params: { timezone: -new Date().getTimezoneOffset() },
    };

    savePromise = $http(options);

    savePromise
      .then((response) => {
        let lesson = response.data.data;
        // saveLessonToList(lesson.id, lesson);
        deferred.resolve(lesson);

        /**
         * Lesson fetched reset
         */
        allLessonsFetched = false;
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function saveLesson(data, lessonId) {
    let deferred = $q.defer(),
      savePromise,
      options,
      deviceType = "BROWSER";
    options = {
      method: "POST",
      data: data,
      params: { timezone: -new Date().getTimezoneOffset() },
    };

    // The lessonId parameter decides, if lesson is being updated, or created
    options.url = commonService.convertToInteger(lessonId)
      ? basePath +
        `edit/` +
        lessonId +
        `?change=${data.change}` +
        `&deviceType=${deviceType}`
      : basePath + "add";
    savePromise = $http(options);
    savePromise
      .then((response) => {
        let lesson = response.data.data;
        saveLessonToList(lesson.id, lesson);
        deferred.resolve(lesson);

        /**
         * Lesson fetched reset
         */
        allLessonsFetched = false;
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function saveAssignmentToLesson(data, lessonId) {
    let deferred = $q.defer(),
      savePromise,
      options;
    options = {
      method: "POST",
      data: data,
      params: { timezone: -new Date().getTimezoneOffset() },
    };

    // The lessonId parameter decides, if lesson is being updated, or created
    options.url = commonService.convertToInteger(lessonId)
      ? basePath + `addAssignmentToLesson/` + lessonId
      : basePath + "add";
    savePromise = $http(options);
    savePromise
      .then((response) => {
        let lesson = response.data.data;

        // saveLessonToList(lesson.id, lesson);
        deferred.resolve(lesson);

        /**
         * Lesson fetched reset
         */
        allLessonsFetched = false;
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function generateExportUrl(lessonId) {
    let deferred = $q.defer(),
      lesson,
      generateExportUrlPromise;

    lessonId = commonService.convertToInteger(lessonId);

    // Check if the lesson exists
    let index2d = findLessonInList(lessonId);
    if (~index2d) {
      lesson = groupedLessonByCourse[index2d[0]].lesson[index2d[1]];
      // If the lesson already has a exportId, then return the exportId
      if (lesson.exportUrl) {
        deferred.resolve(lesson);
        return deferred.promise;
      }
    }

    generateExportUrlPromise = $http.get(
      basePath + "generateExportUrl/" + lessonId
    );
    generateExportUrlPromise
      .then((response) => {
        let lesson = response.data.data;
        saveLessonToList(lesson.id, lesson);
        deferred.resolve(lesson);
      })
      .catch((response) => {
        deferred.reject(response.data.message);
      });
    return deferred.promise;
  }

  function importPublicLesson(lessonId, url) {
    let deferred = $q.defer(),
      importPublicLessonPromise;
    importPublicLessonPromise = $http({
      method: "POST",
      url: basePath + "importPublicLesson",
      data: { lessonId: lessonId, exportId: url },
    });
    importPublicLessonPromise
      .then((response) => {
        let lesson = response.data.data;
        saveLessonToList(lesson.id, lesson);
        deferred.resolve(lesson);
      })
      .catch((response) => {
        deferred.reject(response.data.message);
      });

    return deferred.promise;
  }

  function copyLesson(lessonId, url) {
    let deferred = $q.defer(),
      importPublicLessonPromise;
    importPublicLessonPromise = $http({
      method: "POST",
      url: basePath + "copyLesson",
      data: { lessonId: lessonId, exportId: url },
    });
    importPublicLessonPromise
      .then((response) => {
        let lesson = response.data.data;
        saveLessonToList(lesson.id, lesson);
        deferred.resolve(lesson);
      })
      .catch((response) => {
        console.error(response);
        deferred.reject(response.data.message);
      });

    return deferred.promise;
  }

  function editStudentLessonColor(studentLessonId, color) {
    let deferred = $q.defer(),
      studentLessonPromise;
    studentLessonPromise = $http({
      method: "POST",
      url: appConstants.baseUrl + "/api/students/lessons/colorChange",
      data: {
        lesson: { studentLessonId: studentLessonId, color: color },
      },
    });
    studentLessonPromise
      .then((response) => {
        let lesson = response.data.data;
        // saveLessonToList(lesson.id, lesson);
        deferred.resolve(lesson);
      })
      .catch((response) => {
        console.error(response);
        deferred.reject(response.data.message);
      });

    return deferred.promise;
  }

  function importLesson(url) {
    let deferred = $q.defer(),
      importLessonPromise;

    if (!url) {
      deferred.reject("URL not found");
    } else {
      importLessonPromise = $http({
        method: "POST",
        url: basePath + "import",
        data: { url },
      });
      importLessonPromise
        .then((response) => {
          let lesson = response.data.data;
          saveLessonToList(lesson.id, lesson);
          deferred.resolve(lesson);
        })
        .catch((response) => {
          console.error(response);
          deferred.reject(response.data.message);
        });
    }
    return deferred.promise;
  }

  function assignStudent(lessonId, data) {
    let deferred = $q.defer(),
      assignStudentPromise;

    assignStudentPromise = $http({
      method: "POST",
      url: appConstants.baseUrl + "/api/students/lessons/assignToStudent",
      params: { timezone: -new Date().getTimezoneOffset() },
      data: data,
    });

    assignStudentPromise
      .then((response) => {
        let data = response.data.data;
        addStudentToLesson(data);
        deferred.resolve(data);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function getAssignedStudent(lessonId) {
    let deferred = $q.defer(),
      assignedStudentPromise;

    assignedStudentPromise = $http.get(
      basePath + "getAssignedStudents/" + lessonId
    );
    assignedStudentPromise
      .then((response) => {
        let students = [],
          responseData = [];
        if (response && response.data && response.data.data) {
          responseData = response.data.data;
          students = _.map(responseData, (obj) => obj.studentId);
          students = studentService.getStudentsById(students);
        }
        deferred.resolve([students, responseData]);
      })
      .catch((response) => {
        deferred.reject(response.data.message);
      });
    return deferred.promise;
  }

  function getAssignedLessons(lessonId) {
    let deferred = $q.defer(),
      assignedLessonsPromise;

    assignedLessonsPromise = $http.get(
      basePath + "getAssignedLessons/" + lessonId
    );
    assignedLessonsPromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((response) => {
        deferred.reject(response.data.message);
      });
    return deferred.promise;
  }

  function getPublicLessons(
    lessonTitle,
    filterData,
    pageNumber = 1,
    requestFromAdmin
  ) {
    let deferred = $q.defer(),
      publicLessonsPromise;

    publicLessonsPromise = $http({
      method: "GET",
      url: basePath + "getPublicLessons",
      params: {
        searchBy: lessonTitle,
        page: pageNumber,
        requestFromAdmin: requestFromAdmin,
        grade: filterData.grade,
        weeks: filterData.weeks,
        days: filterData.days,
        offset: filterData.offset,
      },
    });

    publicLessonsPromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((response) => {
        deferred.reject(response.data.message);
      });
    return deferred.promise;
  }

  function getEndDateOfLesson(
    totalDays,
    startDate,
    totalWeeks,
    daysPerWeek,
    startTime,
    duration
  ) {
    let daysResult = [0, 0, 0, 0, 0, 0, 0];
    let maxDay = 0,
      lastDayOfWeek = 0;

    for (let i = 0; i < totalDays.length; i++) {
      if (totalDays[i] in days) {
        daysResult[days[totalDays[i]]] = 1;
      }
    }

    let endOfWeekDays = daysPerWeek,
      dayOfStart = moment(startDate).day();

    _.each(daysResult, (day, index) => {
      if (day) {
        maxDay = maxDay > index ? maxDay : index;
        if (index >= dayOfStart) {
          endOfWeekDays--;
        }
      }
    });

    if (endOfWeekDays <= 0) {
      lastDayOfWeek = maxDay;
      totalWeeks--;
    } else {
      let offsetWeekDay = endOfWeekDays - 1;
      _.each(daysResult, (day, index) => {
        if (day) {
          if (!offsetWeekDay) {
            lastDayOfWeek = index;
          }
          offsetWeekDay--;
        }
      });
    }

    duration = duration.split(":");

    let endDate = moment(startDate, "Y-MM-DD")
      .add(totalWeeks, "weeks")
      .day(lastDayOfWeek)
      .hour(moment(startTime, "HH:mm").hour())
      .minute(moment(startTime, "HH:mm").minute())
      .add(commonService.convertToInteger(duration[0]), "hour")
      .add(commonService.convertToInteger(duration[1]), "minute")
      .format("MM/DD/Y");
    return endDate;
  }

  function getStudentAvailability(
    totalDays,
    startDate,
    totalWeeks,
    daysPerWeek,
    startTime,
    duration,
    studentId,
    timezone
  ) {
    let data = {
      totalDays,
      startDate,
      totalWeeks,
      daysPerWeek,
      startTime,
      duration,
      studentId,
      timezone: new Date().getTimezoneOffset(),
    };

    return $http({
      method: "POST",
      url:
        appConstants.baseUrl + "/api/students/lessons/checkStudentAvailability",
      data: data,
    });
  }
  function editNotepad(notepadData) {
    let defer = $q.defer();
    $http({
      method: "PUT",
      url: appConstants.baseUrl + "/api/students/lessons/editNotepad",
      data: notepadData,
    })
      .then(function (res) {
        defer.resolve(res);
      })
      .catch(function (err) {
        defer.reject(err);
      });
    return defer.promise;
  }
}
export default lessonService;
