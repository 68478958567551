let angular = window.angular;

viewAppointmentController.$inject = [
  "$uibModalInstance",
  "appointmentService",
  "viewAppointmentParams",
  "moment",
  "$uibModal",
  "commonService",
  "$state",
  "_",
  "calendarConstants",
  "studentService",
  "friendService",
  "toastService",
  "$rootScope",
  "$q",
  "appConstants",
];

function viewAppointmentController(
  $uibModalInstance,
  appointmentService,
  viewAppointmentParams,
  moment,
  $uibModal,
  commonService,
  $state,
  _,
  calendarConstants,
  studentService,
  friendService,
  toastService,
  $rootScope,
  $q,
  appConstants
) {
  let vm = this;
  vm.appointment = {};
  vm.isFetchingData = false;
  vm.occurrenceId = viewAppointmentParams.occurrenceId;
  vm.appointmentId = viewAppointmentParams.appointmentId;
  vm.search = viewAppointmentParams.search;
  vm.comingFrom = viewAppointmentParams.comingFrom;
  vm.allDayEvent = false;
  vm.activeTab = "attendees";
  vm.modelName = "appointment";
  vm.category = "event";
  vm.statuses = ["Maybe", "Yes", "No"];
  vm.eventOrganizer = {};

  /////////////OLD working code chunk///////////////////

  // let students_array = _.map(studentService.students, (student, index) => {
  // 	index++;
  // 	return { type: 'student', id: student.id, displayName: student.name, name: student.name + ' (S' + index + ')', mediaUrl: student.mediaUrl || null };
  // });

  // let friends_array = _.map(friendService.getFriends(), (friend, index) => {
  // 	index++;
  // 	return { type: 'friend', id: friend.id, displayName: friend.name, name: friend.name + ' (F' + index + ')', mediaUrl: friend.mediaUrl || null };
  // });

  // vm.students = students_array.concat(friends_array);
  // vm.studentFriends = _.clone(vm.students);

  //////////////////////////////////////////////////////

  /////////////////////////////

  ////////For Family Helper
  let helpers_array = _.filter(
    studentService.students,
    (obj) => obj.role === "HELPER"
  );
  let family_helpers_array = _.filter(
    helpers_array,
    (obj) => obj.relation === "Family"
  );

  family_helpers_array = _.map(family_helpers_array, (helper, index) => {
    index++;
    return {
      type: "helper",
      id: helper.id,
      displayName: helper.name,
      name: helper.name + " (H" + index + ")",
      mediaUrl: helper.mediaUrl || null,
      refId: helper.id,
    };
  });
  //console.log(family_helpers_array);

  let students_array = _.map(studentService.students, (student, index) => {
    index++;
    return {
      type: "student",
      id: student.id,
      displayName: student.name,
      name: student.name + " (S" + index + ")",
      mediaUrl: student.mediaUrl || null,
      refId: student.studentUserId,
    };
  });

  let friends_array = _.map(friendService.getFriends(), (friend, index) => {
    index++;
    return {
      type: "friend",
      id: friend.id,
      displayName: friend.name,
      name: friend.name + " (F" + index + ")",
      mediaUrl: friend.mediaUrl || null,
    };
  });

  let std_helper_array = [];
  std_helper_array = students_array.concat(family_helpers_array);

  for (var i = 0, len = std_helper_array.length; i < len; i++) {
    for (var j = 0, len2 = friends_array.length; j < len2; j++) {
      if (std_helper_array[i].refId === friends_array[j].id) {
        friends_array.splice(j, 1);
        len2 = friends_array.length;
      }
    }
  }

  //console.log('Modified student & helper list is:', std_helper_array);
  //console.log('Modified only frineds list is:', friends_array);
  vm.students = [];
  if (vm.comingFrom != null && vm.comingFrom == "chore") {
    if (students_array.length > 0) {
      //only students & helpers
      vm.students = std_helper_array;
      //vm.students = students_array.concat(family_helpers_array);
    } else {
      //replacing studetns with friends
      vm.students = friends_array;
    }
  } else {
    if (students_array.length > 0) {
      //merging students with friends
      vm.students = std_helper_array.concat(friends_array);
    } else {
      //replacing studetns with friends
      vm.students = friends_array;
    }
  }

  //vm.students = students_array.concat(friends_array);

  vm.studentFriends = _.clone(vm.students);

  ////////////////////////////

  vm.selectedStudentFriends = [];

  vm.selectedAttendees = [];
  vm.remainigAttendees = [];
  angular.extend(vm, {
    close,
    deleteAppointment,
    deleteFutureAppointment,
    toggleTab,
    editAppointment,
    editChore,
    parseTime,
    selectStatus,
    getStatusClass,
    addStudentsTag,
    done,
    getStudentAvatar,
    markTodoChore,
  });

  function getStudentDetails(studentId) {
    let student = studentService.getStudentsById(studentId);
    return student[0] || {};
  }

  function markTodoChore(todoId, todoStatus) {
    if (!todoId) {
      return;
    }
    appointmentService.markTodoChore(todoId, todoStatus).then((res) => {});
    toastService.toast({
      message: todoStatus
        ? "Chore mark as completed!"
        : "Chore mark as not completed!",
      type: "success",
      delay: 3000,
    });
  }

  function getStudentAvatar(studentId) {
    return getStudentDetails(studentId).mediaUrl || appConstants.defaultAvatar;
  }

  function done() {
    let data = {};
    let appointment = {};

    for (let i = 0; i < vm.selectedAttendees.length; i++) {
      if (vm.selectedAttendees[i].type == undefined) {
        vm.selectedAttendees[i].type = "email";
        vm.selectedAttendees[i].id = vm.selectedAttendees[i].name;
      }
    }
    if (vm.oldSelectedAttendees) {
      appointment.attendees = vm.selectedAttendees.concat(vm.remainigAttendees);
    }

    data.appointment = appointment;

    let timezone = -1 * new Date().getTimezoneOffset();

    appointmentService
      .updateAttendees(vm.appointment.id, data, timezone)
      .then((response) => {
        toastService.toast({
          message: response.data.message,
          type: "success",
          delay: 8000,
        });
      })
      .catch((response) => {
        toastService.toast({
          message: "error.data.message",
          type: "error",
          delay: 3000,
        });
      })
      .finally(() => {
        close();
      });
  }

  function addStudentsTag(studentQuery) {
    /**
     * remove attendees from the vm,students list
     */
    vm.students = [];
    for (let i = 0; i < vm.studentFriends.length; i++) {
      let flag = false;
      for (let j = 0; j < vm.attendees.length; j++) {
        if (
          vm.studentFriends[i].type == vm.attendees[j].type &&
          vm.studentFriends[i].id == vm.attendees[j].id
        ) {
          flag = true;
          break;
        }
      }

      if (!flag) {
        vm.students.push(vm.studentFriends[i]);
      }
    }

    let newStudentList = [];
    let findStudent = _.map(vm.students, function (students) {
      if (_.includes(students.name.toLowerCase(), studentQuery.toLowerCase())) {
        newStudentList.push(students);
      }
    });

    var deferred = $q.defer();
    deferred.resolve(newStudentList);
    return deferred.promise;
  }

  function getStatusClass(status) {
    if (status == "Maybe") {
      return _.camelCase("notStarted");
    } else if (status == "No") {
      return _.camelCase("onHold");
    } else {
      return _.camelCase("complete");
    }
  }

  function changeStatusColor(id, status, oldStatus) {
    let element = document.getElementById(`studentAssignment-${id}`);
    element.classList.remove(_.camelCase(oldStatus));
    element.classList.add(_.camelCase(status));
  }

  function delEvent(status) {
    let confirmData = {
      message: "Event will be removed from calendar if you select 'Yes'.",
      type: "danger",
      modalSize: "md",
      confirmLabel: "Yes",
    };

    commonService.confirm(confirmData).then(function (confirmation) {
      if (confirmation.result) {
        updateEvent(status);
      }
    });
  }

  function updateEvent(status) {
    return appointmentService
      .changeStatus(status, vm.appointment.id)
      .then(function (response) {
        if (status == "no") {
          close({ refetchEvents: true, removeCalendarEvent: true });
        } else {
          let data = response.data.data;
          _.forEach(vm.appointmentDetails, (appointmentDetail, n) => {
            if (appointmentDetail.label == "Attendees") {
              _.forEach(appointmentDetail.value, (attendees, i) => {
                if (data[0].entityId == attendees.id) {
                  vm.appointmentDetails[n].value[i].rsvp = data[0].rsvp;
                }
              });
            }
          });
          toastService.toast({
            message: response.data.message,
            type: "success",
            delay: 3000,
          });
        }
      })
      .catch(function (error) {
        // console.log('ERROR', error);
      });
  }

  function selectStatus(status) {
    let oldStatus = vm.status;
    if (status !== "No") {
      vm.status = status;
    }

    if (status == "Yes") {
      status = "yes";
    } else if (status == "No") {
      status = "no";
      delEvent(status);
    } else {
      status = "maybe";
    }

    if (status !== "no") {
      updateEvent(status);
    }
  }

  function toggleTab(tab) {
    vm.activeTab = tab;
  }

  function close(data = {}) {
    angular.extend(data, {
      entityId: vm.appointment.id,
      model: "appointment",
      search: vm.search,
    });
    $uibModalInstance.close(data);
  }

  function parseTime(time) {
    if (time) {
      return moment(time, "hh:mm").format("hh:mm A");
    } else {
      return "";
    }
  }

  function deleteAppointment(occurrenceDelete) {
    let message = calendarConstants.messages.deleteConfirmation;
    message += !vm.repeatmessage
      ? " this appointment"
      : occurrenceDelete
      ? " this occurrence"
      : " all occurrences of this series";
    message += "?";
    let confirmData = {
      message: message,
      type: "danger",
      modalSize: "md",
      confirmLabel: "Yes",
    };

    commonService.confirm(confirmData).then(function (confirmation) {
      if (confirmation.result) {
        // remove-reminder-notification
        let removeNotificationsCriteria = {
          entityId: vm.appointment.id,
          model: "appointment",
        };

        if (occurrenceDelete) {
          removeNotificationsCriteria.meta = {
            occurrenceId: vm.occurrenceId,
          };
        }

        $rootScope.$broadcast(
          "remove-reminder-notification",
          removeNotificationsCriteria
        );

        return appointmentService
          .deleteAppointment(
            vm.appointment.id,
            vm.occurrenceId,
            occurrenceDelete
          )
          .then((response) => {
            if (!occurrenceDelete && vm.repeatmessage) {
              close({ refetchEvents: true, removeCalendarEvent: true });
              toastService.toast({
                message: calendarConstants.messages.removeSuccess,
                type: "success",
                delay: 800,
              });
            } else {
              close({
                removeEvent: true,
                occurrenceId: occurrenceDelete ? vm.occurrenceId : undefined,
              });
              toastService.toast({
                message: calendarConstants.messages.removeSuccess,
                type: "success",
                delay: 800,
              });
            }
          })
          .catch((error) => {
            // console.log(error);
          });
      }
    });
  }

  function deleteFutureAppointment(occurrenceDelete) {
    let message = calendarConstants.messages.deleteConfirmation;
    message += !vm.repeatmessage
      ? " this appointment"
      : occurrenceDelete
      ? " all future event's occurrence"
      : " all occurrences of this series";
    message += "?";
    let confirmData = {
      message: message,
      type: "danger",
      modalSize: "md",
      confirmLabel: "Yes",
    };

    commonService.confirm(confirmData).then(function (confirmation) {
      if (confirmation.result) {
        // remove-reminder-notification
        let removeNotificationsCriteria = {
          entityId: vm.appointment.id,
          model: "appointment",
        };

        if (occurrenceDelete) {
          removeNotificationsCriteria.meta = {
            occurrenceId: vm.occurrenceId,
          };
        }
        $rootScope.$broadcast(
          "remove-reminder-notification",
          removeNotificationsCriteria
        );

        return appointmentService
          .deleteFutureAppointment(vm.appointment.id, vm.startDate)
          .then((response) => {
            close({ refetchEvents: true, removeCalendarEvent: true });
            close({
              removeEvent: true,
              occurrenceId: occurrenceDelete ? vm.occurrenceId : undefined,
            });
            toastService.toast({
              message: calendarConstants.messages.removeSuccess,
              type: "success",
              delay: 800,
            });
          })
          .catch((error) => {
            // console.log(error);
          });
      }
    });
  }

  function editAppointment(editOccurrence) {
    let comingFrom = "event";
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "editAppointmentController",
      controllerAs: "editApptCtrl",
      template: require("../../pug/calendar/edit-appointment.pug").default,
      resolve: {
        editAppointmentParams: {
          appointmentId: vm.appointmentId,
          occurrenceId: vm.occurrenceId,
          editOccurrence: !!editOccurrence,
          search: vm.search,
          comingFrom,
        },
      },
    });
    close(modelInstance.result);
  }

  function editChore(editOccurrence) {
    let comingFrom = "chore";
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "editAppointmentController",
      controllerAs: "editApptCtrl",
      template:require("../../pug/calendar/edit-chore.pug").default ,
      resolve: {
        editAppointmentParams: {
          appointmentId: vm.appointmentId,
          occurrenceId: vm.occurrenceId,
          editOccurrence: !!editOccurrence,
          search: vm.search,
          comingFrom,
        },
      },
    });
    close(modelInstance.result);
  }

  function getAppointmentDetails() {
    vm.isFetchingData = true;
    let timezone = -1 * new Date().getTimezoneOffset();
    appointmentService
      .viewAppointment(vm.appointmentId, vm.occurrenceId, timezone)
      .then(function (response) {
        vm.reminders = [];
        let recurrence = response.recurrence || {},
          reminders = response.reminders || [];

        if (!_.isEmpty(recurrence)) {
          recurrence.endOfRecurrence = commonService.convertFromUTC(
            recurrence.endOfRecurrence
          );
          vm.repeatmessage = appointmentService.getRecurrenceString(
            recurrence,
            response.appointment.startDate
          );
        }

        if (!_.isEmpty(reminders)) {
          vm.reminders = _.map(reminders, function (reminder) {
            return appointmentService.getReminderOption(reminder);
          });
        }

        vm.multipleFiles = {
          toUpload: [],
          uploaded: response.media,
          toDelete: [],
        };

        vm.appointment = response.appointment;
        vm.oldAttendees = response.appointment.attendees;
        vm.oldSelectedAttendees = response.appointment.attendees;
        vm.isOwner = response.isOwner;
        vm.eventOrganizer = response.organizer;
        vm.attendees = vm.oldAttendees;
        vm.reminder;
        vm.status = response.status
          ? commonService.capitalizeFirstLetter(response.status)
          : "Maybe";

        /**
         * update category for the appointment
         */
        let statusSplit =
          response.appointment.category.split(/(?=[A-Z])/) || "Event";
        vm.category = "";
        for (let i = 0; i < statusSplit.length; i++) {
          vm.category = vm.category + " " + statusSplit[i];
        }

        let startDateTime, endDateTime;
        for (let i = 0; i < vm.students.length; i++) {
          let flag = false;
          for (let j = 0; j < vm.oldAttendees.length; j++) {
            if (
              vm.students[i].type == vm.oldAttendees[j].type &&
              vm.students[i].id == vm.oldAttendees[j].id
            ) {
              flag = true;
              break;
            }
          }
          if (flag) {
            vm.selectedAttendees.push(vm.students[i]);
          }
        }

        for (let i = 0; i < vm.oldAttendees.length; i++) {
          let flag = false;
          for (let j = 0; j < vm.selectedAttendees.length; j++) {
            if (
              vm.oldAttendees[i].type == vm.selectedAttendees[j].type &&
              vm.oldAttendees[i].id == vm.selectedAttendees[j].id
            ) {
              flag = true;
              break;
            }
          }
          if (!flag) {
            vm.remainigAttendees.push(vm.oldAttendees[i]);
          }
        }

        vm.appointmentDetails = [
          { label: "Title", value: vm.appointment.title || "" },
          {
            label: "Description",
            value: commonService.createLink(vm.appointment.description),
          },
        ];

        /**
         * For the daytime saving: ends 4th November and starts on 10th March
         * Check the assignment created date before 4th november
         * And assignmen is after 4th November
         * In that case, all day is 11:00 to 10:59
         */
        let firstOccurrence = vm.appointment.firstOccurrence || null;
        if (
          firstOccurrence &&
          moment(firstOccurrence).isDST() &&
          !moment(vm.appointment.startDate).isDST()
        ) {
          vm.appointment.startDate = moment(vm.appointment.startDate).add(
            1,
            "hours"
          );
          vm.appointment.endDate = moment(vm.appointment.endDate).add(
            1,
            "hours"
          );

          if (
            moment(vm.appointment.startDate).format("HH:mm") == "00:00" &&
            moment(vm.appointment.endDate).format("HH:mm") == "23:59"
          ) {
            vm.allDayEvent = true;
          }
        } else if (
          firstOccurrence &&
          !moment(firstOccurrence).isDST() &&
          moment(vm.appointment.startDate).isDST()
        ) {
          vm.appointment.startDate = moment(vm.appointment.startDate).subtract(
            1,
            "hours"
          );
          vm.appointment.endDate = moment(vm.appointment.endDate).subtract(
            1,
            "hours"
          );
          if (
            moment(vm.appointment.startDate).format("HH:mm") == "00:00" &&
            moment(vm.appointment.endDate).format("HH:mm") == "23:59"
          ) {
            vm.allDayEvent = true;
          }
        }

        startDateTime = commonService.convertFromUTC(vm.appointment.startDate);
        endDateTime = commonService.convertFromUTC(vm.appointment.endDate);

        vm.startDate = startDateTime.format("Y-MM-DD");
        vm.startTime = startDateTime.format("HH:mm");

        vm.endDate = endDateTime.format("Y-MM-DD");
        vm.endTime = endDateTime.format("HH:mm");

        if (vm.startTime === "00:00" && vm.endTime === "23:59") {
          vm.allDayEvent = true;
        }

        // vm.appointmentDetails.push({label: 'Location', value: })
        if (vm.allDayEvent) {
          if (
            moment(vm.appointment.startDate).format("L") ==
            moment(vm.appointment.endDate).format("L")
          ) {
            vm.appointmentDetails.push({
              label: "Date",
              value:
                moment(vm.appointment.startDate).format("L") + " (All Day)",
            });
          } else {
            vm.appointmentDetails.push({
              label: "Start",
              value:
                moment(vm.appointment.startDate).format("L") + " (All Day)",
            });
            vm.appointmentDetails.push({
              label: "End",
              value: moment(vm.appointment.endDate).format("L"),
            });
          }
        } else {
          vm.appointmentDetails.push({
            label: "Start",
            value: moment(vm.appointment.startDate).format("LT, L"),
          });
          vm.appointmentDetails.push({
            label: "End",
            value: moment(vm.appointment.endDate).format("LT, L"),
          });
        }

        vm.appointmentDetails.push({ label: "Attendees", value: vm.attendees });
        vm.appointmentDetails.push({
          label: "Repeats",
          value: vm.repeatmessage,
        });

        if (vm.reminders.length > 0) {
          for (let i = 0; i < vm.reminders.length; i++) {
            let index = i + 1;
            vm.appointmentDetails.push({
              label: "Remider " + index,
              value: vm.reminders[i].title + " before",
            });
          }
        }
      })
      .catch((response) => {
        console.error(response);
        close();
        $state.go("404", {}, { location: "replace" });
      })
      .finally(() => {
        vm.isFetchingData = false;
      });
  }

  this.$onInit = getAppointmentDetails;
}
export default viewAppointmentController;
