let angular = window.angular;

EditLessonConfirmationController.$inject = ["$uibModalInstance"];

function EditLessonConfirmationController($uibModalInstance) {
  let vm = this;
  // vm.changeOption = 'future';
  angular.extend(vm, {
    close,
    save,
  });

  function close() {
    $uibModalInstance.dismiss();
  }


  function save() {
    $uibModalInstance.close(vm.changeOption);
  }
}
export default EditLessonConfirmationController;
