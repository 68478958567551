// (function() {
//     'use strict';

//     angular.module('common')
//         .directive('hspFile', hspFile)
//         .controller('HspFileController', HspFileController);
let angular = window.angular;
export function hspFile() {
  let directive = {
    restrict: "E",
    template: require("../../../pug/components/hsp-files-view.pug").default,
    scope: {
      index: "@",
      file: "=",
      state: "=", //'view' or 'edit' or 'temp' or 'upload'  or 'static'
      type: "=", //'toUpload'
      removeFileCallback: "&",
      uploadFileCallback: "&",
      disableDelete: "@",
      assignmentIds: "<",
    },
    controller: "HspFileController",
    controllerAs: "hspFileCtrl",
  };

  return directive;
}

export function HspFileController($scope, fileService, $uibModal, $rootScope) {
  let vm = this;
  vm.file = $scope.file;
  vm.progress = 0;
  vm.startedUploading = false;

  vm.disableDelete = $scope.disableDelete === "true";

  let uploadInstance;
  vm.file.displayUrl = null;

  angular.extend(vm, {
    removeFile: removeFile,
    uploadAbort: uploadAbort,
    downloadFile: downloadFile,
    preview: preview,
  });

  activate();

  $scope.$on("upload-multiple-files", function (event, instanceId, modelName) {
    if ($scope.type == "toUpload" && !vm.startedUploading) {
      upload(instanceId, modelName);
    }
  });

  function activate() {
    if ($scope.state == "view") {
      vm.file.preview = false;
    }
    setDisplayUrl();
  }

  function removeFile() {
    if (
      vm.file.model &&
      vm.file.model == "studentassignment" &&
      $rootScope.user.userRole == "STUDENT"
    ) {
      return;
    }
    $scope.removeFileCallback({ fileState: $scope.state, index: $scope.index });
  }

  function setDisplayUrl() {
    let fileExtension;
    if (angular.isDefined(vm.file.meta)) {
      fileExtension = vm.file.meta.extension.toLowerCase();
    } else if (angular.isDefined(vm.file.name)) {
      fileExtension =
        "." +
        vm.file.name.slice(
          (Math.max(0, vm.file.name.lastIndexOf(".")) || Infinity) + 1
        );
      fileExtension = fileExtension.toLowerCase();
    } else {
      return;
    }

    _.forEach(fileService.fileTypeExtensions, function (extensions, type) {
      if (
        angular.isDefined(fileExtension) &&
        _.includes(extensions, fileExtension)
      ) {
        // vm.file.type = type;
        if (type == "image") {
          if ($scope.state == "temp" || $scope.state == "upload") {
            vm.fileView = vm.file;
          } else {
            vm.file.displayUrl = vm.file.mediaUrl;
            vm.file.preview = true;
          }
        } else if (type == "video") {
          if (vm.file.thumbUrl) {
            vm.file.displayUrl = vm.file.thumbUrl;
            vm.file.preview = true;
          }
          if (vm.file.mediaUrl) {
            vm.file.displayUrl = vm.file.mediaUrl + "-thumb";
          }
        } else {
          vm.file.displayUrl = "/images/file-types/" + type + ".png";
          if (type == "pdf") {
            vm.file.preview = true;
          }
          vm.fileView = null;
        }
      }
    });
  }

  function upload(instanceId, model) {
    vm.startedUploading = true;
    $scope.state = "upload";

    uploadInstance = fileService.uploadFile(
      vm.file,
      instanceId,
      model,
      $scope.assignmentIds
    );
    uploadInstance.then(uploadSuccess, uploadFailure, uploadInProgress);
  }

  function uploadInProgress(event) {
    vm.progress = parseInt((100.0 * event.loaded) / event.total);
  }

  function uploadSuccess(response) {
    uploadInstance = undefined;
    $scope.state = "view";
    $scope.type = "uploaded";
    vm.file = angular.isDefined(response.data.data[0])
      ? response.data.data[0]
      : vm.file;
    setDisplayUrl();
    $scope.uploadFileCallback({
      fileState: $scope.state,
      index: $scope.index,
      file: vm.file,
    });
  }

  function uploadFailure(error) {
    removeFile();
    uploadInstance = undefined;
  }

  function uploadAbort() {
    if (uploadInstance) {
      uploadInstance.abort();
    } else {
      removeFile();
    }
  }

  function downloadFile() {
    if ($scope.state == "view") {
      fileService.downloadFile(vm.file.hash);
    }
  }

  function preview() {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "hspFileViewModalController",
      controllerAs: "fileViewModalCtrl",
      template: require("../../../pug/components/hsp-file-view-modal.pug").default,
      resolve: {
        file: vm.file,
      },
    });
    // modelInstance.result.then(afterView);
    // window.open(vm.file.mediaUrl, '_blank');
  }
}
HspFileController.$inject = [
  "$scope",
  "fileService",
  "$uibModal",
  "$rootScope",
];
