onErrorSrc.$inject = [];

function onErrorSrc() {
  let directive = {
    link: function (scope, element, attrs) {
      element.bind("error", function () {
        if (attrs.src != attrs.onErrorSrc) {
          attrs.$set("src", attrs.onErrorSrc);
        }
      });
    },
  };

  return directive;
}

export default onErrorSrc;
