let angular = window.angular;
academicYearController.$inject = [
  "userService",
  "$scope",
  "appConstants",
  "$uibModalInstance",
  "commonService",
  "$state",
  "toastService",
  "$rootScope",
  "studentService",
  "$q",
];

function academicYearController(
  userService,
  $scope,
  appConstants,
  $uibModalInstance,
  commonService,
  $state,
  toastService,
  $rootScope,
  studentService,
  $q
) {
  let vm = this,
    isRequestSent = false;
  vm.isFetchingData = false;
  vm.viewAddAcademicInformation = false;
  vm.academics = {};
  vm.academicsList = [];
  let students_array = _.filter(
    studentService.students,
    (obj) => obj.role === "STUDENT"
  );
  students_array = _.map(students_array, (student, index) => {
    index++;
    return {
      type: "student",
      id: student.id,
      displayName: student.name,
      name: student.name + " (S" + index + ")",
      mediaUrl: student.mediaUrl || null,
    };
  });
  vm.students = [];
  /**
   * Check is student exists
   */
  if (students_array.length > 0) {
    vm.students = students_array;
  }

  angular.extend(vm, {
    addAcademicInformation,
    cancelAcademicInformation,
    addInformation,
    close,
    deleteAcademicInformation,
    editAcademicInformation,
    addStudentsTag,
  });

  function addStudentsTag(studentQuery) {
    // console.log("vm.students=====", vm.students);
    // if (!studentQuery) {
    //     // var deferred = $q.defer();
    //     // deferred.resolve(vm.students);
    //     return Promise.resolve(vm.students);
    // }
    // let newStudentList = [];
    // let findStudent = _.map(vm.students, function (students) {
    //     if (_.includes(students.name.toLowerCase(), studentQuery.toLowerCase())) {
    //         newStudentList.push(students);
    //     }
    // });
    // var deferred = $q.defer();
    // deferred.resolve(newStudentList);
    // return deferred.promise;
  }

  function defaultAcademicYear() {
    let termStartDate = moment().year() + "-01-01",
      termEndDate = moment().year() + "-12-31",
      midyear = moment().year() + "-06-01";

    if (moment().isBefore(midyear)) {
      termStartDate = moment().subtract(1, "years").year() + "-07-01";
      termEndDate = moment().year() + "-06-01";
    } else {
      termStartDate = moment().year() + "-07-01";
      termEndDate = moment().add(1, "years").year() + "-06-01";
    }
    vm.academics = {
      academicYearStart: termStartDate,
      academicYearEnd: termEndDate,
      academicDays: 180,
    };
  }

  function addAcademicInformation() {
    vm.viewAddAcademicInformation = true;
    defaultAcademicYear();
  }

  function cancelAcademicInformation() {
    vm.academics = {};
    vm.viewAddAcademicInformation = false;
  }

  function close(data = {}) {
    $uibModalInstance.close(data);
  }

  function editAcademicInformation(acad) {
    vm.academics = acad;
    vm.viewAddAcademicInformation = true;
  }

  function deleteAcademicInformation(acad) {
    let confirmData = {
      message: appConstants.messages.deleteAcademicDetailsWarning,
      modalSize: "md",
      type: "danger",
    };

    commonService.confirm(confirmData).then(function (confirmation) {
      if (confirmation.result) {
        userService
          .deleteAcademicYear(acad.id)
          .then((response) => {
            toastService.toast({
              message: "Academic year deleted Successfully!",
              type: "success",
              delay: 3000,
            });
          })
          .catch(function (error) {
            console.error(error);
            toastService.toast({
              message:
                error.data.message || "Please fill the form and try again",
              type: "error",
              delay: 4000,
            });
            $rootScope.loadingBtnComplete("save");
          })
          .finally(() => {
            loadAcademicYear();
          });
      }
    });
  }

  function addInformation() {
    if (!vm.form.$valid || isRequestSent) {
      return;
    }

    return userService
      .addAcademicYear(vm.academics)
      .then((response) => {
        toastService.toast({
          message: "Academic year added Successfully!",
          type: "success",
          delay: 3000,
        });
      })
      .catch((err) => {
        toastService.toast({
          message: err.data.message,
          type: "error",
          delay: 3000,
        });
        console.error(message);
      })
      .finally(() => {
        isRequestSent = false;
        vm.viewAddAcademicInformation = false;
        // $rootScope.loadingBtnComplete('save', 'Create');
        loadAcademicYear();
      });
  }

  function loadAcademicYear() {
    return userService
      .getAcademicYearData()
      .then((response) => {
        vm.academicsList = response.data;
      })
      .catch((err) => {
        toastService.toast({
          message: err.data.message,
          type: "error",
          delay: 3000,
        });
        console.error(message);
      })
      .finally(() => {
        vm.isFetchingData = true;
      });
  }

  function init() {
    loadAcademicYear();
  }

  this.$onInit = init;
}

export default academicYearController;
