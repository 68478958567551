
let angular = window.angular;

    bookAddController.$inject = ['toastService', 'lessonConstants', '$scope', 'bookService', 'appConstants', 'fileService', '$rootScope', '$q', '$uibModal'];

    function bookAddController(toastService, lessonConstants, $scope, bookService, appConstants, fileService, $rootScope, $q, $uibModal) {

        let vm = this;
        vm.book = {};
        vm.defaultBookAvatar = appConstants.defaultBookAvatar;
		vm.dateDuration = 0;
		vm.dueDate = null;
		vm.dateMessage = null;
		vm.inLesson = false;
		vm.independent = false;
		vm.withHelp = false;

		vm.shelfCategories=[];
		vm.selectedBookShelf=[];

        angular.extend(vm, {
            save,
            selectBookCover,
            isTouched,
			onDueDateChange,
			selectBookShelf,
			checkBookShelfCategory
        });

        this.$onInit = function() {
			vm.book.status = 'own';
			vm.book.dueDate = new Date();
			getBookShelfCategories();
        }

		function checkBookShelfCategory(bookShelfs, currentShelf){
			if(bookShelfs && currentShelf){
				let checkBookCategory=_.find(bookShelfs,obj=>obj.id===currentShelf.id);
				if(checkBookCategory){
					return true;
				}
			}
			return false;
		}
        function selectBookShelf(category){
        	// vm.selectedBookShelf=category;
			let findShelfCategory=_.find(vm.selectedBookShelf, obj=>obj.id===category.id);
        	if(findShelfCategory){
				vm.selectedBookShelf=_.without(vm.selectedBookShelf,findShelfCategory);
			}
        	else{
				vm.selectedBookShelf = vm.selectedBookShelf.concat(category);
			}

		}

		function getBookShelfCategories(){
			bookService.getBookShelfCategories()
				.then((response) => {
					vm.shelfCategories = response;
				})
				.catch((err) => {
					console.error(err);
				})
		}

        function selectBookCover(media) {
            return fileService.imageUploadWithCrop(media, 'book')
                .then(selectBookCoverSuccess)
                .catch(selectBookCoverError);
        }

        function selectBookCoverSuccess(response) {
            if (response.file) {
                vm.imageFile = response.file;
            }
        }

        function selectBookCoverError(error) {
            // console.log('Closed modal', error);
        }

        function save() {

            if (!vm.book.title) {
                toastService.toast({
                    message: lessonConstants.messages.completeBookTitle,
                    type: 'error',
                    delay: 4000
                });
                return;
            }

            vm.book.type = null;

			if(vm.inLesson) {
				vm.book.type = 'inLesson';
			}
			if(vm.independent && vm.book.type) {
				vm.book.type += ',readIndependent';
			} else if(vm.independent) {
				vm.book.type = 'readIndependent';
			}
			if(vm.withHelp && vm.book.type) {
				vm.book.type += ',readWithHelp';
			} else if(vm.withHelp) {
				vm.book.type = 'readWithHelp';
			}

			if(vm.selectedBookShelf){
				// vm.book.bookShelfId=vm.selectedBookShelf.id;
				vm.book.bookShelfCategory=vm.selectedBookShelf;
			}

            bookService.editBook(vm.book)
                .then(saveSuccess)
                .then(fileUploadResponse)
                .catch((err) => {
                    toastService.toast({
                        message: err,
                        type: 'success',
                        delay: 2000
                    });
                    // console.log("eerrr", err);
                })
                .finally(() => {
                    // $rootScope.goBack();
                    let modelInstance = $uibModal.open({
                        animation: true,
                        size: 'lg',
                        controller: 'bookAssignController',
                        controllerAs: 'bookAssignCtrl',
                        template:require('../../pug/book/assign-book.pug').default,
                        resolve: {
                            bookData: {
                                bookId: vm.bookObj.id,
                                book: vm.bookObj,
                                activeTab: 'students'
                            }
                        }
                    });

                    modelInstance.result.then(function() {
                        $rootScope.goBack();
                    }).catch(function() {
                        modelInstance.close();
                    });
                })
        }

        function fileUploadResponse(response) {
            return response;
        }

        function saveSuccess(response) {
            let deferred = $q.defer();
            vm.bookObj = response.data;
            let BookId = vm.bookObj.id;
            toastService.toast({
                message: response.message,
                type: 'success',
                delay: 2000
            });
            if (_.isObject(vm.imageFile) && _.isString(vm.imageFile.name) && (vm.imageFile.size > 0)) {
                $rootScope.loadingBtn('save', 'Uploading Picture');
                return fileService.uploadFile(vm.imageFile, BookId, 'book');
            } else {
                deferred.resolve();
            }

            return deferred.promise;
        }

        function getBookDetails() {
            return bookService.viewBook(bookId)
                .then(function(response) {
                    vm.book = response;
                    vm.studentsToAssign = response.students;
                    for (let i = 0; i < vm.studentsToAssign.length; i++) {
                        if (!vm.studentsToAssign[i].hasOwnProperty('comment')) {
                            vm.studentsToAssign[i].comment = '';
                            vm.studentsToAssign[i].rating = 0;
                        }
                    }
                })
                .catch(function(response) {
                    console.error(response);
                });
        }

		function onDueDateChange() {
			if (!vm.dueDate) {
				vm.dueDate = moment().format("Y-MM-DD");
			}

			vm.dueDate = moment(vm.dueDate, "Y-MM-DD")
				.add(vm.dateDuration, "day")
				.format("Y-MM-DD");
			let currentDate = moment(new Date());
			var isafter = moment(
				vm.dueDate
			).isAfter(currentDate);
			if(isafter){
				vm.dateMessage ="";
			}
			// vm.eventStartTime = moment(vm.eventStartTime, 'HH:mm').add(vm.timeDuration, 'seconds').format('HH:mm');
		}

        function isTouched(field) {
            return field.$touched || vm.form.$submitted;
        }
    }

export default bookAddController;
