lessonDurationFormatter.$inject = ["commonService"];
function lessonDurationFormatter(commonService) {
  function formatWeek(weeks) {
    weeks = commonService.convertToInteger(weeks);
    if (!weeks) {
      return;
    }
    return weeks + " Week" + (weeks > 1 ? "s" : "");
  }

  function formatDay(days) {
    days = commonService.convertToInteger(days);
    if (!days) {
      return;
    }
    return days + " Day" + (days > 1 ? "s" : "") + "/Week";
  }

  function formatDuration(duration) {
    let hours,
      minutes,
      regex = /^\d+:\d+$/;
    if (!duration || !regex.test(duration)) {
      return "";
    }
    duration = duration.split(":");
    hours = commonService.convertToInteger(duration[0]);
    minutes = commonService.convertToInteger(duration[1]);

    /**
     * if the time is 23:59 then it is for all day
     */
    if (hours % 24 == 23 && minutes == 59) {
      let count = hours / 24 + 1;
      let countMessage = "All";
      if (commonService.convertToInteger(count) > 1) {
        countMessage = commonService.convertToInteger(count);
      }
      return countMessage + " Day";
    }

    return (
      hours +
      " hr" +
      (hours > 1 ? "s" : "") +
      " " +
      minutes +
      " min" +
      (minutes > 1 ? "s" : "") +
      " "
    );
  }

  return function (weeks, days, duration) {
    if (!weeks || !days || !duration) {
      return "";
    }
    return (
      formatWeek(weeks) +
      " - " +
      formatDay(days) +
      " - " +
      formatDuration(duration)
    );
  };
}
export default lessonDurationFormatter;
