
let angular = window.angular;
websocketService.$inject = ['io', 'Store'];
function websocketService(io, Store) {

    const headers = {
        'Accept': 'application/json',
        'X-Access-Token': Store.get('authorizationToken')
    };
    io.sails.useCORSRouteToGetCookie = false;
    const socket = io.sails.connect({
        transports: ['websocket'],
        // url:"http://localhost:3000"
    });

    angular.extend(this, {
        socket,
        headers
    });
}

export default websocketService;
