let angular = window.angular;

lessonBookSearchController.$inject = [
  "toastService",
  "bookConstants",
  "$uibModal",
  "$scope",
  "$http",
  "$rootScope",
  "bookService",
  "commonService",
  "$uibModalInstance",
  "bookData",
];

function lessonBookSearchController(
  toastService,
  bookConstants,
  $uibModal,
  $scope,
  $http,
  $rootScope,
  bookService,
  commonService,
  $uibModalInstance,
  bookData
) {
  let vm = this;
  let KEY = "AIzaSyDVLpO_0FWnLWeM7X0kchNrjeVk3fz2gA8"; // Google  api key
  let fixed = "&key=" + KEY + "&maxResults=40";
  $scope.todos = [];
  $scope.books = null;
  vm.options = bookConstants.bookSearchOptions;
  vm.type = null;
  vm.data = null;
  vm.activeTab = "assigned-book";
  vm.isFetchingData = false;
  vm.loadMoreCount = 2;
  vm.loadMoreBookStatus = false;
  vm.loadMoreFetchingData = false;
  vm.loadingCheck = true;
  let book;
  vm.books = [];
  let finalBooks = [];
  let addedBooks = [];
  let checkedBookIds = [];

  vm.shelfCategories = [];
  vm.selectedBookShelf = null;
  vm.selectAllBooksCheck = false;

  this.$onInit = function () {
    vm.isFetchingData = true;
    checkedBookIds = bookData.bookIds || [];
    vm.activeTab = "explore";
    bookService
      .getUserBooks()
      .then((response) => {
        vm.isFetchingData = false;
        _.map(response.books, function (book) {
          if (_.includes(checkedBookIds, book.bookId)) {
            book["isSelected"] = true;
          } else {
            book["isSelected"] = false;
          }
          finalBooks.push(book);
          vm.books.push(book);
        });

        vm.loadMore = response.isLoadMore;
      })
      .catch((err) => {
        console.error(err);
      });
    bookService
      .getBookShelfCategories()
      .then((response) => {
        if (response && response.length) {
          vm.shelfCategories = response;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  angular.extend(vm, {
    isTouched,
    toggleTab,
    viewBook,
    close,
    search,
    addBook,
    manualAddBook,
    removeBook,
    loadMoreBooks,
    loadMoreUserBooks,
    edit,
    view,
    deleteBook,
    checkBook,
    done,
    changeImageUrl,
    selectBookShelf,
    getSeletedBooks,
    selectAllBooks,
  });

  function selectAllBooks() {
    vm.selectAllBooksCheck = !vm.selectAllBooksCheck;
    vm.books = _.map(vm.books, (book) => {
      if (vm.selectAllBooksCheck) {
        book.isSelected = true;
      } else {
        book.isSelected = false;
      }
      return book;
    });
  }

  function getSeletedBooks() {
    let booksCount = _.filter(vm.books, (obj) => obj.isSelected);
    return booksCount.length;
  }

  function selectBookShelf(category) {
    vm.selectedBookShelf = category;
    vm.selectAllBooksCheck = false;
    let currentTypeId = 0;
    if (vm.selectedBookShelf) {
      currentTypeId = vm.selectedBookShelf.id;
    }
    vm.isFetchingData = true;
    checkedBookIds = bookData.bookIds || [];
    finalBooks = [];
    vm.books = [];
    bookService
      .getUserBooks(null, currentTypeId)
      .then((response) => {
        vm.isFetchingData = false;
        _.map(response.books, function (book) {
          if (_.includes(checkedBookIds, book.bookId)) {
            book["isSelected"] = true;
          } else {
            book["isSelected"] = false;
          }
          finalBooks.push(book);
          vm.books.push(book);
        });

        vm.loadMore = response.isLoadMore;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function changeImageUrl(img) {
    if (img) {
      img = img.replace("http:", "https:");
    }
    return img;
  }

  function done() {
    let finalOutputBooks = selectBooks();

    $uibModalInstance.close(finalOutputBooks);
  }

  function selectBooks() {
    let outputBooks = [];
    let totalBooks = finalBooks.concat(vm.books);
    for (let i = 0; i < totalBooks.length; i++) {
      if (totalBooks[i].isSelected) {
        outputBooks.push(totalBooks[i]);
      }
    }
    let dummy = _.uniqBy(outputBooks, "bookId");

    return dummy;
  }

  function manualAddBook() {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "lessonBookAddController",
      controllerAs: "lessonBookAddCtrl",
      template: require("../../pug/lesson/add-book.pug").default,
    });
    modelInstance.result
      .then(function (book) {
        if (book) {
          let data = _.clone(book);
          vm.books.unshift(data);
        }
      })
      .catch(function () {
        modelInstance.close();
      });
  }

  function isTouched(field) {
    return field.$touched || vm.form.$submitted;
  }

  function close() {
    $uibModalInstance.close();
  }

  function checkBook(book) {
    addedBooks.push(book);
  }

  function addBook(book) {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "bookEditController",
      controllerAs: "bookEditCtrl",
      template: require("../../pug/book/edit-book.pug").default,
      resolve: {
        bookData: {
          book,
        },
      },
    });

    modelInstance.result.then(afterAddBook).catch(function () {
      modelInstance.close();
    });
  }

  function viewBook(book) {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "bookDetailController",
      controllerAs: "bookDetailCtrl",
      template: require("../../pug/book/view-book.pug").default,
      resolve: {
        bookData: {
          book: book,
          // model: 'search'
        },
      },
    });

    modelInstance.result.then(afterAddBook).catch(function () {
      modelInstance.close();
    });
  }

  function afterAddBook(options) {
    let addedBookId = options.book.bookId;
    let existingBookIndex = _.findIndex(vm.books, {
      bookId: addedBookId,
    });

    if (existingBookIndex > -1) {
      options.book.isSelected = true;
      vm.books[existingBookIndex] = options.book;
    }
  }

  function deleteBook(bookId) {
    commonService
      .confirm({
        message: bookConstants.messages.deleteBookConfirmation,
        type: "danger",
        modalSize: "md",
      })
      .then((confirmation) => {
        if (confirmation.result === true) {
          bookService
            .deleteBook(bookId)
            .then((deleted) => {
              toastService.toast({
                message: bookConstants.messages.bookDeleted,
                type: "success",
                delay: 3000,
              });

              let removeBookIndex = _.findIndex(vm.books, {
                id: bookId,
              });
              if (removeBookIndex > -1) {
                vm.books[removeBookIndex].id = undefined;
              }
            })
            .catch((err) => {
              console.log("errror", err);
            });
        } else return;
      })
      .catch((err) => {
        console.log("errror", err);
      });
  }

  function view(book, activeTab) {
    let bookId = book.id;
    if (!angular.isDefined(bookId) || !bookId) {
      viewBook(book);
    } else {
      let modelInstance = $uibModal.open({
        animation: true,
        size: "lg",
        controller: "bookAssignController",
        controllerAs: "bookAssignCtrl",
        template: require("../../pug/book/assign-book.pug").default,
        resolve: {
          bookData: {
            bookId,
            book,
            activeTab: activeTab,
          },
        },
      });

      modelInstance.result.then(afterView).catch(function () {
        modelInstance.close();
      });
    }
  }

  function afterView(options) {
    if (options) {
      if (!options.removeEvent) {
        let bookUpdateIndex = _.findIndex(vm.books, {
          bookId: options.book.bookId,
        });
        if (bookUpdateIndex > -1) {
          vm.books[bookUpdateIndex] = options.book;
        } else {
          vm.books.push(options);
        }
      } else {
        let removeBookId = options.bookData.bookId;
        let removeBookIndex = _.findIndex(vm.books, {
          id: removeBookId,
        });
        if (removeBookIndex > -1) {
          // vm.books.splice(removeBookIndex, 1);
          vm.books[removeBookIndex].id = undefined;
        }
      }
    }
  }

  function toggleTab(tab) {
    vm.activeTab = tab;
  }

  function search(queryParam) {
    vm.loadingCheck = false;
    let skip;
    vm.isFetchingData = true;

    if (!vm.data) {
      vm.isFetchingData = false;
      toastService.toast({
        message: "Please enter keyword to find the books",
        type: "error",
        delay: 3000,
      });

      return false;
    }

    if (!queryParam) {
      skip = 0;
      vm.books = [];
    } else {
      skip = queryParam.skip;
    }

    let query = {
      searchBy: vm.type.title,
      values: vm.data,
      skip: skip,
    };
    bookService
      .search(query)
      .then((resp) => {
        vm.loadMoreFetchingData = false;
        vm.isFetchingData = false;

        if (!resp.data) {
          toastService.toast({
            message: "There are no books under this criteria",
            type: "error",
            delay: 3000,
          });
          return false;
        }

        _.map(resp.data.data.books, function (book) {
          if (_.includes(checkedBookIds, book.bookId)) {
            book["isSelected"] = true;
          } else {
            book["isSelected"] = false;
          }
          vm.books.push(book);
          vm.books = _.uniqBy(vm.books, "bookId");
        });

        vm.loadMoreBookStatus = resp.data.data.isLoadMore;
      })
      .catch(() => {
        console.log("invalid request");
      })
      .finally(() => {
        vm.loadMoreFetchingData = false;
        vm.isFetchingData = false;
      });
  }

  function edit(book) {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "bookEditController",
      controllerAs: "bookEditCtrl",
      template: require("../../pug/book/edit-book.pug").default,
      resolve: {
        bookData: {
          book,
        },
      },
    });
    modelInstance.result.then(afterView).catch(function () {
      modelInstance.close();
    });
    // modelInstance.result.catch(function() {
    //     console.log("ebnter in bookconteoler"
    // })
  }

  function loadMoreBooks() {
    let queryParam;

    vm.loadMoreFetchingData = true;
    if (!vm.books.length) {
      queryParam = { skip: 0 };
    } else {
      queryParam = { skip: vm.books.length };
    }

    search(queryParam);
  }

  function loadMoreUserBooks() {
    vm.isFetchingData = true;
    checkedBookIds = bookData.bookIds || [];
    vm.activeTab = "explore";
    let queryParam;
    if (!vm.books.length) {
      queryParam = { skip: 0 };
    } else {
      queryParam = { skip: vm.books.length };
    }
    bookService
      .getUserBooks(queryParam)
      .then((response) => {
        vm.isFetchingData = false;
        _.map(response.books, function (book) {
          if (_.includes(checkedBookIds, book.bookId)) {
            book["isSelected"] = true;
          } else {
            book["isSelected"] = false;
          }
          if (vm.selectAllBooksCheck) {
            book["isSelected"] = true;
          }
          finalBooks.push(book);
          vm.books.push(book);
        });

        vm.loadMore = response.isLoadMore;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getTodos() {
    let getPromise;
    bookService.getTodos().then(function (response) {
      vm.saved_books = response.data;
    });
  }

  //  function addBook (book) {
  //     bookService.addBook(book)
  //         .then(function(response) {
  //             // $scope.todos.push($scope.formData)
  //             $scope.formData = {};
  //             $scope.todos = response;
  //             toastService.toast({
  //                 message: bookConstants.messages.bookAdded,
  //                 type: 'success',
  //                 delay: 3000
  //             });
  //             console.log(response);
  //         })
  //         .catch((err)=>{
  //             toastService.toast({
  //                 message: bookConstants.messages.bookAlready,
  //                 type: 'success',
  //                 delay: 3000
  //             });
  //         });
  //   }

  function removeBook(todo) {
    bookService.removeBook(todo).then(function (response) {
      $scope.todos.splice($scope.todos.indexOf(todo), 1);
    });
  }
}
export default lessonBookSearchController;
