let angular = window.angular;
lessonPreviewController.$inject = [
  "lessonData",
  "$uibModal",
  "$uibModalInstance",
  "$state",
  "lessonService",
  "commonService",
  "$timeout",
  "toastService",
  "studentService",
  "_",
  "appConstants",
  "lessonConstants",
];
function lessonPreviewController(
  lessonData,
  $uibModal,
  $uibModalInstance,
  $state,
  lessonService,
  commonService,
  $timeout,
  toastService,
  studentService,
  _,
  appConstants,
  lessonConstants
) {
  let vm = this;
  vm.isFetchingData = false;
  vm.isFetchingExportURL = false;

  angular.extend(vm, {
    toggleTab,
    showWeek,
    close,
    importPublicLesson,
  });

  function toggleTab(tab) {
    vm.lesson.activeTab = tab;
  }

  function showWeek(index) {
    return (
      !index ||
      vm.lesson.lessonAssignment[index - 1].week !==
        vm.lesson.lessonAssignment[index].week
    );
  }

  function close() {
    $uibModalInstance.close(true);
  }

  function importPublicLesson(lessonId, exportUrl) {
    lessonId = commonService.convertToInteger(lessonId);

    lessonService
      .importPublicLesson(lessonId, exportUrl)
      .then(() => {
        toastService.toast({
          message: lessonConstants.messages.lessonImported,
          type: "success",
          delay: 500,
        });
      })
      .catch((error) => {
        toastService.toast({
          message: error,
          type: "error",
          delay: 500,
        });
      });
  }

  this.$onInit = async function () {
    vm.isFetchingData = true;
    let activeTab = lessonData.activeTab;
    // console.log("data is", lessonData);
    let lessonId;
    let lessonPreviewPromise;

    if (!lessonData.adminPreview) {
      if (lessonData.exportId == undefined) {
        lessonId = lessonData.response.exportId;
      } else {
        lessonId = lessonData.exportId;
      }
      lessonPreviewPromise = lessonService.previewLesson(lessonId);
    } else {
      let exportId;
      let request;
      let userId;

      lessonId = lessonData.adminPreview.lessonId;
      exportId = lessonData.adminPreview.exportId;
      request = lessonData.adminPreview.request;
      userId = lessonData.adminPreview.userId;

      lessonPreviewPromise = lessonService.previewLesson(
        exportId,
        lessonId,
        request,
        userId
      );
    }
    lessonPreviewPromise
      .then((response) => {
        vm.lesson = response;
        vm.multipleFiles = {
          uploaded: vm.lesson.media || [],
        };
        if (activeTab && _.indexOf(lessonConstants.tabsList, activeTab) >= 0) {
          vm.lesson.activeTab = activeTab;
        } else {
          vm.lesson.activeTab = "assignment";
        }
        vm.isFetchingData = false;
      })
      .catch((error) => {
        toastService.toast({
          message: error,
          type: "error",
          delay: 2000,
        });
        vm.isFetchingData = false;
      });
  };
}
export default lessonPreviewController;
