let angular  = window.angular;
    gradebookModalController.$inject = ['$uibModalInstance', 'gradebookModalParams', 'assignmentService', '$state', '$uibModal', 'calendarService', '$rootScope', 'toastService', '$window'];

    function gradebookModalController($uibModalInstance, gradebookModalParams, assignmentService, $state, $uibModal, calendarService, $rootScope, toastService, $window) {

        let vm = this,
            returnData = {};
        vm.assignment = {};
        vm.lesson = {};
        vm.student = gradebookModalParams.student;
        vm.name = null;
        vm.statusChanged = false;
        vm.isEdit = false;

        vm.previousType ='';
        vm.gradeObtainedMarks = 0;
        vm.gradeTotalMarks = 0;
        vm.percentage = 0;

        vm.isStatusChanged = false;
        vm.newResources=[];
        vm.gradeType = gradebookModalParams.gradeType;
        vm.lessonId = gradebookModalParams.lessonId;
        vm.studentId = gradebookModalParams.studentId;
        vm.studentIndex = -1;
        vm.result = {graded: false, lessonId: 0};

        angular.extend(vm, {
            close,
            updateGrades,
            addGrade,
            calculatePercentage,
        });

        function updateGrades(id, grade,index) {
            let defaultMarks = {
                obtainedMarks: 0,
                totalMarks: 0,
                percentage: 0
            };

            vm.statusChanged = true;
            return assignmentService.gradeStudent(id, grade.id, defaultMarks)
                .then(function(response) {
                    init();
                    vm.assignment.status = 'complete';
                    vm.assignment.students[index].percentage=grade.minValue + " "+ grade.maxValue;

                    let newAssignment = calendarService.calendarDataTransform(response.data.data);
                    calendarService.saveEventToList(vm.assignment.id, 'studentAssignment', newAssignment);
                    Store.set('assignmentOptions', JSON.stringify(vm.statusChanged));
                    toastService.toast({
                        message: 'Grade updated successfully',
                        type: 'success',
                        delay: 8000
                    });
                    close();
                    vm.isEdit = false;
                    vm.copyIndex = null;
                    vm.resultType = null;
                })
                .catch(function(error) {
                    console.log('ERROR', error);
                });
        }

        function close(data = returnData) {
            $uibModalInstance.close(vm.result);
        }

        function getGrades() {
            return assignmentService.getGrades()
                .then((grades) => {

                    vm.grades = grades.filter((grade) => {
                        return grade.title != "-";
                    });
                })
                .catch((error) => {
                    vm.grades = [];
                })
                .finally(() => {
                    vm.grades.map((grade) => {
                        grade.displayTitle = grade.title +  " (" + grade.minValue + " - " + grade.maxValue +")";

                        return grade;
                    })
                })
        }

        function getAssignmentDetails() {

            vm.isFetchingData = true;
            assignmentService.viewAssignment(gradebookModalParams.lessonId)
                .then((response) => {
                    vm.lesson = response.data;
                    vm.lesson.students.map((student, index) => {
                        if(student.studentId == vm.studentId){
                            vm.studentIndex = index;
                        }
                        return student;
                    });

                    if(vm.studentIndex == -1){
                        close();
                        return null;
                    }
                })
                .catch((error) => {
                    close();
                })
                .finally(() => {
                    vm.isFetchingData = false;
                });
        }

        function calculatePercentage() {

            if(vm.gradeType == 'percentage') {
                _.forEach(vm.grades, (grade, n) => {
                    if(vm.percentage >= grade.minValue && vm.percentage <= grade.maxValue) {
                        vm.grade = grade;
                    }
                })
            } else if(vm.gradeType == 'marks') {

                // if(!vm.gradeObtainedMarks || !vm.gradeTotalMarks){
                // 	vm.percentage = 0;
                // 	vm.grade = "-";
                // }

                vm.obtainedMarks = vm.gradeObtainedMarks;
                vm.totalMarks = parseInt(vm.gradeTotalMarks);

                if(vm.gradeObtainedMarks == 0 && vm.gradeTotalMarks == 0){
                    vm.gradeFlag = true;
                }

                let percentage = (vm.gradeObtainedMarks / vm.gradeTotalMarks) * 100;
                if(isFinite(percentage) && !isNaN(percentage)){
                    vm.percentage = percentage;
                    _.forEach(vm.grades, (grade, n) => {
                        if(vm.percentage > grade.minValue-1 && vm.percentage <= grade.maxValue) {
                            vm.grade = grade;
                        }
                    })
                }
                else{
                    vm.percentage = 0;
                }
            }

        }

        function addGrade(id) {

            if(!vm.grade || !vm.grade.id || (vm.gradeType == 'percentage' && (!vm.percentage || vm.percentage < 0))
                || vm.percentage > 100
                || (vm.gradeType == 'marks' && vm.gradeObtainedMarks > vm.gradeTotalMarks)
                || (vm.gradeType == 'marks' && vm.gradeObtainedMarks == 0 && vm.gradeTotalMarks == 0))
            {
                vm.inputError = "Marks or percentage not correct";
                return null;
            }

            let gradeId = vm.grade.id;

            let data = {};
            if(vm.gradeType == 'marks') {
                data = {
                    obtainedMarks: vm.gradeObtainedMarks,
                    totalMarks: vm.gradeTotalMarks,
                    percentage: vm.percentage,
                    grade: gradeId,
                    status:"complete",
                };

                vm.assignment.status='complete';
            } else if(vm.gradeType = 'percentage') {
                data = {
                    percentage: vm.percentage,
                    grade: gradeId,
                    status:"complete",
                    obtainedMarks : 0,
                    totalMarks : 0
                };

                vm.assignment.status='complete';
            } else if(vm.gradeType == 'grade') {

                let defaultMarks = {
                    obtainedMarks: 0,
                    totalMarks: 0,
                    percentage: 0
                };

                return assignmentService.gradeStudent(vm.lessonId, vm.grade.id, defaultMarks)
                    .then(function(response) {
                        vm.result.graded = true;
                        vm.result.lessonId = vm.lessonId;

                        toastService.toast({
                            message: 'Grade updated successfully',
                            type: 'success',
                            delay: 2000
                        });

                        close();
                    })
                    .catch(function(error) {
                        toastService.toast({
                            message: 'An error occurred',
                            type: 'error',
                            delay: 2000
                        });
                    });
            }

            vm.statusChanged = true;
            assignmentService.addGrade(data, vm.lessonId)
                .then(response => {
                    vm.result.graded = true;
                    vm.result.lessonId = vm.lessonId;

                    toastService.toast({
                        message: 'Grade updated successfully',
                        type: 'success',
                        delay: 2000
                    });

                    close();
                })
                .catch(err => {
                    toastService.toast({
                        message: 'An error occurred',
                        type: 'error',
                        delay: 2000
                    });
                });
        }

        function init() {
            getAssignmentDetails();
            getGrades();
        }

        this.$onInit = init;
    }
export default gradebookModalController;
