let angular = window.angular;
	reportedUserController.$inject = ['$rootScope','userService', '$uibModal', 'commonService', 'toastService'];

	function reportedUserController($rootScope,userService, $uibModal, commonService, toastService) {
		let vm = this;
		vm.isFetchingUsersData = true;

		angular.extend(this, {
			reportUserStatus
		});

		function getReportedUsers(){
			vm.loadingPosts = true;
			vm.isFetchingUsersData = true;
			userService.getAllReportedUsers($rootScope.user.id)
				.then((response)=>{
					vm.userReportedUsers = response.data;
					vm.loadingPosts = false;
					vm.showResults = true;
				})
				.catch(()=>{
					toastService.toast({message: 'Reported Posts Not Found', type: 'error', delay: 2000});
				})
				.finally(() => {
					vm.isFetchingUsersData = false;
				})
		}
		function reportUserStatus(userId,reportId,status,index){
			let data={
				report:{
					reportId,
					userId,
					status
				}
			};

			if(!userId || !reportId ){
				toastService.toast({message: 'Params Missing', type: 'error', delay: 2000});
				return;
			}

			userService
				.updateUserReportStatus(data)
				.then(res=>{
					let msg="User Has been Blocked";
					if(status=="UNBLOCKED"){
						msg="User has been Unblocked";
					}
					vm.userReportedUsers.reportedUsers[index].status=status;
					toastService.toast({message: msg, type: 'success', delay: 2000});

				});
		}

		//
		// function lessonStatusChange(lessonId, ispublic, status, userId, index) {
		// 	let request = 'admin';
		// 	lessonId = commonService.convertToInteger(lessonId);
		// 	if (!lessonId) {
		// 		return;
		// 	}
		// 	lessonService.lessonStatusChange(lessonId, ispublic, status, userId, request)
		// 		.then(response => {
		// 			vm.lessonsDetails.lessons[index].status = response.status;
		// 		});
		// }

		function init() {
			return getReportedUsers();
		}

		this.$onInit = init;
	}
export default reportedUserController;
