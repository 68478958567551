/**
 * @author Shashwat Bhatt <shashwat_bhatt@gridle.io>
 * @required ng-model
 */

function expand(element, initHeight, rows) {
  element[0].style.height = "";
  element[0].style.height =
    Math.min(element[0].scrollHeight, initHeight * rows) + "px";
  element[0].style.overflow =
    element[0].scrollHeight <= initHeight * rows ? "hidden" : "auto";
}

export const autoExpand = ($timeout) => {
  let initHeight;
  let directive = {
    restrict: "A",
    link: function (scope, element, attrs) {
      $timeout(function () {
        initHeight = element[0].offsetHeight;
        element.addClass("auto-expand");
        scope.$watch(attrs.ngModel, function () {
          expand(element, initHeight, attrs.autoExpand - 1);
        });
      }, 0);
    },
  };
  return directive;
};
autoExpand.$inject = ["$timeout"];
