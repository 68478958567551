const angular = window.angular;

giphyService.$inject = ["$http", "$q", "_", "commonService"];

function giphyService($http, $q, _, commonService) {
  let basePath = "https://api.giphy.com/v1/gifs/",
    apiKey = "WltL2c1YViqYEiGPm9KodViAjPNqwQp4",
    rating = "G",
    lang = "en",
    limit = "25",
    offset = 0;

  let service = {
    trending: trending,
    search: search,
  };

  // search();

  return service;

  function getGifsData(method, offset, q) {
    offset = offset || 0;

    if (!method || (method === "search" && !q)) {
      let error = new Error("Invalid Params");
      error.message = "Invalid Params";
      return $q.reject(error);
    }

    let url =
      basePath +
      method +
      "?api_key=" +
      apiKey +
      "&rating=" +
      rating +
      "&lang=" +
      lang +
      "&limit=" +
      limit +
      "&offset=" +
      offset +
      (q ? "&q=" + q : "");

    return $http.get("/images/giphy.json").then(filterGiphyData);
  }

  function filterGiphyData(response) {
    let gifsData = _.map(response.data.data, function (giphyData) {
      if (giphyData.images.downsized) {
        giphyData.gifUrl = giphyData.images.downsized.url;
      }

      if (giphyData.images.original) {
        giphyData.gifUrl = giphyData.images.original.url;
      }

      return giphyData;
    });

    return gifsData;
  }

  function search(q, offset) {
    offset = commonService.convertToInteger(offset);
    return getGifsData("search", offset, q);
  }

  function trending(offset) {
    offset = commonService.convertToInteger(offset);
    return getGifsData("trending", offset);
  }
}
export default giphyService;
