const phoneNumber = {
  restrict: "EA",
  template: require("../../pug/components/phone-number.pug").default,
  controller: [
    "commonService",
    "locationConstants",
    "$scope",
    function (commonService, locationConstants, $scope) {
      const countries = locationConstants.countries;

      let vm = this;

      vm.showDropdown = false;
      vm.displayValue = null;
      angular.extend(this, {
        countries,
        updateCountry,
      });

      function updateCountry(country) {
        vm.countryCode = country.callingCode;
        vm.displayValue = "+" + vm.countryCode;
        this.codeChange({ type: "code", value: vm.countryCode });
      }

      this.$doCheck = function () {
        vm.countryCode = commonService.convertToInteger(vm.countryCode);
        vm.displayValue = "+" + vm.countryCode;
      };

      $scope.$watch("form", (event) => {
        // console.log('FORM CHANGE EVENT --->', event);
      });

      this.$onInit = function () {
        vm.countryCode = commonService.convertToInteger(vm.countryCode) || 1;
        vm.displayValue = "+" + vm.countryCode;
        if (this.form) {
          vm.newForm = this.form;
        }
      };
    },
  ],
  controllerAs: "countryCtrl",
  bindings: {
    countryCode: "=",
    contactNumber: "=",
    form: "=",
    isDropup: "=",
    field: "=",
    codeChange: "&",
  },
};

export default phoneNumber;
