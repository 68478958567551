const angular = window.angular;

feedService.$inject = [
  "$http",
  "$q",
  "$rootScope",
  "Store",
  "appointmentService",
  "appConstants",
];

function feedService(
  $http,
  $q,
  $rootScope,
  Store,
  appointmentService,
  appConstants
) {
  let basePath = appConstants.baseUrl + "/api/feeds/";
  let basePathEvent = appConstants.baseUrl + "/api/events/";

  let userPost = {};
  let groups = [];
  let events = [];

  function getFeeds(skip, limit, groupId) {
    let deferred = $q.defer(),
      getLessonsPromise,
      requestQuery;

    requestQuery =
      basePath + "index" + "?skip=" + skip + "&limit=" + limit + "&type=web";

    if (groupId && groupId > 0) {
      requestQuery += "&groupId=" + groupId;
    }

    getLessonsPromise = $http.get(requestQuery);
    getLessonsPromise
      .then((response) => {
        let feedData = response.data.data;

        deferred.resolve(feedData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function getFeedHighlights(skip, limit) {
    let deferred = $q.defer(),
      getLessonsPromise;

    getLessonsPromise = $http.get(
      basePath + "indexHighlights" + "?skip=" + skip + "&limit=" + limit
    );
    getLessonsPromise
      .then((response) => {
        let feedData = response.data.data;
        deferred.resolve(feedData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function saveFeeds(data) {
    let deferred = $q.defer(),
      savePromise,
      options;
    options = {
      method: "POST",
      data: data,
      url: basePath + "add",
    };

    savePromise = $http(options);
    savePromise
      .then((response) => {
        let feed = response.data.data;

        deferred.resolve(feed);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function editFeeds(data) {
    let deferred = $q.defer(),
      savePromise,
      options;
    options = {
      method: "POST",
      data: data,
      url: basePath + "edit",
    };

    savePromise = $http(options);
    savePromise
      .then((response) => {
        let feed = response.data.data;

        deferred.resolve(feed);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function changeFeedView(data) {
    let deferred = $q.defer(),
      savePromise,
      options,
      feedData = {
        feed: data,
      };

    options = {
      method: "POST",
      data: feedData,
      url: basePath + "changeFeedView",
    };

    savePromise = $http(options);
    savePromise
      .then((response) => {
        let feed = response.data.data;

        deferred.resolve(feed);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function feedLike(feedId, userId, like) {
    let likeStatus = null;

    if (like && like == "L") likeStatus = "D";
    else likeStatus = "L";

    let deferred = $q.defer(),
      savePromise,
      options,
      data = {
        feedId: feedId,
        userId: userId,
        likeType: likeStatus,
      };
    options = {
      method: "POST",
      data: { feed: data },
      url: basePath + "feedLike",
    };

    savePromise = $http(options);
    savePromise
      .then((response) => {
        let result = response.data.data;

        deferred.resolve(result);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function addComment(feedId, comment, userId) {
    let deferred = $q.defer(),
      savePromise,
      options,
      feed = {
        feedId: feedId,
        comment: comment,
        userId: userId,
      };

    options = {
      method: "POST",
      data: { feed: feed },
      url: basePath + "addComment",
    };

    savePromise = $http(options);
    savePromise
      .then((response) => {
        let feedComment = response.data.data;
        // saveLessonToList(lesson.id, lesson);
        deferred.resolve(feedComment);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function addReply(commentId, reply) {
    let deferred = $q.defer(),
      savePromise,
      options,
      commentReply = {
        commentId: commentId,
        reply: reply,
      };

    options = {
      method: "POST",
      data: { commentReply: commentReply },
      url: basePath + "addReply",
    };

    savePromise = $http(options);
    savePromise
      .then((response) => {
        let feedComment = response.data.data;
        // saveLessonToList(lesson.id, lesson);
        deferred.resolve(feedComment);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function deleteReply(replyId) {
    let deferred = $q.defer(),
      savePromise,
      options;

    options = {
      method: "DELETE",
      url: basePath + "deleteReply/" + replyId,
    };

    savePromise = $http(options);
    savePromise
      .then((response) => {
        let commentReply = response.data;
        // saveLessonToList(lesson.id, lesson);
        deferred.resolve(commentReply);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function deleteComment(commentId, userId) {
    let deferred = $q.defer(),
      savePromise,
      options;

    options = {
      method: "DELETE",
      url: basePath + "deleteComment/" + commentId,
    };

    savePromise = $http(options);
    savePromise
      .then((response) => {
        let feedComment = response.data;
        // saveLessonToList(lesson.id, lesson);
        deferred.resolve(feedComment);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function feedUserFollow(feed, feedType) {
    let deferred = $q.defer(),
      savePromise,
      options,
      feedData = {
        userFollowId: feed.userInfo.id,
        type: feedType,
      };

    if (feedType == "F") {
      // feedType == R is set to Save in DataBase as the follow request received

      feedData = {
        userFollowId: feed.userInfo.id,
        type: "R",
      };

      options = {
        method: "POST",
        data: { feed: feedData },
        url: basePath + "feedUserFollow",
      };

      // feedType == R is to remove a follower
    }
    // feeType == A is to Accept Follow Request
    else if (feedType == "A") {
      feedData = {
        userFollowId: feed.userInfo.id,
        type: "F",
      };

      options = {
        method: "POST",
        data: { feed: feedData },
        url: basePath + "feedUserFollow",
      };
    } else if (feedType == "D") {
      feedData = {
        userFollowId: feed.userInfo.id,
        type: "D",
      };

      options = {
        method: "POST",
        data: { feed: feedData },
        url: basePath + "feedUserFollow",
      };

      // feedType == U which receive in else case is to Send the Un-follow a User
    } else if (feedType == "R") {
      feedData = {
        userId: feed.userInfo.id,
        type: "U",
      };

      options = {
        method: "POST",
        data: { feed: feedData },
        url: basePath + "feedRemovefollower",
      };

      // feedType == U which receive in else case is to Send the Un-follow a User
    } else {
      options = {
        method: "POST",
        data: { feed: feedData },
        url: basePath + "feedUnfollows",
      };
    }

    savePromise = $http(options);
    savePromise
      .then((response) => {
        let feedComment = response.data.data;
        // saveLessonToList(lesson.id, lesson);
        deferred.resolve(feedComment);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }
  function conversationUserUnFollow(userId) {
    let deferred = $q.defer(),
      savePromise,
      options,
      feedData = {
        userFollowId: userId,
        type: "U",
      };
    options = {
      method: "POST",
      data: { feed: feedData },
      url: basePath + "feedUnfollows",
    };
    savePromise = $http(options);
    savePromise
      .then((response) => {
        let feedComment = response.data.data;
        // saveLessonToList(lesson.id, lesson);
        deferred.resolve(feedComment);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }
  function conversationUserFollow(userId) {
    let deferred = $q.defer(),
      savePromise,
      options,
      feedData = {
        userFollowId: userId,
        type: "R",
      };
    options = {
      method: "POST",
      data: { feed: feedData },
      url: basePath + "feedUserFollow",
    };
    savePromise = $http(options);
    savePromise
      .then((response) => {
        let feedComment = response.data.data;
        // saveLessonToList(lesson.id, lesson);
        deferred.resolve(feedComment);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function feedDelete(feed) {
    let deferred = $q.defer(),
      savePromise,
      options,
      feedData = {
        feedId: feed.id,
        userId: feed.userInfo.id,
      };

    options = {
      method: "POST",
      data: { feed: feedData },
      url: basePath + "feedDelete",
    };

    savePromise = $http(options);
    savePromise
      .then((response) => {
        let feedDelete = response.data.data;

        deferred.resolve(feedDelete);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function viewFeed(feedId) {
    let deferred = $q.defer(),
      url;

    url = basePath + "view/" + feedId;

    $http
      .get(url)
      .then((response) => {
        let feedData = response.data.data.feeds;
        deferred.resolve(feedData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function viewUser(userId) {
    let deferred = $q.defer(),
      url;

    url = basePath + "userView/" + userId;

    $http
      .get(url)
      .then((response) => {
        let feedData = response.data.data;
        deferred.resolve(feedData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function userPosts(userId, type) {
    let data = {};
    if (type === "u") {
      data = {
        type: "u",
        userId,
      };
    } else {
      data = {
        type: "l",
      };
    }

    let deferred = $q.defer(),
      savePromise,
      options;
    options = {
      method: "POST",
      data: data,
      url: basePath + "userPosts",
    };

    savePromise = $http(options);
    savePromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function getFollowers(type, userId) {
    let data = {};
    if (type === "u") {
      data = {
        type: "u",
        userId,
      };
    } else {
      data = {
        type: "l",
      };
    }
    let deferred = $q.defer(),
      savePromise,
      options;
    options = {
      method: "POST",
      data: data,
      url: basePath + "viewfollowers",
    };

    savePromise = $http(options);
    savePromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }
  function getFollowings(type, userId) {
    let data = {};
    if (type === "u") {
      data = {
        type: "u",
        userId,
      };
    } else {
      data = {
        type: "l",
      };
    }
    let deferred = $q.defer(),
      savePromise,
      options;
    options = {
      method: "POST",
      data: data,
      url: basePath + "viewfollowings",
    };

    savePromise = $http(options);
    savePromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function getLikeList(feedId) {
    let deferred = $q.defer(),
      savePromise,
      options;
    options = {
      method: "POST",
      data: { feedId },
      url: basePath + "viewLikes",
    };

    savePromise = $http(options);
    savePromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function search(searchText) {
    let deferred = $q.defer(),
      savePromise,
      options;
    let searchUserPromise;

    searchUserPromise = $http({
      method: "GET",
      url: basePath + "index",
      params: {
        q: searchText,
      },
    });

    searchUserPromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }
  function searchFilter(
    searchText,
    name,
    location,
    interests,
    faith,
    teachingStyle,
    gender
  ) {
    let deferred = $q.defer(),
      savePromise,
      options;
    let searchUserPromise;

    let params = {};
    if (name) {
      params.name = name;
    }
    if (location) {
      params.location = location;
    }
    if (interests) {
      params.interests = interests;
    }
    if (faith) {
      params.faith = faith;
    }
    if (teachingStyle) {
      params.teachingStyle = teachingStyle;
    }
    if (gender) {
      params.gender = gender;
    }
    if (searchText) {
      params.q = searchText;
    }
    searchUserPromise = $http({
      method: "GET",
      url: basePath + "index",
      params: params,
    });

    searchUserPromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function saveBio(bioDetails) {
    let deferred = $q.defer(),
      savePromise,
      options;

    options = {
      method: "POST",
      data: { feed: bioDetails },
      url: basePath + "saveBio",
    };

    savePromise = $http(options);
    savePromise
      .then((response) => {
        let bioDetails = response.data.data;

        deferred.resolve(bioDetails);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function createGroup(groupData) {
    let deferred = $q.defer(),
      getLessonsPromise,
      options;
    options = {
      method: "POST",
      data: { group: groupData },
      url: basePath + "groupadd",
    };

    getLessonsPromise = $http(options);
    getLessonsPromise
      .then((response) => {
        let feedData = response.data.data;

        deferred.resolve(feedData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function getAllGroups(skip, limit) {
    let deferred = $q.defer(),
      getGroupsPromise,
      options;
    options = {
      method: "GET",
      url: basePath + "getgroups?skip=" + skip + "&limit=" + limit,
    };

    getGroupsPromise = $http(options);
    getGroupsPromise
      .then((response) => {
        let groupData = response.data.data;
        groups = groupData;
        deferred.resolve(groupData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function getOverAllGroups() {
    let deferred = $q.defer(),
      getGroupsPromise,
      options;
    options = {
      method: "GET",
      url: basePath + "getallgroups",
    };

    getGroupsPromise = $http(options);
    getGroupsPromise
      .then((response) => {
        let groupData = response.data.data;

        deferred.resolve(groupData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }
  function showDefaultGroups() {
    let deferred = $q.defer(),
      getGroupsPromise,
      options;
    options = {
      method: "GET",
      url: basePath + "showDefaultGroups",
    };

    getGroupsPromise = $http(options);
    getGroupsPromise
      .then((response) => {
        let groupData = response.data.data;

        deferred.resolve(groupData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  // function getOverAllGroups(skip, limit) {

  // 	let deferred = $q.defer(),
  // 		getGroupsPromise, options;
  // 	options = {
  // 		method: 'GET',
  // 		url: basePath + 'getallgroups?skip=' + skip + "&limit=" + limit,
  // 	};

  // 	getGroupsPromise = $http(options);
  // 	getGroupsPromise
  // 		.then((response) => {
  // 			let groupData = response.data.data;

  // 			deferred.resolve(groupData);
  // 		})
  // 		.catch((response) => {
  // 			deferred.reject(response.data);
  // 		});

  // 	return deferred.promise;
  // }

  function getGroupMembers(id) {
    let deferred = $q.defer(),
      getGroupsPromise,
      options;
    options = {
      method: "GET",
      url: basePath + "getgroupmembers/" + id,
    };

    getGroupsPromise = $http(options);
    getGroupsPromise
      .then((response) => {
        let groupData = response.data.data;

        deferred.resolve(groupData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }
  function getSingleGroup(id) {
    let deferred = $q.defer(),
      getGroupsPromise,
      options;
    options = {
      method: "GET",
      url: basePath + "getsinglegroup/" + id,
    };

    getGroupsPromise = $http(options);
    getGroupsPromise
      .then((response) => {
        let groupData = response.data.data;

        deferred.resolve(groupData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function inviteMember(data) {
    let deferred = $q.defer(),
      getLessonsPromise,
      options;
    options = {
      method: "POST",
      data,
      url: basePath + "invitemember",
    };

    getLessonsPromise = $http(options);
    getLessonsPromise
      .then((response) => {
        let feedData = response.data.data;

        deferred.resolve(feedData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function changeMemberRole(data) {
    let deferred = $q.defer(),
      getLessonsPromise,
      options;
    options = {
      method: "POST",
      data,
      url: basePath + "changememberrole",
    };

    getLessonsPromise = $http(options);
    getLessonsPromise
      .then((response) => {
        let feedData = response.data.data;

        deferred.resolve(feedData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }
  function removeMember(data) {
    let deferred = $q.defer(),
      getLessonsPromise,
      options;
    options = {
      method: "DELETE",
      data,
      url: basePath + "removemember",
    };

    getLessonsPromise = $http(options);
    getLessonsPromise
      .then((response) => {
        let feedData = response.data.data;

        deferred.resolve(feedData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function removeUser(data) {
    let deferred = $q.defer(),
      getLessonsPromise,
      options;
    options = {
      method: "POST",
      data,
      url: basePath + "removeuser",
    };

    getLessonsPromise = $http(options);
    getLessonsPromise
      .then((response) => {
        let feedData = response.data.data;

        deferred.resolve(feedData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }
  function blockUser(data) {
    let deferred = $q.defer(),
      getLessonsPromise,
      options;
    options = {
      method: "POST",
      data,
      url: basePath + "blockuser",
    };

    getLessonsPromise = $http(options);
    getLessonsPromise
      .then((response) => {
        let feedData = response.data.data;

        deferred.resolve(feedData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function leaveGroup(data) {
    let deferred = $q.defer(),
      getLessonsPromise,
      options;
    options = {
      method: "POST",
      data,
      url: basePath + "leavegroup",
    };

    getLessonsPromise = $http(options);
    getLessonsPromise
      .then((response) => {
        let feedData = response.data.data;

        deferred.resolve(feedData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  // function setGroups(data) {
  // 	groups = groups.filter(group => group.feedGroupId !== data.groups[0].id);
  // 	console.log("groups", groups);
  // 	let eventIds = _.map(data.events, 'id');
  // 	//let eventIds = _.map(events.data.data, 'id');
  // 	console.log("eventIds", eventIds);
  // 	events = events.data.data.filter(event => event.id !== eventIds);
  // 	console.log("events", events);
  // }

  // function getGroups(){
  // 	return (groups, events);
  // }

  function groupDelete(data) {
    let deferred = $q.defer(),
      getLessonsPromise,
      options;
    options = {
      method: "POST",
      data,
      url: basePath + "groupdelete",
    };

    getLessonsPromise = $http(options);
    getLessonsPromise
      .then((response) => {
        let feedData = response.data.data;
        //groups = feedData;
        deferred.resolve(feedData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    $rootScope.myGroupsPromise = deferred.promise;
    return deferred.promise;
  }

  function updateGroup(data) {
    let deferred = $q.defer(),
      getLessonsPromise,
      options;
    options = {
      method: "POST",
      data,
      url: basePath + "updategroupsettings",
    };

    getLessonsPromise = $http(options);
    getLessonsPromise
      .then((response) => {
        let feedData = response.data.data;

        deferred.resolve(feedData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function createEvent(data) {
    let deferred = $q.defer(),
      getLessonsPromise,
      options;
    options = {
      method: "POST",
      data,
      url: basePathEvent + "add",
    };

    getLessonsPromise = $http(options);
    getLessonsPromise
      .then((response) => {
        deferred.resolve(response);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function searchGroups(content, filter) {
    let searchData = "";
    if (content && filter) {
      searchData = "filter=" + filter + "&content=" + content;
    } else if (content) {
      searchData = "content=" + content;
    }
    //else if(faith) {
    // 	searchData = 'faith=' + faith;
    // }
    let deferred = $q.defer(),
      getGroupsPromise,
      options;
    options = {
      method: "GET",
      url: basePath + "searchGroup?" + searchData,
    };

    getGroupsPromise = $http(options);
    getGroupsPromise
      .then((response) => {
        let feedData = response;

        deferred.resolve(feedData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function getAllEvents() {
    let deferred = $q.defer(),
      getEventsPromise,
      options;
    options = {
      method: "GET",
      url: basePathEvent + "groupEvents",
    };

    getEventsPromise = $http(options);
    getEventsPromise
      .then((response) => {
        events = response;
        deferred.resolve(response);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function acceptInvitation(data) {
    let deferred = $q.defer(),
      getLessonsPromise,
      options;
    options = {
      method: "POST",
      data,
      url: basePath + "acceptInvitation",
    };

    getLessonsPromise = $http(options);
    getLessonsPromise
      .then((response) => {
        let feedData = response;

        deferred.resolve(feedData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function getGroupEvents(skip, limit, id, type) {
    let deferred = $q.defer(),
      getEventsPromise,
      requestQuery;

    requestQuery =
      basePathEvent +
      "index/" +
      id +
      "?skip=" +
      skip +
      "&limit=" +
      limit +
      "&type=" +
      type;

    getEventsPromise = $http.get(requestQuery);
    // getEventsPromise = $http(options);
    getEventsPromise
      .then((response) => {
        deferred.resolve(response);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });
    return deferred.promise;
  }
  function getMemberGroupEvents(id, type) {
    let deferred = $q.defer(),
      getEventsPromise,
      requestQuery;

    requestQuery =
      basePathEvent + "getMemberGroupEvents/" + id + "?type=" + type;

    getEventsPromise = $http.get(requestQuery);
    // getEventsPromise = $http(options);
    getEventsPromise
      .then((response) => {
        deferred.resolve(response);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });
    return deferred.promise;
  }

  function getUserEvents() {
    let deferred = $q.defer(),
      getEventsPromise,
      options;
    options = {
      method: "GET",
      url: basePathEvent + "getUserEvents",
    };
    getEventsPromise = $http(options);
    getEventsPromise
      .then((response) => {
        deferred.resolve(response);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });
    return deferred.promise;
  }

  function rejectInvitation(data) {
    let deferred = $q.defer(),
      getLessonsPromise,
      options;
    options = {
      method: "POST",
      data,
      url: basePath + "rejectInvitation",
    };

    getLessonsPromise = $http(options);
    getLessonsPromise
      .then((response) => {
        let feedData = response;

        deferred.resolve(feedData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });
    return deferred.promise;
  }

  function joinGroupEvent(data) {
    let deferred = $q.defer(),
      getEventsPromise,
      options;
    options = {
      method: "POST",
      data: data,
      url: basePathEvent + "join",
    };

    getEventsPromise = $http(options);
    getEventsPromise
      .then((response) => {
        deferred.resolve(response);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function editEvent(data, id) {
    let deferred = $q.defer(),
      getEventsPromise,
      options;
    options = {
      method: "POST",
      data: data,
      url: basePathEvent + "update/" + id,
    };

    getEventsPromise = $http(options);
    getEventsPromise
      .then((response) => {
        deferred.resolve(response);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function inviteGroupEvent(data) {
    let deferred = $q.defer(),
      getEventsPromise,
      options;
    options = {
      method: "POST",
      data: data,
      url: basePathEvent + "inviteParticipant",
    };

    getEventsPromise = $http(options);
    getEventsPromise
      .then((response) => {
        deferred.resolve(response);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function inviteGroupMemberOnMail(data) {
    let deferred = $q.defer(),
      getEventsPromise,
      options;
    options = {
      method: "POST",
      data: data,
      url: basePath + "sendInviteEmail",
    };

    getEventsPromise = $http(options);
    getEventsPromise
      .then((response) => {
        deferred.resolve(response);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function viewSingleEvent(id) {
    let deferred = $q.defer(),
      getEventsPromise,
      options;
    options = {
      method: "GET",
      url: basePathEvent + "viewSingle/" + id,
    };

    getEventsPromise = $http(options);
    getEventsPromise
      .then((response) => {
        deferred.resolve(response);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function getEventParticipants(id) {
    let deferred = $q.defer(),
      getEventsPromise,
      options;
    options = {
      method: "GET",
      url: basePathEvent + "eventParticipants/" + id,
    };

    getEventsPromise = $http(options);
    getEventsPromise
      .then((response) => {
        deferred.resolve(response);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function getEventFeeds(skip, limit, eventId) {
    let deferred = $q.defer(),
      getLessonsPromise,
      requestQuery;

    requestQuery =
      basePath + "eventFeeds" + "?skip=" + skip + "&limit=" + limit;

    if (eventId && eventId > 0) {
      requestQuery += "&eventId=" + eventId;
    }

    getLessonsPromise = $http.get(requestQuery);
    getLessonsPromise
      .then((response) => {
        let feedData = response.data.data;

        deferred.resolve(feedData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function deleteEvent(id) {
    let deferred = $q.defer(),
      getEventsPromise,
      options;
    options = {
      method: "DELETE",
      url: basePathEvent + "eventDelete/" + id,
    };

    getEventsPromise = $http(options);
    getEventsPromise
      .then((response) => {
        deferred.resolve(response);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function searchEvents(content, filter, data) {
    let searchData = "";
    if (content && filter) {
      searchData = "filter=" + filter + "&content=" + content;
    } else if (filter) {
      searchData = "filter=" + filter;
    } else if (content) {
      searchData = "content=" + content;
    }
    let deferred = $q.defer(),
      getGroupsPromise,
      options;
    options = {
      method: "POST",
      data,
      url: basePathEvent + "searchEvent?" + searchData,
    };
    getGroupsPromise = $http(options);
    getGroupsPromise
      .then((response) => {
        let feedData = response;

        deferred.resolve(feedData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }
  function getAllGroupsEvents() {
    let deferred = $q.defer(),
      getGroupsPromise,
      options;
    options = {
      method: "GET",
      url: basePathEvent + "getJoinedGroupEvents",
    };
    getGroupsPromise = $http(options);
    getGroupsPromise
      .then((response) => {
        let feedData = response;

        deferred.resolve(feedData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function getAllJoinedGroup(content) {
    let searchData = "";
    let deferred = $q.defer(),
      getGroupsPromise,
      options;
    if (content) {
      searchData = "content=" + content;
    }
    options = {
      method: "GET",
      url: basePathEvent + "grouponeventcreate?" + searchData,
    };
    getGroupsPromise = $http(options);
    getGroupsPromise
      .then((response) => {
        let feedData = response;

        deferred.resolve(feedData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function sendEmailAlert(data) {
    let deferred = $q.defer(),
      getGroupsPromise,
      options;
    options = {
      method: "POST",
      data,
      url: basePath + "emailalert",
    };
    getGroupsPromise = $http(options);
    getGroupsPromise
      .then((response) => {
        let feedData = response;

        deferred.resolve(feedData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }
  function getGroupMedia(id, type) {
    let deferred = $q.defer(),
      getGroupsPromise,
      options;
    options = {
      method: "GET",
      url: basePath + "groupmedia/" + id + "?type=" + type,
    };
    getGroupsPromise = $http(options);
    getGroupsPromise
      .then((response) => {
        let feedData = response;

        deferred.resolve(feedData);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function myPosts() {
    let deferred = $q.defer(),
      savePromise;

    savePromise = $http.get(basePath + "myPosts");
    savePromise
      .then((response) => {
        userPost = response.data.data.userPost;
        return deferred.resolve(userPost);
      })
      .catch((response) => {
        return deferred.reject(response);
      });
    $rootScope.myPostsPromise = deferred.promise;
    return deferred.promise;
  }

  function getUserPost() {
    return userPost;
  }
  function setUserPost(type) {
    if (type === "ADD") {
      userPost.myPosts = userPost.myPosts + 1;
    }
    if (type === "LESS") {
      userPost.myPosts = userPost.myPosts - 1;
    }
  }

  function setUserFollowing(type) {
    if (type === "ADD") {
      userPost.following = userPost.following + 1;
    }
    if (type === "LESS") {
      userPost.following = userPost.following - 1;
    }
  }

  function setUserFollower(type) {
    if (type === "ADD") {
      userPost.followers = userPost.followers + 1;
    }
    if (type === "LESS") {
      userPost.followers = userPost.followers - 1;
    }
  }

  function setUserGroups(type) {
    if (type === "ADD") {
      userPost.groups = userPost.groups + 1;
    }
    if (type === "LESS") {
      userPost.groups = userPost.groups - 1;
    }
  }

  function setHeaderTitle(title, backIcon) {
    headerTitle = {
      title,
      backIcon,
    };
  }
  function getHeaderTitle() {
    return headerTitle;
  }

  function createQuestion(data) {
    let deferred = $q.defer(),
      groupsPromise,
      options;
    options = {
      method: "POST",
      data,
      url: basePath + "createQuestion",
    };
    groupsPromise = $http(options);
    groupsPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function getAllQuestions(groupId) {
    let deferred = $q.defer(),
      savePromise;

    savePromise = $http.get(basePath + "getAllQuestions/" + groupId);
    savePromise
      .then((response) => {
        return deferred.resolve(response.data);
      })
      .catch((response) => {
        return deferred.reject(response);
      });
    return deferred.promise;
  }

  function deleteQuestion(questionId) {
    let deferred = $q.defer(),
      savePromise;

    savePromise = $http.get(basePath + "deleteQuestion/" + questionId);
    savePromise
      .then((response) => {
        return deferred.resolve(response.data);
      })
      .catch((response) => {
        return deferred.reject(response);
      });
    return deferred.promise;
  }

  function editQuestion(data, id) {
    let deferred = $q.defer(),
      groupsPromise,
      options;
    options = {
      method: "POST",
      data,
      url: basePath + "editQuestion/" + id,
    };
    groupsPromise = $http(options);
    groupsPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function viewMembersPendingRequests(id) {
    let deferred = $q.defer(),
      groupsPromise,
      options;
    options = {
      method: "GET",
      url: basePath + "viewMembersPendingRequests/" + id,
    };
    groupsPromise = $http(options);
    groupsPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function submitAnswer(data, id) {
    let deferred = $q.defer(),
      groupsPromise,
      options;
    options = {
      method: "POST",
      data,
      url: basePath + "answerGroupQuestions/" + id,
    };
    groupsPromise = $http(options);
    groupsPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function acceptAllInvitation(data) {
    let deferred = $q.defer(),
      groupsPromise,
      options;
    options = {
      method: "POST",
      data,
      url: basePath + "acceptAllInvitation",
    };
    groupsPromise = $http(options);
    groupsPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function rejectAllInvitation(data) {
    let deferred = $q.defer(),
      groupsPromise,
      options;
    options = {
      method: "POST",
      data,
      url: basePath + "rejectAllInvitation",
    };
    groupsPromise = $http(options);
    groupsPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function addToCalendar(event) {
    let data = {
      appointment: {},
      recurrence: {},
      reminders: [],
    };

    data.appointment.title = event.title;
    data.appointment.description = event.detail;

    data.appointment.location = event.location;
    data.appointment.latitude = event.locationLatitude || 0;
    data.appointment.longitude = event.locationLongitude || 0;

    let eventMember = [];
    for (let i = 0; i < event.participants.length; i++) {
      if (event.participants[i].userRole != "OWNER") {
        eventMember.push({
          type: "friend",
          id: event.participants[i].userId.id,
          name: event.participants[i].userId.name,
        });
      }
    }

    data.appointment.attendees = eventMember;
    data.appointment.category = "groupEvent";

    // let startDateTime = moment(event.startDate);//commonService.convertToUTC(event.startDate);
    // let endDateTime = moment(event.endDate);//commonService.convertToUTC(event.endDate);
    // Make sure end datetime is not before start datetime
    if (moment(event.endDate).isBefore(event.startDate)) {
      toastService.toast({
        message: "Event end date must be after start date",
        type: "error",
        delay: 3000,
      });
      return false;
    }

    data.appointment.startDate = event.startDate; //startDateTime.format('Y-MM-DD HH:mm:ss');
    data.appointment.endDate = event.endDate; //endDateTime.format('Y-MM-DD HH:mm:ss');

    let timezone = -1 * new Date().getTimezoneOffset();
    return appointmentService.createAppointment(timezone, data);
  }

  angular.extend(this, {
    getFeeds,
    saveFeeds,
    editFeeds,
    feedLike,
    addComment,
    feedUserFollow,
    feedDelete,
    getFeedHighlights,
    viewFeed,
    viewUser,
    userPosts,
    search,
    getFollowers,
    getFollowings,
    saveBio,
    getLikeList,
    changeFeedView,
    createGroup,
    getAllGroups,
    getGroupMembers,
    getSingleGroup,
    changeMemberRole,
    inviteMember,
    removeMember,
    leaveGroup,
    updateGroup,
    removeUser,
    blockUser,
    groupDelete,
    createEvent,
    getAllEvents,
    getGroupEvents,
    joinGroupEvent,
    editEvent,
    inviteGroupEvent,
    viewSingleEvent,
    getEventFeeds,
    deleteEvent,
    searchGroups,
    getOverAllGroups,
    acceptInvitation,
    rejectInvitation,
    getEventParticipants,
    searchEvents,
    getAllGroupsEvents,
    getAllJoinedGroup,
    sendEmailAlert,
    deleteComment,
    inviteGroupMemberOnMail,
    searchFilter,
    getGroupMedia,
    myPosts,
    userPost,
    getUserPost,
    setUserPost,
    setUserFollowing,
    setUserFollower,
    setUserGroups,
    //setGroups,
    //getGroups,
    groups,
    events,
    getUserEvents,
    addReply,
    deleteReply,
    showDefaultGroups,
    createQuestion,
    getAllQuestions,
    deleteQuestion,
    editQuestion,
    viewMembersPendingRequests,
    submitAnswer,
    acceptAllInvitation,
    rejectAllInvitation,
    addToCalendar,
    getMemberGroupEvents,
    conversationUserFollow,
    conversationUserUnFollow,
  });
}
export default feedService;
