timepicker.$inject = ["moment"];
function timepicker(moment) {
  return {
    require: "ngModel",
    restrict: "AE",
    scope: {
      ngModel: "=",
    },
    replace: true,
    template: require("../../pug/components/timepicker.pug").default,
    link: function (scope, element, attrs, ngModel) {
      let tmp = ngModel.$setDirty;
      ngModel.$setDirty = angular.noop;

      scope.meridian = "AM";

      scope.$watch("hour + minute + meridian", function () {
        ngModel.$setViewValue({
          hour: scope.hour,
          minute: scope.minute,
          meridian: scope.meridian,
        });
        ngModel.$setDirty = tmp;
      });

      ngModel.$parsers.push(function (viewValue) {
        if (!scope.hour || !scope.minute) {
          return;
        }
        let hour = scope.hour;
        let min = scope.minute;
        let meridian = scope.meridian;
        let value = hour + ":" + min + " " + meridian;
        return moment(value, "hh:mm A").format("HH:mm");
      });

      ngModel.$formatters.push(function (modelValue) {
        if (!modelValue) {
          return;
        }
        let time = moment(modelValue, "hh:mm");
        return {
          hour: time.format("hh"),
          minute: time.format("mm"),
          meridian: time.format("A"),
        };
      });

      ngModel.$render = function () {
        if (!ngModel.$viewValue) {
          return;
        }
        scope.hour = ngModel.$viewValue.hour;
        scope.minute = ngModel.$viewValue.minute;
        scope.meridian = ngModel.$viewValue.meridian;
      };
    },
  };
}
export default timepicker;
