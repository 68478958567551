let angular = window.angular;
    userEditController.$inject = ['userService', '_', '$rootScope', '$scope', 'commonService', '$state', 'Store', 'toastService', 'fileService', '$uibModal', 'locationConstants', 'tabs', '$uibModalInstance'];

    function userEditController(userService, _, $rootScope, $scope, commonService, $state, Store, toastService, fileService, $uibModal, locationConstants, tabs, $uibModalInstance) {

        let vm = this;
        vm.user = _.clone($rootScope.user);
        if($rootScope.user.userRole==='HELPER' && $rootScope.user.helperId)
        vm.user.id=$rootScope.user.helperId
        vm.countries = locationConstants.countries;
        vm.states = [];
        vm.cities = [];
        vm.location = {};
		//vm.address = "";
        //vm.errorAddress = false;

		vm.errorCountry = false;
        vm.errorZip = false;
        vm.errorState = false;
        vm.errorCity = false;
        vm.isFetchingStates = false;
        vm.isFetchingCities = false;
        vm.selectedInterests = [];
        vm.showForm = tabs.tabValue;
        vm.headingText = "";
        vm.email = "";
        vm.isEmailChange = false;
		vm.emailExistError = "";
		vm.checkEmail = "";
        vm.logoutConfirm = false;

        let countries = _.map(vm.countries, function(country) {
            return country.name;
        });

        angular.extend(this, {
            selectProfilePic,
            editProfile,
            getStates,
            getCities,
            checkLocation,
            checkLocationOnBlur,
            checkAlphaNumeric,
            locationSelected,
            countryChanged,
			onTagAdded,
			isTouched,
			close,
			changePassword,
			validateEmail
        });

        if (vm.user.countryCode === 0) {
            vm.user.countryCode = null;
        }

		function isTouched(field) {
			return  vm.form.$submitted;
		}

		function onTagAdded($tag) {

			if(vm.selectedInterests.length>=5){
				return false;
			}
		}

        function countryChanged(type, value) {
            if (type === 'code') {
                let country = _.find(vm.countries, { 'callingCode': value });
                if (country && country.name) {
                    vm.user.country = country.name;
                    countryNameChanged(country.name);
                }
            }

            if (type === 'name') {
                let country = _.find(vm.countries, { 'name': value });
                if (country && country.callingCode) {
                    vm.user.countryCode = country.callingCode;
                    countryNameChanged(country.name);
                }
            }
        }

        function locationSelected(country, state, city, zip) {
            vm.location.state = state;
            vm.location.city = city;

            if (zip) {
                vm.user.zipCode = zip;
            }

            vm.user.country = (country) ? country : '';

            if (country) {
                vm.user.state = null;
                vm.user.city = null;
                getStates(country)
                    .then((userState) => {
                        if (userState) {
                            return getCities(country, userState);
                        }
                    });
            }
        }

        function getStates(country, action) {
            if (!country) {
                return;
            }

            vm.isFetchingStates = true;
            if (action !== "init") {
                vm.states = null;
                vm.cities = null;
                vm.errorCountry = false;
                vm.form.country.$valid = true;
            }

            return userService.getStateList(country)
                .then((response) => {
                    vm.states = response;
                    if (vm.location.state) {
                        let stateIndex = _.findIndex(vm.states, (state) => {
                            return state.includes(vm.location.state);
                        });
                        vm.user.state = (stateIndex >= 0) ? vm.states[stateIndex] : '';
                    }
                    return vm.user.state;
                })
                .catch((error) => {
                    console.error("err", error);
                })
                .finally(() => {
                    vm.isFetchingStates = false;
                });
        }

        function countryNameChanged(name) {
            vm.user.state = '';
            vm.user.city = '';
            return getStates(name || vm.user.country);
        }

        function stateNameChanged() {
            vm.user.city = '';
        }

        function checkLocation(location, type) {
            // console.log("check " + type + " on select");
            if (!location) {
                return;
            }
            switch (type) {
                case "country":
                    for (let value of countries) {
                        if (value.toLowerCase().search(location.toLowerCase()) >= 0) {
                            vm.errorCountry = false;
                            break;
                        }
                        vm.errorCountry = true;

                    }
                    break;

                case "state":
                    stateNameChanged();
                    for (let value of vm.states) {
                        if (value.toLowerCase().search(location.toLowerCase()) >= 0) {
                            vm.errorState = false;
                            break;
                        }
                        vm.errorState = true;
                    }
                    break;

                case "city":
                    for (let value of vm.cities) {
                        if (value.toLowerCase().search(location.toLowerCase()) >= 0) {
                            vm.errorCity = false;
                            break;
                        }
                        vm.errorCity = true;
                    }
                    break;
            }
        }

        function checkAlphaNumeric() {
            if (!vm.user.zipCode) {
                vm.errorZip = true;
                return;
            }
            let pattern = /[@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
            let str = vm.user.zipCode;
            if (str.search(pattern) >= 0) {
                vm.errorZip = true;
                return;
            }
            vm.errorZip = false;

        }

        function checkLocationOnBlur(location, type) {

            // console.log("check " + type + " on blur");
            if (!location) {
                return;
            }
            switch (type) {
                case "country":
                    if (vm.errorCountry == false) {
                        return;
                    }
                    for (let value of countries) {
                        if (value.toLowerCase() == location.toLowerCase()) {
                            vm.errorCountry = false;
                            vm.form.country.$valid = true;
                            getStates(value);
                            break;
                        }
                        vm.errorCountry = true;
                        vm.form.country.$valid = false;
                    }
                    break;
                case "state":
                    if (vm.errorState == false) {
                        return;
                    }
                    for (let value of vm.states) {
                        if (value.toLowerCase() == location.toLowerCase()) {
                            vm.errorState = false;
                            vm.form.state.$valid = true;
                            getCities(vm.user.country, value);
                            break;
                        }
                        vm.errorState = true;
                        vm.form.state.$valid = false;
                    }
                    break;
                case "city":
                    if (vm.errorCity == false) {
                        return;
                    }
                    for (let value of vm.cities) {
                        if (value.toLowerCase() == location.toLowerCase()) {
                            vm.errorCity = false;
                            vm.form.city.$valid = true;
                            break;
                        }
                        vm.errorCity = true;
                        vm.form.city.$valid = false;

                    }
                    break;
            }
        }

        function getCities(country, state, action) {
            if (!country || !state) {
                return;
            }

            vm.user.state = state;

            if (action !== "init") {
                vm.user.city = '';
                vm.errorState = false;
                vm.form.state.$valid = true;
            }

            if (vm.states) {
                vm.isFetchingCities = true;
                vm.cities = null;
                userService.getCityList(country, state)
                    .then((response) => {
                        vm.cities = response;
                        if (vm.location.city) {
                            let cityIndex = _.findIndex(vm.cities, (city) => {
                                return city.includes(vm.location.city);
                            });

                            vm.user.city = (cityIndex >= 0) ? vm.cities[cityIndex] : '';
                        }
                    })
                    .catch((error) => {
                        console.error("err", error);
                    })
                    .finally(() => {
                        vm.isFetchingCities = false;
                    });
            }
        }

        function updateCurrentUser(user) {
            let oldUser=Object.assign({},$rootScope.user);
            $rootScope.user = user;
            $rootScope.user.trial = oldUser.trial;
            $rootScope.user.payment = oldUser.payment;
            $rootScope.user.remainintTrialDays = oldUser.remainintTrialDays;
            Store.set('userData', JSON.stringify($rootScope.user));
        }

        function selectProfilePic(media) {
            return fileService.imageUploadWithCrop(media, 'user', vm.user, true)
                .then(selectProfilePicSuccess)
                .catch(selectProfilePicError);

            function selectProfilePicSuccess(response) {
                if (response.user) {
                    vm.user = response.user;
                    updateCurrentUser(vm.user);
                    toastService.toast({
                        message: "Some error occured, try again!",//userConstants.messages.profilePictureUpdateSuccess,
                        type: 'success',
                        delay: 2000
                    });
                }
            }

            function selectProfilePicError(error) {
                console.error('Closed modal', error);
            }
        }

		function validateEmail(str) {
			//const paragraph = 'a@e.com';
			const regex = /^[\w!#$%&'*+/=?^_`{|}~-]+@[\w.-]+\.\w+$/;
			let found = str.match(regex);
			if(!found){
				return false;
			}
			else return true;

		}
		function verifyPassword(){

				let modelInstance = $uibModal.open({
					animation: true,
					size: "md",
					controller: "passwordVerifyController",
					controllerAs: "passwordVerifyCtrl",
                    template : require('../../pug/user/verify-password.pug').default,
					resolve: null,
				});

			modelInstance.result.then(data=>{
				if(data.message == 'Password Match') {
					vm.isEmailChange = true;
					vm.user.email = vm.email;
					vm.user.verified = 'false';
                    vm.logoutConfirm = true;
					editProfile();
				}
			}).catch(function() {
				modelInstance.close();
			});
		}


        function editProfile() {

			//console.log("log",vm);

			if ((!countries.includes(vm.user.country) || !vm.user.zipCode) && tabs.tabValue == 'contactInfo') {
            	vm.countryError = "Some error occured, try again!"//userConstants.messages.profileAddressInvalid
                // toastService.toast({
                //     message: userConstants.messages.profileAddressInvalid,
                //     type: 'error',
                //     delay: 2000
                // });
                return false;
            }

            if ((!vm.user.city || !vm.user.state) && tabs.tabValue == 'contactInfo') {
            	//console.log('city or sate true')
				vm.stateError = "Some error occured, try again!"//userConstants.messages.profileAddressInvalid;
                // toastService.toast({
                //     message: userConstants.messages.profileAddressInvalid,
                //     type: 'error',
                //     delay: 2000
                // });
                return false;
            }
			if ((!vm.user.address) && tabs.tabValue == 'contactInfo') {
            	//console.log(vm.user.location);
				vm.addressError = "Some error occured, try again!"
				return false;
            }

			if (!vm.form.$valid && !vm.email && tabs.tabValue == 'accountInfo') {
				vm.emailError = "Please enter valid email";
				return false;
			}
			if(vm.user.email !== vm.email && !vm.isEmailChange) {
				if(validateEmail(vm.email)){
					verifyPassword();
					return;
				}
				else{
					vm.emailError = "Please enter valid email";
					return false;
				}

			}

            if (!vm.form.$valid && !vm.user.dob && tabs.tabValue == 'contactInfo') {
                vm.dobError = "Some error occured, try again!"//userConstants.messages.userDobInvalid
            	// toastService.toast({
                //     message: userConstants.messages.userDobInvalid,
                //     type: 'error',
                //     delay: 2000
                // });
                return false;
            }
			if (!vm.form.$valid && !vm.user.gender && tabs.tabValue == 'personalInfo') {
				vm.genderError = 'Please select gender';
				// toastService.toast({
				// 	message: userConstants.messages.genderError,
				// 	type: 'error',
				// 	delay: 2000
				// });
				return false;
			}

			if (!vm.form.$valid && !vm.user.firstName && tabs.tabValue == 'personalInfo') {
				vm.firstNameError = "Please enter first name."
				return false;
			}

			if (!vm.form.$valid && !vm.user.lastName && tabs.tabValue == 'personalInfo') {
				vm.lastNameError = "Please enter last name."
				return false;
			}

			if (!vm.form.$valid && !vm.user.username  && tabs.tabValue == 'accountInfo') {
				vm.userNameError = "Invalid username"
				// toastService.toast({
				// 	message: 'Invalid username',
				// 	type: 'error',
				// 	delay: 2000
				// });
				return false;
			}

			if (!vm.form.$valid && !vm.user.contactNumber && tabs.tabValue == 'contactInfo') {
				//console.log("contact", vm.form.contact);
				//console.log("contact", vm.form.contact.$invalid);
				vm.phoneError = "Please enter valid contact";
				return false;
			}


            //Check if country-code and number both are set.
            if (vm.user.contactNumber && !vm.user.countryCode && tabs.tabValue == 'contactInfo') {
                toastService.toast({
                    message: "Some error occured, try again!",//userConstants.messages.userContactInvalid,
                    type: 'error',
                    delay: 2000
                });
                return false;
            } else if ((!vm.user.contactNumber && !vm.user.countryCode && tabs.tabValue == 'contactInfo') || (vm.user.countryCode && !vm.user.contactNumber && tabs.tabValue == 'contactInfo')) {
                vm.user.countryCode = 0;
                vm.user.contactNumber = null;
            }

			if (vm.user.contactNumber && !vm.user.countryCode && tabs.tabValue == 'contactInfo') {
				toastService.toast({
					message: "Some error occured, try again!",//userConstants.messages.userContactInvalid,
					type: 'error',
					delay: 2000
				});
				return false;
			}

            if(tabs.tabValue == 'personalInfo' || tabs.tabValue == 'accountInfo') {
            	vm.user.makeOptional = true;
			} else {
            	vm.user.makeOptional = false;
			}

            let user;

            $rootScope.loadingBtn('save', 'Loading');
			if(vm.selectedInterests.length > 0) {
				let selectedInterests = "";
				_.forEach(vm.selectedInterests, function (interest, n) {
					if(n == 0) {
						selectedInterests += interest.text;
					} else {
						selectedInterests += ',' + interest.text;
					}

				})
				vm.selectedInterests = selectedInterests;
			} else {
				vm.selectedInterests = "";
			}
			vm.user.interest = vm.selectedInterests;
			vm.user.isEmailChange = vm.isEmailChange;
			if (vm.user.countryCode === null) {
				vm.user.countryCode = 0;
			}
            userService.editProfile(vm.user)
                .then((response) => {
					vm.isEmailChange = false;
                    user = response.data.User;
                    updateCurrentUser(user);
                    toastService.toast({
                        message: 'Profile Updated Successfully!',//userConstants.messages.profileUpdateSuccess,
                        type: 'success',
                        delay: 2000
                    });
                    vm.form.$submitted = true;
                    close(response)
                    if(vm.logoutConfirm) userService.logout();
                    //$state.go('userProfile', { userId: $rootScope.user.id });
                })
                .catch((response) => {
					if(response.message == 'User already exist') {
						vm.emailExistError = response.message;
						vm.checkEmail = vm.email;
                        vm.isEmailChange = false;
                        vm.logoutConfirm = false;
                        toastService.toast({
                            message: "This email is already associated with another account.",
                            type: 'error',
                            delay: 2000
                        });
                        return false;
					}
					vm.isEmailChange = false;
                    vm.logoutConfirm = false;
                    toastService.toast({
                        message: "Some error occured, try again!",//userConstants.messages.profileUpdateError,
                        type: 'error',
                        delay: 2000
                    });
                })
                .finally(() => {
                    $rootScope.loadingBtnComplete('save', 'Save');
                });
        }

        this.$onInit = function() {
			//console.log("hello this is my first grunt testing");
        	if(tabs.tabValue == 'accountInfo') {
        		vm.headingText = 'Account Info';
			} else if(tabs.tabValue == 'personalInfo') {
        		vm.headingText = 'Personal Info';
			} else if(tabs.tabValue == 'contactInfo') {
        		vm.headingText = 'Contact Info';
			}
            if (vm.user.interest) {
                vm.selectedInterests = vm.user.interest.split(",");
            }
            vm.email = vm.user.email;
			vm.teachingstyle = vm.user.teachingStyle;
            if (vm.user.country) {
                getStates(vm.user.country, "init")
                    .then((response) => {
                        getCities(vm.user.country, vm.user.state, "init");
                    })
                    .catch((err) => {
                        // console.log("err");
                    });
            }
        };

		function close(data) {
			$uibModalInstance.close(data);
		}

		function changePassword() {
			let modelInstance = $uibModal.open({
				animation: true,
                template : require('../../pug/user/change-password.pug').default,
				controller: 'changePasswordController',
				controllerAs: 'changePwdCtrl'
			});

			modelInstance.result.catch(function () {
				modelInstance.close();
			})
		}

        /**
         * If a state change is detected, when the task controller is active, then it is checked whether the modal window is open, if yes then it is closed
         */
        $scope.$on("$stateChangeStart", function(evt, toState, toParams, fromState, fromParams) {

            if (vm.form.$dirty && !vm.form.$pristine && !vm.form.$submitted) {
                evt.preventDefault();
                $rootScope.isLoading = false;
                let confirmData = {
                    message: "Some error occured, try again!",//userConstants.messages.leavePageConfirmation,
                    modalSize: 'md',
                    type: 'danger'
                };

                commonService.confirm(confirmData)
                    .then(function(confirmation) {
                        if (confirmation.result) {
                            vm.form.$submitted = true;
                            $state.go(toState, toParams);
                        }
                    });
            }
        });
    }
export default userEditController;
