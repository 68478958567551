let angular = window.angular;



bookService.$inject = ['$http', '$q', 'toastService', 'appConstants'];

function bookService($http, $q, toastService, appConstants) {
    //   let basePath = '/api/books/';
    angular.extend(this, {
        addReview,
        setStudentBook,
        getStudentBook,
        assignBook,
        getUserBooks,
        getAssignedStudent,
        viewBook,
        editBook,
        addBook,
        print,
        deleteBook,
        search,
        searchFromMyBookLog,
        getResources,
        unassignBook,
        deleteReview,
        searchedPrint,
        getBookShelfCategories,
        addShelfCategory,
        updateShelfCategory,
        deleteShelfCategory,
        addBookToShelf,
        addStudentStatus,
        getFeaturedBooks,
        setShowMoreFeatureBook,
    });

    function unassignBook(bookAssignId, toDeleteAssignment) {
        let isDeleteAssignment = false;
        if (angular.isDefined(toDeleteAssignment) && toDeleteAssignment) {
            isDeleteAssignment = toDeleteAssignment;
        }
        // api/books/unassignBook/90
        var defer = $q.defer();

        $http({
            method: 'DELETE',
            url: appConstants.baseUrl + '/api/books/unassignBook/' + bookAssignId,
            data: {
                isDeleteAssignment: isDeleteAssignment
            }
        })
            .then(function (resp) {
                defer.resolve(resp);
            }).catch((err) => {
                defer.reject(err);
            });
        return defer.promise;
    }

    function getResources(queryParams) {
        let deferred = $q.defer(),
            getResourcesPromise;

        getResourcesPromise = $http({
            method: 'GET',
            url: appConstants.baseUrl + '/api/books/getAssignBooks',
            params: queryParams
        });

        getResourcesPromise
            .then((response) => {
                let books = response.data.data;

                deferred.resolve(books);
            })
            .catch((err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    }

    function getFeaturedBooks() {
        let deferred = $q.defer(),
            getFeaturedBooksPromise;

        getFeaturedBooksPromise = $http({
            method: 'GET',
            url: appConstants.baseUrl + '/api/books/getFeaturedBooks'
        });

        getFeaturedBooksPromise
            .then((response) => {
                let books = response.data.data;

                deferred.resolve(books);
            })
            .catch((err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    }
    function setShowMoreFeatureBook(id) {
        let deferred = $q.defer(),
            getFeaturedBooksPromise;

        getFeaturedBooksPromise = $http({
            method: 'GET',
            url: appConstants.baseUrl + '/api/books/setShowMoreFeatureBook/' + id
        });

        getFeaturedBooksPromise
            .then((response) => {
                let books = response.data.data;

                deferred.resolve(books);
            })
            .catch((err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    }


    function setStudentBook(bookId, studentId) {
        var defer = $q.defer();

        $http({
            method: 'POST',
            url: appConstants.baseUrl + '/api/books/setStudentBook',
            data: { bookId, studentId, isAssign: false }
        })
            .then(function (resp) {
                defer.resolve(resp.data.message);
            }).catch((err) => {
                defer.reject(err);
            });
        return defer.promise;
    }

    function getStudentBook(id) {
        var defer = $q.defer();

        $http({
            method: 'GET',
            url: appConstants.baseUrl + '/api/books/getStudentBook/' + id
        })
            .then(function (resp) {

                defer.resolve(resp.data.data);
            }).catch((err) => {
                defer.reject(err);
            });
        return defer.promise;
    }

    function editBook(book) {
        var defer = $q.defer();

        $http({
            method: 'POST',
            url: appConstants.baseUrl + '/api/books/edit',
            data: book
        })
            .then(function (resp) {

                defer.resolve(resp.data);
            }).catch((err) => {
                defer.reject(err);
            });
        return defer.promise;
    }

    function print() {

        let url = appConstants.baseUrl + '/api/books/print?' + 'authToken=' + Store.get('authorizationToken');

        let form = document.createElement('FORM');
        form.method = 'POST';
        form.action = url;
        form.target = '_self';
        // for (let i in printData) {
        // 	if (printData.hasOwnProperty(i)) {
        // 		let input = document.createElement('input');
        // 		input.type = 'hidden';
        // 		input.name = i;
        // 		input.value = JSON.stringify(printData[i]);
        // 		form.appendChild(input);
        // 		console.log('input', input)
        // 	}
        // }
        document.body.appendChild(form);
        window.open('', '_self');
        form.submit();
        document.body.removeChild(form);
        toastService.toast({
            message: 'Print in process',
            type: 'success',
            delay: 5000
        });

    }

    function addReview(bookId, studentId) {
        var defer = $q.defer();

        $http({
            method: 'POST',
            url: appConstants.baseUrl + '/api/students/books/comment',
            //data: { bookId, studentId: student.studentId, comment: student.comment, rating: student.rating, isRead: student.isRead }
            data: { bookId, studentId }

        })
            .then(function (resp) {
                defer.resolve(resp);
            }).catch((err) => {
                defer.reject(err);
            });
        return defer.promise;
    }

    function deleteBook(bookId, isDeleteAssignment) {
        var defer = $q.defer();

        $http({
            method: 'DELETE',
            url: appConstants.baseUrl + '/api/books/remove/' + bookId,
            data: {
                isDeleteAssignment: isDeleteAssignment
            }
        })
            .then(function (resp) {
                defer.resolve(resp);
            }).catch((err) => {
                defer.reject(err);
            });
        return defer.promise;
    }

    function deleteReview(studentId, bookId) {
        var defer = $q.defer();

        $http({
            method: 'POST',
            url: appConstants.baseUrl + '/api/books/reviewDelete',
            data: {
                bookId: bookId,
                studentId: studentId
            }
        })
            .then(function (resp) {
                defer.resolve(resp);
            }).catch((err) => {
                defer.reject(err);
            });
        return defer.promise;
    }


    function getUserBooks(queryData, typeId) {
        let deferred = $q.defer(),
            getBooksPromise,
            queryParams = { skip: 0 };

        if (queryData) {
            queryParams.skip = queryData.skip;
        }
        if (typeId) {
            queryParams.typeId = typeId;
        }

        // getBooksPromise = $http.get('/api/books/' + 'viewAll');
        getBooksPromise = $http({
            method: 'GET',
            url: appConstants.baseUrl + '/api/books/viewAll',
            params: queryParams
        });

        getBooksPromise
            .then((response) => {
                let books = response.data.data;

                deferred.resolve(books);
            })
            .catch((err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    }

    function getAssignedStudent(bookId) {
        let deferred = $q.defer(),
            assignedStudentPromise;

        assignedStudentPromise = $http.get(appConstants.baseUrl + '/api/students/books/' + 'view/' + bookId);
        assignedStudentPromise
            .then((response) => {
                // students = _.map(response.data.data, (obj) => obj.studentId);
                // students = studentService.getStudentsById(students);

                deferred.resolve(response.data.data);
            })
            .catch((err) => {
                deferred.reject(err);
            });
        return deferred.promise;
    }

    function viewBook(bookId) {
        let deferred = $q.defer(),
            bookViewPromise;

        bookViewPromise = $http.get(appConstants.baseUrl + '/api/books/' + 'view/' + bookId);
        bookViewPromise
            .then((response) => {

                let book = response.data.data;
                // savebookToList(book.id, book);
                deferred.resolve(book);
            })
            .catch((err) => {

                deferred.reject(err);
            });

        return deferred.promise;
    }


    function assignBook(params) {
        let deferred = $q.defer(),
            assignStudentPromise,
            queryParams = {};

        if (params) {
            queryParams.studentIds = params.studentIds || [];
            queryParams.bookId = params.bookId || 0;
            queryParams.isAssignment = params.isAssignment || false;
            queryParams.assignment = params.assignment || {};
            queryParams.isRequestFromWeb = params.isRequestFromWeb;
        }
        assignStudentPromise = $http({
            method: 'POST',
            url: appConstants.baseUrl + '/api/students/books/assignToAssignment',
            // '/api/students/books/' + 'assign',
            data: queryParams
        });

        assignStudentPromise
            .then((response) => {
                let data = response.data.data;
                deferred.resolve(data);
            })
            .catch((err) => {
                deferred.reject(err);
            });

        return deferred.promise;
    }

    function search(data) {

        let defer = $q.defer(),
            addBookPromise,
            Queryparams = {};

        if (data.searchBy) {
            Queryparams.searchBy = data.searchBy || '';
        }

        if (data.values) {
            Queryparams.values = data.values || '';
        }

        Queryparams.skip = data.skip || 0;

        addBookPromise = $http({
            method: 'GET',
            url: appConstants.baseUrl + '/api/books/' + 'search',
            params: Queryparams
        });

        addBookPromise.then(function (resp) {
            defer.resolve(resp);
        }).catch((err) => {
            defer.reject(err);
        });
        return defer.promise;
    }

    function searchFromMyBookLog(data, queryData) {
        let defer = $q.defer(),
            searchFromMyBookLogPromise,
            queryParams = { skip: 0 };

        if (queryData) {
            queryParams.skip = queryData.skip;
        }

        searchFromMyBookLogPromise = $http({
            method: 'POST',
            url: appConstants.baseUrl + '/api/books/advanceSearch',
            data: data,
            params: queryParams
        });

        searchFromMyBookLogPromise.then(function (resp) {
            defer.resolve(resp);
        }).catch((err) => {
            defer.reject(err);
        });
        return defer.promise;
    }

    function searchedPrint(body) {
        let url = appConstants.baseUrl + '/api/books/searchPrint?' + 'authToken=' + Store.get('authorizationToken');

        let form = document.createElement('FORM');
        form.method = 'POST';
        form.action = url;
        form.target = '_self';
        for (let data in body) {

            if (body.hasOwnProperty(data)) {
                let input = document.createElement('input');
                input.type = 'hidden';
                input.name = data;
                input.value = JSON.stringify(body[data]);
                form.appendChild(input);
            }
        }
        document.body.appendChild(form);
        window.open('', '_self');
        form.submit();
        document.body.removeChild(form);
        toastService.toast({
            message: 'Print in process',
            type: 'success',
            delay: 5000
        });
    }

    //--ADD
    function addBook(book) {
        var defer = $q.defer(),
            addBookPromise;
        let new_data = {
            authors: book.authors || null,
            title: book.title,
            bookId: book.bookId,
            description: book.description || null,
            img: book.img || null,
            publisher: book.publisher || null,
            publishedDate: book.publishedDate || null,
            isbn: book.isbn,
            pageCount: book.pageCount
        };

        addBookPromise = $http({
            method: 'POST',
            url: appConstants.baseUrl + '/api/books/add',
            data: new_data
        });

        addBookPromise.then(function (resp) {
            defer.resolve(resp);
        }).catch((err) => {
            defer.reject(err);
        });
        return defer.promise;
    }

    function getBookShelfCategories() {
        var defer = $q.defer();

        $http({
            method: 'GET',
            url: appConstants.baseUrl + '/api/books/getBookShelfCategories'
        })
            .then(function (resp) {

                defer.resolve(resp.data.data);
            }).catch((err) => {
                defer.reject(err);
            });
        return defer.promise;
    }
    function addShelfCategory(shelfData) {
        var defer = $q.defer();

        $http({
            method: 'POST',
            url: appConstants.baseUrl + '/api/books/addShelfCategory',
            data: shelfData
        })
            .then(function (resp) {

                defer.resolve(resp.data.data);
            }).catch((err) => {
                defer.reject(err);
            });
        return defer.promise;
    }

    function updateShelfCategory(shelfData) {
        var defer = $q.defer();

        $http({
            method: 'POST',
            url: appConstants.baseUrl + '/api/books/updateShelfCategory',
            data: shelfData
        })
            .then(function (resp) {

                defer.resolve(resp.data.data);
            }).catch((err) => {
                defer.reject(err);
            });
        return defer.promise;
    }

    function deleteShelfCategory(shelfData) {
        var defer = $q.defer();

        $http({
            method: 'POST',
            url: appConstants.baseUrl + '/api/books/deleteShelfCategory',
            data: shelfData
        })
            .then(function (resp) {

                defer.resolve(resp.data.data);
            }).catch((err) => {
                defer.reject(err);
            });
        return defer.promise;
    }
    function addBookToShelf(id, shelfData) {
        var defer = $q.defer();

        $http({
            method: 'POST',
            url: appConstants.baseUrl + '/api/books/addBookToShelf/' + id,
            data: shelfData
        })
            .then(function (resp) {

                defer.resolve(resp.data.data);
            }).catch((err) => {
                defer.reject(err);
            });
        return defer.promise;
    }
    function addStudentStatus(data) {
        var defer = $q.defer();

        $http({
            method: 'POST',
            url: appConstants.baseUrl + '/api/students/books/addStudentStatus',
            data: data
        })
            .then(function (resp) {

                defer.resolve(resp.data.data);
            }).catch((err) => {
                defer.reject(err);
            });
        return defer.promise;
    }

}


export default bookService;
