let angular = window.angular;
    bookPrintModalController.$inject = ['bookData', 'bookService', 'studentService', '$state', '$stateParams', '_', 'commonService', 'toastService', 'moment', 'lessonConstants', '$uibModal', 'fileService', 'appConstants', '$rootScope', '$q', '$uibModalInstance', 'bookConstants'];

    function bookPrintModalController(bookData, bookService, studentService, $state, $stateParams, _, commonService, toastService, moment, lessonConstants, $uibModal, fileService, appConstants, $rootScope, $q, $uibModalInstance, bookConstants) {

        angular.extend(this, {
            close,
            print
        });

        var vm = this;
        vm.printData = 'searched';

        function close() {
            $uibModalInstance.close();
        }

        function validateSearchedPrintData() {
            /**
             * check if search query is entered
             */
            if (bookData.searchData.values) {
                /**
                 * check if search query has minimum 2 characters
                 */
                if (bookData.searchData.values.trim().length >= 2) {
                    /**
                     * check if atleast one 'search with' is selected
                     */


                    /**
                     * check if query length is greater than 100
                     */
                    if (bookData.searchData.values.trim().length > 100) {
                        toastService.toast({
                            message: bookConstants.messages.maxCharacterKeywordError,
                            type: 'error',
                            delay: 3000
                        });
                        return false;
                    } else {
                        return true;
                    }
                }
                /**
                 * show toast for entering atleast 2 characters
                 */
                else {
                    toastService.toast({
                        message: bookConstants.messages.minCharacterKeywordError,
                        type: 'error',
                        delay: 3000
                    });
                    return false;
                }
            }
            /**
             * check if search query is not entered and one of the 'search with' is selected
             */
            else if (!bookData.searchData.values) {
                if ((bookData.searchData.date.to && !bookData.searchData.date.from) || (!bookData.searchData.date.to && bookData.searchData.date.from)) {
                    toastService.toast({
                        message: bookConstants.messages.enterStartEndDate,
                        type: 'error',
                        delay: 3000
                    });
                    return false;
                } else {
                    return true;
                }

            } else {
                return true;
            }

        }

        function print() {
            switch (vm.printData) {
                case 'searched':
                    if (!validateSearchedPrintData()) {
                        return false;
                    }
                    bookService.searchedPrint(bookData.searchData);
                    close();
                    break;

                case 'all':
                    bookService.print();
                    close();
                    break;

                default:
                    break;
            }
        }
    }

export default bookPrintModalController;
