let angular = window.angular;
createAssignmentController.$inject = [
  "$rootScope",
  "$scope",
  "$uibModalInstance",
  "assignmentService",
  "options",
  "moment",
  "calendarConstants",
  "studentService",
  "calendarService",
  "commonService",
  "toastService",
  "$q",
  "$uibModal",
  "lessonService",
  "appConstants",
  "lessonConstants",
];

function createAssignmentController(
  $rootScope,
  $scope,
  $uibModalInstance,
  assignmentService,
  options,
  moment,
  calendarConstants,
  studentService,
  calendarService,
  commonService,
  toastService,
  $q,
  $uibModal,
  lessonService,
  appConstants,
  lessonConstants
) {
  let vm = this,
    isRequestSent = false,
    previousAssignmentType = null;

  // vm.displayTitle = null;
  vm.assignmentTitle = null;
  vm.noteTitle = null;
  vm.allDayEvent = false;
  vm.emptyFieldErrorclass = "";

  vm.activeTab = "assignment";
  vm.category = "assignment";

  vm.dateDuration = 0;
  vm.timeDuration = moment.duration(1, "hour").asSeconds();

  vm.startDate = null;
  vm.startTime = null;

  vm.endDate = null;
  vm.endTime = null;

  vm.studentId = null;
  vm.selectedStudents = [];
  let students_array = _.filter(
    studentService.students,
    (obj) => obj.role === "STUDENT"
  );
  students_array = _.map(students_array, (student, index) => {
    index++;
    return {
      type: "student",
      id: student.id,
      displayName: student.name,
      name: student.name + " (S" + index + ")",
      mediaUrl: student.mediaUrl || null,
    };
  });

  vm.students = students_array;
  vm.resourceBookIds = [];
  vm.books = [];
  vm.emptyFieldError = false;
  vm.emptyFieldErrorMessage = "Fill Empty Fields";
  vm.assignmentEndDataBeforeStartError = false;
  vm.assignmentEndDataBeforeStartErrorMessage =
    calendarConstants.messages.assignmentEndDateError;
  vm.newResources = [];

  vm.colors = appConstants.newColors;
  vm.lessonColor = null;
  vm.defaultCourseList = lessonConstants.defaults.defaultCourseList;
  vm.defaultLessonList;
  vm.credits = lessonConstants.defaults.credits;
  vm.allDayLessonDuration = lessonConstants.defaults.allDayDuration;
  vm.defaultAssignmentTypeCondition =
    lessonConstants.defaults.defaultAssignmentTypeCondition;
  vm.lessonSelectedFromList = false;

  vm.isGrading = false;

  angular.extend(this, {
    close,
    onEndDateChange,
    onStartDateChange,
    createAssignment,
    toggleTab,
    isTouched,
    addStudentsTag,
    openBookSearchModal,
    removeBook,
    openResourceModal,
    removeNewResource,
    editNewResource,
    removeErrorMessage,
    setStudentLabelColor,
    addCustomType,
    studentVerify,
  });

  function setStudentLabelColor(color) {
    vm.lessonColor = color;
  }

  function getBookIdFromResources() {
    vm.resourceBookIds = _.map(vm.books, "bookId");
  }

  function getDefaultResourceData(books) {
    let willAddBooks = [],
      finalBooks = [];
    for (let i = 0; i < books.length; i++) {
      let index = _.findIndex(vm.books, { bookId: books[i].bookId });

      if (index < 0) {
        let object = books[i];

        // object.startDay = 1;
        // object.startWeek=  1,
        // object.endDay = vm.days,
        // object.endWeek = vm.weeks;
        willAddBooks.push(object);
      }
    }
    finalBooks = vm.books.concat(willAddBooks);
    return finalBooks;
  }

  function removeBook(bookId) {
    let index = _.findIndex(vm.books, { id: bookId });
    if (index >= 0) {
      vm.books.splice(index, 1);
    }
  }

  function openBookSearchModal() {
    getBookIdFromResources();
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "lessonBookSearchController",
      controllerAs: "lessonBookSearchCtrl",
      template: require("../../pug/lesson/search-book.pug").default,
      resolve: {
        bookData: {
          bookIds: vm.resourceBookIds,
          // addGlobally: true
        },
      },
    });
    modelInstance.result
      .then(function (books) {
        books = getDefaultResourceData(books);
        vm.books = angular.copy(books);
      })
      .catch(function () {
        modelInstance.close();
      });
  }

  function addStudentsTag(studentQuery) {
    vm.emptyFieldErrorclass = "hasSuccess";
    vm.emptyFieldError = false;
    if (!studentQuery) {
      var deferred = $q.defer();
      deferred.resolve(vm.students);
      return deferred.promise;
    }

    let newStudentList = [];
    let findStudent = _.map(vm.students, function (students) {
      if (_.includes(students.name.toLowerCase(), studentQuery.toLowerCase())) {
        newStudentList.push(students);
      }
    });

    var deferred = $q.defer();
    deferred.resolve(newStudentList);

    return deferred.promise;
  }

  function isTouched(field) {
    if (angular.isDefined(field)) {
      return field.$touched || vm.form.$submitted;
    } else {
      return false;
    }
  }

  function toggleTab(tab) {
    vm.activeTab = tab;
  }

  function close(data = {}) {
    angular.extend(data, {
      model: "studentAssignment",
    });

    $uibModalInstance.close(data);
  }

  $scope.courseSelect = function ($item, $model, $label) {
    let courseName = $item || $model || $label;

    lessonService.getCourseLessons(courseName).then((lessonData) => {
      vm.defaultLessonList = lessonData;
    });
  };

  function addCustomType(assignmentType, action) {
    previousAssignmentType = vm.category;
    if (action == "add" && assignmentType.name != "custom") {
      vm.category = assignmentType.name;
      return true;
    }

    let modelInstance = $uibModal.open({
      animation: true,
      size: "md",
      controller: "assignmentTypeController",
      controllerAs: "assignmentTypeCtrl",
      template: require("../../pug/lesson/add-assignment-type.pug").default,
      backdrop: "static",
      keyboard: false,
      resolve: {
        resourceData: {
          // lessonId: editAssignmentParams.studentsLesson.lessonId,
          assignmentType: {
            name: assignmentType.name,
            lessonId: assignmentType.lessonId,
            id: assignmentType.id,
            action: action,
          },
        },
      },
    });

    modelInstance.result
      .then(function (newAssignmentType) {
        if (newAssignmentType) {
          if (action == "add") {
            vm.assignmentType.push(newAssignmentType);

            /**
             * Update assignment category
             */
            vm.category = newAssignmentType.name;
            previousAssignmentType = newAssignmentType.name;
          } else if (action == "edit") {
            if (newAssignmentType.assignmentTypeStatus == "delete") {
              commonService.removeFromArray(
                newAssignmentType.id,
                "id",
                vm.assignmentType
              );
              if (previousAssignmentType == newAssignmentType.name) {
                vm.category = "assignment";
                previousAssignmentType = "assignment";
              }
            } else {
              for (let i = 0; i < vm.assignmentType.length; i++) {
                if (vm.assignmentType[i].id == newAssignmentType.id) {
                  vm.assignmentType[i].name = newAssignmentType.name;
                  vm.assignmentType[i].lessonId = newAssignmentType.lessonId;
                }
              }
              /**
               * Update assignment category
               */
              vm.category = newAssignmentType.name;
              previousAssignmentType = newAssignmentType.name;
            }
          }
        } else {
          vm.category = previousAssignmentType;
        }
      })
      .catch(function () {
        modelInstance.close();
      });
  }

  function setLessonAssignmentData() {
    let data;

    /**
     * Get student assignment Ids
     */
    let studentIds = [];
    if (!_.isEmpty(vm.selectedStudents)) {
      _.map(vm.selectedStudents, function (student) {
        studentIds.push(student.id);
      });
    }

    if (!vm.assignmentTitle) {
      vm.assignmentTitle = $(".assignmentTitle").html().toString();
    }
    if (!vm.noteTitle) {
      vm.noteTitle = $(".noteTitle").html().toString();
    }
    let startTime = moment(vm.startDateTime),
      endTime = moment(vm.endDateTime);
    let momentDiff = moment.duration(endTime.diff(startTime)),
      hours = parseInt(momentDiff.asHours()),
      minutes = parseInt(momentDiff.asMinutes()) - hours * 60,
      duration = hours + ":" + minutes;
    let startDateTime = commonService.convertToUTC(vm.startDateTime);
    let utcStartDateTime = startDateTime.format("Y-MM-DD HH:mm:00");

    // vm.assignmentTitle = commonService.trimInputField(commonService.removeHTMLTags(vm.assignmentTitle));
    // vm.noteTitle = commonService.trimInputField(commonService.removeHTMLTags(vm.noteTitle));

    let assignments = [
      {
        category: vm.category,
        week: lessonConstants.defaults.weeks,
        day: lessonConstants.defaults.days,
        position: lessonConstants.defaults.positions,
        title: vm.assignmentTitle,
        noteTitle: vm.noteTitle,
        lessonDate: moment(vm.startDateTime).format("Y-MM-DD"),
        lessonDuration: duration,
        isGradingEnable: vm.isGrading,
        isAddon: vm.displayTitle && vm.displayTitle.id ? true : false,
      },
    ];
    //console.log('all day event', vm.allDayEvent);
    let eDate;
    if (vm.allDayEvent) {
      eDate = moment(vm.startDateTime).format("Y-MM-DD");
    } else {
      eDate = moment(vm.endDateTime).add(1, "days").format("Y-MM-DD");
    }
    //let eDate = moment(vm.startDateTime).format("Y-MM-DD");
    data = {
      lesson: {
        assignmentDays: [vm.startDateTime.format("ddd").toLowerCase()],
        books: vm.books,
        color: vm.lessonColor,
        course: vm.course,
        credits: parseFloat(vm.credits),
        weeks: lessonConstants.defaults.weeks,
        days: lessonConstants.defaults.days,
        duration: duration, //vm.allDayEvent ? vm.allDayLessonDuration : duration,
        startDate: moment(vm.startDateTime).format("Y-MM-DD"),
        //endDate: moment(vm.endDateTime)
        //	.add(1, "days")
        //	.format("Y-MM-DD"),
        endDate: eDate,
        startTime: vm.allDayEvent
          ? lessonConstants.defaults.startTime
          : moment(vm.startDateTime).format("HH:mm"),
        utcStartDateTime: utcStartDateTime,
        isGroup: true,
        name: vm.displayTitle,
        newResources: vm.newResources,
        //notepad: vm.noteTitle,
        category: vm.category,
        studentId: studentIds,
        selectedStudents: vm.selectedStudents,
        // title: vm.assignmentTitle,
        lessonAssignment: assignments,
        newAssignmentType: [],
      },
    };

    if (vm.assignmentId) {
      data.studentAssignment.id = vm.assignmentId;
    } else {
      data.lesson.books = data.lesson.books.map((book) => {
        let b = angular.copy(book);
        b.startDay = 1;
        b.startWeek = 1;
        b.endDay = 1;
        b.endWeek = 4;
        return b;
      });
    }

    return data;
  }

  function setAssignmentData() {
    let data;

    /**
     * Get student assignment Ids
     */
    let studentIds = [];
    if (!_.isEmpty(vm.selectedStudents)) {
      _.map(vm.selectedStudents, function (student) {
        studentIds.push(student.id);
      });
    }

    if (!vm.assignmentTitle) {
      vm.assignmentTitle = $(".assignmentTitle").html().toString();
    }

    if (!vm.noteTitle) {
      vm.noteTitle = $(".noteTitle").html().toString();
    }

    data = {
      studentAssignment: {
        course: vm.course,
        credits: vm.credits,
        color: vm.lessonColor,
        displayTitle: vm.displayTitle,
        title: vm.assignmentTitle,
        weeks: vm.weeks,
        category: vm.category,
        startDate: vm.startDateTime.format("Y-MM-DD HH:mm:00"),
        endDate: vm.endDateTime.format("Y-MM-DD HH:mm:00"),
        studentId: studentIds,
        noteTitle: vm.noteTitle,
        books: vm.books,
        newResources: vm.newResources,
        assignmentDays: [vm.startDateTime.format("ddd").toLowerCase()],
      },
    };

    if (vm.assignmentId) {
      data.studentAssignment.id = vm.assignmentId;
    }
    return data;
  }

  function studentVerify(student) {
    if (vm.displayTitle && vm.displayTitle.id && vm.displayTitle.studentId) {
      if (!vm.displayTitle.studentId.includes(student.id)) {
        toastService.toast({
          message: calendarConstants.messages.studentNotAddedToLesson,
          type: "error",
          delay: 3000,
        });
        return false;
      }
    }
  }

  function createAssignment() {
    if (!vm.selectedStudents.length && $rootScope.user.userRole !== "STUDENT") {
      vm.form.$valid = false;

      vm.emptyFieldErrorclass = "student-empty-error";
    } else {
      vm.emptyFieldErrorclass = "";
    }

    let data;

    if (!vm.form.$valid || isRequestSent) {
      vm.emptyFieldError = true;

      if (
        !vm.course &&
        !vm.displayTitle &&
        !vm.selectedStudents.length &&
        $rootScope.user.userRole !== "STUDENT"
      ) {
        vm.emptyFieldErrorMessage = "Fill empty fields";
      } else if (!vm.displayTitle) {
        vm.emptyFieldErrorMessage = "Fill empty field: Lesson Name";
      } else if (
        !vm.selectedStudents.length &&
        $rootScope.user.userRole !== "STUDENT"
      ) {
        vm.emptyFieldErrorMessage = "Fill empty field: Assign students";
      }

      return;
    }

    /**
     * check for multiple students
     */
    if (
      _.isEmpty(vm.selectedStudents) &&
      $rootScope.user.userRole == "PARENT"
    ) {
      toastService.toast({
        message: calendarConstants.messages.assignmentStudentNotAdded,
        type: "error",
        delay: 3000,
      });
      return;
    } else if (
      _.isEmpty(vm.selectedStudents) &&
      $rootScope.user.userRole == "STUDENT"
    ) {
      vm.selectedStudents = [$rootScope.user];
    }

    // Convert the time and date to UTC
    vm.startDateTime = commonService.convertToUTC(
      vm.startDate + " " + (vm.allDayEvent ? "00:00" : vm.startTime)
    );
    vm.endDateTime = commonService.convertToUTC(
      vm.endDate + " " + (vm.allDayEvent ? "23:59" : vm.endTime)
    );

    //console.log('all day event', vm.allDayEvent);
    // if(vm.allDayEvent){
    // 	vm.endDateTime = commonService.convertToUTC(
    // 		vm.startDate + " " + (vm.allDayEvent ? "23:59" : vm.endTime)
    // 	);
    // 	//vm.endDate = vm.startDate;
    // }
    // Make sure end datetime is not before start datetime
    if (moment(vm.endDateTime).isBefore(vm.startDateTime)) {
      vm.assignmentEndDataBeforeStartError = true;
      toastService.toast({
        message: calendarConstants.messages.assignmentEndDateError,
        type: "error",
        delay: 3000,
      });
      return false;
    }

    isRequestSent = true;
    $rootScope.loadingBtn("createAssignment", "Loading");
    let lessonPromise;
    /**
     * Student role: Create only assignment
     * Parent role: create lesson assignment
     */
    // if ($rootScope.user.userRole == 'STUDENT') {
    // 	data = setAssignmentData();
    // 	lessonPromise = assignmentService.saveAssignment(data);
    // }

    data = setLessonAssignmentData();
    if (data.lesson.name && data.lesson.name.id) {
      lessonPromise = lessonService.saveAssignmentToLesson(
        data,
        data.lesson.name.id
      );
    } else {
      /**
       * Calendar lesson should not be shown in lesson list
       */

      data.lesson.isVisible = false;
      lessonPromise = lessonService.saveLesson(data);
    }

    lessonPromise
      .then((response) => {
        let lessonAssignmentResponse = response.lessonAssignment;
        let event = calendarService.calendarDataTransform(
          "studentAssignment",
          lessonAssignmentResponse
        );

        calendarService.saveEventToList(event.entityId, event.model, event);

        vm.form.$setSubmitted();
        let data = {
          entityId: event.entityId,
          event: event,
          addEvent: true,
          refetchEvents: true,
        };
        vm.close(data);
      })
      .catch((error) => {
        toastService.toast({
          message: "There is some error, please retry",
          type: "error",
          delay: 3000,
        });
      })
      .finally(() => {
        isRequestSent = false;
        $rootScope.loadingBtnComplete("save", "Create");
      });
  }

  function removeErrorMessage() {
    if (vm.displayTitle && vm.displayTitle.length > 1) {
      vm.emptyFieldError = false;
      vm.lessonSelectedFromList = false;
    }
  }

  $scope.lessonSelect = function ($item, $model, $label) {
    let lessonName = $item || $model || $label;

    if (lessonName && lessonName.id) {
      vm.lessonSelectedFromList = true;
    }
  };

  function onEndDateChange() {
    vm.dateDuration = moment(vm.endDate, "Y-MM-DD").diff(
      moment(vm.startDate, "Y-MM-DD"),
      "days"
    );
    vm.timeDuration = moment(vm.endTime, "HH:mm").diff(
      moment(vm.startTime, "HH:mm"),
      "seconds"
    );
  }

  function onStartDateChange() {
    if (!vm.startDate) {
      vm.startDate = moment().format("Y-MM-DD");
    }

    if (!vm.startTime) {
      vm.startTime = calendarConstants.defaultStartTime;
    }

    vm.endDate = moment(vm.startDate, "Y-MM-DD")
      .add(vm.dateDuration, "day")
      .format("Y-MM-DD");
    vm.endTime = moment(vm.startTime, "HH:mm")
      .add(vm.timeDuration, "seconds")
      .format("HH:mm");
  }

  this.$onInit = function () {
    lessonService.getElectronicMediaName().then((data) => {
      vm.electronicMediaNames = data;
    });

    vm.startDate = options.startDate
      ? options.startDate
      : moment().format("Y-MM-DD");
    vm.startTime = options.startTime ? options.startTime : null;

    onStartDateChange();

    if (options.assignmentId) {
      vm.assignmentId = options.assignmentId;
    }

    lessonService
      .getAddAssignmentDefaultData()
      .then((defaultAssignmentData) => {
        vm.defaultCourseList = defaultAssignmentData.courseList
          ? defaultAssignmentData.courseList
          : lessonConstants.defaults.defaultCourseList;
        vm.assignmentType = defaultAssignmentData.assignmentType;
      });
  };

  function openResourceModal() {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "md",
      controller: "lessonElectronicMediaController",
      controllerAs: "lessonElectronicMediaCtrl",
      template: require("../../pug/lesson/electronic-media.pug").default,
      resolve: {
        resourceData: {
          newResource: null,
          mediaNames: vm.electronicMediaNames,
        },
      },
    });
    modelInstance.result
      .then(function (resource) {
        if (resource) {
          vm.newResources.push(angular.copy(resource));
          // $rootScope.$broadcast('newResourcesAdd',vm.newResources);
        }
      })
      .catch(function () {
        modelInstance.close();
      });
  }

  function editNewResource(editNewResource, index) {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "md",
      controller: "lessonElectronicMediaController",
      controllerAs: "lessonElectronicMediaCtrl",
      template: require("../../pug/lesson/electronic-media.pug").default,
      resolve: {
        resourceData: {
          newResource: {
            editNewResource,
            type: "edit",
          },
        },
      },
    });
    modelInstance.result
      .then(function (resource) {
        if (resource && vm.newResources.length) {
          if (resource) {
            vm.newResources[index] = resource;
          }
        }
      })
      .catch(function () {
        modelInstance.close();
      });
  }

  function removeNewResource(index) {
    if (vm.newResources.length) {
      vm.newResources.splice(index, 1);
    }
  }

  $scope.$on("modal.closing", function (evt) {
    if (
      vm.form.$dirty &&
      !vm.form.$pristine &&
      !vm.form.$submitted &&
      (!vm.form.displayTitle.$pristine ||
        !vm.form.assignmentTitle.$pristine ||
        !vm.form.noteTitle.$pristine ||
        !vm.form.course.$pristine ||
        !vm.form.credits.$pristine)
    ) {
      evt.preventDefault();
      $rootScope.isLoading = false;
      let confirmData = {
        message: calendarConstants.messages.leavePageConfirmation,
        type: "danger",
        modalSize: "md",
      };

      commonService.confirm(confirmData).then(function (confirmation) {
        if (confirmation.result) {
          vm.form.$submitted = true;
          vm.close();
        }
      });
    }
  });
}
export default createAssignmentController;
