let angular = window.angular;
    userController.$inject = [
        "$rootScope",
        "$uibModal",
        "$state",
        "$stateParams",
        "commonService",
        "fileService",
        "userService",
        "toastService",
        "userConstants"
    ];

    function userController(
        $rootScope,
        $uibModal,
        $state,
        $stateParams,
        commonService,
        fileService,
        userService,
        toastService,
        userConstants
    ) {
        let vm = this;
        vm.user = {};
        vm.activeTab = "personalInfo";
        vm.userPlan = {};
        vm.trialStatus = null;

        vm.referralData = {
            invitations: [],
            creditUsers: [],
            invitationCount: 0,
            creditEarned: 0,
            creditRadeem: 0,
            availableCredits: 0,
        };

        angular.extend(this, {
            changePassword,
            isCurrentUser,
            toggleTab,
            editProfile,
            selectProfilePic,
            openInviteFriendsModal,
            getReferralData,
            copyReferralLink,
            openUserPaymentHistoryModal,
            openSubscriptionCancelModal,
        });

        function getReferralData() {
            userService.getReferrals().then((res) => {
                let data = res.data;
                if (data.invitations.length) {
                    vm.referralData.invitations = data.invitations;
                }
                if (data.creditUsers.length) {
                    vm.referralData.creditUsers = data.creditUsers;
                }
                if (data.invitationCount)
                    vm.referralData.invitationCount = data.invitationCount;
                if (data.creditEarned)
                    vm.referralData.creditEarned = data.creditEarned;
                if (data.creditRadeem)
                    vm.referralData.creditRadeem = data.creditRadeem;
                if (data.availableCredits)
                    vm.referralData.availableCredits = data.availableCredits;
            });
        }
        function isCurrentUser(user) {
            return user.id === $rootScope.user.id;
        }

        function getUserInfo() {
            getReferralData();
            let userId = commonService.convertToInteger($stateParams.userId);
            // In-case is current user id is provided or no id is provided, then return the current users profile
            if (userId === $rootScope.user.id || userId === $rootScope.user.helperId) {
                vm.user = $rootScope.user;
                if ($rootScope.user.userRole === "HELPER" && $rootScope.user.helperId) {
                    vm.user.id = $rootScope.user.helperId;
                }
                if (!vm.user.contactNumber) vm.user.countryCode = 0;
                vm.userPlan = vm.user.payment;
                let copyLinkAddress = userConstants.referralUrl + vm.user.id + "?isType=" + $rootScope.user.isAffiliate + "&link=";
                vm.copyLink = copyLinkAddress + vm.user.profileLink;

                // if((vm.user.remainintTrialDays != null) && (vm.user.remainintTrialDays >= 0))
                // 	vm.trialStatus = true;

                if($rootScope.user.userRole=="PARENT" && $rootScope.user.trial != null && $rootScope.user.payment.currStatus!=="Cancel")
                    vm.trialStatus = $rootScope.user.trial;

                return;
            }
            $rootScope.getFriendListPromise.then(() => {
                let user = $rootScope.getFriendDetails(userId);
                if (!user.id) {
                    return $state.go("404");
                }
                vm.user = user;
                vm.userPlan = vm.user.payment;
            });
        }

        function changePassword() {
            let modelInstance = $uibModal.open({
                animation: true,
                template : require('../../pug/user/change-password.pug').default,
                controller: "changePasswordController",
                controllerAs: "changePwdCtrl",
            });

            modelInstance.result.catch(function () {
                modelInstance.close();
            });
        }

        function toggleTab(value) {
            vm.activeTab = value;
        }

        function selectProfilePic(media) {
            return fileService
                .imageUploadWithCrop(media, "user", vm.user, true)
                .then(selectProfilePicSuccess)
                .catch(selectProfilePicError);

            function selectProfilePicSuccess(response) {
                if (response.user) {
                    vm.user = response.user;
                    updateCurrentUser(vm.user);
                    // toastService.toast({
                    // 	message: userConstants.messages.profilePictureUpdateSuccess,
                    // 	type: 'success',
                    // 	delay: 2000
                    // });
                }
            }

            function selectProfilePicError(error) {
                console.error("Closed modal", error);
            }
        }

        function editProfile(value) {
            let modelInstance = {};
            // if(value == vm.activeTab) {
            modelInstance = $uibModal.open({
                animation: true,
                size: "md",
                controller: "userEditController",
                controllerAs: "userEditCtrl",
                template : require('../../pug/user/edit-profile.pug').default,
                resolve: {
                    tabs: {
                        tabValue: value,
                    },
                },
            });
            // }

            modelInstance.result
                .then((data) => {
                    getUserInfo();
                })
                .catch(function () {
                    modelInstance.close();
                });
        }

        function openInviteFriendsModal(referralLink) {
            let modelInstance = $uibModal.open({
                animation: true,
                size: "lg",
                controller: "inviteReferralsController",
                controllerAs: "inviteFriendsCtrl",
                template : require('../../pug/layout/invite-referrals.pug').default,
                resolve: {
                    shareData: {
                        link: referralLink,
                    },
                },
            });

            modelInstance.result.catch(function () {
                modelInstance.close();
            });
        }

        function copyReferralLink() {
            var copyText = document.getElementById("copyLink");
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            document.execCommand("copy");
            toastService.toast({
                message: "Referral Link Copied",
                type: "success",
                delay: 3000,
            });
        }

        function openUserPaymentHistoryModal() {
            let modelInstance = $uibModal.open({
                animation: true,
                size: "lg",
                controller: "userPaymentHistoryController",
                controllerAs: "userPaymentHistoryCtrl",
                template : require('../../pug/user/user-payment-history.pug').default,
                resolve: null,
            });

            modelInstance.result.catch(function () {
                modelInstance.close();
            });
        }


        function openSubscriptionCancelModal() {
            let modelInstance = $uibModal.open({
                animation: true,
                size: "md",
                controller: "userSubscriptionCancelController",
                controllerAs: "userSubscriptionCancelCtrl",
                template : require('../../pug/user/user-subscription-cancel.pug').default,
                resolve: null,
            });

            modelInstance.result.catch(function () {
                modelInstance.close();
            });
        }

        this.$onInit = getUserInfo;
    }
export default userController;
