//let angular = window.angular;

fileService.$inject = ["Upload", "$http", "$q", "$uibModal","appConstants"];

function fileService(Upload, $http, $q, $uibModal,appConstants) {
  let fileTypeExtensions = {
    image: [".jpg", ".jpeg", ".gif", ".png"],
    doc: [".doc", ".rtf", ".txt", ".odt", ".wps", ".docx"],
    ppt: [".ppt", ".odp", ".pptx"],
    xls: [".xls", ".csv", ".ods"],
    pdf: [".pdf"],
    video: [".mp4", ".avi", ".3gp", ".mov", ".mkv"],
  };

  let appointmentImageIds = [];
  let service = {
    uploadFile,
    uploadApptFile,
    removeFile,
    downloadFile,
    imageUploadWithCrop,
    fileTypeExtensions,
    getAppointmentImageIds,
    removeFileFromSearchLesson,
    removeAllHash,
    removeAssignmentMedia,
  };

  return service;

  function uploadFile(file, id, model) {
    let uploadData;
    if (id.entityId) {
      uploadData = {
        entityId: id.entityId,
        optionalId: id.students,
        model: model,
        media: file,
      };
    } else {
      uploadData = {
        entityId: id,
        model: model,
        media: file,
      };
    }

    return Upload.upload({
      url: appConstants.baseUrl+"/api/upload",
      data: uploadData,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  function uploadApptFile(file, model) {
    return Upload.upload({
      url:appConstants.baseUrl+ "/api/media/uploadEvent",
      data: {
        model: model,
        media: file,
      },
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      _.forEach(res.data.data, (data) => {
        appointmentImageIds.push(data.id);
      });
    });
  }

  function getAppointmentImageIds() {
    let dup = appointmentImageIds;
    appointmentImageIds = [];
    return dup;
  }

  function removeFile(hash) {
    return $http({
      method: "DELETE",
      url: appConstants.baseUrl+"/api/media/remove/" + hash,
    });
  }

  function removeFileFromSearchLesson(mediaIds) {
    return $http({
      url: appConstants.baseUrl+"/api/media/removeAll",
      data: {
        ids: mediaIds,
      },
      method: "POST",
    });
  }
  function removeAllHash(mediaIds) {
    return $http({
      url: appConstants.baseUrl+"/api/media/removeAllHash",
      data: {
        ids: mediaIds,
      },
      method: "POST",
    });
  }
  function removeAssignmentMedia(data) {
    return $http({
      url: appConstants.baseUrl+"/api/media/removeAssignmentMedia",
      data: data,
      method: "POST",
    });
  }

  function downloadFile(hash) {
    if (!hash) {
      return false;
    }
    let url = appConstants.baseUrl+"/api/download/" + hash;
    window.open(url, "_self");
  }

  function imageUploadWithCrop(media, modelName, entity, canGoBack) {
    return $q((resolve, reject) => {
      let imgUploadWithCropModal = $uibModal.open({
        animation: true,
        size: "lg",
        template: require("../../pug/components/img-upload-wtih-crop-modal.pug")
          .default,
        controller: "ImgUploadWithCropModalController",
        controllerAs: "imgCropCtrl",
        resolve: {
          media,
          options: {
            entity,
            modelName,
            canGoBack,
          },
        },
      });

      imgUploadWithCropModal.result
        .then(
          function close(response) {
            resolve(response);
          },
          function dismiss(error) {
            reject(error);
          }
        )
        .catch(function () {
          imgUploadWithCropModal.close();
        });
    });
  }
}

export default fileService;
