let angular = window.angular;
	userPaymentHistoryController.$inject = ['userService', 'toastService','Socialshare','appConstants','$uibModal','$rootScope'];

	function userPaymentHistoryController(userService, toastService, Socialshare, appConstants,$uibModal,$rootScope) {
		let vm = this;
        vm.paymentData={};
        vm.isFetchingPaymentsData = true;
		angular.extend(vm, {
            toggleTab,
        });

        function toggleTab(tab) {
            vm.activeTab = tab;
        }

        function init() {
            userService.getUserPaymentsService()
            .then(result => {

                if(result.data.length) {

                    vm.isFetchingPaymentsData = false;
                    vm.paymentData = result.data;
                } else {
                    vm.paymentData = [];
                }
            });
		}

		this.$onInit = init;
    }

export default userPaymentHistoryController;
