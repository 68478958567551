export const fixedHeaderDirective = ($window) => {
  return {
    restrict: "A",
    link: function (scope, element, attrs) {
      let elem = document.getElementById(attrs.id);

      function getOffset(element) {
        let top = 0;
        if (angular.isDefined(element.offsetTop)) {
          top = element.offsetTop - element.scrollTop;
        }
        return { top: top };
      }

      angular.element($window).bind("scroll", function (el) {
        scope.topOffset = getOffset(elem).top
          ? getOffset(elem).top
          : scope.topOffset;
        if ($($window).scrollTop() >= scope.topOffset) {
          element.addClass("fixedHeaderClass");
        } else {
          element.removeClass("fixedHeaderClass");
        }
        scope.$apply();
      });

      scope.$on("$destroy", function () {
        angular.element($window).unbind("scroll");
      });
    },
  };
};
fixedHeaderDirective.$inject = ["$window"];
