import bookAddController from "./bookAddController";
import bookAssignController from "./bookAssignController";
import bookAssignmentModalController from "./bookAssignmentModalController";
import { bookConstants } from "./bookConstants";
import bookController from "./bookController";
import bookDetailController from "./bookDetailController";
import bookEditController from "./bookEditController";
import bookPrintModalController from "./bookPrintModalController";
import bookSearchController from "./bookSearchController";
import bookService from "./bookService";
import confirmationUnassignBookModalController from "./confirmationUnassignBookModalController";
import confirmationUnassignEntireBookModalController from "./confirmationUnassignEntireBookModalController";
import lessonBookAddController from "../lesson/lessonBookAddController";
// import lessonBookAndElectronicMediaController from "../lesson/addBookAndElectronicMediaController";
import lessonBookSearchController from "../lesson/lessonBookSearchController";

let angular = window.angular;
export const BOOK_MODULE = angular.module("book", []);

BOOK_MODULE.service("bookService", bookService);
// BOOK_MODULE.controller(
//   "lessonBookAndElectronicMediaController",
//   lessonBookAndElectronicMediaController
// );
BOOK_MODULE.controller("lessonBookAddController", lessonBookAddController);
BOOK_MODULE.controller("lessonBookSearchController", lessonBookSearchController);
BOOK_MODULE.controller("bookAddController", bookAddController);
BOOK_MODULE.controller("bookAssignController", bookAssignController);
BOOK_MODULE.controller("bookAssignmentModalController", bookAssignmentModalController);
BOOK_MODULE.constant("bookConstants", bookConstants);
BOOK_MODULE.controller("bookController", bookController);
BOOK_MODULE.controller("bookDetailController", bookDetailController);
BOOK_MODULE.controller("bookEditController", bookEditController);
BOOK_MODULE.controller("bookPrintModalController", bookPrintModalController);
BOOK_MODULE.controller("bookSearchController", bookSearchController);
BOOK_MODULE.controller("confirmationUnassignBookModalController", confirmationUnassignBookModalController);
BOOK_MODULE.controller("confirmationUnassignEntireBookModalController", confirmationUnassignEntireBookModalController);
