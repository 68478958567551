
	freeMonthRequestsController.$inject = ['userService', 'toastService','Socialshare','appConstants','$uibModal','$rootScope'];

	function freeMonthRequestsController(userService, toastService, Socialshare, appConstants,$uibModal,$rootScope) {
        let vm = this;
        vm.isFetchingRequests = false;
        vm.requestsList=[];
        vm.page = 1;
        vm.loadMore = false;

		angular.extend(vm, {
            previousPage,
            nextPage,
            confirm,
            unconfirm,
        });

        function confirm(user){
            return userService.confirmUserCancellation(user, true, "freeMonth")
            .then(response => {
                if (response) {
                    let updateUserIndex = _.findIndex(vm.requestsList, function (el) {
                        return el.userId.id === response.data.userId;
                    });
                    if (updateUserIndex > -1) {
                        vm.requestsList[updateUserIndex].trial_request_status = response.data.trial_request_status;
                    }
                    toastService.toast({
                        message: response.message,
                        type: 'success',
                        delay: 2000
                    });
                }
            })
            .catch(err => {
                toastService.toast({
                    message: response.message,
                    type: 'error',
                    delay: 2000
                });
            })
        }

        function unconfirm(user){
            return userService.confirmUserCancellation(user, false, "freeMonth")
            .then(response => {
                if (response) {
                    let updateUserIndex = _.findIndex(vm.requestsList, function (el) {
                        return el.userId.id === response.data.userId;
                    });
                    if (updateUserIndex > -1) {
                        vm.requestsList[updateUserIndex].trial_request_status = response.data.trial_request_status;
                    }
                    toastService.toast({
                        message: response.message,
                        type: 'success',
                        delay: 2000
                    });
                }
            })
            .catch(err => {
                toastService.toast({
                    message: response.message,
                    type: 'error',
                    delay: 2000
                });
            })
        }

        function getRequests() {
            vm.isFetchingRequests = true;
            userService.getFreeMonthRequests(vm.page - 1)
                .then(response => {
                    vm.requestsList = response.freeMonthRequests;
                    vm.loadMore = response.isLoadMore;
                })
                .catch(() => {
                    toastService.toast({message: 'Requests Not Found', type: 'error', delay: 2000});
                })
                .finally(() => {
                    vm.isFetchingRequests = false;
                })
        }

        function previousPage() {
            if (vm.page > 1) {
                --vm.page;
                getRequests();
            }
        }

        function nextPage() {
            if (vm.loadMore) {
                ++vm.page;
                getRequests();
            }
        }

		function init() {
			return getRequests();
		}

		this.$onInit = init;
	}
export default freeMonthRequestsController;
