const angular = window.angular;
chatCreateController.$inject = [
  "$rootScope",
  "$uibModal",
  "collaborateService",
  "_",
  "chatConstants",
  "$state",
  "friendService",
  "commonService",
];
function chatCreateController(
  $rootScope,
  $uibModal,
  collaborateService,
  _,
  chatConstants,
  $state,
  friendService,
  commonService
) {
  let vm = this,
    isRequestSent = false;

  angular.extend(this, {
    createChat,
    isTouched,
    goBack,
    openUserEditModal,
  });

  vm.name = null;
  vm.friends = [];

  vm.chats = commonService.chats;

  function openUserEditModal() {
    let modelInstance = {};
    modelInstance = $uibModal.open({
      animation: true,
      size: "md",
      controller: "userProfileInfoController",
      controllerAs: "userProfileInfoCtrl",
      template: require("../../pug/user/user-profile-edit-info.pug").default,
      resolve: null,
    });
    // }

    modelInstance.result
      .then((data) => {
        $state.go("collaborateCreate");
        $rootScope.profileComplete(true);
      })
      .catch(function () {
        modelInstance.close();
      });
  }

  function getUsersToAdd() {
    let userIds = [];
    for (let i = vm.friends.length - 1; ~i; i--) {
      if (vm.friends[i].addToChat) {
        userIds.push(vm.friends[i].id);
        vm.friends[i].addToChat = !vm.friends[i].addToChat;
      }
    }
    return userIds;
  }

  function goBack() {
    if (vm.chats) $state.go("collaborateViewChat", { chatId: vm.chats[0].id });
    else $state.go("dashboard");
  }

  function isTouched(field) {
    if (angular.isDefined(field)) {
      return field.$touched || vm.form.$submitted;
    } else {
      return false;
    }
  }

  function createChat() {
    if (!vm.form.$valid) {
      return false;
    }

    let userIds = getUsersToAdd();
    if (!userIds.length) {
      return false;
    }

    isRequestSent = true;
    $rootScope.loadingBtn("save", "Loading");
    collaborateService
      .createChat(userIds, chatConstants.chatType.GROUP, vm.name)
      .then((data) => {
        $state.go("collaborateViewChat", { chatId: data.id });
        collaborateService.sideMenuChatId = data.id;
      })
      .catch((message) => {})
      .finally(() => {
        isRequestSent = false;
        $rootScope.loadingBtnComplete("save", "Create");
      });
  }

  function getFriendList() {
    $rootScope.getFriendListPromise.then((data) => {
      vm.friends = _.clone(data);
    });

    $rootScope.chatIndexPromise.then((successData) => {
      vm.chats = collaborateService.chatsList;
    });
  }

  this.$onInit = getFriendList;
}
export default chatCreateController;
