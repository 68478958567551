let angular = window.angular;
    bookAssignmentModalController.$inject = ['bookConstants', 'bookData', '$scope', '$uibModalInstance', 'bookService', 'studentService', '$state', '$stateParams', '_', 'commonService', 'toastService', 'moment', 'lessonConstants', '$uibModal', 'calendarConstants', '$rootScope', '$timeout'];

    function bookAssignmentModalController(bookConstants, bookData, $scope, $uibModalInstance, bookService, studentService, $state, $stateParams, _, commonService, toastService, moment, lessonConstants, $uibModal, calendarConstants, $rootScope, $timeout) {

        let vm = this;
        let returnData = {};
        vm.addToCalendar = false;
        vm.allDayEvent = false;
        vm.assignment;
        vm.dateDuration = 0;
        vm.timeDuration = moment.duration(1, 'hour').asSeconds();

        vm.startDate = null;
        vm.startTime = null;

        vm.endDate = null;
        vm.endTime = null;

        angular.extend(this, {
            close,
            assignStudents,
            isTouched,
            onEndDateChange,
            onStartDateChange
        });

        $scope.data = {};

        function close(data = returnData) {
            $uibModalInstance.close(data);
        }

        function onEndDateChange(index) {
            vm.dateDuration = moment(vm.students[index].endDate, 'Y-MM-DD').diff(moment(vm.students[index].startDate, 'Y-MM-DD'), 'days');
            vm.timeDuration = moment(vm.students[index].endTime, 'HH:mm').diff(moment(vm.students[index].startTime, 'HH:mm'), 'seconds');
            if(vm.dateDuration <0){
                vm.dateMessage= 'The start date cannot be greater than the end date. Please update and try again';
                $timeout(() => {
                    vm.dateMessage = '';
                }, 3000)
            }
        }

        function onStartDateChange(index) {
            if (!vm.students[index].startDate) {
                vm.students[index].startDate = moment().format('Y-MM-DD');
            }

            if (!vm.students[index].startTime) {
                vm.students[index].startTime = calendarConstants.defaultStartTime;
            }

            vm.students[index].endDate = moment(vm.students[index].startDate, 'Y-MM-DD').add(vm.dateDuration, 'day').format('Y-MM-DD');
            vm.students[index].endTime = moment(vm.students[index].startTime, 'HH:mm').add(vm.timeDuration, 'seconds').format('HH:mm');

            if(vm.dateDuration <0){
                vm.dateMessage= 'The start date cannot be greater than the end date. Please update and try again';
                $timeout(() => {
                    vm.dateMessage = '';
                }, 3000)
            }
        }

        function isTouched(field) {
            if (angular.isDefined(field)) {
                return field.$touched || vm.form.$submitted;
            } else {
                return false;
            }
        }

        function addToAssignmentConfirmation() {
            commonService.confirm({
                    message: bookConstants.messages.deleteBookConfirmation,
                    type: 'danger',
                    modalSize: "md"
                })
                .then((confirmation) => {
                    if (confirmation.result === true) {
                        bookService.deleteBook(bookId)
                            .then((deleted) => {
                                toastService.toast({
                                    message: bookConstants.messages.bookDeleted,
                                    type: 'success',
                                    delay: 3000
                                });
                            })
                            .catch((err) => {
                                // console.log("error", err);
                            })
                            .finally(() => {
                                close({ removeEvent: true, bookData });
                            })
                    } else return;
                })
                .catch((err) => {
                    // console.log("error", err);
                });
        }

        function setAssignmentData() {
            //let assignment = {};
            let singleAssignment = {};
            let assignment = [];
            if(vm.selectedStudents.length >0){
                _.forEach(vm.selectedStudents, student =>{
                    singleAssignment.startDate = commonService.convertToUTC(student.startDate + ' ' + (student.allDayEvent ? '00:00' : student.startTime));
                    singleAssignment.endDate = commonService.convertToUTC(student.endDate + ' ' + (student.allDayEvent ? '23:59' : student.endTime));
                    singleAssignment.displayTitle = bookData.book.title;
                    //singleAssignment.studentId = student.id;
                    singleAssignment.title = null;
                    singleAssignment.noteTitle = null;
                    assignment.push(_.clone(singleAssignment));
                });
            }
            // assignment.startDate = commonService.convertToUTC(vm.startDate + ' ' + (vm.allDayEvent ? '00:00' : vm.startTime));
            // assignment.endDate = commonService.convertToUTC(vm.endDate + ' ' + (vm.allDayEvent ? '23:59' : vm.endTime));
            // assignment.displayTitle = bookData.book.title;
            // assignment.title = null;
            // assignment.noteTitle = null;
            return assignment;
        }

        function addToCalendarfunc(){
            vm.addToCalendar = true
        }

        function getSelectedStudents() {
            _.map(vm.students, function(student) {
                if (student.isAssign) {
                    //vm.selectedStudents.push(student.id);
                   vm.selectedStudents.push(student);
                }
            })
            return vm.selectedStudents;
        }

        function assignStudents() {
            vm.selectedStudents = [];
            if(vm.dateDuration <0){
                vm.dateMessage= 'The start date cannot be greater than the end date. Please update and try again';
                $timeout(() => {
                    vm.dateMessage = '';
                }, 3000)
                return false;
            }

            if (!vm.form.$valid) {
                return;
            } else {
                    vm.selectedStudents = getSelectedStudents();
                    vm.assignment = setAssignmentData();
            }

            if (_.isEmpty(vm.selectedStudents)) {
                toastService.toast({
                    message: calendarConstants.messages.bookStudentNotAssigned,
                    type: 'error',
                    delay: 3000
                });
                return;
            }

            if (vm.allDayEvent) {
                toastService.toast({
                    message: calendarConstants.messages.addToCalendarNotChecked,
                    type: 'error',
                    delay: 5000
                });
                return;
            }

            $rootScope.loadingBtn('assignStudentsSave', 'Saving');

            vm.disableObj = {
                'disable': true
            }

            //bookService.assignBook({ studentIds: vm.selectedStudents, bookId: bookData.book.id, assignment: vm.assignment, isRequestFromWeb: true})
            bookService.assignBook({ studentIds: vm.selectedStudents, bookId: bookData.book.id, assignment: vm.assignment, isRequestFromWeb: true})
                .then((response) => {
                    console.log("response book",response);
                    $rootScope.loadingBtnComplete('assignStudentsSave', 'Save');
                    vm.disableObj = {
                        'disable': false
                    }
                    response.bookId = bookData.book.id;
                    close(response);
                })
                .catch((err) => {
                    // console.log("Studens not assigned", err);
                })

        }

        function viewBook(){
            bookService.viewBook(bookData.book.id)
            .then(response =>{
                vm.bookAssignees = response.assignments;
            });
        }

        function getBookAssignee() {
            // bookService.viewBook(bookData.book.id)
            // .then(response =>{
            //     vm.bookAssignees = response.assignments;
            //     vm.studentList = _.reject(studentService.students, student =>{
            //         for(let i=0; i<response.assignments.length; i++){
            //             if(student.id == response.assignments[i].studentId.id){
            //                 return student;
            //             }
            //         }
            //     })
            vm.students = [];
			let students_array=_.filter(studentService.students,(obj)=>obj.role==='STUDENT');
            _.map(students_array, function(student) {
                student.isAssign = false;
                student.addToCalendar = false;
                student.startDate= '';
                student.endDate= '';
                student.allDayEvent= false;
                student.startTime= '';
                student.endTime= '';
                vm.students.push(student);
            })
            //});
        }

        this.$onInit = function() {
            //onStartDateChange();

            return [getBookAssignee()];
        }
    }

export default bookAssignmentModalController;
