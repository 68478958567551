const angular = window.angular;

EditGroupChatNameController.$inject = [
  "$uibModalInstance",
  "chat",
  "$rootScope",
  "collaborateService",
];

function EditGroupChatNameController(
  $uibModalInstance,
  chat,
  $rootScope,
  collaborateService
) {
  /* jshint validthis:true */
  let vm = this;
  vm.chat = angular.copy(chat) || {};

  function close() {
    $uibModalInstance.dismiss();
  }

  function updateGroupChatName() {
    if (!vm.chat.name) {
      return;
    }

    $rootScope.loadingBtn("updateName", "Updating");
    return collaborateService
      .updateGroupChatName(vm.chat)
      .then(updateGroupChatNameSuccess)
      .catch(updateGroupChatNameFailure);
  }

  function updateGroupChatNameSuccess(response) {
    $rootScope.loadingBtnComplete("updateName", "Update");
    $uibModalInstance.close();
  }

  function updateGroupChatNameFailure(error) {
    console.error(error);
  }

  angular.extend(vm, {
    close,
    updateGroupChatName,
  });
}
export default EditGroupChatNameController;
