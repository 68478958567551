import { FileUploadController, hspFileUpload } from "./fileUploadDirective";
import {
  HspAddFilesController,
  hspAddMultipleFiles,
} from "../components/fileDirectives/hspAddFilesDirective";
import {
  HspFileController,
  hspFile,
} from "../components/fileDirectives/hspFileDirective";
import {
  HspMultipleFilesController,
  hspMultipleFiles,
} from "../components/fileDirectives/hspMultipleFilesDirective";
import {
  HspYoutubeListController,
  hspYoutubeList,
} from "../components/youtubeEmbeddedListDirective";
import { authotizationInterceptor, httpInterceptor } from "./interceptor";
import {
  datePickerDirective,
  datePickerSelectDirective,
} from "../components/datepickerDirective";
import { fromNow, momentFilter } from "./momentFilter";

import ConfirmModalController from "../components/confirmModalController";
import ImgUploadWithCropModalController from "../components/imgUploadWithCropModalController";
import afterRender from "../components/afterRenderDirective";
import agendaDateFilter from "../components/agendaDateFilter";
import { appConstants } from "./appConstant";
import { autoExpand } from "./autoExpand";
import commonService from "./commonService";
import { durationDirective } from "./durationDirective";
import emojiPicker from "../components/emojiPickerDirective";
import emojiPickerComment from "../components/emojiPickerCommentDirective";
import emojiPickerMessenger from "../components/emojiPickerMessengerDirective";
import fileService from "./fileService";
import { fixedHeaderDirective } from "./fixedHeaderDirective";
import format from "../components/formatFilter";
import { hspAutofocus } from "./hspAutoFocus";
import hspFileViewModalController from "../components/fileDirectives/hspFileViewModalController";
import hspInfoModalController from "./hspInfoModalController";
import hspInfoTrailModalController from "../components/hspInfoTrailModalController";
import hspTutorialModalController from "./hspTutorialModalController";
import hspTyping from "../components/typingDirective";
import infiniteScroll from "../components/infiniteScroll";
import infiniteScrollReverse from "../components/infiniteScrollReverse";
import lessonDurationFormatter from "../components/lessonDurationFormatter";
import locationConstants from "./locationConstants";
import { mentionDirective } from "./mentionExampleDirective";
import ngDropDown from "../components/ngDropDown";
import ngEnter from "../components/ngEnter";
import { ngLightBox } from "./ngLightBox";
import onErrorSrc from "../components/imgDirective";
import phoneNumber from "../components/phoneNumberComponent";
import placesAutocomplete from "../components/placesAutocompleteDirective";
import stateHistory from "./stateHistory";
import stringToNumber from "../components/stringToNumberDirective";
import timepicker from "../components/timepickerDirective";
import toastService from "./toastService";
import trackEvents from "./trackEvents";
import truncateString from "./truncateFilter";
import unsafeHTML from "../components/unsafeHTMLFilter";
import websocketService from "./webSocketService";

let angular = window.angular;

export const COMMON_MODULE = angular.module("common", []);

COMMON_MODULE.factory("Store", [
  "$window",
  function ($window) {
    return $window.Store;
  },
]);
COMMON_MODULE.factory("_", [
  "$window",
  function ($window) {
    // $window._ = _;
    return $window._;
  },
]);

COMMON_MODULE.factory("moment", [
  "$window",
  function ($window) {
    // $window.moment = moment;
    return $window.moment;
  },
]);
COMMON_MODULE.factory("io", [
  "$window",
  function ($window) {
    return $window.io;
  },
]);

COMMON_MODULE.service("webSocketService", websocketService);
COMMON_MODULE.service("toastService", toastService);
COMMON_MODULE.service("commonService", commonService);
COMMON_MODULE.service("trackEvents", trackEvents);
COMMON_MODULE.service("fileService", fileService);

COMMON_MODULE.constant("appConstants", appConstants);

COMMON_MODULE.directive("hspAutofocus", hspAutofocus);
COMMON_MODULE.directive("autoExpand", autoExpand);
COMMON_MODULE.directive("duration", durationDirective);
COMMON_MODULE.directive("hspFileUpload", hspFileUpload).controller(
  "FileUploadController",
  FileUploadController
);
COMMON_MODULE.directive("fixedHeader", fixedHeaderDirective);
COMMON_MODULE.controller("hspInfoModalController", hspInfoModalController);
COMMON_MODULE.controller(
  "hspTutorialModalController",
  hspTutorialModalController
);

// COMMON_MODULE.config("httpInterceptor", httpInterceptor).factory(
//   "authotizationInterceptor",
//   authotizationInterceptor
// );

COMMON_MODULE.directive("mentionExample", mentionDirective);

COMMON_MODULE.filter("moment", momentFilter);
COMMON_MODULE.filter("fromNow", fromNow);
COMMON_MODULE.directive("ngLightBox", ngLightBox);
COMMON_MODULE.service("stateHistory", stateHistory);
COMMON_MODULE.filter("truncateString", truncateString);

COMMON_MODULE.directive("afterRender", afterRender);
COMMON_MODULE.filter("agendaDateFilter", agendaDateFilter);
COMMON_MODULE.controller("ConfirmModalController", ConfirmModalController);
COMMON_MODULE.directive("datepicker", datePickerDirective);
COMMON_MODULE.directive("datepickerSelect", datePickerSelectDirective);
COMMON_MODULE.directive("emojiPickerComment", emojiPickerComment);
COMMON_MODULE.directive("emojiPicker", emojiPicker);
COMMON_MODULE.directive("emojiPickerMessenger", emojiPickerMessenger);
COMMON_MODULE.filter("format", format);
COMMON_MODULE.controller(
  "hspInfoTrailModalController",
  hspInfoTrailModalController
);
COMMON_MODULE.directive("onErrorSrc", onErrorSrc);
COMMON_MODULE.controller(
  "ImgUploadWithCropModalController",
  ImgUploadWithCropModalController
);
COMMON_MODULE.directive("infiniteScroll", infiniteScroll);
COMMON_MODULE.directive("infiniteScrollReverse", infiniteScrollReverse);
COMMON_MODULE.filter("lessonDurationFormatter", lessonDurationFormatter);
COMMON_MODULE.directive("ngDropDown", ngDropDown);
COMMON_MODULE.directive("ngEnter", ngEnter);
COMMON_MODULE.component("phoneNumber", phoneNumber);
COMMON_MODULE.directive("placesAutocomplete", placesAutocomplete);
COMMON_MODULE.directive("stringToNumber", stringToNumber);
COMMON_MODULE.directive("timepicker", timepicker);
COMMON_MODULE.directive("hspTyping", hspTyping);
COMMON_MODULE.filter("unsafeHTML", unsafeHTML);
COMMON_MODULE.directive("hspYoutubeList", hspYoutubeList).controller(
  "HspYoutubeListController",
  HspYoutubeListController
);
COMMON_MODULE.directive("hspAddMultipleFiles", hspAddMultipleFiles).controller(
  "HspAddFilesController",
  HspAddFilesController
);
COMMON_MODULE.directive("hspFile", hspFile).controller(
  "HspFileController",
  HspFileController
);
COMMON_MODULE.controller(
  "hspFileViewModalController",
  hspFileViewModalController
);
COMMON_MODULE.directive("hspMultipleFiles", hspMultipleFiles).controller(
  "HspMultipleFilesController",
  HspMultipleFilesController
);
COMMON_MODULE.constant("locationConstants", locationConstants);
