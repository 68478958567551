



function loadMore() {
    let directive = {
        link: link,
        restrict: 'A'
    };
    return directive;

    function link(scope, element, attrs) {
        let raw = element[0];

        element.bind("scroll", () => {
            if ((raw.scrollTop + raw.offsetHeight + 5) >= raw.scrollHeight) {
                scope.$apply(attrs.loadMore);
            }
        });
    }
}

export default loadMore;
