
let angular = window.angular;
notificationService.$inject = ['webSocketService', '$timeout', '$q', '$http', 'commonService', '$rootScope', '_', 'userService', "appConstants"];
function notificationService(webSocketService, $timeout, $q, $http, commonService, $rootScope, _, userService, appConstants) {

    let basePath = appConstants.baseUrl + '/api/notifications/',
        notifications = [],
        stopGetMore = false;

    let notificationFactory = {
        getAllNotifications,
        markRead,
        remove,
        showPushNotification,
        clearAllNotifications,
        orderNotifications,
        notifications
    };

    function showPushNotification(message) {
        if ('serviceWorker' in navigator && userService.checkUserLogin()) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                if (registrations.length > 0) {
                    const title = message.title;
                    const options = {
                        body: message.messageBody,
                        icon: '../../favicon.png',
                        badge: '../../favicon.png',
                        data: message.forwardLink,
                        tag: "message.userFriendId"
                    };

                    registrations[0].showNotification(title, options);
                }
            });
        }
    }

    function getAllNotifications(getMore) {
        let deferred = $q.defer(),
            getNotificationsUrl = basePath + 'index',
            getNotificationsPromise;

        if (stopGetMore) {
            deferred.resolve(notifications);
        } else {
            if (getMore) {
                getNotificationsUrl += '/' + notifications[notifications.length - 1].id;
            }

            getNotificationsPromise = $http.get(getNotificationsUrl);
            getNotificationsPromise
                .then((response) => {
                    $rootScope.reminderNotificationsCount = response.data.data.unreadNotifications;

                    if (_.isEmpty(response.data.data.notifications)) {
                        stopGetMore = true;
                    }

                    if (getMore) {
                        _.each(response.data.data.notifications, (notification) => {
                            commonService.saveInArray(notification.id, 'id', notifications, notification);

                        });
                    } else {
                        notifications = response.data.data.notifications;
                    }
                    deferred.resolve(notifications);
                })
                .catch((response) => {
                    deferred.reject(response.data);
                });

        }

        return deferred.promise;
    }

    function markRead(id, markAllRead) {
        let url = basePath + 'markRead/' + id;

        if (angular.isDefined(markAllRead) && markAllRead) {
            url += '?markAllRead=true';
        }

        return $http.get(url);
    }

    function clearAllNotifications() {
        return $http.delete(basePath + 'clearAllNotifications');
    }

    function remove(id) {
        return $http.delete(basePath + 'delete/' + id);
    }

    function orderNotifications() {
        notifications = _.sortBy(notifications, ['isSeen']);
        return notifications;
    }

    webSocketService.socket.on('reminderNotification', (notification) => {

        if (!notification.id) {
            return false;
        }

        $timeout(() => {
            commonService.saveInArray(notification.id, 'id', notifications, notification, true);
            $rootScope.reminderNotificationsCount++;
        }, 0);
    });

    $rootScope.$on('remove-reminder-notification', (event, criteria) => {
        if (!criteria) {
            return false;
        }

        $timeout(() => {
            let removeNotificationIds = [],
                index = 0;

            // DEVAM
            while (index >= 0) {
                index = _.findIndex(notifications, criteria, index);
                if (index < 0 || (removeNotificationIds.length - 1 >= 0 && index <= removeNotificationIds[removeNotificationIds.length - 1])) {
                    break;
                } else {
                    removeNotificationIds.push(index);
                    index++;
                }
            }

            for (let i = 0; i < removeNotificationIds.length; i++) {
                if (!notifications[removeNotificationIds[i] - i].isSeen) {
                    $rootScope.reminderNotificationsCount--;
                }
                notifications.splice(removeNotificationIds[i] - i, 1);
            }

        }, 0);
    });

    return notificationFactory;
}

export default notificationService;
