let angular = window.angular;
inviteFriendsController.$inject = [
  "userService",
  "$scope",
  "appConstants",
  "$uibModalInstance",
  "commonService",
  "$state",
  "toastService",
  "$rootScope",
  "shareData",
];
function inviteFriendsController(
  userService,
  $scope,
  appConstants,
  $uibModalInstance,
  commonService,
  $state,
  toastService,
  $rootScope,
  shareData
) {
  let vm = this,
    isRequestSent = false;
  vm.isFetchingData = false;
  vm.groupedDetails = [];
  let inviteUrl =
    "https://app.homeschoolpanda.com/referral/" +
    $rootScope.user.id +
    "?isType=" +
    $rootScope.user.isAffiliate +
    "&link=";

  angular.extend(vm, {
    close,
    addPeople,
    isTouched,
    deleteNameMailDetail,
    inviteFriends,
  });
  vm.showError = false;
  vm.message = appConstants.messages.checkoutApp;
  vm.referralMessage =
    appConstants.messages.referralMessage +
    (shareData ? inviteUrl + shareData.link : "");
  vm.email = "";
  vm.fullName = "";
  vm.showPeopleList = false;

  // Email matching pattern
  vm.emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  //Full Name matching patetrn: allow alphanumeric,spaces,hyphen,underscore and dot
  vm.fullNamePattern = /^[\w .-]*$/;

  function isTouched(field) {
    return field.$touched || vm.form.$submitted;
  }

  function close() {
    $uibModalInstance.close(true);
  }

  function addPeople(fullName, email) {
    if (!vm.form.$valid || isRequestSent || !email) {
      vm.form.$submitted = true;
      return;
    }
    if (_.findIndex(vm.groupedDetails, { email: vm.email }) < 0) {
      vm.groupedDetails.push({ name: fullName, email: email });
      resetForm();
    } else {
      vm.showError = true;
    }
  }

  function resetForm() {
    vm.email = null;
    vm.fullName = null;
    vm.showPeopleList = true;
    vm.form.$setPristine();
    vm.form.$setUntouched();
    vm.form.email.$setUntouched();
    vm.form.fullName.$setUntouched();
    vm.showError = false;
  }

  function deleteNameMailDetail(fullName, email) {
    for (let i = 0; i < vm.groupedDetails.length; i++) {
      if (
        vm.groupedDetails[i].fullName == fullName &&
        vm.groupedDetails[i].email == email
      ) {
        vm.groupedDetails.splice(i, 1);
        i = vm.groupedDetails.length;
      }
    }
  }

  function inviteFriends() {
    let groupedDetails = angular.copy(vm.groupedDetails);

    if (vm.email !== "" && vm.email !== null && vm.email !== undefined) {
      groupedDetails.push({ name: vm.fullName, email: vm.email });
    }
    if (!vm.form.$valid || isRequestSent || !groupedDetails.length) {
      return;
    }
    return userService
      .inviteFriends(groupedDetails, vm.message)
      .then((response) => {
        toastService.toast({
          message: "Invite Sent Successfully!",
          type: "success",
          delay: 3000,
        });

        vm.close();
      })
      .catch((err) => {
        toastService.toast({
          message: err.data.message,
          type: "error",
          delay: 3000,
        });
        console.error(message);
      });
  }

  $scope.$on("modal.closing", function (evt) {
    if (vm.form.$dirty && !vm.form.$pristine && !vm.form.$submitted) {
      evt.preventDefault();
      let confirmData = {
        message: appConstants.messages.leavePageConfirmation,
        modalSize: "md",
        type: "danger",
      };
      commonService.confirm(confirmData).then(function (confirmation) {
        if (confirmation.result) {
          vm.form.$submitted = true;
          vm.close();
        }
      });
    }
  });
}

export default inviteFriendsController;
