let angular = window.angular;
calendarService.$inject = [
  "$http",
  "$q",
  "_",
  "moment",
  "commonService",
  "Store",
  "calendarConstants",
  "appConstants",
];

function calendarService(
  $http,
  $q,
  _,
  moment,
  commonService,
  Store,
  calendarConstants,
  appConstants
) {
  let basePath = appConstants.calendarBaseUrl + "/api/calendars";
  console.log("🚀 ~ file: calendarService.js:24 ~ basePath:", basePath)
  var vm = this;
  vm.editedLesson = {};
  /**
   * Stores the list of events currently available to the user
   * @type Array
   */
  let events = [];

  angular.extend(this, {
    clearEventsList,
    getEvents,
    getChores,
    getNewChore,
    events,
    removeEventFromList,
    saveEventToList,
    updateEventTitleToList,
    getEventFromCalendarList,
    calendarDataTransform,
    printCalendarEvents,
    getAllLessons,
    search,
    viewStudentLesson,
    updateStudentLesson,
    getOverdueAssignmentsCount,
    updateResourcesToEvent,
    getFilterLessons,
    updateStatusToEvent,
    updateEventTitle,
    updateEventGrading,
    checkFutureEvents,
  });

  function checkFutureEvents(event) {
    let futureEvent = events.find((element) => {
      return (
        element.metaData.lessonId === event.metaData.lessonId &&
        element.entityId !== event.entityId &&
        moment(element.startDate) > moment(event.startDate)
      );
    });

    if (futureEvent) {
      return true;
    } else {
      return false;
    }
  }

  function getOverdueAssignmentsCount() {
    let deferred = $q.defer(),
      getOverdueAssignmentsCountPromise;

    getOverdueAssignmentsCountPromise = $http({
      method: "GET",
      url: appConstants.baseUrl + "/api/students/assignments/getNotCompleteCount",
    });

    getOverdueAssignmentsCountPromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        deferred.reject(response.data.message);
      });

    return deferred.promise;
  }

  function updateStudentLesson(data) {
    let deferred = $q.defer(),
      getLessonsPromise;

    getLessonsPromise = $http({
      method: "POST",
      url: appConstants.baseUrl + "/api/lessons/editFromCalender",
      data: data,
    });

    getLessonsPromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        deferred.reject(error);
      });

    return deferred.promise;
  }

  function viewStudentLesson(lessonId) {
    let deferred = $q.defer(),
      lessonViewPromise;

    lessonViewPromise = $http.get(
      appConstants.baseUrl + "/api/lessons/viewAssignedLesson/" + lessonId
    );
    lessonViewPromise
      .then((response) => {
        let lesson = response.data.data;
        deferred.resolve(lesson);
      })
      .catch((response) => {
        deferred.reject(response.data.message);
      });

    return deferred.promise;
  }

  function search(queryData) {
    let deferred = $q.defer(),
      getLessonsPromise;

    getLessonsPromise = $http({
      method: "POST",
      url:
        appConstants.baseUrl + "/api/students/lessons/searchLessons?timezone=" +
        -new Date().getTimezoneOffset(),
      data: queryData,
    });

    getLessonsPromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((error) => {
        deferred.reject(response.data.message);
      });

    return deferred.promise;
  }

  function getAllLessons(queryData) {
    let deferred = $q.defer(),
      getLessonsPromise,
      queryParams = { nextPage: 1 };

    if (queryData) {
      queryParams.nextPage = queryData.nextPage;
    }

    getLessonsPromise = $http({
      method: "GET",
      url: appConstants.baseUrl + "/api/students/lessons/getAllLessons",
      params: queryParams,
    });

    getLessonsPromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        deferred.reject(response.data.message);
      });

    return deferred.promise;
  }

  function getFilterLessons() {
    let deferred = $q.defer(),
      getFilterLessonsPromise;

    getFilterLessonsPromise = $http({
      method: "GET",
      url: appConstants.baseUrl + "/api/students/lessons/getFilterLessons",
    });

    getFilterLessonsPromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((error) => {
        console.log(error);
        deferred.reject(response.data.message);
      });

    return deferred.promise;
  }

  function getEventFromCalendarList(entityId, model) {
    let index = findEventInList(entityId, model);
    if (!~index) {
      return false;
    }
    return events[index];
  }

  function findEventInList(entityId, model, occurrenceId) {
    return _.findIndex(events, (event) => {
      if (angular.isDefined(occurrenceId) && occurrenceId >= 0) {
        return (
          event.entityId === entityId &&
          event.model === model &&
          event.metaData.occurrenceId === occurrenceId
        );
      } else {
        return event.entityId === entityId && event.model === model;
      }
    });
  }

  function removeEventFromList(entityId, model, occurrenceId) {
    let index = findEventInList(entityId, model, occurrenceId);
    if (!~index) {
      return false;
    }
    events.splice(index, 1);
    return true;
  }

  function updateResourcesToEvent(entityId, model, resources, occurrenceId) {
    let index = findEventInList(entityId, model, occurrenceId);
    if (!~index) {
      // events.push(event);
      return false;
    } else {
      events[index].metaData.resources = resources;
    }
    return true;
  }

  function updateStatusToEvent(entityId, model, status, occurrenceId) {
    let index = findEventInList(entityId, model, occurrenceId);
    if (!~index) {
      return null;
    } else {
      events[index].metaData.status = status;
    }
    return events[index];
  }

  function updateEventTitleToList(entityId, model, event, occurrenceId) {
    let index = findEventInList(entityId, model, occurrenceId);
    if (!~index) {
      return null;
    } else {
      events[index].assignmentTitle = event.metaData.title;
      events[index].metaData.title = event.metaData.title;
    }
    return events[index];
  }
  function updateEventTitle(entityId, model, title, updateTitleForAll) {
    // let index = events.findIndex(event => event.entityId === entityId && event.model === model);
    // //let index = findEventInList(entityId, model, occurrenceId);
    // if (!~index) {
    //     return null;
    // } else {
    // 	events[index].assignmentTitle = title;
    // 	events[index].metaData.title = title;
    // }
    // return events[index];

    let eventArray = [];
    updateTitleForAll.forEach((assignmentId) => {
      let index = events.findIndex(
        (event) => event.entityId === assignmentId && event.model === model
      );
      if (index !== -1) {
        events[index].assignmentTitle = title;
        events[index].metaData.title = title;
        eventArray.push(events[index]);
      }
    });

    return eventArray;
  }
  function updateEventGrading(
    entityId,
    model,
    isGradingEnable,
    updateGradingForAll
  ) {
    let eventArray = [];
    updateGradingForAll.forEach((assignmentId) => {
      let index = events.findIndex(
        (event) => event.entityId === assignmentId && event.model === model
      );
      if (index !== -1) {
        events[index].metaData.isGradingEnable = isGradingEnable;
        eventArray.push(events[index]);
      }
    });

    return eventArray;
  }
  function saveEventToList(entityId, model, event, occurrenceId) {
    let index = findEventInList(entityId, model, occurrenceId);
    if (!~index) {
      events.push(event);
    } else {
      events[index] = event;
    }
    return true;
  }

  /**
   * Function to clear the event list
   * @returns {Array}
   */
  function clearEventsList() {
    return events.splice(0);
  }

  function calendarDataTransform(model, data) {
    let eventData = {},
      startTime,
      endTime,
      startDateTime,
      endDateTime;

    switch (model) {
      case "appointment":
        // Create Date Time from Date and Time
        startDateTime = moment.utc(data.startDate);
        startDateTime = startDateTime.format("Y-MM-DD HH:mm:ss");

        endDateTime = moment.utc(data.endDate);
        endDateTime = endDateTime.format("Y-MM-DD HH:mm:ss");

        eventData = {
          entityId: data.id,
          model: model,
          title: data.title,
          userId: data.userId,
          startDate: startDateTime,
          endDate: endDateTime,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          metaData: {
            description: data.description,
            location: data.location,
            latitude: data.latitude,
            longitude: data.longitude,
          },
        };
        break;

      case "vacation":
        // Create Date Time from Date and Time
        startDateTime = moment.utc(data.startDate);
        startDateTime = startDateTime.format("Y-MM-DD HH:mm:ss");

        endDateTime = moment.utc(data.endDate);
        endDateTime = endDateTime.format("Y-MM-DD HH:mm:ss");

        eventData = {
          entityId: data.id,
          model: model,
          title: data.title,
          userId: data.userId,
          startDate: startDateTime,
          endDate: endDateTime,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          metaData: {
            description: data.description,
            location: data.location,
            latitude: data.latitude,
            longitude: data.longitude,
          },
        };
        break;

      case "studentAssignment":
        // eventData = [];
        // 	for(let i = 0;i<data.length ; i++)
        // 	{
        // 		eventData.push({
        // 			entityId: data[i].id,
        // 			model: model,
        // 			title: data[i].displayTitle || data[i].studentLesson.name,
        // 			//						userId: data[i].userId,
        // 			startDate: data[i].startDate,
        // 			endDate: data[i].endDate,
        // 			createdAt: data[i].createdAt,
        // 			updatedAt: data[i].updatedAt,
        // 			metadata: {
        // 				attendance: data[i].attendance,
        // 				title: data[i].title,
        // 				studentId: data[i].studentId,
        // 				category: data[i].category,
        // 				lessonId: data[i].studentLessonPlanId ? data[i].studentLessonPlanId :  0,
        // 				mediaUrl: null
        // 			}
        // 		})
        // 	}
        eventData = {
          entityId: data.id,
          model: model,
          title: data.displayTitle || data.title || data.noteTitle, // check this and correct
          // title: data.displayTitle || data.studentLesson.name,
          //						userId: data.userId,
          startDate: data.startDate,
          endDate: data.endDate,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
          metaData: {
            attendance: data.attendance,
            title: data.title,
            studentId: data.studentId,
            category: data.category,
            lessonId: data.studentLessonPlanId ? data.studentLessonPlanId : 0,
            mediaUrl: null,
            status: data.status || "not started",
          },
        };

        break;
      default:
        return false;
    }
    return eventData;
  }

  function getChores(queryParams, responseOnly) {
    let deferred = $q.defer(),
      getEventsPromise;

    queryParams.deviceType = calendarConstants.deviceType;

    // Subtract one day as buffer
    queryParams.startDate = moment(queryParams.startDate)
      .subtract(1, "d")
      .format("Y-MM-DD");

    // Add timezone
    queryParams.timezone = -new Date().getTimezoneOffset();

    getEventsPromise = $http({
      method: "GET",
      url: basePath + "/getChores",
      params: queryParams,
    });

    getEventsPromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((response) => {
        deferred.reject(response.data.message);
      });

    return deferred.promise;
  }

  function getNewChore(date) {
    const user = JSON.parse(localStorage.getItem("userData"));
    var reqData = JSON.stringify({
      query: `query GetOwnerChore($input: getOwnerChoreInput!) {
        getOwnerChore(input: $input) {
          message
          chores {
            choreId
            occurrenceId
            title
            userId
            startDate
            endDate
            endOfRecurrence
            timezone
            catergoryId
            allDay
            assignees {
              assigneeId
              modal
              entityId
              choreId
              data {
                id
                firstName
                lastName
                role
                mediaUrl
              }
              occurrenceChoreStatus {
                status
                statusCode
                occurrenceId
              }
            }
            files {
              id
              name
              userId
              model
              mediaUrl
              hash
              entityId
              meta {
                mimeType
                extension
              }
            }
            choreCategory {
              id
              media {
                id
                hash
                entityId
                mediaUrl
                model
                name
                userId
                meta {
                  extension
                  mimeType
                }
              }
              mediaId
              title
              type
              userId
            }
            recurrence {
              id
              choreId
              maxOccurrences
              month
              monthDay
              numberOfWeek
              seperationCount
              startOfRecurrence
              type
              weekDays
              endOfRecurrence
            }
          }
        }
      }`,
      variables: { input: { type: "date", dateToGet: date, parentId: user.userRole === "HELPER" ? user.id : undefined, } }
    });

    let chorePromise = $http({
      method: "POST",
      url: appConstants.graphQLUrl,
      data: reqData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authorizationToken")}`,
      },
    });
    return chorePromise;
  }

  /**
   * Index function to get the list of events releated to the current user.
   * @param {Object} queryParams The URL query parameters to attach to the request
   * @returns {Promise} An unresolved promise
   */
  function getEvents(queryParams, responseOnly) {
    let deferred = $q.defer(),
      getEventsPromise;

    queryParams.deviceType = calendarConstants.deviceType;

    // Subtract one day as buffer
    queryParams.startDate = moment(queryParams.startDate)
      .subtract(1, "d")
      .format("Y-MM-DD");

    // Add timezone
    queryParams.timezone = -new Date().getTimezoneOffset();

    getEventsPromise = $http({
      method: "GET",
      url: basePath,
      params: queryParams,
    });

    getEventsPromise
      .then((response) => {
        _.each(response.data.data, (event) => {
          if (event.model === "appointment") {
            saveEventToList(
              event.entityId,
              event.model,
              event,
              event.metaData.occurrenceId
            );
          } else {
            saveEventToList(event.entityId, event.model, event);
          }
        });

        if (responseOnly) {
          deferred.resolve(response.data.data);
        } else {
          deferred.resolve(events);
        }
      })
      .catch((response) => {
        deferred.reject(response.data.message);
      });

    return deferred.promise;
  }

  function printCalendarEvents(queryParams) {
    let url = basePath + "/print?";
    _.each(queryParams, (value, key) => {
      url += key + "=" + value + "&";
    });
    url += "authToken=" + Store.get("authorizationToken");
    window.open(url, "_self");
  }
}
export default calendarService;
