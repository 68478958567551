let angular = window.angular;
EditAssignmentConfirmationController.$inject = ["$uibModalInstance", "$scope"];
function EditAssignmentConfirmationController($uibModalInstance, $scope) {
  let vm = this;
  vm.rescheduleOption = "one";
  vm.message = "";
  vm.buttonName = "";
  angular.extend(vm, {
    close,
    save,
  });
  function close() {
    $uibModalInstance.dismiss();
  }
  function save() {
    $uibModalInstance.close(vm.rescheduleOption);
  }
  function init() {
    if ($scope.$resolve.confirmationModalData) {
      vm.buttonName = $scope.$resolve.confirmationModalData.buttonName;
      vm.message = $scope.$resolve.confirmationModalData.message;
    } else {
      vm.groupCheck = $scope.$resolve.isGroupAssignment;
    }
  }
  this.$onInit = init;
}
export default EditAssignmentConfirmationController;
