infiniteScrollReverse.$inject = ["$timeout", "_", "commonService"];
function infiniteScrollReverse($timeout, _, commonService) {
  return function (scope, elm, attr) {
    let element = elm[0];
    let old_scrolltop = element.scrollTop;

    $timeout(() => {
      old_scrolltop = element.scrollTop;
      element.scrollTop = element.scrollHeight;
    });

    function inifiniteScrollCall() {
      let offset = commonService.convertToInteger(attr.threshold) || 100;
      if (element.scrollTop < offset && attr.canLoad) {
        old_scrolltop = element.scrollTop;
        let sh = element.scrollHeight;
        let whenScrolledPromise = scope.$apply(attr.whenScrolled);
        let lastMessageId = attr.lastMessageId;
        if (whenScrolledPromise && whenScrolledPromise.then) {
          whenScrolledPromise.then((res) => {
            _.defer(() => {
              if (element.scrollHeight != sh) {
                element.scrollTop =
                  document.getElementById(lastMessageId).offsetTop - 20;
              }
            });
          });
        }
      }
    }

    let scrollCallback = _.throttle(() => {
      if (element.scrollTop < old_scrolltop) {
        inifiniteScrollCall();
      }
      old_scrolltop = element.scrollTop;
    }, 200);

    // let throttlescrollCallback = _.throttle(scrollCallback, 100);

    elm.bind("scroll", scrollCallback);

    scope.$on("$destroy", function () {
      elm.remove();
    });
  };
}

export default infiniteScrollReverse;
