const budgetConstants = {
    categoryLimit: 10,
    type: {
        overall: 'overallBudget',
        student: 'studentBudget'
    },
    messages: {
        deleteTransaction: 'Are you sure you want to remove this transaction?',
        enterValidCategory: 'Please enter a valid category name',
        leavePageConfirmation: 'The changes you made will be lost if you leave this page.\nContinue with unsaved changes?',
        categoryNameNotProvided: 'Category name not provided',
        deleteBudget: 'Are you sure you want to remove this budget?'
    },
    categories: {
        custom: { name: 'Custom Category', id: 0 }
    },
    graph: {
        types: {
            bar: 'bar',
            pie: 'pie',
            doughnut: 'doughnut'
        },
        title: {
            bar: 'Budget Vs Expenses',
            pie: 'Amount Spent Vs Remaining',
            doughnut: 'Budget by Category'
        },
        color: {
            remaining: '#5edc09',
            spent: '#dd3f11'
        }
    },
    endpoints: {
        budget: '/api/budgets',
        transaction: '/api/budgets/transactions',
        category: '/api/budgets/categories'
    }
};
export default budgetConstants;
