const angular = window.angular;

ReportModalController.$inject = ["$uibModalInstance"];

function ReportModalController($uibModalInstance) {
  /* jshint validthis:true */
  let vm = this;

  angular.extend(vm, {
    close,
    report,
  });

  function close() {
    vm.reportReason = "";
    $uibModalInstance.dismiss();
  }

  function report() {
    $uibModalInstance.close(vm.reportReason);
  }
}
export default ReportModalController;
