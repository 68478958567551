let angular = window.angular;

    agreeTermsController.$inject = ['userService', '$uibModalInstance'];

    function agreeTermsController(userService, $uibModalInstance) {
        /* jshint validthis:true */
        let vm = this;

        angular.extend(vm, {
            cancel,
            agree
        });

        function cancel(){
            return userService.logout()
                .then(()=>{
                    window.location.href = '/login';
                    $uibModalInstance.close();
                });
        }

        function agree() {
            return userService.agreeTerms()
                .then(()=>{
                    $uibModalInstance.close();
                });
        }
    }
    export default agreeTermsController;
