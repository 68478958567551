
let angular = window.angular;

commonService.$inject = ['$rootScope', 'moment', '$parse', '$uibModal', '_', '$http', '$location', 'lessonConstants', 'toastService', '$state', 'appConstants'];

function commonService($rootScope, moment, $parse, $uibModal, _, $http, $location, lessonConstants, toastService, $state, appConstants) {

    $rootScope.genderList = [{
        key: 'M',
        value: 'Male'
    },
    {
        key: 'F',
        value: 'Female'
    }
    ];

    let currentYear = new Date().getFullYear();
    $rootScope.datepickerYears = [];
    const days = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };

    for (let i = currentYear - 100; i <= currentYear; i++) {
        $rootScope.datepickerYears.push(i);
    }

    $rootScope.statuses = lessonConstants.statuses;

    let forStringifyNumber = {
        special: ['zeroth', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'],
        deca: ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet']
    };

    angular.extend($rootScope, {
        dateFormat,
        preventNegative,
        getStatusClass,
        selectAssignmentStatus,
        validateIsAfter,
        validateIsBefore,
        validateIsTouched,
        dateFormatForTranscript
    });

    angular.extend(this, {
        validURL,
        dateFormat,
        dateFormatForTranscript,
        preventNegative,
        findInArray,
        saveInArray,
        removeFromArray,
        convertToInteger,
        convertToBoolean,
        convertToUTC,
        convertFromUTC,
        checkIfMozilla,
        checkIfObjectEmpty,
        checkIfIe,
        checkIsTabActive,
        stringifyNumber,
        confirm,
        copyToClipboard,
        compareObjectChanges,
        getQueryString,
        fireIntercomEvent,
        getQuote,
        renderTemplateString,
        capitalizeFirstLetter,
        sortDateByStartDate,
        sanitizeDeepObject,
        convertFromUTCWithoutDST,
        validRequest,
        createLink,
        removeHTMLTags,
        trimInputField,
        getDaysBetweenDates,
        getDaysFromBinary,
        getBinaryFromDays,
        getHourFromTime,
        maxDateFromList,
        convertTimeToDecimal,
        getUngradedAssignmentsCount
    });

    function getUngradedAssignmentsCount() {
        const token = localStorage.getItem('authorizationToken');
        var data = JSON.stringify({
          query: `query Query {
          getUngradedAssignmentCount
        }`,
          variables: {}
        });
        return $http({
          method: "POST",
          url: appConstants.graphQLUrl,
          headers: { 
            'Authorization': `Bearer ${token}`, 
            'Content-Type': 'application/json',
            'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone
          },
          data : data
        });
    }

    function maxDateFromList(data) {

        let dates = data.slice() // copy the array for keeping original array with order
            // sort by parsing them to date
            .sort(function (a, b) {
                return new Date(a) - new Date(b);
            });
        return dates.pop();
    }

    function convertTimeToDecimal(time) {

        if (!time) {
            return 0;
        }
        let type = (typeof time);
        // Check if time is of type number or string
        if (type === 'number') {
            return time;
        } else if (type !== 'string') {
            return 0;
        }

        let duration = 0,
            regex = /^\d+:\d+$/;
        // Check if regex matches time format
        if (!regex.test(time)) {
            return 0;
        }
        time = time.split(":");
        duration += convertToInteger(time[0]);
        duration += parseFloat((convertToInteger(time[1]) / 60).toFixed(4));
        return duration;
    }

    function getDaysFromBinary(totalDays) {
        let result = [];
        let daysArray = _.keys(days);
        for (let i = 0; i < daysArray.length; i++) {
            if (totalDays[i] == 1) {
                result.push(daysArray[i]);
            }
        }
        return result;
    }

    function getBinaryFromDays(totalDays) {
        let result = [0, 0, 0, 0, 0, 0, 0];
        for (let i = 0; i < totalDays.length; i++) {
            if (totalDays[i] in days) {
                result[days[totalDays[i]]] = 1;
            }
        }
        result = result.join('');
        return result;
    }

    function validRequest(requestModel) {

        if ($rootScope.user && $rootScope.user.userRole == "STUDENT") {
            return true;
        }

        if (requestModel && $rootScope.user && $rootScope.user.payment) {
            let userPayment = $rootScope.user.payment;
            let userAllowedModules = userPayment.modules ? userPayment.modules : [];
            if (userPayment.modules && userAllowedModules.includes(requestModel)) {
                return true;
            }
        }
        $state.go('404');
        return false;
    }

    function sanitizeDeepObject(obj) {
        const result = [];
        for (const prop in obj) {
            const value = obj[prop];
            if (typeof value === 'object') {
                result.push(sanitizeDeepObject(value));
            } else {
                if (value !== null && value !== undefined && value) {
                    result.push({ prop: value });
                }
            }
        }
        return result;
    }

    function sortDateByStartDate(dateArray) {
        let temp = dateArray.sort((currentDate, nextDate) => {
            return (moment(currentDate.academicYearStart).isAfter(moment(nextDate.academicYearStart)));
        })
        return temp;
    }

    function validURL(str) {
        let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?' + // port
            '(\\/[-a-z\\d%@_.~+&:]*)*' + // path
            '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return pattern.test(str);
    }

    function getStatusClass(status) {
        return _.camelCase(status);
    }

    function selectAssignmentStatus(status, id, events) {
        events = events.map((event) => {
            if (event.entityId == id) {
                event.metaData.status = status;
            }
            return event;
        });

        changeAssignmentStatus(status, id)
            .then(function (response) {
                toastService.toast({
                    message: response.data.message,
                    type: 'success',
                    delay: 8000
                });
            })
            .catch(function (error) {
                // console.log('ERROR', error);
            });
    }

    function changeAssignmentStatus(status, id) {
        let changeStatusPromise;
        changeStatusPromise = $http({
            method: 'POST',
            url: appConstants.baseUrl + '/api/students/assignments/status/' + id,
            data: { status: status }
        });

        return changeStatusPromise;
    }


    function renderTemplateString(input, data) {
        let params = [];
        let startIndex = 0;
        let endOfString = false;
        let dataParams = [];

        if (!data) {
            return input;
        }
        while (!endOfString) {
            let newStartIndex = input.indexOf("${", startIndex) + 2;
            if (newStartIndex < startIndex) {
                endOfString = true;
                break;
            }

            let closingIndex = input.indexOf("}", newStartIndex);

            if (newStartIndex >= 0 && closingIndex > 0) {
                let param = input.substring(newStartIndex, closingIndex);
                params.push(param);
            }

            startIndex = newStartIndex;
        }

        for (let i = 0; i < params.length; i++) {
            if (data[params[i]]) {
                dataParams.push(data[params[i]]);
            } else {
                dataParams.push('${' + params[i] + '}');
            }
        }

        let renderFunction = new Function(params, "return `" + input + "`;");
        let renderedString = renderFunction(...dataParams);
        return renderedString;
    }


    function getQueryString() {
        let queryString = $location.search();
        return queryString;
    }

    function fireIntercomEvent(eventName) {
        $http.post('/api/users/fireIntercomEvent', { eventName: eventName }).then(function (res) { });
    }

    function stringifyNumber(n) {
        if (n < 20) {
            return forStringifyNumber.special[n];
        } else if (n % 10 === 0) {
            return forStringifyNumber.deca[Math.floor(n / 10) - 2] + 'ieth';
        }
        return forStringifyNumber.deca[Math.floor(n / 10) - 2] + 'y-' + forStringifyNumber.special[n % 10];
    }
    
    function dateFormat(date, format) {
        if (date === 'Invalid date' || date === null || !date) {
            return;
        }
        // console.log(date, format, 'dateformat----');
        // console.log('dffn',moment(date).format(format));
        let res = moment(date).format(format);
        return res
    }

    function dateFormatForTranscript(date, format) {
        if (date === 'Invalid date' || date === null || !date) {
            return;
        }
        let res = moment.utc(date).format(format);
        return res
    }

    function compareObjectChanges(originalObject, newObject) {
        return _.reduce(originalObject, function (result, n, key) {
            result = result || originalObject[key] != newObject[key];
            return result;
        }, false);
    }

    /**
     * Function to prevent user from entering negative numbers in type="number" input fields.
     * Allows user to enter only 0-9 from either Keyboard or Numpad, and Backspace and Tab keys are registered.
     * @param {Event} event for keydown
     */
    function preventNegative(event) {
        if (!((event.keyCode > 95 && event.keyCode < 106) || (event.keyCode > 47 && event.keyCode < 58) || event.keyCode == 8 || event.keyCode == 190 || event.keyCode == 110 || event.keyCode == 9 || event.keyCode == 37 || event.keyCode == 39 || event.keyCode == 46)) {
            event.preventDefault();
        }
    }


    /**
     * Function to find a particular element/object from an array of objects.
     * @param {Mixed} needle The value to be matched against
     * @param {String} keypath The path to the value against which the needle would be matched
     * @param {Array} haystack The array in which the search has to be made
     * @returns {Number} The index of the needle in the haystack
     * @returns {Number}
     */
    function findInArray(needle, keypath, haystack) {
        if (!needle || !keypath || !haystack.length) {
            return -1;
        }
        let getKeyValue = $parse(keypath);
        for (let i = haystack.length - 1; i >= 0; i--) {
            if (getKeyValue(haystack[i]) === needle) {
                return i;
            }
        }
        return -1;
    }

    function saveInArray(needle, keypath, haystack, data, addAtStart) {

        if (!needle || !keypath || !(haystack instanceof Array)) {
            return false;
        }

        let index = findInArray(needle, keypath, haystack);

        if (!~index) {
            if (addAtStart) {
                haystack.unshift(data);
            } else {
                haystack.push(data);
            }
        } else {
            haystack[index] = data;
        }
        // console.log(haystack);
        return true;
    }


    /**
     * Function to remove an object entry from an array of objects where the needle matches the keypath value
     * @param {Mixed} needle The value to be matched against
     * @param {String} keypath The path to the value against which the needle would be matched
     * @param {Array} haystack The array in which the search has to be made
     * @returns {Array} The updated haystack
     */
    function removeFromArray(needle, keypath, haystack) {
        if (!needle || !keypath || !haystack.length) {
            return haystack;
        }
        let index = findInArray(needle, keypath, haystack);
        if (index < 0) {
            return haystack;
        }
        haystack.splice(index, 1);
        return haystack;
    }

    /**
     * Function to convert a string to an integer. It uses the Bitwise-Not(~) operator, which is a faster alternative,
     * and further when a string/object/array is passed, it returns 0 instead of NaN, which is returned by parseInt.
     * @param {Mixed} number The value to be converted to integer.
     * @returns {Number} The integer equivalent of the value
     */
    function convertToInteger(number) {
        return ~~number;
    }

    function convertToBoolean(input) {
        if (typeof input === 'object') {
            return !_.isEmpty(input)
        } else if (typeof input === 'string') {
            return input === 'true' || !!convertToInteger(input);
        } else {
            return !!input;
        }
    }

    /**
     * Function to indecate a button is loading
     * @param {String} btnId Jquery id slector of button
     * @param {String} text the label text to indecate: default value is 'loading'
     */
    $rootScope.loadingBtn = function (btnId, text) {
        let btn = angular.element(document.getElementById(btnId));
        $rootScope[btnId] = btn.html();
        text = text || 'Loading';
        btn.html('<span class="spinner"></span> ' + text + '');
        btn.prop('disabled', true);
    };

    /**
     * Function to indecate a button has completed loading
     * @param {String} btnId Jquery id slector of button
     * @param {String} text the label text of button
     */
    $rootScope.loadingBtnComplete = function (btnId, text) {
        let btn = angular.element(document.getElementById(btnId));
        let renderObject = (angular.isDefined(text) ? text : $rootScope[btnId]);
        btn.html(renderObject);
        $rootScope[btnId] = null;
        btn.removeAttr('disabled');
    };

    function checkIsTabActive() {
        let state, result = false;
        if (typeof document.hidden !== 'undefined') {
            state = 'visibilityState';
        } else if (typeof document.mozHidden !== 'undefined') {
            state = 'mozVisibilityState';
        } else if (typeof document.msHidden !== 'undefined') {
            state = 'msVisibilityState';
        } else if (typeof document.webkitHidden !== 'undefined') {
            state = 'webkitVisibilityState';
        }

        if (document[state] === 'hidden')
            result = false;
        else
            result = true;
        return result;
    }

    function checkIfMozilla() {
        return typeof InstallTrigger !== 'undefined';
    }

    function checkIfObjectEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return true;
        }
        return false;
    }

    function checkIfIe() {
        return !!window.MSInputMethodContext && !!document.documentMode;
    }

    function convertToUTC(date, format = 'Y-MM-DD HH:mm:ss') {
        return moment(date, format).utc();
    }

    function convertFromUTC(date) {
        return moment.utc(date).local();
    }

    function convertFromUTCWithoutDST(date) {
        return moment(date);
    }

    /**
     * @param {type} data
     * @param {string : any} message,
     * @param {string : ['danger', 'primary']} type *optional,
     * @param {string : any} closeLabel *optional,
     * @param {string : any} confirmLabel *optional,
     * @param {string : ['lg', 'md', 'sm']} modalSize *optional,
     * @returns {unresolved}
     */
    function confirm(data = {}) {

        let confirmModalInstance = $uibModal.open({
            animation: true,
            ariaLabelledBy: 'modal-title-top',
            ariaDescribedBy: 'modal-body-top',
            template: require('../../pug/components/confirm-modal.pug').default,
            size: (data.modalSize) ? data.modalSize : 'sm',
            controller: 'ConfirmModalController',
            controllerAs: 'confirmCtrl',
            backdrop: 'static',
            resolve: {
                data
            }
        });

        return confirmModalInstance.result.then((result) => {
            return result;
        }, function () {
            return { result: false };
        }).catch(function () {
            confirmModalInstance.close();
        });
    }

    function copyToClipboard(text) {
        if (window.clipboardData && window.clipboardData.setData) {
            // IE specific code path to prevent textarea being shown while dialog is visible.
            return window.clipboardData.setData("Text", text);

        } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            let textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand("copy"); // Security exception may be thrown by some browsers.
            } catch (ex) {
                console.warn("Copy to clipboard failed.", ex);
                return false;
            } finally {
                document.body.removeChild(textarea);
            }
        }
    }

    function getQuote() {
        return $http.get('/data/quotes.json');
    }

    function validateIsBefore(beforeDate, afterDate, equalToMatch) {
        if (!beforeDate || !afterDate) {
            return true;
        }

        if (angular.isDefined(equalToMatch) && equalToMatch) {
            return moment(beforeDate).isSameOrBefore(moment(afterDate));
        } else {
            return moment(beforeDate).isBefore(moment(afterDate));
        }
    }

    function validateIsAfter(afterDate, beforeDate, equalToMatch) {
        if (!beforeDate || !afterDate) {
            return true;
        }

        if (angular.isDefined(equalToMatch) && equalToMatch) {
            return moment(afterDate).isSameOrAfter(moment(beforeDate));
        } else {
            return moment(afterDate).isAfter(moment(beforeDate));
        }
    }

    function validateIsTouched(field, form) {
        return field.$touched || form.$submitted;
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function removeHTMLTags(html) {
        if (html) {
            html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
            html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
            html = html.replace(/<\/div>/ig, '\n');
            html = html.replace(/<\/li>/ig, '\n');
            html = html.replace(/<li>/ig, '  *  ');
            html = html.replace(/<\/ul>/ig, '\n');
            html = html.replace(/<\/p>/ig, '\n');
            html = html.replace(/<br\s*[\/]?>/gi, "\n");
            html = html.replace(/<[^>]+>/ig, '');
            html = html.replace(/<\/?[^>]+>/ig, " ");
            html = html.replace(/&nbsp;/ig, " ");
        }
        return html;
    }

    function trimInputField(input) {

        if (input) {
            let trimmedInput;
            trimmedInput = input.replace(/<br\s*[\/]?>/gi, "");
            trimmedInput = input.trim();
            if (trimmedInput.length == 0) {
                input = trimmedInput;
            }
        }

        return input;
    }

    function getHourFromTime(time) {
        if (time) {
            time = time + '';
            let duration = time.split(':');
            return convertToInteger(duration[0]);
        }
        return 0;
    }

    function createLink(text) {

        if (!text || text == null) {
            return text;
        }

        let checkHtml = /<[a-z][\s\S]*>/i.test(text);
        if (!checkHtml) {
            var regex = /(https?:\/\/([-\w\.]+)+(:\d+)?(\/([\w\/\-_\.]*(\?\S+)?)?)?)/ig;
            var replaced_text = text.replace(regex, "<a href='$1' target='_blank'>$1</a>");
            text = replaced_text;
        }

        return text;
    }

    /* Given a start date, end date and day name, return
    * an array of dates between the two dates for the
    * given day inclusive
    * @param {Date} start - date to start from
    * @param {Date} end - date to end on
    * @param {string} dayName - name of day
    * @returns {Array} array of Dates
    */
    function getDaysBetweenDates(start, end, totalDays, offset = 0) {
        let result = [];

        for (let i = 0; i < totalDays.length; i++) {
            let day = days[totalDays[i]];
            let current = new Date(start);

            current.setDate(current.getDate() + (day - current.getDay() + 7) % 7);

            while (current < end) {
                /**
                 * The + symbol acts as a unary + and converts anything it is provided into a number.
                 * For more information refer: https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Operators/Arithmetic_Operators#Unary_plus_()
                 */

                result.push(new Date(+current + offset));
                current.setDate(current.getDate() + 7);
            }
        }

        result = result.sort((a, b) => {
            if (a > b) {
                return 1;
            }
            if (a < b) {
                return -1;
            }
            return 0;
        });

        return result;
    }
}

export default commonService;
