const locationContants = {
    countries: [{
        code: 'US',
        name: 'United States',
        callingCode: 1,
        label: 'United States ‪( + 1)‬'
    },
        {
            name: 'Afghanistan',
            code: 'AF',
            callingCode: 93,
            label: 'Afghanistan ‪(+93)‬'
        },
        {
            name: 'Albania',
            code: 'AL',
            callingCode: 355,
            label: 'Albania ‪(+355)‬'
        },
        {
            name: 'Åland Islands',
            code: 'AX',
            callingCode: 358,
            label: 'Åland Islands ‪( + 358)‬'
        },
        {
            name: 'Algeria',
            code: 'DZ',
            callingCode: 213,
            label: 'Algeria ‪(+213)‬'
        },
        {
            name: 'American Samoa',
            code: 'AS',
            callingCode: 1,
            label: 'American Samoa ‪( + 1)‬'
        },
        {
            name: 'Andorra',
            code: 'AD',
            callingCode: 376,
            label: 'Andorra ‪( + 376)‬‬'
        },
        {
            name: 'Angola',
            code: 'AO',
            callingCode: 244,
            label: 'Angola ‪( + 244)'
        },
        {
            name: 'Anguilla',
            code: 'AI',
            callingCode: 1,
            label: 'Anguilla ‪( + 1)‬'
        },
        {
            name: 'Antigua and Barbuda',
            code: 'AG',
            callingCode: 1,
            label: 'Antigua and Barbuda ‪( + 1)‬'
        },
        {
            name: 'Argentina',
            code: 'AR',
            callingCode: 54,
            label: 'Argentina ‪( + 54)'
        },
        {
            name: 'Armenia',
            code: 'AM',
            callingCode: 374,
            label: 'Armenia ‪( + 374)‬'
        },
        {
            name: 'Aruba',
            code: 'AW',
            callingCode: 297,
            label: 'Aruba ‪( + 297)‬'
        },
        {
            name: 'Australia',
            code: 'AU',
            callingCode: 61,
            label: 'Australia ‪( + 61)‬'
        },
        {
            name: 'Austria',
            code: 'AT',
            callingCode: 43,
            label: 'Austria ‪( + 43)‬'
        },
        {
            name: 'Azerbaijan',
            code: 'AZ',
            callingCode: 994,
            label: 'Azerbaijan ‪( + 994)‬'
        },
        {
            name: 'Bahamas',
            code: 'BS',
            callingCode: 1,
            label: 'Bahamas ‪( + 1)‬'
        },
        {
            name: 'Bahrain',
            code: 'BH',
            callingCode: 973,
            label: 'Bahrain ‪( + 973)‬'
        },
        {
            name: 'Bangladesh',
            code: 'BD',
            callingCode: 880,
            label: 'Bangladesh ‪( + 880)‬'
        },
        {
            name: 'Barbados',
            code: 'BB',
            callingCode: 1,
            label: 'Barbados ‪( + 1)‬'
        },
        {
            name: 'Belarus',
            code: 'BY',
            callingCode: 375,
            label: 'Belarus ‪( + 375)‬'
        },
        {
            name: 'Belgium',
            code: 'BE',
            callingCode: 32,
            label: 'Belgium ‪( + 32)‬'
        },
        {
            name: 'Belize',
            code: 'BZ',
            callingCode: 501,
            label: 'Belize ‪( + 501)‬'
        },
        {
            name: 'Benin',
            code: 'BJ',
            callingCode: 229,
            label: 'Benin ‪( + 229)‬‬'
        },
        {
            name: 'Bermuda',
            code: 'BM',
            callingCode: 1,
            label: 'Bermuda ‪( + 1)‬'
        },
        {
            name: 'Bhutan',
            code: 'BT',
            callingCode: 975,
            label: 'Bhutan ‪( + 975)‬'
        },
        {
            name: 'Bolivia',
            code: 'BO',
            callingCode: 591,
            label: 'Bolivia ‪( + 591)‬'
        },
        {
            name: 'Bosnia and Herzegovina',
            code: 'BA',
            callingCode: 387,
            label: 'Bosnia and Herzegovina ‪( + 387)‬'
        },
        {
            name: 'Botswana',
            code: 'BW',
            callingCode: 267,
            label: 'Botswana ‪( + 267)‬'
        },
        {
            name: 'Brazil',
            code: 'BR',
            callingCode: 55,
            label: 'Brazil ‪( + 55)'
        },
        {
            name: 'British Indian Ocean Territory',
            code: 'IO',
            callingCode: 246,
            label: 'British Indian Ocean Territory ‪( + 246)‬‬'
        },
        {
            name: 'Brunei Darussalam',
            code: 'BN',
            callingCode: 673,
            label: 'Brunei Darussalam ‪( + 673)‬'
        },
        {
            name: 'Bulgaria',
            code: 'BG',
            callingCode: 359,
            label: 'Bulgaria ‪( + 359)‬'
        },
        {
            name: 'Burkina Faso',
            code: 'BF',
            callingCode: 226,
            label: 'Burkina Faso ‪( + 226)‬'
        },
        {
            name: 'Burundi',
            code: 'BI',
            callingCode: 257,
            label: 'Burundi ‪( + 257)‬'
        },
        {
            name: 'Cambodia',
            code: 'KH',
            callingCode: 855,
            label: 'Cambodia ‪( + 855)‬'
        },
        {
            name: 'Cameroon',
            code: 'CM',
            callingCode: 237,
            label: 'Cameroon ‪( + 237)'
        },
        {
            name: 'Canada',
            code: 'CA',
            callingCode: 1,
            label: 'Canada ‪( + 1)‬‬'
        },
        {
            name: 'Cape Verde',
            code: 'CV',
            callingCode: 238,
            label: 'Cape Verde ‪( + 238)‬‬'
        },
        {
            name: 'Caribbean Netherlands',
            code: 'BQ',
            callingCode: 599,
            label: 'Caribbean Netherlands ( + 599)‬'
        },
        {
            name: 'Cayman Islands',
            code: 'KY',
            callingCode: 1,
            label: 'Cayman Islands ‪( + 1)‬'
        },
        {
            name: 'Central African Republic',
            code: 'CF',
            callingCode: 236,
            label: 'Central African Republic ‪( + 236)‬'
        },
        {
            name: 'Chad',
            code: 'TD',
            callingCode: 235,
            label: 'Chad ‪( + 235)‬'
        },
        {
            name: 'Chile',
            code: 'CL',
            callingCode: 56,
            label: 'Chile ‪( + 56)‬'
        },
        {
            name: 'China',
            code: 'CN',
            callingCode: 86,
            label: 'China ‪( + 86)‬'
        },
        {
            name: 'Christmas Island',
            code: 'CX',
            callingCode: 61,
            label: 'Christmas Island ‪( + 61)‬',
        },
        {
            name: 'Cocos (Keeling) Islands',
            code: 'CC',
            callingCode: 61891,
            label: 'Cocos (Keeling) Islands ‪( + 61 891)‬'
        },
        {
            name: 'Colombia',
            code: 'CO',
            callingCode: 57,
            label: 'Colombia ‪( + 57)‬'
        },
        {
            name: 'Comoros',
            code: 'KM',
            callingCode: 269,
            label: 'Comoros ‪( + 269)‬'
        },
        {
            name: 'Congo',
            code: 'CG',
            callingCode: 242,
            label: 'Congo ‪( + 242)‬'
        },
        {
            name: 'Cook Islands',
            code: 'CK',
            callingCode: 682,
            label: 'Cook Islands ‪( + 682)‬'
        },
        {
            name: 'Costa Rica',
            code: 'CR',
            callingCode: 506,
            label: 'Costa Rica ‪( + 506)‬'
        },
        {
            name: 'Cote D\'Ivoire',
            code: 'CI',
            callingCode: 225,
            label: 'Cote D\'Ivoire ( + 225)‬'
        },
        {
            name: 'Croatia',
            code: 'HR',
            callingCode: 385,
            label: 'Croatia ‪( + 385)‬'
        },
        {
            name: 'Cuba',
            code: 'CU',
            callingCode: 53,
            label: 'Cuba ‪( + 53)‬'
        },
        {
            name: 'Cyprus',
            code: 'CY',
            callingCode: 357,
            label: 'Cyprus ‪( + 357)‬'
        },
        {
            name: 'Czech Republic',
            code: 'CZ',
            callingCode: 420,
            label: 'Czech Republic ‪( + 420)‬'
        },
        {
            name: 'Democratic Republic of the Congo',
            code: 'CD',
            callingCode: 243,
            label: 'Democratic Republic of the Congo ‪( + 243)‬'
        },
        {
            name: 'Denmark',
            code: 'DK',
            callingCode: 45,
            label: 'Denmark ‪( + 45)‬'
        },
        {
            name: 'Djibouti',
            code: 'DJ',
            callingCode: 253,
            label: 'Djibouti ‪( + 253)‬'
        },
        {
            name: 'Dominica',
            code: 'DM',
            callingCode: 1,
            label: 'Dominican Republic ‪( + 1)‬'
        },
        {
            name: 'Dominica',
            code: 'DM',
            callingCode: 1,
            label: 'Dominica ‪( + 1)‬'
        },
        {
            name: 'Dominican Republic',
            code: 'DO',
            callingCode: 1,
            label: 'Dominican Republic ‪( + 1)‬'
        },
        {
            name: 'Ecuador',
            code: 'EC',
            callingCode: 593,
            label: 'Ecuador ‪( + 593)‬‬'
        },
        {
            name: 'Egypt',
            code: 'EG',
            callingCode: 20,
            label: 'Egypt ‪( + 20)‬'
        },
        {
            name: 'El Salvador',
            code: 'SV',
            callingCode: 503,
            label: 'El Salvador ‪( + 503)‬‬'
        },
        {
            name: 'Equatorial Guinea',
            code: 'GQ',
            callingCode: 240,
            label: 'Equatorial Guinea ‪( + 240)‬'
        },
        {
            name: 'Eritrea',
            code: 'ER',
            callingCode: 291,
            label: 'Eritrea ‪( + 291)‬'
        },
        {
            name: 'Estonia',
            code: 'EE',
            callingCode: 372,
            label: 'Estonia ‪( + 372)‬'
        },
        {
            name: 'Ethiopia',
            code: 'ET',
            callingCode: 251,
            label: 'Ethiopia ‪( + 251)‬'
        },
        {
            name: 'Falkland Islands (Malvinas)',
            code: 'FK',
            callingCode: 500,
            label: 'Falkland Islands (Malvinas) ( + 500)‬'
        },
        {
            name: 'Faroe Islands',
            code: 'FO',
            callingCode: 298,
            label: 'Faroe Islands ‪( + 298)‬'
        },
        {
            name: 'Federated States of Micronesia',
            code: 'FM',
            callingCode: 691,
            label: 'Federated States of Micronesia ‪( + 691)‬'
        },
        {
            name: 'Fiji',
            code: 'FJ',
            callingCode: 679,
            label: 'Fiji ‪( + 679)‬'
        },
        {
            name: 'Finland',
            code: 'FI',
            callingCode: 358,
            label: 'Finland ‪( + 358)‬'
        },
        {
            name: 'France',
            code: 'FR',
            callingCode: 33,
            label: 'France ‪( + 33)‬'
        },
        {
            name: 'French Guiana',
            code: 'GF',
            callingCode: 594,
            label: 'French Guiana ‪( + 594)‬'
        },
        {
            name: 'French Polynesia',
            code: 'PF',
            callingCode: 689,
            label: 'French Polynesia ‪( + 689)‬'
        },
        {
            name: 'French Southern and Antarctic Lands',
            code: 'TF',
            callingCode: 262,
            label: 'French Southern and Antarctic Lands ( + 262)‬'
        },
        {
            name: 'Gabon',
            code: 'GA',
            callingCode: 241,
            label: 'Gabon ‪( + 241)‬‬'
        },
        {
            name: 'Gambia',
            code: 'GM',
            callingCode: 220,
            label: 'Gambia ‪( + 220)'
        },
        {
            name: 'Georgia',
            code: 'GE',
            callingCode: 995,
            label: 'Georgia ‪( + 995)‬'
        },
        {
            name: 'Germany',
            code: 'DE',
            callingCode: 49,
            label: 'Germany ‪( + 49)‬'
        },
        {
            name: 'Ghana',
            code: 'GH',
            callingCode: 233,
            label: 'Ghana ‪( + 233)‬'
        },
        {
            name: 'Gibraltar',
            code: 'GI',
            callingCode: 350,
            label: 'Gibraltar ‪( + 350)‬'
        },
        {
            name: 'Greece',
            code: 'GR',
            callingCode: 30,
            label: 'Greece ‪( + 30)‬'
        },
        {
            name: 'Greenland',
            code: 'GL',
            callingCode: 299,
            label: 'Greenland ‪( + 299)‬'
        },
        {
            name: 'Grenada',
            code: 'GD',
            callingCode: 1,
            label: 'Grenada ‪( + 1)‬'
        },
        {
            name: 'Guadeloupe',
            code: 'GP',
            callingCode: 590,
            label: 'Guadeloupe ‪( + 590)‬'
        },
        {
            name: 'Guam',
            code: 'GU',
            callingCode: 1,
            label: 'Guam ‪( + 1)‬'
        },
        {
            name: 'Guatemala',
            code: 'GT',
            callingCode: 502,
            label: 'Guatemala ‪( + 502)‬'
        },
        {
            name: 'Guernsey',
            code: 'GG',
            callingCode: 44,
            label: 'French Southern and Antarctic Lands ( + 44)‬'
        },
        {
            name: 'Guinea',
            code: 'GN',
            callingCode: 224,
            label: 'Guinea ‪( + 224)‬‬'
        },
        {
            name: 'Guinea',
            code: 'GN',
            callingCode: 675,
            label: 'Papua New Guinea ‪( + 675)‬'
        },
        {
            name: 'Guinea',
            code: 'GN',
            callingCode: 245,
            label: 'Guinea-Bissau (Guiné-Bissau)( + 245)‬‬'
        },
        {
            name: 'Guinea',
            code: 'GN',
            callingCode: 240,
            label: 'Equatorial Guinea ‪( + 240)‬'
        },
        {
            name: 'Guinea-Bissau',
            code: 'GW',
            callingCode: 245,
            label: 'Guinea-Bissau (Guiné-Bissau)( + 245)‬‬'
        },
        {
            name: 'Guyana',
            code: 'GY',
            callingCode: 592,
            label: 'Guyana ‪( + 592)‬'
        },
        {
            name: 'Haiti',
            code: 'HT',
            callingCode: 509,
            label: 'Haiti ‪( + 509)‬‬'
        },
        {
            name: 'Honduras',
            code: 'HN',
            callingCode: 504,
            label: 'Honduras ‪( + 504)‬'
        },
        {
            name: 'Hong Kong',
            code: 'HK',
            callingCode: 852,
            label: 'Hong Kong ‪( + 852)‬'
        },
        {
            name: 'Hungary',
            code: 'HU',
            callingCode: 36,
            label: 'Hungary ‪( + 36)'
        },
        {
            name: 'Iceland',
            code: 'IS',
            callingCode: 354,
            label: 'Iceland ‪( + 354)‬'
        },
        {
            name: 'India',
            code: 'IN',
            callingCode: 91,
            label: 'India ‪( + 91)‬'
        },
        {
            name: 'Indonesia',
            code: 'ID',
            callingCode: 62,
            label: 'Indonesia ‪( + 62)‬'
        },
        {
            name: 'Iran',
            code: 'IR',
            callingCode: 98,
            label: 'Iran ‪( + 98)'
        },
        {
            name: 'Iraq',
            code: 'IQ',
            callingCode: 964,
            label: 'Iraq ‪( + 964)‬‬'
        },
        {
            name: 'Ireland',
            code: 'IE',
            callingCode: 353,
            label: 'Ireland ‪( + 353)‬'
        },
        {
            name: 'Isle of Man',
            code: 'IM',
            callingCode: 44,
            label: 'Isle of Man ( + 44)‬'
        },
        {
            name: 'Israel',
            code: 'IL',
            callingCode: 972,
            label: 'Israel ‪( + 972)‬'
        },
        {
            name: 'Italy',
            code: 'IT',
            callingCode: 39,
            label: 'Italy ‪( + 39)‬'
        },
        {
            name: 'Jamaica',
            code: 'JM',
            callingCode: 1,
            label: 'Jamaica ‪( + 1)‬'
        },
        {
            name: 'Japan',
            code: 'JP',
            callingCode: 81,
            label: 'Japan ‪( + 81)‬'
        },
        {
            name: 'Jersey',
            code: 'JE',
            callingCode: 44,
            label: 'Jersey ( + 44)‬'
        },
        {
            name: 'Jordan',
            code: 'JO',
            callingCode: 962,
            label: 'Jordan ‪( + 962)‬'
        },
        {
            name: 'Kazakhstan',
            code: 'KZ',
            callingCode: 7,
            label: 'Kazakhstan ‪( + 7)‬'
        },
        {
            name: 'Kenya',
            code: 'KE',
            callingCode: 254,
            label: 'Kenya ‪( + 254)‬'
        },
        {
            name: 'Kiribati',
            code: 'KI',
            callingCode: 686,
            label: 'Kiribati ‪( + 686)‬'
        },
        {
            name: 'Kuwait',
            code: 'KW',
            callingCode: 965,
            label: 'Kuwait ‪( + 965)‬'
        },
        {
            name: 'Kyrgyzstan',
            code: 'KG',
            callingCode: 996,
            label: 'Kyrgyzstan ‪( + 996)‬'
        },
        {
            name: 'Laos',
            code: 'LA',
            callingCode: 856,
            label: 'Laos ‪( + 856)‬'
        },
        {
            name: 'Latvia',
            code: 'LV',
            callingCode: 371,
            label: 'Latvia ‪( + 371)'
        },
        {
            name: 'Lebanon',
            code: 'LB',
            callingCode: 961,
            label: 'Lebanon ‪( + 961)‬‬'
        },
        {
            name: 'Lesotho',
            code: 'LS',
            callingCode: 266,
            label: 'Lesotho ‪( + 266)‬'
        },
        {
            name: 'Liberia',
            code: 'LR',
            callingCode: 231,
            label: 'Liberia ‪( + 231)‬'
        },
        {
            name: 'Libya',
            code: 'LY',
            callingCode: 218,
            label: 'Libya ‪( + 218)'
        },
        {
            name: 'Liechtenstein',
            code: 'LI',
            callingCode: 423,
            label: 'Liechtenstein ‪( + 423)‬'
        },
        {
            name: 'Lithuania',
            code: 'LT',
            callingCode: 370,
            label: 'Lithuania ‪( + 370)‬'
        },
        {
            name: 'Luxembourg',
            code: 'LU',
            callingCode: 352,
            label: 'Luxembourg ‪( + 352)‬'
        },
        {
            name: 'Macao',
            code: 'MO',
            callingCode: 853,
            label: 'Macao ‪( + 853)‬'
        },
        {
            name: 'Macedonia (FYROM)',
            code: 'MK',
            callingCode: 389,
            label: 'Macedonia (FYROM) ‪( + 389)‬'
        },
        {
            name: 'Madagascar',
            code: 'MG',
            callingCode: 261,
            label: 'Madagascar ‪( + 261)‬'
        },
        {
            name: 'Malawi',
            code: 'MW',
            callingCode: 265,
            label: 'Malawi ‪( + 265)‬'
        },
        {
            name: 'Malaysia',
            code: 'MY',
            callingCode: 60,
            label: 'Malaysia ‪( + 60)‬'
        },
        {
            name: 'Maldives',
            code: 'MV',
            callingCode: 960,
            label: 'Maldives ‪( + 960)‬'
        },
        {
            name: 'Mali',
            code: 'ML',
            callingCode: 223,
            label: 'Mali ‪( + 223)‬'
        },
        {
            name: 'Malta',
            code: 'MT',
            callingCode: 356,
            label: 'Malta ‪( + 356)‬'
        },
        {
            name: 'Marshall Islands',
            code: 'MH',
            callingCode: 692,
            label: 'Marshall Islands ‪( + 692)‬'
        },
        {
            name: 'Martinique',
            code: 'MQ',
            callingCode: 596,
            label: 'Martinique ‪( + 596)‬'
        },
        {
            name: 'Mauritania',
            code: 'MR',
            callingCode: 222,
            label: 'Mauritania ‪( + 222)‬'
        },
        {
            name: 'Mauritius',
            code: 'MU',
            callingCode: 230,
            label: 'Mauritius ‪( + 230)‬'
        },
        {
            name: 'Mayotte',
            code: 'YT',
            callingCode: 262,
            label: 'Mayotte ‪( + 262)‬'
        },
        {
            name: 'Mexico',
            code: 'MX',
            callingCode: 52,
            label: 'Mexico ‪( + 52)‬'
        },
        {
            name: 'Moldova',
            code: 'MD',
            callingCode: 373,
            label: 'Moldova ‪( + 373)‬'
        },
        {
            name: 'Monaco',
            code: 'MC',
            callingCode: 377,
            label: 'Monaco ‪( + 377)‬'
        },
        {
            name: 'Mongolia',
            code: 'MN',
            callingCode: 976,
            label: 'Mongolia ‪( + 976)‬'
        },
        {
            name: 'Montenegro',
            code: 'ME',
            callingCode: 382,
            label: 'Montenegro ‪( + 382)‬'
        },
        {
            name: 'Montserrat',
            code: 'MS',
            callingCode: 1,
            label: 'Montserrat ‪( + 1)'
        },
        {
            name: 'Morocco',
            code: 'MA',
            callingCode: 212,
            label: 'Morocco ‪( + 212)‬'
        },
        {
            name: 'Mozambique',
            code: 'MZ',
            callingCode: 258,
            label: 'Mozambique ‪( + 258)‬'
        },
        {
            name: 'Myanmar',
            code: 'MM',
            callingCode: 95,
            label: 'Myanmar ‪( + 95)‬'
        },
        {
            name: 'Namibia',
            code: 'NA',
            callingCode: 264,
            label: 'Namibia ‪( + 264)‬'
        },
        {
            name: 'Nauru',
            code: 'NR',
            callingCode: 674,
            label: 'Nauru ‪( + 674)‬'
        },
        {
            name: 'Nepal',
            code: 'NP',
            callingCode: 977,
            label: 'Nepal ‪( + 977)‬'
        },
        {
            name: 'Netherlands',
            code: 'NL',
            callingCode: 31,
            label: 'Netherlands ‪( + 31)‬'
        },
        {
            name: 'New Caledonia',
            code: 'NC',
            callingCode: 687,
            label: 'New Caledonia ‪( + 687)‬'
        },
        {
            name: 'New Zealand',
            code: 'NZ',
            callingCode: 64,
            label: 'New Zealand ‪( + 64)‬'
        },
        {
            name: 'Nicaragua',
            code: 'NI',
            callingCode: 505,
            label: 'Nicaragua ‪( + 505)‬'
        },
        {
            name: 'Niger',
            code: 'NE',
            callingCode: 227,
            label: 'Niger ‪( + 227)‬'
        },
        {
            name: 'Niger',
            code: 'NE',
            callingCode: 234,
            label: 'Nigeria ‪( + 234)‬'
        },
        {
            name: 'Nigeria',
            code: 'NG',
            callingCode: 234,
            label: 'Nigeria ‪( + 234)‬'
        },
        {
            name: 'Niue',
            code: 'NU',
            callingCode: 683,
            label: 'Niue ‪( + 683)‬'
        },
        {
            name: 'Norfolk Island',
            code: 'NF',
            callingCode: 672,
            label: 'Norfolk Island ‪( + 672)‬'
        },
        {
            name: 'North Korea',
            code: 'KP',
            callingCode: 850,
            label: 'North Korea ‪( + 850)‬'
        },
        {
            name: 'Northern Mariana Islands',
            code: 'MP',
            callingCode: 1,
            label: 'Northern Mariana Islands ‪( + 1)‬'
        },
        {
            name: 'Norway',
            code: 'NO',
            callingCode: 47,
            label: 'Norway ‪( + 47)‬'
        },
        {
            name: 'Oman',
            code: 'OM',
            callingCode: 968,
            label: 'Oman ‪( + 968)‬'
        },
        {
            name: 'Pakistan',
            code: 'PK',
            callingCode: 92,
            label: 'Pakistan ‪( + 92)‬'
        },
        {
            name: 'Palau',
            code: 'PW',
            callingCode: 680,
            label: 'Palau ‪( + 680)‬'
        },
        {
            name: 'Palestine',
            code: 'PS',
            callingCode: 970,
            label: 'Palestine ‪( + 970)‬'
        },
        {
            name: 'Panama',
            code: 'PA',
            callingCode: 507,
            label: 'Panama ‪( + 507)‬'
        },
        {
            name: 'Papua New Guinea',
            code: 'PG',
            callingCode: 675,
            label: 'Papua New Guinea ‪( + 675)‬'
        },
        {
            name: 'Paraguay',
            code: 'PY',
            callingCode: 595,
            label: 'Paraguay ‪( + 595)‬'
        },
        {
            name: 'Peru',
            code: 'PE',
            callingCode: 51,
            label: 'Peru ‪( + 51)‬'
        },
        {
            name: 'Philippines',
            code: 'PH',
            callingCode: 63,
            label: 'Philippines ‪( + 63)‬'
        },
        {
            name: 'Pitcairn Islands',
            code: 'PN',
            callingCode: 64,
            label: 'Pitcairn Islands ( + 64)'
        },
        {
            name: 'Poland',
            code: 'PL',
            callingCode: 48,
            label: 'Poland ‪( + 48)‬'
        },
        {
            name: 'Portugal',
            code: 'PT',
            callingCode: 351,
            label: 'Portugal ‪( + 351)‬'
        },
        {
            name: 'Puerto Rico',
            code: 'PR',
            callingCode: 1,
            label: 'Puerto Rico ‪( + 1)‬'
        },
        {
            name: 'Qatar',
            code: 'QA',
            callingCode: 974,
            label: 'Qatar ‪( + 974)‬'
        },
        {
            name: 'Reunion',
            code: 'RE',
            callingCode: 262,
            label: 'Reunion ‪( + 262)‬'
        },
        {
            name: 'Romania',
            code: 'RO',
            callingCode: 40,
            label: 'Romania ‪( + 40)‬'
        },
        {
            name: 'Russia',
            code: 'RU',
            callingCode: 7,
            label: 'Russia ‪( + 7)‬'
        },
        {
            name: 'Rwanda',
            code: 'RW',
            callingCode: 250,
            label: 'Rwanda ‪( + 250)‬'
        },
        {
            name: 'Saint Helena, Ascension and Tristan da Cunha',
            code: 'SH',
            callingCode: 290,
            label: 'Saint Helena, Ascension and Tristan da Cunha ‪( + 290)‬'
        },
        {
            name: 'Saint Kitts and Nevis',
            code: 'KN',
            callingCode: 1,
            label: 'Saint Kitts and Nevis ‪( + 1)‬'
        },
        {
            name: 'Saint Lucia',
            code: 'LC',
            callingCode: 1,
            label: 'Saint Lucia ‪( + 1)‬'
        },
        {
            name: 'Saint Pierre and Miquelon',
            code: 'PM',
            callingCode: 508,
            label: 'Saint Pierre and Miquelon ‪( + 508)‬'
        },
        {
            name: 'Saint Vincent and the Grenadines',
            code: 'VC',
            callingCode: 1,
            label: 'Saint Vincent and the Grenadines ‪( + 1)‬'
        },
        {
            name: 'Samoa',
            code: 'WS',
            callingCode: 1,
            label: 'American Samoa ‪( + 1)‬'
        },
        {
            name: 'Samoa',
            code: 'WS',
            callingCode: 685,
            label: 'Samoa ‪( + 685)‬'
        },
        {
            name: 'San Marino',
            code: 'SM',
            callingCode: 378,
            label: 'San Marino ‪( + 378)‬'
        },
        {
            name: 'Sao Tome and Principe',
            code: 'ST',
            callingCode: 239,
            label: 'Sao Tome and Principe ‪( + 239)‬'
        },
        {
            name: 'Saudi Arabia',
            code: 'SA',
            callingCode: 966,
            label: 'Saudi Arabia ‪( + 966)‬'
        },
        {
            name: 'Senegal',
            code: 'SN',
            callingCode: 221,
            label: 'Senegal ‪( + 221)‬'
        },
        {
            name: 'Serbia',
            code: 'RS',
            callingCode: 381,
            label: 'Serbia ‪( + 381)‬'
        },
        {
            name: 'Seychelles',
            code: 'SC',
            callingCode: 248,
            label: 'Seychelles ‪( + 248)‬'
        },
        {
            name: 'Sierra Leone',
            code: 'SL',
            callingCode: 232,
            label: 'Sierra Leone ‪( + 232)‬'
        },
        {
            name: 'Singapore',
            code: 'SG',
            callingCode: 65,
            label: 'Singapore ‪( + 65)‬'
        },
        {
            name: 'Sint Maarten',
            code: 'SX',
            callingCode: 1721,
            label: 'Sint Maarten ‪( + 1 721)‬'
        },
        {
            name: 'Slovakia',
            code: 'SK',
            callingCode: 421,
            label: 'Slovakia ‪( + 421)‬'
        },
        {
            name: 'Slovenia',
            code: 'SI',
            callingCode: 386,
            label: 'Slovenia ‪( + 386)‬'
        },
        {
            name: 'Solomon Islands',
            code: 'SB',
            callingCode: 677,
            label: 'Solomon Islands ‪( + 677)‬'
        },
        {
            name: 'Somalia',
            code: 'SO',
            callingCode: 252,
            label: 'Somalia ‪( + 252)‬'
        },
        {
            name: 'South Africa',
            code: 'ZA',
            callingCode: 27,
            label: 'South Africa ‪( + 27)‬'
        },
        {
            name: 'South Georgia and the South Sandwich Islands',
            code: 'GS',
            callingCode: 500,
            label: 'South Georgia and the South Sandwich Islands ‪( + 500)‬'
        },
        {
            name: 'South Korea',
            code: 'KR',
            callingCode: 82,
            label: 'South Korea ‪( + 82)‬'
        },
        {
            name: 'Spain',
            code: 'ES',
            callingCode: 34,
            label: 'Spain ‪( + 34)‬'
        },
        {
            name: 'Sri Lanka',
            code: 'LK',
            callingCode: 94,
            label: 'Sri Lanka ‪( + 94)‬'
        },
        {
            name: 'Sudan',
            code: 'SD',
            callingCode: 249,
            label: 'Sudan ‪( + 249)‬'
        },
        {
            name: 'Suriname',
            code: 'SR',
            callingCode: 597,
            label: 'Suriname ‪( + 597)‬'
        },
        {
            name: 'Svalbard and Jan Mayen',
            code: 'SJ',
            callingCode: 47,
            label: 'Svalbard and Jan Mayen ‪( + 47)‬'
        },
        {
            name: 'Swaziland',
            code: 'SZ',
            callingCode: 268,
            label: 'Swaziland ‪( + 268)‬'
        },
        {
            name: 'Sweden',
            code: 'SE',
            callingCode: 46,
            label: 'Sweden ‪( + 46)‬'
        },
        {
            name: 'Switzerland',
            code: 'CH',
            callingCode: 41,
            label: 'Switzerland ‪( + 41)'
        },
        {
            name: 'Syria',
            code: 'SY',
            callingCode: 963,
            label: 'Syria ‪( + 963)‬'
        },
        {
            name: 'Taiwan',
            code: 'TW',
            callingCode: 886,
            label: 'Taiwan ‪( + 886)‬'
        },
        {
            name: 'Tajikistan',
            code: 'TJ',
            callingCode: 992,
            label: 'Tajikistan ‪( + 992)‬'
        },
        {
            name: 'Tanzania',
            code: 'TZ',
            callingCode: 255,
            label: 'Tanzania ‪( + 255)'
        },
        {
            name: 'Thailand',
            code: 'TH',
            callingCode: 66,
            label: 'Thailand ‪( + 66)‬'
        },
        {
            name: 'Timor-Leste',
            code: 'TL',
            callingCode: 670,
            label: 'Timor-Leste ‪( + 670)‬'
        },
        {
            name: 'Togo',
            code: 'TG',
            callingCode: 228,
            label: 'Togo ‪( + 228)‬‬'
        },
        {
            name: 'Tokelau',
            code: 'TK',
            callingCode: 690,
            label: 'Tokelau ‪( + 690)‬‬'
        },
        {
            name: 'Tonga',
            code: 'TO',
            callingCode: 676,
            label: 'Tonga ‪( + 676)‬'
        },
        {
            name: 'Trinidad and Tobago',
            code: 'TT',
            callingCode: 1,
            label: 'Trinidad and Tobago ‪( + 1)‬'
        },
        {
            name: 'Tunisia',
            code: 'TN',
            callingCode: 216,
            label: 'Tunisia ‪( + 216)‬'
        },
        {
            name: 'Turkey',
            code: 'TR',
            callingCode: 90,
            label: 'Turkey ‪( + 90)‬'
        },
        {
            name: 'Turkmenistan',
            code: 'TM',
            callingCode: 993,
            label: 'Turkmenistan ‪( + 993)‬'
        },
        {
            name: 'Turks and Caicos Islands',
            code: 'TC',
            callingCode: 1,
            label: 'Turks and Caicos Islands ‪( + 1)‬'
        },
        {
            name: 'Tuvalu',
            code: 'TV',
            callingCode: 688,
            label: 'Tuvalu ‪( + 688)‬‬'
        },
        {
            name: 'Uganda',
            code: 'UG',
            callingCode: 256,
            label: 'Uganda ‪( + 256)‬'
        },
        {
            name: 'Ukraine',
            code: 'UA',
            callingCode: 380,
            label: 'Ukraine ‪( + 380)'
        },
        {
            name: 'United Arab Emirates',
            code: 'AE',
            callingCode: 971,
            label: 'United Arab Emirates ‪( + 971)‬'
        },
        {
            name: 'United Kingdom',
            code: 'GB',
            callingCode: 44,
            label: 'United Kingdom ‪( + 44)‬'
        },
        {
            name: 'United States Minor Outlying Islands',
            code: 'UM',
            callingCode: 1,
            label: 'United States Minor Outlying Islands ‪( + 1)‬'
        },
        {
            name: 'Uruguay',
            code: 'UY',
            callingCode: 598,
            label: 'Uruguay ‪( + 598)‬'
        },
        {
            name: 'Uzbekistan',
            code: 'UZ',
            callingCode: 998,
            label: 'Uzbekistan ‪( + 998)‬'
        },
        {
            name: 'Vanuatu',
            code: 'VU',
            callingCode: 678,
            label: 'Vanuatu ‪( + 678)‬'
        },
        {
            name: 'Vatican City',
            code: 'VA',
            callingCode: 379,
            label: 'Vatican City ‪( + 379)‬'
        },
        {
            name: 'Venezuela',
            code: 'VE',
            callingCode: 58,
            label: 'Venezuela ‪( + 58)‬'
        },
        {
            name: 'Vietnam',
            code: 'VN',
            callingCode: 84,
            label: 'Vietnam ‪( + 84)‬'
        },
        {
            name: 'Virgin Islands, British',
            code: 'VG',
            callingCode: 1,
            label: 'British Virgin Islands ‪( + 1)‬'
        },
        {
            name: 'Virgin Islands, U.S',
            code: 'VI',
            callingCode: 1,
            label: 'US Virgin Islands ‪( + 1)‬'
        },
        {
            name: 'Wallis and Futuna',
            code: 'WF',
            callingCode: 681,
            label: 'Wallis and Futuna ‪( + 681)‬'
        },
        {
            name: 'Western Sahara',
            code: 'EH',
            callingCode: 212,
            label: 'Western Sahara ‪( + 212)‬'
        },
        {
            name: 'Yemen',
            code: 'YE',
            callingCode: 967,
            label: 'Yemen ‪( + 967)‬'
        },
        {
            name: 'Zambia',
            code: 'ZM',
            callingCode: 260,
            label: 'Zambia ‪( + 260)‬'
        },
        {
            name: 'Zimbabwe',
            code: 'ZW',
            callingCode: 263,
            label: 'Zimbabwe ‪( + 263)‬'
        }
    ]
}

export default locationContants;
