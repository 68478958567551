let angular = window.angular;
hspInfoTrailModalController.$inject = [
  "$rootScope",
  "$window",
  "_",
  "$uibModal",
  "$uibModalInstance",
  "toastService",
  "$q",
  "appConstants",
  "commonService",
  "$stateParams",
  "$state",
  "infoParam",
];

function hspInfoTrailModalController(
  $rootScope,
  $window,
  _,
  $uibModal,
  $uibModalInstance,
  toastService,
  $q,
  appConstants,
  commonService,
  $stateParams,
  $state,
  infoParam
) {
  let vm = this;

  vm.title = "Share Feed";
  vm.data = null;
  vm.remainintTrialDays = 0;

  angular.extend(this, {
    close,
    goPaymentPlans,
    continueFreeTrail,
  });

  function continueFreeTrail() {
    Store.set("trailModal", JSON.stringify(true));
    close();
  }
  function close() {
    $uibModalInstance.close(true);
  }

  function goPaymentPlans() {
    $state.go("paymentPlan");

    close();
  }

  function init() {
    if (infoParam) {
      vm.remainintTrialDays = infoParam.data;
    }
  }

  this.$onInit = init;
}
export default hspInfoTrailModalController;
