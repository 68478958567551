let angular = window.angular;
    userAdminController.$inject = ['userService', '$rootScope', 'commonService', 'toastService', '$state', '$stateParams'];

    function userAdminController(userService, $rootScope, commonService, toastService, $state, $stateParams) {
        let vm = this;
        vm.page = 1;
        vm.isAdmin = false;
        $rootScope.user.adminSidebar = false;
        vm.isSearch = false;
        vm.isFetchingUsersData = true;
        vm.userMetrics = {};
        vm.monthlyReport = {};
        vm.monthlyReportMemo = {};
        vm.isFetchingMonthlyReport = true;
        vm.monthList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        vm.stats = {
            netUser: 0,
            conversionRate: 0,
            churnRate: 0,
            acquisitionCost: 0,
            monthlyNetUser: [],
            monthlyConversionRate: [],
            monthlyChurnRate: [],
            acquisitionCost: [],
            acquisitionRate: []
        };

        vm.currYearUser = 0;
        vm.currYearSubscribers = 0;
        vm.currYearCancellation = 0;
        vm.currYearExpense = 0;
        angular.extend(this, {
            previousPage,
            nextPage,
            verifyUser,
            deleteUser,
            search,
            isTouched,
            resetUsers,
            updateExpense,
            getMonthData
        });

        function isTouched(field) {
            if (angular.isDefined(field)) {
                return field.$touched || vm.form.$submitted;
            } else {
                return false;
            }
        }
        function getMonthData(month) {

            vm.isFetchingMonthlyReport = true;
            if(vm.monthlyReportMemo[month]){
                vm.monthlyReport = vm.monthlyReportMemo[month];
                vm.monthlyReport.month = month;
                vm.isFetchingMonthlyReport = false;
                return;
            }

            const monthNumber = vm.monthList.indexOf(month);
            userService.getDailyMetricsbyMonth(monthNumber)
                .then(response => {
                    if (response) {
                        vm.monthlyReport = response.data;
                        vm.monthlyReport.month = month;
                        vm.monthlyReportMemo[month] = response.data;
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    vm.isFetchingMonthlyReport = false;
                })
        }

        function search(page) {
            if (!page) {
                vm.page = 1;
            } else {
                vm.page = page;
            }
            if (vm.searchData && vm.searchData.trim().length >= 2) {
                $rootScope.loadingBtn('searchBtn', 'Fetching');
                vm.isSearch = true;
                vm.isFetchingUsersData = true;
                userService.search(vm.searchData, vm.page - 1)
                    .then(response => {
                        if (response) {

                            vm.users = response.data.users;
                            vm.loadMore = response.data.isLoadMore;
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
                    .finally(() => {
                        vm.isFetchingUsersData = false;
                        $rootScope.loadingBtnComplete('searchBtn', 'Search');
                    })
            } else {
                toastService.toast({
                    message: 'Please enter atleast 2 characters to find users',
                    type: 'error',
                    delay: 3000
                });
            }

        }

        function deleteUser(user) {

            let deleteUserEmail = "removed_" + user.id + user.email;

            let confirmData = {
                message: 'Are you sure you want to delete this user?',
                type: 'danger',
                modalSize: 'md',
                confirmLabel: 'Yes'
            };

            commonService.confirm(confirmData)
                .then(function(confirmation) {
                    if (confirmation.result) {
                        return userService.deleteUser(user.id, deleteUserEmail)
                            .then(response => {
                                if (response) {

                                    if (angular.isDefined(response.data.isAdmin) && response.data.isAdmin) {
                                        toastService.toast({
                                            message: response.message,
                                            type: 'error',
                                            delay: 2000
                                        });
                                        return;
                                    }
                                    let removeUserIndex = _.findIndex(vm.users, { id: response.data[0].id })
                                    if (removeUserIndex > -1) {
                                        vm.users.splice(removeUserIndex, 1)
                                    }
                                    toastService.toast({
                                        message: response.message,
                                        type: 'success',
                                        delay: 2000
                                    });
                                }
                            })
                            .catch(err => {
                                console.log(err)
                            })
                    } else {
                        return;
                    }
                });

        }

        function verifyUser(user) {
            $rootScope.loadingBtn('verifyLink-' + user.id, 'Verifying');

            return userService.verifyUser(user.id)
                .then(response => {
                    if (response) {
                        let updateUserIndex = _.findIndex(vm.users, { id: response.data[0].id })
                        if (updateUserIndex > -1) {
                            vm.users[updateUserIndex] = response.data[0];
                        }
                        toastService.toast({
                            message: response.message,
                            type: 'success',
                            delay: 2000
                        });
                    }
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    $rootScope.loadingBtnComplete('verifyLink-' + user.id, 'Verify');
                })
        }

        function previousPage() {
            if (vm.page > 1) {
                --vm.page;
                if (vm.isSearch) {
                    search(vm.page);
                } else {
                    getUsers();
                }
            }
        }

        function nextPage() {
            if (vm.loadMore) {
                ++vm.page;
                if (vm.isSearch) {
                    search(vm.page);
                } else {
                    getUsers();
                }
            }
        }

        function resetUsers() {
            vm.searchData = '';
            getUsers();
        }

        function updateExpense() {
            vm.isFetchingUsersData = true;
            userService.updateExpense(vm.userMetrics.totalExpenses)
                .then(response => {

                    vm.userMetrics.totalExpenses = response.data.expense;
                    updateAcquisitionCost(vm.userMetrics);
                    toastService.toast({
                        message: "Successfully changed",
                        type: 'success',
                        delay: 2000
                    });
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    vm.isFetchingUsersData = false;
                })
        }

        function getUsers() {
            vm.isFetchingUsersData = true;
            userService.getAllUsers(vm.page - 1)
                .then(response => {
                    if (response) {
                        vm.users = response.users;
                        vm.loadMore = response.isLoadMore;
                        vm.userMetrics = response.userMetrics;

                        setUserMetricsMetaData(vm.userMetrics);
                    }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    vm.isFetchingUsersData = false;
                    getMonthData("January");
                })
        }

        function setUserMetricsMetaData(userMetrics) {

            let countCurrYearUser = 0;
            let currYearSubscribers = 0;
            let currYearCancellation = 0;
            for (let i=0; i<userMetrics.selectedUsers.length; i++) {
                countCurrYearUser += userMetrics.selectedUsers[i].count;
            }

            for (let i=0; i<userMetrics.selectedSubscribers.length; i++) {
                currYearSubscribers += userMetrics.selectedSubscribers[i].count;
            }

            for (let i=0; i<userMetrics.selectedCancellation.length; i++) {
                currYearCancellation += userMetrics.selectedCancellation[i].count;
            }
            vm.currYearUser = countCurrYearUser;
            vm.currYearSubscribers = currYearSubscribers;
            vm.currYearCancellation = currYearCancellation;

            let monthlyNetUser = [],
                monthlyConversionRate = [],
                monthlyChurnRate = [];
            for (let i=0; i<userMetrics.selectedSubscribers.length; i++) {
                monthlyNetUser.push({
                    monthId: userMetrics.selectedSubscribers[i].monthId,
                    month: userMetrics.selectedSubscribers[i].month,
                    count: userMetrics.selectedSubscribers[i].count - userMetrics.selectedCancellation[i].count
                });

                monthlyConversionRate.push({
                    monthId: userMetrics.selectedSubscribers[i].monthId,
                    month: userMetrics.selectedSubscribers[i].month,
                    count: userMetrics.selectedUsers[i].count == 0 ? '0.00%' : ((userMetrics.selectedSubscribers[i].count/userMetrics.selectedUsers[i].count)*100).toFixed(2) + '%'
                });

                monthlyChurnRate.push({
                    monthId: userMetrics.selectedSubscribers[i].monthId,
                    month: userMetrics.selectedSubscribers[i].month,
                    count: userMetrics.selectedSubscribers[i].count == 0 ? '0.00%' : ((userMetrics.selectedCancellation[i].count/userMetrics.selectedSubscribers[i].count)*100).toFixed(2) + '%'
                });
            }
            vm.stats.monthlyNetUser = monthlyNetUser;
            vm.stats.monthlyConversionRate = monthlyConversionRate;
            vm.stats.monthlyChurnRate = monthlyChurnRate;

            vm.stats.netUser = vm.currYearSubscribers - vm.currYearCancellation;
            vm.stats.conversionRate = ((vm.currYearSubscribers/vm.currYearUser)*100).toFixed(2) + '%';
            vm.stats.churnRate = ((vm.currYearCancellation/vm.currYearSubscribers)*100).toFixed(2) + '%';

            updateAcquisitionCost(userMetrics);
        }

        function updateAcquisitionCost(userMetrics) {
            let currYearExpense = 0,
                acquisitionCost = [];


            for (let i=0; i<userMetrics.totalExpenses.length; i++) {
                currYearExpense += parseInt(userMetrics.totalExpenses[i].count);

                acquisitionCost.push({
                    monthId: userMetrics.totalExpenses[i].monthId,
                    month: userMetrics.totalExpenses[i].month,
                    count: userMetrics.selectedUsers[i].count == 0 ? '0.00$' : (userMetrics.totalExpenses[i].count/userMetrics.selectedUsers[i].count).toFixed(2) + '$'
                });
            }

            vm.stats.acquisitionCost = acquisitionCost;
            vm.currYearExpense = currYearExpense;
            vm.stats.acquisitionRate = ((vm.currYearExpense/vm.currYearUser)).toFixed(2) + '$';
        }

        function verifyUserAdmin() {

            userService.verifyAdmin($stateParams.userId)
                .then(response => {
                    if (response) {
                        vm.isAdmin = response.isAdmin;
                        $rootScope.user.adminSidebar = true;
                    }
                    if (vm.isAdmin) {
                        getUsers();
                    }
                })
                .catch(error => {
                    // console.log(error)
                    $state.go('404');
                })
        }

        this.$onInit = verifyUserAdmin;

    }
export default userAdminController;
