// (function () {
//     'use strict';

//     angular
//         .module ('common')
//         .directive ('hspYoutubeList', hspYoutubeList)
//         .controller('HspYoutubeListController', HspYoutubeListController);

export function hspYoutubeList() {
  // Usage:
  //     <directive></directive>
  // Creates:
  //
  let directive = {
    restrict: "E",

    scope: {
      textData: "=",
    },
    template: require("../../pug/components/youtube-embedded-list.pug").default,
    controller: "HspYoutubeListController",
  };
  return directive;
}

export function HspYoutubeListController($scope) {
  let youtubeRegex =
    /(?:https?:\/\/)?(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/v\/|\/watch\?v=|\/ytscreeningroom\?v=|\/feeds\/api\/videos\/|\/user\S*[^\w\-\s]|\S*[^\w\-\s]))([\w\-]{11})[?=&+%\w-]*/g;
  let regexMatchs = $scope.textData
    ? $scope.textData.match(youtubeRegex)
    : null;
  $scope.youtubeLinks =
    regexMatchs && regexMatchs.toString()
      ? regexMatchs
          .toString()
          .replace(/[\[\]]/g, "")
          .split(",")
      : [];

  $scope.embedOptions = {
    sanitizeHtml: false, // convert html to text
    fontSmiley: false, // convert ascii smileys into font smileys
    emoji: false, // convert emojis short names into images
    link: false, // convert links into anchor tags
    linkTarget: "_blank", //_blank|_self|_parent|_top|framename

    pdf: {
      embed: false, // show pdf viewer.
    },

    image: {
      embed: false, // toggle embedding image after link, supports gif|jpg|jpeg|tiff|png|svg|webp.
    },

    audio: {
      embed: false, // toggle embedding audio player, supports wav|mp3|ogg
    },

    basicVideo: true, // embed video player, supports ogv|webm|mp4
    gdevAuth: "AIzaSyDJ5EP7Cbq5ZvIqqebl3SlkLjg0XH5a24w", // Google developer auth key for YouTube data api
    video: {
      embed: true, // embed YouTube/Vimeo videos
      width: "300px", // width of embedded player
      height: "225px", // height of embedded player
      ytTheme: "dark", // YouTube player theme (light/dark)
      details: true, // display video details (like title, description etc.)
      thumbnailQuality: "low", // quality of the thumbnail low|medium|high
      autoPlay: true, // autoplay embedded videos
    },
    twitchtvEmbed: false,
    dailymotionEmbed: false,
    tedEmbed: false,
    dotsubEmbed: false,
    liveleakEmbed: false,
    ustreamEmbed: false,
    soundCloudEmbed: false,
    soundCloudOptions: {
      height: 160,
      themeColor: "f50000",
      autoPlay: false,
      hideRelated: false,
      showComments: true,
      showUser: true,
      showReposts: false,
      visual: false, // Show/hide the big preview image
      download: false, // Show/Hide download buttons
    },
    spotifyEmbed: false,

    tweetEmbed: false, // toggle embedding tweets
    tweetOptions: {
      // The maximum width of a rendered Tweet in whole pixels. Must be between 220 and 550 inclusive.
      maxWidth: 550,
      // Toggle expanding links in Tweets to photo, video, or link previews.
      hideMedia: false,
      // When set to true or 1 a collapsed version of the previous Tweet in a conversation thread
      // will not be displayed when the requested Tweet is in reply to another Tweet.
      hideThread: false,
      // Specifies whether the embedded Tweet should be floated left, right, or center in
      // the page relative to the parent element.Valid values are left, right, center, and none.
      // Defaults to none, meaning no alignment styles are specified for the Tweet.
      align: "none",
      // Request returned HTML and a rendered Tweet in the specified.
      // Supported Languages listed here (https://dev.twitter.com/web/overview/languages)
      lang: "en",
    },

    code: {
      highlight: false, // highlight code written in 100+ languages supported by highlight
      // requires highlight.js (https://highlightjs.org/) as dependency.
      lineNumbers: false, // display line numbers
    },
    codepenEmbed: false,
    codepenHeight: 300,
    jsfiddleEmbed: false,
    jsfiddleHeight: 300,
    jsbinEmbed: false,
    jsbinHeight: 300,
    plunkerEmbed: false,
    githubgistEmbed: false,
    ideoneEmbed: false,
    ideoneHeight: 300,
  };
}

HspYoutubeListController.$inject = ["$scope"];
