import { chatConstants } from "./chatConstants";
import chatCreateController from "./chatCreateController";
import chatViewController from "./chatViewController";
import collaborateController from "./collaborateController";
import collaborateService from "./collaborateService";
import collaborateSearchController from "./collaborateSearchController";
import EditGroupChatNameController from "./editGroupChatNameController";
import giphyService from "./giphyService";
import groupInfoController from "./groupInfoController";
import ReportModalController from "./reportModalController";

let angular = window.angular;

export const COLLABORATE_MODULE = angular.module("collaborate", []);

COLLABORATE_MODULE.service("collaborateService", collaborateService);
COLLABORATE_MODULE.constant("chatConstants", chatConstants);
COLLABORATE_MODULE.controller("chatCreateController", chatCreateController);
COLLABORATE_MODULE.controller("chatViewController", chatViewController);
COLLABORATE_MODULE.controller("collaborateController", collaborateController);
COLLABORATE_MODULE.controller(
  "collaborateSearchController",
  collaborateSearchController
);
COLLABORATE_MODULE.controller(
  "EditGroupChatNameController",
  EditGroupChatNameController
);
COLLABORATE_MODULE.factory("giphyService", giphyService);
COLLABORATE_MODULE.controller("groupInfoController", groupInfoController);
COLLABORATE_MODULE.controller("ReportModalController", ReportModalController);
