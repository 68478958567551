const angular = window.angular;
chatViewController.$inject = [
  "$rootScope",
  "$window",
  "$timeout",
  "$state",
  "$stateParams",
  "collaborateService",
  "commonService",
  "friendService",
  "chatConstants",
  "_",
  "moment",
  "$scope",
  "$uibModal",
  "toastService",
  "giphyService",
  "$q",
  "$document",
  "feedService",
];

function chatViewController(
  $rootScope,
  $window,
  $timeout,
  $state,
  $stateParams,
  collaborateService,
  commonService,
  friendService,
  chatConstants,
  _,
  moment,
  $scope,
  $uibModal,
  toastService,
  giphyService,
  $q,
  $document,
  feedService
) {
  let vm = this;
  let typeTimer,
    typingInterval = 1000,
    typingData;
  vm.gluedScroll = true;
  vm.chat = {};
  vm.canLoad = true;
  vm.messageBody = "";
  vm.gifs = [];

  vm.userLimit = chatConstants.userLimit;
  vm.ngEmbeddedOption = chatConstants.ngEmbeddedOption;
  vm.filesToUpload = [];
  let chatIndex = -1;

  let queuedMessages = [];

  vm.unreadMessageBubble = false;
  vm.hasNewMessage = false;

  vm.showUserDetail = false;
  vm.replyMessageId = null;
  angular.extend(this, {
    sendMessage,
    onRetry,
    loadMore,
    getUserInfo,
    loadMoreUsers,
    filesAdded,
    fileUploaded,
    removeFile,
    typingKeyUp,
    typingKeyDown,
    typingBlur,
    blockUser,
    unblockUser,
    openReportUserDialog,
    deleteMessage,
    removeDocMessage,
    changeGroupName,
    gotoChatBottom,
    showDetail,
    stopClick,
    deleteChat,
    unfriendUser,
    viewGroupInfo,
    exitGroupClick,
    checkMessage,
    replyMessage,
    unfollowUser,
    followUser,
  });

  function stopClick($event) {
    $event.stopPropagation();
    $event.preventDefault();
  }

  function checkMessage(message) {
    let userChatIndex = vm.chat.userchats.findIndex(
      (obj) => obj.userId == $rootScope.user.id
    );
    if (userChatIndex !== -1) {
      let userChat = vm.chat.userchats[userChatIndex];
      if (userChat.oldMessagesView) {
        let messageDate = moment(message.createdAt);
        let userChatJoinedDate = moment(userChat.createdAt);
        return !messageDate.isSameOrBefore(userChatJoinedDate);
      } else {
        return true;
      }
    }
    return true;
  }

  function exitGroupClick() {
    let confirmData = {
      message:
        "Please select another member to be an admin of this group before leaving.",
      type: "danger",
      modalSize: "md",
      confirmLabel: "Yes",
    };

    let checkRole = "ADMIN";

    let userRoleIndex = vm.chat.userchats.findIndex(
      (obj) => obj.userId == $rootScope.user.id
    );
    if (userRoleIndex !== -1) {
      let userRole = vm.chat.userchats[userRoleIndex];
      checkRole = userRole.role;
    }

    if (checkRole !== "USER") {
      let userChats = vm.chat.userchats.filter((obj) => obj.role == "ADMIN");
      if (userChats.length <= 1) {
        commonService.confirm(confirmData).then(function (confirmation) {
          if (confirmation.result) {
            let modalInstance = $uibModal.open({
              animation: true,
              size: "md",
              controller: "groupInfoController",
              controllerAs: "groupInfoCtrl",
              template: require("../../pug/collaborate/group-info.pug").default,
              resolve: {
                chat: vm.chat,
              },
            });
            modalInstance.result
              .then(
                () => {
                  removeAdmin();
                },
                () => {
                  removeAdmin();
                }
              )
              .catch(function () {
                modelInstance.close();
              });
          }
        });
        return;
      }
    }

    let memberId = vm.chat.userchats.findIndex(
      (obj) => obj.userId == $rootScope.user.id
    );
    if (memberId != -1) {
      memberId = vm.chat.userchats[memberId].id;
      let confirmExitData = {
        message: "Are you sure you want to exit group?",
        type: "danger",
        modalSize: "md",
        confirmLabel: "Yes",
      };
      commonService.confirm(confirmExitData).then(function (confirmation) {
        if (confirmation.result) {
          collaborateService.exitUser(memberId).then((data) => {
            if (data) {
              if (collaborateService.chatsList[0].id) {
                collaborateService.sideMenuChatId =
                  collaborateService.chatsList[0].id;
                $state.go("collaborateViewChat", {
                  chatId: collaborateService.chatsList[0].id,
                });
              } else {
                $state.go("collaborateCreate");
              }
            }
          });
        }
      });
    }
  }

  function removeAdmin() {
    let adminCount = vm.chat.userchats.filter((obj) => obj.role == "ADMIN");
    if (adminCount.length > 1) {
      let memberId = vm.chat.userchats.findIndex(
        (obj) => obj.userId == $rootScope.user.id
      );
      if (memberId != -1) {
        memberId = vm.chat.userchats[memberId].id;
        collaborateService.exitUser(memberId).then((data) => {
          if (data) {
            if (collaborateService.chatsList[0].id) {
              collaborateService.sideMenuChatId =
                collaborateService.chatsList[0].id;
              $state.go("collaborateViewChat", {
                chatId: collaborateService.chatsList[0].id,
              });
            } else {
              $state.go("collaborateCreate");
            }
            // toastService.toast({message: "", type: 'success', delay: 2000});
          }
        });
      }
    }
  }

  function blockUser() {
    return friendService
      .blockUser(vm.chat.friend)
      .then(getBlockSuccessFunction("Chat blocked successfully"))
      .catch(getBlockFailureFunction("Chat not blocked"));
  }

  function unblockUser() {
    return friendService
      .unblockUser(vm.chat.friend)
      .then(getBlockSuccessFunction("Chat unblocked successfully"))
      .catch(getBlockFailureFunction("Chat not unblocked"));
  }

  function openReportUserDialog() {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "md",
      controller: "ReportModalController",
      controllerAs: "reportModalCtrl",
      template: require("../../pug/collaborate/report-user-modal.pug").default,
    });
    modelInstance.result.then(reportUser, angular.noop).catch(function () {
      modelInstance.close();
    });
  }

  function reportUser(reasonReport) {
    return friendService
      .reportUser(vm.chat.friend, reasonReport)
      .then(getBlockSuccessFunction("User reported successfully"))
      .then(getBlockFailureFunction("User not reported"));
  }

  function getBlockSuccessFunction(successMessage) {
    return function (response) {
      let data = response.data.data;
      if (angular.isDefined(data.friend)) {
        vm.chat.isBlocked = data.friend.isBlocked;
        vm.chat.blockedBy = data.friend.blockedBy;
        collaborateService.orderChat();
      }
      toastService.toast({
        message: successMessage,
        type: "success",
        delay: 2000,
      });
    };
  }

  function getBlockFailureFunction(failureMessage) {
    failureMessage = angular.isDefined(failureMessage)
      ? failureMessage
      : "Some Internal Server Error";
    return function (error) {
      message = error.data.message ? error.data.message : failureMessage;
      toastService.toast({ message: message, type: "error", delay: 2000 });
    };
  }

  function typingKeyUp() {
    $timeout.cancel(typeTimer);
    typeTimer = $timeout(function () {
      typingData = {
        chatId: vm.chat.id,
        typingStatus: false,
      };

      collaborateService.typing(typingData);
      vm.chat.selfTyping = false;
    }, typingInterval);
  }

  function typingKeyDown() {
    typingData = {
      chatId: vm.chat.id,
      typingStatus: true,
    };
    $timeout.cancel(typeTimer);

    if (!vm.chat.selfTyping) {
      collaborateService.typing(typingData);
      vm.chat.selfTyping = true;
    }
  }

  function typingBlur() {
    $timeout.cancel(typeTimer);
    typingData = {
      chatId: vm.chat.id,
      typingStatus: false,
    };
    vm.chat.selfTyping = false;
    collaborateService.typing(typingData);
  }

  function loadMoreUsers() {
    vm.userLimit = vm.chat.users.length;
  }

  function getUserInfo(userId, users) {
    if (!userId || !users || !users.length) {
      return {};
    }

    if (userId === $rootScope.user.id) {
      return $rootScope.user;
    }

    let index = commonService.findInArray(userId, "id", users);
    if (~index) {
      return users[index];
    }

    return {};
  }

  function addToQueue(message) {
    let messageObject = _.clone(message);
    messageObject.createdAt = moment
      .unix($window.Date.now() / 1000)
      .toISOString();
    messageObject.isQueued = true;
    queuedMessages.push(messageObject);

    if (
      vm.chat.messages.length &&
      vm.chat.messages[vm.chat.messages.length - 1].type === "typing"
    ) {
      vm.chat.messages.pop();
    }

    vm.chat.messages.push(messageObject);
    if (messageObject.type === "media") {
      $timeout(function () {
        $rootScope.$broadcast("upload-multiple-files", vm.chat.id, "chat");
      }, 200);
    }
  }

  function onMessageErrorCallback(localId) {
    let index = commonService.findInArray(
      localId,
      "local_id",
      vm.chat.messages
    );
    if (~index) {
      vm.chat.messages[index].allowRetry = true;
    }
  }

  function scrollMessageBoxToBottom() {
    const element = document.getElementById("chatbox-messages");
    element.scrollTop = element.scrollHeight + 100;
  }

  function onMessageSuccessCallback(message) {
    scrollMessageBoxToBottom();
    for (let i = queuedMessages.length - 1; ~i; i--) {
      if (queuedMessages[i].localId === message.localId) {
        queuedMessages.splice(i, 1);
      }
    }

    let index = _.findIndex(vm.chat.messages, { localId: message.localId });
    if (~index && vm.chat.messages[index].isQueued) {
      vm.chat.messages[index].id = message.id;
      vm.chat.messages[index].createdAt = message.createdAt;
      vm.chat.messages[index].metaData = message.metaData;
      vm.chat.messages[index].type = message.type;

      if (message.type == "media") {
        message = collaborateService.convertForMediaMessage(message);
        vm.chat.messages[index].file = message.file;
      }

      delete vm.chat.messages[index].isQueued;
    }
  }

  function onMessageCallback(message) {
    collaborateService.createMessage(message).then(
      (res) => {
        // console.log('on message callback');
        // console.log(message);
        onMessageSuccessCallback(res.data.Message);
      },
      (err) => {
        // console.log('on error message callback');
        // console.log(err);
        onMessageErrorCallback(message.localId);
      }
    );
  }

  function deleteMessage(messageId) {
    collaborateService.deleteMessage(messageId, vm.chat.id);
  }

  function replyMessage(messageId) {
    vm.replyMessageId = messageId;
  }

  function sendMessage(messageBody) {
    if (vm.chat.isBlocked) {
      return;
    }

    if (!messageBody) {
      return false;
    }

    let message = {
      type: "text",
      messageBody: messageBody,
      userId:
        $rootScope.user.userRole === "HELPER"
          ? $rootScope.user.helperId
          : $rootScope.user.id,
      chatId: vm.chat.id,
      friendId: vm.chat.friend,
      localId: _.uniqueId("message_"),
    };
    if (vm.replyMessageId) {
      message.replyId = vm.replyMessageId;
      vm.replyMessageId = null;
    }

    messageBody = null;
    vm.messageBody = null;

    addToQueue(message);
    onMessageCallback(message);
  }

  function sendDocMessage(file) {
    if (vm.chat.isBlocked) {
      return;
    }

    if (!file) {
      return;
    }

    let fileType = getFileType(file.type);
    let message = {
      type: "media",
      messageBody: commonService.renderTemplateString(
        chatConstants.messages.uploadMediaFile,
        { fileType }
      ),
      userId: $rootScope.user.id,
      chatId: vm.chat.id,
      localId: _.uniqueId("message_"),
      file: file,
    };

    addToQueue(message);
  }

  function getFileType(type) {
    let fileType;

    if (type.includes("pdf")) {
      fileType = "PDF file";
    } else if (type.includes("xls")) {
      fileType = "Excel file";
    } else if (type.includes("image")) {
      fileType = "Image file";
    } else if (type.includes("video")) {
      fileType = "Video file";
    } else {
      fileType = "file";
    }

    return fileType;
  }

  function filesAdded(files) {
    _.each(files, function (file) {
      sendDocMessage(file);
    });
  }

  function removeFile(fileState, index) {
    vm.chat.messages.splice(index, 1);
  }

  function removeDocMessage(fileState, index) {
    if (
      angular.isDefined(vm.chat.messages[index]) &&
      angular.isDefined(vm.chat.messages[index].id)
    ) {
      deleteMessage(vm.chat.messages[index].id);
    }
  }

  function fileUploaded(fileState, index, file) {
    let mediaMessage = angular.copy(vm.chat.messages[index]);
    let media = angular.copy(file);

    mediaMessage.metaData = {
      mediaId: media.id,
      mediaUrl: media.mediaUrl,
      mediaName: media.name,
      mediaHash: media.hash,
      mediaMeta: media.meta,
      thumbUrl: media.thumbUrl,
    };

    vm.chat.messages[index].file = media;

    let messageCallbackData = _.clone(mediaMessage);
    delete messageCallbackData.file;

    if (angular.isDefined(messageCallbackData)) {
      onMessageCallback(messageCallbackData);
    }
  }

  function onRetry(localId) {
    let queueIndex = collaborateService.findMessageByLocalId(
      localId,
      queuedMessages
    );
    onMessageCallback(queuedMessages[queueIndex]);
    let index = collaborateService.findMessageByLocalId(
      localId,
      vm.chat.messages
    );
    vm.chat.messages[index].allowRetry = false;
  }

  function loadMore() {
    if (
      vm.chat.messages.length >= collaborateService.chatMessageLimit &&
      vm.canLoad
    ) {
      vm.canLoad = false;
      vm.loading = true;
      const loadMorePromise = collaborateService.getMessages(
        vm.chat.type,
        vm.chat.id,
        collaborateService.chatMessageLimit,
        vm.chat.messages.length
      );
      loadMorePromise
        .then((res) => {
          vm.canLoad = true;
          vm.lastMessageId = vm.chat.messages[0].id;
          if (res.firstMessage) {
            vm.canLoad = false;
          }
        })
        .finally(() => {
          vm.loading = false;
        });
      return loadMorePromise;
    }
  }

  function onRefreshCallBack() {
    collaborateService.setChatSeen(vm.chat.id);
  }

  function onFocusCallBack() {
    if (!document.hidden) {
      $timeout(() => {
        let index = collaborateService.findChatById($stateParams.chatId);

        if (~index) {
          collaborateService.removeUnreadCount(index, "chat");
        }
      }, 2000);
    }
  }

  function changeGroupName() {
    if (vm.chat.type !== "group") {
      return;
    }

    let modalInstance = $uibModal.open({
      animation: true,
      size: "md",
      controller: "EditGroupChatNameController",
      controllerAs: "editChatNameCtrl",
      template: require("../../pug/collaborate/edit-chat-name.pug").default,

      resolve: {
        chat: vm.chat,
      },
    });
  }

  function viewGroupInfo() {
    if (vm.chat.type !== "group") {
      return;
    }

    let modalInstance = $uibModal.open({
      animation: true,
      size: "md",
      controller: "groupInfoController",
      controllerAs: "groupInfoCtrl",
      template: require("../../pug/collaborate/group-info.pug").default,

      resolve: {
        chat: vm.chat,
      },
    });
    modalInstance.result
      .then(
        (value) => {
          if (value) {
            vm.chat.userchats = _.clone(value.userchats);
            vm.chat.users = _.clone(value.users);
          }
        },
        () => {}
      )
      .catch(function () {
        modelInstance.close();
      });
  }

  function getChat() {
    let getchat = collaborateService.viewChat("chat", $stateParams.chatId);
    getchat
      .then((index) => {
        chatIndex = index;
        vm.chat = collaborateService.chatsList[index];
        collaborateService.currentChatId = vm.chat.id;
        vm.chat.messages = _.map(
          vm.chat.messages,
          collaborateService.convertForMediaMessage
        );
        if (vm.chat.messages[0]) {
          vm.lastMessageId = vm.chat.messages[0].id;
        }
        collaborateService.removeUnreadCount(index, "chat");
        setTimeout(() => {
          scrollMessageBoxToBottom();
        }, 100);
      })
      .catch((err) => {
        if (collaborateService.chatsList[0].id) {
          collaborateService.sideMenuChatId =
            collaborateService.chatsList[0].id;
          $state.go("collaborateViewChat", {
            chatId: collaborateService.chatsList[0].id,
          });
        } else {
          $state.go("collaborateCreate");
        }
      });
  }

  function showDetail(value) {
    vm.showUserDetail = value;
  }

  function getTrendingGifs() {
    return giphyService.trending().then(getGifsSuccess).catch(getGifsFailure);
  }

  function getGifsSuccess(response) {
    // vm.gifs = [];
    // vm.gifs = response;
    // console.log('vm.gifs', vm.gifs);
  }

  function getGifsFailure(error) {
    console.error(error);
  }

  function initialize() {
    // getTrendingGifs();

    setTimeout(() => {
      const element = document.getElementsByClassName("emoji-wysiwyg-editor");
      element[0].removeAttribute("placeholder");
    }, 0);

    window.addEventListener("beforeunload", onRefreshCallBack);
    document.addEventListener("visibilitychange", onFocusCallBack);
    collaborateService.ifUserHasChat()
      ? getChat()
      : $rootScope.chatIndexPromise.then(getChat);
  }

  // $(window).bind("beforeunload", function(event) {
  // 	alert('HI');
  // 	event.preventDefault();
  // });

  this.$onInit = initialize;

  $window.addEventListener("beforeunload", function (e) {
    typingData = {
      chatId: vm.chat.id,
      typingStatus: false,
    };

    // Promise to be on $window object
    $window.beforeunloadPromise = $q.all([
      collaborateService.typing(typingData),
      collaborateService.setChatSeen(vm.chat.id),
    ]);
    return $window.beforeunloadPromise;
  });

  this.$onDestroy = function (event) {
    window.removeEventListener("beforeunload", onRefreshCallBack);
    document.removeEventListener("visibilitychange", onFocusCallBack);
    collaborateService.setChatSeen(vm.chat.id);
  };

  $scope.$on("message:scroll", function () {
    // console.log("message:scroll")
    newMessageIconUpdate();
  });
  $scope.$on("group:remove", function () {
    if (collaborateService.chatsList.length) {
      collaborateService.sideMenuChatId = collaborateService.chatsList[0].id;
      $state.go("collaborateViewChat", {
        chatId: collaborateService.chatsList[0].id,
      });
    }
  });

  function gotoChatBottom() {
    let chatBox = angular.element(document.getElementById("chatbox-messages"));
    chatBox.animate(
      {
        scrollTop: chatBox[0].scrollHeight - chatBox[0].offsetHeight,
      },
      300
    );
    vm.unreadMessageBubble = false;
  }

  angular
    .element(document.getElementById("chatbox-messages"))
    .bind("scroll", function () {
      if (vm.hasNewMessage) {
        newMessageIconUpdate();
      }
    });

  function newMessageIconUpdate() {
    let chatBox = angular.element(
      document.getElementById("chatbox-messages")
    )[0];
    let chatBoxTotalHeight = chatBox.scrollHeight;
    let chatBoxScrollHeight = chatBox.scrollTop + chatBox.offsetHeight + 200;
    let isScrollRequired = false;
    let hasNewMessage = false;

    if (chatBoxTotalHeight > chatBoxScrollHeight) {
      isScrollRequired = true;
      hasNewMessage = true;
    }

    vm.unreadMessageBubble = isScrollRequired;
    vm.hasNewMessage = hasNewMessage;
  }

  function deleteChat(chat) {
    let confirmData = {
      message: "Are you sure you want to delete the conversation ?",
      type: "danger",
      modalSize: "md",
      confirmLabel: "Yes",
    };
    if (!chat) {
      return;
    }
    if (!$stateParams.chatId) {
      return;
    }

    commonService.confirm(confirmData).then(function (confirmation) {
      if (confirmation.result) {
        return collaborateService
          .deleteChat($stateParams.chatId, chat.friend)
          .then((data) => {
            if (data.chatId) {
              collaborateService.deleteChatConversation(data.chatId);
              if (collaborateService.ifUserHasChat()) {
                if (collaborateService.chatsList[0].id) {
                  collaborateService.sideMenuChatId =
                    collaborateService.chatsList[0].id;
                  $rootScope.$broadcast(
                    "deleteEvent",
                    collaborateService.chatsList[0].id
                  );
                  $state.go("collaborateViewChat", {
                    chatId: collaborateService.chatsList[0].id,
                  });
                } else {
                  $state.go("collaborateCreate");
                }
              } else {
                $state.go("collaborateCreate");
              }
            }
          });
      }
    });
  }

  function unfriendUser(chat) {
    let confirmData = {
      message: "Are you sure you want to unfriend this user ?",
      type: "danger",
      modalSize: "md",
      confirmLabel: "Yes",
    };
    if (!chat) {
      return;
    }
    if (!$stateParams.chatId) {
      return;
    }
    commonService.confirm(confirmData).then(function (confirmation) {
      if (confirmation.result) {
        return collaborateService
          .unfriendUser({
            friend: {
              chatId: $stateParams.chatId,
              friendId: chat.friend,
            },
          })
          .then((data) => {
            if (data.id) {
              collaborateService.unfriendUserConversation(data.id);
              if (collaborateService.ifUserHasChat()) {
                if (collaborateService.chatsList[0].id) {
                  collaborateService.sideMenuChatId =
                    collaborateService.chatsList[0].id;
                  $state.go("collaborateViewChat", {
                    chatId: collaborateService.chatsList[0].id,
                  });
                } else {
                  $state.go("collaborateCreate");
                }
              } else {
                $state.go("collaborateCreate");
              }
            }
          });
      }
    });
  }

  function unfollowUser(chat) {
    let confirmData = {
      message: "Are you sure you want to unfollow this user ?",
      type: "danger",
      modalSize: "md",
      confirmLabel: "Yes",
    };
    if (!chat) {
      return;
    }
    if (!$stateParams.chatId) {
      return;
    }
    commonService.confirm(confirmData).then(function (confirmation) {
      if (confirmation.result) {
        let getUserId = _.find(
          chat.userchats,
          (obj) => obj.userId !== $rootScope.user.id
        );
        let userFollowId = 0;
        if (getUserId) {
          userFollowId = getUserId.userId;
        }
        return feedService
          .conversationUserUnFollow(userFollowId)
          .then((data) => {
            if (data.id) {
              collaborateService.followUserFromConversation(chat.id, "U");
            }
          });
      }
    });
  }
  function followUser(chat) {
    if (!chat) {
      return;
    }
    if (!$stateParams.chatId) {
      return;
    }
    let getUserId = _.find(
      chat.userchats,
      (obj) => obj.userId !== $rootScope.user.id
    );
    let userFollowId = 0;
    if (getUserId) {
      userFollowId = getUserId.userId;
    }
    return feedService.conversationUserFollow(userFollowId).then((data) => {
      if (data.id) {
        collaborateService.followUserFromConversation(chat.id, "R");
      }
    });
  }
}

export default chatViewController;
