
	passwordVerifyController.$inject = ['$rootScope', '$uibModalInstance', 'userService', 'toastService','userConstants'];
	function passwordVerifyController($rootScope, $uibModalInstance, userService, toastService,userConstants) {
		let vm = this;

		vm.password = null;
		vm.showError = "";
		vm.wrongPassword = "";

		vm.close = function (data) {
			$uibModalInstance.close(data);
		};

		vm.passwordVerify = function () {

			let data = {};
			data.password = vm.password;

			userService.verifyPassword(data)
				.then((response) => {
					//console.log(response)
					// toastService.toast({
					// 	message: userConstants.messages.changePasswordSuccess,
					// 	type: 'success',
					// 	delay: 5000
					// });
					vm.close(response);
				})
				.catch((response) => {
					console.log('error in js', response)
					if (response.message === 'Incorrect Password') {
						vm.showError = 'Incorrect Password';
						vm.wrongPassword = vm.password;
					}
					// console.log(response);
				});
		}

	}
export default passwordVerifyController;
