let angular = window.angular;
	userProfileInfoController.$inject = ['userService','userConstants', '_', '$rootScope', '$scope', 'commonService', '$state', 'Store', 'toastService', 'fileService', '$uibModal', 'locationConstants', '$uibModalInstance'];

	function userProfileInfoController(userService,userConstants, _, $rootScope, $scope, commonService, $state, Store, toastService, fileService, $uibModal, locationConstants, $uibModalInstance) {

		let vm = this;
		vm.user = _.clone($rootScope.user);
		vm.countries = locationConstants.countries;
		vm.states = [];
		vm.cities = [];
		vm.location = {};
		vm.errorCountry = false;
		vm.errorZip = false;
		vm.errorState = false;
		vm.errorCity = false;
		vm.isFetchingStates = false;
		vm.isFetchingCities = false;

		let countries = _.map(vm.countries, function(country) {
			return country.name;
		});

		angular.extend(this, {
			editProfile,
			getStates,
			getCities,
			checkLocation,
			checkLocationOnBlur,
			checkAlphaNumeric,
			locationSelected,
			countryChanged,
			isTouched,
			close
		});

		if (vm.user.countryCode === 0) {
			vm.user.countryCode = null;
		}

		function isTouched(field) {
			return  vm.form.$submitted;
		}


		function countryChanged(type, value) {
			if (type === 'code') {
				let country = _.find(vm.countries, { 'callingCode': value });
				if (country && country.name) {
					vm.user.country = country.name;
					countryNameChanged(country.name);
				}
			}

			if (type === 'name') {
				let country = _.find(vm.countries, { 'name': value });
				if (country && country.callingCode) {
					vm.user.countryCode = country.callingCode;
					countryNameChanged(country.name);
				}
			}
		}

		function locationSelected(country, state, city, zip) {
			vm.location.state = state;
			vm.location.city = city;

			if (zip) {
				vm.user.zipCode = zip;
			}

			vm.user.country = (country) ? country : '';

			if (country) {
				vm.user.state = null;
				vm.user.city = null;
				getStates(country)
					.then((userState) => {
						if (userState) {
							return getCities(country, userState);
						}
					});
			}
		}

		function getStates(country, action) {
			if (!country) {
				return;
			}

			vm.isFetchingStates = true;
			if (action !== "init") {
				vm.states = null;
				vm.cities = null;
				vm.errorCountry = false;
				vm.form.country.$valid = true;
			}

			return userService.getStateList(country)
				.then((response) => {
					vm.states = response;
					if (vm.location.state) {
						let stateIndex = _.findIndex(vm.states, (state) => {
							return state.includes(vm.location.state);
						});
						vm.user.state = (stateIndex >= 0) ? vm.states[stateIndex] : '';
					}
					return vm.user.state;
				})
				.catch((error) => {
					console.error("err", error);
				})
				.finally(() => {
					vm.isFetchingStates = false;
				});
		}

		function countryNameChanged(name) {
			vm.user.state = '';
			vm.user.city = '';
			return getStates(name || vm.user.country);
		}

		function stateNameChanged() {
			vm.user.city = '';
		}

		function checkLocation(location, type) {
			// console.log("check " + type + " on select");
			if (!location) {
				return;
			}
			switch (type) {
				case "country":
					for (let value of countries) {
						if (value.toLowerCase().search(location.toLowerCase()) >= 0) {
							vm.errorCountry = false;
							break;
						}
						vm.errorCountry = true;

					}
					break;

				case "state":
					stateNameChanged();
					for (let value of vm.states) {
						if (value.toLowerCase().search(location.toLowerCase()) >= 0) {
							vm.errorState = false;
							break;
						}
						vm.errorState = true;
					}
					break;

				case "city":
					for (let value of vm.cities) {
						if (value.toLowerCase().search(location.toLowerCase()) >= 0) {
							vm.errorCity = false;
							break;
						}
						vm.errorCity = true;
					}
					break;
			}
		}

		function checkAlphaNumeric() {
			if (!vm.user.zipCode) {
				vm.errorZip = true;
				return;
			}
			let pattern = /[@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
			let str = vm.user.zipCode;
			if (str.search(pattern) >= 0) {
				vm.errorZip = true;
				return;
			}
			vm.errorZip = false;

		}

		function checkLocationOnBlur(location, type) {

			// console.log("check " + type + " on blur");
			if (!location) {
				return;
			}
			switch (type) {
				case "country":
					if (vm.errorCountry == false) {
						return;
					}
					for (let value of countries) {
						if (value.toLowerCase() == location.toLowerCase()) {
							vm.errorCountry = false;
							vm.form.country.$valid = true;
							getStates(value);
							break;
						}
						vm.errorCountry = true;
						vm.form.country.$valid = false;
					}
					break;
				case "state":
					if (vm.errorState == false) {
						return;
					}
					for (let value of vm.states) {
						if (value.toLowerCase() == location.toLowerCase()) {
							vm.errorState = false;
							vm.form.state.$valid = true;
							getCities(vm.user.country, value);
							break;
						}
						vm.errorState = true;
						vm.form.state.$valid = false;
					}
					break;
				case "city":
					if (vm.errorCity == false) {
						return;
					}
					for (let value of vm.cities) {
						if (value.toLowerCase() == location.toLowerCase()) {
							vm.errorCity = false;
							vm.form.city.$valid = true;
							break;
						}
						vm.errorCity = true;
						vm.form.city.$valid = false;

					}
					break;
			}
		}

		function getCities(country, state, action) {
			if (!country || !state) {
				return;
			}

			vm.user.state = state;

			if (action !== "init") {
				vm.user.city = '';
				vm.errorState = false;
				vm.form.state.$valid = true;
			}

			if (vm.states) {
				vm.isFetchingCities = true;
				vm.cities = null;
				userService.getCityList(country, state)
					.then((response) => {
						vm.cities = response;
						if (vm.location.city) {
							let cityIndex = _.findIndex(vm.cities, (city) => {
								return city.includes(vm.location.city);
							});

							vm.user.city = (cityIndex >= 0) ? vm.cities[cityIndex] : '';
						}
					})
					.catch((error) => {
						console.error("err", error);
					})
					.finally(() => {
						vm.isFetchingCities = false;
					});
			}
		}

		function updateCurrentUser(user) {
			let oldUser=Object.assign({},$rootScope.user);
			$rootScope.user = user;
			$rootScope.user.trial = oldUser.trial;
			$rootScope.user.payment = oldUser.payment;
			$rootScope.user.remainintTrialDays = oldUser.remainintTrialDays;
			Store.set('userData', JSON.stringify($rootScope.user));
		}


		function editProfile() {
			//console.log(vm.user)
			if ((!countries.includes(vm.user.country) || !vm.user.zipCode)) {
				vm.countryError = "Some error occured, try again!";//userConstants.messages.profileAddressInvalid
				return false;
			}

			if ((!vm.user.city || !vm.user.state) ) {
				//console.log('city or sate true')
				vm.stateError = "Some error occured, try again!";//userConstants.messages.profileAddressInvalid;
				return false;
			}

			if (!vm.form.$valid && !vm.user.dob ) {
				vm.dobError = "Some error occured, try again!";//userConstants.messages.userDobInvalid
				return false;
			}
			if (!vm.form.$valid && !vm.user.gender) {
				vm.genderError = 'Please select gender';
				return false;
			}



			let user;

			$rootScope.loadingBtn('save', 'Loading');
			if (vm.user.countryCode === null) {
				vm.user.countryCode = 0;
			}
			userService.editProfile(vm.user)
				.then((response) => {
					user = response.data.User;
					updateCurrentUser(user);
					toastService.toast({
						message: userConstants.messages.profileUpdateSuccess,
						type: 'success',
						delay: 2000
					});
					vm.form.$submitted = true;
					close(response);
				})
				.catch(err => {
					console.log("error")
					toastService.toast({
						message: "Some error occured, try again!",//userConstants.messages.profileUpdateError,
						type: 'error',
						delay: 2000
					});
				})
				.finally(() => {
					$rootScope.loadingBtnComplete('save', 'Save');
				});
		}

		this.$onInit = function() {
			if (vm.user.country) {
				getStates(vm.user.country, "init")
					.then((response) => {
						getCities(vm.user.country, vm.user.state, "init");
					})
					.catch((err) => {
						// console.log("err");
					});
			}
		};

		function close(data) {
			$uibModalInstance.close(data);
		}

		/**
		 * If a state change is detected, when the task controller is active, then it is checked whether the modal window is open, if yes then it is closed
		 */
		$scope.$on("$stateChangeStart", function(evt, toState, toParams, fromState, fromParams) {

			if (vm.form.$dirty && !vm.form.$pristine && !vm.form.$submitted) {
				evt.preventDefault();
				$rootScope.isLoading = false;
				let confirmData = {
					message: "Some error occured, try again!",//userConstants.messages.leavePageConfirmation,
					modalSize: 'md',
					type: 'danger'
				};

				commonService.confirm(confirmData)
					.then(function(confirmation) {
						if (confirmation.result) {
							vm.form.$submitted = true;
							$state.go(toState, toParams);
						}
					});
			}
		});
	}
export default userProfileInfoController;
