let angular = window.angular;
appointmentService.$inject = [
  "$http",
  "$q",
  "calendarService",
  "calendarConstants",
  "moment",
  "commonService",
    "appConstants",
];

function appointmentService(
  $http,
  $q,
  calendarService,
  calendarConstansts,
  moment,
  commonService,
  appConstants,
) {
  let basePath = appConstants.baseUrl+"/api/appointments/";

  let vacationBasePath = appConstants.baseUrl+"/api/vacations/";

  angular.extend(this, {
    viewAppointment,
    createAppointment,
    createVacation,
    editAppointment,
    deleteAppointment,
    deleteFutureAppointment,
    getRecurrenceOption,
    getRecurrenceString,
    getReminderOption,
    changeStatus,
    updateAttendees,
    viewVacation,
    editVacation,
    deleteVacation,
    markTodoChore,
  });

  function markTodoChore(choreId, todoStatus) {
    let deferred = $q.defer(),
      taskPromise;

    taskPromise = $http.post(basePath + "markChore/" + choreId, {
      status: todoStatus,
    });

    taskPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function updateAttendees(appointmentId, data, timezone = null) {
    let editAppointmentPromise;
    let editUrl =
      basePath + "updateAttendees/" + appointmentId + "?timezone=" + timezone;

    editAppointmentPromise = $http.post(editUrl, data);
    return editAppointmentPromise;
  }

  function changeStatus(status, id) {
    let changeStatusPromise;
    // changeStatusPromise = $http({
    //     method: 'POST',
    //     url: basePath + 'status/' + id,
    //     data: { status: status }
    // });
    changeStatusPromise = $http.post(basePath + "status/" + id, {
      status: status,
    });
    return changeStatusPromise;
  }

  function getRecurrenceOption(recurrence) {
    let recurrenceOption;
    _.takeRightWhile(calendarConstansts.recurrenceOptions, function (option) {
      if (
        recurrence.type == option.type &&
        ((option.shorthand &&
          option.separationCount == recurrence.separationCount) ||
          !option.shorthand)
      ) {
        recurrenceOption = option;
      }
      if (!recurrenceOption) {
        return true;
      }
    });

    return recurrenceOption;
  }

  function getReminderOption(reminder) {
    return _.find(
      calendarConstansts.reminderOptions,
      _.pick(reminder, ["value", "unit"])
    );
  }

  function getRecurrenceString(recurrence, startDate) {
    let option,
      string = "";
    option = getRecurrenceOption(recurrence);
    if (option.shorthand) {
      if (option.dynamic) {
        string += "Every ";
        string +=
          option.type === "month"
            ? moment(startDate).format("Do")
            : option.type === "week"
            ? moment(startDate).format("dddd")
            : "";
      } else {
        string += option.title;
      }
    } else {
      if (recurrence.separationCount > 0) {
        string +=
          "Every " +
          (recurrence.separationCount + 1) +
          " " +
          recurrence.type +
          (recurrence.separationCount >= 1 ? "s" : "");
      } else {
        string += option.title;
      }

      string +=
        option.type === "month" ? " on " + moment(startDate).format("Do") : "";
      if (recurrence.weekDay.length) {
        string += " on ";
        recurrence.weekDay.map((day, index) => {
          if (index === recurrence.weekDay.length - 1) {
            string += " " + day;
          } else {
            string += " " + day + ",";
          }
        });
      } else {
        string +=
          option.type === "week"
            ? " on " + moment(startDate).format("dddd")
            : "";
      }
    }

    if (recurrence.endsWith === "maxOccurrences") {
      string +=
        ", " +
        recurrence.maxOccurrences +
        " time" +
        (recurrence.maxOccurrences > 1 ? "s" : "");
    } else if (recurrence.endsWith === "endOfRecurrence") {
      string +=
        ", until " + moment(recurrence.endOfRecurrence).format("MMM D, YYYY");
    }
    return string;
  }

  function viewAppointment(appointmentId, occurrenceId) {
    let deferred = $q.defer(),
      viewAppointmentPromise;
    viewAppointmentPromise = $http.get(
      basePath +
        "view/" +
        appointmentId +
        "?timezone=" +
        -new Date().getTimezoneOffset() +
        (angular.isDefined(occurrenceId) ? "&occurrenceId=" + occurrenceId : "")
    );

    viewAppointmentPromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function viewVacation(appointmentId) {
    let deferred = $q.defer(),
      viewAppointmentPromise;
    viewAppointmentPromise = $http.get(
      vacationBasePath +
        "view/" +
        appointmentId +
        "?timezone=" +
        -new Date().getTimezoneOffset()
    );

    viewAppointmentPromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function createAppointment(timezone, data) {
    let createAppointmentPromise;
    // createAppointmentPromise = $http.post(basePath + 'add/' + '?timezone=' + -(new Date()).getTimezoneOffset(), data);
    var timeZone = moment.tz.guess();
    // console.log("timeZone",timeZone)
    createAppointmentPromise = $http({
      method: "POST",
      url: basePath + "add",
      params: {
        timezone: -new Date().getTimezoneOffset(),
        timezonename: timeZone,
      },
      data: data,
    });
    return createAppointmentPromise;
  }

  function createVacation(timezone, data) {
    let createVacationPromise;
    // createAppointmentPromise = $http.post(basePath + 'add/' + '?timezone=' + -(new Date()).getTimezoneOffset(), data);
    createVacationPromise = $http({
      method: "POST",
      url: vacationBasePath + "save",
      params: { timezone: -new Date().getTimezoneOffset() },
      data: data,
    });
    return createVacationPromise;
  }

  function editVacation(
    appointmentId,
    data,
    editType,
    occurrenceId,
    timezone,
    dropped
  ) {
    let editAppointmentPromise;
    let editUrl = vacationBasePath + "save/" + appointmentId;

    // editAppointmentPromise = $http.post(editUrl, data);
    editAppointmentPromise = $http({
      method: "POST",
      url: editUrl,
      params: { timezone: -new Date().getTimezoneOffset() },
      data: data,
    });
    return editAppointmentPromise;
  }

  function editAppointment(
    appointmentId,
    data,
    editType,
    occurrenceId,
    timezone,
    dropped
  ) {
    var timeZone = moment.tz.guess();
    let editAppointmentPromise;
    let editUrl = basePath + "edit/" + appointmentId + "?editType=" + editType;
    if (occurrenceId) {
      editUrl += "&occurrenceId=" + occurrenceId;
    }

    if (angular.isDefined(dropped)) {
      editUrl += "&dropped=" + dropped;
    }

    // editAppointmentPromise = $http.post(editUrl, data);
    editAppointmentPromise = $http({
      method: "POST",
      url: editUrl,
      params: {
        timezone: -new Date().getTimezoneOffset(),
        timezonename: timeZone,
      },
      data: data,
    });
    return editAppointmentPromise;
  }

  function deleteAppointment(appointmentId, occurrenceId, occurrenceDelete) {
    let deferred = $q.defer(),
      deleteAppointmentPromise;

    let url = basePath + "deleteAppointment/" + appointmentId;

    if (
      angular.isDefined(occurrenceId) &&
      angular.isDefined(occurrenceDelete)
    ) {
      url +=
        "?deleteOccurrence=" +
        occurrenceDelete +
        "&occurrenceId=" +
        occurrenceId;
    }

    deleteAppointmentPromise = $http.delete(url);

    deleteAppointmentPromise
      .then((response) => {
        calendarService.removeEventFromList(appointmentId, "appointment");
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function deleteFutureAppointment(appointmentId, selectedDate) {
    var timeZone = moment.tz.guess();
    let deferred = $q.defer(),
      deleteAppointmentPromise;
    let param = {
      selectedDate,
    };
    let url = basePath + "deleteAppointmentOccurance/" + appointmentId;

    deleteAppointmentPromise = $http({
      method: "DELETE",
      url: url,
      data: param,
      params: { timezonename: timeZone },
    });
    deleteAppointmentPromise
      .then((response) => {
        //calendarService.removeEventFromList(appointmentId, 'appointment');
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function deleteVacation(appointmentId) {
    let deferred = $q.defer(),
      deleteAppointmentPromise;

    let url = vacationBasePath + "deleteVacation/" + appointmentId;

    deleteAppointmentPromise = $http.delete(url);

    deleteAppointmentPromise
      .then((response) => {
        calendarService.removeEventFromList(appointmentId, "vacation");
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }
}

export default appointmentService;
