let angular = window.angular;

lessonListAssignController.$inject = [
  "$scope",
  "lessonService",
  "studentService",
  "$state",
  "$stateParams",
  "_",
  "commonService",
  "toastService",
  "lessonConstants",
  "$uibModal",
  "lessonData",
  "$uibModalInstance",
];

function lessonListAssignController(
  $scope,
  lessonService,
  studentService,
  $state,
  $stateParams,
  _,
  commonService,
  toastService,
  lessonConstants,
  $uibModal,
  lessonData,
  $uibModalInstance
) {
  let vm = this;
  let lessonId = 0;

  vm.lesson = {};
  vm.assignedStudents = [];
  vm.studentsToAssign = [];
  vm.assignmentDays = [];
  $scope.assignmentForm = {};
  vm.newStudentAssign = {};

  vm.assigningStudentId = 0;
  vm.isMaximumDaysSelected = false;
  vm.allDayLesson = false;
  vm.isFormInvalid = false;
  vm.formErrorMessage = "";
  vm.assignmentEndDate = null;
  vm.lessonStartDurationVisible = false;
  vm.showHide = false;

  vm.academicDetails = [
    {
      academicYearStart: lessonConstants.startOfyear,
      academicYearEnd: lessonConstants.endOfYear,
      grade: "Pre-school",
      academicDays: lessonConstants.academicDays,
    },
  ];

  vm.days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  angular.extend(this, {
    goBack,
    assignTo,
    addAndAssignTo,
    assignToStudent,
    createNewAndAssignToStudent,
    onSelectDay,
    close,
    checkAssignedStudents,
  });

  // function showEndDate() {
  // 	console.log(vm.assignmentDate);
  // }
  $scope.data = {};
  function checkAssignedStudents(student) {
    let checkStudentAssignStatus = vm.assignedStudents.find(
      (stdnt) => stdnt.id === student.id
    );
    if (checkStudentAssignStatus) {
      return true;
    } else {
      return false;
    }
  }
  function goBack() {
    $state.go("lessonList");
  }

  // Get the list of students to which the lesson is assigned currently
  function getAssignedStudents(totalStudents) {
    return lessonService
      .getAssignedStudent(lessonId)
      .then(function ([students, response]) {
        let studentIds = _.map(students, "id");
        let notAssignedStudent = [];
        for (let i = 0; i < totalStudents.length; i++) {
          if (!studentIds.includes(totalStudents[i].id)) {
            totalStudents[i].studentAssignedToLessonPlan = true;
            notAssignedStudent.push(totalStudents[i]);
          }
        }

        vm.assignedStudents = students;

        vm.studentsToAssign = totalStudents; //notAssignedStudent;
        if (!vm.assignedStudents.length && vm.lesson.startDate) {
          vm.lessonStartDurationVisible = true;
        }
        return response;
      })
      .catch(function (response) {
        console.error(response);
      });
  }

  // Get students to assign the lesson to
  function getStudentsToAssign() {
    return studentService
      .getStudents()
      .then(function (totalStudents) {
        return getAssignedStudents(totalStudents);
      })
      .catch(function (response) {
        console.error(response);
      });
  }

  function assignTo(studentId, index) {
    let modalInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "lessonAssignModalController",
      controllerAs: "lessonAssignCtrl",
      template: require("../../pug/lesson/assign-lesson-modal.pug").default,
      resolve: {
        data: {
          studentId: studentId,
          lesson: vm.lesson,
        },
      },
    });

    modalInstance.result
      .then(function (id, studentLesson) {
        let assignedStudent = _.find(vm.studentsToAssign, {
          id: id,
        });
        if (assignedStudent) {
          vm.assignedStudents.push(assignedStudent);
        }
      })
      .catch(function () {
        modalInstance.close();
      });
  }

  function validateAssignForm(check) {
    if (!check && !vm.isFormInvalid) {
      return;
    }

    if (!vm.assignmentDate) {
      vm.isFormInvalid = true;
      vm.formErrorMessage = lessonConstants.messages.assignErrorStartDate;
      return false;
    }

    if (!vm.assignmentTime) {
      if (vm.allDayLesson && vm.lesson.duration == "23:59") {
        //it is all day event to start time should be 12:00AM
      } else {
        vm.isFormInvalid = true;
        vm.formErrorMessage = lessonConstants.messages.assignErrorStartTime;
        return false;
      }
    }

    if (vm.assignmentDays.length !== vm.lesson.days) {
      vm.isFormInvalid = true;
      vm.formErrorMessage = commonService.renderTemplateString(
        lessonConstants.messages.assignErrorDays,
        { days: vm.lesson.days }
      );
      return false;
    }

    vm.isFormInvalid = false;
    vm.formErrorMessage = "";
    return true;
  }

  /**
   * Main function to differentiate between group and ungroup lesson
   * @param {*} studentId
   * @param {*} index
   */
  function assignToStudent(studentId) {
    if (vm.lesson.isGroup) {
      lessonGroupAssignToStudent(studentId, "same");
    } else {
      lessonUngroupAssignToStudent(studentId, "same");
    }
  }

  function createNewAndAssignToStudent(studentId) {
    //console.log("here", studentId);
    if (!lessonId || lessonId == 0) {
      return false;
    }
    /**
     * Create lesson first
     */
    // let lessonId = commonService.convertToInteger(lessonId);
    lessonService
      .copyLesson(lessonId)
      .then((response) => {
        vm.lesson = response;
        //console.log("after response", studentId);
        // if (vm.lesson.isGroup) {
        // 	lessonGroupAssignToStudent(studentId, "new");
        // } else {
        lessonUngroupAssignToStudent(studentId, "new");
        // }
      })
      .catch((error) => {
        toastService.toast({
          message: error,
          type: "error",
          delay: 2000,
        });
      });
  }

  function lessonUngroupAssignToStudent(studentId, assignType = "same") {
    let modalInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "lessonAssignModalController",
      controllerAs: "lessonAssignCtrl",
      template: require("../../pug/lesson/assign-lesson-modal.pug").default,
      resolve: {
        data: {
          studentId: studentId,
          lesson: vm.lesson,
        },
      },
    });

    modalInstance.result
      .then(function (id, studentLesson) {
        let assignedStudent = _.find(vm.studentsToAssign, {
          id: id,
        });
        assignedStudent.studentAssignedToLessonPlan = true;
        if (assignedStudent && assignType == "same") {
          vm.assignedStudents.push(assignedStudent);
          // commonService.removeFromArray(studentId, 'id', vm.studentsToAssign);
        }

        if (assignType == "new") {
          $uibModalInstance.dismiss();
        }
      })
      .catch(function () {
        modalInstance.close();
      });
  }

  function lessonGroupAssignToStudent(studentId, assignType = "same") {
    let formValidated = validateAssignForm(true);

    if (!formValidated) {
      toastService.toast({
        message:
          "Please select the date and time first to assign a lesson plan to a student",
        type: "error",
        delay: 3000,
      });
      return;
    }

    // $rootScope.loadingBtn('assign', 'Assigning');

    let data = {},
      dateTime;
    data.studentId = studentId;
    data.lessonId = vm.lesson.id;
    data.assignmentDays = vm.assignmentDays;
    data.startDate = vm.assignmentDate;
    // data.color = vm.student.color;

    /**
     * we count end date from the backend
     */
    // data.endDate = vm.assignmentEndDate;

    /**
     * IF this is all day lesson then we set start date on 12:00AM
     */
    if (vm.allDayLesson && vm.lesson.duration == "23:59") {
      vm.assignmentTime = "00:00";
    }
    dateTime = commonService.convertToUTC(
      vm.assignmentDate + " " + vm.assignmentTime
    );
    data.utcStartDateTime = dateTime.format("Y-MM-DD HH:mm:00");

    let lessonStartDurationPromise = Promise.resolve(true);
    if (vm.lessonStartDurationVisible) {
      vm.lesson.startDate = data.utcStartDateTime;
      vm.lesson.assignmentDays = vm.assignmentDays;
      vm.lesson.startTime = vm.assignmentTime; //dateTime == null ? dateTime : dateTime.format("HH:mm");
      lessonStartDurationPromise = lessonService.addLessonTime(vm.lesson);
    }

    lessonStartDurationPromise
      .then((lessonUpdate) => {
        lessonService.assignStudent(vm.lesson, data);
      })
      .then((response) => {
        toastService.toast({
          message: lessonConstants.messages.assignedSuccessfuly,
          type: "success",
          delay: 3000,
        });

        // commonService.removeFromArray(studentId, 'id', vm.studentsToAssign);
        let currentStudent = studentService.getStudentsById(studentId);
        vm.assignedStudents.push(currentStudent[0]);

        if (vm.lessonStartDurationVisible) {
          vm.lesson.assignmentDays = commonService.getBinaryFromDays(
            vm.assignmentDays
          );
        }
        vm.lessonStartDurationVisible = false;

        // $rootScope.loadingBtnComplete('assign', 'Assign');
        // $uibModalInstance.close(vm.student.id, response);

        if (assignType == "new") {
          $uibModalInstance.dismiss();
        }
      })
      .catch((response) => {
        toastService.toast({
          message: "Something went wrong, try again",
          type: "error",
          delay: 3000,
        });
      });
  }

  function addAndAssignTo(assignType = "same") {
    vm.newStudentAssign.academics = vm.academicDetails;
    studentService
      .addStudent(vm.newStudentAssign)
      .then((response) => {
        let newStudent = response.data.data;
        if (newStudent.Student) {
          let studentData = newStudent.Student;
          studentService.saveStudent(studentData.id, studentData);
          vm.studentsToAssign.push(studentData);
          vm.newStudentAssign = {};
          if (assignType == "new") {
            return createNewAndAssignToStudent(studentData.id);
          } else {
            return assignToStudent(studentData.id);
          }
        } else {
          toastService.toast({
            message: "You can not uncheck all Search In categories",
            type: "error",
            delay: 5000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function onSelectDay() {
    vm.isMaximumDaysSelected = vm.assignmentDays.length >= vm.lesson.days;
    // showEndDate();
  }

  function close() {
    $uibModalInstance.dismiss();
  }

  function init() {
    vm.lesson = lessonData;
    lessonId = commonService.convertToInteger(lessonData.id);
    /**
     * Check the duration, if it is 23:59 then it is all day event
     */
    if (vm.lesson.duration == "23:59") {
      vm.allDayLesson = true;
      vm.assignmentTime = "00:00";
    } else {
      vm.assignmentTime = moment(lessonData.startDate).format("HH:mm");
    }

    if (!vm.lesson.isGroup) {
      return [getStudentsToAssign()];
    }

    if (!lessonData.startDate || !lessonData.assignmentDays) {
      vm.lessonStartDurationVisible = true;
      return [getStudentsToAssign()];
    }

    /**
     * Calculate Date and days
     */
    vm.assignmentDate = moment(lessonData.startDate).format("Y-MM-DD");
    let assignmentDays = commonService.getDaysFromBinary(
      lessonData.assignmentDays
    );

    if (assignmentDays.length != lessonData.days) {
      vm.lessonStartDurationVisible = true;
      return [getStudentsToAssign()];
    }
    vm.assignmentDays = commonService.getDaysFromBinary(
      lessonData.assignmentDays
    );
    onSelectDay();

    // if (!vm.assignedStudents.length && vm.lesson.startDate) {
    // 	console.log("true");
    // 	vm.lessonStartDurationVisible = true;
    // }
    return [getStudentsToAssign()];
  }

  this.$onInit = init;
}
export default lessonListAssignController;
