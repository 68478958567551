let angular = window.angular;

assignmentService.$inject = [
  "$http",
  "$q",
  "studentService",
  "_",
  "toastService",
    "appConstants"
];

function assignmentService($http, $q, studentService, _, toastService,appConstants) {
  let basePath = appConstants.baseUrl+"/api/students/assignments/";
  let weekdaysObject = {
    sun: "Sunday",
    mon: "Monday",
    tue: "Tuesday",
    wed: "Wednesday",
    thu: "Thursday",
    fri: "Friday",
    sat: "Saturday",
  };
  let self = this;
  self.grades = [];

  angular.extend(this, {
    gradeStudent,
    viewAssignment,
    gradeViewAssignment,
    saveAssignment,
    changeStatus,
    deleteAssignment,
    getGrades,
    viewLesson,
    changeStatusAllLessons,
    fetchIncompleteAssignments,
    changeAssignmentsStatus,
    rescheduleAssignments,
    getWeekdayString,
    addGrade,
    search,
    searchAssignmentService,
    getUngradedLessons,
    getOverdueCount,
    getUngradedCount,
    getOverdueAssignments,
    filterOverdueAssignments,
    deleteGrade,
    assignmentTitleAndDailyNote,
    deleteElectronicMedia,
    dragOrder,
  });

  function getGrades() {
    let deferred = $q.defer(),
      getGradesPromise;

    getGradesPromise = $http({
      method: "GET",
      url: appConstants.baseUrl+"/api/grades/index",
    });

    if (self.grades.length) {
      deferred.resolve(self.grades);
    } else {
      getGradesPromise
        .then((response) => {
          self.grades = response.data.data;
          deferred.resolve(self.grades);
        })
        .catch((response) => {
          deferred.reject(response.data.message);
        });
    }

    return deferred.promise;
  }

  function gradeStudent(assignmentId, grade, defaultMarks) {
    let deferred = $q.defer(),
      data = { grade, defaultMarks },
      gradeStudentPromise;

    gradeStudentPromise = $http({
      method: "POST",
      url: basePath + "grade/" + assignmentId,
      data: data,
    });

    gradeStudentPromise
      .then((response) => {
        deferred.resolve(response);
      })
      .catch((response) => {
        deferred.reject(response);
      });

    return deferred.promise;
  }

  function gradeViewAssignment(assignmentId) {
    let deferred = $q.defer(),
      viewAssignmentPromise;

    viewAssignmentPromise = $http({
      method: "GET",
      url: basePath + "gradeView/" + assignmentId + "?richText=true",
    });

    viewAssignmentPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data.message);
      });

    return deferred.promise;
  }

  function viewAssignment(assignmentId) {
    let deferred = $q.defer(),
      viewAssignmentPromise;

    viewAssignmentPromise = $http({
      method: "GET",
      url: basePath + "view/" + assignmentId + "?richText=true",
    });

    viewAssignmentPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data.message);
      });

    return deferred.promise;
  }

  function viewLesson(lessonId) {
    let deferred = $q.defer(),
      viewLessonPromise;

    viewLessonPromise = $http({
      method: "GET",
      url:  appConstants.baseUrl+"/api/students/lessons/getStudentLessonAssignment/" + lessonId,
    });

    viewLessonPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data.message);
      });

    return deferred.promise;
  }

  function fetchIncompleteAssignments(queryData) {
    let deferred = $q.defer(),
      getIncompleteAssignmentsPromise,
      queryParams = { skip: 0 };

    if (queryData) {
      queryParams.skip = queryData.skip;
    }

    getIncompleteAssignmentsPromise = $http({
      method: "GET",
      url:  appConstants.baseUrl+"/api/students/assignments/getNotComplete",
      params: queryParams,
    });

    getIncompleteAssignmentsPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data.message);
      });

    return deferred.promise;
  }

  function getOverdueAssignments(queryData) {
    let deferred = $q.defer(),
      getIncompleteAssignmentsPromise,
      queryParams = { skip: 0 };

    if (queryData) {
      queryParams.skip = queryData.skip;
    }

    getIncompleteAssignmentsPromise = $http({
      method: "GET",
      url:  appConstants.baseUrl+"/api/students/assignments/getOverdueAssignments",
      params: queryParams,
    });

    getIncompleteAssignmentsPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data.message);
      });

    return deferred.promise;
  }

  function filterOverdueAssignments(queryData) {
    let deferred = $q.defer(),
      getIncompleteAssignmentsPromise,
      queryParams = { skip: 0 },
      query = null;

    if (queryData) {
      queryParams.skip = queryData.skip;
    }

    if (queryData.studentId && queryData.lessonId) {
      query =
        "?studentId=" + queryData.studentId + "&lessonId=" + queryData.lessonId;
    } else if (queryData.studentId) {
      query = "?studentId=" + queryData.studentId;
    } else {
      query = "?lessonId=" + queryData.lessonId;
    }

    getIncompleteAssignmentsPromise = $http({
      method: "GET",
      url:
          appConstants.baseUrl+"/api/students/assignments/filterOverdueAssignments" +
        query +
        "&skip=" +
        queryData.skip,
    });

    getIncompleteAssignmentsPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data.message);
      });

    return deferred.promise;
  }

  function search(queryData) {
    let deferred = $q.defer(),
      getIncompleteAssignmentsPromise,
      queryParams = { skip: 0 },
      query = null;

    if (queryData) {
      queryParams.skip = queryData.skip;
    }

    if (queryData.studentId && queryData.lessonId) {
      query =
        "?studentId=" + queryData.studentId + "&lessonId=" + queryData.lessonId;
    } else if (queryData.studentId) {
      query = "?studentId=" + queryData.studentId;
    } else {
      query = "?lessonId=" + queryData.lessonId;
    }

    getIncompleteAssignmentsPromise = $http({
      method: "GET",
      url:
          appConstants.baseUrl+"/api/students/assignments/searchOverdue" +
        query +
        "&skip=" +
        queryData.skip,
    });

    getIncompleteAssignmentsPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data.message);
      });

    return deferred.promise;
  }

  function searchAssignmentService(queryData) {
    //console.log("queryData ins assignment Service", queryData);

    let deferred = $q.defer(),
      getIncompleteAssignmentsPromise,
      queryParams = { skip: 0 },
      query = null;

    if (queryData) {
      queryParams.skip = queryData.skip;
    }

    if (
      queryData.startDate &&
      queryData.endDate &&
      queryData.studentId &&
      queryData.lessonId
    ) {
      query =
        "?startDate=" +
        queryData.startDate +
        "&endDate=" +
        queryData.endDate +
        "&studentId=" +
        queryData.studentId +
        "&lessonId=" +
        queryData.lessonId;
    } else if (queryData.startDate && queryData.endDate && queryData.lessonId) {
      query =
        "?startDate=" +
        queryData.startDate +
        "&endDate=" +
        queryData.endDate +
        "&lessonId=" +
        queryData.lessonId;
    } else if (
      queryData.startDate &&
      queryData.endDate &&
      queryData.studentId
    ) {
      query =
        "?startDate=" +
        queryData.startDate +
        "&endDate=" +
        queryData.endDate +
        "&studentId=" +
        queryData.studentId;
    } else if (queryData.startDate && queryData.endDate) {
      query =
        "?startDate=" + queryData.startDate + "&endDate=" + queryData.endDate;
    } else if (queryData.studentId && queryData.lessonId) {
      query =
        "?studentId=" + queryData.studentId + "&lessonId=" + queryData.lessonId;
    } else if (queryData.studentId) {
      query = "?studentId=" + queryData.studentId;
    } else {
      query = "?lessonId=" + queryData.lessonId;
    }

    let getSearchAssignmentsPromise = $http({
      method: "GET",
      url:
          appConstants.baseUrl+"/api/students/assignments/searchAssignments" +
        query +
        "&skip=" +
        queryData.skip,
    });

    getSearchAssignmentsPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data.message);
      });

    return deferred.promise;
  }

  function saveAssignment(data) {
    let saveAssignmentPromise;
    saveAssignmentPromise = $http({
      method: "POST",
      url: basePath + "save",
      params: { timezone: -new Date().getTimezoneOffset() },
      data: data,
    });
    return saveAssignmentPromise;
  }

  function addGrade(data, id) {
    let addGradePromise;
    addGradePromise = $http({
      method: "POST",
      url: basePath + "addGrade/" + id,
      data: data,
    });
    return addGradePromise;
  }

  function changeStatus(status, id) {
    let changeStatusPromise,
      ids = _.isArray(id) ? id : [id];
    changeStatusPromise = $http({
      method: "POST",
      url: basePath + "status",
      data: {
        status: status,
        ids: ids,
      },
    });
    return changeStatusPromise;
  }

  function changeStatusAllLessons(status, id) {
    let changeStatusAllLessonsPromise;
    changeStatusAllLessonsPromise = $http({
      method: "POST",
      url:  appConstants.baseUrl+"/api/students/lessons/status",
      data: { status: status, id: id },
    });
    return changeStatusAllLessonsPromise;
  }

  function changeAssignmentsStatus(assignmentId, status, isCheckAll) {
    let changeAssignmentsStatusPromise, data;
    if (!isCheckAll) {
      data = {
        ids: assignmentId,
        status: status,
        isCheckAll: isCheckAll,
      };
    } else {
      data = {
        uncheckedIds: assignmentId,
        status: status,
        isCheckAll: isCheckAll,
      };
    }

    changeAssignmentsStatusPromise = $http({
      method: "POST",
      url:  appConstants.baseUrl+"/api/students/assignments/updateAllStatus",
      data: data,
    });
    return changeAssignmentsStatusPromise;
  }

  function rescheduleAssignments(
    groupAssignments,
    assignmentId,
    date,
    rescheduleFor,
    isCheckAll
  ) {
    let rescheduleAssignmentsStatusPromise;
    let data;
    if (isCheckAll) {
      data = {
        groupAssignments: groupAssignments,
        uncheckedIds: assignmentId,
        startDate: date,
        reschedule: rescheduleFor,
        isCheckAll: isCheckAll,
      };
    } else {
      data = {
        groupAssignments: groupAssignments,
        ids: assignmentId,
        startDate: date,
        reschedule: rescheduleFor,
        isCheckAll: isCheckAll,
      };
    }

    rescheduleAssignmentsStatusPromise = $http({
      method: "POST",
      url:  appConstants.baseUrl+"/api/students/assignments/changeAllStartDate",
      params: { timezone: -new Date().getTimezoneOffset() },
      data: data,
    });
    return rescheduleAssignmentsStatusPromise;
  }

  function deleteAssignment(id) {
    // return $http.delete(basePath + 'delete/' + id);
    return $http({
      method: "DELETE",
      url: basePath + "delete/",
      data: { id },
    });
  }

  function getWeekdayString(assignmentDaysArray) {
    if (!assignmentDaysArray || !assignmentDaysArray.length) {
      return "";
    }

    let assignmentMessage = "";
    /**
     * Create string for the weekdays
     */

    _.forEach(assignmentDaysArray, function (weekday, index) {
      if (index == 0) {
        assignmentMessage += weekdaysObject[weekday];
      } else {
        assignmentMessage += ", " + weekdaysObject[weekday];
      }
    });

    return assignmentMessage;
  }

  function getUngradedLessons(queryParams) {
    let deferred = $q.defer(),
      getUngradedAssignmentsPromise;

    if (queryParams.students) {
      queryParams.filter = true;
    }
    if (queryParams.lessonIds) {
      queryParams.filter = true;
    }

    getUngradedAssignmentsPromise = $http({
      method: "GET",
      url:  appConstants.baseUrl+"/api/students/assignments/getUngradedLessons",
      params: queryParams,
    });

    getUngradedAssignmentsPromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((error) => {
        deferred.reject(response.data.message);
      });

    return deferred.promise;
  }

  function getOverdueCount(queryParams) {
    let deferred = $q.defer(),
      getIncompleteAssignmentsPromise;

    if (queryParams.students) queryParams.filter = true;

    getIncompleteAssignmentsPromise = $http({
      method: "GET",
      url:  appConstants.baseUrl+"/api/students/assignments/getOverdueCount",
      params: queryParams,
    });

    getIncompleteAssignmentsPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data.message);
      });

    return deferred.promise;
  }

  function getUngradedCount(queryParams) {
    let deferred = $q.defer(),
      getUngradedAssignmentsPromise;

    if (queryParams.students) {
      queryParams.filter = true;
    }
    if (queryParams.lessonIds) {
      queryParams.filter = true;
    }

    getUngradedAssignmentsPromise = $http({
      method: "GET",
      url:  appConstants.baseUrl+"/api/students/assignments/getUngradedCount",
      params: queryParams,
    });

    getUngradedAssignmentsPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data.message);
      });

    return deferred.promise;
  }

  function deleteGrade(id) {
    let deleteGradePromise,
      queryParams = { assignmentId: id };

    deleteGradePromise = $http({
      method: "GET",
      url:  appConstants.baseUrl+"/api/students/assignments/deleteGrade",
      params: queryParams,
    });
    return deleteGradePromise;
  }
  function assignmentTitleAndDailyNote(assignmentData) {
    let defer = $q.defer();
    $http({
      method: "POST",
      url:  appConstants.baseUrl+"/api/students/assignments/assignmentTitleAndDailyNote",
      data: assignmentData,
    })
      .then(function (response) {
        defer.resolve(response);
      })
      .catch(function (err) {
        defer.reject(err);
      });
    return defer.promise;
  }
  function deleteElectronicMedia(electronicMedia) {
    let defer = $q.defer();
    $http({
      method: "DELETE",
      url:  appConstants.baseUrl+"/api/students/assignments/deleteElectronicMedia",
      data: electronicMedia,
    })
      .then(function (response) {
        defer.resolve(response);
      })
      .catch(function (err) {
        defer.reject(err);
      });
    return defer.promise;
  }
  function dragOrder(dragOrder) {
    let defer = $q.defer();
    $http({
      method: "POST",
      url: appConstants.baseUrl+"/api/students/assignments/dragOrder",
      data: dragOrder,
    })
      .then(function (response) {
        defer.resolve(response);
      })
      .catch(function (err) {
        defer.reject(err);
      });
    return defer.promise;
  }
}
export default assignmentService;
