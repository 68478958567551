let angular = window.angular;
    budgetService.$inject = ['$http', '_', '$q', 'commonService', 'budgetConstants',"appConstants"];

    function budgetService($http, _, $q, commonService, budgetConstants,appConstants) {

        let studentBudgets,
            overallBudget,
            categories = [];

        let service = {
            getBudgets,
            getBudgetDetails,
            editBudget,
            addTransaction,
            updateTransaction,
            deleteTransaction,
            studentBudgets,
            overallBudget,
            addCategory,
            editCategory,
            getCategories,
            createBudget,
            searchExpense,
            deleteBudget
        };

        function addCategory(name, color) {

            let addCategoryPromise, deferred, data;
            deferred = $q.defer();
            if (!name) {
                return $q.reject(budgetConstants.messages.categoryNameNotProvided);
            }
            data = {
                category: {name, color}
            };
            addCategoryPromise = $http.post(appConstants.baseUrl+budgetConstants.endpoints.category + '/add', data);
            addCategoryPromise
                .then((response) => {
                    let category = response.data.data;
                    commonService.saveInArray(category.id, 'id', categories, category);
                    deferred.resolve(category);
                })
                .catch((response) => {
                    deferred.reject(response.data.message);
                });

            return deferred.promise;
        }

        function editCategory(category) {
            return $http.post(appConstants.baseUrl+budgetConstants.endpoints.category + '/edit/' + category.id, {category});
        }

        function getCategories() {
            let categoriesListPromise, deferred = $q.defer();

            if (categories.length) {
                deferred.resolve(categories);
            } else {
                categoriesListPromise = $http.get(appConstants.baseUrl+budgetConstants.endpoints.category + '/index');
                categoriesListPromise
                    .then((response) => {
                        // Default categories not to be available in drop down | Discussed with Client
                        // categories = _.filter(response.data.data, (category) => category.userId || category.userId == 0);
                        categories = _.filter(response.data.data, (category) => category.userId);
                        deferred.resolve(categories);
                    })
                    .catch((response) => {
                        deferred.reject(response.data.message);
                    });
            }
            return deferred.promise;
        }

        function getBudgets() {
            return $http.get(appConstants.baseUrl+budgetConstants.endpoints.budget + '/index?version=true');
        }

        function deleteBudget(id) {
            return $http.delete(appConstants.baseUrl+budgetConstants.endpoints.budget + '/deleteBudget/' + id);
        }

        function getBudgetDetails(id, params) {
            let link;
            if(id) {
                link = appConstants.baseUrl+budgetConstants.endpoints.budget + '/view_v2/' + id;
            } else {
                link = appConstants.baseUrl+budgetConstants.endpoints.budget + '/view_v2';
            }
            return $http.get(link);
        }

        function editBudget(budget) {
            let link = appConstants.baseUrl+budgetConstants.endpoints.budget + '/edit_v2/' + budget.id + '?';
            return $http.post(link, {
                budget: budget
            });
        }

        function createBudget(budget) {
            let link = appConstants.baseUrl+budgetConstants.endpoints.budget + '/create';
            // return $http.post(link, {
            // 	budget: budget
            // });



            var defer = $q.defer();

            $http({
                method: 'POST',
                url: link,
                data:{
                    budget: budget
                }
            })
                .then(function(resp) {
                    defer.resolve(resp);
                }).catch((err) => {
                console.log("err")
                defer.reject(err);
            });
            return defer.promise;

        }

        function addTransaction(transaction) {
            let link = appConstants.baseUrl+budgetConstants.endpoints.transaction + '/add_v2';
            return $http.post(link, {
                transaction: transaction
            });
        }

        function updateTransaction(transaction, changes) {
            let link = appConstants.baseUrl+budgetConstants.endpoints.transaction + '/edit/' + transaction.id;
            return $http.post(link, {
                transaction: transaction,
                change: changes
            });
        }

        function searchExpense(data) {
            let link = appConstants.baseUrl+budgetConstants.endpoints.budget + '/search';
            return $http.post(link, {
                searchData: data,
            });

        }

        function deleteTransaction(id) {
            let link = appConstants.baseUrl+budgetConstants.endpoints.transaction + '/remove/' + id;
            return $http.delete(link);
        }



        function initialize() {
            getCategories();
        }
        initialize();

        return service;
    }
export default budgetService;
