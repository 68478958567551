let angular = window.angular;
friendService.$inject = ['$rootScope', '$http', '$q', 'commonService', 'collaborateService', '$timeout', 'userService', 'webSocketService', 'notificationService', 'appConstants'];

function friendService($rootScope, $http, $q, commonService, collaborateService, $timeout, userService, webSocketService, notificationService, appConstants) {

    let basePath = appConstants.baseUrl + '/api/friends/';
    let friends = [];

    angular.extend(this, {
        friends,
        getFriends,
        search,
        searchFilter,
        sendInvitation,
        updateStatus,
        blockUser,
        unblockUser,
        reportUser,
        getFriendsById,
        getAllChatFriends
    });

    function getAllChatFriends() {
        let deferred = $q.defer(),
            getFriendsPromise;

        getFriendsPromise = $http.get("/api/chats/" + 'getAllChatContacts');
        getFriendsPromise
            .then((response) => {
                // console.log("freinsd",friends)
                deferred.resolve(response.data);
            })
            .catch((response) => {
                deferred.reject(response.data);
            });
        return deferred.promise;
    }
    function getFriends() {
        return friends;
    }

    function getFriendsById(friendIds) {
        let i, index, friendList = [];
        friendIds = angular.isArray(friendIds) ? friendIds : [friendIds];
        for (i = friendIds.length - 1; ~i; --i) {
            index = commonService.findInArray(friendIds[i], 'id', friends);
            if (~index) {
                friendList.push(friends[index]);
            }
        }
        return friendList;
    }

    function blockUser(id) {
        let url = basePath + 'block/' + id;
        return $http.post(url, {});
    }

    function unblockUser(id) {
        let url = basePath + 'unblock/' + id;
        return $http.post(url, {});
    }

    function reportUser(id, message) {
        let url = basePath + 'report/' + id;
        return $http.post(url, {
            message
        });
    }

    $rootScope.getFriendDetails = function (userId) {
        if (!userId) {
            return {};
        }

        if (userId === $rootScope.user.id) {
            return $rootScope.user;
        }

        //Check if the User exists in the workspace users list
        let index = commonService.findInArray(userId, 'id', friends);
        if (~index) {
            return friends[index];
        }

        // In-case the user is not a friend, then fetch the details
        // Send a request if the user is not present in the above lists and subsequently make add the user to the friend list.
        return {};
    };

    function getAllFriends() {
        let deferred = $q.defer(),
            getFriendsPromise;

        getFriendsPromise = $http.get(basePath + 'index');
        getFriendsPromise
            .then((response) => {
                friends = response.data.data;
                deferred.resolve(friends);
            })
            .catch((response) => {
                deferred.reject(response.data);
            });
        $rootScope.getFriendListPromise = deferred.promise;
        return deferred.promise;
    }

    function search(searchText) {
        let searchUserPromise;
        searchUserPromise = $http({
            method: 'GET',
            url: basePath + 'search',
            params: {
                q: searchText
            }
        });
        return searchUserPromise;
    }
    function searchFilter(searchText, filterObj) {
        let params = {
            q: searchText
        };
        if (filterObj.location) params.location = filterObj.location;
        if (filterObj.interests) params.interests = filterObj.interests;
        if (filterObj.faith) params.faith = filterObj.faith;
        if (filterObj.teachingStyle) params.teachingStyle = filterObj.teachingStyle;
        if (filterObj.gender) params.gender = filterObj.gender;
        let searchUserPromise;
        searchUserPromise = $http({
            method: 'GET',
            url: basePath + 'search',
            params: params
        });
        return searchUserPromise;
    }

    function sendInvitation(userId, inviteMessage) {
        let sendInvitationPromise, payload,
            deferred = $q.defer();

        payload = {
            friend: {
                userId: userId,
                inviteMessage: inviteMessage
            }
        };

        sendInvitationPromise = $http({
            method: 'POST',
            url: basePath + 'add',
            data: payload
        });
        sendInvitationPromise
            .then((response) => {
                let user = response.data.data.user,
                    chat = response.data.data.chat;
                commonService.saveInArray(user.id, 'id', friends, user);
                collaborateService.saveChat(chat);
                deferred.resolve(response);
            })
            .catch((response) => {
                deferred.reject(response);
            });
        return deferred.promise;
    }

    function updateFriendRequestStatus(friendId, friendRequestStatus) {
        let index;
        index = commonService.findInArray(friendId, 'id', friends);
        if (~index) {
            friends[index].friendRequestStatus = friendRequestStatus;
        }
        // Enable/Disable chat based on the friend status
        let canSendMessage = friendRequestStatus === 'A' ? true : false;
        collaborateService.toggleChatBox([friendId], canSendMessage);
    }

    function updateStatus(friendId, friendRequestStatus, methodName) {
        let deferred = $q.defer(),
            updateFriendRequestPromise, payload;

        payload = {
            friendId: friendId,
            status: friendRequestStatus
        };

        updateFriendRequestPromise = $http({
            method: 'POST',
            url: basePath + '' + methodName,
            data: payload
        });

        updateFriendRequestPromise
            .then((response) => {
                updateFriendRequestStatus(friendId, friendRequestStatus);
                deferred.resolve(response);
            })
            .then(() => {
                if (friendRequestStatus == "R") {
                    let removeIndex = friends.map(function (friend) { return friend.id; }).indexOf(friendId);
                    friends.splice(removeIndex, 1);
                    collaborateService.removeChat(friendId);
                }
            })
            .catch((response) => {
                deferred.reject(response);
            });

        return deferred.promise;
    }

    function updateChatStatus(friendId, status) {
        if (friendId === $rootScope.user.id) {
            return userService.onUserStatusUpdate(status);
        }
        let index = commonService.findInArray(friendId, 'id', friends);
        if (~index) {
            friends[index].status = status;
            return true;
        }
        return false;
    }

    webSocketService.socket.on('newfriendRequest', (obj) => {

        if (!obj.id) {
            return false;
        }
        $timeout(() => {
            collaborateService.onlineUsers.push(obj.id);
            commonService.saveInArray(obj.id, 'id', friends, obj);
        }, 0);
    });

    webSocketService.socket.on('userstatus', (obj) => {

        $timeout(() => {
            collaborateService.onlineUsers.push(obj.userId);
            updateChatStatus(obj.userId, obj.status);
        }, 0);
    });

    // webSocketService.socket.on('friendRequestDeclined', (obj) => {
    //     $timeout(() => {
    //         collaborateService.removeChat(obj.userfriendId, 'friend');
    //         notificationService.showPushNotification(obj);

    //     }, 0);
    // });

    webSocketService.socket.on('friendRequestStatusUpdate', (obj) => {
        if (!obj.length || !obj[0].id) {
            return false;
        }

        if (obj[0].acceptorId !== $rootScope.user.id) {
            notificationService.showPushNotification(obj[0]['notificationData']);
        }

        if (obj[0].status == "R") {
            $timeout(() => {
                collaborateService.removeChat(obj[0]['id'], 'friend');
            }, 0);

        }
        if (obj[0].status == "A") {
            const chatIndex = collaborateService.chatsList.map((chat) => chat.friend).indexOf(obj[0]['id']);
            const chatId = collaborateService.chatsList[chatIndex].id;
            obj[0]['notificationData']['forwardLink'] = `/collaborate/chat/${chatId}`;

            let friendId, friendRequestStatus, acceptorId, acceptorStatus;
            friendId = obj[0].userId === $rootScope.user.id ? obj[0].friendId : obj[0].userId;
            friendRequestStatus = obj[0].status;
            acceptorId = obj[0].User.id;
            acceptorStatus = obj[0].User.status;
            $timeout(() => {
                collaborateService.onlineUsers.push(friendId);
                updateFriendRequestStatus(friendId, friendRequestStatus);
                updateChatStatus(acceptorId, acceptorStatus);
            }, 0);
        }
    });

    getAllFriends();
}

export default friendService;
