const angular = window.angular;

groupInfoController.$inject = [
  "$uibModalInstance",
  "chat",
  "$rootScope",
  "collaborateService",
  "fileService",
  "Upload",
  "$state",
];

function groupInfoController(
  $uibModalInstance,
  chat,
  $rootScope,
  collaborateService,
  fileService,
  Upload,
  $state
) {
  /* jshint validthis:true */
  let vm = this;
  vm.chat = chat || {};
  vm.addMember = false;
  vm.searchText = "";
  vm.searchFriends = [];
  vm.currentMember = {};
  vm.checkAdmin = false;
  vm.userRole = "ADMIN";

  vm.modelName = "pandaGroup";

  function close() {
    $uibModalInstance.close();
  }

  function resetSearch() {
    vm.searchText = "";
    collaborateService.friendSearch("").then((friends) => {
      vm.searchFriends = _.map(friends.data, (friend) => {
        friend.oldMessagesView = true;
        return friend;
      });
    });
  }

  function addMemberClick(value) {
    vm.addMember = value;
  }
  function checkRole(member) {
    let memberDetail = _.find(
      vm.chat.userchats,
      (obj) => obj.userId == member.id
    );
    if (memberDetail && memberDetail.role == "ADMIN") {
      return true;
    }
    return false;
  }

  function checkCurrentUser(member) {
    let memberDetail = _.find(
      vm.chat.userchats,
      (obj) => obj.userId == member.id
    );
    if (memberDetail && memberDetail.role == "ADMIN") {
      if (memberDetail.userId == $rootScope.user.id) {
        return false;
      }
      return true;
    }
    return false;
  }

  function changeRole(member, role) {
    if (role == "USER") {
      let userChats = vm.chat.userchats.filter((obj) => obj.role == "ADMIN");

      if (userChats.length <= 1) {
        vm.checkAdmin = true;
        return;
      }
    }

    let memberDetail = _.find(
      vm.chat.userchats,
      (obj) => obj.userId == member.id
    );
    if (memberDetail) {
      collaborateService.changeRole(memberDetail.id, role).then((data) => {
        // if(data){
        // 	let index=vm.chat.userchats.findIndex(obj=>obj.id==data.id);
        // 	if(index!=-1){
        // 		vm.chat.userchats[index]=data;
        // 	}
        // }
      });
    }
  }

  function removeUser(member) {
    let memberDetail = _.find(
      vm.chat.userchats,
      (obj) => obj.userId == member.id
    );
    if (memberDetail) {
      collaborateService.removeUser(memberDetail.id).then((data) => {
        if (data) {
          let index = vm.chat.userchats.findIndex((obj) => obj.id == data.id);
          let userIndex = vm.chat.users.findIndex(
            (obj) => obj.id == data.userId
          );
          if (index != -1) {
            vm.chat.userchats.splice(index, 1);
          }
          if (userIndex != -1) {
            vm.chat.users.splice(userIndex, 1);
          }
        }
      });
    }
  }

  function searchUsers(name) {
    collaborateService.friendSearch(name).then((friends) => {
      vm.searchFriends = _.map(friends.data, (friend) => {
        friend.oldMessagesView = true;
        return friend;
      });
    });
  }
  function closeSearch() {
    vm.searchText = "";
    vm.addMember = false;
  }
  function clearSearch() {}
  function addGroupMemberClick(member) {
    let userId = member.id;
    let chatId = vm.chat.id;
    collaborateService
      .addGroupMember(member, chatId, !member.oldMessagesView)
      .then((data) => {
        // vm.chat.userchats.push(data);
        // vm.chat.users.push(member);
      });
  }

  function checkMemberStatus(member) {
    let findIndex = vm.chat.userchats.findIndex(
      (obj) => obj.userId === member.id
    );
    if (findIndex != -1) {
      return false;
    }
    return true;
  }

  function init() {
    if (vm.chat.userchats) {
      let userRoleIndex = vm.chat.userchats.findIndex(
        (obj) => obj.userId == $rootScope.user.id
      );
      if (userRoleIndex !== -1) {
        let userRole = vm.chat.userchats[userRoleIndex];
        vm.userRole = userRole.role;
        if (!vm.userRole) {
          vm.userRole = "ADMIN";
        }
      }
    }
  }

  function selectGroupImage(media) {
    Upload.base64DataUrl(media).then(function (urls) {
      var uploadFile = Upload.dataUrltoBlob(urls, media.name);
      fileService
        .uploadFile(uploadFile, vm.chat.id, "groupchat")
        .then((res) => {
          if (res.status === 200) {
            res.data.data.forEach((item, n) => {
              if (item.entityId == vm.chat.id) {
                vm.chat.mediaUrl = item.mediaUrl;
                collaborateService.updateGroupMedia(vm.chat.id, item.mediaUrl);
              }
            });
          }
        })
        .catch((err) => {});
    });
  }

  function goToProfile(id) {
    $state.go("feedUserView", {
      userId: id,
    });
    $uibModalInstance.close();
  }

  this.$onInit = init;

  angular.extend(vm, {
    close,
    checkRole,
    changeRole,
    removeUser,
    addMemberClick,
    searchUsers,
    closeSearch,
    clearSearch,
    addGroupMemberClick,
    checkMemberStatus,
    selectGroupImage,
    goToProfile,
    checkCurrentUser,
    resetSearch,
  });
}
export default groupInfoController;
