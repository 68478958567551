let angular = window.angular;

lessonAssignModalController.$inject = [
  "$uibModalInstance",
  "lessonService",
  "studentService",
  "data",
  "lessonConstants",
  "commonService",
  "toastService",
  "$rootScope",
  "_",
  "appConstants",
  "$http",
  "$q",
];

function lessonAssignModalController(
  $uibModalInstance,
  lessonService,
  studentService,
  data,
  lessonConstants,
  commonService,
  toastService,
  $rootScope,
  _,
  appConstants,
  $http,
  $q
) {
  /* jshint validthis:true */
  let vm = this;
  vm.isFetchingData = true;
  vm.lesson = {};
  vm.assignmentDays = [];
  // $scope.assignmentForm = {};

  // vm.assigningStudentId = 0;
  vm.isMaximumDaysSelected = false;
  vm.isFormInvalid = false;
  vm.formErrorMessage = "";
  vm.assignmentEndDate = null;
  vm.studentLessons = [];
  vm.conlfictedAssignments = [];
  vm.lessonLimit = 3;

  vm.allDayLesson = false;
  vm.days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  vm.student = {};
  vm.colors = appConstants.newColors;

  angular.extend(vm, {
    close,
    assignLesson,
    onSelectDay,
    showEndDate,
    validateAssignForm,
    resetConflicts,
    increaseLessonLimit,
    setStudentLabelColor,
  });

  function setStudentLabelColor(color) {
    if (color) {
      vm.student.color = color;
    }
  }
  function getLessonByStudentId(studentId) {
    const query = `query GetLessonByStudent($studentId:Int!) {
  getLessonByStudent(studentId:$studentId){
    id
    userId
    name
    color
    description
    isVisible
    lesson {
      id
      startDate
      endDate
      assignmentDays {
        name
      }
      studentId
      course {
        id
        name
        color
        description
        userId
        isVisible
      }
      lessonId
      days
      weeks
      duration
      name
      color
      isGroup
    }
  }
}`;
    let defer = $q.defer();
    let request = {
      method: "POST",
      url: "http://localhost:4000/graphql",
      data: JSON.stringify({
        query,
        variables: { studentId: studentId },
      }),
    };

    $http(request)
      .then((response) => {
        let res = [];

        if (response.data.data.getLessonByStudent.length) {
          const { getLessonByStudent } = response.data.data;
          getLessonByStudent.forEach((course) => {
            let mappedLessons = course.lesson.map((el) => {
              return {
                ...el,
                assignmentDays: el.assignmentDays.map((el) => el.name),
              };
            });
            res.push({
              ...course,
              lesson: mappedLessons,
            });
          });
        }
        return defer.resolve(res);
      })
      .catch((err) => defer.reject(err));
    return defer.promise;
  }
  function init() {
    if (!data.lesson || !data.studentId) {
      console.error("No params passed to modal");
      close();
    }
    // getLessonByStudentId(data.studentId)
    //   .then((res) => console.log("graphql", res))
    //   .catch((err) => console.log(err));
    resetConflicts();

    vm.lesson = data.lesson;

    if (vm.lesson.assignmentDays) {
      console.log("vm.lesson.assignmentDays", vm.lesson.assignmentDays);
      vm.assignmentDays = vm.lesson.assignmentDays;
    }

    /**
     * Check the duration, if it is 23:59 then it is all day event
     */
    if (vm.lesson.duration == "23:59") {
      vm.allDayLesson = true;
      vm.assignmentTime = "00:00";
    }

    return studentService
      .getStudentDetail(data.studentId)
      .then((response) => {
        if (!response) {
          console.error("No student found");

          close();
        }

        vm.student = response;
        if (!vm.student.color) {
          vm.student.color = lessonConstants.color;
        }

        return lessonService.getLessonByStudentId(vm.student.id);
      })
      .then((response) => {
        _.each(response, (course) => {
          let courseData = _.pick(course, ["name", "id", "userId"]);

          _.each(course.lesson, (lesson) => {
            if (moment(lesson.endDate, "Y-MM-DD").isBefore(moment())) {
              return;
            }

            if (moment(lesson.startTime, "HH:mm").isValid()) {
              lesson.startTime = commonService.convertFromUTC(
                lesson.startDate + " " + lesson.startTime + ":00"
              );
              lesson.endTime = moment(lesson.startTime)
                .add(lesson.duration.split(":")[0], "hour")
                .add(lesson.duration.split(":")[1], "minute");
            }

            let lessonData = _.assign(_.clone(lesson), { course: courseData });
            vm.studentLessons.push(lessonData);
          });
        });

        vm.studentLessons = _.sortBy(
          vm.studentLessons,
          function (studentLesson) {
            return (
              moment(studentLesson.startDate).format("Y-MM-DD") +
              " " +
              moment(studentLesson.startTime).format("HH:mm:ss")
            );
          }
        );
        vm.isFetchingData = false;
      });
  }

  function close() {
    $uibModalInstance.dismiss();
  }

  function increaseLessonLimit() {
    vm.lessonLimit += 3;
  }

  function resetConflicts() {
    vm.showConflictedAssignments = false;
    vm.showLoadingConflictedAssignments = false;
    vm.showStudentAvailable = false;
  }

  function validateAssignForm(check) {
    if (!check && !vm.isFormInvalid) {
      return;
    }

    if (!vm.assignmentDate) {
      vm.isFormInvalid = true;
      vm.formErrorMessage = lessonConstants.messages.assignErrorStartDate;
      return false;
    }

    if (!vm.assignmentTime) {
      if (vm.allDayLesson && vm.lesson.duration == "23:59") {
        //it is all day event to start time should be 12:00AM
      } else {
        vm.isFormInvalid = true;
        vm.formErrorMessage = lessonConstants.messages.assignErrorStartTime;
        return false;
      }
    }

    if (vm.assignmentDays.length !== vm.lesson.days) {
      vm.isFormInvalid = true;
      vm.formErrorMessage = commonService.renderTemplateString(
        lessonConstants.messages.assignErrorDays,
        { days: vm.lesson.days }
      );
      return false;
    }

    vm.isFormInvalid = false;
    vm.formErrorMessage = "";
    return true;
  }

  function assignLesson() {
    let formValidated = validateAssignForm(true);

    if (!formValidated) {
      return;
    }

    $rootScope.loadingBtn("assign", "Assigning");

    let data = {},
      dateTime;
    data.studentId = vm.student.id;
    data.lessonId = vm.lesson.id;
    data.assignmentDays = vm.assignmentDays;
    data.startDate = vm.assignmentDate;
    data.color = vm.student.color;

    /**
     * we count end date from the backend
     */
    // data.endDate = vm.assignmentEndDate;

    /**
     * IF this is all day lesson then we set start date on 12:00AM
     */
    if (vm.allDayLesson && vm.lesson.duration == "23:59") {
      vm.assignmentTime = "00:00";
    }
    dateTime = commonService.convertToUTC(
      vm.assignmentDate + " " + vm.assignmentTime
    );
    data.utcStartDateTime = dateTime.format("Y-MM-DD HH:mm:00");

    let lessonStartDurationPromise = Promise.resolve(true);

    if (vm.lesson.isGroup) {
      vm.lesson.startDate = data.utcStartDateTime;
      vm.lesson.assignmentDays = vm.assignmentDays;
      vm.lesson.startTime = vm.assignmentTime; //dateTime == null ? dateTime : dateTime.format("HH:mm");
      lessonStartDurationPromise = lessonService.addLessonTime(vm.lesson);
    }
    lessonStartDurationPromise
      .then((lessonUpdate) => {
        lessonService.assignStudent(vm.lesson, data);
      })
      .then((response) => {
        toastService.toast({
          message: lessonConstants.messages.assignedSuccessfuly,
          type: "success",
          delay: 3000,
        });

        $rootScope.loadingBtnComplete("assign", "Assign");
        $uibModalInstance.close(vm.student.id, response);
      })
      .catch((response) => {
        toastService.toast({
          message: response.data.message,
          type: "error",
          delay: 3000,
        });
      });
  }

  function onSelectDay() {
    vm.isMaximumDaysSelected = vm.assignmentDays.length >= vm.lesson.days;
    showEndDate();
  }

  function showEndDate() {
    validateAssignForm();
    if (!vm.isMaximumDaysSelected || !vm.assignmentDate || !vm.assignmentTime) {
      vm.assignmentEndDate = null;
      return;
    }

    vm.showConflictedAssignments = false;
    vm.showStudentAvailable = false;
    vm.conflictedAssignments = [];
    vm.showLoadingConflictedAssignments = true;

    vm.assignmentEndDate = lessonService.getEndDateOfLesson(
      vm.assignmentDays,
      vm.assignmentDate,
      vm.lesson.weeks,
      vm.lesson.days,
      vm.assignmentTime,
      vm.lesson.duration
    );

    if (!vm.allDayLesson) {
      return lessonService
        .getStudentAvailability(
          vm.assignmentDays,
          vm.assignmentDate,
          vm.lesson.weeks,
          vm.lesson.days,
          vm.assignmentTime,
          vm.lesson.duration,
          vm.student.id
        )
        .then(getStudentAvailabilitySuccess);
    } else {
      return;
    }
  }

  function getStudentAvailabilitySuccess(response) {
    vm.showLoadingConflictedAssignments = false;

    if (!response.data.data || !response.data.data.length) {
      vm.showStudentAvailable = true;
      return;
    } else {
      vm.showConflictedAssignments = true;

      vm.conflictedAssignments = _.map(response.data.data, (assignment) => {
        assignment.startDate = commonService.convertFromUTC(
          assignment.startDate
        );
        assignment.startTime = moment(assignment.startDate).format("hh:mm a");
        assignment.startDate = moment(assignment.startDate).format("Y-MM-DD");

        assignment.endDate = commonService.convertFromUTC(assignment.endDate);
        assignment.endTime = moment(assignment.endDate).format("hh:mm a");
        assignment.endDate = moment(assignment.endDate).format("Y-MM-DD");

        return assignment;
      });
    }
  }

  this.$onInit = init;
}
export default lessonAssignModalController;
