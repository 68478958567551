let angular = window.angular;

hspInfoModalController.$inject = ["$uibModalInstance", "infoParam"];

function hspInfoModalController($uibModalInstance, infoParam) {
  let vm = this;

  vm.title = "Share Feed";
  vm.data = null;
  angular.extend(this, {
    close,
  });
  function close() {
    $uibModalInstance.close(true);
  }

  function init() {
    if (infoParam) {
      vm.data = infoParam.data;
    }
  }

  this.$onInit = init;
}
export default hspInfoModalController;
