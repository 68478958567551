export const calendarConstants = {
  defaultStartTime: "10:00",
  defaultEndTime: "11:00",
  defaultDateTimeSavingsEnd: "2018-11-04",
  assignmentType: {
    all: "all",
    incomplete: "incomplete",
  },
  eventDisplayLimit: 3,
  color: {
    studentAssignment: "#01579B",
    appointment: "#65696f",
  },
  defaultMaxOccurrences: 10,
  deviceType: "BROWSER",
  reminderOptions: [
    { id: 1, title: "1 min", value: 1, unit: "minute" },
    { id: 2, title: "15 mins", value: 15, unit: "minute" },
    { id: 3, title: "30 mins", value: 30, unit: "minute" },
    { id: 4, title: "1 hr", value: 1, unit: "hour" },
    { id: 5, title: "6 hrs", value: 6, unit: "hour" },
    { id: 6, title: "12 hrs", value: 12, unit: "hour" },
    { id: 7, title: "1 day", value: 1, unit: "day" },
    { id: 8, title: "2 days", value: 2, unit: "day" },
    { id: 9, title: "1 week", value: 1, unit: "week" },
    { id: 10, title: "2 weeks", value: 2, unit: "week" },
  ],
  recurrenceOptions: [
    { id: 1, title: "Daily", type: "day", shorthand: false },
    { id: 2, title: "Weekly", type: "week", shorthand: false },
    {
      id: 3,
      title: "Bi-weekly",
      type: "week",
      shorthand: true,
      separationCount: 1,
    },
    { id: 4, title: "Monthly", type: "month", shorthand: false },
    {
      id: 5,
      title: "Quaterly",
      type: "month",
      shorthand: true,
      separationCount: 3,
    },
    {
      id: 6,
      title: "Half-yearly",
      type: "month",
      shorthand: true,
      separationCount: 5,
    },
    { id: 7, title: "Yearly", type: "year", shorthand: false },
    // {id: 8, type: "week", shorthand: true, separationCount: 0, dynamic: true},
    {
      id: 9,
      type: "month",
      shorthand: true,
      separationCount: 0,
      dynamic: true,
    },
  ],
  printOptions: [
    {
      id: 1,
      title: "Previous 3 months",
      prev: 3,
      next: -1,
      unit: "month",
      reference: "dynamic",
    },
    {
      id: 2,
      title: "Previous month",
      prev: 1,
      next: -1,
      unit: "month",
      reference: "dynamic",
    },
    {
      id: 3,
      title: "Previous week",
      prev: 1,
      next: -1,
      unit: "week",
      reference: "dynamic",
    },
    {
      id: 4,
      title: "This month",
      prev: 0,
      next: 0,
      unit: "month",
      reference: "dynamic",
    },
    {
      id: 5,
      title: "This week",
      prev: 0,
      next: 0,
      unit: "week",
      reference: "dynamic",
    },
    {
      id: 6,
      title: "Today",
      prev: 0,
      next: 0,
      unit: "day",
      reference: "dynamic",
    },
    {
      id: 7,
      title: "Tomorrow",
      prev: -1,
      next: 1,
      unit: "day",
      reference: "dynamic",
    },
    {
      id: 8,
      title: "Next week",
      prev: -1,
      next: 1,
      unit: "week",
      reference: "dynamic",
    },
    {
      id: 9,
      title: "Next 2 weeks",
      prev: -1,
      next: 2,
      unit: "week",
      reference: "dynamic",
    },
    {
      id: 10,
      title: "Next month",
      prev: -1,
      next: 1,
      unit: "month",
      reference: "dynamic",
    },
    {
      id: 11,
      title: "Next 3 months",
      prev: -1,
      next: 3,
      unit: "month",
      reference: "dynamic",
    },
    { id: 12, title: "Custom", reference: "custom" },
  ],
  messages: {
    changeAppointmentDate: "Are you sure you want to change the event date?",
    changeAssignmentDate:
      "Are you sure you want to change the assignment date?",
    eventCreated: "Event created",
    vacationCreated: "Days off created",
    vacationUpdated: "Days off updated",
    assignmentUpdated: "Assignment updated",
    assignmentNotUpdated: "Assignment not updated",
    assignmentEndDateError: "Assignment end date must be after start date",
    eventUpdated: "Event updated",
    eventNotUpdated: "Event not updated",
    removeSuccess: "Event deleted successfully",
    eventEndDateError: "Event end date must be after start date",
    leavePageConfirmation:
      "The changes you made will be lost if you leave this page.\nContinue with unsaved changes?",
    selectEntityToPrint:
      "Any of Event or Assignment option to be selected for print",
    deleteConfirmation: "Are you sure you want to delete",
    studentNotAddedToLesson:
      "Student is not part of the lesson. If you want to assign student then add student to lesson first, then try again.",
    assignmentStudentNotAdded: "Assign at least one student to the assignment",
    bookStudentNotAssigned: "Assign at least one student to the book",
    addToCalendarNotChecked:
      "Please select 'Add to calendar' if you want to assign this book to an assignment",
    searchCalendar: {
      searchQueryEmpty: "Please enter keywords to find the lesson or student",
      searchResultEmpty: "No result found",
    },
    deleteResource: "Resource successfully deleted",
    deleteBookConfirmation: "Are you sure you want to delete this book?",
    addVacationTitle: "Add Title for the Days Off",
    addStudents: "Add Students",
    deleteVacationConfirmation: "Are you sure you want to delete days off?",
    calendarDataUpdate: "Calendar data updating..",
    assignmentComplete: "Assignment marked as complete",
    assignmentNotStarted: "Assignment marked as not started",
    showIncompleteAssignment: "Listing only incomplete assignments",
    showAllAssignment: "Listing all the assignments",
  },
  calendarDayElementClassName: "fc-day",
  calendarModel: "calendar",
};
