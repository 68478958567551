
export default function customAutofocus() {
    return {
        restrict: "A",

        link: function (scope, element, attrs) {
            scope.$watch(
                function () {
                    return scope.$eval(attrs.customAutofocus);
                },
                function (newValue) {
                    if (newValue == true) {
                        element[0].focus();
                    }
                }
            );
        },
    };
}
