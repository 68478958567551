format.$inject = ["$sce"];
function format($sce) {
  const toString = Object.prototype.toString,
    urlRegex =
      /\b(?:(https?|ftp|file):\/\/|www\.)[-A-Z0-9+()&@$#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|]/gi,
    protocolRegex = /^[a-z]+\:\/\//i,
    map = { "&": "&amp;", ">": "&gt;", "<": "&lt;" };

  function urlHighlight(input) {
    function urlify(text) {
      return text.replace(urlRegex, function (url) {
        let httpUrl = url;
        if (!protocolRegex.test(url)) {
          httpUrl = "http://" + url;
        }
        return (
          '<a class="link" href="' +
          httpUrl +
          '" target="_blank">' +
          url +
          "</a>"
        );
      });
    }
    return urlify(input);
  }

  function unEscapseString(input) {
    if (!input.replace) {
      input = input.toString();
    }

    input = input.replace(/[&<>]/g, function (m) {
      return map[m];
    });

    input = input.replace(/\r?\n/g, "<br/>");

    let stringArray = input.split("");
    for (let i = stringArray.length - 1; ~i; i--) {
      if (stringArray[i] === " " && stringArray[i - 1] === " ") {
        stringArray[i] = "&nbsp;";
      }
    }
    input = stringArray.join("");
    return input;
  }

  return function (input) {
    if (!input || toString.call(input) !== "[object String]") {
      return input;
    }
    input = urlHighlight(unEscapseString(input));
    return $sce.trustAsHtml(input);
  };
}
export default format;
