let angular = window.angular;

studentService.$inject = [
  "$http",
  "$q",
  "commonService",
  "_",
  "appConstants",
  "moment",
];

function studentService($http, $q, commonService, _, appConstants, moment) {
  let self = this;
  let basePath = appConstants.baseUrl + "/api/students/";
  let initStudentsLoaded;

  this.students = [];
  this.academics = [];

  angular.extend(this, {
    removeAcademicDetails,
    removeStudent,
    saveStudent,
    getStudentsById,
    getStudents,
    getHelperDetail,
    getStudentDetail,
    addStudent,
    addUser,
    editStudent,
    editUser,
    deleteStudent,
    deleteUser,
    getLessons,
    removeAssignment,
    getAllLessonAssignment,
    loginStatusChange,
    userLoginStatusChange,
    addStudentAcademics,
    updateStudentAcademics,
    deleteStudentAcademics,
    permissionChange,
    studentPermission,
    validateSemesterDates,
    validateSemesterDatesIndex,
  });

  function validateSemesterDatesIndex(data) {
    const academics = angular.copy(data);
    let condition = true;
    if (!academics.semesters) {
      return { condition };
    }

    let errorIndex;
    let errorOverlapping;
    const semesters = academics.semesters;
    semesters.forEach((semester, index) => {
      let checkSemestorAcademicStart = moment(semester.semesterStart).isBefore(
        moment(academics.academicYearStart)
      );
      let checkSemestorAcademicEnd = moment(semester.semesterEnd).isAfter(
        moment(academics.academicYearEnd)
      );
      let checkSemestorOverlapStart = moment(semester.semesterStart).isAfter(
        moment(semester.semesterEnd)
      );
      let checkSemestorOverlapEnd = moment(semester.semesterEnd).isBefore(
        moment(semester.semesterStart)
      );

      let checkOverlaps = semesters.find((obj, findIndex) => {
        if (index != findIndex) {
          let semestorStart = moment(obj.semesterStart).isSameOrAfter(
            moment(semester.semesterStart)
          );
          let semestorEnd = moment(obj.semesterEnd).isSameOrBefore(
            moment(semester.semesterEnd)
          );
          let semestorStartBetween = moment(semester.semesterStart).isBetween(
            moment(obj.semesterStart),
            moment(obj.semesterEnd)
          );
          let semestorEndBetween = moment(semester.semesterEnd).isBetween(
            moment(obj.semesterStart),
            moment(obj.semesterEnd)
          );
          if (
            (semestorStart && semestorEnd) ||
            semestorStartBetween ||
            semestorEndBetween
          ) {
            return true;
          }
        }
      });

      if (
        checkOverlaps ||
        checkSemestorAcademicStart ||
        checkSemestorAcademicEnd ||
        checkSemestorOverlapStart ||
        checkSemestorOverlapEnd
      ) {
        errorIndex = index;
        condition = false;
        if (checkOverlaps) {
          errorOverlapping = true;
        }
      }
    });
    return { condition, errorIndex, errorOverlapping };
  }
  function validateSemesterDates(data) {
    const academics = angular.copy(data);
    let condition = true;
    if (!academics.semesters) {
      return condition;
    }

    const semesters = academics.semesters;
    semesters.forEach((semester, index) => {
      let checkSemestorAcademicStart = moment(semester.semesterStart).isBefore(
        moment(academics.academicYearStart)
      );
      let checkSemestorAcademicEnd = moment(semester.semesterEnd).isAfter(
        moment(academics.academicYearEnd)
      );
      let checkSemestorOverlapStart = moment(semester.semesterStart).isAfter(
        moment(semester.semesterEnd)
      );
      let checkSemestorOverlapEnd = moment(semester.semesterEnd).isBefore(
        moment(semester.semesterStart)
      );

      let checkOverlaps = semesters.find((obj, findIndex) => {
        if (index != findIndex) {
          let semestorStart = moment(obj.semesterStart).isSameOrAfter(
            moment(semester.semesterStart)
          );
          let semestorEnd = moment(obj.semesterEnd).isSameOrBefore(
            moment(semester.semesterEnd)
          );
          let semestorStartBetween = moment(semester.semesterStart).isBetween(
            moment(obj.semesterStart),
            moment(obj.semesterEnd)
          );
          let semestorEndBetween = moment(semester.semesterEnd).isBetween(
            moment(obj.semesterStart),
            moment(obj.semesterEnd)
          );
          if (
            (semestorStart && semestorEnd) ||
            semestorStartBetween ||
            semestorEndBetween
          ) {
            return true;
          }
        }
      });
      if (
        checkSemestorAcademicStart ||
        checkSemestorAcademicEnd ||
        checkSemestorOverlapStart ||
        checkSemestorOverlapEnd ||
        checkOverlaps
      ) {
        condition = false;
      }
    });
    // semesters.forEach((semester, index) => {
    //   if (
    //     moment(semester.semesterStart).isAfter(moment(semester.semesterEnd))
    //     || moment(semester.semesterStart).isBefore(moment(academics.academicYearStart)) || moment(semester.semesterStart).isAfter(
    //       moment(academics.academicYearEnd)
    //     ) ||
    //     moment(semester.semesterEnd).isAfter(moment(academics.academicYearEnd))
    //   ) {
    //     condition = false;
    //   }
    // });

    return condition;
  }

  function removeAcademicDetails(studentId, acadId) {
    let deferred = $q.defer(),
      deleteAcademicPromise;

    deleteAcademicPromise = $http.delete(
      basePath +
        "removeAcademic/" +
        "?studentId=" +
        studentId +
        "&academicId=" +
        acadId
    );

    deleteAcademicPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function removeStudent(studentId) {
    return commonService.removeFromArray(studentId, "id", self.students);
  }

  function saveStudent(studentId, student) {
    return commonService.saveInArray(studentId, "id", self.students, student);
  }

  function getStudentsById(studentIds) {
    let i,
      index,
      studentList = [];
    studentIds = angular.isArray(studentIds) ? studentIds : [studentIds];
    for (i = studentIds.length - 1; ~i; --i) {
      index = commonService.findInArray(studentIds[i], "id", self.students);
      if (~index) {
        studentList.push(self.students[index]);
      }
    }
    return studentList;
  }

  function getStudents() {
    let deferred = $q.defer(),
      getStudentsPromise;

    getStudentsPromise = $http.get(basePath + "index");

    getStudentsPromise
      .then((response) => {
        self.students.splice(0);
        Array.prototype.push.apply(self.students, response.data.data);
        deferred.resolve(self.students);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function getStudentsNew() {
    let deferred = $q.defer(),
      getStudentsPromise;

    getStudentsPromise = $http.get(basePath + "index");

    getStudentsPromise
      .then((response) => {
        self.students.splice(0);
        Array.prototype.push.apply(self.students, response.data.data);
        deferred.resolve(self.students);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    const token = localStorage.getItem("authorizationToken");
    const userData = JSON.parse(localStorage.getItem("userData"));
    const data = { userId: userData.id };
    // Starting from here
    var reqData = JSON.stringify({
      query: `query GetAllStudents($userId: Int!) {
                    getAllStudents(userId: $userId) {
                      name
                      firstName
                      lastName
                      id
                      dob
                      gender
                      userId
                      studentUserId
                      school
                      email
                    }
                }`,
      variables: data,
    });
    let secondSetStudents = $http({
      method: "POST",
      url: appConstants.graphQLUrl,
      data: reqData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authorizationToken")}`,
      },
    });
    secondSetStudents
      .then((response) => {
        self.students.splice(0);
        Array.prototype.push.apply(self.students, response.data.data);
        deferred.resolve(self.students);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });
    // ends till here

    return deferred.promise;
  }

  function getHelperDetail(studentId) {
    let deferred = $q.defer(),
      url;

    url = basePath + "userView/" + studentId;
    url += "?timezone=" + new Date().getTimezoneOffset();

    $http
      .get(url)
      .then((response) => {
        let student = response.data.data.Student;
        deferred.resolve(student);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function getStudentDetail(studentId) {
    let deferred = $q.defer(),
      url;

    url = basePath + "view/" + studentId;
    url += "?timezone=" + new Date().getTimezoneOffset();

    $http
      .get(url)
      .then((response) => {
        let student = response.data.data.Student;
        deferred.resolve(student);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function loginStatusChange(student) {
    let deferred = $q.defer();
    let url = basePath + "loginStatusChange";
    let data = {};
    data.student = student;

    $http
      .post(url, data)
      .then((response) => {
        let student = response.data;
        deferred.resolve(student);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }
  function userLoginStatusChange(student) {
    let deferred = $q.defer();
    let url = basePath + "userLoginStatusChange";
    let data = {};
    data.student = student;

    $http
      .post(url, data)
      .then((response) => {
        let student = response.data;
        deferred.resolve(student);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }
  function addUser(student) {
    let url = basePath + "addHelper";
    let data = {};
    data.student = student;
    return $http.post(url, data);
  }

  function addStudent(student) {
    let url = basePath + "add";
    let data = {};
    data.student = student;
    data.student.academicYear = "data";
    return $http.post(url, data);
  }

  function addStudentAcademics(academics) {
    let url = basePath + "addAcademics";
    let data = {};
    data.academics = academics;
    return $http.post(url, data);
  }

  function updateStudentAcademics(academics) {
    let url = basePath + "editAcademics";
    let data = {};
    data.academics = academics;
    return $http.post(url, data);
  }

  function deleteStudentAcademics(academics) {
    let url = basePath + "deleteAcademics";
    let data = {};
    data.academics = academics;
    return $http.post(url, data);
  }

  function editStudent(student) {
    let url = basePath + "edit/" + student.id;
    let data = {};
    data.student = student;
    return $http.post(url, data);
  }
  function editUser(user) {
    let url = basePath + "editUser/" + user.id;
    let data = {};
    data.user = user;
    return $http.post(url, data);
  }

  function deleteStudent(studentId) {
    let deferred = $q.defer(),
      deleteStudentPromise;

    deleteStudentPromise = $http.delete(basePath + "remove/" + studentId);

    deleteStudentPromise
      .then((response) => {
        commonService.removeFromArray(studentId, "id", self.students);
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function deleteUser(studentId) {
    let deferred = $q.defer(),
      deleteStudentPromise;

    deleteStudentPromise = $http.delete(basePath + "removeUser/" + studentId);

    deleteStudentPromise
      .then((response) => {
        commonService.removeFromArray(studentId, "id", self.students);
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function getLessons(studentId, startDate, endDate) {
    let deferred = $q.defer(),
      getLessonPromise,
      query = "",
      url;

    if (!studentId) {
      deferred.reject();
    } else {
      query = basePath + "lessons/getLessons/?studentId=" + studentId;

      if (startDate && endDate) {
        query += "&startDate=" + startDate + "&endDate=" + endDate;
      }

      getLessonPromise = $http.get(query);

      getLessonPromise
        .then((response) => {
          deferred.resolve(response.data.data);
        })
        .catch((response) => {
          deferred.reject(response.data);
        });
    }

    return deferred.promise;
  }

  function removeAssignment(lessonId, option, reqDate) {
    let deferred = $q.defer(),
      removeLessonPromise;

    if (!reqDate) {
      reqDate = moment().format("YYYY-MM-DD");
    }

    // removeLessonPromise = $http.post('/api/students/lessons/unassign/' + '?id=' + lessonId + '&change=' + option + '&reqDate=' + reqDate);
    removeLessonPromise = $http({
      method: "POST",
      url:
        appConstants.baseUrl +
        "/api/students/lessons/unassign/" +
        "?change=" +
        option +
        "&reqDate=" +
        reqDate,
      data: { id: lessonId },
    });
    removeLessonPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function getAllLessonAssignment() {
    let deferred = $q.defer(),
      getStudentLessonPromise,
      lessonUrl = basePath + "lessons/getAllLessons";

    getStudentLessonPromise = $http({
      method: "GET",
      url: lessonUrl,
    });

    getStudentLessonPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.resolve(response.data);
      });

    return deferred.promise;
  }

  function permissionChange(student, permissionType) {
    let deferred = $q.defer();
    let url = basePath + "updateStudentPermission";
    let data = {};

    data.userId = student.userId;
    data.studentId = student.id;
    // data.edit = permissionType === 'assignment' ? student.editAssignment : student.editEvent;
    if (permissionType === "assignment") {
      data.edit = student.editAssignment;
    }
    if (permissionType === "event") {
      data.edit = student.editEvent;
    }
    if (permissionType === "grade") {
      data.edit = student.editGrade;
    }
    data.delete = 0;
    data.permissionType = permissionType;
    data.studentUserId = student.studentUserId;

    $http
      .post(url, data)
      .then((response) => {
        let student = response.data;
        deferred.resolve(student);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }
  function studentPermission(id, data) {
    let deferred = $q.defer();
    let url = basePath + "updateStudentSearchPermission/" + id;

    $http
      .post(url, data)
      .then((response) => {
        let student = response.data;
        deferred.resolve(student);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }
}

export default studentService;
