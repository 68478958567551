/**
 * $http Interceptors
 */

export const httpInterceptor = ($httpProvider) => {
  $httpProvider.interceptors.push("authotizationInterceptor");
};
httpInterceptor.$inject = ["$httpProvider"];

export const authotizationInterceptor = (Store, $timeout, $q, $window) => {
  // let responseInterceptor = {
  //
  // 	responseError: function (response) {
  // 		if (response.status && response.statusText) {
  // 			if (response.status === 401 && response.statusText === 'Unauthorized') {
  // 				$timeout(function () {
  // 					Store.clearAll();
  // 					let pathname = window.location.pathname;
  // 					let redirect_url = (pathname === '/' || pathname === '/index') ? '/login' : '/login?redirect_url=' + pathname;
  // 					if(response.data.error && response.data.error.studentPaymentMissing === true){
  // 						redirect_url += "&missingPayment=true";
  // 					}
  // 					window.location = redirect_url;
  // 				}, 0);
  // 			}
  // 		}
  // 		return $q.reject(null);
  // 	}
  // };
  // return responseInterceptor;

  return {
    request: function (config) {
      return config;
    },

    requestError: function (config) {
      return config;
    },

    response: function (res) {
      return res;
    },

    responseError: function (response) {
      if (response.status && response.statusText) {
        if (response.status === 401 && response.statusText === "Unauthorized") {
          Store.clearAll();
          let pathname = window.location.pathname;
          let redirect_url =
            pathname === "/" || pathname === "/index"
              ? "/login"
              : "/login?redirect_url=" + pathname;
          if (
            response.data.error &&
            response.data.error.studentPaymentMissing === true
          ) {
            redirect_url += "&missingPayment=true";
          }
          $window.location.href = redirect_url;
        }

        return $q.reject(response);
      }
      return response;
    },
  };
};
authotizationInterceptor.$inject = ["Store", "$timeout", "$q", "$window"];
