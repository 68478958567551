export const hspAutofocus = ($timeout) => {
  function link(scope, element, attrs) {
    $timeout(function () {
      element[0].focus();
    });
  }
  return {
    link: link,
    restrict: "A",
  };
};
hspAutofocus.$inject = ["$timeout"];
