const angular = window.angular;
rescheduleAssignmentController.$inject = [
  "$uibModalInstance",
  "assignmentService",
  "$state",
  "studentService",
  "commonService",
  "lessonConstants",
  "appConstants",
  "_",
  "$uibModal",
  "calendarService",
  "$rootScope",
  "toastService",
  "moment",
  "lessonService",
];

function rescheduleAssignmentController(
  $uibModalInstance,
  assignmentService,
  $state,
  studentService,
  commonService,
  lessonConstants,
  appConstants,
  _,
  $uibModal,
  calendarService,
  $rootScope,
  toastService,
  moment,
  lessonService
) {
  let vm = this,
    returnData = {};
  vm.statuses = lessonConstants.statuses;
  vm.modalTitle = "Assignment Rescheduler";
  //vm.status = "not started";
  vm.assignmentSearchedPerformed = false;
  vm.checkAllAssignments = false;
  vm.checkedAssignmentIds = [];
  vm.lesson = [];
  vm.assignmentData = [];
  vm.isFetchingAssignmentData = false;
  vm.searchedStudentId = null;
  vm.searchedLessonId = null;
  vm.searchedStartDate = null;
  vm.searchedEndDate = null;
  //vm.errorMessage = "";

  vm.searchData = {
    studentId: "",
    lessonId: "",
    startDate: vm.startDate,
    endDate: vm.endDate,
    skip: 0,
  };
  vm.searchDataOverDue = {
    studentId: "",
    lessonId: "",
    skip: 0,
  };

  vm.searchedOverdueStudentId = null;
  vm.searchedOverdueLessonId = null;
  vm.isStateChanged = false;
  vm.groupAssignmentCollection = [];

  angular.extend(vm, {
    close,
    checkAll,
    setModelTitle,
    viewAssignment,
    getStatusClass,
    selectStatus,
    selectStatusAllAssignments,
    loadMoreAssignments,
    loadMoreSearchAllAssignments,
    rescheduleDate,
    editAssignment,
    deleteAssignment,
    confirmReschedule,
    removeHTMLTags,
    search,
    searchAssignments,
  });

  function getStatusClass(status) {
    return _.camelCase(status);
  }

  function checkAll() {
    vm.isStateChanged = true;
    _.map(vm.assignmentData, function (assignment) {
      assignment.isChecked = vm.checkAllAssignments;
      return assignment;
    });
  }

  function setModelTitle() {
    vm.isStateChanged = true;
    vm.checkAllAssignments = false;
    vm.isStateChanged = true;
    vm.modalTitle =
      vm.modalTitle === "Assignment Rescheduler"
        ? "Assignment Search"
        : "Assignment Rescheduler";
    vm.searchData.startDate = vm.searchData.startDate
      ? undefined
      : vm.searchData.startDate;
    vm.searchData.endDate = vm.searchData.endDate
      ? undefined
      : vm.searchData.endDate;
    vm.loadMore = false;

    if (vm.modalTitle === "Assignment Search") {
      vm.assignmentData = [];
      vm.searchData = {
        studentId: "",
        lessonId: "",
        startDate: null,
        endDate: null,
        skip: 0,
      };
      vm.searchDataOverDue = {
        studentId: "",
        lessonId: "",
      };
    } else if (vm.modalTitle === "Assignment Rescheduler") {
      vm.assignmentData = [];
      let query = { skip: 0 };
      getIncompleteAssignmentDetails(query);
    }
  }

  function changeStatusColorAllAssignments(status, oldStatus) {
    let element = document.getElementById(`studentAssignmentStatusAll`);
    if (angular.isDefined(oldStatus)) {
      element.classList.remove(_.camelCase(oldStatus));
      element.classList.add(_.camelCase(status));
    } else {
      element.classList.add(_.camelCase(status));
    }
  }

  function confirmReschedule() {
    // selectStatusAllAssignments();
    vm.isStateChanged = true;
    /*
     * push all the ids of checked assignments which are to be rescheduled
     **/

    //isStudentLessonGroup

    vm.assignmentData.forEach((assignment) => {
      if (assignment.isChecked) {
        if (
          (assignment.hasOwnProperty("studentLessonPlan") &&
            !assignment.studentLessonPlan.isGroup) ||
          assignment.isStudentLessonGroup
        ) {
          vm.checkedAssignmentIds = [
            ...vm.checkedAssignmentIds,
            ...assignment.ids,
          ];
        } else if (
          assignment.studentLessonPlan &&
          assignment.studentLessonPlan.isGroup
        ) {
          vm.groupAssignmentCollection.push({
            studentLessonPlanId: assignment.studentLessonPlanId,
            week: assignment.week,
            day: assignment.day,
            position: assignment.position,
            lessonId: assignment.studentLessonPlan.lessonId,
          });
        }
      }
    });

    // _.map(vm.assignmentData, function (assignment) {
    // 	console.log(assignment);
    // 	if (
    // 		assignment.isChecked &&
    // 		assignment.studentLessonPlan &&
    // 		!assignment.studentLessonPlan.isGroup
    // 	) {
    // 		_.map(assignment.ids, function (id) {
    // 			vm.checkedAssignmentIds.push(id);
    // 		});
    // 	}
    // 	if (
    // 		assignment.isChecked &&
    // 		assignment.studentLessonPlan &&
    // 		assignment.studentLessonPlan.isGroup
    // 	) {
    // 		vm.groupAssignmentCollection.push({
    // 			studentLessonPlanId: assignment.studentLessonPlanId,
    // 			week: assignment.week,
    // 			day: assignment.day,
    // 			position: assignment.position,
    // 			lessonId: assignment.studentLessonPlan.lessonId,
    // 		});
    // 	}
    // });

    if (
      (vm.checkedAssignmentIds.length > 0 ||
        vm.groupAssignmentCollection.length > 0) &&
      vm.date
    ) {
      let modelInstance = $uibModal.open({
        animation: true,
        size: "md",
        controller: "EditAssignmentConfirmationController",
        controllerAs: "confirmAssignmtCtrl",
        template: require("../../pug/calendar/edit-assignment-confirmation.pug")
          .default,
      });

      modelInstance.result
        .then(function (option) {
          vm.rescheduleFor = option || "one";
          rescheduleDate();
        }, angular.noop)
        .catch(function () {
          modelInstance.close();
        });
    } else {
      if (
        vm.date &&
        !vm.checkedAssignmentIds.length &&
        !vm.groupAssignmentCollection.length
      ) {
        toastService.toast({
          message: lessonConstants.messages.emptyAssignment,
          type: "error",
          delay: 4000,
        });
      } else if (
        (vm.checkedAssignmentIds.length || vm.groupAssignmentCollection) &&
        !vm.date
      ) {
        toastService.toast({
          message: lessonConstants.messages.emptyDate,
          type: "error",
          delay: 4000,
        });
      } else {
        toastService.toast({
          message: lessonConstants.messages.emptyAssignmentDate,
          type: "error",
          delay: 4000,
        });
      }
    }
  }

  function rescheduleDate() {
    let date = moment(vm.date).format("Y-MM-DD HH:mm:00");

    return assignmentService
      .rescheduleAssignments(
        vm.groupAssignmentCollection,
        vm.checkedAssignmentIds,
        date,
        vm.rescheduleFor
      )
      .then(function (response) {
        vm.checkedAssignmentIds = [];
        vm.responseData = response.data.data;
        let deleteIds = [];

        /**
         * get ids which are scheduled after today and format date for the remaining ones
         */
        _.map(vm.responseData, function (assignment) {
          _.map(vm.assignmentData, function (assignmentData) {
            if (
              assignmentData.id == assignment.id ||
              (assignment.isStudentLessonGroup &&
                assignment.studentLessonGroupId ==
                  assignmentData.studentLessonGroupId)
            ) {
              var startDate = moment(assignment.startDate);
              var now = moment();
              if (now > startDate) {
                assignmentData.isChecked = false;
                assignmentData.startTime = moment(assignment.startDate).format(
                  "MMM DD, Y, LT"
                );
                assignmentData.endTime = moment(assignment.endDate).format(
                  "MMM DD, Y, LT"
                );
                return assignmentData;
              } else {
                deleteIds.push(assignmentData.id);
              }
            }
          });
        });

        /**
         * remove the assignments which are scheduled after today
         */
        removeAssignmentsScheduledLater(deleteIds);

        toastService.toast({
          message: response.data.message,
          type: "success",
          delay: 6000,
        });
      })
      .catch(function (error) {
        // console.log('ERROR', error);
      });
  }

  function removeAssignmentsScheduledLater(deleteIds) {
    if (deleteIds.length) {
      _.forEach(deleteIds, function (index) {
        let deleteIndex = _.findIndex(vm.assignmentData, {
          id: index,
        });
        if (deleteIndex > -1) {
          vm.assignmentData.splice(deleteIndex, 1);
        }
      });
    }
    checkAndLoadMoreAssignments();
  }

  function checkAndLoadMoreAssignments() {
    if (vm.assignmentData.length == 0 && vm.loadMore) {
      loadMoreAssignments();
    }
  }

  function selectStatusAllAssignments(status) {
    vm.isStateChanged = true;
    /*
     * push all the ids of checked assignments whose status is to be changed
     **/
    _.map(vm.assignmentData, function (assignment) {
      if (assignment.isChecked) {
        _.map(assignment.ids, function (id) {
          vm.checkedAssignmentIds.push(id);
        });
      }
    });

    /*
     * Change status of all the selected assignments from assignmentData array
     **/
    if (vm.checkedAssignmentIds.length > 0) {
      let oldStatus = vm.status;
      vm.status = status;
      return assignmentService
        .changeAssignmentsStatus(vm.checkedAssignmentIds, status)
        .then(function (response) {
          /*
           * display changed status of all the selected assignments from assignmentData array
           **/
          if (status != "complete") {
            _.map(vm.checkedAssignmentIds, function (id) {
              _.map(vm.assignmentData, function (assignment) {
                if (assignment.id == id) {
                  assignment.status = status;
                  assignment.isChecked = false;
                  return assignment;
                }
              });
            });
          } else {
            /**
             * remove assignments with status 'complete'
             */
            _.map(vm.checkedAssignmentIds, function (assignmentId) {
              let deleteIndex = _.findIndex(vm.assignmentData, {
                id: assignmentId,
              });
              if (deleteIndex > -1) {
                vm.assignmentData.splice(deleteIndex, 1);
              }
            });

            checkAndLoadMoreAssignments();
          }
          vm.status = "";
          // changeStatusColorAllAssignments(status, oldStatus);
          vm.checkedAssignmentIds = [];
          vm.checkAllAssignments = false;

          toastService.toast({
            message: response.data.message,
            type: "success",
            delay: 8000,
          });
        })
        .catch(function (error) {
          // console.log('ERROR', error);
        });
    } else {
      toastService.toast({
        message: lessonConstants.messages.emptyAssignment,
        type: "error",
        delay: 4000,
      });
    }
  }

  function selectStatus(index, assignment, status) {
    vm.isStateChanged = true;
    let oldStatus = assignment.status;
    vm.assignmentData[index].status = status;
    vm.checkedAssignmentIds = assignment.ids;

    return assignmentService
      .changeAssignmentsStatus(vm.checkedAssignmentIds, status)
      .then(function (response) {
        if (vm.vm.modalTitle === "Assignment Search") {
          changeStatusColor(assignment.id, status, oldStatus);
        } else {
          if (status != "complete") {
            changeStatusColor(assignment.id, status, oldStatus);
          } else {
            let deleteAssignmentIndex = _.findIndex(vm.assignmentData, {
              id: assignment.id,
            });

            vm.assignmentData.splice(deleteAssignmentIndex, 1);
            checkAndLoadMoreAssignments();
          }
        }

        let element = document.getElementById(
          `studentAssignmentStatus-${assignment.id}`
        );
        element.classList.remove("open");
        vm.checkedAssignmentIds = [];
        toastService.toast({
          message: response.data.message,
          type: "success",
          delay: 8000,
        });
      })
      .catch(function (error) {
        // console.log('ERROR', error);
      });
  }

  function changeStatusColor(id, status, oldStatus) {
    let element = document.getElementById(`studentAssignment-${id}`);
    element.classList.remove(_.camelCase(oldStatus));
    element.classList.add(_.camelCase(status));
  }

  function viewAssignment(assignmentObj) {
    vm.isStateChanged = true;
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "assignmentController",
      controllerAs: "assignmentCtrl",
      template: require("../../pug/calendar/view-assignment.pug").default,
      resolve: {
        viewAssignmentParams: {
          assignmentId: assignmentObj.id,
          search: false,
          reschedule: true,
        },
      },
    });
    modelInstance.result.then(afterView).catch(function () {
      modelInstance.close();
    });
  }

  function editAssignment(assignmentObj) {
    vm.isStateChanged = true;
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "editAssignmentController",
      controllerAs: "editAssignmtCtrl",
      template: require("../../pug/calendar/edit-assignment.pug").default,
      resolve: {
        editAssignmentParams: {
          assignmentId: assignmentObj.id,
          search: false,
          reschedule: true,
        },
      },
    });
    modelInstance.result.then(afterView).catch(function () {
      modelInstance.close();
    });
  }

  function afterView(options) {
    if (options.removeEvent) {
      /**
       * find remove index
       */
      let deleteAssignmentIndex;
      if (options.entity.isStudentLessonGroup) {
        deleteAssignmentIndex = _.findIndex(vm.assignmentData, {
          studentLessonGroupId: options.entity.studentLessonGroupId,
        });
      } else {
        deleteAssignmentIndex = _.findIndex(vm.assignmentData, {
          id: options.entity.id,
        });
      }

      /**
       * remove from assignmentData array
       */
      if (deleteAssignmentIndex >= 0) {
        vm.assignmentData.splice(deleteAssignmentIndex, 1);
        checkAndLoadMoreAssignments();
      }
    } else {
      /**
       * from edit modal, replace respective assignment obj with updated one
       */
      if (Object.keys(calendarService.editedLesson).length) {
        calendarService.editedLesson.startTime = moment(
          calendarService.editedLesson.startDate
        ).format("MMM DD, Y, LT");
        calendarService.editedLesson.endTime = moment(
          calendarService.editedLesson.endDate
        ).format("MMM DD, Y, LT");
        var startDate = moment(calendarService.editedLesson.startDate);
        var now = moment();
        if (calendarService.editedLesson.isStudentLessonGroup) {
          let assignmentIndex = _.findIndex(vm.assignmentData, {
            studentLessonGroupId:
              calendarService.editedLesson.studentLessonGroupId,
          });
          if (assignmentIndex >= 0) {
            if (now > startDate) {
              calendarService.editedLesson.ids =
                vm.assignmentData[assignmentIndex].ids;
              vm.assignmentData[assignmentIndex] = calendarService.editedLesson;
            } else {
              vm.assignmentData.splice(assignmentIndex, 1);
              checkAndLoadMoreAssignments();
            }
          }
        } else {
          let assignmentIndex = _.findIndex(vm.assignmentData, {
            id: calendarService.editedLesson.id,
          });
          if (assignmentIndex >= 0) {
            if (now > startDate) {
              calendarService.editedLesson.ids =
                vm.assignmentData[assignmentIndex].ids;
              vm.assignmentData[assignmentIndex] = calendarService.editedLesson;
            } else {
              vm.assignmentData.splice(assignmentIndex, 1);
              checkAndLoadMoreAssignments();
            }
          }
        }

        calendarService.editedLesson = {};
      } else {
        /**
         * from view modal update a status of an assignment
         */
        if (options.entity.isStudentLessonGroup) {
          let assignmentIndex = _.findIndex(vm.assignmentData, {
            studentLessonGroupId: options.entity.studentLessonGroupId,
          });
          if (assignmentIndex >= 0) {
            vm.assignmentData[assignmentIndex].status = options.entity.status;
          }
        } else {
          let assignmentIndex = _.findIndex(vm.assignmentData, {
            id: options.entity.id,
          });
          if (assignmentIndex >= 0) {
            vm.assignmentData[assignmentIndex].status = options.entity.status;
          }
        }
      }
    }

    vm.close(modelInstance.result);
  }

  function deleteAssignment(assignment) {
    vm.isStateChanged = true;
    let id = assignment.id;
    let confirmData = {
      message: "Are you sure you want to delete this assignment ?",
      type: "danger",
      modalSize: "md",
      confirmLabel: "Yes",
    };

    commonService.confirm(confirmData).then(function (confirmation) {
      if (confirmation.result) {
        return assignmentService
          .deleteAssignment(id)
          .then((response) => {
            let removeNotificationsCriteria = {
              entityId: id,
              model: "studentAssignment",
            };

            $rootScope.$broadcast(
              "remove-reminder-notification",
              removeNotificationsCriteria
            );

            toastService.toast({
              message: lessonConstants.messages.deleteAssignmentSuccess,
              type: "success",
              delay: 5000,
            });

            //remove from lessonData array
            let deleteAssignmentIndex = _.findIndex(vm.assignmentData, {
              id: id,
            });
            if (deleteAssignmentIndex >= 0) {
              vm.assignmentData.splice(deleteAssignmentIndex, 1);
              checkAndLoadMoreAssignments();
            }
          })
          .catch(deleteAssignmentFailure);
      }
    });
  }

  function deleteAssignmentFailure(error) {
    let message = angular.isDefined(error.data.message)
      ? error.data.message
      : "Some error occured, please try again";
    toastService.toast({
      message: message,
      type: "error",
      delay: 5000,
    });
  }

  function loadMoreAssignments() {
    vm.isStateChanged = true;
    let query;
    if (vm.assignmentData.length == 0) {
      query = { skip: 0 };
    } else {
      let assignmentIds = [];
      _.map(vm.assignmentData, function (assignment) {
        _.map(assignment.ids, function (id) {
          assignmentIds.push(id);
        });
      });

      query = { skip: assignmentIds.length };
    }

    vm.isLoadingMoreAssignments = true;

    if (!vm.assignmentSearchedPerformed) {
      getIncompleteAssignmentDetails(query);
    } else {
      vm.searchDataOverDue.skip = query.skip;
      search();
    }
  }

  function loadMoreSearchAllAssignments() {
    vm.isStateChanged = true;
    let query;
    if (vm.assignmentData.length == 0) {
      query = { skip: 0 };
    } else {
      let assignmentIds = [];
      _.map(vm.assignmentData, function (assignment) {
        _.map(assignment.ids, function (id) {
          assignmentIds.push(id);
        });
      });

      query = { skip: assignmentIds.length };
    }

    vm.isLoadingMoreAssignments = true;

    vm.searchData.skip = vm.searchData.skip + query.skip;
    searchAssignments();
  }

  function getIncompleteAssignmentDetails(queryParam) {
    vm.isFetchingAssignmentData = true;
    vm.assignmentSearchedPerformed = false;
    assignmentService
      .fetchIncompleteAssignments(queryParam)
      .then((response) => {
        if (!queryParam) {
          vm.assignmentData = [];
        }
        setViewData(response.data);
        vm.loadMore = response.data.loadMore;
      })
      .catch((error) => {
        close();
        $state.go("404", {}, { location: "replace" });
      })
      .finally(() => {
        vm.isFetchingAssignmentData = false;
        vm.isLoadingMoreAssignments = false;
      });
  }

  function setViewData(data) {
    if (
      !vm.loadMore ||
      vm.searchedStudentId !== vm.searchData.studentId ||
      vm.searchedLessonId !== vm.searchData.lessonId ||
      vm.searchedStartDate !== vm.searchData.startDate ||
      vm.searchedEndDate !== vm.searchData.endDate ||
      vm.searchedOverdueStudentId !== vm.searchDataOverDue.studentId ||
      vm.searchedOverdueLessonId !== vm.searchDataOverDue.lessonId
    ) {
      vm.assignmentData = [];
    }

    // vm.assignmentData = data.Assignments;
    if (angular.isDefined(data) && angular.isDefined(data.Assignments)) {
      _.map(data.Assignments, function (assignment) {
        assignment.isChecked = false;

        assignment.startTime = moment(assignment.startDate).format(
          "MMM DD, Y, LT"
        );
        assignment.endTime = moment(assignment.endDate).format("MMM DD, Y, LT");

        if (assignment.studentLessonPlan) {
          assignment.lessonName = assignment.studentLessonPlan.name || "";
        }

        vm.assignmentData.push(assignment);
      });
    }

    vm.searchedStudentId = vm.searchData.studentId;
    vm.searchedLessonId = vm.searchData.lessonId;
    vm.searchedStartDate = vm.searchData.startDate;
    vm.searchedEndDate = vm.searchData.endDate;

    vm.searchedOverdueStudentId = vm.searchDataOverDue.studentId;
    vm.searchedOverdueLessonId = vm.searchDataOverDue.lessonId;
  }

  function close(data = returnData) {
    if (vm.isStateChanged) {
      data.refetchEvents = true;
    }
    $uibModalInstance.close(data);
  }

  function getStudents() {
    return studentService
      .getStudents()
      .then((response) => {
        vm.students = response;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getLessons() {
    vm.isFetchingData = true;
    lessonService
      .getLessons(0, 10, true)
      .then((response) => {
        if (response.lessons.length > 0) {
          //vm.groupedLessons = response.lessons;
          vm.groupedLessons = _.sortBy(response.lessons, "updatedAt").reverse();
          _.forEach(vm.groupedLessons, (groupLesson, n) => {
            _.forEach(groupLesson.lesson, (lesson, m) => {
              vm.lesson.push(lesson);
            });
          });
        }

        vm.loadMoreCourseStatus = response.loadMore;
        vm.pageCount = response.page;
      })
      .catch((response) => {
        console.error(response);
      })
      .finally(() => {
        vm.isFetchingData = false;
      });
  }

  function search() {
    vm.isStateChanged = true;
    vm.isFetchingAssignmentData = true;
    vm.assignmentSearchedPerformed = true;

    assignmentService
      .search(vm.searchDataOverDue)
      .then((response) => {
        if (!vm.loadMore) {
          vm.assignmentData = [];
        }
        if (
          response.data.Assignments.length &&
          !vm.searchDataOverDue.studentId &&
          response.data.Assignments[0].studentLessonPlan.isGroup
        ) {
          let groupAssignments = response.data.Assignments.filter(
            (assignment) =>
              assignment.studentLessonPlan.id ===
              response.data.Assignments[0].studentLessonPlan.id
          );
          response.data.Assignments = groupAssignments;
        }
        setViewData(response.data);

        vm.loadMore = response.data.loadMore;
      })
      .catch((error) => {
        close();
        $state.go("404", {}, { location: "replace" });
      })
      .finally(() => {
        vm.isFetchingAssignmentData = false;
        vm.isLoadingMoreAssignments = false;
      });
  }

  function searchAssignments() {
    let validDate = moment(vm.searchData.startDate).isBefore(
      vm.searchData.endDate
    );

    if (
      vm.searchData.endDate !== null &&
      vm.searchData.startDate !== null &&
      !validDate
    ) {
      let A = moment(vm.searchData.startDate);
      let B = moment(vm.searchData.endDate);
      if (A.diff(B, "days") !== 0) {
        toastService.toast({
          message: lessonConstants.messages.endDateIsBeforeStartDate,
          type: "error",
          delay: 4000,
        });

        return;
      }
    }

    if (
      (vm.searchData.startDate && !vm.searchData.endDate) ||
      (!vm.searchData.startDate && vm.searchData.endDate)
    ) {
      return;
    } else if (vm.searchData.startDate && vm.searchData.endDate) {
      //console.log("else if(vm.searchData.startDate  && vm.searchData.endDate)");

      vm.searchCalled = true;
      vm.assignmentSearchedPerformed = true;
      vm.modalTitle = "Assignment Search";
    }

    vm.isFetchingAssignmentData = true;
    assignmentService
      .searchAssignmentService(vm.searchData)
      .then((response) => {
        if (!vm.loadMore) {
          vm.assignmentData = [];
        }

        setViewData(response.data);
        vm.loadMore = response.data.loadMore;
        vm.checkAllAssignments = false;

        if (!response.data.loadMore) {
          vm.searchData.skip = 0;
        }
      })
      .catch((error) => {
        close();
        $state.go("404", {}, { location: "replace" });
      })
      .finally(() => {
        vm.isFetchingAssignmentData = false;
        vm.isLoadingMoreAssignments = false;
      });
  }

  function init() {
    vm.isStateChanged = false;
    if (!_.isEmpty(studentService.students)) {
      let students_array = _.filter(
        studentService.students,
        (obj) => obj.role === "STUDENT"
      );
      vm.students = students_array;
      getLessons();
    } else {
      getStudents();
    }
    return [getIncompleteAssignmentDetails()];
  }

  function removeHTMLTags(html) {
    html = html.replace(/<style([\s\S]*?)<\/style>/gi, "");
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, "");
    html = html.replace(/<\/div>/gi, "\n");
    html = html.replace(/<\/li>/gi, "\n");
    html = html.replace(/<li>/gi, "  *  ");
    html = html.replace(/<\/ul>/gi, "\n");
    html = html.replace(/<\/p>/gi, "\n");
    html = html.replace(/<br\s*[\/]?>/gi, "\n");
    html = html.replace(/<[^>]+>/gi, "");
    html = html.replace(/<\/?[^>]+>/gi, " ");

    var temp = document.createElement("div");
    temp.innerHTML = html;

    return temp.textContent || temp.innerText || "";
  }

  this.$onInit = init;
}
export default rescheduleAssignmentController;
