let angular = window.angular;

lessonAssignController.$inject = [
  "$scope",
  "lessonService",
  "studentService",
  "$state",
  "$stateParams",
  "_",
  "commonService",
  "toastService",
  "moment",
  "lessonConstants",
  "$uibModal",
];

function lessonAssignController(
  $scope,
  lessonService,
  studentService,
  $state,
  $stateParams,
  _,
  commonService,
  toastService,
  moment,
  lessonConstants,
  $uibModal
) {
  let vm = this;
  let lessonId = 0;

  vm.lesson = {};
  vm.assignedStudents = [];
  vm.studentsToAssign = [];
  vm.assignmentDays = [];
  $scope.assignmentForm = {};
  vm.newStudentAssign = {};

  vm.assigningStudentId = 0;
  vm.isMaximumDaysSelected = false;
  vm.isFormInvalid = false;
  vm.formErrorMessage = "";
  vm.assignmentEndDate = null;

  vm.academicDetails = [
    {
      academicYearStart: lessonConstants.startOfyear,
      academicYearEnd: lessonConstants.endOfYear,
      grade: "Pre-school",
      academicDays: lessonConstants.academicDays,
    },
  ];

  vm.days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  angular.extend(this, {
    goBack,
    assignTo,
    addAndAssignTo,
  });

  $scope.data = {};

  function goBack() {
    $state.go("lessonList");
  }

  // Get the list of students to which the lesson is assigned currently
  function getAssignedStudents() {
    return lessonService
      .getAssignedStudent(lessonId)
      .then(function ([students, response]) {
        vm.assignedStudents = students;
        return response;
      })
      .catch(function (response) {
        console.error(response);
      });
  }

  // Get students to assign the lesson to
  function getStudentsToAssign() {
    return studentService
      .getStudents()
      .then(function (response) {
        vm.studentsToAssign = response;
        return getAssignedStudents();
      })
      .catch(function (response) {
        console.error(response);
      });
  }

  // Get details about selected lesson
  function getLessonDetails() {
    return lessonService
      .viewLesson(lessonId)
      .then(function (response) {
        vm.lesson = response;
        return response;
      })
      .catch(function (response) {
        console.error(response);
      });
  }

  function assignTo(studentId, index) {
    let modalInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "lessonAssignModalController",
      controllerAs: "lessonAssignCtrl",
      template: require("../../pug/lesson/assign-lesson-modal.pug").default,
      resolve: {
        data: {
          studentId: studentId,
          lesson: vm.lesson,
        },
      },
    });

    modalInstance.result
      .then(function (id, studentLesson) {
        let assignedStudent = _.find(vm.studentsToAssign, { id: id });
        if (assignedStudent) {
          vm.assignedStudents.push(assignedStudent);
        }
      })
      .catch(function () {
        modalInstance.close();
      });
  }

  function addAndAssignTo() {
    vm.newStudentAssign.academics = vm.academicDetails;
    studentService
      .addStudent(vm.newStudentAssign)
      .then((response) => {
        let newStudent = response.data.data;
        if (newStudent.Student) {
          let studentData = newStudent.Student;
          vm.studentsToAssign.push(studentData);
          vm.newStudentAssign = {};
          return assignTo(studentData.id);
        } else {
          toastService.toast({
            message: "You can not uncheck all Search In categories",
            type: "error",
            delay: 5000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function initialData() {
    try {
      const response = await Promise.all([
        (async () => await lessonService.viewLesson(lessonId))(),
        (async () => await studentService.getStudents())(),
        (async () => await lessonService.getAssignedStudent(lessonId))(),
      ]);
      const [lessonResponse, studentResponse, assignedStudentsResponse] =
        response;
      vm.lesson = lessonResponse;
      vm.studentsToAssign = studentResponse;
      vm.assignedStudents = assignedStudentsResponse;
    } catch (err) {
      console.log(err);
    }
  }

  function init() {
    lessonId = commonService.convertToInteger($stateParams.lessonId);
    return [getLessonDetails(), getStudentsToAssign()];
    //initialData();
  }

  this.$onInit = init;
}

export default lessonAssignController;
