let angular = window.angular;

function dashboardController(
  $uibModal,
  $rootScope,
  moment,
  $q,
  collaborateService,
  taskService,
  calendarService,
  budgetService,
  friendService,
  commonService,
  studentService,
  ngIntroService,
  Store,
  $timeout,
  dashboardConstants,
  appConstants,
  $state,
  assignmentService,
  _,
  toastService,
  chatConstants,
  $document,
  $window,
  lessonConstants,
  userService,
  appointmentService
) {
  let vm = this,
    timezone;

  vm.isLoadingBudget = true;
  vm.isLoadingEvents = true;
  vm.isLoadingChats = true;
  vm.isLoadingOverdueAssignments = true;
  vm.isLoadingUngradedAssignments = true;
  vm.isLoadingTasks = true;
  vm.isLoadingChores = true;
  vm.isLoadingInitialData = true;

  vm.focusIndex = -1;
  vm.taskTitle = "";
  vm.taskList = "";
  vm.newList = "";
  vm.chats = [];
  vm.tasks = [];
  vm.chores = [];
  vm.budget = {};
  vm.hasNoBudget = true;
  vm.ngEmbeddedOption = chatConstants.ngEmbeddedOption;
  vm.agenda = [];
  vm.statuses = lessonConstants.statuses;
  vm.overdue = {
    assignmentData: [],
    checkAllAssignments: false,
    checkedAssignmentIds: [],
    limit: 3,
    searchPerformed: false,
    filterName: null,
    countSelected: 0,
  };
  vm.gradebook = {
    filter: [],
    lessonFilter: [],
    filterCount: 0,
    lessons: [],
    limit: 4,
  };
  vm.visibleCards = [];

  vm.allowedWidget = angular.copy(dashboardConstants.widgets);
  vm.widgetList = [];

  vm.searchDataOverDue = {
    studentId: "",
    lessonId: "",
    skip: 0,
  };

  vm.paymentPlanList = {
    basic: { title: "Social Networker", price: 2 },
    advance: { title: "Homeschooler Essentials", price: 5 },
    pro: { title: "Homeschooler Pro", price: 8 },
  };

  vm.labels = ["Spent Amount", "Remaining Amount"];
  vm.optionsPie = {
    tooltipEvents: [],
    showTooltips: true,
    tooltipCaretSize: 0,
    onAnimationComplete: function () {
      this.showTooltip(this.segments, true);
    },
  };
  vm.colorsPie = ["#116682", "#daf3fb"];

  if ($rootScope.user.userRole === "PARENT") {
    vm.cards = [
      { template: "agenda-card" },
      { template: "budget-card" },
      { template: "collaborate-card" },
      { template: "todo-card" },
      // { template: "overdue-card" },
      // { template: "gradebook-card" },
      { template: "chore-card" },
    ];
  } else {
    vm.cards = [
      { template: "agenda-card" },
      { template: "todo-card" },
      // { template: "overdue-card" },
      // { template: "gradebook-card" },
    ];
  }

  vm.dropdownCards = angular.copy(dashboardConstants.widgets);

  /**
   * Flag to indicate whether the user profile is completed or not.
   */
  vm.isProfileComplete = true;

  /**
   * Agenda defaults
   */
  vm.startDate = moment();
  vm.endDate = moment().add(1, "d");
  timezone = -1 * new Date().getTimezoneOffset();

  /**
   * Chore defaults
   */
  vm.choreStartDate = moment();
  vm.choreEndDate = moment().add(1, "d");

  /**
   * Display Limits
   * @type type
   */
  vm.chatLimit = dashboardConstants.chatLimit;
  vm.agendaLimit = dashboardConstants.agendaLimit;
  vm.taskLimit = dashboardConstants.taskLimit;
  vm.assignmentLimit = dashboardConstants.assignmentLimit;
  vm.tasks = [{}];

  /**
   * List Index
   */
  vm.editTodoIndex = -1;
  vm.editCategoryIndex = -1;
  vm.isRequestSent = {
    task: false,
    updateTaskStatus: false,
    budget: false,
  };

  let taskQueryParams = {
    limit: dashboardConstants.taskLimit,
    skip: 0,
  };

  vm.studentId = null;

  // Tour options
  vm.tourOptions = {
    steps: [
      {
        element: document.querySelector(".sidenav-head"),
        intro: dashboardConstants.content.welcome,
        position: "right",
      },
      {
        element: document.querySelector(".sidenav"),
        intro: dashboardConstants.content.sidenav,
        position: "right",
      },
      {
        element: document.querySelector("#agenda-card"),
        intro: dashboardConstants.content.agendaCard,
        position: "right",
      },
      {
        element: document.querySelector("#budget-card"),
        intro: dashboardConstants.content.budgetCard,
        position: "left",
      },
      {
        element: document.querySelector("#collaborate-card"),
        intro: dashboardConstants.content.collaborateCard,
        position: "right",
      },
      {
        element: document.querySelector("#todo-card"),
        intro: dashboardConstants.content.todoCard,
        position: "left",
      },
      {
        element: document.querySelector("#overdue-card"),
        intro: dashboardConstants.content.gradeBookCard,
        position: "left",
      },
      // {
      //   element: document.querySelector("#gradebook-card"),
      //   intro: dashboardConstants.content.gradeBookCard,
      //   position: "right",
      // },
    ],
    showStepNumbers: true,
    showBullets: false,
    exitOnOverlayClick: true,
    exitOnEsc: true,
    nextLabel: "Next",
    prevLabel: "Previous",
    skipLabel: "Skip Tour",
    doneLabel: "Finish",
  };

  ngIntroService.setOptions(vm.tourOptions);

  ngIntroService.onComplete(() => {
    $timeout(tourEnd, 0);
  });

  ngIntroService.onExit(() => {
    $timeout(tourEnd, 0);
  });

  // $rootScope.user.adminSidebar = false;
  $rootScope = {
    ...$rootScope,
    user: {
      ...$rootScope.user,
      adminSidebar: false,
    },
  };

  vm.filterLessons = [];

  vm.grades = [];

  vm.gradeDateCheck = false;

  if (Store.get("gradeAll") !== null) {
    vm.gradeAll = Store.get("gradeAll") == "true";
  } else {
    vm.gradeAll = false;
  }

  if (Store.get("future") !== null) {
    vm.gradeFuture = Store.get("future") == "true";
  } else {
    vm.gradeFuture = false;
  }
  vm.widgetLength = vm.dropdownCards.length;

  if ($rootScope.user.userRole == "STUDENT") {
    vm.widgetLength = vm.dropdownCards.length - 1;
  }

  vm.checkCancelUser =
    $rootScope.user.userRole == "PARENT" &&
    $rootScope.user.payment.planId.detail == "basic";

  angular.extend(this, {
    updateCardPosition,
    getOnlineStatus,
    notCurrentUser,
    getNextDayEvents,
    getPreviousDayEvents,
    getChoreSubTitle,
    getChoreStatus,
    getChoreStatusIcon,
    addTask,
    updateTaskStatus,
    isTouched,
    getStudentDetails,
    deleteTask,
    toggleEditTask,
    editTask,
    loadMoreChat,
    loadMoreAgenda,
    loadMoreTask,
    isNewSignup: Store.get("isNewSignup"),
    getStudentAvatar,
    viewEvent,
    toggleEditTodo,
    toggleEditCategoryForm,
    toggleEditTodoForm,
    // toggleEditListForm,
    addCategory,
    editCategory,
    addTodo,
    editTodo,
    // editCategory,
    deleteCategory,
    deleteTodo,
    markTodo,
    markTodoChore,
    viewCategory,
    viewAddCategory,
    openInfoModal,
    getStudentBudget,
    collaborateViewChat,
    overAllBudgetDetails,
    calendar,
    openTutorials,
    getStatusClass,
    filterSearch,
    editGrade,
    selectStatusAllAssignments,
    checkAllOverdue,
    confirmReschedule,
    checkAndLoadMoreOverdueAssignments,
    applyGradebookFilter,
    loadMoreUngradedAssignments,
    changeOverdueFulter,
    openRescheduleModal,
    countSelectedOverdueAssignments,
    removeHTMLTags,
    viewStudentAssignment,
    removeCard,
    addCard,
    createChore,
    viewChore,
    editChore,
    deleteChore,
    viewMoreChoreTodos,
    viewLessChoreTodos,
    changeAssignmentStatus,
    calculateCustomPercentage,
    addCustomGrade,
    applyLessonFilter,
    clearGradeFilter,
    onGradeBookDateChange,
    changeGradedOption,
    changeFutureOption,
    clearStudentGrade,
    checkWidgetCard,
    helperPermissionCheck,
  });

  function helperPermissionCheck() {
    if (
      $rootScope.user.userRole === "HELPER" &&
      $rootScope.user.rolePermission.dashboard &&
      $rootScope.user.rolePermission.dashboard !== "EDIT"
    ) {
      toastService.toast({
        message: "Access Denied",
        type: "error",
        delay: 2000,
      });
      return true;
    } else {
      return false;
    }
  }
  function changeFutureOption() {
    let check = helperPermissionCheck();
    if (check) {
      vm.gradeFuture = false;
      return;
    }
    vm.gradebook.lessons = [];
    vm.isLoadingUngradedAssignments = true;
    getGradebookAssignments(true);
    Store.set("future", vm.gradeFuture);
  }

  function changeGradedOption(value) {
    let check = helperPermissionCheck();
    if (check) {
      return;
    }
    vm.gradeAll = value;
    vm.gradebook.lessons = [];
    vm.isLoadingUngradedAssignments = true;
    getGradebookAssignments(true);
    Store.set("gradeAll", vm.gradeAll);
  }

  function onGradeBookDateChange() {
    if (!vm.gradeDateCheck && vm.gradebook.startDate && vm.gradebook.endDate) {
      vm.gradebook.lessons = [];
      vm.isLoadingUngradedAssignments = true;
      getGradebookAssignments(true);
      vm.gradeDateCheck = true;
    }
  }

  function clearGradeFilter() {
    vm.filterLessons = [];
    vm.gradebook = {
      filter: [],
      lessonFilter: [],
      filterCount: 0,
      lessons: [],
      limit: 4,
    };
    getGradebookAssignments(false);
    vm.gradeDateCheck = false;
  }

  function openInfoModal() {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "hspInfoModalController",
      controllerAs: "hspInfoModalCtrl",
      template: require("../../pug/components/hsp-info-modal.pug").default,
      resolve: {
        infoParam: {
          data: `<div class="info-modal">
								<h4>Dashboard</h4>
								<ul>
									<li>The <b>Dashboard</b> is your control center to get a snapshot of all the tools to keep you organized and connected.</li>
								</ul>
								<div>
									<h4>Agenda</h4>
								</div>
								<ul class="ul">
									<li>The Agenda allows you to get a snapshot of all your assignments and events.</li>
									<li>View your assignments and events in a daily chronological order.
										<ul class="ul-circle">
											<li>Click the “<” to see previous day’s agenda and “>” to see upcoming days.</li>
										</ul>
									</li>
									<li>Easily change the status of assignments by clicking on the status field. By default, it is set to “Not Started”</li>
									<li>Create Agenda Button will redirect you to the Calendar page where you can enter in assignments or events.</li>
								</ul>
								<div>
									<h4>Task List</h4>
								</div>
								<ul class="ul">
									<li>The <b>Task List</b> allows you to create “To-Do” lists and break it down into different categories.</li>
									<li>Click on <b>Add Category</b> (Errands, Library Book List, Shopping List, etc.)</li>
									<li>Click on <b>Add Tasks</b> under the category to enter in your tasks or to-dos.</li>
									<li>Check them off as you complete them.</li>
								</ul>
								<div>
									<h4>Budget & Expenses</h4>
								</div>
								<ul class="ul">
									<li>The Budget & Expenses on the dashboard provides a snapshot of homeschool budget.</li>
									<li>View Budget vs. Expenses, Amount Spent versus Remaining & Budget by Category
										<ul class="ul-circle">
											<li>Click the “>” or “<” to see all the different graphs</li>
											<li>Hovering on the graph displays the numerical values</li>
										</ul>
									</li>
									<li>Clicking on the Plan Budget will redirect you to create/update your budget and expenses.</li>
								</ul>
								<div>
									<h4>Panda Messenger</h4>
								</div>
								<ul class="ul">
									<li>You are never alone when you are using Homeschool Panda. Panda Messenger is a great tool to communicate with other homeschoolers in a safe and secure platform.</li>
									<li>View all your recent chats and by clicking on them will redirect you to the <b>Panda Messenger Module</b>.</li>
									<li>Clicking on <b>Connect Now</b> will also redirect you to the Panda Messenger module</li>
								</ul>
							  </div>`,
        },
      },
    });
  }

  function openTutorials() {
    // let modelInstance = $uibModal.open({
    // 	animation: true,
    // 	size: "lg",
    // 	controller: "hspTutorialModalController",
    // 	controllerAs: "hspTutorialModalCtrl",
    // 	templateUrl: "/partials/components/hsp-tutorial-modal.html",
    // 	resolve: {
    // 		pageType: 'dashboard'
    // 	}
    // });
  }

  function deleteChore(index, chore) {
    let confirmData = {
      message: "Are you sure you want to delete " + chore.title + " ?",
      type: "danger",
      modalSize: "md",
      confirmLabel: "Yes",
    };

    commonService.confirm(confirmData).then(function (confirmation) {
      if (confirmation.result) {
        // remove-reminder-notification
        let removeNotificationsCriteria = {
          entityId: chore.entityId,
          model: "appointment",
        };

        $rootScope.$broadcast(
          "remove-reminder-notification",
          removeNotificationsCriteria
        );

        return appointmentService
          .deleteAppointment(chore.entityId)
          .then((response) => {
            refetchChore();
            toastService.toast({
              message: "Chore removed successfully",
              type: "success",
              delay: 300,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }

  function editChore(index, chore) {
    let comingFrom = "chore";
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "editAppointmentController",
      controllerAs: "editApptCtrl",
      template: require("../../pug/calendar/edit-chore.pug").default,
      resolve: {
        editAppointmentParams: {
          appointmentId: chore.entityId,
          occurrenceId: chore.metaData.occurrenceId || 1,
          editOccurrence: false,
          comingFrom,
        },
      },
    });
    modelInstance.result.then(refetchChore).catch(function () {
      modelInstance.close();
    });
  }

  function viewMoreChoreTodos(studentChore) {
    studentChore.hideView = false;
    studentChore.todoDefault = 100;
  }

  function viewLessChoreTodos(studentChore) {
    studentChore.hideView = true;
    studentChore.todoDefault = 3;
  }

  function viewChore(index, chore) {
    let comingFrom = "chore";
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "viewAppointmentController",
      controllerAs: "viewApptCtrl",
      template: require("../../pug/calendar/view-chore.pug").default,
      resolve: {
        viewAppointmentParams: {
          appointmentId: chore.entityId,
          occurrenceId: chore.metaData.occurrenceId,
          search: null,
          comingFrom,
        },
      },
    });
    modelInstance.result.then(refetchChore).catch(function () {
      modelInstance.close();
    });
  }

  function createChore() {
    $state.go("chores");
  }

  function refetchChore() {
    vm.isLoadingChores = true;
    getChores();
  }

  function viewAddCategory() {
    vm.editCategoryIndex = -1;
    vm.focusIndex = -1;
    let model = "category";
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "todoController",
      controllerAs: "todoCtrl",
      template: require("../../pug/dashboard/todo-list.pug").default,
      resolve: {
        categoryData: {
          model,
        },
      },
    });

    modelInstance.result.catch(function () {
      getTasks();
      modelInstance.close();
    });
  }

  function viewCategory($index) {
    vm.editCategoryIndex = -1;
    vm.focusIndex = -1;
    let model = "todo";
    let categoryId = vm.tasks[$index].id;
    let category = vm.tasks[$index].category;
    let todos = vm.tasks[$index]["todo"];
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "todoController",
      controllerAs: "todoCtrl",
      template: require("../../pug/dashboard/todo-list.pug").default,
      resolve: {
        categoryData: {
          category,
          model,
          categoryId,
          todos,
        },
      },
    });

    modelInstance.result.catch(function () {
      getTasks();
      modelInstance.close();
    });
  }

  function toggleEditTodo($categoryIndex, $todoIndex, isEditCancel) {
    isEditCancel
      ? (vm.tasks[$categoryIndex]["todo"][$todoIndex].title =
          vm.tasks[$categoryIndex]["todo"][$todoIndex].oldTitle)
      : (vm.tasks[$categoryIndex]["todo"][$todoIndex].oldTitle =
          vm.tasks[$categoryIndex]["todo"][$todoIndex].title);
  }

  function editCategory($categoryIndex, category) {
    if ($categoryIndex < 0) {
      return;
    }
    vm.editCategoryIndex = -1;
    vm.focusIndex = -1;
    let categoryId = vm.tasks[$categoryIndex].id;
    taskService
      .editCategory(categoryId, category)
      .then((res) => {
        // vm.tasks[$categoryIndex].isEditing = false;
        // vm.tasks[$categoryIndex].category = category;

        toastService.toast({
          message: dashboardConstants.messages.category.categoryUpdated,
          type: "success",
          delay: 2000,
        });
      })
      .catch((err) => {
        toastService.toast({
          message: dashboardConstants.messages.category.categoryNotUpdated,
          type: "error",
          delay: 3000,
        });
      });
  }

  function markTodo($categoryIndex, $todoIndex, todoId) {
    if ($categoryIndex < 0 || $todoIndex < 0 || !todoId) {
      return;
    }
    // vm.tasks[$categoryIndex]['todo'][$todoIndex].completed = !vm.tasks[$categoryIndex]['todo'][$todoIndex].completed;
    taskService.completeTask(todoId).then((res) => {});
  }

  function markTodoChore(todoId, todoStatus) {
    if (!todoId) {
      return;
    }
    appointmentService.markTodoChore(todoId, todoStatus).then((res) => {});
    toastService.toast({
      message: todoStatus
        ? "Chore mark as completed!"
        : "Chore mark as not completed!",
      type: "success",
      delay: 3000,
    });
  }

  function toggleEditCategory($categoryIndex, isEditCancel) {
    isEditCancel
      ? (vm.tasks[$categoryIndex].category =
          vm.tasks[$categoryIndex].oldCategory)
      : (vm.tasks[$categoryIndex].oldCategory =
          vm.tasks[$categoryIndex].category);
  }

  function toggleEditCategoryForm($index, $categoryIndex, isEditCancel) {
    vm.editCategoryIndex = angular.isNumber($index)
      ? commonService.convertToInteger($index)
      : -1;
    if (~vm.editCategoryIndex) {
      vm.editCategoryIndex = $index;
    } else {
      vm.editCategoryIndex = -1;
    }
    // vm.tasks[$categoryIndex].isEditing = true;
    // toggleEditCategory($categoryIndex, isEditCancel);
    vm.focusIndex = $index;
  }

  function toggleEditTodoForm(
    $index,
    $categoryIndex,
    $todoIndex,
    isEditCancel
  ) {
    vm.editTodoIndex = angular.isNumber($index)
      ? commonService.convertToInteger($index)
      : -1;
    if (~vm.editTodoIndex) {
      vm.editTodoIndex = $index;
    }
    toggleEditTodo($categoryIndex, $todoIndex, isEditCancel);
  }

  function addCategory(title) {
    if (!title) {
      return;
    }
    vm.taskTitle = "";

    taskService
      .addCategory(title)
      .then((res) => {
        let category = res.data.data;
        category.todo = [];
        vm.tasks.push(category);
      })
      .finall(() => {
        // toastService
        toastService.toast({
          message: dashboardConstants.messages.categoryCreate,
          type: "success",
          delay: 3000,
        });
      });
  }

  function editTodo($categoryIndex, $todoIndex, todo) {
    // vm.tasks[$categoryIndex]['todo'][$listIndex].title = todo;
    let categoryId = vm.tasks[$categoryIndex].id;
    let todoId = vm.tasks[$categoryIndex]["todo"][$todoIndex].id;
    taskService.editTodo(todoId, categoryId, todo).then((res) => {
      vm.tasks[$categoryIndex]["todo"][$todoIndex].title = todo;
      vm.editTodoIndex = -1;
    });
    // vm.tasks[$categoryIndex]['todo'].push({'title':todo});
    // toggleEditTodoForm();
  }

  function addTodo($categoryIndex, todo) {
    if (!todo) {
      return;
    }

    vm.newList = "";
    taskService
      .addTodo(todo, vm.tasks[$categoryIndex].id)
      .then((res) => {
        let todo = res.data.data;
        // if(vm.tasks)
        vm.tasks[$categoryIndex]["todo"].push(todo);
      })
      .finally(() => {});
    toggleEditCategoryForm();
  }

  function deleteCategory($index, categoryId) {
    let confirmData = {
      message: dashboardConstants.messages.category.deleteCategoryConfirmation,
      modalSize: "md",
      type: "danger",
    };
    commonService.confirm(confirmData).then(function (confirmation) {
      if (confirmation.result) {
        taskService
          .deleteCategory(categoryId)
          .then((res) => {})
          .finally(() => {
            vm.tasks.splice($index, 1);
          });
      }
    });
  }

  function deleteTodo($categoryIndex, $todoIndex) {
    taskService
      .deleteTodo(vm.tasks[$categoryIndex]["todo"][$todoIndex].id)
      .then((res) => {})
      .finally(() => {
        vm.tasks[$categoryIndex]["todo"].splice($todoIndex, 1);
      });
  }

  function updateCardPosition($index) {
    vm.cards.splice($index, 1);
    let uniqueArr = vm.cards.reduce((arr, item) => {
      const uniq = arr.filter((i) => i.template !== item.template);
      return [...uniq, item];
    }, []);

    let getCards = _.map(uniqueArr, "template");

    userService.updateWidgetPosition(getCards).then((response) => {
      if ($rootScope.user.widgetView && _.isArray($rootScope.user.widgetView)) {
        $rootScope.user.widgetView = getCards;
      }
      Store.set("userData", JSON.stringify($rootScope.user));
      toastService.toast({
        message: "Widget position updated",
        type: "success",
        delay: 3000,
      });
    });

    // Store.set("cards", JSON.stringify(uniqueArr));
  }

  function tourEnd() {
    Store.clear("isNewSignup");
    vm.isNewSignup = false;
  }

  function loadMoreChat() {
    vm.chatLimit += 20;
  }

  function loadMoreAgenda() {
    vm.agendaLimit += 20;
  }

  function getStudentDetails(studentId) {
    let student = studentService.getStudentsById(studentId);
    return student[0] || {};
  }

  function getStudents() {
    studentService.getStudents().then((response) => {
      let students_array = _.filter(response, (obj) => obj.role === "STUDENT");
      vm.students = students_array;
      if (vm.students.length) {
        vm.studentId = vm.students[0].id;
        getStudentBudget();
      }
    });
  }

  function getStudentBudget() {
    vm.selectStyle = "";
    budgetService.getBudgetDetails(vm.studentId).then((response) => {
      let budgets = response.data.data;
      if (budgets.length > 0) {
        let runLoop = true;
        let currentDate = moment(new Date());
        for (let i = 0; i < budgets.length; i++) {
          let startDate = moment(budgets[i].startDate);
          let endDate = moment(budgets[i].endDate);
          let showBetween = currentDate.isBetween(startDate, endDate);
          if (!showBetween && runLoop) {
            let lastBudget = _.last(budgets, (budget) => {
              return true;
            });
            if (lastBudget.total == 0) {
              vm.budget = {};
              vm.hasNoBudget = true;
              showGraph();
              break;
            }
            vm.budget = lastBudget;
            runLoop = false;
            vm.budgetNotFound = "";
            vm.hasNoBudget = false;
            vm.budget.showStartDate = moment(vm.budget.startDate).format(
              "MMM DD, YYYY"
            );
            vm.budget.showEndDate = moment(vm.budget.endDate).format(
              "MMM DD, YYYY"
            );
            vm.startEndDate = `${vm.budget.showStartDate} - ${vm.budget.showEndDate}`;
            vm.spentPercentage =
              (vm.budget.spentAmount * 100) / vm.budget.total;
            vm.remainingPercentage =
              (vm.budget.remainingAmount * 100) / vm.budget.total;
            showGraph();
            break;
          } else {
            if (budgets[i].total == 0) {
              vm.budget = {};
              vm.hasNoBudget = true;
              showGraph();
              break;
            }
            vm.budget = budgets[i];
            runLoop = false;
            vm.budgetNotFound = "";
            vm.hasNoBudget = false;
            vm.budget.showStartDate = moment(vm.budget.startDate).format(
              "MMM DD, YYYY"
            );
            vm.budget.showEndDate = moment(vm.budget.endDate).format(
              "MMM DD, YYYY"
            );
            vm.startEndDate = `${vm.budget.showStartDate} - ${vm.budget.showEndDate}`;
            vm.spentPercentage =
              (vm.budget.spentAmount * 100) / vm.budget.total;
            vm.remainingPercentage =
              (vm.budget.remainingAmount * 100) / vm.budget.total;
            showGraph();
            break;
            // } else {
            // 	vm.budget = {};
            // 	vm.hasNoBudget = true;
            // 	showGraph();
            // 	break;
            // }
          }
          //})
        }
      } else {
        vm.budget = {};
        vm.hasNoBudget = true;
        showGraph();
      }
      // vm.budget = response.data.data
    });
  }

  function isTouched(field, form) {
    if (field && field.$viewValue && !field.$pristine) {
      if (form) {
        form.$setUntouched();
        form.$setPristine();
      }
      return true;
    }
  }

  function notCurrentUser(user) {
    if (!user || !$rootScope.user) {
      return false;
    }
    if ($rootScope.user.userRole === "HELPER") {
      return user.id !== $rootScope.user.helperId;
    }
    return user.userId !== $rootScope.user.id;
  }

  function getOnlineStatus(userId) {
    if (~collaborateService.onlineUsers.indexOf(userId)) {
      return $rootScope.getFriendDetails(userId).status;
    }
    if (userId === $rootScope.user.id) {
      return $rootScope.user.status || "online";
    }
    return "offline";
  }

  function getNextDayEvents(card) {
    if (card == "agenda-card") {
      vm.isLoadingEvents = true;
      vm.startDate.add(1, "d");
      vm.endDate.add(1, "d");
      getEvents();
    } else if (card == "chore-card") {
      vm.isLoadingChores = true;
      vm.choreStartDate.add(1, "d");
      vm.choreEndDate.add(1, "d");
      getChores();
    }
  }

  function getPreviousDayEvents(card) {
    if (card == "agenda-card") {
      vm.isLoadingEvents = true;
      vm.startDate.subtract(1, "d");
      vm.endDate.subtract(1, "d");
      getEvents();
    } else if (card == "chore-card") {
      vm.isLoadingChores = true;
      vm.choreStartDate.subtract(1, "d");
      vm.choreEndDate.subtract(1, "d");
      getChores();
    }
  }

  function getChores() {
    calendarService
      .getNewChore(vm.choreStartDate.format("Y-MM-DD"))
      .then((events) => {
        vm.chores = events.data.data.getOwnerChore.chores;
        if (vm.isLoadingChores) {
          vm.isLoadingChores = false;
        }
      })
      .catch((err) => console.log("error--->", err.message));
  }

  function getChoreSubTitle(chore) {
    return `${
      chore?.recurrence?.type
        ? capitalizeFirstLetter(chore?.recurrence?.type.toLowerCase())
        : "No repetition"
    } ${
      chore?.endOfRecurrence
        ? moment.utc(chore?.endOfRecurrence).format("MMM DD, YYYY")
        : "---"
    } ${
      chore?.allDay ? "All Day" : moment.utc(chore?.startDate).format("h:mm A")
    }`;
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function getChoreStatus(assignees) {
    return assignees?.every((el) => el?.occurrenceChoreStatus?.statusCode === 0)
      ? " Not Started"
      : assignees?.every((el) => el?.occurrenceChoreStatus?.statusCode === 2)
      ? " On Hold"
      : assignees?.every((el) => el?.occurrenceChoreStatus?.statusCode === 3)
      ? " Completed"
      : " In Progress";
  }

  function getChoreStatusIcon(assignees) {
    return assignees?.every((el) => el?.occurrenceChoreStatus?.statusCode === 0)
      ? "images/grayhole.svg"
      : assignees?.every((el) => el?.occurrenceChoreStatus?.statusCode === 2)
      ? "images/hold.svg"
      : assignees?.every((el) => el?.occurrenceChoreStatus?.statusCode === 3)
      ? "images/completed.svg"
      : "images/progress.svg";
  }

  function eventDaylightSavingAdjust(events, queryStartDate, eventType) {
    //console.log('event List in daylight saving ftn', events);
    let eventList = [];
    _.forEach(events, (event) => {
      /**
       * For the daytime saving: ends 4th November and starts on 10th March
       * Check the assignment created date before 4th november
       * And assignmen is after 4th November
       * In that case, all day is 11:00 to 10:59
       */
      if (
        event.firstOccurrence &&
        moment(event.firstOccurrence).isDST() &&
        !moment(event.startDate).isDST()
      ) {
        event.startDate = moment(event.startDate).add(1, "hours");
        event.endDate = moment(event.endDate).add(1, "hours");
        if (
          moment(event.startDate).format("HH:mm") == "00:00" &&
          moment(event.endDate).format("HH:mm") == "23:59"
        ) {
          event.allDayEvent = true;
        }
      } else if (
        event.firstOccurrence &&
        !moment(event.firstOccurrence).isDST() &&
        moment(event.startDate).isDST()
      ) {
        event.startDate = moment(event.startDate).subtract(1, "hours");
        event.endDate = moment(event.endDate).subtract(1, "hours");
        if (
          moment(event.startDate).format("HH:mm") == "00:00" &&
          moment(event.endDate).format("HH:mm") == "23:59"
        ) {
          event.allDayEvent = true;
        }
      }

      if (
        moment(event.startDate).format("HH:mm") === "00:00" &&
        moment(event.endDate).format("HH:mm") === "23:59"
      ) {
        event.allDayEvent = true;
      } else {
        event.allDayEvent = false;
      }

      /**
       * to update assignment and event for the same day
       */

      if (moment(event.startDate).format("Y-MM-DD") == queryStartDate) {
        eventList.push(event);
      }

      if (eventType == "chores") {
        event.metaData.hideView = true;
        event.metaData.todoDefault = 3;
      }
    });
    //console.log('event List in daylight saving ftn', eventList);
    return eventList;
  }

  function getEvents() {
    let query = {
      startDate: vm.startDate.format("Y-MM-DD"),
      endDate: vm.endDate.format("Y-MM-DD"),
      timezone: timezone,
      pageType: "dashboard",
    };

    let queryStartDate = query.startDate;
    calendarService.getEvents(query, true).then((events) => {
      vm.events = eventDaylightSavingAdjust(
        events,
        queryStartDate,
        "appointment"
      );
      if (vm.isLoadingEvents) {
        vm.isLoadingEvents = false;
      }
    });
  }

  function addTask(title) {
    if (!title || !vm.taskForm.$valid || vm.isRequestSent.task) {
      return;
    }
    vm.isRequestSent.task = true;
    taskService
      .addTask(title)
      .then((response) => {
        vm.taskTitle = "";
      })
      .catch((response) => {
        console.error(response);
      })
      .finally(() => {
        vm.isRequestSent.task = false;
        vm.taskForm.$setUntouched();
        vm.taskForm.taskTitle.$setPristine();
      });
  }

  function toggleEditTask(task, isEditCancel) {
    isEditCancel ? (task.title = task.oldTask) : (task.oldTask = task.title);
    task.isEditing = !task.isEditing;
  }

  function editTask(task) {
    if (!vm[`editTaskForm-${task.id}`].$valid) {
      return false;
    }
    taskService.editTask(task.id, task.title).catch((response) => {
      task.title = task.oldTask;
      console.error(response);
    });
  }

  function deleteTask(taskId) {
    let confirmData = {
      message: dashboardConstants.messages.todo.deleteToDoConfirmation,
      modalSize: "md",
      type: "danger",
    };
    commonService.confirm(confirmData).then(function (confirmation) {
      if (confirmation.result) {
        taskService.deleteTask(taskId).catch((error) => {
          console.error(error);
        });
      }
    });
  }

  function updateTaskStatus(task) {
    let status = task.completed;

    vm.isRequestSent.updateTaskStatus = true;
    taskService
      .completeTask(task.id)
      .catch((response) => {
        task.completed = !status;
      })
      .finally(() => {
        vm.isRequestSent.updateTaskStatus = true;
      });
  }

  function getTasks() {
    taskService
      .getCategory(taskQueryParams)
      .then((response) => {
        vm.tasks = response.data.data;
        // vm.tasks = _.sortBy(vm.tasks).reverse();
        // for(let i = 0; i < vm.tasks.length; i++){
        // 	if(!_.isEmpty(vm.tasks[i].todo)){
        // 		vm.tasks.sort((a, b) => {
        // 			return (a.completed === false);
        // 		})
        // 	}
        // }

        for (let i = 0; i < vm.tasks.length; i++) {
          for (let j = 0; j < vm.tasks[i].todo.length; j++) {
            vm.tasks[i].todo[j].isURL = commonService.validURL(
              vm.tasks[i].todo[j].title
            ); // true or false;
          }
        }
      })
      .finally(() => {
        vm.tasks = _.sortBy(vm.tasks).reverse();
        if (vm.isLoadingTasks) {
          vm.isLoadingTasks = false;
        }
      });
  }

  function loadMoreTask() {
    vm.taskLimit += dashboardConstants.taskLimit;
    taskQueryParams.skip = vm.tasks.length;
    getTasks();
  }

  function getChats() {
    if (
      $rootScope.user &&
      !$rootScope.user.userRole == "STUDENT" &&
      (!$rootScope.user.dob ||
        !$rootScope.user.gender ||
        !$rootScope.user.zipCode)
    ) {
      vm.isProfileComplete = false;
      return;
    }

    $rootScope.chatIndexPromise.then(() => {
      vm.chats = collaborateService.chatsList;
      if (vm.isLoadingChats) {
        vm.isLoadingChats = false;
      }
    });
  }

  function showGraph() {
    vm.data = [];
    if (vm.budget) {
      vm.data.push(vm.budget.spentAmount);
      vm.data.push(vm.budget.remainingAmount);
    }
  }

  function getBudget() {
    vm.isRequestSent.budget = true;
    budgetService
      .getBudgetDetails()
      .then((response) => {
        vm.budget = response.data.data[0];
        showGraph();
      })
      .catch((response) => {
        console.error(response);
      })
      .finally(() => {
        vm.isRequestSent.budget = false;
        if (vm.isLoadingBudget) {
          vm.isLoadingBudget = false;
        }
      });
  }

  function getStudentAvatar(studentId) {
    return (
      vm.getStudentDetails(studentId).mediaUrl || appConstants.defaultAvatar
    );
  }

  function viewEvent(event) {
    let params = {
      entityId: event.entityId,
      model: event.model,
    };
    if (
      !$rootScope.user.rolePermission["calendar"] &&
      $rootScope.user.userRole === "HELPER"
    ) {
      return (
        false(event && event.metaData && event.metaData.occurrenceId) &&
        (params.occurrenceId = event.metaData.occurrenceId)
      );
    }
    if (event.model == "studentAssignment" && event.isGroup) {
      return;
    }
    $state.go("calendar", params);
  }

  function collaborateViewChat(id) {
    if (
      $rootScope.checkRolePermission("dashboard") ||
      $rootScope.user.userRole === "STUDENT"
    ) {
      $state.go("collaborateViewChat", { chatId: id });
    } else {
      return false;
    }
  }
  function overAllBudgetDetails() {
    if (
      !$rootScope.user.rolePermission["budget"] &&
      $rootScope.user.userRole == "HELPER"
    ) {
      return false;
    } else {
      $state.go("overAllBudgetDetails");
    }
  }
  function calendar() {
    if (
      !$rootScope.user.rolePermission["calendar"] &&
      $rootScope.user.userRole == "HELPER"
    ) {
      return false;
    } else {
      $state.go("calendar");
    }
  }

  // function setShowQuote(value){
  // 	Store.set('showQuote', `${value}`);
  // 	vm.showQuote = value;
  // }

  // function closeQuote(){
  // 	vm.showQuote = false;
  // }

  // function removeQuote() {
  // 	setShowQuote(false);
  // }

  // function getQuote() {
  // 	return commonService.getQuote()
  // 		.then(getQuoteSuccess)
  // 		.catch(angular.noop);
  // }

  // function getQuoteSuccess(response){

  // 	if(response.data && response.data.length){
  // 		let quoteIndex = moment().dayOfYear() % response.data.length;
  // 		vm.quote = response.data[quoteIndex];
  // 		if(!Store.get('showQuote')){
  // 			setShowQuote(true);
  // 		} else {
  // 			vm.showQuote = Store.get('showQuote') == 'true';
  // 		}
  // 	}
  // }

  function getOverdueAssignments(queryParam) {
    vm.overdue.searchPerformed = false;
    assignmentService
      .getOverdueAssignments(queryParam)
      .then((response) => {
        if (!queryParam) {
          vm.overdue.assignmentData = [];
        }

        let originalCount = vm.overdue.assignmentData.length;

        _.map(response.data.Assignments, function (assignment) {
          assignment.duration = calculateAssignmentDuration(
            assignment.startDate
          );
          if (assignment.isStudentLessonGroup) {
            assignment.showName = assignment.title;
            assignment.secondName = assignment.displayTitle;
          } else {
            // assignment.showName = assignment.studentLessonPlan.name;
            assignment.showName = assignment.title;
            assignment.secondName = assignment.studentLessonPlan.name;
          }

          var studentNames = "";
          _.map(assignment.students, function (student) {
            studentNames += student.name + ", ";
          });
          assignment.studentNames = studentNames.substring(
            0,
            studentNames.length - 2
          );

          vm.overdue.assignmentData.push(assignment);
        });

        vm.overdue.loadMore = response.data.loadMore;
        getOverdueAssignmentsCount();
        if (originalCount > 0) {
          vm.overdue.limit = vm.overdue.assignmentData.length;
        }
        if (vm.overdue.checkAllAssignments) {
          checkAllOverdue();
        }
      })
      .catch((error) => {
        vm.overdue.assignmentData = [];
      });
  }

  function getStatusClass(status) {
    return _.camelCase(status);
  }

  function filterSearch(category, calendar, student, lesson, event) {
    switch (category) {
      case "student":
        student.selected = !student.selected;
        if (student.selected) {
          vm.gradebook.filterCount++;
          searchData.students.push(student);
        } else {
          vm.gradebook.filterCount--;
          searchData.students.splice(
            searchData.students.findIndex(function (index) {
              return index.id === student.id;
            }),
            1
          );
        }
        break;
      default:
        break;
    }
  }

  function getGradebookAssignments(filter) {
    let query;
    if (filter === true) {
      query = { skip: 0 };
    } else {
      let assignmentIds = [];
      _.map(vm.gradebook.lessons, function (assignment) {
        _.map(assignment.ids, function (id) {
          assignmentIds.push(id);
        });
      });

      query = { skip: vm.gradebook.lessons.length };
    }

    query.filter = false;
    if (vm.gradebook.filter.length > 0) {
      query.students = vm.gradebook.filter;
      query.filter = true;
    }

    if (vm.gradebook.lessonFilter.length > 0) {
      query.lessonIds = vm.gradebook.lessonFilter;
      query.filter = true;
    }

    if (vm.gradebook.startDate && vm.gradebook.endDate) {
      query.startDate = vm.gradebook.startDate;
      query.endDate = vm.gradebook.endDate;
      query.filter = true;
    }

    if (vm.gradeAll) {
      query.gradeAll = true;
    }
    if (vm.gradeFuture) {
      query.future = true;
    }

    assignmentService.getUngradedLessons(query).then((response) => {
      _.map(response.lessons, function (lesson) {
        lesson.duration = calculateAssignmentDuration(lesson.startDate);
        if (lesson.isStudentLessonGroup) {
          lesson.showName = lesson.title;
          lesson.secondName = lesson.displayTitle;
        } else {
          lesson.showName = lesson.title;
          lesson.secondName = lesson.lessonName;
        }

        vm.gradebook.lessons.push(lesson);
      });
      vm.gradebook.loadMore = response.loadMore;
      vm.gradebook.limit = vm.gradebook.lessons.length;
      if (query.skip == 0) {
        vm.gradebook.limit = 4;
      }

      getUngradedAssignmentsCount();

      if (vm.isLoadingUngradedAssignments) {
        vm.isLoadingUngradedAssignments = false;
      }
      // if(vm.gradeDateCheck) vm.gradeDateCheck = false;
    });
  }

  function editGrade(type, lessonId, studentId, student) {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "md",
      controller: "gradebookModalController",
      controllerAs: "gradebookModalCtrl",
      template: require("../../pug/dashboard/grade-modal.pug").default,
      resolve: {
        gradebookModalParams: {
          gradeType: type,
          lessonId: lessonId,
          studentId: studentId,
          student: student,
        },
      },
    });
    modelInstance.result
      .then(function (result) {
        if (result.graded === true && result.lessonId) {
          removeAfterGrading(result.lessonId);
        }
      })
      .catch(function () {
        modelInstance.close();
      });
  }

  function removeAfterGrading(lessonId) {
    let removeIndex = -1;
    vm.gradebook.lessons.map((lesson, index) => {
      if (lesson.id == lessonId) {
        removeIndex = index;
      }
      return lesson;
    });

    if (removeIndex > -1) {
      vm.gradebook.lessons.splice(removeIndex, 1);
    }

    getUngradedAssignmentsCount();
  }

  function selectStatusAllAssignments(status) {
    // push all the ids of checked assignments whose status is to be changed
    _.map(vm.overdue.assignmentData, function (assignment) {
      if (assignment.isChecked) {
        _.map(assignment.ids, function (id) {
          vm.overdue.checkedAssignmentIds.push(id);
        });
      }
    });

    // Change status of all the selected assignments from assignmentData array
    if (vm.overdue.checkedAssignmentIds.length > 0) {
      let oldStatus = vm.overdue.status;
      vm.overdue.status = status;
      return assignmentService
        .changeAssignmentsStatus(vm.overdue.checkedAssignmentIds, status)
        .then(function (response) {
          // display changed status of all the selected assignments from assignmentData array
          if (status != "complete") {
            _.map(vm.overdue.checkedAssignmentIds, function (id) {
              _.map(vm.overdue.assignmentData, function (assignment) {
                if (assignment.id == id) {
                  assignment.status = status;
                  assignment.isChecked = false;
                  return assignment;
                }
              });
            });
          } else {
            // remove assignments with status 'complete'
            _.map(vm.overdue.checkedAssignmentIds, function (assignmentId) {
              let deleteIndex = _.findIndex(vm.overdue.assignmentData, {
                id: assignmentId,
              });
              if (deleteIndex > -1) {
                vm.overdue.assignmentData.splice(deleteIndex, 1);
              }
            });

            checkAndLoadMoreOverdueAssignments();
            getOverdueAssignmentsCount();
          }
          vm.overdue.status = "";
          vm.overdue.checkedAssignmentIds = [];
          vm.overdue.checkAllAssignments = false;
          checkAllOverdue();

          toastService.toast({
            message: response.data.message,
            type: "success",
            delay: 8000,
          });
        })
        .catch(function (error) {
          toastService.toast({
            message: "An error has occurred",
            type: "error",
            delay: 8000,
          });
        });
    } else {
      toastService.toast({
        message: lessonConstants.messages.emptyAssignment,
        type: "error",
        delay: 4000,
      });
    }
  }

  function confirmReschedule() {
    //push all the ids of checked assignments which are to be rescheduled

    _.map(vm.overdue.assignmentData, function (assignment) {
      if (assignment.isChecked) {
        _.map(assignment.ids, function (id) {
          vm.overdue.checkedAssignmentIds.push(id);
        });
      }
    });

    if (vm.overdue.checkedAssignmentIds.length > 0 && vm.overdue.date) {
      let modelInstance = $uibModal.open({
        animation: true,
        size: "md",
        controller: "EditAssignmentConfirmationController",
        controllerAs: "confirmAssignmtCtrl",
        template: require("../../pug/calendar/edit-assignment-confirmation.pug")
          .default,
      });

      modelInstance.result
        .then(function (option) {
          vm.overdue.rescheduleFor = option || "one";
          rescheduleDate();
        }, angular.noop)
        .catch(function () {
          modelInstance.close();
        });
    } else {
      if (vm.overdue.date && !vm.overdue.checkedAssignmentIds.length) {
        toastService.toast({
          message: lessonConstants.messages.emptyAssignment,
          type: "error",
          delay: 4000,
        });
      } else if (vm.overdue.checkedAssignmentIds.length && !vm.overdue.date) {
        toastService.toast({
          message: lessonConstants.messages.emptyDate,
          type: "error",
          delay: 4000,
        });
      } else {
        toastService.toast({
          message: lessonConstants.messages.emptyAssignmentDate,
          type: "error",
          delay: 4000,
        });
      }
    }
  }

  function rescheduleDate() {
    let date = moment(vm.overdue.date).format("Y-MM-DD HH:mm:00");

    return assignmentService
      .rescheduleAssignments(
        [],
        vm.overdue.checkedAssignmentIds,
        date,
        vm.overdue.rescheduleFor
      )
      .then(function (response) {
        vm.overdue.checkedAssignmentIds = [];
        vm.overdue.responseData = response.data.data;
        let deleteIds = [];

        // get ids which are scheduled after today and format date for the remaining ones
        _.map(vm.overdue.responseData, function (assignment) {
          _.map(vm.overdue.assignmentData, function (assignmentData) {
            if (
              assignmentData.id == assignment.id ||
              (assignment.isStudentLessonGroup &&
                assignment.studentLessonGroupId ==
                  assignmentData.studentLessonGroupId)
            ) {
              var startDate = moment(assignment.startDate);
              var now = moment();
              if (now > startDate) {
                assignmentData.isChecked = false;
                assignmentData.startDate = moment(assignment.startDate).format(
                  "MMM DD, Y, LT"
                );
                assignmentData.endDate = moment(assignment.endDate).format(
                  "MMM DD, Y, LT"
                );
                return assignmentData;
              } else {
                deleteIds.push(assignmentData.id);
              }
            }
          });
        });

        //remove the assignments which are scheduled after today
        removeAssignmentsScheduledLater(deleteIds);
        getOverdueAssignmentsCount();
        vm.overdue.checkAllAssignments = false;
        checkAllOverdue();

        toastService.toast({
          message: response.data.message,
          type: "success",
          delay: 6000,
        });
      })
      .catch(function (error) {
        toastService.toast({
          message: "An error has occurred",
          type: "error",
          delay: 6000,
        });
      });
  }

  function removeAssignmentsScheduledLater(deleteIds) {
    if (deleteIds.length) {
      _.forEach(deleteIds, function (index) {
        let deleteIndex = _.findIndex(vm.overdue.assignmentData, {
          id: index,
        });
        if (deleteIndex > -1) {
          vm.overdue.assignmentData.splice(deleteIndex, 1);
        }
      });
    }
    // checkAndLoadMoreAssignments();
  }

  function checkAndLoadMoreOverdueAssignments() {
    if (vm.overdue.assignmentData.length > vm.overdue.limit) {
      return;
    }
    if (vm.overdue.loadMore) {
      loadMoreOverdueAssignments();
    }
  }

  function loadMoreOverdueAssignments() {
    let query;
    if (vm.overdue.assignmentData.length == 0) {
      query = { skip: 0 };
    } else {
      let assignmentIds = [];
      _.map(vm.overdue.assignmentData, function (assignment) {
        _.map(assignment.ids, function (id) {
          assignmentIds.push(id);
        });
      });

      query = { skip: assignmentIds.length };
    }

    if (!vm.overdue.searchPerformed) {
      getOverdueAssignments(query);
    } else {
      vm.searchDataOverDue.skip = query.skip;
      applyOverdueFilter(false);
    }
    // vm.overdue.limit = vm.overdue.assignmentData.length;
  }

  function loadMoreUngradedAssignments() {
    if (vm.gradebook.limit < vm.gradebook.lessons.length) {
      vm.gradebook.limit = vm.gradebook.lessons.length;
    } else {
      getGradebookAssignments(false);
    }
  }

  function checkAllOverdue() {
    _.map(vm.overdue.assignmentData, function (assignment, index) {
      if (index < vm.overdue.limit) {
        assignment.isChecked = vm.overdue.checkAllAssignments;
      }
      return assignment;
    });

    countSelectedOverdueAssignments();
  }

  function countSelectedOverdueAssignments() {
    vm.overdue.countSelected = 0;
    _.map(vm.overdue.assignmentData, function (assignment) {
      if (assignment.isChecked) {
        vm.overdue.countSelected++;
      }

      return assignment;
    });
  }

  function getOverdueAssignmentsCount() {
    let query = {};
    query.filter = false;
    if (vm.searchDataOverDue.studentId != "") {
      query.students = vm.searchDataOverDue.studentId;
      query.filter = true;
    }

    assignmentService
      .getOverdueCount(query)
      .then((response) => {
        vm.overdue.count = response.data.overdueCount;
      })
      .catch((error) => {
        vm.overdue.count = 0;
      })
      .finally(() => {
        if (vm.isLoadingOverdueAssignments) {
          vm.isLoadingOverdueAssignments = false;
        }
      });
  }

  function getUngradedAssignmentsCount() {
    let query = {};
    query.filter = false;
    if (vm.gradebook.filter.length > 0) {
      query.students = vm.gradebook.filter;
      query.filter = true;
    }

    if (vm.gradebook.lessonFilter.length > 0) {
      query.lessonIds = vm.gradebook.lessonFilter;
      query.filter = true;
    }

    if (vm.gradebook.startDate && vm.gradebook.endDate) {
      query.startDate = vm.gradebook.startDate;
      query.endDate = vm.gradebook.endDate;
      query.filter = true;
    }

    if (vm.gradeAll) {
      query.gradeAll = true;
    }

    assignmentService
      .getUngradedCount(query)
      .then((response) => {
        vm.gradebook.count = response.data.ungradedCount;
      })
      .catch((error) => {
        vm.gradebook.count = 0;
      });
  }

  function applyGradebookFilter(student) {
    if (vm.gradebook.filter.includes(student.id)) {
      const index = vm.gradebook.filter.indexOf(student.id);
      if (index > -1) {
        vm.gradebook.filter.splice(index, 1);
      }
      vm.gradebook.filterCount--;
    } else {
      vm.gradebook.filter.push(student.id);
      vm.gradebook.filterCount++;
    }

    vm.gradebook.lessons = [];
    vm.isLoadingUngradedAssignments = true;
    getGradebookAssignments(true);
  }

  function applyLessonFilter(lesson) {
    if (vm.gradebook.lessonFilter.includes(lesson.id)) {
      const index = vm.gradebook.lessonFilter.indexOf(lesson.id);
      if (index > -1) {
        vm.gradebook.lessonFilter.splice(index, 1);
      }
      vm.gradebook.filterCount--;
    } else {
      vm.gradebook.lessonFilter.push(lesson.id);
      vm.gradebook.filterCount++;
    }

    vm.gradebook.lessons = [];
    vm.isLoadingUngradedAssignments = true;
    getGradebookAssignments(true);
  }

  function changeOverdueFulter(id, name) {
    vm.isLoadingOverdueAssignments = true;
    vm.overdue.assignmentData = [];
    vm.overdue.checkAllAssignments = false;
    vm.overdue.countSelected = 0;
    vm.searchDataOverDue.skip = 0;
    vm.overdue.limit = 3;

    if (id == vm.searchDataOverDue.studentId) {
      vm.searchDataOverDue.studentId = "";
      vm.overdue.filterName = null;
      vm.overdue.searchPerformed = false;
      getOverdueAssignments();
      return;
    } else {
      vm.searchDataOverDue.studentId = id;
      vm.overdue.filterName = name;
      vm.overdue.searchPerformed = true;
      applyOverdueFilter(true);
      return;
    }
  }

  function applyOverdueFilter(filterStatus) {
    assignmentService
      .filterOverdueAssignments(vm.searchDataOverDue)
      .then((response) => {
        vm.overdue.loadMore = response.data.loadMore;

        let originalCount = vm.overdue.assignmentData.length;

        _.map(response.data.Assignments, function (assignment) {
          assignment.duration = calculateAssignmentDuration(
            assignment.startDate
          );
          if (assignment.isStudentLessonGroup) {
            assignment.showName = assignment.title;
            assignment.secondName = assignment.displayTitle;
          } else {
            // assignment.showName = assignment.studentLessonPlan.name;
            assignment.showName = assignment.title;
            assignment.secondName = assignment.studentLessonPlan.name;
          }

          var studentNames = "";
          _.map(assignment.students, function (student) {
            studentNames += student.name + ", ";
          });
          assignment.studentNames = studentNames.substring(
            0,
            studentNames.length - 2
          );

          vm.overdue.assignmentData.push(assignment);
        });

        getOverdueAssignmentsCount();
        if (originalCount > 0) {
          vm.overdue.limit = vm.overdue.assignmentData.length;
        }
        if (vm.overdue.checkAllAssignments) {
          checkAllOverdue();
        }
      })
      .catch((error) => {
        // console.log(error);
        close();
        // $state.go('404', {}, { location: 'replace' });
      });
  }

  function openRescheduleModal() {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "rescheduleAssignmentController",
      controllerAs: "rescheduleAssignCtrl",
      template: require("../../pug/calendar/reschedule-assignment.pug").default,
    });
    modelInstance.result.then(afterView).catch(function () {
      modelInstance.close();
    });
  }

  function viewStudentAssignment(assignment, search, referer) {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "assignmentController",
      controllerAs: "assignmentCtrl",
      template: require("../../pug/calendar/view-assignment.pug").default,
      resolve: {
        viewAssignmentParams: {
          assignmentId: assignment.id,
          search: search,
          metadata: assignment,
          isWidgetReferer: true,
        },
      },
    });
    modelInstance.result
      .then(function (option) {
        if (
          option.refetchEvents ||
          option.removeEvent ||
          option.isGradeChanged ||
          option.updateEvent ||
          option.changedStatus
        ) {
          if (referer === 1) {
            vm.overdue.assignmentData = [];
            vm.overdue.limit = 3;
            vm.isLoadingOverdueAssignments = true;
            if (!vm.overdue.searchPerformed) {
              getOverdueAssignments();
            } else {
              vm.searchDataOverDue.skip = 0;
              applyOverdueFilter(false);
            }
          } else if (referer === 2) {
            vm.isLoadingUngradedAssignments = true;
            vm.gradebook.lessons = [];
            vm.gradebook.limit = 4;
            getGradebookAssignments(true);
          }
        }

        // else if(option.removeEvent){
        // 	removedAssignmentId = option.deleteAssignmentId;
        // 	var removeIndex = -1;
        // 	vm.overdue.map((assignment, index) => {
        // 		if(assignment.ids.includes(removedAssignmentId)){
        // 			removeIndex = index;
        // 		}
        // 	})
        // 	vm.overdue.assignmentData.filter((assignment) => {
        // 		return !assignment.ids.includes(removedAssignmentId);
        // 	})
        // }
      }, angular.noop)
      .catch(function () {
        modelInstance.close();
      });
    // modelInstance.result
    // .then(afterView, function() {
    //     vm.statusChanged = false;
    //     let options = JSON.parse(Store.get('assignmentOptions'));
    //     Store.set('assignmentOptions', JSON.stringify(vm.statusChanged));

    //     if (options) {
    //         return refetchEvents();
    //     }
    // })
    // .catch(function() {
    //     modelInstance.close();
    // });
  }

  function calculateAssignmentDuration(startString) {
    const startDay = new Date(startString);
    return startDay.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  }

  function removeHTMLTags(html) {
    if (!html || html == null) {
      return html;
    }

    html = html.replace(/<style([\s\S]*?)<\/style>/gi, "");
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, "");
    html = html.replace(/<\/div>/gi, "\n");
    html = html.replace(/<\/li>/gi, "\n");
    html = html.replace(/<li>/gi, "  *  ");
    html = html.replace(/<\/ul>/gi, "\n");
    html = html.replace(/<\/p>/gi, "\n");
    html = html.replace(/<br\s*[\/]?>/gi, "\n");
    html = html.replace(/<[^>]+>/gi, "");
    html = html.replace(/<\/?[^>]+>/gi, " ");

    var temp = document.createElement("div");
    temp.innerHTML = html;

    return temp.textContent || temp.innerText || "";
  }

  function addCard(template) {
    // let cardWidget = _.remove(vm.widgetList, function (widget) {
    // 	return widget.template == template;
    // });
    userService.updateWidget(template, "add").then((response) => {
      // cardWidget = _.isArray(cardWidget) ? cardWidget[0] : cardWidget;
      let findCardWidget = _.find(
        vm.dropdownCards,
        (obj) => obj.template == template
      );
      if (vm.cards) {
        vm.cards.push(findCardWidget);
      } else {
        vm.cards = [findCardWidget];
      }
      if ($rootScope.user.widgetView && _.isArray($rootScope.user.widgetView)) {
        $rootScope.user.widgetView.push(template);
      }
      Store.set("userData", JSON.stringify($rootScope.user));
      // Store.set("cards", JSON.stringify(vm.cards));

      toastService.toast({
        message: "Added widget in the dashboard view",
        type: "success",
        delay: 3000,
      });
    });
  }

  function removeCard(template) {
    let confirmData = {
      message: "Are you sure you want to remove this widget ?",
      type: "danger",
      modalSize: "md",
      confirmLabel: "Yes",
    };

    commonService.confirm(confirmData).then(function (confirmation) {
      if (confirmation.result) {
        removeCardAction(template);
      }
    });
  }

  function removeCardAction(template) {
    if (vm.cards) {
      userService.updateWidget(template, "sub").then((response) => {
        _.remove(vm.cards, function (card) {
          return card.template == template;
        });
        //Remove card from user stored data
        _.remove($rootScope.user.widgetView, (obj) => obj == template);
        Store.set("userData", JSON.stringify($rootScope.user));

        // Added to widget list
        // addToWidgetList(template);
        toastService.toast({
          message: "Removed widget from the dashboard view",
          type: "success",
          delay: 3000,
        });
      });
    }
  }

  function addToWidgetList(template) {
    let selectWidget = {};
    for (let i = 0; i < vm.allowedWidget.length; i++) {
      if (vm.allowedWidget[i].template == template) {
        selectWidget = vm.allowedWidget[i];
        break;
      }
    }

    vm.widgetList.push(selectWidget);

    userService.updateWidget(template, "sub");
  }

  /**
   * Set widget List based on the user widgets
   */
  function setWidgetList() {
    let widgetView = $rootScope.user.widgetView;
    if (!widgetView || widgetView.length == 0) {
      return;
    }

    for (let i = 0; i < widgetView.length; i++) {
      for (let j = 0; j < vm.allowedWidget.length; j++) {
        if (widgetView[i] == vm.allowedWidget[j].template) {
          vm.widgetList.push(vm.allowedWidget[j]);
          break;
        }
      }
    }
  }

  /**
   * Remove widgets from the cards
   */
  function removeFromCards() {
    if (vm.widgetList) {
      for (let i = 0; i < vm.widgetList.length; i++) {
        _.remove(vm.cards, function (card) {
          return card.template == vm.widgetList[i].template;
        });
      }

      // Store.set("cards", JSON.stringify(vm.cards));
    }
  }

  function initializeDashboard() {
    let payment = $rootScope.user.payment;

    if (payment) {
      if (payment.planId.detail == "pro") {
        vm.cards = [
          { template: "agenda-card" },
          { template: "chore-card" },
          { template: "budget-card" },
          { template: "collaborate-card" },
          { template: "todo-card" },
          // { template: "overdue-card" },
          // { template: "gradebook-card" },
        ];
      } else if (payment.planId.detail == "advance") {
        vm.cards = [
          { template: "agenda-card" },
          { template: "chore-card" },
          { template: "collaborate-card" },
          { template: "todo-card" },
          // { template: "overdue-card" },
          // { template: "gradebook-card" },
        ];
      } else {
        vm.cards = [
          { template: "collaborate-card" },
          { template: "todo-card" },
        ];
      }
    } else {
      vm.cards = [{ template: "upgrade-plan" }];
    }
  }

  function getFilterLessons() {
    calendarService.getFilterLessons().then((response) => {
      vm.filterLessons = response;
    });
  }

  function changeAssignmentStatus(assignment, status) {
    let check = helperPermissionCheck();
    if (check) {
      return;
    }
    if (!assignment.id && !status) {
      return;
    }
    assignmentService.changeStatus(status, assignment.id).then((response) => {
      if (response.status == 200 && response.data && response.data.data) {
        assignment.status = response.data.data.status;
      }
    });
  }

  function calculateCustomPercentage(index, lesson) {
    let check = helperPermissionCheck();
    if (check) {
      vm.customValue = "";
      return;
    }
    let customValue = vm.gradebook.lessons[index].customValue;
    vm.gradebook.lessons[index].gradeCustomError = false;
    let numbersCheck = /^[0-9]+$/;
    if (customValue) {
      customValue = customValue.trim();
      customValue = customValue.replace(" ", "");
      if (customValue.includes("/")) {
        let splitCustomValue = customValue.split("/");
        if (splitCustomValue.length >= 2) {
          if (
            !isNaN(parseInt(splitCustomValue[0])) &&
            !isNaN(parseInt(splitCustomValue[1]))
          ) {
            let percentage =
              (parseInt(splitCustomValue[0]) / parseInt(splitCustomValue[1])) *
              100;
            vm.gradebook.lessons[index].percentage = Math.ceil(percentage);
          } else {
            vm.gradeCustomError = true;
            vm.gradebook.lessons[index].gradeCustomError = true;
          }
        } else {
          vm.gradeCustomError = true;
          vm.gradebook.lessons[index].gradeCustomError = true;
        }
      } else {
        let checkInGrades = _.find(vm.grades, function (grade) {
          if (grade.title.toLowerCase() === customValue.toLowerCase()) {
            return true;
          }
        });
        if (checkInGrades) {
          vm.gradebook.lessons[index].percentage = checkInGrades.maxValue;
        } else {
          let customPercentage = parseInt(customValue);
          if (
            customValue.match(numbersCheck) &&
            isFinite(customPercentage) &&
            !isNaN(customPercentage)
          ) {
            vm.gradebook.lessons[index].percentage =
              Math.ceil(customPercentage);
          } else {
            vm.gradeCustomError = true;
            vm.gradebook.lessons[index].gradeCustomError = true;
          }
        }
      }
      _.forEach(vm.grades, (grade, n) => {
        if (
          vm.gradebook.lessons[index].percentage >= grade.minValue &&
          vm.gradebook.lessons[index].percentage <= grade.maxValue
        ) {
          vm.gradebook.lessons[index].grade = grade;
        }
      });
    }
  }

  function addCustomGrade(id, index, type, grade) {
    let check = helperPermissionCheck();
    if (check) {
      return;
    }
    let data = null;
    let gradeId = null;
    if (type === "custom") {
      if (
        !vm.gradebook.lessons[index].customValue ||
        vm.gradebook.lessons[index].gradeCustomError
      ) {
        return;
      }
      _.forEach(vm.grades, (grade, n) => {
        if (
          vm.gradebook.lessons[index].percentage >= grade.minValue &&
          vm.gradebook.lessons[index].percentage <= grade.maxValue
        ) {
          gradeId = grade.id;
        }
      });
    }
    if (type === "dropdown") {
      gradeId = grade.id;
      vm.gradebook.lessons[index].percentage = grade.maxValue;
      vm.gradebook.lessons[index].customValue = grade.title;
      vm.gradebook.lessons[index].grade = grade;
    }

    data = {
      percentage: vm.gradebook.lessons[index].percentage,
      grade: gradeId,
      status: "complete",
      customValue: vm.gradebook.lessons[index].customValue,
      obtainedMarks: 0,
      totalMarks: 0,
    };

    assignmentService
      .addGrade(data, id)
      .then((response) => {
        if (response.data && response.data.data && response.data.data.length) {
          let result = response.data.data[0];
          if (!vm.gradeAll) {
            if (result.grade && result.id) {
              removeAfterGrading(result.id);
            }
          }

          toastService.toast({
            message: "Grade successfully added",
            type: "success",
            delay: 5000,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function clearStudentGrade(lesson, index) {
    if (!lesson.id) {
      return;
    }
    if (!lesson.grade) {
      toastService.toast({
        message: "No Grade is assigned",
        type: "error",
        delay: 5000,
      });
      return;
    }

    assignmentService
      .deleteGrade(lesson.id)
      .then((response) => {
        if (response.status == 200) {
          vm.gradebook.lessons[index].customValue = "";
          vm.gradebook.lessons[index].grade = null;
          vm.gradebook.lessons[index].percentage = 0;
          toastService.toast({
            message: "Student Grade is Cleared",
            type: "success",
            delay: 2000,
          });
        }
      })
      .catch((error) => {
        let message = angular.isDefined(error.data.message)
          ? error.data.message
          : "Some error occured, please try again";
        toastService.toast({
          message: message,
          type: "error",
          delay: 5000,
        });
      });
  }

  function getGrades() {
    return assignmentService
      .getGrades()
      .then((grades) => {
        vm.grades = grades;
      })
      .catch((error) => {
        vm.grades = [];
      });
  }

  function checkWidgetCard(value) {
    if (value) {
      let checkCard = _.find(vm.cards, (obj) => obj.template == value);
      if ($rootScope.user.userRole == "STUDENT") {
        if (value == "budget-card") {
          return false;
        }

        // if (value == "gradebook-card") {
        //   if (
        //     $rootScope.user.rolePermission &&
        //     $rootScope.user.rolePermission.grade &&
        //     $rootScope.user.rolePermission.grade == "edit"
        //   ) {
        //     return true;
        //   } else {
        //     return false;
        //   }
        // }
        // if (value == "overdue-card") {
        //     if ($rootScope.user.rolePermission && $rootScope.user.rolePermission.assignment && $rootScope.user.rolePermission.assignment == 'edit') {
        //         return true;
        //     }
        //     else {
        //         return false;
        //     }
        // }

        return !checkCard;
      } else if (
        $rootScope.user.userRole === "HELPER" &&
        $rootScope.user.relation !== "Family"
      ) {
        if (value == "chore-card") {
          return false;
        } else {
          return !checkCard;
        }
      } else {
        return !checkCard;
      }
    }
    return true;
  }

  function getCardsFromDb() {
    if ($rootScope.user.widgetView && $rootScope.user.widgetView.length) {
      let cards = [];
      _.forEach($rootScope.user.widgetView, function (widget) {
        if (widget == "agenda-card" && !vm.checkCancelUser) {
          cards.push({ template: "agenda-card" });
        }
         else if (widget == "chore-card" && !vm.checkCancelUser) {
          cards.push({ template: "chore-card" });
        }
         else if (widget == "budget-card" && !vm.checkCancelUser) {
          cards.push({ template: "budget-card" });
        } else if (widget == "collaborate-card") {
          cards.push({ template: "collaborate-card" });
        } else if (widget == "todo-card" && !vm.checkCancelUser) {
          cards.push({ template: "todo-card" });
        }
        // else if (
        //     widget == "overdue-card" &&
        //     !vm.checkCancelUser
        // ) {
        //     cards.push({ template: "overdue-card" });
        // }
        // else if (widget == "gradebook-card" && !vm.checkCancelUser) {
        //   cards.push({ template: "gradebook-card" });
        // }
      });
      return cards;
    }
    return [];
  }

  function init() {
    // Update widget list
    // setWidgetList();

    if ($rootScope.user.userRole === "PARENT") {
      let payment = $rootScope.user.payment;
      if (!payment) {
        let id = $rootScope.user.helperId
          ? $rootScope.user.helperId
          : $rootScope.user.id;
        userService.view(id).then((userData) => {
          initializeDashboard();
        });
      } else {
        initializeDashboard();
      }
    }
    // else if ($rootScope.user.userRole === "HELPER") {
    // 	vm.cards = [];
    // 	if ($rootScope.user.rolePermission.calendar) {
    // 		vm.cards.push({ template: "agenda-card" });
    // 		vm.cards.push({ template: "chore-card" });
    // 		vm.cards.push({ template: "overdue-card" });
    // 		vm.cards.push({ template: "gradebook-card" });
    // 	}
    // 	if ($rootScope.user.rolePermission["budget"]) {
    // 		vm.cards.push({ template: "budget-card" });
    // 	}
    // 	// vm.cards.push({ template: 'todo-card' })

    // 	if ($rootScope.user.rolePermission["pandaMessenger"]) {
    // 		vm.cards.splice({ template: "collaborate-card" });
    // 	}
    // } else {
    // 	vm.cards = [
    // 		{ template: "agenda-card" },
    // 		{ template: "chore-card" },
    // 		{ template: "todo-card" },
    // 		{ template: "collaborate-card" },
    // 		{ template: "overdue-card" },
    // 		{ template: "gradebook-card" },
    // 	];
    // }

    try {
      // Card list
      // vm.cards = JSON.parse(Store.get("cards")) || vm.cards;
      vm.cards = getCardsFromDb() || vm.cards;

      let filteredCards = [];

      if ($rootScope.user.userRole === "HELPER") {
        filteredCards = [...vm.cards];
        if (!$rootScope.user.rolePermission.calendar) {
          filteredCards = filteredCards.filter(
            (card) =>
              card.template !== "gradebook-card" &&
              card.template !== "agenda-card"
          );
        }

        if (!$rootScope.user.rolePermission.collaborate) {
          filteredCards = filteredCards.filter(
            (card) => card.template !== "collaborate-card"
          );
        }

        vm.cards = angular.copy(filteredCards);
      } else if ($rootScope.user.userRole === "STUDENT") {
        filteredCards = [...vm.cards];
        if (
          !$rootScope.user.rolePermission.grade ||
          !$rootScope.user.rolePermission.grade == "edit"
        ) {
          filteredCards = filteredCards.filter(
            (card) => card.template !== "gradebook-card"
          );
        }
        if (
          !$rootScope.user.rolePermission.assignment ||
          !$rootScope.user.rolePermission.assignment == "edit"
        ) {
          filteredCards = filteredCards.filter(
            (card) => card.template !== "overdue-card"
          );
        }
        //
        // if (!$rootScope.user.rolePermission.collaborate) {
        // 	filteredCards = filteredCards.filter(
        // 		(card) => card.template !== "collaborate-card"
        // 	);
        // }

        vm.cards = angular.copy(filteredCards);
      }

      // removeFromCards();
    } catch (e) {
      console.log("error", e);
    }

    // getQuote(); // Show Intitial Quote
    getBudget(); // Get the Budget
    getEvents(); // Get the events
    getChats(); // Get all chats

    // Get the tasks
    taskService.tasks.splice(0);
    getTasks();

    getChores();

    getStudents();
    vm.overdue.limit = 3;
    getOverdueAssignments(); //get overdue assignments and count
    getGradebookAssignments(false); //get ungraded assignments and count

    getGrades();
    getFilterLessons();

    // Initialize tour
    if (vm.isNewSignup) {
      ngIntroService.start();
    }
  }

  this.$onInit = init;
}
dashboardController.$inject = [
  "$uibModal",
  "$rootScope",
  "moment",
  "$q",
  "collaborateService",
  "taskService",
  "calendarService",
  "budgetService",
  "friendService",
  "commonService",
  "studentService",
  "ngIntroService",
  "Store",
  "$timeout",
  "dashboardConstants",
  "appConstants",
  "$state",
  "assignmentService",
  "_",
  "toastService",
  "chatConstants",
  "$document",
  "$window",
  "lessonConstants",
  "userService",
  "appointmentService",
];
export default dashboardController;
