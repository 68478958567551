let angular = window.angular;

createAppointmentController.$inject = [
  "$scope",
  "uiCalendarConfig",
  "fileService",
  "$uibModalInstance",
  "appointmentService",
  "options",
  "moment",
  "calendarConstants",
  "calendarService",
  "commonService",
  "toastService",
  "$timeout",
  "_",
  "$rootScope",
  "studentService",
  "friendService",
  "$q",
  "$state",
  "chatConstants",
];

function createAppointmentController(
  $scope,
  uiCalendarConfig,
  fileService,
  $uibModalInstance,
  appointmentService,
  options,
  moment,
  calendarConstants,
  calendarService,
  commonService,
  toastService,
  $timeout,
  _,
  $rootScope,
  studentService,
  friendService,
  $q,
  $state,
  chatConstants
) {
  /* jshint validthis:true */
  let vm = this;

  vm.reqPage = "calendar";
  vm.recurrence = {};
  vm.calendarName = "hspCalendar";
  vm.reminders = [];
  vm.recurrenceOptions = _.clone(calendarConstants.recurrenceOptions);
  vm.reminderOptions = _.clone(calendarConstants.reminderOptions);
  vm.repeatEnd = "maxOccurrences";
  vm.endOfRecurrenceInvalid = false;
  vm.dateDuration = 0;
  vm.timeDuration = moment.duration(1, "hour").asSeconds();
  vm.allDayEvent = false;

  vm.category = "event";

  /////////////////////////////
  //console.log('data from student service is:', studentService.students);

  ////////For Family Helper
  let helpers_array = _.filter(
    studentService.students,
    (obj) => obj.role === "HELPER"
  );
  let family_helpers_array = _.filter(
    helpers_array,
    (obj) => obj.relation === "Family"
  );

  family_helpers_array = _.map(family_helpers_array, (helper, index) => {
    index++;
    return {
      type: "helper",
      id: helper.id,
      displayName: helper.name,
      name: helper.name + " (H" + index + ")",
      mediaUrl: helper.mediaUrl || null,
      refId: helper.id,
    };
  });
  //console.log(family_helpers_array);

  let students_array = _.filter(
    studentService.students,
    (obj) => obj.role === "STUDENT"
  );

  students_array = _.map(students_array, (student, index) => {
    index++;
    return {
      type: "student",
      id: student.id,
      displayName: student.name,
      name: student.name + " (S" + index + ")",
      mediaUrl: student.mediaUrl || null,
      refId: student.studentUserId,
    };
  });
  let friends_array = _.map(friendService.getFriends(), (friend, index) => {
    index++;
    return {
      type: "friend",
      id: friend.id,
      displayName: friend.name,
      name: friend.name + " (F" + index + ")",
      mediaUrl: friend.mediaUrl || null,
    };
  });

  //console.log('Friend List is:', friends_array);
  let std_helper_array = [];
  std_helper_array = students_array.concat(family_helpers_array);

  // let a = [];
  // a = a.concat(std_helper_array);
  // let b = [];
  // b = b.concat(friends_array);
  //removing duplicates from frineds array
  for (var i = 0, len = std_helper_array.length; i < len; i++) {
    for (var j = 0, len2 = friends_array.length; j < len2; j++) {
      if (std_helper_array[i].refId === friends_array[j].id) {
        friends_array.splice(j, 1);
        len2 = friends_array.length;
      }
    }
  }

  //console.log('Modified student & helper list is:', std_helper_array);
  //console.log('Modified only frineds list is:', friends_array);
  //Student and chore list
  vm.students = [];
  /**
   * Check is student exists
   */
  //console.log('Title is:', options.title);
  if (options.title != null && options.title == "chore") {
    if (students_array.length > 0) {
      //only students & helpers
      vm.students = std_helper_array;
      //vm.students = students_array.concat(family_helpers_array);
    } else {
      //replacing studetns with friends
      vm.students = friends_array;
    }
  } else {
    if (students_array.length > 0) {
      //merging students with friends
      vm.students = std_helper_array.concat(friends_array);
    } else {
      //replacing studetns with friends
      vm.students = friends_array;
    }
  }
  /////////////////////////////////////////
  vm.eventWeekdays = [];
  vm.days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  vm.selectedAttendees = [];

  vm.appointmentTitle = null;
  vm.appointmentDesc = null;

  vm.appointmentStartDate = null;
  vm.appointmentStartTime = null;

  vm.appointmentEndDate = null;
  vm.appointmentEndTime = null;

  vm.appointmentLocation = "";
  vm.locationLatitude = 0;
  vm.locationLongitude = 0;
  vm.emptyFieldError = false;
  vm.modelName = "appointment";

  vm.media = [];
  vm.multipleFiles = {
    toUpload: [],
    uploaded: vm.media,
    toDelete: [],
  };

  /**
   * Chore todo tasks
   */
  vm.todos = [];
  vm.ngEmbeddedOption = chatConstants.ngEmbeddedOption;
  vm.editTodoIndex = -1;

  //For Date Error
  vm.dateError = "";

  angular.extend(vm, {
    close,
    isTouched,
    checkEndOfRecurrence,
    createAppointment,
    createChore,
    onEndDateChange,
    onStartDateChange,
    addReminder,
    removeReminder,
    getReminderTitle,
    onRecurrenceOptionChange,
    filesUpdateCallback,
    onRepeatEndOptionChange,
    addStudentsTag,
    removeErrorMessage,
    addTodoToList,
    toggleEditTodoForm,
    deleteTodo,
    saveTodo,
  });

  function toggleEditTodoForm($index, $todoIndex, isEditCancel, taskId) {
    vm.editTodoIndex = angular.isNumber($index)
      ? commonService.convertToInteger($index)
      : -1;
    if (~vm.editTodoIndex) {
      vm.editTodoIndex = $index;
    } else {
      vm.editCategoryIndex = -1;
    }

    if (!vm.todos[$todoIndex].title || vm.todos[$todoIndex].title == "") {
      if (!taskId) {
        vm.todos.splice($todoIndex, 1);
        return true;
      } else {
        deleteTodo($todoIndex, todoId);
      }
    }

    toggleEditTodo($todoIndex, isEditCancel);
  }

  function toggleEditTodo($todoIndex, isEditCancel) {
    isEditCancel
      ? (vm.todos[$todoIndex].title = vm.todos[$todoIndex].oldTitle)
      : (vm.todos[$todoIndex].oldTitle = vm.todos[$todoIndex].title);
  }

  function deleteTodo($todoIndex, todoId) {
    let confirmData = {
      message: "Are you sure you want to delete this task item.",
      modalSize: "md",
      type: "danger",
    };
    commonService.confirm(confirmData).then(function (confirmation) {
      if (confirmation.result) {
        vm.todos.splice($todoIndex, 1);
      }
    });
  }

  function saveTodo($todoIndex, todo) {
    if (!todo || !todo.trim()) {
      toastService.toast({
        message: "Please enter todo in task list",
        type: "error",
        delay: 3000,
      });
      return;
    }
    vm.editTodoIndex = -1;
  }

  function addTodoToList() {
    let todoReferenceId;
    if (vm.todos.length == 0) {
      todoReferenceId = vm.todos.length + 1;
    } else {
      todoReferenceId = vm.todos[vm.todos.length - 1]
        ? vm.todos[vm.todos.length - 1].todoReferenceId + 1
        : vm.todos.length + 1;
    }
    vm.todos.push({ title: "", todoReferenceId: todoReferenceId });
    vm.editTodoIndex = vm.todos.length - 1;
    vm.isEditing = true;
  }

  function addStudentsTag(studentQuery, isChore) {
    if (!studentQuery) {
      var deferred = $q.defer();
      deferred.resolve(vm.students);
      return deferred.promise;
    }
    let newStudentList = [];
    if (vm.students.length > 0) {
      let findStudent = _.map(vm.students, function (students) {
        if (
          _.includes(students.name.toLowerCase(), studentQuery.toLowerCase())
        ) {
          newStudentList.push(students);
        }
      });
    }

    var deferred = $q.defer();
    deferred.resolve(newStudentList);
    return deferred.promise;
  }

  function filesUpdateCallback(id) {
    if (vm.updatedLesson) {
      vm.updatedLesson.media = vm.media;
    }

    if (id) {
      $state.go("lessonPlannerAssign", { lessonId: id });
    }

    isRequestSent = false;
    $rootScope.loadingBtnComplete("save", "Create");
  }

  function checkEndOfRecurrence(date) {
    if (date) {
      vm.endOfRecurrenceInvalid = moment(date).isBefore(
        moment(vm.appointmentStartDate)
      );
    } else {
      vm.endOfRecurrenceInvalid = false;
    }
  }

  function isTouched(field) {
    if (angular.isDefined(field)) {
      return field.$touched || vm.form.$submitted;
    } else {
      return false;
    }
  }

  function addReminder() {
    let id = vm.reminders.length
      ? vm.reminders[vm.reminders.length - 1].id + 1
      : 1,
      options = _.clone(vm.reminderOptions),
      value = vm.reminders.length + 1;

    let reminder = { id, options, value };

    vm.reminders.push(reminder);
  }

  function getReminderTitle(index) {
    return commonService.stringifyNumber(index + 1);
  }

  function removeReminder(index) {
    vm.reminders.splice(index, 1);
  }

  function close(data = {}) {
    angular.extend(data, {
      model: "appointment",
    });
    if (data.refetchEvents) {
      refetchEvents();
    }
    $uibModalInstance.close(data);
  }

  function createChore() {
    vm.category = "chores";
    vm.reqPage = "dashboard";

    if ($rootScope.user.userRole !== "STUDENT" && !vm.selectedAttendees.length) {
      toastService.toast({
        message: "Please add atleast one student to chore",
        type: "error",
        delay: 3000,
      });
      return;
    }

    if (vm.todos.length) {
      vm.todos = vm.todos.filter((o) => o.title != "");
    }

    createAppointment();
  }

  function createAppointment() {
    vm.dateError = "";
    vm.emptyFieldError = false;
    if (!vm.form.$valid) {
      vm.emptyFieldError = true;
      return;
    }

    if (
      vm.repeatEnd != "endOfRecurrence" &&
      !vm.form.$valid &&
      angular.isDefined(vm.form.$error.parse)
    ) {
      vm.form.$valid =
        _.keys(vm.form.$error).length === 1 &&
        vm.form.$error.parse[0].$name == "endOfRecurrence";
    }

    if (vm.form.$valid && !vm.endOfRecurrenceInvalid) {
      let data = {
        appointment: {},
        recurrence: {},
        reminders: [],
      };

      let startDateTime, endDateTime;

      data.appointment.imageIds = fileService.getAppointmentImageIds();

      data.appointment.title = vm.appointmentTitle;
      data.appointment.description = vm.appointmentDesc;

      data.appointment.location = vm.appointmentLocation;
      data.appointment.latitude = vm.locationLatitude;
      data.appointment.longitude = vm.locationLongitude;

      for (let i = 0; i < vm.selectedAttendees.length; i++) {
        if (vm.selectedAttendees[i].type == undefined) {
          vm.selectedAttendees[i].type = "user";
          vm.selectedAttendees[i].id = vm.selectedAttendees[i].name;
        }
      }
      //add attendees to the event
      data.appointment.attendees = vm.selectedAttendees;

      //add category of event
      data.appointment.category = vm.category;

      // Convert the time and date to UTC
      startDateTime = commonService.convertToUTC(
        vm.appointmentStartDate +
        " " +
        (vm.allDayEvent ? "00:00" : vm.appointmentStartTime)
      );
      endDateTime = commonService.convertToUTC(
        vm.appointmentEndDate +
        " " +
        (vm.allDayEvent ? "23:59" : vm.appointmentEndTime)
      );

      // Make sure end datetime is not before start datetime
      if (moment(endDateTime).isBefore(startDateTime)) {
        // toastService.toast({
        //     message: calendarConstants.messages.eventEndDateError,
        //     type: 'error',
        //     delay: 3000
        // });
        vm.dateError = calendarConstants.messages.eventEndDateError;
        return false;
      }
      vm.dateError = "";
      vm.emptyFieldError = false;
      data.appointment.category = vm.category;
      data.appointment.startDate = startDateTime.format("Y-MM-DD HH:mm:ss");
      data.appointment.endDate = endDateTime.format("Y-MM-DD HH:mm:ss");

      $rootScope.loadingBtn("createAppointment", "creating");

      if (!_.isEmpty(vm.recurrence) && angular.isDefined(vm.recurrence.type)) {
        data.recurrence = _.pick(vm.recurrence, [
          "type",
          "maxOccurrences",
          "endOfRecurrence",
          "separationCount",
        ]);
        data.recurrence = _.assign(data.recurrence, { endsWith: vm.repeatEnd });
        /*
         *  Get the value of endOfRecurrence, convert it into a datetime for endOfDay and then convert it into UTC
         */

        if (vm.repeatEnd == "maxOccurrences") {
          let offset =
            (vm.recurrence.separationCount + 1) * vm.recurrence.maxOccurrences;
          vm.recurrence.endOfRecurrence = moment(vm.appointmentStartDate).add(
            offset,
            vm.recurrence.type
          );
        }

        let endOfRecurrence = data.recurrence.endOfRecurrence;
        endOfRecurrence = moment(endOfRecurrence).endOf("day");
        endOfRecurrence = commonService.convertToUTC(
          endOfRecurrence,
          "Y-MM-DD"
        );
        data.recurrence.endOfRecurrence =
          endOfRecurrence.format("Y-MM-DD HH:mm:ss");

        //add weekdays
        data.recurrence.weekDay = vm.eventWeekdays || [];
      }

      if (!_.isEmpty(vm.reminders)) {
        data.reminders = _.map(vm.reminders, function (reminder) {
          let selectedOption = _.find(reminder.options, { id: reminder.value });
          return _.pick(selectedOption, ["value", "unit"]);
        });
      }

      /**
       * Add chore todo list
       */
      data.appointment.todos = vm.todos;

      let timezone = -1 * new Date().getTimezoneOffset();
      appointmentService
        .createAppointment(timezone, data)
        .then((response) => {
          if (response.data.data.appointment) {
            vm.form.$setSubmitted();
          }
          $rootScope.$broadcast(
            "$hsp-update-files",
            response.data.data.appointment.id,
            "appointment"
          );
          // $rootScope.$broadcast("$hsp-update-files", response.data.data.appointment.id, 'appointment');
          if (_.isEmpty(data.recurrence)) {
            let event = calendarService.calendarDataTransform(
              "appointment",
              response.data.data.appointment
            );
            let transformedEvent = {
              entityId: event.entityId,
              event: event,
              addEvent: true,
            };
            calendarService.saveEventToList(event.entityId, event.model, event);
            toastService.toast({
              message: calendarConstants.messages.eventCreated,
              type: "success",
              delay: 3000,
            });
            vm.close(transformedEvent);
          } else {
            toastService.toast({
              message: calendarConstants.messages.eventCreated,
              type: "success",
              delay: 3000,
            });

            vm.category == "chores" ? close() : close({ refetchEvents: true });
          }
        })
        .catch((error) => {
          toastService.toast({
            message: error.data
              ? error.data.message
              : "some error may occur, please try again",
            type: "error",
            delay: 3000,
          });
        })
        .finally(() => {
          if (vm.reqPage && vm.reqPage == "dashboard") {
            $rootScope.loadingBtnComplete("createAppointment", "create");
          } else {
            $rootScope.loadingBtnComplete("createAppointment", "create");
            $state.go("calendar");
          }
        });
    }
  }

  function refetchEvents() {
    uiCalendarConfig.calendars[vm.calendarName].fullCalendar("refetchEvents");
  }

  function removeErrorMessage() {
    if (vm.appointmentTitle && vm.appointmentTitle.length > 1) {
      vm.emptyFieldError = false;
    }
  }

  function onEndDateChange() {
    setFormDirtyForDateTime("End");
    vm.dateDuration = moment(vm.appointmentEndDate, "Y-MM-DD").diff(
      moment(vm.appointmentStartDate, "Y-MM-DD"),
      "days"
    );
    vm.timeDuration = moment(vm.appointmentEndTime, "HH:mm").diff(
      moment(vm.appointmentStartTime, "HH:mm"),
      "seconds"
    );
  }

  function onStartDateChange(setPristine) {
    setFormDirtyForDateTime("Start");
    if (!vm.appointmentStartDate) {
      vm.appointmentStartDate = moment().format("Y-MM-DD");
    }

    if (!vm.appointmentStartTime) {
      vm.appointmentStartTime = calendarConstants.defaultStartTime;
    }

    vm.appointmentEndDate = moment(vm.appointmentStartDate, "Y-MM-DD")
      .add(vm.dateDuration, "day")
      .format("Y-MM-DD");
    vm.appointmentEndTime = moment(vm.appointmentStartTime, "HH:mm")
      .add(vm.timeDuration, "seconds")
      .format("HH:mm");
    // setRepeatOptions(vm.appointmentStartDate);
  }

  function onRecurrenceOptionChange() {
    if (vm.recurrence) {
      if (angular.isDefined(vm.recurrence.type) && !vm.recurrence.shorthand) {
        vm.recurrence.separationCount = 0;
      }

      vm.repeatEveryOptions = [];
      for (let i = 0; i < 30; i++) {
        let option = {
          title:
            i + 1 + " " + _.capitalize(vm.recurrence.type) + (i > 0 ? "s" : ""),
          separationCount: i,
        };
        vm.repeatEveryOptions.push(option);
      }
      onRepeatEndOptionChange();
    }
  }

  function onRepeatEndOptionChange() {
    if (vm.repeatEnd == "maxOccurrences") {
      vm.recurrence.maxOccurrences = calendarConstants.defaultMaxOccurrences;

      let offset =
        (vm.recurrence.separationCount + 1) * vm.recurrence.maxOccurrences;
      vm.recurrence.endOfRecurrence = moment(vm.appointmentStartDate).add(
        offset,
        vm.recurrence.type
      );
    } else {
      if (vm.recurrence.maxOccurrences) {
        delete vm.recurrence.maxOccurrences;
      }
    }

    if (vm.repeatEnd == "endOfRecurrence") {
      let offset =
        (vm.recurrence.separationCount + 1) * vm.recurrence.maxOccurrences;
      vm.recurrence.endOfRecurrence = moment(vm.appointmentStartDate).add(
        offset,
        vm.recurrence.type
      );
    } else {
      // delete vm.recurrence.endOfRecurrence;
    }
  }

  // function setRepeatOptions(date) {
  //     // let dynamicWeekOptionIndex = _.findIndex(vm.recurrenceOptions, { type: 'week', dynamic: true });
  //     // let dynamicMonthOptionIndex = _.findIndex(vm.recurrenceOptions, { type: 'month', dynamic: true });

  //     vm.recurrenceOptions[8].title = 'Every ' + moment(date).format('dddd');
  //     vm.recurrenceOptions[9].title = 'Every ' + moment(date).format('Do');
  // }

  function setFormDirtyForDateTime(element) {
    if (angular.isDefined(vm.form) && angular.isDefined(vm.form.$pristine)) {
      vm.form.$pristine = false;
      vm.form.$dirty = true;

      if (
        angular.isDefined(
          vm.form["appointment" + element + "Date"] &&
          vm.form["appointment" + element + "Time"]
        )
      ) {
        vm.form["appointment" + element + "Date"].$pristine = false;
        vm.form["appointment" + element + "Time"].$dirty = true;
      }
    }
  }

  $timeout(function () {
    vm.form.$pristine = true;
    vm.form.$dirty = false;
  }, 1000);

  this.$onInit = function () {
    if (options.startDate) {
      vm.appointmentStartDate = options.startDate
        ? options.startDate
        : moment().format("Y-MM-DD");
      vm.appointmentStartTime = options.startTime ? options.startTime : null;
      vm.onStartDateChange(true);
      //vm.isChoreOrEvent
      //console.log('value of title is:', vm.isChoreOrEvent);
    }
  };

  $scope.$on("modal.closing", function (evt) {
    if (vm.form.$dirty && !vm.form.$pristine && !vm.form.$submitted) {
      evt.preventDefault();
      let confirmData = {
        message: calendarConstants.messages.leavePageConfirmation,
        modalSize: "md",
        type: "danger",
      };
      commonService.confirm(confirmData).then(function (confirmation) {
        if (confirmation.result) {
          vm.form.$submitted = true;
          vm.close();
        }
      });
    }
  });
}

export default createAppointmentController;
