function ngEnter() {
  return function (scope, element, attrs) {
    element.bind("keypress", (event) => {
      if (event.which === 13) {
        scope.$apply(attrs.ngEnter);
        event.preventDefault();
      }
    });
    scope.$on("destroy", () => {
      element.unbind("keypress");
    });
  };
}
export default ngEnter;
