let angular = window.angular;
    confirmationUnassignBookModalController.$inject = ['bookConstants', 'bookData', '$scope', '$uibModalInstance', 'bookService', 'studentService', '$state', '$stateParams', '_', 'commonService', 'toastService', 'moment', 'lessonConstants', '$uibModal', 'calendarConstants'];

    function confirmationUnassignBookModalController(bookConstants, bookData, $scope, $uibModalInstance, bookService, studentService, $state, $stateParams, _, commonService, toastService, moment, lessonConstants, $uibModal, calendarConstants) {

        let vm = this;
        let returnData = {};
        vm.confirmUnassignValue = 'book';
        let assignment = bookData.assignment;
        vm.isDeleteAssignment = false;
        angular.extend(this, {
            close,
            submit
        });

        $scope.data = {};

        function close(data = returnData) {
            $uibModalInstance.close(data);
        }

        function getDeleteAssignmentStatus() {
            switch (vm.confirmUnassignValue) {
                case 'book':
                    vm.isDeleteAssignment = false;
                    break;
                case 'assignment':
                    vm.isDeleteAssignment = true;
                    break;
                default:
                    vm.isDeleteAssignment = false;
                    break;
            }
        }

        function submit() {
            getDeleteAssignmentStatus();
            bookService.unassignBook(assignment.assignId, vm.isDeleteAssignment)
                .then((response) => {
                    toastService.toast({
                        message: bookConstants.messages.bookUnassigned,
                        type: 'success',
                        delay: 3000
                    });
                })
                .catch((err) => {
                    // console.log("errror", err);
                })
                .finally(() => {
                    close();
                })
        }

        this.$onInit = function() {
        }
    }
export default confirmationUnassignBookModalController;
