let angular = window.angular;

export function datePickerDirective(moment, _) {
  return {
    require: "ngModel",
    link: function (scope, element, attrs, ngModel) {
      // let setDirtyFunc = ngModel.$setDirty;
      // ngModel.$setDirty = angular.noop;

      let config,
        isParserSet = false,
        isFormatterSet = false;
      try {
        config = JSON.parse(attrs.datepickerConfig);
      } catch (e) {
        config = {};
      }

      // scope.$watch(attrs.ngModel, function() {
      //     if (ngModel.$setDirty.toString() != setDirtyFunc.toString()) {
      //         ngModel.$setDirty = setDirtyFunc;
      //     }
      // });

      ngModel.$parsers.push(function (value) {
        if (value) {
          return moment(value, "L").format("Y-MM-DD");
        }
        return;
      });

      ngModel.$formatters.push(function (value) {
        if (value && value !== "Invalid date") {
          $(element).datepicker("setDate", moment(value).toDate());
          return moment(value).format("L");
        } else {
          $(element).datepicker(config);
        }
      });
    },
  };
}
datePickerDirective.$inject = ["moment", "_"];

export function datePickerSelectDirective(moment) {
  return {
    require: "ngModel",
    restrict: "AE",
    scope: {
      ngModel: "=",
    },
    replace: true,
    template: require("../../pug/components/datepicker-select.pug").default,
    link: linkFn,
    // link: function(scope, element, attrs, NgModelController) {

    //     let tmp = NgModelController.$setDirty;
    //     NgModelController.$setDirty = angular.noop;

    //     // Format backend value to the values suitable for view
    //     NgModelController.$formatters.push(function(modelValue) {
    //         if (modelValue) {
    //             let passedDate = moment(modelValue, 'Y-MM-DD');
    //             let day = passedDate.format('DD');
    //             let month = passedDate.format('MM');
    //             let year = passedDate.year();
    //             return { day, month, year };
    //         }
    //     });

    //     // Format view value to the value suitable for backend
    //     NgModelController.$parsers.push(function(viewValue) {
    //         if (scope.year && scope.month && scope.day) {
    //             let now = moment();
    //             let then = moment(scope.year + '-' + scope.month + '-' + scope.day, 'Y-MM-DD');
    //             // Prevent future dates to be selected when picking up birthdate.
    //             if (then.isAfter(now)) {
    //                 return;
    //             } else {
    //                 return scope.year + '-' + scope.month + '-' + scope.day;
    //             }
    //         } else {
    //             //						NgModelController.$setPristine();
    //             return '';
    //         }
    //     });

    //     // Update scope values from viewValues
    //     NgModelController.$render = function() {
    //         if (NgModelController.$viewValue) {
    //             scope.day = NgModelController.$viewValue.day;
    //             scope.month = NgModelController.$viewValue.month;
    //             scope.year = NgModelController.$viewValue.year;
    //         }
    //     };

    //     // Run digest cycle when a view value changes
    //     scope.$watch('day + month + year', function() {
    //         NgModelController.$setViewValue({
    //             day: scope.day,
    //             month: scope.month,
    //             year: scope.year
    //         });
    //         NgModelController.$setDirty = tmp;
    //     });
    // }
  };
}
datePickerSelectDirective.$inject = ["moment"];
function linkFn($scope, element, attrs, NgModelController) {
  /**
   * Stores a list of years
   * @type {number[]}
   */
  $scope.years = [];

  /**
   * Stores the reference to the $setDirty method of the NgModelController.
   * @type {NgModel.$setDirty}
   */
  let tmp = NgModelController.$setDirty;

  // Temporarily set the $setDirty function to noop, to make sure form-validations work properly
  NgModelController.$setDirty = angular.noop;

  // To set $touched of day, month and year dropdown for validations
  let childElmn = element.children();

  for (var i = 0; i < childElmn.length; i++) {
    angular.element(childElmn[i]).on("focus", function () {
      return NgModelController.$setTouched();
    });
  }

  /**
   * Populates a list of years, i.e. all the years from 100 years from now.
   * @function populateYearList
   * @name linkFn~populateYearList
   */
  function populateYearList() {
    let currentYear = new Date().getFullYear();
    for (let i = currentYear - 100; i <= currentYear; i++) {
      $scope.years.push(i);
    }
  }

  // set $valid for validations

  NgModelController.$validators.validateDate = function (
    modelValue,
    viewValue
  ) {
    if (
      !modelValue ||
      (!viewValue.year && !viewValue.month && !viewValue.day)
    ) {
      return true;
    }
    let date =
      modelValue ||
      viewValue.year + "-" + viewValue.month + "-" + viewValue.day;

    return moment(date, "Y-MM-DD").isValid();
  };

  NgModelController.$validators.required = function (modelValue, viewValue) {
    // Check if required attrs is present
    // If it is present, check the model value, else short-circuit and return true, to bypass the validation
    return attrs.required ? !!modelValue : true;
  };

  // Format backend value to the values suitable for view
  NgModelController.$formatters.push(function (modelValue) {
    if (modelValue) {
      let passedDate = moment(modelValue, "Y-MM-DD");
      let day = passedDate.format("DD");
      let month = passedDate.format("MM");
      let year = passedDate.year();
      return { day, month, year };
    } else {
      return { day: "", month: "", year: null }; //year has data type: number
    }
  });

  // Format view value to the value suitable for backend
  NgModelController.$parsers.push(function (viewValue) {
    if ($scope.year && $scope.month && $scope.day) {
      let now = moment();
      let then = moment(
        $scope.year + "-" + $scope.month + "-" + $scope.day,
        "Y-MM-DD"
      );
      if (then.isAfter(now)) {
        return;
      } else {
        return $scope.year + "-" + $scope.month + "-" + $scope.day;
      }
    } else {
      return "";
    }
  });

  // Update $scope values from viewValues
  NgModelController.$render = function () {
    if (NgModelController.$viewValue) {
      $scope.day = NgModelController.$viewValue.day;
      $scope.month = NgModelController.$viewValue.month;
      $scope.year = NgModelController.$viewValue.year;
    }
  };

  // Run digest cycle when a view value changes
  $scope.$watch("day + month + year", function () {
    NgModelController.$setViewValue({
      day: $scope.day,
      month: $scope.month,
      year: $scope.year,
    });
    NgModelController.$setDirty = tmp;
  });

  populateYearList();
}
