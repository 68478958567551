const angular = window.angular;
collaborateSearchController.$inject = [
  "$rootScope",
  "$scope",
  "$stateParams",
  "friendService",
  "$uibModal",
  "commonService",
  "$window",
  "$uibModalStack",
];
function collaborateSearchController(
  $rootScope,
  $scope,
  $stateParams,
  friendService,
  $uibModal,
  commonService,
  $window,
  $uibModalStack
) {
  const defaultFriendRequestMessage = " has sent a friend request";

  let vm = this,
    modelInstance,
    selectedUser;
  vm.searchText = null;

  vm.userList = [];
  vm.inviteMessage = null;
  vm.userParam = {};

  angular.extend(this, {
    openFriendRequestDialog,
    sendInvitation,
    closeFriendRequestDialog,
  });

  function closeFriendRequestDialog() {
    try {
      modelInstance.close();
    } catch (err) {
      $uibModalStack.dismissAll();
    }
  }

  function sendInvitation() {
    if (!selectedUser || !selectedUser.id) {
      selectedUser = vm.userParam;
    }

    if (!selectedUser.id) {
      return;
    }

    friendService
      .sendInvitation(selectedUser.id, vm.inviteMessage)
      .then((response) => {
        closeFriendRequestDialog();

        // commonService.removeFromArray(selectedUser.id, 'id', vm.userList);
        let findIndex = vm.userList.findIndex(
          (obj) => obj.id == selectedUser.id
        );
        if (findIndex != -1) {
          vm.userList[findIndex].sendInvitation = true;
        }
        selectedUser = {};
      })
      .catch((response) => {
        $uibModalStack.dismissAll();
      });
  }

  function openFriendRequestDialog(user) {
    vm.inviteMessage = $rootScope.user.name + defaultFriendRequestMessage;
    selectedUser = user;
    modelInstance = $uibModal.open({
      animation: true,
      template: require("../../pug/collaborate/add-friend.pug").default,
      scope: $scope,
    });
    modelInstance.result.catch(function () {
      modelInstance.close();
      $uibModalStack.dismissAll();
    });
  }

  function initialize() {
    // if (feedParam) {
    // 	vm.userParam = feedParam.userParam;
    // 	vm.inviteMessage = feedParam.inviteMessage;
    // }

    vm.searchText = $stateParams.searchText;
    vm.location = $stateParams.location;
    vm.interests = $stateParams.interests;
    vm.faith = $stateParams.faith;
    vm.teachingStyle = $stateParams.teachingStyle;
    vm.gender = $stateParams.gender;
    // if (!vm.searchText) {
    // 	return;
    // }
    let filterObj = {};
    if (vm.location) filterObj.location = vm.location;
    if (vm.interests) filterObj.interests = vm.interests;
    if (vm.faith) filterObj.faith = vm.faith;
    if (vm.teachingStyle) filterObj.teachingStyle = vm.teachingStyle;
    if (vm.gender) filterObj.gender = vm.gender;

    // console.log("filterOjb",filterObj)
    friendService
      .searchFilter(vm.searchText, filterObj)
      .then((response) => {
        vm.userList = response.data.data;
      })
      .catch((response) => {
        // console.log(response);
      });
  }

  this.$onInit = initialize;
}

export default collaborateSearchController;
