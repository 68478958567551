export const userConstants ={
	referralUrl: "https://app.homeschoolpanda.com/referral/",
	messages: {
		passwordLengthValidation: 'Password must be at least six character long',
		confirmPasswordValidation: 'Confirm password should be same as new password',
		changePasswordSuccess: 'Your password is changed successfully',
		changePasswordFailure: 'The existing password you have provided is not valid',
		profilePictureUpdateSuccess: 'Your profile picture has been updated',
		// userContactInvalid: 'Your contact number is invalid',
		// userDobInvalid: 'Your date of birth is invalid',
		// profileAddressInvalid: 'Your street address is invalid',
		profileUpdateSuccess: 'Your profile has been updated',
		profileUpdateError : 'Error while updating profile',
		leavePageConfirmation: 'The changes you made will be lost if you navigate away.\nAre you sure you want to leave this page?',
		userDobInvalid: 'User date of birth is invalid',
		userContactInvalid: 'User contact number is invalid',
		profileAddressInvalid: 'User address is invalid',
		genderError: 'Please select gender'
	}
}
