let angular = window.angular;
lessonViewModalController.$inject = [
  "$rootScope",
  "$uibModal",
  "$scope",
  "$window",
  "lessonConstants",
  "lessonService",
  "$state",
  "commonService",
  "stateHistory",
  "toastService",
  "data",
];
function lessonViewModalController(
  $rootScope,
  $uibModal,
  $scope,
  $window,
  lessonConstants,
  lessonService,
  $state,
  commonService,
  stateHistory,
  toastService,
  data
) {
  let vm = this;

  angular.extend(vm, {
    previewLesson,
  });

  function previewLesson() {
    let request = "admin";
    let exportId = null;
    let lessonId = vm.lessonsDetails.lesson.id;
    let userId = vm.lessonsDetails.lesson.user.id;
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "lessonPreviewController",
      controllerAs: "lessonPreviewCtrl",
      template: require("../../pug/lesson/preview-lesson.pug").default,
      resolve: {
        lessonData: {
          adminPreview: {
            exportId,
            lessonId,
            request,
            userId,
          },
          activeTab: "assignment",
        },
      },
    });
    modelInstance.result.catch(function () {
      modelInstance.close();
    });
    // lessonService.previewLesson(exportId, lessonId, request, userId)
    // .then((response)=>{
    // 	/**
    // 	 * Open model with the given data
    // 	 */

    // 	// toastService.toast({message: lessonConstants.messages.lessonImported, type: 'success', delay: 2000});
    // })
    // .catch((error)=>{
    // 	toastService.toast({message: error, type: 'error', delay: 2000});
    // })
  }

  function init() {
    vm.lessonsDetails = data;
  }

  this.$onInit = init;
}

export default lessonViewModalController;
