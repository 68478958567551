let angular = window.angular;
stateHistory.$inject = ["$rootScope", "$state", "trackEvents"];
function stateHistory($rootScope, $state, trackEvents) {
  /**
   * The fullHistory array stores the sequence of states, as the user traverses the application. The array is used to help the user navigate through
   * the application when the user presses the back button.
   * @type Array
   */
  let fullHistory = [];

  angular.extend(this, {
    getHistory,
  });
  $rootScope.goBack = goBack;

  $rootScope.$on(
    "$stateChangeStart",
    function (evt, toState, toParams, fromState) {
      $rootScope.isLoading = true;
    }
  );

  $rootScope.$on(
    "$stateChangeError",
    function (event, toState, toParams, fromState, fromParams, error) {
      $rootScope.isLoading = false;
      // console.log('Error while changing state.');
    }
  );

  $rootScope.$on(
    "$stateNotFound",
    function (event, unfoundState, fromState, fromParams) {
      // Send to error state
      $rootScope.isLoading = false;
      // console.log('Requested state not found.');
    }
  );

  $rootScope.$on(
    "$stateChangeSuccess",
    function (event, to, toParams, from, fromParams) {
      let stateObject = {
        state: from,
        stateParams: fromParams,
      };
      fullHistory.push(stateObject);
      $rootScope.isLoading = false;
      /**
       * Send tracking information to intercom and google
       */
      trackEvents.send(to.url);
      trackEvents.sendGAPageView(to);
    }
  );

  function goBack() {
    let top;
    if (fullHistory.length) {
      top = fullHistory.length - 1;
      if (fullHistory[top].state.name) {
        return $state.go(fullHistory[top].state, fullHistory[top].stateParams);
      }
    }
    history.back();
  }

  function getHistory() {
    return fullHistory;
  }
}

export default stateHistory;
