let angular = window.angular;
emojiPickerMessengerDirective.$inject = ["$window", "$timeout"];
function emojiPickerMessengerDirective($window, $timeout) {
  return {
    require: "ngModel",
    scope: {
      ngModel: "=",
      onEnter: "&",
      isDisabled: "=",
      ngKeyup: "&",
      ngKeydown: "&",
      ngBlur: "&",
    },
    link: function (scope, element, attr, ngModel) {
      let emojiPickerDiv,
        emojiPickerButton,
        sendMessageInput,
        eventWatchers = {};
      element.attr("data-emoji-input", "unicode");
      element.attr("data-emojiable", "true");
      function setUpEventListeners() {
        emojiPickerDiv = angular.element(
          document.getElementsByClassName("emoji-wysiwyg-editor")
        );
        emojiPickerButton = angular.element(
          document.getElementsByClassName("emoji-picker-icon")
        );
        emojiPickerDiv.attr("ui-mention", "");
        emojiPickerDiv.attr("mention-example", "");
        /**
         * Used to update the ngModel, when the div content is updated by the emojiPicker.
         */
        emojiPickerDiv.on("blur", (event) => {
          scope.ngBlur();
        });
        emojiPickerDiv.on("keyup", (event) => {
          // var element =
          // 	typeof event === "object" ? event.target : document.getElementById(event);
          // console.log('scroll height', element)
          // var scrollHeight = element.scrollHeight; // replace 60 by the sum of padding-top and padding-bottom
          // element.style.height = scrollHeight + "px";
          scope.ngKeyup();
        });
        emojiPickerDiv.on("keydown", (event) => {
          // Update ngModel
          let text = event.target.innerText;
          scope.ngKeydown();
          //						scope.ngModel = text;
          $timeout(() => {
            if (event.which === 13) {
              (scope.onEnter || angular.noop)({
                messageBody: text,
              });
              scope.ngBlur();
              emojiPickerDiv[0].innerText = "";
              event.preventDefault();
            }
            scope.$apply();
          }, 0);
        });

        eventWatchers.ngModelWatch = scope.$watch("ngModel", (event) => {
          if (!scope.ngModel && emojiPickerDiv[0].innerText != "") {
            $timeout(() => {
              emojiPickerDiv[0].innerText = "";
              scope.$apply();
            }, 0);
          }
        });

        eventWatchers.isDisabledWatch = scope.$watch("isDisabled", () => {
          if (scope.isDisabled) {
            emojiPickerDiv.attr("disabled", "");
            emojiPickerDiv.removeAttr("contenteditable");
            emojiPickerButton.attr("disabled", "");
          } else {
            emojiPickerDiv.removeAttr("disabled");
            emojiPickerDiv.attr("contenteditable", !scope.isDisabled);
            emojiPickerButton.removeAttr("disabled");
          }
        });
      }

      function initializeEmojiPicker() {
        // Initializes and creates emoji set from sprite sheet
        $window.emojiPicker = new EmojiPicker({
          emojiable_selector: "[data-emojiable=true]",
          assetsPath: "/images/emoji-picker/",
        });

        // Finds all elements with `emojiable_selector` and converts them to rich emoji input fields
        // You may want to delay this step if you have dynamically created input fields that appear later in the loading process
        // It can be called as many times as necessary; previously converted input fields will not be converted again
        $window.emojiPicker.discover();
        $timeout(() => {
          scope.$apply();
          setUpEventListeners();
        }, 0);
      }

      scope.$on("destroy", function () {
        eventWatchers.ngModelWatch();
        eventWatchers.isDisabledWatch();
        emojiPickerDiv.off("keydown");
        emojiPickerDiv.off("keyup");
        emojiPickerDiv.off("blur");
      });

      initializeEmojiPicker();
    },
  };
}
export default emojiPickerMessengerDirective;
