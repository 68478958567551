function ngDropDown() {
  return function (scope, element, attrs) {
    element.bind("keydown keypress", function (event) {
      if (event.which === 13) {
        scope.$apply(attrs.ngDropDown);
        event.preventDefault();
      }
    });

    element.bind("focus", function (event) {
      var a, b;
      a = document.createElement("DIV");
      a.setAttribute("id", this.id + "autocomplete-list");
      a.setAttribute("class", "autocomplete-items");
      /*append the DIV element as a child of the autocomplete container:*/
      this.parentNode.appendChild(a);
      b = document.createElement("DIV");
      b.innerHTML = "<strong> Hello</strong>";
      a.appendChild(b);
    });

    // scope.$on("destroy", () => {
    // 	element.unbind("keypress");
    // });
  };
}

export default ngDropDown;
