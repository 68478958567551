import addBookTemplate from "../../pug/book/add-book.pug";
import addHelperTemplate from "../../pug/student/add-helper.pug";
// import addLessonTemplate from "../../pug/lesson/add-lesson.pug";
import addStudentTemplate from "../../pug/student/add-student.pug";
import adminConfirmedCancellationTemplate from "../../pug/user/admin-confirmed-cancellations.pug";
import adminMetricTemplate from "../../pug/user/admin-metrics.pug";
import adminPendingCancellationTemplate from "../../pug/user/admin-pending-cancellations.pug";
import adminTemplate from "../../pug/user/admin.pug";
import amazonPlanTemplate from "../../pug/paymentplan/amazon-plan.pug";
import approvedAffiliatesTemplate from "../../pug/user/approved-affiliates.pug";
import assignBookTemplate from "../../pug/book/assign-book.pug";
import attendenceReportTemplate from "../../pug/report/attendance-report.pug";
import bookListTemplate from "../../pug/book/book-list.pug";
import budgetDetailTemplate from "../../pug/budget/budget-detail.pug";
// import calendarTemplate from "../../pug/calendar/calendar.pug";
import collaborateChatDetail from "../../pug/collaborate/chat-detail.pug";
import collaborateCreateChat from "../../pug/collaborate/create-chat.pug";
import collaborateLayoutTemplate from "../../pug/collaborate/layout.pug";
import collaborateSearchParent from "../../pug/collaborate/search-parents.pug";
import dashboardTemplate from "../../pug/dashboard/dashboard.pug";
import editHelperTemplate from "../../pug/student/edit-helper.pug";
// import editLessonTemplate from "../../pug/lesson/edit-lesson.pug";
import editProfileTemplate from "../../pug/user/edit-profile.pug";
import editStudentTemplate from "../../pug/student/edit-student.pug";
import emptyViewTemplate from "../../pug/layout/empty-view.pug";
import errorTemplate from "../../pug/error/404.pug";
import feedHeaderTemplate from "../../pug/feed/header-layout.pug";
import feedLayoutemplate from "../../pug/feed/layout.pug";
// import feedTestemplate from "../../pug/feed/feed-test.pug";
import freeMonthRequestTemplate from "../../pug/user/free-month-requests.pug";
import gradeReportTemplate from "../../pug/report/grade-report.pug";
import importLessonTemplate from "../../pug/lesson/import-lesson-page.pug";
import layout from "../../pug/layout/layout.pug";
import layoutBudgetTemplate from "../../pug/budget/layout.pug";
import layoutStudentTemplate from "../../pug/student/layout.pug";
import lessonImportAdminTemplate from "../../pug/user/lesson-import-admin.pug";
import lessonImportDisapproveTemplate from "../../pug/user/lesson-import-disapprove.pug";
import lessonList from "../../pug/lesson/list-lesson.pug";
import lessonViewModalTemplate from "../../pug/user/lesson-view-modal.pug";
import listFeedTemplate from "../../pug/feed/list-feed.pug";
import paymentPlanTemplate from "../../pug/paymentplan/plan.pug";
import pendingAffiliatesTemplate from "../../pug/user/pending-affiliates.pug";
import permissionDeniedTemplate from "../../pug/error/permissionDenied.pug";
import planErrorTemplate from "../../pug/paymentplan/plan-error.pug";
import planWebhookTemplate from "../../pug/paymentplan/plan-webhook.pug";
import portfolioReportTemplate from "../../pug/report/portfolio-report.pug";
import refferalDetailsTemplate from "../../pug/referralplan/referral-details.pug";
import reportPostTemplate from "../../pug/user/report-post.pug";
import reportedUsersTemplate from "../../pug/user/reported-users.pug";
import searchBookTemplate from "../../pug/book/search-book.pug";
import studentDetailTemplate from "../../pug/student/student-detail.pug";
import transcriptReportTemplate from "../../pug/report/transcript-report.pug";
import userAffiliateTemplate from "../../pug/user/user-affiliate.pug";
import userFeedTemplate from "../../pug/feed/user-feed.pug";
import userInfoTemplate from "../../pug/student/userInfo-detail.pug";
import userProfileTemplate from "../../pug/user/user-profile.pug";
import userRefferalListTemplate from "../../pug/user/user-referral-list.pug";
import userRefferalTemplate from "../../pug/user/user-referral.pug";

//let angular = window.angular;
const document = window.document;

appConfig.$inject = [
  "$compileProvider",
  "$urlRouterProvider",
  "$stateProvider",
  "$locationProvider",
  "StoreProvider",
  "$httpProvider",
  "$provide",
];

function appConfig(
  $compileProvider,
  $urlRouterProvider,
  $stateProvider,
  $locationProvider,
  StoreProvider,
  $httpProvider,
  $provide
) {
  /**
   * $location settings
   */
  $locationProvider.html5Mode({
    enabled: true,
    requireBase: false,
  });

  /**
   * $compile settings
   */
  $compileProvider.aHrefSanitizationWhitelist(
    /^\s*(https?|file|skype|mailto):/
  );
  $compileProvider.debugInfoEnabled(false);
  $compileProvider.commentDirectivesEnabled(false);

  /**
   * $provide settings
   */

  $provide.decorator("$exceptionHandler", [
    "$delegate",
    "$injector",
    function ($delegate, $injector) {
      return function (exception, cause) {
        let exceptionsToIgnore = [
          "Possibly unhandled rejection: backdrop click",
          "Possibly unhandled rejection: cancel",
          "Possibly unhandled rejection: escape key press",
          "Possibly unhandled rejection: undefined",
          "Possibly unhandled rejection: null",
        ];
        if (exceptionsToIgnore.indexOf(exception) >= 0) {
          return;
        }
        $delegate(exception, cause);
      };
    },
  ]);

  /**
   * $http settings
   */

  const store = StoreProvider.$get();
  const accessToken = store.get("authorizationToken");
  //

  // const accessToken = localStorage.getItem("authorizationToken");
  $httpProvider.defaults.cache = false;
  $httpProvider.defaults.headers.common["X-Access-Token"] = accessToken;
  $httpProvider.defaults.headers.common["Content-Type"] =
    "application/json;charset=utf-8";
  $httpProvider.defaults.headers.common["Accept"] = "application/json";
  $httpProvider.useApplyAsync(true);
  // $httpProvider.interceptors.push('middleware');

  /**
   * For IE Caching Bug - It caches all the GET request.
   */
  let isIE = /*@cc_on!@*/ false || !!document.documentMode; // Internet Explorer 6-11

  if (isIE) {
    if (!$httpProvider.defaults.headers.get) {
      $httpProvider.defaults.headers.get = {};
    }
    $httpProvider.defaults.headers.get["Cache-Control"] = "no-cache";
    $httpProvider.defaults.headers.get["If-Modified-Since"] = "0";
  }

  /**
   * State configurations
   */
  $stateProvider
    .state("index", {
      abstract: true,
      template: layout,
      controller: "appController as appCtrl",
    })
    .state("dashboard", {
      url: "/dashboard",
      parent: "index",
      controller: "dashboardController as dashCtrl",
      template: dashboardTemplate,
    })
    // .state({
    //   name: "calendar",
    //   url: "/calendar?entityId&model&occurrenceId&status",
    //   parent: "index",
    //   controller: "calendarController as calCtrl",
    //   template: calendarTemplate,
    //   resolve: {
    //     foo: ['$q', '$ocLazyLoad', function ($q, $ocLazyLoad) {
    //       let deferred = $q.defer();
    //       require.ensure([], function () {
    //         let module = require('../calendar/calendar.module');
    //         $ocLazyLoad.load({
    //           name: 'calendar'
    //         });

    //         return deferred.promise;
    //       },
    //     ],
    //   },
    // })
    .state({
      name: "calendar",
      url: "/calendar?entityId&model&occurrenceId&status",
      parent: "index",
      controller: "calendarv2Controller as calCtrl",
      template: require("../../pug/React-Calendar/Calendar.pug").default,
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../../react-calendar/calendar.module");
              $ocLazyLoad.load({
                name: "reactCalendar",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state({
      name: "chores",
      url: "/chores",
      parent: "index",
      controller: "choresController as choresCtrl",
      template:
        '<div class="chores-wrapper"><chores-directive root-scope="choresCtrl.rootScope" go-to-chores="choresCtrl.goToChores"></chores-directive></div>',
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../../react-chores/chores.module");
              $ocLazyLoad.load({
                name: "reactChores",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state({
      name: "rescheduler",
      url: "/calendar/rescheduler",
      parent: "index",
      controller: "reactReschedulerController as reschedulerCtrl",
      template:
        '<div class="rescheduler-wrapper"><rescheduler-directive  root-scope="reschedulerCtrl.rootScope" go-to-calendar="reschedulerCtrl.goToCalendar"></rescheduler-directive></div>',
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../../react-rescheduler/ReactRescheduler.module");
              $ocLazyLoad.load({
                name: "reactRescheduler",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state("lessonList", {
      url: "/lesson",
      parent: "index",
      controller: "lessonController as lessonCtrl",
      template: lessonList,
      //   resolve: {
      //     redirectWithoutSubscription: "redirectWithoutSubscription",
      //   },
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../lesson/lesson.module");
              $ocLazyLoad.load({
                name: "lesson",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    // .state("lessonAdd", {
    //   url: "/lesson/add",
    //   parent: "index",
    //   controller: "lessonAddController as lessonAddCtrl",
    //   template: addLessonTemplate,
    //   //   resolve: {
    //   //     redirectWithoutSubscription: "redirectWithoutSubscription",
    //   //   },
    // })
    .state("lessonAdd", {
      url: "/lesson/add",
      parent: "index",
      controller: "reactCreateLessonController as createCtrl",
      template:
        '<div class="lesson-wrapper"><lesson-form-directive go-to-lesson="createCtrl.goToLesson" lesson-service="createCtrl.lessonService" assign-students="createCtrl.assignStudents" show-warning-message="createCtrl.showWarningMessage" root-scope="createCtrl.rootScope"></lesson-form-directive></div>',
      //   resolve: {
      //     redirectWithoutSubscription: "redirectWithoutSubscription",
      //   },
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../../Lesson-Planner/createLesson/ReactLesson.module");
              $ocLazyLoad.load({
                name: "reactCreateLessonPlanner",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    // .state("lessonEdit", {
    //   url: "/lesson/{lessonId:[0-9]+}/edit",
    //   parent: "index",
    //   params: {
    //     studentId: null,
    //     isGroup: null,
    //     origin: null,
    //   },
    //   controller: "lessonEditController as lessonEditCtrl",
    //   template: editLessonTemplate,
    //   //   resolve: {
    //   //     redirectWithoutSubscription: "redirectWithoutSubscription",
    //   //   },
    // })
    .state("lessonEdit", {
      url: "/lesson/{lessonId:[0-9]+}/edit",
      parent: "index",
      params: {
        studentId: null,
        isGroup: null,
        origin: null,
      },
      controller: "reactEditLessonController as editCtrl",
      template:
        '<div class="lesson-wrapper"><lesson-edit-form-directive go-to-lesson="editCtrl.goToLesson" lesson-id="editCtrl.lessonId" lesson-service="editCtrl.lessonService" root-scope="editCtrl.rootScope"></lesson-edit-form-directive></div>',
      //   resolve: {
      //     redirectWithoutSubscription: "redirectWithoutSubscription",
      //   },
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../../Lesson-Planner/EditLesson/EditLesson.module");
              $ocLazyLoad.load({
                name: "reactEditLessonPlanner",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state("lessonImport", {
      url: "/lesson/import",
      parent: "index",
      controller: "lessonImportController as lessonImportCtrl",
      template: importLessonTemplate,
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../lesson/lesson.module");
              $ocLazyLoad.load({
                name: "lesson",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state("bookList", {
      url: "/books",
      parent: "index",
      controller: "bookController as bookCtrl",
      template: bookListTemplate,
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
    })
    .state("bookAdd", {
      url: "/books/addbook",
      parent: "index",
      controller: "bookAddController as bookAddCtrl",
      template: addBookTemplate,
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
    })
    .state("bookSearch", {
      url: "/books/search",
      parent: "index",
      controller: "bookSearchController as bookSearchCtrl",
      template: searchBookTemplate,
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
    })
    .state("bookAssign", {
      url: "/books/{bookId:[0-9]+}/assign",
      parent: "index",
      controller: "bookAssignController as bookAssignCtrl",
      template: assignBookTemplate,
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
    })
    .state("budget", {
      abstract: true,
      parent: "index",
      controller: "budgetController as budgetCtrl",
      template: layoutBudgetTemplate,
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
      // resolve: {
      //   loadMyService: ['$ocLazyLoad', function($ocLazyLoad) {
      //            return $ocLazyLoad.load('../budget/budgetController.js');
      //   }]
      // }
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../budget/budget.module.js");
              $ocLazyLoad.load({
                name: "budget",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state("budgetDetails", {
      url: "/budget/{budgetId:[0-9]+}",
      controller: "budgetDetailsController as budgetDetailCtrl",
      parent: "budget",
      template: budgetDetailTemplate,
      // resolve: {
      //   'redirectToOverallBudget': 'redirectToOverallBudget'
      // }
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../budget/budget.module.js");
              $ocLazyLoad.load({
                name: "budget",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state("overAllBudgetDetails", {
      url: "/budget",
      controller: "budgetDetailsController as budgetDetailCtrl",
      parent: "budget",
      template: budgetDetailTemplate,
      // resolve: {
      //   'redirectToOverallBudget': 'redirectToOverallBudget'
      // }
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../budget/budget.module.js");
              $ocLazyLoad.load({
                name: "budget",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    //panda posta

    .state("feedsHeader", {
      abstract: true,
      parent: "index",
      controller: "feedHeaderController as feedHeaderCtrl",
      template: feedHeaderTemplate,
      // resolve: {
      //   redirectWithoutSubscription: "redirectWithoutSubscription",
      // },
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../feed/feed.module");
              $ocLazyLoad.load({
                name: "feed",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state("feeds", {
      abstract: true,
      parent: "feedsHeader",
      controller: "feedLayoutController as feedLayoutCtrl",
      template: feedLayoutemplate,
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../feed/feed.module");
              $ocLazyLoad.load({
                name: "feed",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state("feedList", {
      url: "/pandapost",
      parent: "feeds",
      params: {
        name: "",
        location: "",
        interests: "",
        faith: "",
        teachingStyle: "",
        gender: "",
      },
      controller: "feedController as feedCtrl",
      template: listFeedTemplate,
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../feed/feed.module");
              $ocLazyLoad.load({
                name: "feed",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state("feedUserView", {
      url: "/pandapost/user/{userId:[\\d\\w]+}",
      parent: "feeds",
      controller: "feedUserController as feedUserCtrl",
      template: userFeedTemplate,
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../feed/feed.module");
              $ocLazyLoad.load({
                name: "feed",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state("feedViewSingle", {
      url: "/pandapost/{feedId:[0-9]+}",
      parent: "feedsHeader",
      controller: "feedViewSingleController as feedViewSingleCtrl",
      template: require("../../pug/feed/view-single-feed.pug").default,
      // resolve: {
      //   redirectWithoutSubscription: "redirectWithoutSubscription",
      // },
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../feed/feed.module");
              $ocLazyLoad.load({
                name: "feed",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state("feedGroupView", {
      url: "/pandapost/group/{groupId:[0-9]+}",
      parent: "feeds",
      controller: "groupController as groupCtrl",
      template: require("../../pug/feed/group-list.pug").default,
      params: {
        memberTab: null,
        privateGroup: null,
      },
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../feed/feed.module");
              $ocLazyLoad.load({
                name: "feed",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state("eventViewSingle", {
      url: "/pandapost/group/event/{eventId:[0-9]+}",
      parent: "feeds",
      controller: "groupEventSingleController as groupEventSingleCtrl",
      template: require("../../pug/feed/group-event-single.pug").default,
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../feed/feed.module");
              $ocLazyLoad.load({
                name: "feed",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state("groupJoin", {
      url: "/pandapost/group/join",
      parent: "feeds",
      controller: "groupJoinController as groupJoinCtrl",
      template: require("../../pug/feed/group-join.pug").default,
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../feed/feed.module");
              $ocLazyLoad.load({
                name: "feed",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state("eventJoin", {
      url: "/pandapost/event/join",
      parent: "feeds",
      controller: "groupEventJoinController as groupEventJoinCtrl",
      template: require("../../pug/feed/group-event-join.pug").default,
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../feed/feed.module");
              $ocLazyLoad.load({
                name: "feed",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    // Collaborate Routes
    .state("collaborate", {
      url: "/collaborate",
      abstract: true,
      parent: "index",
      controller: "collaborateController as colbrtCtrl",
      template: collaborateLayoutTemplate,
      // resolve: {
      // 	'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
    })
    .state("collaborateSearch", {
      url: "/search?searchText",
      parent: "collaborate",
      params: {
        searchText: "",
        name: "",
        location: "",
        interests: "",
        faith: "",
        teachingStyle: "",
        gender: "",
      },
      controller: "collaborateSearchController as searchCtrl",
      template: collaborateSearchParent,
      // resolve: {
      // 	'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
    })
    .state("collaborateCreate", {
      url: "/create",
      parent: "collaborate",
      controller: "chatCreateController as createChatCtrl",
      template: collaborateCreateChat,
      // resolve: {
      // 	'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
    })
    .state("collaborateViewChat", {
      url: "/chat/{chatId:[\\d\\w]+}",
      parent: "collaborate",
      controller: "chatViewController as chatCtrl",
      template: collaborateChatDetail,
      // resolve: {
      // 	'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
    })

    // User routes
    .state("userProfile", {
      url: "/user/profile/{userId:[0-9]*}",
      parent: "index",
      controller: "userController as userCtrl",
      template: userProfileTemplate,
    })
    .state("userProfileEdit", {
      url: "/user/profile/edit",
      parent: "index",
      controller: "userEditController as userEditCtrl",
      template: editProfileTemplate,
    })
    .state("report", {
      url: "/report",
      parent: "index",
      abstract: true,
      template: emptyViewTemplate,
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../report/report.module.js");
              $ocLazyLoad.load({
                name: "report",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state("reportCard", {
      url: "/report-card",
      parent: "report",
      controller: "reactReportCardController as reportCardCtrl",
      template:
        '<div><report-card-directive  root-scope="reportCardCtrl.rootScope"></report-card-directive></div>',
      // resolve: {

      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              const reportModule = require("../../HSP-Reports/ReportCard/ReportCard.module");
              $ocLazyLoad.load({
                name: "reactReportCard",
              });
              deferred.resolve(reportModule);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state("reactGradeBook", {
      name: "reactGradeBook",
      url: "/gradebook",
      parent: "index",
      controller: "reactGradeBookController as gradeBookCtrl",
      template:
        '<div><grade-book-directive  root-scope="gradeBookCtrl.rootScope"></grade-book-directive></div>',
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../../GradeBook/GradeBook.module");
              $ocLazyLoad.load({
                name: "reactGradeBook",
              });
              deferred.resolve(module);
            });
            return deferred.promise;
          },
        ],
      },
    })
    .state("reactAttendanceReport", {
      name: "reactAttendanceReport",
      url: "/newAttendance",
      parent: "report",
      controller: "attendanceReportController as attenReportrCtrl",
      template:
        '<div class="attendance-report-wrapper"><attendance-report-directive  root-scope="attenReportrCtrl.rootScope" go-to-attendance-report="attenReportrCtrl.goToAttendanceReport"></attendance-report-directive></div>',
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../../HSP-Reports/AttendanceReport/AttendanceReport.module");
              $ocLazyLoad.load({
                name: "reactAttendanceReport",
              });
              deferred.resolve(module);
            });
            return deferred.promise;
          },
        ],
      },
    })

    .state("reactPortfolioReport", {
      name: "reactPortfolioReport",
      url: "/portfolio",
      parent: "report",
      controller: "portfolioReportController as attenReportrCtrl",
      template:
        '<div class="portfolio-report-wrapper"><portfolio-report-directive  root-scope="attenReportrCtrl.rootScope" go-to-attendance-report="attenReportrCtrl.goToAttendanceReport"></-report-directive></div>',
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../../HSP-Reports/PortfolioReport/portfolioReport.module");
              $ocLazyLoad.load({
                name: "reactPortfolioReport",
              });
              deferred.resolve(module);
            });
            return deferred.promise;
          },
        ],
      },
    })

    .state("gradeReport", {
      url: "/grade",
      parent: "report",
      controller: "gradeReportController as gradeReportCtrl",
      template: gradeReportTemplate,
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../report/report.module.js");
              $ocLazyLoad.load({
                name: "report",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state("attendanceReport", {
      url: "/attendance",
      parent: "report",
      controller: "attendanceReportController as attendReportCtrl",
      template: attendenceReportTemplate,
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../report/report.module.js");
              $ocLazyLoad.load({
                name: "report",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state("portfolioReport", {
      url: "/portfolio",
      parent: "report",
      controller: "portfolioReportController as portfolioReportCtrl",
      template: portfolioReportTemplate,
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../report/report.module.js");
              $ocLazyLoad.load({
                name: "report",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state("transcriptReport", {
      url: "/transcript",
      parent: "report",
      controller: "transcriptReportController as transcriptReportCtrl",
      template: transcriptReportTemplate,
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../report/report.module.js");
              $ocLazyLoad.load({
                name: "report",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })

    .state({
      name: "student",
      abstract: true,
      parent: "index",
      template: layoutStudentTemplate,
    })
    .state("studentInfo", {
      url: "/student/{studentId:[0-9]+}",
      controller: "studentViewController as viewStudentCtrl",
      template: studentDetailTemplate,
      parent: "student",
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
    })
    .state("userInfo", {
      url: "/user/{studentId:[0-9]+}",
      parent: "student",
      controller: "userViewController as viewUserCtrl",
      template: userInfoTemplate,
      // resolve: {
      //   redirectWithoutSubscription: "redirectWithoutSubscription",
      // },
    })
    .state("studentAdd", {
      url: "/student/add",
      controller: "studentAddController as addStudentCtrl",
      template: addStudentTemplate,
      parent: "student",
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
    })
    .state("studentEdit", {
      url: "/student/edit/{studentId:[0-9]+}",
      parent: "student",
      controller: "studentEditController as editStudentCtrl",
      template: editStudentTemplate,

      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
    })
    .state("userAdd", {
      url: "/user/add",
      parent: "student",
      controller: "helperAddController as addhelperCtrl",
      template: addHelperTemplate,

      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
    })
    .state("userEdit", {
      url: "/user/edit/{studentId:[0-9]+}",
      parent: "student",
      controller: "helperEditController as editHelperCtrl",
      template: editHelperTemplate,

      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
    })
    //admin route
    .state("admin", {
      url: "/user/admin",
      parent: "index",
      abstract: true,
      template: emptyViewTemplate,
    })
    .state("userList", {
      url: "/{userId:[0-9]*}",
      parent: "admin",
      controller: "userAdminController as userAdminCtrl",
      template: adminTemplate,
      // resolve: {
      //   'redirectIfNotAdmin' : 'redirectIfNotAdmin'
      // }
    })
    .state("lessonViewModal", {
      url: "/{userId:[0-9]*}/view",
      parent: "admin",
      controller: "lessonViewModalController as lessonViewModalCtrl",
      template: lessonViewModalTemplate,
      // resolve: {
      //   'redirectIfNotAdmin' : 'redirectIfNotAdmin'
      // }
    })
    .state("userApproval", {
      url: "/{userId:[0-9]*}/approval",
      parent: "admin",
      controller: "userApprovalController as adminApprovalCtrl",
      template: lessonImportAdminTemplate,
      // resolve: {
      //   'redirectIfNotAdmin' : 'redirectIfNotAdmin'
      // }
    })
    .state("userDisapproval", {
      url: "/{userId:[0-9]*}/disapproval",
      parent: "admin",
      controller: "userDisapprovalController as adminDisapprovalCtrl",
      template: lessonImportDisapproveTemplate,
      // resolve: {
      //   'redirectIfNotAdmin' : 'redirectIfNotAdmin'
      // }
    })
    .state("userMetrics", {
      url: "/{userId:[0-9]*}/metrics",
      parent: "admin",
      controller: "userAdminController as userAdminCtrl",
      template: adminMetricTemplate,
      // resolve: {
      //   'redirectIfNotAdmin' : 'redirectIfNotAdmin'
      // }
    })
    .state("reportedPost", {
      url: "/{userId:[0-9]*}/report_post",
      parent: "admin",
      controller: "userReportPostsController as userReportPostsCtrl",
      template: reportPostTemplate,
      // resolve: {
      //   'redirectIfNotAdmin' : 'redirectIfNotAdmin'
      // }
    })
    .state("reportedUsers", {
      url: "/{userId:[0-9]*}/report_users",
      parent: "admin",
      controller: "reportedUserController as reportedUserCtrl",
      template: reportedUsersTemplate,
      // resolve: {
      //   'redirectIfNotAdmin' : 'redirectIfNotAdmin'
      // }
    })
    .state("pendingAffiliates", {
      url: "/{userId:[0-9]*}/pendingAffiliates",
      parent: "admin",
      controller: "pendingAffiliatesController as pendingAffiliatesCtrl",
      template: pendingAffiliatesTemplate,
      // resolve: {
      //   'redirectIfNotAdmin' : 'redirectIfNotAdmin'
      // }
    })
    .state("approvedAffiliates", {
      url: "/{userId:[0-9]*}/approvedAffiliates",
      parent: "admin",
      controller: "approvedAffiliatesController as approvedAffiliatesCtrl",
      template: approvedAffiliatesTemplate,
      // resolve: {
      //   'redirectIfNotAdmin' : 'redirectIfNotAdmin'
      // }
    })
    .state("confirmedCancellations", {
      url: "/{userId:[0-9]*}/confirmedCancellations",
      parent: "admin",
      controller:
        "confirmedCancellationsController as confirmedCancellationsCtrl",
      template: adminConfirmedCancellationTemplate,
    })
    .state("pendingCancellations", {
      url: "/{userId:[0-9]*}/pendingCancellations",
      parent: "admin",
      controller: "pendingCancellationsController as pendingCancellationsCtrl",
      template: adminPendingCancellationTemplate,
    })
    .state("freeMonthRequests", {
      url: "/{userId:[0-9]*}/freeMonthRequests",
      parent: "admin",
      controller: "freeMonthRequestsController as freeMonthRequestsCtrl",
      template: freeMonthRequestTemplate,
    })

    // Referral Plan Route
    .state("referralDetails", {
      url: "/referral/view",
      controller: "referralDetailsController as referralDetailsCtrl",
      parent: "index",
      template: refferalDetailsTemplate,
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
    })

    //Payment Plan
    .state("paymentPlan", {
      url: "/payment",
      params: {
        isRedirectedWithoutSubscription: false,
      },
      parent: "index",
      controller: "planController as planCtrl",
      template: paymentPlanTemplate,
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../paymentplan/paymentplan.module");
              $ocLazyLoad.load({
                name: "paymentplan",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state("paypalWebhook", {
      url: "/payment/paypalWebhook",
      parent: "index",
      params: {
        title: null,
        price: null,
        date: null,
      },
      controller: "paypalWebhookController as planWebCtrl",
      template: planWebhookTemplate,
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../paymentplan/paymentplan.module");
              $ocLazyLoad.load({
                name: "paymentplan",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state("paymentErrorWebhook", {
      url: "/payment/error",
      parent: "index",
      controller: "paymentErrorController as paymentErrorCtrl",
      template: planErrorTemplate,
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../paymentplan/paymentplan.module");
              $ocLazyLoad.load({
                name: "paymentplan",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })
    .state("amazonPlan", {
      url: "/payment/amazon",
      parent: "index",
      controller: "amazonPlanController as amazonPlanCtrl",
      template: amazonPlanTemplate,
      resolve: {
        foo: [
          "$q",
          "$ocLazyLoad",
          function ($q, $ocLazyLoad) {
            let deferred = $q.defer();
            require.ensure([], function () {
              let module = require("../paymentplan/paymentplan.module");
              $ocLazyLoad.load({
                name: "paymentplan",
              });
              deferred.resolve(module);
            });

            return deferred.promise;
          },
        ],
      },
    })

    //referral
    .state("referrals", {
      url: "/referrals",
      parent: "index",
      controller: "userReferralController as userRefCtrl",
      template: userRefferalTemplate,
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
    })

    //referral List
    .state("referralList", {
      url: "/referralList",
      parent: "index",
      controller: "userReferralListController as userRefListCtrl",
      template: userRefferalListTemplate,
      // resolve: {
      //   'redirectWithoutSubscription': 'redirectWithoutSubscription'
      // },
    })

    //Affiliate
    .state("affiliate", {
      url: "/affiliate",
      parent: "index",
      controller: "userAffiliateController as userAffiliateCtrl",
      template: userAffiliateTemplate,
    })

    // Error State
    .state("404", {
      url: "/404",
      parent: "index",
      template: errorTemplate,
    })

    // Error State
    .state("permissionDenied", {
      url: "/permissionDenied",
      parent: "index",
      template: permissionDeniedTemplate,
    });

  $urlRouterProvider.otherwise("/dashboard");
}

export default appConfig;
