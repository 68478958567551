let angular = window.angular;
lessonBookAndElectronicMediaController.$inject = [
  "toastService",
  "bookConstants",
  "$uibModal",
  "$scope",
  "$http",
  "$rootScope",
  "bookService",
  "lessonService",
  "commonService",
  "$uibModalInstance",
  "bookData",
];

function lessonBookAndElectronicMediaController(
  toastService,
  bookConstants,
  $uibModal,
  $scope,
  $http,
  $rootScope,
  bookService,
  lessonService,
  commonService,
  $uibModalInstance,
  bookData
) {
  let vm = this;
  let KEY = "AIzaSyDVLpO_0FWnLWeM7X0kchNrjeVk3fz2gA8"; // Google  api key
  let fixed = "&key=" + KEY + "&maxResults=40";
  $scope.todos = [];
  $scope.books = null;
  vm.options = bookConstants.bookSearchOptions;
  vm.type = null;
  vm.data = null;
  vm.activeTab = "assigned-book";
  vm.isFetchingData = false;
  vm.loadMoreCount = 2;
  vm.loadMoreBookStatus = false;
  vm.loadMoreFetchingData = false;
  vm.loadingCheck = true;
  let book;
  vm.books = [];
  let finalBooks = [];
  let addedBooks = [];
  let checkedBookIds = [];

  vm.shelfCategories = [];
  vm.selectedBookShelf = null;
  vm.selectAllBooksCheck = false;
  vm.bookResources = true;
  vm.electronicMediaResources = false;
  vm.cards = [];
  vm.deletedCards = [];

  this.$onInit = function () {
    vm.isFetchingData = true;
    checkedBookIds = bookData.bookIds.map((elem) => elem.id) || [];

    vm.cards = [...bookData.electronicMedia];

    vm.activeTab = "explore";
    bookService
      .getUserBooks()
      .then((response) => {
        vm.isFetchingData = false;
        _.map(response.books, function (book) {
          if (_.includes(checkedBookIds, book.id)) {
            book["isSelected"] = true;
          } else {
            book["isSelected"] = false;
          }
          finalBooks.push(book);
          vm.books.push(book);
        });

        vm.loadMore = response.isLoadMore;
      })
      .catch((err) => {
        console.error(err);
      });
    bookService
      .getBookShelfCategories()
      .then((response) => {
        if (response && response.length) {
          vm.shelfCategories = response;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  angular.extend(vm, {
    //isTouched,
    toggleTab,
    viewBook,
    close,
    search,
    addBook,
    manualAddBook,
    removeBook,
    loadMoreBooks,
    loadMoreUserBooks,
    edit,
    view,
    deleteBook,
    checkBook,
    done,
    changeImageUrl,
    selectBookShelf,
    getSeletedBooks,
    selectAllBooks,
    selectTab,
    createResource,
    deleteElectronicMedia,
    addResources,
    checkExistingBooks,
    addAssignIdNewBooks,
  });

  function checkExistingBooks(finalBooks) {
    let mappedBooks = finalBooks.map((book) => {
      let res = bookData.bookIds.find((elem) => {
        return elem.id === book.id;
      });
      if (res === undefined) {
        return book;
      } else {
        book.assignId = res.assignId;
        return book;
      }
    });
    return mappedBooks;
  }

  function addAssignIdNewBooks(existingBooks, newBooks) {
    let mappedBooks = existingBooks.map((book) => {
      let res = newBooks.find((elem) => {
        return elem.bookId === book.id;
      });
      if (res === undefined) {
        return book;
      } else {
        book.assignId = res.id;
        return book;
      }
    });
    return mappedBooks;
  }
  function addResources() {
    let finalOutputBooks = selectBooks();
    let bookWithAssignId = checkExistingBooks(finalOutputBooks);
    let uncheckBooks = [];
    let newBooks = [];
    if (checkedBookIds.length) {
      bookData.bookIds.forEach((elem) => {
        let result = finalOutputBooks.find((book) => book.id === elem.id);

        if (result === undefined) {
          uncheckBooks.push(elem);
        } else {
          finalOutputBooks = finalOutputBooks.filter(
            (book) => book.id !== elem.id
          );
        }
      });
      newBooks = [...finalOutputBooks];
    } else {
      newBooks = [...finalOutputBooks];
    }
    let entityId;
    let type;
    if (bookData.assignment.isStudentLessonGroup) {
      entityId = bookData.assignment.studentLessonGroupId;
      type = "groupAssignment";
    } else {
      entityId = bookData.assignment.id;
      type = "studentAssignment";
    }
    newBooks = _.map(newBooks, (book) => {
      return {
        entityId,
        type,
        userId: bookData.userId,
        bookId: book.id,
      };
    });

    let newCards = vm.cards.filter((elem) => !elem.id);

    let existingElectronicMedia = vm.cards.filter((elem) => elem.id);

    let newResources = [];

    if (newCards.length) {
      bookData.assignment.students.forEach((student, i) => {
        newCards.forEach((obj) => {
          let element = angular.copy(obj);
          element.studentAssignmentId = student.assignmentId;
          element.studentId = student.studentId;
          element.assignmentId = bookData.assignmentId;
          element.lessonPlanId = bookData.assignment.studentLesson.id;
          element.isStudentLessonGroup =
            bookData.assignment.isStudentLessonGroup;
          element.userId = bookData.userId;
          newResources.push(element);
        });
      });
    }

    let studentAssignmentIds = bookData.assignment.students.map((elem) => {
      return {
        studentAssignmentId: elem.assignmentId,
      };
    });

    let cards = [];
    if (vm.deletedCards.length > 0) {
      vm.deletedCards.forEach((elem) => {
        let data = studentAssignmentIds.map((student) => {
          return {
            name: elem.name,
            studentAssignmentId: student.studentAssignmentId,
          };
        });
        cards = [...cards, ...data];
      });
    }

    let addResourcesData = {
      newElectronicMedia: newResources,
      deletedElectronicMedia: cards,
      userId: bookData.userId,
      newBooks: newBooks,
      uncheckBooks: uncheckBooks,
    };
    let electronicMediaResponse = [];
    lessonService
      .addResources(addResourcesData)
      .then((res) => {
        if (
          res.data.data.electronicMedia &&
          res.data.data.electronicMedia.length
        ) {
          electronicMediaResponse = res.data.data.electronicMedia.filter(
            (elem) => elem.studentId === bookData.assignment.studentId[0]
          );
          vm.cards = [...existingElectronicMedia, ...electronicMediaResponse];
        } else {
          vm.cards = [...existingElectronicMedia];
        }

        if (res.data.data.books && res.data.data.books.length) {
          bookWithAssignId = addAssignIdNewBooks(
            bookWithAssignId,
            res.data.data.books
          );
        }
        toastService.toast({
          message: "Resources updated successfully",
          type: "success",
          delay: 2000,
        });

        $uibModalInstance.close({
          books: bookWithAssignId,
          electronicMedia: vm.cards,
        });
        return res;
      })
      .catch((err) => {
        console.log(err);
        toastService.toast({
          message: "Operation failed, try again",
          type: "error",
          delay: 2000,
        });
      });
  }

  function deleteElectronicMedia(i) {
    let deletedCard = vm.cards.find((elem, index) => index === i);
    if (deletedCard.id) {
      vm.deletedCards.push(deletedCard);
    }

    let filteredCards = vm.cards.filter((card, index) => index !== i);
    vm.cards = [...filteredCards];
  }

  function createResource() {
    if (!vm.electronicMediaForm.$valid) {
      toastService.toast({
        message: "Enter Required Fields",
        type: "success",
        delay: 2000,
      });
      return;
    }
    let pattern = /^((http|https|ftp):\/\/)/;

    if (!pattern.test(vm.url)) {
      vm.url = "http://" + vm.url;
    }
    // vm.description=$(".notepad-electronicmedia").html().toString();
    vm.description =
      '<a href="' +
      vm.url +
      '"  target="' +
      vm.openin +
      '">' +
      vm.text +
      "</a>";

    let data = {
      name: vm.name,
      description: vm.description,
    };
    vm.cards = [...vm.cards, data];
    vm.name = "";
    vm.text = "";
    vm.url = "";
    vm.openin = "";
    vm.electronicMediaForm.$setPristine();
    vm.electronicMediaForm.$setUntouched();
  }

  function selectTab(type) {
    if (type === "book") {
      vm.bookResources = true;
      vm.electronicMediaResources = false;
    }
    if (type === "electronic") {
      vm.bookResources = false;
      vm.electronicMediaResources = true;
    }
  }

  function selectAllBooks() {
    vm.selectAllBooksCheck = !vm.selectAllBooksCheck;
    vm.books = _.map(vm.books, (book) => {
      if (vm.selectAllBooksCheck) {
        book.isSelected = true;
      } else {
        book.isSelected = false;
      }
      return book;
    });
  }

  function getSeletedBooks() {
    let booksCount = _.filter(vm.books, (obj) => obj.isSelected);
    return booksCount.length;
  }

  function selectBookShelf(category) {
    vm.selectedBookShelf = category;
    vm.selectAllBooksCheck = false;
    let currentTypeId = 0;
    if (vm.selectedBookShelf) {
      currentTypeId = vm.selectedBookShelf.id;
    }
    vm.isFetchingData = true;
    checkedBookIds = bookData.bookIds || [];
    finalBooks = [];
    vm.books = [];
    bookService
      .getUserBooks(null, currentTypeId)
      .then((response) => {
        vm.isFetchingData = false;
        _.map(response.books, function (book) {
          if (_.includes(checkedBookIds, book.bookId)) {
            book["isSelected"] = true;
          } else {
            book["isSelected"] = false;
          }
          finalBooks.push(book);
          vm.books.push(book);
        });

        vm.loadMore = response.isLoadMore;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function changeImageUrl(img) {
    if (img) {
      img = img.replace("http:", "https:");
    }
    return img;
  }

  function done() {
    let finalOutputBooks = selectBooks();

    let newResources = {
      newbooks: finalOutputBooks,
      newElectronicMedia: vm.cards,
    };

    $uibModalInstance.close(newResources);
  }

  function selectBooks() {
    let outputBooks = [];
    let totalBooks = finalBooks.concat(vm.books);
    for (let i = 0; i < totalBooks.length; i++) {
      if (totalBooks[i].isSelected) {
        outputBooks.push(totalBooks[i]);
      }
    }
    let dummy = _.uniqBy(outputBooks, "bookId");

    return dummy;
  }

  function manualAddBook() {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "lessonBookAddController",
      controllerAs: "lessonBookAddCtrl",
      template: require("../../pug/lesson/add-book.pug").default,
    });
    modelInstance.result
      .then(function (book) {
        if (book) {
          let data = _.clone(book);
          vm.books.unshift(data);
        }
      })
      .catch(function () {
        modelInstance.close();
      });
  }

  // function isTouched(field) {
  // 	return field.$touched || vm.form.$submitted;
  // }

  function close() {
    $uibModalInstance.close();
  }

  function checkBook(book) {
    addedBooks.push(book);
  }

  function addBook(book) {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "bookEditController",
      controllerAs: "bookEditCtrl",
      template: require("../../pug/book/edit-book.pug").default,
      resolve: {
        bookData: {
          book,
        },
      },
    });

    modelInstance.result.then(afterAddBook).catch(function () {
      modelInstance.close();
    });
  }

  function viewBook(book) {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "bookDetailController",
      controllerAs: "bookDetailCtrl",
      template: require("../../pug/book/view-book.pug").default,

      resolve: {
        bookData: {
          book: book,
          // model: 'search'
        },
      },
    });

    modelInstance.result.then(afterAddBook).catch(function () {
      modelInstance.close();
    });
  }

  function afterAddBook(options) {
    let addedBookId = options.book.bookId;
    let existingBookIndex = _.findIndex(vm.books, {
      bookId: addedBookId,
    });

    if (existingBookIndex > -1) {
      options.book.isSelected = true;
      vm.books[existingBookIndex] = options.book;
    }
  }

  function deleteBook(bookId) {
    commonService
      .confirm({
        message: bookConstants.messages.deleteBookConfirmation,
        type: "danger",
        modalSize: "md",
      })
      .then((confirmation) => {
        if (confirmation.result === true) {
          bookService
            .deleteBook(bookId)
            .then((deleted) => {
              toastService.toast({
                message: bookConstants.messages.bookDeleted,
                type: "success",
                delay: 3000,
              });

              let removeBookIndex = _.findIndex(vm.books, {
                id: bookId,
              });
              if (removeBookIndex > -1) {
                vm.books[removeBookIndex].id = undefined;
              }
            })
            .catch((err) => {
              console.log("errror", err);
            });
        } else return;
      })
      .catch((err) => {
        console.log("errror", err);
      });
  }

  function view(book, activeTab) {
    let bookId = book.id;
    if (!angular.isDefined(bookId) || !bookId) {
      viewBook(book);
    } else {
      let modelInstance = $uibModal.open({
        animation: true,
        size: "lg",
        controller: "bookAssignController",
        controllerAs: "bookAssignCtrl",
        template: require("../../pug/book/assign-book.pug").default,

        resolve: {
          bookData: {
            bookId,
            book,
            activeTab: activeTab,
          },
        },
      });

      modelInstance.result.then(afterView).catch(function () {
        modelInstance.close();
      });
    }
  }

  function afterView(options) {
    if (options) {
      if (!options.removeEvent) {
        let bookUpdateIndex = _.findIndex(vm.books, {
          bookId: options.book.bookId,
        });
        if (bookUpdateIndex > -1) {
          vm.books[bookUpdateIndex] = options.book;
        } else {
          vm.books.push(options);
        }
      } else {
        let removeBookId = options.bookData.bookId;
        let removeBookIndex = _.findIndex(vm.books, {
          id: removeBookId,
        });
        if (removeBookIndex > -1) {
          // vm.books.splice(removeBookIndex, 1);
          vm.books[removeBookIndex].id = undefined;
        }
      }
    }
  }

  function toggleTab(tab) {
    vm.activeTab = tab;
  }

  function search(queryParam) {
    vm.loadingCheck = false;
    let skip;
    vm.isFetchingData = true;

    if (!vm.data) {
      vm.isFetchingData = false;
      toastService.toast({
        message: "Please enter keyword to find the books",
        type: "error",
        delay: 3000,
      });

      return false;
    }

    if (!queryParam) {
      skip = 0;
      vm.books = [];
    } else {
      skip = queryParam.skip;
    }

    let query = {
      searchBy: vm.type.title,
      values: vm.data,
      skip: skip,
    };
    bookService
      .search(query)
      .then((resp) => {
        vm.loadMoreFetchingData = false;
        vm.isFetchingData = false;

        if (!resp.data) {
          toastService.toast({
            message: "There are no books under this criteria",
            type: "error",
            delay: 3000,
          });
          return false;
        }

        _.map(resp.data.data.books, function (book) {
          if (_.includes(checkedBookIds, book.bookId)) {
            book["isSelected"] = true;
          } else {
            book["isSelected"] = false;
          }
          vm.books.push(book);
          vm.books = _.uniqBy(vm.books, "bookId");
          // finalBooks.push(book);
        });

        vm.loadMoreBookStatus = resp.data.data.isLoadMore;
      })
      .catch(() => {
        console.log("invalid request");
      })
      .finally(() => {
        vm.loadMoreFetchingData = false;
        vm.isFetchingData = false;
      });
  }

  function edit(book) {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "bookEditController",
      controllerAs: "bookEditCtrl",
      template: require("../../pug/book/edit-book.pug").default,

      resolve: {
        bookData: {
          book,
        },
      },
    });
    modelInstance.result.then(afterView).catch(function () {
      modelInstance.close();
    });
    // modelInstance.result.catch(function() {
    //     console.log("ebnter in bookconteoler"
    // })
  }

  function loadMoreBooks() {
    let queryParam;

    vm.loadMoreFetchingData = true;
    if (!vm.books.length) {
      queryParam = {
        skip: 0,
      };
    } else {
      queryParam = {
        skip: vm.books.length,
      };
    }

    search(queryParam);
  }

  function loadMoreUserBooks() {
    vm.isFetchingData = true;
    checkedBookIds = bookData.bookIds || [];
    vm.activeTab = "explore";
    let queryParam;
    if (!vm.books.length) {
      queryParam = {
        skip: 0,
      };
    } else {
      queryParam = {
        skip: vm.books.length,
      };
    }
    bookService
      .getUserBooks(queryParam)
      .then((response) => {
        vm.isFetchingData = false;
        _.map(response.books, function (book) {
          if (_.includes(checkedBookIds, book.bookId)) {
            book["isSelected"] = true;
          } else {
            book["isSelected"] = false;
          }
          if (vm.selectAllBooksCheck) {
            book["isSelected"] = true;
          }
          finalBooks.push(book);
          vm.books.push(book);
        });

        vm.loadMore = response.isLoadMore;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //  function addBook (book) {
  //     bookService.addBook(book)
  //         .then(function(response) {
  //             // $scope.todos.push($scope.formData)
  //             $scope.formData = {};
  //             $scope.todos = response;
  //             toastService.toast({
  //                 message: bookConstants.messages.bookAdded,
  //                 type: 'success',
  //                 delay: 3000
  //             });
  //             console.log(response);

  //         })
  //         .catch((err)=>{
  //             toastService.toast({
  //                 message: bookConstants.messages.bookAlready,
  //                 type: 'success',
  //                 delay: 3000
  //             });
  //         });
  //   }

  function removeBook(todo) {
    bookService.removeBook(todo).then(function (response) {
      $scope.todos.splice($scope.todos.indexOf(todo), 1);
    });
  }
}
export default lessonBookAndElectronicMediaController;
