
    bookController.$inject = ['$scope','bookConstants', '_', '$uibModal', 'studentService', 'bookService','$compile', 'toastService','$sce', '$q', 'appConstants', 'commonService', '$rootScope'];

    function bookController($scope, bookConstants, _, $uibModal, studentService, bookService,$compile, toastService,$sce, $q, appConstants, commonService, $rootScope) {
        let vm = this;

        vm.isFetchingData = false;
        vm.bookStatus = false;
        vm.books = [];
        vm.desc = [];
        vm.options = bookConstants.bookSearchOptions;
        vm.selectedStudents = [];
        vm.isReadStatus = 'all';
        vm.isSearchClicked = false;
		vm.data = null;
		vm.isFetchingData = false;
		vm.notAdded = false;
		vm.category = "";
		vm.status = "";
		vm.copyStatus = null;
		vm.type = "";
		vm.copyType = null;
		vm.dateWarning = false;
		vm.loadMoreBookStatus = false;
		vm.loadMoreFetchingData = false;
        vm.isReadPrint = 'all';
        vm.searchWith = 'titleIsbnAuthor';
		vm.printSelectedStudents=[];
		vm.printCategory = "";
		vm.filterOptions = [];
        vm.showSearch = false;
        vm.searchData = {
			'status': {
				'own': false,
				'wishList': false,
				'borrowed': false
			},
			'category': vm.category,
			'student': [],
			'searchBy': {
				'all': true,
				'read': false,
				'notRead': false
            },
            'searchWith': {
                'title': true,
                'author': false,
                'isbn': false
            },
			'date': {
				'from': null,
				'to': null
			},
			'values': null,
			'type': null,
        };

		vm.printData = {
			'category': vm.printCategory,
			'student': [],
			'searchBy': {
				'all': true,
				'read': false,
				'notRead': false
			},
			'date': {
				'from': null,
				'to': null
			},
		};

		vm.activeShelf=null;
		vm.shelfCategories=[];
		vm.addCategoryCheck="none";
		vm.shelfName="";
		vm.updateShelfCategory=null;
		vm.wantToReadId=3;

		vm.featuredBooks=[];
        angular.extend(this, {
            view,
            edit,
            deleteBook,
            toggleTab,
            print,
            addBook,
            search,
            searchOnEnter,
            addBookFromWeb,
            addStudentsTag,
            loadMoreBooks,
            assignBook,
            fetchStudents,
            checkIfSearchWithChecked,
            reset,
            resetSearchData,
			openInfoModal,
			searchDatabase,
			changeStatus,
			changeType,
			loadMoreSearchBooks,
			resetPrintData,
			changeImageUrl,
			addNewShelfCategory,
			addNewShelf,
			cancelShelf,
			editShelfSelect,
			editShelfCategory,
			deleteShelfCategory,
			addBookToWantToRead,
			addBookToShelf,
			bookshelfClick,
			checkPopover,
			checkBookShelfCategory,
			selectFeaturedBooks,
			setShowMoreFeatureBook,
			addStudentStatus,
			startConfetti,
			setPopoverListPosition,
			setPopoverStudentPosition,
			addStudentParentStatus,
			viewFeatureBook

        });
		function setPopoverStudentPosition(index ){

			let finalIndex=index%2;
			let position="top-right";
			if(index>=0 && index<=2){
				return 'bottom-left';
			}
			if(finalIndex==0){
				position="top-left";
			}

			return position;
		}

        function setPopoverListPosition(index){
			let position="top-right";
			if(index<3){
				position="bottom-right";
			}

        	return position;
		}

		function addStudentParentStatus(status, student, bookId, index){
			if(student.status==status){
				return;
			}
			let data={
				studentId:student.studentId,
				bookId:bookId,
				status:status
			};
			bookService.addStudentStatus(data)
				.then(response=>{
					student.status=status;
				});
		}

		function addStudentStatus(status, student, bookId, index){
			if(student.status==status){
				return;
			}
			let data={
				studentId:student.studentId,
				bookId:bookId,
				status:status
			};
			bookService.addStudentStatus(data)
				.then(response=>{
					student.status=status;
					let canvas = document.getElementById('confetti-'+index);
					if(canvas && status==="COMPLETED") {

						canvas.style.display="block";
						startConfetti('confetti-'+index);

					}
				});
		}
		function startConfetti(id){
			$().setGlobals(id);
			$().initializeConfetti();
			setTimeout(()=>{
				$().stopConfetti();
				let canvas = document.getElementById(id);
				canvas.style.display="none";
			},5000);
		}


        function setShowMoreFeatureBook(featureBook){
			bookService.setShowMoreFeatureBook(featureBook.id)
				.then(response=>{
					featureBook.loadMore=!featureBook.loadMore;
				});
		}
        function selectFeaturedBooks(){
			vm.activeShelf = {
				name:"Featured Books"
			};
			// vm.books = [];
			bookService.getFeaturedBooks()
				.then(response=>{
					vm.featuredBooks=response;
					// vm.books=response.books;
					// vm.copyBooks = vm.books;
					// vm.loadMore = response.isLoadMore;
				});
		}

        function checkBookShelfCategory(bookShelfs, currentShelf){
			if(bookShelfs && currentShelf){
				let checkBookCategory=_.find(bookShelfs,obj=>obj.id===currentShelf.id);
				if(checkBookCategory){
					return true;
				}
			}
        	return false;
		}

        function bookshelfClick(category){
        	if(category) {
				vm.activeShelf = category;
			}
        	else{
        		vm.activeShelf=null;
			}
			getUserBooks();

		}

        function addBookToShelf(category, book){
			if(!book || !category){
				toastService.toast({
					message: "No Book Selected",
					type: 'error',
					delay: 3000
				});
				return;
			}
			// if(book.bookShelfId && book.bookShelfId.id===category.id){
			//
			// 	return;
			// }

				let data = {
					book: {
						bookShelfId: category.id
					}
				};
				bookService.addBookToShelf(book.id, data)
					.then(res => {
						if (res) {
							// if(book.bookShelfId) {
							// 	let oldShelf = book.bookShelfId;
							// 	let findOldShelfCategoryIndex = _.findIndex(vm.shelfCategories, obj => obj.id === oldShelf.id);
							// 	if (findOldShelfCategoryIndex !== -1) {
							// 		if (vm.shelfCategories[findOldShelfCategoryIndex].total !== 0) {
							// 			vm.shelfCategories[findOldShelfCategoryIndex].total = vm.shelfCategories[findOldShelfCategoryIndex].total - 1;
							// 		}
							// 	}
							// }
							// let findNewShelfCategoryIndex = _.findIndex(vm.shelfCategories, obj => obj.id === category.id);
							// if (findNewShelfCategoryIndex !== -1) {
							// 	vm.shelfCategories[findNewShelfCategoryIndex].total = vm.shelfCategories[findNewShelfCategoryIndex].total + 1;
							// }
							// book.bookShelfId = category;
							// console.log("res",res.bookShelfCategory);
							// console.log("book",book.bookShelfCategory);


							if(res.bookShelfCategory && res.bookShelfCategory.length){
								_.forEach(res.bookShelfCategory, currentBookShelf=>{
									let findShelfInOldIndex=_.findIndex(book.bookShelfCategory,obj=>obj.id===currentBookShelf.id);
									if(findShelfInOldIndex===-1){
										let findShelfIndex=_.findIndex(vm.shelfCategories, obj => obj.id === category.id);
										if (findShelfIndex !== -1) {
											vm.shelfCategories[findShelfIndex].total = vm.shelfCategories[findShelfIndex].total +1;
										}
									}
									else{
										_.remove(book.bookShelfCategory, function (oldBookShelf) {
											return oldBookShelf.id == currentBookShelf.id;
										});
									}
								});
								if(book.bookShelfCategory && book.bookShelfCategory.length) {
									_.forEach(book.bookShelfCategory, oldBookShelf=>{
										let findShelfIndex=_.findIndex(vm.shelfCategories, obj => obj.id === oldBookShelf.id);
										if (findShelfIndex !== -1) {
											vm.shelfCategories[findShelfIndex].total = vm.shelfCategories[findShelfIndex].total -1;
										}
									});
								}
							}
							else{
								_.forEach(book.bookShelfCategory, oldBookShelf=>{
									let findShelfIndex=_.findIndex(vm.shelfCategories, obj => obj.id === oldBookShelf.id);
									if (findShelfIndex !== -1) {
										vm.shelfCategories[findShelfIndex].total = vm.shelfCategories[findShelfIndex].total -1;
									}
								});
							}


							if(res.bookShelfCategory){
								book.bookShelfCategory=res.bookShelfCategory;
							}

							if (vm.activeShelf) {
								if (book.bookShelfCategory && book.bookShelfCategory.length) {

									let checkBookCategory = _.find(book.bookShelfCategory, obj => obj.id === vm.activeShelf.id);
									if (!checkBookCategory) {
										_.remove(vm.books, function (cBook) {
											return cBook.id == book.id;
										});
									}

								}
								else{
									_.remove(vm.books, function (cBook) {
										return cBook.id == book.id;
									});
								}

							}
							toastService.toast({
								message: "Book added to Shelf",
								type: 'success',
								delay: 3000
							});
						}
					})
					.catch(err => {
						toastService.toast({
							message: "Error Occurred",
							type: 'error',
							delay: 3000
						});
					});
		}

        function addBookToWantToRead(book){
        	if(!book || !book.id){
				toastService.toast({
					message: "No Book Selected",
					type: 'error',
					delay: 3000
				});
				return;
			}
        	let findWantToReadId=_.find(vm.shelfCategories,shelf=>{
        		if(shelf.name==="Want to Read"){
        			return true;
				}
			});
        	if(!findWantToReadId){
				toastService.toast({
					message: "No Want to Read Category Found",
					type: 'error',
					delay: 3000
				});
				return;
			}

        	let data={
        		book:{
					bookShelfId:findWantToReadId.id
				}
			};

        	bookService.addBookToShelf(book.id,data)
				.then(res=>{
					if(res) {
						let findWantToRead=_.find(vm.shelfCategories,obj=>obj.id===findWantToReadId.id);
						if(findWantToRead){
							book.bookShelfId=findWantToRead;
							findWantToRead.total=findWantToRead.total+1;
						}
						if(res.bookShelfCategory){
							book.bookShelfCategory=res.bookShelfCategory;
						}
						toastService.toast({
							message: "Book added to Shelf",
							type: 'success',
							delay: 3000
						});
					}
				})
				.catch(err=>{
					toastService.toast({
						message: "Error Occurred",
						type: 'error',
						delay: 3000
					});
				});
		}

        function cancelShelf(){
			resetShelfCategory();
		}
		function addNewShelf(){
			vm.addCategoryCheck="add";
		}
		function addNewShelfCategory(){
			if(!vm.shelfName){
				toastService.toast({
					message: "Enter Shelf Name",
					type: 'error',
					delay: 3000
				});
				return;
			}

			let data={
				bookShelf: {
					name: vm.shelfName
				}
			};
			bookService.addShelfCategory(data)
				.then(res=>{
					vm.shelfCategories.push(res);
					toastService.toast({
						message: "Shelf Added",
						type: 'success',
						delay: 3000
					});
					resetShelfCategory();

				})
				.catch(err=>{
					toastService.toast({
						message: "Error Occured",
						type: 'error',
						delay: 3000
					});
				})
		}

		function resetShelfCategory(){
			vm.addCategoryCheck="none";
			vm.shelfName="";
			vm.updateShelfCategory=null;
		}

		function editShelfSelect(shelfCategory){
			if(!shelfCategory || !shelfCategory.id){
				toastService.toast({
					message: "No Shelf Category Selected",
					type: 'error',
					delay: 3000
				});
				return;
			}
			vm.updateShelfCategory=shelfCategory;
			vm.addCategoryCheck="edit";
		}
		function editShelfCategory(){
			if(!vm.updateShelfCategory || !vm.updateShelfCategory.id){
				toastService.toast({
					message: "No Shelf Category Selected",
					type: 'error',
					delay: 3000
				});
				return;
			}

			let data={
				bookShelf: {
					name: vm.updateShelfCategory.name,
					id:vm.updateShelfCategory.id,
					userId: vm.updateShelfCategory.userId,
				}
			};
			bookService.updateShelfCategory(data)
				.then(res=>{
					if(res){
						let findShelfIndex=_.findIndex(vm.shelfCategories,obj=>obj.id===res.id);
						if(findShelfIndex!==-1){
							vm.shelfCategories[findShelfIndex].name=res.name;
						}

						_.forEach(vm.books, obj=>{
							if(obj.bookShelfId && obj.bookShelfId.id=== res.id){
								obj.bookShelfId.name=res.name;
							}
						});
					}
					toastService.toast({
						message: "Shelf Updated",
						type: 'success',
						delay: 3000
					});
					resetShelfCategory();

				})
				.catch(err=>{
					toastService.toast({
						message: "Error Occured",
						type: 'error',
						delay: 3000
					});
				})
		}

		function deleteShelfCategory(shelfCategory){
			let confirmData = {
				message: "Are you sure you want to delete this shelf? All of its data will be deleted and you won't be able to undo.",
				type: "danger",
				modalSize: "md",
				confirmLabel: "Yes",
			};

			let data={
				bookShelf:shelfCategory
			}
			commonService.confirm(confirmData).then(function (confirmation) {
				if (confirmation.result) {
					return bookService
						.deleteShelfCategory(data)
						.then(deleteShelfSuccess)
						.catch(deleteShelfFailure);
				}
			});
		}

		function deleteShelfSuccess(response){
			if (response) {
				_.remove(vm.shelfCategories, function (category) {
					return category.id == response.id;
				});
				vm.activeShelf=null;
				getUserBooks();
			}

			toastService.toast({
				message: "Successfully deleted Book Shelf",
				type: "success",
				delay: 3000,
			});
		}
		function deleteShelfFailure(response){
			toastService.toast({
				message: "Book Shelf delete failed",
				type: "error",
				delay: 3000,
			});
		}

		function changeImageUrl(img){
			if(img){
				img=img.replace('http:','https:');
			}
			return img;
		}

		function openInfoModal() {
			let modelInstance = $uibModal.open({
				animation: true,
				size: 'md',
				controller: 'hspInfoModalController',
				controllerAs: 'hspInfoModalCtrl',
				template:require('../../pug/components/hsp-info-modal.pug').default,

				resolve: {
					infoParam:{
						data:`<div class="info-modal">
								<h4>My Books</h4>
								<ul class="ul">
									<li>When you click on My Books, your collection of books in the database will be displayed. The search icon will let you search for a book using the title, author name, and ISBN number. You can customize the search function according to your liking by clicking the (v) icon in the search bar. </li>
									<li>Once you click on a book, you’ll be able to edit its details and delete it by clicking the pencil icon. You will also be able to assign it to a student or add it to your calendar by clicking on the blue assign button. When a book is assigned to a student, he/she can rate it and comment on it, those comments will appear under the book’s description.</li
									<li>By clicking the book, you’ll be able to read its description, reviews, and assignments as shown in the segmented bar at the bottom.</li>
									<li>By clicking the blue print button on the right side of the page, you’ll be able to download the pdf copy of the assigned books and print them.</li>
									<li>If you manually want to add a book which is not shown in the results, you can click on the Manual Add button on the top right. By clicking the button, you will be able to add a book by filling the following options; the title of the book, the author name(s), its pages, its words, a description, and the book’s level. Only adding the title is mandatory. You will also have the option to add a cover for the book.</li>
								</ul>
							  </div>`
					}
				}
			});
		}

        function resetSearchData() {
			vm.showSearch = false;
			vm.books = [];
            return getUserBooks();
        }

        function reset() {
			vm.showSearch = false;
            vm.searchData = {
                'status': {
                    'own': false,
                    'wishList': false,
                    'borrowed': false
                },
				'category': '',
                'student': [],
                'searchBy': {
					'all': true,
					'read': false,
					'notRead': false
                },
                'date': {
                    'from': null,
                    'to': null
                },
                'values': null,
				'type': null,
            }
			vm.category = '';
            vm.status = null;
            vm.copyStatus = null;
            vm.type = null;
            vm.copyStatus = null;
            vm.selectedStudents = [];
            vm.isReadStatus = 'all';
            vm.isSearchClicked = false;
            resetSearchData();
        }

		function resetPrintData() {
			vm.searchData = {
				'category': '',
				'student': [],
				'searchBy': {
					'all': true,
					'read': false,
					'notRead': false
				},
				'date': {
					'from': null,
					'to': null
				},
			}

			vm.printSelectedStudents = [];
			vm.isReadPrint = 'all';
		}

        function changeStatus() {
			if (vm.copyStatus != vm.status) {
				vm.copyStatus = vm.status;
			} else {
				vm.status = null;
				vm.copyStatus = null;
			}
		}

		function changeType() {
			if (vm.copyType != vm.type) {
				vm.copyType = vm.type;
			} else {
				vm.type = null;
				vm.copyType = null;
			}
		}

        function checkIfSearchWithChecked(key) {
            switch (key) {
                case 'title':
                    if (!vm.searchData.searchWith.title && (vm.searchData.values && vm.searchData.values.trim().length >= 2)) {
                        if (!vm.searchData.searchWith.author && !vm.searchData.searchWith.isbn) {
                            vm.searchData.searchWith.title = true;
                            showCheckErrorToast();
                            vm.validSearchIn = false;
                        }
                    } else {
                        vm.validSearchIn = true;
                    }
                    break;
                case 'author':
                    if (!vm.searchData.searchWith.author && (vm.searchData.values && vm.searchData.values.trim().length >= 2)) {
                        if (!vm.searchData.searchWith.title && !vm.searchData.searchWith.isbn) {
                            vm.searchData.searchWith.author = true;
                            showCheckErrorToast();
                            vm.validSearchIn = false;
                        }
                    } else {
                        vm.validSearchIn = true;
                    }
                    break;
                case 'isbn':
                    if (!vm.searchData.searchWith.isbn && (vm.searchData.values && vm.searchData.values.trim().length >= 2)) {
                        if (!vm.searchData.searchWith.title && !vm.searchData.searchWith.author) {
                            vm.searchData.searchWith.isbn = true;
                            showCheckErrorToast();
                            vm.validSearchIn = false;
                        }
                    } else {
                        vm.validSearchIn = true;
                    }
                    break;
                default:
                    vm.validSearchIn = false;
                    break;

            }
        }

        function showCheckErrorToast() {
            toastService.toast({
                message: bookConstants.messages.cantUncheckSearchWithCategory,
                type: 'error',
                delay: 3000
            });
        }

        function fetchStudents() {
			let students_array=_.filter(studentService.students,(obj)=>obj.role==='STUDENT');
            students_array = _.map(students_array, (student, index) => {
                index++;
                return { type: 'student', id: student.id, displayName: student.name, name: student.name + ' (S' + index + ')', mediaUrl: student.mediaUrl || null };
            });
            vm.students = students_array;
        }

        function assignBook(book) {
            let modelInstance = $uibModal.open({
                animation: true,
                size: 'lg',
                controller: 'bookAssignmentModalController',
                controllerAs: 'bookAssignmentModalCtrl',
				template:require('../../pug/book/add-assignee.pug').default,
                resolve: {
                    bookData: {
                        book
                    }
                }
            });
            modelInstance.result
                .then(afterAssignView)
                .catch(function() {
                    modelInstance.close();
                });
        }

        function afterAssignView(options) {
            if (Object.keys(options)) {
                _.map(options.assignStudents, function(reviewStudent) {
                    _.map(studentService.students, function(student) {
                        if (student.id == reviewStudent.studentId) {

                            if (!student.mediaUrl) {
                                student.mediaUrl = appConstants.defaultAvatar;
                            }

                            reviewStudent.mediaUrl = student.mediaUrl;
                            reviewStudent.name = student.name;
                        }
                    })
                })

                /**
                 * replace older reviews with updated ones
                 */
                let updatedReviewBookIndex = _.findIndex(vm.books, { id: options.bookId });
                if (updatedReviewBookIndex > -1) {
                    vm.books[updatedReviewBookIndex].assignStudents = options.assignStudents;
                }
            }
        }

        function addBookFromWeb(book) {
            addBook(book);
        }

        function toggleTab(tab) {
            vm.activeTab = tab;
        }

		function getReadStatusPrint() {
			switch (vm.isReadPrint) {
				case 'notread':
					vm.printData.searchBy = {
						'all': false,
						'read': false,
						'notRead': true
					}
					break;

				case 'read':
					vm.printData.searchBy = {
						'all': false,
						'read': true,
						'notRead': false
					}
					break;

				default:
					vm.printData.searchBy = {
						'all': true,
						'read': false,
						'notRead': false
					}
					break;
			}
		}

		function getSelectedStudentsPrint() {
			vm.printData.student = [];
			if (!_.isEmpty(vm.printSelectedStudents)) {
				_.map(vm.printSelectedStudents, function(student) {
					vm.printData.student.push(student.id);
				})
			}
		}

        function print() {
            if (!vm.isSearchClicked) {
				// getReadStatusPrint();
				// getSelectedStudentsPrint();
				// vm.printData.category = vm.printCategory;
				// console.log("Print data", vm.printData)
                bookService.print();
            } else {
                let modelInstance = $uibModal.open({
                    animation: true,
                    size: 'md',
                    controller: 'bookPrintModalController',
                    controllerAs: 'bookPrintModalCtrl',
					template:require('../../pug/book/print-books.pug').default,
                    resolve: {
                        bookData: {
                            searchData: vm.searchData
                        }
                    }
                });
                modelInstance.result
                    .then()
                    .catch(function() {
                        modelInstance.close();
                    });
            }
        }

        function getReadStatus() {
            switch (vm.isReadStatus) {
                case 'notread':
                    vm.searchData.searchBy = {
                        'all': false,
                        'read': false,
                        'notRead': true
                    }
                    break;

                case 'read':
                    vm.searchData.searchBy = {
                        'all': false,
                        'read': true,
                        'notRead': false
                    }
                    break;

                default:
                    vm.searchData.searchBy = {
                        'all': true,
                        'read': false,
                        'notRead': false
                    }
                    break;
            }
        }

		function getBookStatus() {
			switch (vm.status) {
				case 'own':
					vm.searchData.status = {
						'own': true,
						'wishList': false,
						'borrowed': false
					}
					break;

				case 'wish list':
					vm.searchData.status = {
						'own': false,
						'wishList': true,
						'borrowed': false
					}
					break;

				case 'borrowed':
					vm.searchData.status = {
						'own': false,
						'wishList': false,
						'borrowed': true
					}

				default:
					vm.searchData.status = {
						'own': false,
						'wishList': false,
						'borrowed': false
					}
					break;
			}
		}

        function getSelectedStudents() {
            vm.searchData.student = [];
            if (!_.isEmpty(vm.selectedStudents)) {
                _.map(vm.selectedStudents, function(student) {
                    vm.searchData.student.push(student.id);
                })
            }
        }

        function loadMoreBooks() {
            let query;
            if (vm.books.length == 0) {
                query = { skip: 0 };
            } else {
                query = { skip: vm.books.length };
            }

            vm.loadMoreFetchingData = true;
            if (vm.isSearchClicked) {
                search(query);
            } else {
                getUserBooks(query);
            }
        }

        function getUserBooks(queryParam) {
            vm.isFetchingData = true;
            let currentTypeId=0;

            if(vm.activeShelf){
				currentTypeId=vm.activeShelf.id;
			}
            bookService.getUserBooks(queryParam,currentTypeId)
                .then((response) => {
                    if (!queryParam) {
                        vm.books = [];
                    }

                    _.map(response.books, function(book) {
                    	if(book.status == 'borrowed' && book.dueDate) {
							let currentDate = moment(new Date());
							var isafter = moment(book.dueDate).isAfter(currentDate);
							book.dateWarning = !isafter;
                    		book.dueDate = moment(book.dueDate).format('MMM DD, YYYY');
                        }

						// if(book.type) {
						// 	let types = book.type.split(",");
                        //     book.bookType = '';
                        //     book.inLesson = '';
						//
                        //     if(types.length >0){
                        //         for(var i=0; i<types.length; i++) {
                        //            // book.bookType += types[i];
                        //             if(types[i] == 'inLesson'){
                        //                 if(book.bookType){
                        //                     book.bookType += ', In Lesson'
                        //                 }else{
                        //                     book.bookType += 'In Lesson'
                        //                 }
                        //             } else if(types[i] == 'readIndependent'){
                        //                 if(book.bookType){
                        //                     book.bookType += ', Independent Reading'
                        //                 }else{
                        //                     book.bookType += 'Independent Reading'
                        //                 }
                        //             } else if(types[i] == 'readWithHelp'){
                        //                 if(book.bookType){
                        //                     book.bookType += ', Read with help'
                        //                 }else{
                        //                     book.bookType += 'Read with help'
                        //                 }
                        //             }
                        //         }
                        //     }
						// }
                        vm.books.push(book);
                    })

                    vm.copyBooks = vm.books;
                    vm.loadMore = response.isLoadMore;
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    vm.isFetchingData = false;
                    vm.loadMoreFetchingData = false;
                    // let descriptions = document.getElementsByClassName("par");
                    // for(let i = 0; i < discriptions.length ; i++){
                    // 	console.log("hmtl",calculate(discriptions[i]));
                    // }
                });
        }

        function validateSearchData() {
            /**
             * check if search query is entered
             */
            if (vm.searchData.values) {
                /**
                 * check if search query has minimum 2 characters
                 */
                if (vm.searchData.values.trim().length >= 2) {
                    /**
                     * check if atleast one 'search with' is selected
                     */
                    if (!vm.searchData.values) {
                        toastService.toast({
                            message: bookConstants.messages.checkSearchWithCategory,
                            type: 'error',
                            delay: 3000
                        });
                        return false;
                    }

                    /**
                     * check if query length is greater than 100
                     */
                    else if (vm.searchData.values.trim().length > 100) {
                        toastService.toast({
                            message: bookConstants.messages.maxCharacterKeywordError,
                            type: 'error',
                            delay: 3000
                        });
                        return false;
                    } else {
                        return true;
                    }
                }
                /**
                 * show toast for entering atleast 2 characters
                 */
                else {
                    toastService.toast({
                        message: bookConstants.messages.minCharacterKeywordError,
                        type: 'error',
                        delay: 3000
                    });
                    return false;
                }
            }
            /**
             * check if search query is not entered and one of the 'search with' is selected
             */
            else {
                return true;
            }

        }

        function search(query) {
			vm.showSearch = true;
            if (!validateSearchData()) {
                return false;
            }

            if (!vm.isFetchingData) {
                /**
                 * set book array empty for first search result
                 */
                if (!query) {
                    vm.books = [];
                }
                /**
                 * get read status radio button value and get selected students for search
                 */
                getReadStatus();

				getBookStatus();

                getSelectedStudents();
                vm.searchData.category = vm.category;
                vm.searchData.type = vm.type;

                /**
                 * fetch searched data
                 */
                vm.isFetchingData = true;

                bookService.searchFromMyBookLog(vm.searchData, query)
                    .then((resp) => {
                    	// let students = [];
                    	// if(vm.searchData.values) {
						// 	vm.filterOptions.push({key: 'Search', value: vm.searchData.values})
						// }
                    	// if(vm.searchData.category) {
                    	// 	vm.filterOptions.push({key: 'Category', value: vm.searchData.category})
						// }
                    	// // if(vm.searchData.student.length > 0) {
                    	// // 	_.forEach(vm.searchData.student, (student, n) => {
                    	// // 		students.push(student);
						// // 	});
                    	// // 	vm.filterOptions.push({key: 'Students', value: students});
						// // }
                    	// if(vm.status) {
                    	// 	vm.filterOptions.push({key: 'Status', value: vm.status})
						// }
                    	// if(vm.isReadStatus) {
                    	// 	vm.filterOptions.push({key: 'Search By', value: vm.isReadStatus})
						// }
                    	// if(vm.type) {
                    	// 	let type = '';
                    	// 	if(vm.type == 'inLesson') {
                    	// 		type = 'In Lesson'
						// 	}
                    	// 	if(vm.type == 'readIndependently') {
                    	// 		type = 'Independent Reading';
						// 	}
                    	// 	if(vm.type == 'WithHelp') {
                    	// 		type = 'Read With Help';
						// 	}
                    	// 	vm.filterOptions.push({key: 'Type', value: type})
						// }
                    	// if(vm.searchData.date.to && vm.searchData.date.from) {
                    	// 	vm.filterOptions.push({key: 'Date', value: vm.searchData.date.to + ',' + vm.searchData.date.from})
						// } else if(vm.searchData.date.to) {
                    	// 	vm.filterOptions.push({key: 'Date', value: vm.searchData.date.to})
						// } else if(vm.searchData.date.from) {
                    	// 	vm.filterOptions.push({key: 'Date', value: vm.searchData.date.from})
						// }
						//
                    	// console.log('filter options', vm.filterOptions)

                        vm.isSearchClicked = true;
                        if (!resp.data.data.books || !resp.data.data.books.length) {
                            toastService.toast({
                                message: bookConstants.messages.noBookFound,
                                type: 'error',
                                delay: 3000
                            });
                            return false;
                        }

                        _.map(resp.data.data.books, function(book) {
							if(book.status == 'borrowed') {
								let currentDate = moment(new Date());
								var isafter = moment(
									book.dueDate
								).isAfter(currentDate);
								book.dateWarning = !isafter;
								book.dueDate = moment(book.dueDate).format('MMM DD, YYYY');
							}
							if(book.type) {
								let types = book.type.split(",");
								book.bookType = null;
								book.inLesson = '';
								for(var i=0; i<types.length; i++) {
									if(vm.searchData.type) {
										if(book.lesson && vm.searchData.type == 'inLesson') {
											book.inLesson = 'inLesson';
										} else {
											if(book.bookType && types[i] == 'readIndependent' && vm.searchData.type == 'readIndependent') {
												book.bookType += ", Independent Reading";
											} else if(types[i] == 'readIndependent' && vm.searchData.type == 'readIndependent') {
												book.bookType = "Independent Reading";
											}
											if(book.bookType && types[i] == 'readWithHelp' && vm.searchData.type == 'readWithHelp') {
												book.bookType += ", Read with help";
											} else if(types[i] == 'readWithHelp' && vm.searchData.type == 'readWithHelp') {
												book.bookType = "Read with help";
											}
										}
									} else {
										if(book.lesson) {
											book.inLesson = 'inLesson';
										} else {
											if(book.bookType && types[i] == 'readIndependent') {
												book.bookType += ", Independent Reading";
											} else if(types[i] == 'readIndependent') {
												book.bookType = "Independent Reading";
											}
											if(book.bookType && types[i] == 'readWithHelp') {
												book.bookType += ", Read with help";
											} else if(types[i] == 'readWithHelp') {
												book.bookType = "Read with help";
											}
										}
									}
								}
							}
                            vm.books.push(book);
                        })

                        vm.loadMore = resp.data.data.isLoadMore;
                    })
                    .catch(() => {
                        // console.log("invalid request");
                    })
                    .finally(() => {
                        vm.isFetchingData = false;
                        vm.loadMoreFetchingData = false;
                    });
            }
        }

        function oldSearch() {
            if (!vm.data) {
                toastService.toast({
                    message: bookConstants.messages.minCharacterKeywordError,
                    type: 'error',
                    delay: 3000
                });
                return false;
            }

            vm.isFetchingData = true;
            let query = { searchBy: vm.type.title, values: vm.data };

            bookService.searchFromMyBookLog(query)
                .then((resp) => {

                    vm.isFetchingData = false;

                    if (!resp.data) {
                        toastService.toast({
                            message: bookConstants.messages.noBookFound,
                            type: 'error',
                            delay: 3000
                        });
                        return false;
                    }

                    vm.books = resp.data.data;
                    if (!vm.books || vm.books.length < 1) {
                        toastService.toast({
                            message: bookConstants.messages.noBookFound,
                            type: 'error',
                            delay: 3000
                        });
                        return false;
                    }

                })
                .catch(() => {
                    // console.log("invalid request");
                });
        }

        function addBook(book) {
            let modelInstance = $uibModal.open({
                animation: true,
                size: 'lg',
                controller: 'bookEditController',
                controllerAs: 'bookEditCtrl',
				template:require('../../pug/book/edit-book.pug').default,
                resolve: {
                    bookData: {
                        book
                    }
                }
            });

            modelInstance.result.then(afterView).catch(function() {
                modelInstance.close();
            });
        }

        function afterView(options) {
            if (options) {
                /**
                 * book update action
                 */
                if (!options.removeEvent) {
                    /**
                     * update book's updated data
                     */

                    if (options.isEditModal) {
                        let bookUpdateIndex = _.findIndex(vm.books, { bookId: options.book.bookId });
                        /**
                         * update already added book in the book list
                         */
                        if (bookUpdateIndex > -1) {


							if(options.book.bookShelfCategory && options.book.bookShelfCategory.length){
								_.forEach(options.book.bookShelfCategory, currentBookShelf=>{
									let findShelfInOldIndex=_.findIndex(vm.books[bookUpdateIndex].bookShelfCategory,obj=>obj.id===currentBookShelf.id);
									if(findShelfInOldIndex===-1){
										let findShelfIndex=_.findIndex(vm.shelfCategories, obj => obj.id === currentBookShelf.id);
										if (findShelfIndex !== -1) {
											vm.shelfCategories[findShelfIndex].total = vm.shelfCategories[findShelfIndex].total +1;
										}
									}
									else{
										_.remove(vm.books[bookUpdateIndex].bookShelfCategory, function (oldBookShelf) {
											return oldBookShelf.id == currentBookShelf.id;
										});
									}
								});
								if(vm.books[bookUpdateIndex].bookShelfCategory && vm.books[bookUpdateIndex].bookShelfCategory.length) {
									_.forEach(vm.books[bookUpdateIndex].bookShelfCategory, oldBookShelf=>{
										let findShelfIndex=_.findIndex(vm.shelfCategories, obj => obj.id === oldBookShelf.id);
										if (findShelfIndex !== -1) {
											vm.shelfCategories[findShelfIndex].total = vm.shelfCategories[findShelfIndex].total -1;
										}
									});
								}
							}
							else{
								_.forEach(vm.books[bookUpdateIndex].bookShelfCategory, oldBookShelf=>{
									let findShelfIndex=_.findIndex(vm.shelfCategories, obj => obj.id === oldBookShelf.id);
									if (findShelfIndex !== -1) {
										vm.shelfCategories[findShelfIndex].total = vm.shelfCategories[findShelfIndex].total -1;
									}
								});
							}






                        	// if(options.book.status == 'borrowed') {
                        	// 	options.book.dueDate = moment(options.book.dueDate).format('MMM DD, YYYY');
							// }
                        	// if(options.book.type) {
							// 	let types = options.book.type.split(",");
							// 	options.book.bookType = '';
							// 	options.book.inLesson = '';
							// 	for (var i = 0; i < types.length; i++) {
							//
                            //         if(types[i] == 'inLesson'){
                            //             if(options.book.bookType){
                            //                 options.book.bookType += ', In Lesson'
                            //             }else{
                            //                 options.book.bookType += 'In Lesson'
                            //             }
                            //         } else if(types[i] == 'readIndependent'){
                            //             if(options.book.bookType){
                            //                 options.book.bookType += ', Independent Reading'
                            //             }else{
                            //                 options.book.bookType += 'Independent Reading'
                            //             }
                            //         } else if(types[i] == 'readWithHelp'){
                            //             if(options.book.bookType){
                            //                 options.book.bookType += ', Read with help'
                            //             }else{
                            //                 options.book.bookType += 'Read with help'
                            //             }
                            //         }
							// 	}
							// }
                            vm.books[bookUpdateIndex] = options.book;
                            vm.books[bookUpdateIndex].assignStudents = options.studentsAssigned || [];
                            vm.books[bookUpdateIndex].averageRating = options.rating;
                        }
                        /**
                         * add a new book in the book list
                         */
                        else {

                            vm.books.push(options.book);

							// if(options.book.bookShelfId){
							// 	let category=options.book.bookShelfId;
							// 	let findNewShelfCategoryIndex = _.findIndex(vm.shelfCategories, obj => obj.id === category.id);
							// 	if (findNewShelfCategoryIndex !== -1) {
							// 		vm.shelfCategories[findNewShelfCategoryIndex].total = vm.shelfCategories[findNewShelfCategoryIndex].total + 1;
							// 	}
							// }
							if(options.book.bookShelfCategory) {
								_.forEach(options.book.bookShelfCategory, bookShelf => {
									let findBookShelfIndex = _.findIndex(vm.shelfCategories, obj => obj.id === bookShelf.id);
									if (findBookShelfIndex !== -1) {
										vm.shelfCategories[findBookShelfIndex].total = vm.shelfCategories[findBookShelfIndex].total + 1;
									}
								});
							}
						}
                    }
                    /**
                     * update only students review data
                     */
                    else {
                        /**
                         * replace older reviews with updated ones
                         */
                        let updatedReviewBookIndex = _.findIndex(vm.books, { id: options.bookId });
                        if (updatedReviewBookIndex > -1) {
							vm.books[updatedReviewBookIndex].averageRating = options.rating;
                            vm.books[updatedReviewBookIndex].assignStudents = options.studentsAssigned;
                        }
                    }
                }
                /**
                 * on remove action
                 */
                else {
                    /**
                     * remove a review
                     */
                    if (options.reviewRemove) {
                        let bookIndex = _.findIndex(vm.books, { id: options.bookData.bookId });
                        let reviewRemoveIndex;
                        if (bookIndex > -1) {
                            reviewRemoveIndex = _.findIndex(vm.books[bookIndex].assignStudents, { id: options.revmovedReview.studentId })
                        }
                        vm.books[bookIndex].assignStudents.splice(reviewRemoveIndex, 1);

                    } else {
                        /**
                         * remove a book
                         */
                        let removeBookId = options.bookData.bookId;
                        let removeBookIndex = _.findIndex(vm.books, { id: removeBookId });
                        if (removeBookIndex > -1) {
							// if(vm.books[removeBookIndex].bookShelfId && vm.books[removeBookIndex].bookShelfId.id){
							// 	let findBookShelfCategoryIndex=_.findIndex(vm.shelfCategories, { id: vm.books[removeBookIndex].bookShelfId.id });
							// 	if(findBookShelfCategoryIndex > -1){
							// 		vm.shelfCategories[findBookShelfCategoryIndex].total=vm.shelfCategories[findBookShelfCategoryIndex].total-1;
							// 	}
							// }
							if(vm.books[removeBookIndex].bookShelfCategory && vm.books[removeBookIndex].bookShelfCategory.length){
								_.forEach(vm.books[removeBookIndex].bookShelfCategory, bookShelfCategory=>{
									let findBookShelfCategoryIndex=_.findIndex(vm.shelfCategories, { id: bookShelfCategory.id });
									if(findBookShelfCategoryIndex > -1){
										vm.shelfCategories[findBookShelfCategoryIndex].total=vm.shelfCategories[findBookShelfCategoryIndex].total-1;
									}
								});
							}
                            vm.books.splice(removeBookIndex, 1);
                        }
                    }
                }
            }
        }

        function deleteBook(book, bookId) {
            if (angular.isDefined(book.assignments) && book.assignments.length) {
                let modelInstance = $uibModal.open({
                    animation: true,
                    size: 'md',
                    controller: 'confirmationUnassignEntireBookModalController',
                    controllerAs: 'confirmationUnassignEntireBookModalCtrl',
					template:require('../../pug/book/unassign-book-confirmation.pug').default,
                    resolve: {
                        bookData: {
                            assignment: {
                                assignId: bookId
                            }
                        }
                    }
                });
                modelInstance.result
                    .then(function(options) {
                        afterView(options);
                    })
                    .catch(function() {
                        modelInstance.close();
                    });
            } else {
                commonService.confirm({
                        message: bookConstants.messages.deleteBookConfirmation,
                        type: 'danger',
                        modalSize: "md"
                    })
                    .then((confirmation) => {
                        if (confirmation.result === true) {
                            bookService.deleteBook(bookId, false)
                                .then((deleted) => {

                                    toastService.toast({
                                        message: bookConstants.messages.bookDeleted,
                                        type: 'success',
                                        delay: 3000
                                    });

                                    let removeBookIndex = _.findIndex(vm.books, { id: bookId });
                                    if (removeBookIndex > -1) {

										if(vm.books[removeBookIndex].bookShelfId && vm.books[removeBookIndex].bookShelfId.id){
											let findBookShelfCategoryIndex=_.findIndex(vm.shelfCategories, { id: vm.books[removeBookIndex].bookShelfId.id });
											if(findBookShelfCategoryIndex > -1){
												vm.shelfCategories[findBookShelfCategoryIndex].total=vm.shelfCategories[findBookShelfCategoryIndex].total-1;
											}
										}

                                        vm.books.splice(removeBookIndex, 1);
                                    }
                                })
                                .catch((err) => {
                                });
                        } else return;
                    })
                    .catch((err) => {
                    });
            }
        }

        function getBookStudents() {
            return bookService.getAssignedStudent(bookId)
                .then(function(response) {
                    vm.bookStudents = response;
                })
                .catch(function(response) {
                    console.error(response);
                });
        }

        function edit(book) {
            let modelInstance = $uibModal.open({
                animation: true,
                size: 'lg',
                controller: 'bookEditController',
                controllerAs: 'bookEditCtrl',
				template:require('../../pug/book/edit-book.pug').default,
                resolve: {
                    bookData: {
                        book
                    }
                }
            });
            modelInstance.result
            .then(afterView)
            .catch(function() {
                modelInstance.close();
            });
        }

        function afterAddBook(options) {
            let addedBookId = options.bookId;
            let existingBookIndex = _.findIndex(vm.books, { bookId: addedBookId });
            if (existingBookIndex > -1) {
            	if(options.status == 'borrowed') {
					options.dueDate = moment(options.dueDate).format('MMM DD, YYYY');
				}
                vm.books[existingBookIndex] = options;
            }
        }


        function viewFeatureBook(book){
			let bookId = book.id;
			let modelInstance = $uibModal.open({
				animation: true,
				size: 'lg',
				controller: 'bookDetailController',
				controllerAs: 'bookDetailCtrl',
				template:require('../../pug/book/view-book.pug').default,
				resolve: {
					bookData: {
						book: book
					}
				}
			});

		}

        function view(book, activeTab) {
            let bookId = book.id;
            if (!angular.isDefined(bookId) || !bookId) {
                let modelInstance = $uibModal.open({
                    animation: true,
                    size: 'lg',
                    controller: 'bookDetailController',
                    controllerAs: 'bookDetailCtrl',
					template:require('../../pug/book/view-book.pug').default,
                    resolve: {
                        bookData: {
                            book: book
                        }
                    }
                });

                modelInstance.result.then(afterAddBook).catch(function() {
                    modelInstance.close();
                });
            } else {
                let modelInstance = $uibModal.open({
                    animation: true,
                    size: 'lg',
                    controller: 'bookAssignController',
                    controllerAs: 'bookAssignCtrl',
					template:require('../../pug/book/assign-book.pug').default,
                    resolve: {
                        bookData: {
                            bookId,
                            book,
                            activeTab: activeTab
                        }
                    }
                });

                modelInstance.result
                .then(afterView, function() {
                    afterView(JSON.parse(Store.get('ratingData')));
                })
                .catch(function() {
                    modelInstance.close();
                });
            }

        }

        function calculate(other) {
            let old_hieght = other.height();
            other.html('a<br>b').hide().appendTo('body');
            size = other.height() / 2;
            // other.remove();
            return old_hieght / size;
        }

        function addStudentsTag(studentQuery) {

            if (!studentQuery) {
                var deferred = $q.defer();
                deferred.resolve(vm.students);
                return deferred.promise;
            }

            let newStudentList = [];
            _.map(vm.students, function(students) {
                if (_.includes(students.name.toLowerCase(), studentQuery.toLowerCase())) {
                    newStudentList.push(students);
                }
            });

            var deferred = $q.defer();
            deferred.resolve(newStudentList);

            return deferred.promise;
        }

        function searchOnEnter(event) {
            if (event.keyCode == 13) {
                searchDatabase();
            }
            if(event.type==="click"){
				searchDatabase();
			}
        }

		function searchDatabase(queryParam) {
			vm.showSearch = true;
			let skip;

            if(!vm.searchData.values || vm.searchData.values.length <= 2) {
                //getUserBooks();
                vm.books = vm.copyBooks;
                return;
            }

            vm.disableSearch = {
                'disable': true
            }

            if (!queryParam) {
                skip = 0;
                vm.books = [];
            } else {
                skip = queryParam.skip;
            }

            let query = { searchBy: vm.searchWith,
                        values: vm.searchData.values,
                        skip: skip };

            if (!vm.isFetchingData) {
                vm.isFetchingData = true;
                bookService.search(query)
                    .then((resp) => {
                        if (!resp.data) {

                            toastService.toast({
                                message: 'There are no books under this criteria',
                                type: 'error',
                                delay: 3000
                            });
                            vm.isFoundData = false;
                            vm.loadMoreFetchingData = false;
                            vm.isFetchingData = false;
                            return false;
                        }

                        if (resp.data.data.books.length) {
                            _.map(resp.data.data.books, function (book) {
                                if (!book.id) {
                                    vm.notAdded = true;
                                } else {
                                    if (book.status == 'borrowed') {
                                        let currentDate = moment(new Date());
                                        var isafter = moment(
                                            book.dueDate
                                        ).isAfter(currentDate);
                                        book.dateWarning = !isafter;
                                        book.dueDate = moment(book.dueDate).format('MMM DD, YYYY');
                                    }
                                    if (book.type) {
                                        let types = book.type.split(",");
                                        book.type = null;
                                        book.inLesson = '';
                                        for (var i = 0; i < types.length; i++) {
                                            if (book.lesson) {
                                                book.inLesson = 'inLesson';
                                            } else {
                                                if (book.bookType && types[i] == 'readIndependent') {
                                                    book.bookType += ", Independent Reading";
                                                } else if (types[i] == 'readIndependent') {
                                                    book.bookType = "Independent Reading";
                                                }
                                                if (book.bookType && types[i] == 'readWithHelp') {
                                                    book.bookType += ", Read with help";
                                                } else if (types[i] == 'readWithHelp') {
                                                    book.bookType = "Read with help";
                                                }
                                            }
                                        }
                                    }
                                }
                                vm.books.push(book);
                                vm.books = _.uniqBy(vm.books, 'bookId')
                            })
                        } else {
                            toastService.toast({
                                message: 'There are no books under this criteria',
                                type: 'error',
                                delay: 3000
                            });
                            vm.isFoundData = false;
                        }

                        vm.loadMoreBookStatus = resp.data.data.isLoadMore;
                    })
                    .catch((er) => {
                        console.log("book not find");
                    })
                    .finally(() => {
                        vm.loadMoreFetchingData = false;
                        vm.isFetchingData = false;
                        vm.disableSearch = {
                            'disable': false
                        }
                    });
            }
		}

		function loadMoreSearchBooks() {
			let queryParam;
			vm.loadMoreFetchingData = true;
			if (!vm.books.length) {
				queryParam = { skip: 0 };
			} else {
				queryParam = { skip: vm.books.length };
			}

			searchDatabase(queryParam);
		}

		function getBookShelfCategories(){
			bookService.getBookShelfCategories()
				.then((response) => {
					vm.shelfCategories = response;
				})
				.catch((err) => {
					console.error(err);
				})
		}



        function init() {
            vm.activeTab = 'library';

			// HelpCrunch('updateUser', $rootScope.helpCrunchuser, 'e8aa8af95cd6a05b80308dcb3dd6901d', function() {});

            return [getBookShelfCategories(),getUserBooks()];
            // getBookStudents()];
        }
        function checkPopover(book){
			$scope.dynamicPopover = {
				content: book,
			};
		}

        this.$onInit = init;
    }
export default bookController;
