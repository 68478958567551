//Service for tracking events



trackEvents.$inject = ['$rootScope', '_', '$window', '$location'];
function trackEvents($rootScope, _, $window, $location) {

    angular.extend(this, {
        send,
        sendGAPageView,
        sendGAException
    });
    $rootScope.openSupportChat = openSupportChat;

    function checkIntercom() {
        if (typeof $window.Intercom !== 'undefined') {
            return true;
        }
        return false;
    }

    function checkInspectlet() {
        if (typeof $window.__insp !== 'undefined') {
            return true;
        }
        return false;
    }

    function checkGoogleAnalytics() {
        if (typeof $window.ga !== 'undefined') {
            return true;
        }
        return false;
    }

    function getIntercomAppId() {
        let appId = 'dlkcqecp';
        if (document.location.hostname === 'homeschoolpanda.com' || document.location.hostname === 'app.homeschoolpanda.com') {
            appId = 'dlkcqecp';//support@homeschoolpanda.com
            // appId = 'e6js1d88';//contact@homeschoolpanda.com
        }
        return appId;
    }

    function initIntercom(currentUser) {

        if (currentUser) {

            if (checkIntercom()) {

                let appId = getIntercomAppId();

                Intercom('boot', {
                    app_id: appId,
                    email: currentUser.email,
                    user_id: currentUser.id,
                    name: currentUser.name,
                    address: currentUser.address,
                    city: currentUser.city,
                    dob: currentUser.dob,
                    contactNumber: currentUser.contactNumber,
                    location: currentUser.location,
                    countryCode: currentUser.countryCode,
                    zipCode: currentUser.zipCode,
                    verified: currentUser.verified,
                    status: currentUser.status,
                    gender: currentUser.gender,
                    signupType: currentUser.signupType,
                    userRole: currentUser.userRole,
                    payment_trial: currentUser.trial,
                    payment_plan: currentUser.payment && currentUser.payment.planId ? currentUser.payment.planId.title : 'NoPlan',
                    // user_hash: currentUser.user_hash,
                    plan_end_date: moment(currentUser.payment && currentUser.payment.nextPayment).format("YYYY-MM-DD"),
                    payment_status: currentUser.payment && currentUser.payment.currStatus,
                    createdAt: new Date(currentUser.createdAt).getTime() / 1000
                });
                //Show the Intercom help icon.
                /*document.getElementById('supportSection').style.display = 'block';*/
            }
            if (checkInspectlet()) {
                __insp.push(['identify', currentUser.email]);
                __insp.push(['tagSession', { email: currentUser.email }]);
                __insp.push(['tagSession', { name: currentUser.name }]);
            }

            if (checkGoogleAnalytics()) {
                ga('set', 'userId', currentUser.id);
            }
        }
    }

    function init() {
        //first check localstorage
        if ($rootScope.user) {
            let currentUser = $rootScope.user;
            currentUser.userHash = currentUser.id;
            initIntercom(currentUser);
        }
    }

    function sendData(type, data) {
        //tracking events in intercom
        if (!checkIntercom()) {
            return false;
        }
        type = getLocationName(type);
        Intercom('trackEvent', type, data);
    }

    function send(type, data) {
        sendData(type, data);
    }

    function openSupportChat() {
        if (!checkIntercom()) {
            return false;
        }
        Intercom('show');
    }

    function getLocationName(transitionToName) {
        let location = '';
        switch (transitionToName) {
            case '404':
                location = '/404';
                break;
            case 'collaborateViewChat':
                location = '/chat';
                break;
            case 'collaborateSearch':
                location = '/chat/search';
                break;
            default:
                location = $location.path().replace(/[0-9]\/?/g, '');
        }

        return location;
    }

    function sendGAPageView(transitionTo) {

        if (checkGoogleAnalytics()) {
            let location = getLocationName(transitionTo.name);

            /**
             * @link https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
             * @returns {undefined}
             */
            $window.ga('set', 'title', transitionTo.name.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }));
            $window.ga('set', 'page', location);
            $window.ga('send', 'pageview');
        }
    }

    function sendGAException(error) {
        if (checkGoogleAnalytics()) {
            $window.ga('send', 'exception', {
                'exDescription': error.message,
                'exFatal': true
            });
        }
    }

    _.defer(() => {
        init();
    });
}

export default trackEvents;