const angular = window.angular;
referralDetailsController.$inject = [
  "$rootScope",
  "$scope",
  "commonService",
  "$state",
  "fileService",
  "$q",
  "moment",
  "toastService",
  "appConstants",
];

function referralDetailsController(
  $rootScope,
  $scope,
  commonService,
  $state,
  studentService,
  fileService,
  $q,
  moment,
  toastService,
  appConstants,
  studentConstants
) {
  let vm = this;

  vm.totalCreditEarned = function () {};
}
export default referralDetailsController;
