function truncateString() {
  return truncateStringFilter;

  function truncateStringFilter(input, length) {
    if (input.length > length) {
      input = input.substring(0, length).replace(/\W*\w*$/, "") + "\u2026";
    }
    return input;
  }
}

export default truncateString;
