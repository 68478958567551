let angular = window.angular;
    bookEditController.$inject = ['bookData', '$scope', '$uibModalInstance', 'bookService', 'studentService', '$state', '$stateParams', '_', 'commonService', 'toastService', 'moment', 'lessonConstants', '$uibModal', 'fileService', 'appConstants', '$rootScope', '$q'];

    function bookEditController(bookData, $scope, $uibModalInstance, bookService, studentService, $state, $stateParams, _, commonService, toastService, moment, lessonConstants, $uibModal, fileService, appConstants, $rootScope, $q) {

        let vm = this;
        let lessonId = 0;
        let bookId;
        vm.book = {};
        vm.assignedStudents = [];
        vm.studentsToAssign = [];
        vm.assignmentDays = [];
        $scope.assignmentForm = {};
        let studentsAssigned;
        let assignStudents;
        vm.assigningStudentId = 0;
        vm.isMaximumDaysSelected = false;
        vm.isFormInvalid = false;
        vm.formErrorMessage = '';
        vm.assignmentEndDate = null;
        vm.comment = ' ';
        $scope.title = null;
        vm.defaultBookAvatar = appConstants.defaultBookAvatar;
        vm.category = null;
        vm.status = null;
        vm.dueDate = null;
        vm.dateDuration = 0;
        vm.dateMessage = null;
        vm.types = null;
        vm.inLesson = false;
        vm.independent = false;
        vm.withHelp = false

		vm.shelfCategories=[];
		vm.selectedBookShelf=[];

        angular.extend(this, {
            close,
            save,
            selectBookCover,
            isTouched,
            closeThisModal,
			onDueDateChange,
			changeImageUrl,
			selectBookShelf,
			checkBookShelfCategory
        });

		function checkBookShelfCategory(bookShelfs, currentShelf){
			if(bookShelfs && currentShelf){
				let checkBookCategory=_.find(bookShelfs,obj=>obj.id===currentShelf.id);
				if(checkBookCategory){
					return true;
				}
			}
			return false;
		}
		function selectBookShelf(category){
			// vm.selectedBookShelf=category;
			let findShelfCategory=_.find(vm.selectedBookShelf, obj=>obj.id===category.id);
			if(findShelfCategory){
				vm.selectedBookShelf=_.without(vm.selectedBookShelf,findShelfCategory);
			}
			else{
				vm.selectedBookShelf = vm.selectedBookShelf.concat(category);
			}

		}

		// function selectBookShelf(category){
		// 	vm.selectedBookShelf=category;
		// }

		function getBookShelfCategories(){
			bookService.getBookShelfCategories()
				.then((response) => {
					vm.shelfCategories = response;
				})
				.catch((err) => {
					console.error(err);
				})
		}

		function changeImageUrl(img){
			if(img){
				img=img.replace('http:','https:');
			}
			return img;
		}

        function closeThisModal() {
            $uibModalInstance.dismiss();
        }

        function selectBookCover(media) {
            return fileService.imageUploadWithCrop(media, 'book')
                .then(selectBookCoverSuccess)
                .catch(selectBookCoverError);
        }

        function selectBookCoverSuccess(response) {
            if (response.file) {
                vm.imageFile = response.file;
            }
        }

        function selectBookCoverError(error) {
            // console.log('Closed modal', error);
        }

        function save() {

            if (!vm.book.title) {
                toastService.toast({
                    message: lessonConstants.messages.completeBookTitle,
                    type: 'error',
                    delay: 4000
                });
                return;
            }

            $rootScope.loadingBtn('editSaveBtn', 'Saving');
            vm.disableObj = {
                'disable': true
            }

            if (!_.isObject(vm.imageFile) && _.isString(vm.imageFile)) {
                vm.book.img = vm.imageFile;
            }
            if(vm.book.status != 'borrowed') {
            	vm.book.dueDate = null;
			}
            vm.book.type = null;
            if(vm.inLesson) {
            	vm.book.type = 'inLesson';
			}
            if(vm.independent && vm.book.type) {
            	vm.book.type += ',readIndependent';
             }
             else if(vm.independent) {
            	vm.book.type = 'readIndependent';
			}
            if(vm.withHelp && vm.book.type) {
            	vm.book.type += ',readWithHelp';
            }
            else if(vm.withHelp) {
            	vm.book.type = 'readWithHelp';
			}

			if(vm.selectedBookShelf){
				// vm.book.bookShelfId=vm.selectedBookShelf.id;
				vm.book.bookShelfCategory=vm.selectedBookShelf;
			}

            bookService.editBook(vm.book, bookData)
                .then(saveSuccess)

                .catch((err) => {
                    toastService.toast({
                        message: err,
                        type: 'success',
                        delay: 2000
                    });
                })
                .finally(() => {
                    if (angular.isDefined(bookData.addGlobally) && (bookData.addGlobally)) {
                        let modelInstance = $uibModal.open({
                            animation: true,
                            size: 'lg',
                            controller: 'bookAssignController',
                            controllerAs: 'bookAssignCtrl',
                            template:require('../../pug/book/assign-book.pug').default,
                            resolve: {
                                bookData: {
                                    bookId: vm.bookObj.id,
                                    book: vm.bookObj,
                                    activeTab: 'students'
                                }
                            }
                        });

                        modelInstance.result.then(function(options) {
                            /**
                             * pass data to display after assigning book to students
                             */
                            close(options);
                        }).catch(function() {
                            modelInstance.close();
                        });

                    } else {
                        close();
                    }

                    $rootScope.loadingBtnComplete('editSaveBtn', 'Save');
                    vm.disableObj = {
                        'disable': false
                    }
                })
        }

        function fileUploadResponse(response) {
            if (angular.isDefined(response) && response) {
                vm.bookObj.imageId = response.data.data[0].hash;
                vm.bookObj.mediaId = response.data.data[0].id;
                vm.bookObj.img = response.data.data[0].mediaUrl;
            }
            return response;
        }

        function saveSuccess(response) {
            let deferred = $q.defer();
            vm.bookObj = response.data;
            let BookId = vm.bookObj.id;
            toastService.toast({
                message: response.message,
                type: 'success',
                delay: 2000
            });
            if (_.isObject(vm.imageFile) && _.isString(vm.imageFile.name) && (vm.imageFile.size > 0)) {
                $rootScope.loadingBtn('save', 'Uploading Picture');
                return fileService.uploadFile(vm.imageFile, BookId, 'book')
                    .then(fileUploadResponse);
            } else {
                deferred.resolve();
            }

            return deferred.promise;
        }

        function close(data = {}) {
            if (!Object.keys(data).length || (Object.keys(data).length && !data.removeEvent && !data.studentsAssigned.length)) {
                data.studentsAssigned = studentsAssigned;
            }
            angular.extend(data, {
                book: vm.bookObj,
                isEditModal: true,
                rating: bookData.book.averageRating
            });
            // $uibModalInstance.close({ 'book': vm.bookObj, 'isEditModal': true, 'studentsAssigned': studentsAssigned });
            $uibModalInstance.close(data);
        }

        // Get details about selected book
        function getBookDetails() {
            return bookService.viewBook(bookId)
                .then(function(response) {
                    vm.book = response;
                    vm.studentsToAssign = response.students;
                    for (let i = 0; i < vm.studentsToAssign.length; i++) {
                        if (!vm.studentsToAssign[i].hasOwnProperty('comment')) {
                            vm.studentsToAssign[i].comment = '';
                            vm.studentsToAssign[i].rating = 0;
                        }
                    }
                })
                .catch(function(response) {
                    console.error(response);
                });
        }

        function isTouched(field) {
            return field.$touched || vm.form.$submitted;
        }

		function onDueDateChange() {
			// if (!vm.dueDate) {
			// 	vm.dueDate = moment().format("Y-MM-DD");
			// }

			vm.book.dueDate = moment(vm.book.dueDate, "Y-MM-DD")
				.add(vm.dateDuration, "day")
				.format("Y-MM-DD");
			let currentDate = moment(new Date());
			var isafter = moment(
				vm.book.dueDate
			).isAfter(currentDate);
			if(isafter){
				vm.dateMessage ="";
			}
			// vm.eventStartTime = moment(vm.eventStartTime, 'HH:mm').add(vm.timeDuration, 'seconds').format('HH:mm');
		}

        this.$onInit = function() {
            // vm.activeTab = bookData.activeTab;
            bookId = commonService.convertToInteger(bookData.bookId);
            let book = bookData.book || {};

			// if(book.bookShelfId && book.bookShelfId.id){
			// 	vm.selectedBookShelf=book.bookShelfId;
			// }
			if(book.bookShelfCategory){
				vm.selectedBookShelf=book.bookShelfCategory;
			}
            // vm.actionType = angular.isDefined(bookData.actionType) ? bookData.actionType : undefined;
			if(book.type) {
				vm.types = book.type.split(",");
				for(var i=0; i<vm.types.length; i++) {
					if(vm.types[i] == 'inLesson') {
						vm.inLesson = true;
					} else if(vm.types[i] == 'readIndependent') {
						vm.independent = true;
					} else if(vm.types[i] == 'readWithHelp') {
						vm.withHelp = true;
					}
				}
			}

            // vm.book.model = bookData.model;
            vm.book.bookId = book.bookId || null;
            vm.book.title = book.title || null;
            vm.book.authors = book.authors || null;
            vm.book.description = book.description || null;
            vm.imageFile = book.img || null;
            vm.book.wordCount = book.wordCount || null;
            vm.book.pageCount = book.pageCount || null;
            vm.book.level = book.level || null;
            vm.book.category = book.category || null;
            vm.book.status = book.status || null;
            vm.book.dueDate = book.dueDate || null;
            vm.book.isbn = book.isbn || null;
            vm.book.location = book.location || null;
            studentsAssigned = book.assignStudents || [];
            // assignStudents = book.assignStudents || [];

            // return [getBookDetails()];
			getBookShelfCategories();
        }
    }

export default bookEditController;
