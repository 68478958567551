let angular = window.angular;
    confirmationUnassignEntireBookModalController.$inject = ['bookConstants', 'bookData', '$scope', '$uibModalInstance', 'bookService', 'studentService', '$state', '$stateParams', '_', 'commonService', 'toastService', 'moment', 'lessonConstants', '$uibModal', 'calendarConstants'];

    function confirmationUnassignEntireBookModalController(bookConstants, bookData, $scope, $uibModalInstance, bookService, studentService, $state, $stateParams, _, commonService, toastService, moment, lessonConstants, $uibModal, calendarConstants) {

        let vm = this;
        let returnData = {};
        vm.confirmUnassignValue = 'book';
        let assignment = bookData.assignment;
        vm.isDeleteAssignment = false;
        angular.extend(this, {
            close,
            submit
        });

        $scope.data = {};

        function close(data) {
            let defaultCloseData = {
                removeEvent: false,
                bookData: {
                    bookId: assignment.assignId
                }
            }
            if(!data) {
                data = defaultCloseData;
            }
            $uibModalInstance.close(data);
        }

        function getDeleteAssignmentStatus() {
            switch (vm.confirmUnassignValue) {
                case 'book':
                    vm.isDeleteAssignment = false;
                    break;
                case 'assignment':
                    vm.isDeleteAssignment = true;
                    break;
                default:
                    vm.isDeleteAssignment = false;
                    break;
            }
        }

        function submit() {
            getDeleteAssignmentStatus();
            let removeEvent = false;
            bookService.deleteBook(assignment.assignId, vm.isDeleteAssignment)
                .then((response) => {
                    toastService.toast({
                        message: bookConstants.messages.bookDeleted,
                        type: 'success',
                        delay: 3000
                    });
                    removeEvent = true;
                })
                .catch((err) => {
                    // console.log("errror", err);
                })
                .finally(() => {
                    let bookDeletedData = {
                        removeEvent: removeEvent,
                        bookData: {
                            bookId: assignment.assignId
                        }
                    }
                    close(bookDeletedData);
                })
        }

        this.$onInit = function() {
            // console.log("ASSIGNMENT", bookData.assignment)
        }
    }
export default confirmationUnassignEntireBookModalController;
