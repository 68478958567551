let angular = window.angular;
    bookDetailController.$inject = ['bookData', '$uibModal', '$uibModalInstance', '$state', 'bookService', 'commonService', '$timeout', 'toastService', 'studentService', '_', 'appConstants', 'bookConstants'];

    function bookDetailController(bookData, $uibModal, $uibModalInstance, $state, bookService, commonService, $timeout, toastService, studentService, _, appConstants, bookConstants) {

        let vm = this;
        vm.isFetchingData = false;
        vm.isFetchingExportURL = false;
        let returnData;
        angular.extend(vm, {
            close,
            assign,
            add,
            deleteBook,
            edit
        });

        function edit() {
            // close();
            let book = bookData.book;
            let modelInstance = $uibModal.open({
                animation: true,
                size: 'lg',
                controller: 'bookEditController',
                controllerAs: 'bookEditCtrl',
                template:require('../../pug/book/edit-book.pug').default,
                resolve: {
                    bookData: {
                        book,
                        addGlobally: true
                    }
                }
            });

            modelInstance.result.then(function(options) {
                $uibModalInstance.close(options);

            }).catch(function() {
                modelInstance.close();
            });
        }

        function getDeleteAssignmentStatus() {
            switch (vm.confirmUnassignValue) {
                case 'book':
                    vm.isDeleteAssignment = false;
                    break;
                case 'assignment':
                    vm.isDeleteAssignment = true;
                    break;
                default:
                    vm.isDeleteAssignment = false;
                    break;
            }
        }

        function deleteBook() {
            let modelInstance = $uibModal.open({
                animation: true,
                size: 'md',
                controller: 'confirmationUnassignEntireBookModalController',
                controllerAs: 'confirmationUnassignEntireBookModalCtrl',
                template:require('../../pug/book/unassign-book-confirmation.pug').default,
                resolve: {
                    bookData: {
                        assignment: {
                            assignId: vm.bookId
                        }
                    }
                }
            });
            modelInstance.result
                .then(function(options) {
                    close(options);
                })
                .catch(function() {
                    modelInstance.close();
                });
        }

        function add() {
            bookService.addBook(bookData.book)
                .then(function(response) {
                    toastService.toast({
                        message: response.data.message,
                        type: 'success',
                        delay: 3000
                    });

                    close(response.data.data);
                })
                .catch((err) => {
                    toastService.toast({
                        message: bookConstants.messages.bookAlready,
                        type: 'success',
                        delay: 3000
                    });
                });
        }

        function close(data = returnData) {
            $uibModalInstance.close(data);
        }

        function assign(bookId) {
            close();
            let modelInstance = $uibModal.open({
                animation: true,
                size: 'lg',
                controller: 'bookAssignController',
                controllerAs: 'bookAssignCtrl',
                template:require('../../pug/book/assign-book.pug').default,
                resolve: {
                    bookData: {
                        bookId
                        // activeTab: activeTab
                    }
                }
            });

            modelInstance.result.then(function() { close(); }).catch(function() {
                modelInstance.close();
            });
        }

        function getBookDetail() {
            vm.isFetchingData = true;
            bookService.viewBook(vm.bookId)
                .then(setLessonData)
                .catch((error) => {
                    console.error(error);
                    onLessonNotFound();
                })
                .finally(() => {
                    vm.isFetchingData = false;
                });
        }

        function getReviewers() {
            return lessonService.getAssignedStudent(vm.bookId)
                .then(function(response) {
                    // vm.assignedStudents = _.uniqBy(response, 'id');
                    // return getLessonDetail();
                })
                .catch(function(response) {
                    console.error(response);
                });
        }

        this.$onInit = function() {

            let book = bookData.book;
            // let bookId = commonService.convertToInteger(book.bookId);
            vm.model = bookData.model;
            vm.bookId = book.id;
            vm.title = book.title;
            vm.pageCount = book.pageCount;
            vm.level = book.level;
            vm.authors = book.authors || null;
            vm.description = book.description || null;
            vm.img = book.img;
            return;
        };

    }
export default bookDetailController;
