let angular = window.angular;

lessonElectronicMediaController.$inject = [
  "toastService",
  "$scope",
  "appConstants",
  "$rootScope",
  "$q",
  "$uibModal",
  "$uibModalInstance",
  "resourceData",
];

function lessonElectronicMediaController(
  toastService,
  $scope,
  appConstants,
  $rootScope,
  $q,
  $uibModal,
  $uibModalInstance,
  resourceData
) {
  let vm = this;
  vm.name = "";
  vm.description = "";
  vm.newResource = null;
  vm.type = "add";
  vm.people = [
    {
      firstName: "Daryl",
      surname: "Rowland",
      twitter: "@darylrowland",
      pic: "img/daryl.jpeg",
    },
    {
      firstName: "Alan",
      surname: "Partridge",
      twitter: "@alangpartridge",
      pic: "img/alanp.jpg",
    },
    {
      firstName: "Annie",
      surname: "Rowland",
      twitter: "@anklesannie",
      pic: "img/annie.jpg",
    },
  ];
  vm.openin = "_self";
  vm.text = "";
  vm.url = "";
  angular.extend(vm, {
    close,
    isTouched,
    createResource,
    localSearch,
    onMediaSelect,
  });

  function onMediaSelect($item, $model, $label, $event) {
    // console.log("$model",$model)
    // console.log("$label",$label)
    // console.log("$event",$event)
    vm.name = $item.name;
    var a = document.createElement("DIV");
    a.innerHTML = $item.description;
    vm.url = a.firstChild.href;
    vm.text = a.firstChild.innerText;
    vm.openin = a.firstChild.target;
  }

  function localSearch(str) {}

  function createResource() {
    if (!vm.form.$valid) {
      return;
    }
    // vm.description=$(".notepad-electronicmedia").html().toString();
    vm.description =
      '<a href="' +
      vm.url +
      '"  target="' +
      vm.openin +
      '">' +
      vm.text +
      "</a>";

    let data = {
      name: vm.name,
      description: vm.description,
    };
    if (vm.type == "edit") {
      vm.newResource.name = vm.name;
      vm.newResource.description = vm.description;
      data = vm.newResource;
    }
    close(data);
  }
  this.$onInit = function () {
    vm.mediaNames = resourceData.mediaNames;
    if (resourceData.newResource && resourceData.newResource.editNewResource) {
      vm.name = resourceData.newResource.editNewResource.name;
      var a = document.createElement("DIV");
      a.innerHTML = resourceData.newResource.editNewResource.description;
      vm.url = a.firstChild.href;
      vm.text = a.firstChild.innerText;
      vm.openin = a.firstChild.target;
      if (resourceData.newResource.type) {
        vm.type = resourceData.newResource.type;
        if (resourceData.newResource.type == "edit") {
          vm.newResource = resourceData.newResource.editNewResource;
        }
      }
      // setTimeout(()=>{
      // 	// $(".notepad-electronicmedia").html(newResource.editNewResource.description);
      //
      // },300);
    }
  };

  function close(data) {
    $uibModalInstance.close(data);
  }

  function isTouched(field) {
    return field.$touched || vm.form.$submitted;
  }
}

export default lessonElectronicMediaController;
