let angular = window.angular;
    userApprovalController.$inject = ['lessonService',  '$uibModal', 'commonService', 'toastService'];

    function userApprovalController(lessonService,  $uibModal, commonService, toastService) {

        let vm = this;
		vm.isFetchingUsersData = true;

        angular.extend(this, {
			lessonLoadMore,
			lessonView,
			lessonStatusChange
        });

        function getLessons(){
			vm.loadingLessons = true;
			vm.isFetchingUsersData = true;
			let request = 'approve';
			let lessonTitle = null;
			let	filterData = {
				grade: null,
				weeks: 0,
				days: 0
			}
			let pageNumber = 1;
			// if (!vm.form.$valid) {
			// 	return;
			// }
			lessonService.getPublicLessons(lessonTitle, filterData, pageNumber, request)
			.then((response)=>{
				vm.lessonsDetails = response;
				vm.loadingLessons = false;
				vm.showResults = true;
			})
			.catch(()=>{
				toastService.toast({message: 'Lesson Not Found', type: 'error', delay: 2000});
			})
			.finally(() => {
				vm.isFetchingUsersData = false;
			})
		}

		function lessonLoadMore(){
			let pageNumber = vm.lessonsDetails.nextPage || 1;
			let url = null;
			let request = 'approve';
			let	filterData = {
				grade: null,
				weeks: 0,
				days: 0
			}
			lessonService.getPublicLessons(url, filterData, pageNumber, request)
			.then((response)=>{

				if(response.lessons.length >= 1){
					vm.lessonsDetails.lessons = vm.lessonsDetails.lessons.concat(response.lessons);
				}

				vm.lessonsDetails.page = response.page;
				vm.lessonsDetails.nextPage = response.nextPage;
				vm.lessonsDetails.loadMore = response.loadMore;
				vm.loadingLessons = false;
				vm.showResults = true;

			})
			.catch(()=>{
				toastService.toast({message: 'Lesson Not Found', type: 'error', delay: 2000});
			});
		}

		function lessonView(lesson) {
            let modalInstance = $uibModal.open({
                animation: true,
                size: 'lg',
                controller: 'lessonViewModalController',
                controllerAs: 'lessonViewModalCtrl',
				template : require('../../pug/user/lesson-view-modal.pug').default,
                resolve: {
                    data: {
                        lesson: lesson
                    }
                }
            });

            modalInstance.result.then(function(id, studentLesson) {
                let assignedStudent = _.find(vm.studentsToAssign, { 'id': id });
                if (assignedStudent) {
                    vm.assignedStudents.push(assignedStudent);
                }
            }).catch(function() {
                modalInstance.close();
            });
		}

		function lessonStatusChange(lessonId, ispublic, status, userId, index) {
			let request = 'admin';
            lessonId = commonService.convertToInteger(lessonId);
            if (!lessonId) {
                return;
            }
			 lessonService.lessonStatusChange(lessonId, ispublic, status, userId, request)
			 .then(response => {
				 vm.lessonsDetails.lessons[index].status = response.status;
			 });
        }

        function init() {
			return getLessons();
		}

		this.$onInit = init;
    }
export default userApprovalController;
