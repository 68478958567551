let angular = window.angular;
hspFileViewModalController.$inject = ["$uibModalInstance", "file", "$sce"];

function hspFileViewModalController($uibModalInstance, file, $sce) {
  /* jshint validthis:true */
  let vm = this;

  angular.extend(vm, {
    close: close,
  });

  function close() {
    $uibModalInstance.dismiss();
  }

  activate();

  function activate() {
    if (file.meta.mimeType.includes("video")) {
      vm.fileType = "video";
    } else if (file.meta.mimeType.includes("image")) {
      vm.fileType = "image";
    } else if (file.meta.mimeType.includes("pdf")) {
      vm.fileType = "pdf";
    } else {
      vm.fileType = "default";
    }

    vm.file = file;
    if (vm.file && vm.file.mediaUrl) {
      vm.file.mediaUrl = $sce.trustAsResourceUrl(vm.file.mediaUrl);
    }
  }
}
export default hspFileViewModalController;
