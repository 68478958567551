let angular = window.angular;

    todoController.$inject = ['categoryData','$uibModalInstance', '$rootScope', 'moment', '$q', 'taskService','commonService', '$timeout', 'dashboardConstants', '$state', '_', 'chatConstants', 'toastService'];

    function todoController(categoryData, $uibModalInstance, $rootScope, moment, $q, taskService, commonService, $timeout, dashboardConstants, $state, _, chatConstants, toastService) {
        let vm = this, timezone;
        let categoryId;
        vm.taskTitle = '';
        vm.taskList = '';
        vm.newList = '';
        vm.todos = [];
        vm.model = '';
        vm.category = '';
        vm.afterAddCategory = false;
        vm.addFlag = false;
        /**
         * Agenda defaults
         */
        vm.startDate = moment();
        vm.endDate = moment().add(1, 'd');
        timezone = -1 * (new Date()).getTimezoneOffset();

        vm.ngEmbeddedOption = chatConstants.ngEmbeddedOption;

        /**
         * Display Limits
         * @type type
         */
        vm.taskLimit = dashboardConstants.taskLimit;
        vm.editTodoIndex = -1;
        vm.editCategoryIndex = -1;

        let taskQueryParams = {
            limit: dashboardConstants.taskLimit,
            skip: 0
        };

        angular.extend(this, {
            close,
            loadMoreTask,
            toggleEditTodo,
            toggleEditTodoForm,
            addCategory,
            editCategory,
            addTodo,
            addTask,
            editTodo,
            deleteCategory,
            deleteTodo,
            closeClicked,
            markTodo,
            toggle,
            done,
        });

        function done(){
            close();
        }

        function addTask(title) {
            if (!title || !vm.taskForm.$valid) {
                return;
            }
            taskService.addTask(title)
                .then((response) => {
                    vm.taskTitle = '';
                    // vm.todos.push({t);
                })
                .catch((response) => {
                    console.error(response);
                })
                .finally(() => {
                    vm.taskForm.$setUntouched();
                    vm.taskForm.taskTitle.$setPristine();
                });
        }

        function loadMoreTask() {
            vm.taskLimit += dashboardConstants.taskLimit;
            taskQueryParams.skip = vm.todos.length;
            getTasks();
        }

        function close() {
            // $uibModalInstance.close(true);
            $uibModalInstance.dismiss();
        }

        function closeCategory() {
            // $uibModalInstance.close(true);
            $uibModalInstance.dismiss();
        }

        function toggleEditTodo($todoIndex, isEditCancel) {
            isEditCancel ? vm.todos[$todoIndex].title = vm.todos[$todoIndex].oldTitle : vm.todos[$todoIndex].oldTitle = vm.todos[$todoIndex].title;
        }

        function editCategory(category){
            if(!category){
                return;
            }
            taskService.editCategory(categoryId, category)
                .then(res => {

                    toastService.toast({
                        message:dashboardConstants.messages.category.categoryUpdated,
                        type: 'success',
                        delay: 2000
                    });
                })
                .finally(()=>{
                    vm.isEditing = false;
                    vm.editCategoryIndex = -1;
                })
        }

        function markTodo(todoId){
            taskService.completeTask(todoId)
                .then(res => {
                })
        }

        function toggleEditTodoForm($index, $todoIndex, isEditCancel){
            vm.editTodoIndex = angular.isNumber($index) ? commonService.convertToInteger($index) : -1;
            if (~vm.editTodoIndex){
                vm.editTodoIndex = $index;
            }
            else{
                vm.editCategoryIndex = -1;
            }
            console.log("vm.editTodoIndex=====", vm.editTodoIndex);
            toggleEditTodo($todoIndex, isEditCancel);
        }

        function toggle(){
            vm.isEditing = true;
        }

        function closeClicked() {
            vm.isClose = true;
        }

        function addCategory(title){
            if(vm.isClose){
                vm.isClose = false;
                return;
            }
            if(!title){
                return;
            }
            vm.isEditing = true;
            vm.afterAddCategory = true;
            if(!vm.addFlag){
                editCategory(title);
            }
            else{
                vm.afterAddCategory = true;
                taskService.addCategory(title)
                    .then(res => {
                        let category = res.data.data;
                        categoryId = category.id;
                        // vm.category = '';
                    })
                    .finally(()=>{
                        vm.isEditing = false;
                        vm.afterAddCategory = true;
                        vm.addFlag = false;
                        vm.isClose = false;

                    })
            }
        }

        function editTodo($todoIndex, todo){

            // vm.todos[$categoryIndex]['todo'][$listIndex].title = todo;
            let todoId = vm.todos[$todoIndex].id;
            taskService.editTodo(todoId, categoryId, todo)
                .then(res => {
                    vm.todos[$todoIndex].title = todo;
                    vm.editTodoIndex = -1;
                })
            // vm.todos[$categoryIndex]['todo'].push({'title':todo});
            // toggleEditTodoForm();
        }

        function addTodo(todo){
            if(!todo){
                return;
            }

            vm.newList = '';
            taskService.addTodo(todo, categoryId)
                .then(res => {
                    let todo = res.data.data;

                    vm.todos.push(todo);
                })
                .finally(()=>{
                    vm.taskTitle = '';
                })
        }

        function deleteCategory($index){
            let confirmData = {
                message: dashboardConstants.messages.category.deleteCategoryConfirmation,
                modalSize: 'md',
                type: 'danger'
            };
            commonService.confirm(confirmData)
                .then(function (confirmation) {
                    if (confirmation.result) {
                        taskService.deleteCategory(vm.todos[$index].id)
                            .then(res => {
                                // console.log("Resi----",res);
                            })
                            .finally(()=>{
                                vm.todos.splice($index,1);
                            })
                    }
                })
        }

        function deleteTodo($todoIndex, todoId){
            let confirmData = {
                message: dashboardConstants.messages.todo.deleteToDoConfirmation,
                modalSize: 'md',
                type: 'danger'
            };
            commonService.confirm(confirmData)
                .then(function (confirmation) {
                    if (confirmation.result) {
                        taskService.deleteTodo(todoId)
                            .then(res => {
                                // console.log("Resi----",res);
                            })
                            .finally(()=>{
                                vm.todos.splice($todoIndex,1);
                            })
                    }
                })
        }


        function loadMoreChat() {
            vm.chatLimit += 20;
        }

        function isTouched(field, form) {
            if (field && field.$viewValue && !field.$pristine) {
                if (form) {
                    form.$setUntouched();
                    form.$setPristine();
                }
                return true;
            }
        }

        function getTasks() {
            taskQueryParams.categoryId = categoryId;
            taskService.getCategory(taskQueryParams)
                .then((response) => {
                    _.forEach(response.data.data[0].todo, todo => {
                        vm.todos.push(todo);
                    })
                    // console.log("vm,0----",vm.todos);
                });
        }

        function onCategoryNotFound() {
            $timeout(close, 0);
            $state.go('404');
        }

        function init() {
            vm.model = categoryData.model;
            vm.isEditing = false;
            // console.log("iddddd---",categoryData);
            if(vm.model == 'todo'){
                categoryId = commonService.convertToInteger(categoryData.categoryId);
                vm.todos = categoryData.todos;
                vm.category = _.clone(categoryData.category);
                vm.afterAddCategory = true;
            }
            else {
                vm.isEditing = true;
                vm.addFlag = true;
            }
            // if (!categoryId) {
            // 	return onCategoryNotFound();
            // }
        }

        this.$onInit = init;
    }
export default todoController;
