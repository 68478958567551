let angular = window.angular;

userService.$inject = [
  "$http",
  "$q",
  "$rootScope",
  "Store",
  "webSocketService",
  "$uibModal",
  "$cookies",
  "moment",
  "toastService",
  "$timeout",
  "appConstants",
];
function userService(
  $http,
  $q,
  $rootScope,
  Store,
  webSocketService,
  $uibModal,
  $cookies,
  moment,
  toastService,
  $timeout,
  appConstants
) {
  let basePath = appConstants.baseUrl + "/api/users/";
  let calendarBasePath = appConstants.CalendarBaseUrl + "/api/users/";

  let locationPath = appConstants.baseUrl + "/api/locations";
  let userAdmin = false;

  angular.extend(this, {
    loginUser,
    changePassword,
    view,
    editProfile,
    updateUserStatus,
    onUserStatusUpdate,
    getStateList,
    getCityList,
    inviteFriends,
    logout,
    checkUserLogin,
    showAgreeTerms,
    agreeTerms,
    shareHSP,
    verifyAdmin,
    getAllUsers,
    deleteUser,
    verifyUser,
    search,
    searchByName,
    searchByNameV2,
    getWeather,
    setCalenderSettings,
    googleCalender,
    googleEmailUpdate,
    updateGoogleEvents,
    viewUserProfile,
    startGoogleSync,
    verifyPassword,
    addReferrals,
    getReferrals,
    getUserPaymentsService,
    updateExpense,
    addAcademicYear,
    getAcademicYearData,
    deleteAcademicYear,
    getAllReportedPosts,
    updatePostStatus,
    getAllReportedUsers,
    getAllAffiliates,
    approveAffiliate,
    getDailyMetricsbyMonth,
    getConfirmedCancellations,
    getPendingCancellations,
    cancelUserSubscription,
    confirmUserCancellation,
    getFreeMonthRequests,
    updateUserReportStatus,
    getUserCalendarSettings,
    setCalendarView,
    updateWidget,
    updateWidgetPosition,
    setCalendarAssignmentsStatus,
    getDragOrder,
  });

  function loginUser() {
    let deferred = $q.defer(),
      widgetPromise;

    widgetPromise = $http({
      method: "POST",
      url: appConstants.baseUrl + "/api/users/login",
      data: {
        type: "password",
        user: {
          email: "qali@gtek.solutions",
          password: "harry1234",
        },
      },
    });

    widgetPromise
      .then((response) => {
        console.log("response login", response);
        localStorage.setItem(
          "authorizationToken",
          response.data.data.authToken
        );
        localStorage.setItem(
          "userData",
          JSON.stringify(response.data.data.User)
        );
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function updateWidget(template, action) {
    let deferred = $q.defer(),
      widgetPromise,
      widgetData = {
        template: template,
        action: action,
      };

    widgetPromise = $http({
      method: "POST",
      url: basePath + "updateWidget",
      data: { widget: widgetData },
    });

    widgetPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }
  function updateWidgetPosition(cards) {
    let deferred = $q.defer(),
      widgetPromise;

    widgetPromise = $http({
      method: "POST",
      url: basePath + "updateWidgetPosition",
      data: { widget: { cards: cards } },
    });

    widgetPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function addReferrals(searchData) {
    let deferred = $q.defer(),
      searchPromise;

    searchPromise = $http({
      method: "POST",
      url: basePath + "addReferral",
      data: { referral: searchData },
    });

    searchPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function addAcademicYear(academicData) {
    let deferred = $q.defer(),
      academicPromise;

    academicPromise = $http({
      method: "POST",
      url: basePath + "addAcademicData",
      data: { academicData: academicData },
    });

    academicPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function deleteAcademicYear(academicId) {
    let url = basePath + "deleteAcademics";
    let data = {
      academicId: academicId,
    };
    return $http.post(url, data);
  }

  function getAcademicYearData() {
    let deferred = $q.defer(),
      academicPromise;

    academicPromise = $http({
      method: "GET",
      url: basePath + "getAcademicData",
    });

    academicPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function getReferrals() {
    let deferred = $q.defer(),
      searchPromise;

    searchPromise = $http({
      method: "GET",
      url: basePath + "getReferrals",
    });

    searchPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function search(searchData, page) {
    let deferred = $q.defer(),
      searchPromise;

    searchPromise = $http({
      method: "POST",
      url: appConstants.baseUrl + "/api/admins/searchUser",
      data: { searchQuery: searchData, page: page },
    });

    searchPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function searchByName(searchText) {
    let searchPromise;
    searchPromise = $http({
      method: "GET",
      url: basePath + "searchByName",
      params: {
        q: searchText,
      },
    });
    return searchPromise;
  }
  function searchByNameV2(searchText, groupId, type) {
    let searchPromise;
    searchPromise = $http({
      method: "GET",
      url: basePath + "searchByName",
      params: {
        q: searchText,
        groupId,
        type,
      },
    });
    return searchPromise;
  }

  function deleteUser(userId, updateEmail) {
    let deferred = $q.defer(),
      deleteUserPromise;

    deleteUserPromise = $http({
      method: "POST",
      url: appConstants.baseUrl + "/api/admins/deleteUser",
      data: { id: userId, emailToUpdate: updateEmail },
    });

    deleteUserPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function verifyUser(userId) {
    let deferred = $q.defer(),
      verifyUserPromise;

    verifyUserPromise = $http({
      method: "POST",
      url: appConstants.baseUrl + "/api/admins/verifyUser",
      data: { id: userId },
    });

    verifyUserPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function getAllUsers(page) {
    let deferred = $q.defer(),
      getAllUsersPromise,
      queryParams;

    queryParams = {
      page: page,
    };

    getAllUsersPromise = $http({
      method: "GET",
      url: appConstants.baseUrl + "/api/admins/getAllUsers",
      params: queryParams,
    });

    getAllUsersPromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function getDailyMetricsbyMonth(month) {
    let deferred = $q.defer(),
      dailyMetricsPromise;

    dailyMetricsPromise = $http({
      method: "POST",
      url: appConstants.baseUrl + "/api/admins/getDailyMetricsbyMonth",
      data: { month: month },
    });

    dailyMetricsPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function verifyAdmin(userId) {
    let deferred = $q.defer(),
      verifyAdminPromise;

    verifyAdminPromise = $http.get(
      appConstants.baseUrl + "/api/admins/verify/" + userId
    );

    verifyAdminPromise
      .then((response) => {
        if (response.data.data) {
          userAdmin = response.data.data.isAdmin || false;
        }
        deferred.resolve(response.data.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function shareHSP(type1) {
    $http.post(basePath + "shareSocial/", { type: type1 });
  }

  function checkUserLogin() {
    return Store.get("userData") && Store.get("authorizationToken");
  }

  function onUserStatusUpdate(status) {
    // let tempRootScope = { ...$rootScope };
    // tempRootScope.user.status = status;
    $rootScope = {
      ...$rootScope,
      user: {
        ...$rootScope.user,
        status,
      },
    };
    // $rootScope = tempRootScope;
    Store.set("userData", JSON.stringify($rootScope.user));
  }

  function logout() {
    return $http
      .post(appConstants.baseUrl + "/api/users/logout", {
        subscriptionToken: Store.get("subscriptionToken"),
      })
      .then(clearStore)
      .catch(clearStore);
  }

  function clearStore() {
    Store.clearAll();
    var domain_name = window.location.hostname.split(".").slice(-2).join(".");
    //Clear cookies value
    $cookies.remove("paypal");
  }

  function updateUserStatus(status) {
    let deferred = $q.defer(),
      payload = {
        status: status,
      };

    let url = "/api/chats/updateUserStatus";
    const headers = {
      Accept: "application/json",
      "X-Access-Token": window.Store.get("authorizationToken"),
    };

    webSocketService.socket.request(
      { url: url, headers: headers, method: "post", data: payload },
      (response, jwres) => {
        if (jwres.error) {
          return deferred.reject(response);
        }
        onUserStatusUpdate(response.data.status);
        deferred.resolve(response.data);
      }
    );
    return deferred.promise;
  }

  function view(userId) {
    let deferred = $q.defer(),
      viewProfilePromise;
    viewProfilePromise = $http.get(basePath + "view/" + userId);
    viewProfilePromise
      .then((response) => {
        deferred.resolve(response.data.data.User);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });
    return deferred.promise;
  }

  function editProfile(user) {
    let deferred = $q.defer(),
      editProfilePromise;

    editProfilePromise = $http.post(basePath + "edit/" + user.id, { user });

    editProfilePromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function changePassword(data) {
    let deferred = $q.defer(data),
      changePasswordPromise;

    changePasswordPromise = $http.post(basePath + "changePassword", data);

    changePasswordPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function verifyPassword(data) {
    let deferred = $q.defer(data),
      verifyPasswordPromise;

    verifyPasswordPromise = $http.post(basePath + "verifyPassword", data);

    verifyPasswordPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((response) => {
        deferred.reject(response.data);
      });

    return deferred.promise;
  }

  function getStateList(country) {
    let deferred = $q.defer(country),
      getStateListPromise;

    getStateListPromise = $http.get(locationPath, {
      params: { country: country },
    });

    getStateListPromise
      .then((response) => {
        deferred.resolve(response.data.data.places);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function getCityList(country, state) {
    let deferred = $q.defer(state),
      getCityListPromise;

    getCityListPromise = $http.get(locationPath, {
      params: {
        country: country,
        type: "city",
        state: state,
      },
    });

    getCityListPromise
      .then((response) => {
        deferred.resolve(response.data.data.places);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function showAgreeTerms() {
    $uibModal.open({
      animation: true,
      size: "lg",
      backdrop: false,
      controller: "agreeTermsController",
      controllerAs: "agreeTermsCrtl",
      template: require("../../pug/user/agree-terms.pug").default,
    });
  }

  function agreeTerms() {
    let deferred = $q.defer();

    $http
      .get(basePath + "agreeTerms")
      .then((response) => {
        deferred.resolve(response);
      })
      .catch((err) => {
        deferred.reject(err);
      });
    return deferred.promise;
  }

  function inviteFriends(mail, message, referral = false) {
    let deferred = $q.defer(),
      data = {},
      inviteFriendsPromise;

    data.invites = mail;
    data.inviteMessage = message;
    inviteFriendsPromise = $http.post(
      basePath + "invite/?referral=" + referral,
      data
    );
    inviteFriendsPromise
      .then((response) => {
        deferred.resolve(response.data.message);
      })
      .catch((err) => {
        deferred.reject(err);
      });
    return deferred.promise;
  }

  function displayLocation(latitude, longitude, cb) {
    var geocoder;
    geocoder = new google.maps.Geocoder();
    var latlng = new google.maps.LatLng(latitude, longitude);

    let value = geocoder.geocode(
      { latLng: latlng },
      function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            var add = results[0].formatted_address;
            var value = add.split(",");
            let count = 0;
            count = value.length;
            // country=value[count-1];
            // state=value[count-2];
            var city = value[count - 3];
            // x.innerHTML = "city name is: " + city;
            // return city;
            cb(city);
          } else {
            // return null
            cb(null);
          }
        } else {
          cb(null);
        }
      }
    );
  }

  function getWeather() {
    let deferred = $q.defer(),
      getWeatherPromise;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          displayLocation(
            position.coords.latitude,
            position.coords.longitude,
            (city) => {
              getWeatherPromise = $http.get(
                "https://api.openweathermap.org/data/2.5/weather?&APPID=c77191af8c87d32394dee712377f6c18&lat=" +
                  position.coords.latitude +
                  "&lon=" +
                  position.coords.longitude,
                {
                  headers: {
                    "X-Access-Token": undefined,
                  },
                }
              );

              getWeatherPromise
                .then((response) => {
                  response.data.name = city || response.data.name;
                  deferred.resolve(response.data);
                })
                .catch((err) => {
                  deferred.reject(err);
                });
            }
          );
        },
        function () {}
      );
    }

    return deferred.promise;
  }

  function setCalenderSettings(data) {
    let deferred = $q.defer(),
      getCalenderPromise;
    getCalenderPromise = $http.post(calendarBasePath + "setCalenderSettings", data);
    getCalenderPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });
    return deferred.promise;
  }
  function googleCalender() {
    let deferred = $q.defer(),
      getCalenderPromise;
    getCalenderPromise = $http.get(basePath + "googleCalender");
    getCalenderPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });
    return deferred.promise;
  }

  function googleEmailUpdate(data) {
    let deferred = $q.defer(),
      getCalenderPromise;
    getCalenderPromise = $http.post(basePath + "updateGmailAccount", data);
    getCalenderPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });
    return deferred.promise;
  }
  function updateGoogleEvents(data) {
    let deferred = $q.defer(),
      getCalenderPromise;
    getCalenderPromise = $http.post(basePath + "updateFutureEvents", data);
    getCalenderPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });
    return deferred.promise;
  }
  function viewUserProfile() {
    let deferred = $q.defer(),
      getCalenderPromise;
    getCalenderPromise = $http.get(basePath + "getUserProfile");
    getCalenderPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });
    return deferred.promise;
  }

  function startGoogleSync() {
    var timeZone = moment.tz.guess();
    let deferred = $q.defer(),
      getCalenderPromise;
    getCalenderPromise = $http({
      method: "GET",
      url: basePath + "googleSync",
      params: { timezone: timeZone },
    });
    getCalenderPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });
    return deferred.promise;
  }

  function updateExpense(data) {
    let deferred = $q.defer(),
      getExpensePromise;
    getExpensePromise = $http.post(
      appConstants.baseUrl + "/api/admins/updateExpense",
      {
        expense: data,
      }
    );
    getExpensePromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });
    return deferred.promise;
  }

  function getUserPaymentsService() {
    let deferred = $q.defer(),
      getPaymentsPromise;
    getPaymentsPromise = $http.get(basePath + "getPaymentHistory");
    getPaymentsPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });
    return deferred.promise;
  }

  function getAllReportedPosts(userId) {
    let deferred = $q.defer(),
      verifyUserPromise;

    verifyUserPromise = $http({
      method: "get",
      url: appConstants.baseUrl + "/api/admins/getAllReportedPosts/" + userId,
    });

    verifyUserPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }
  function updatePostStatus(data) {
    let deferred = $q.defer(),
      verifyUserPromise;

    verifyUserPromise = $http({
      method: "post",
      url: appConstants.baseUrl + "/api/admins/updatePostStatus",
      data: data,
    });

    verifyUserPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }
  function updateUserReportStatus(data) {
    let deferred = $q.defer(),
      verifyUserPromise;

    verifyUserPromise = $http({
      method: "post",
      url: appConstants.baseUrl + "/api/admins/updateUserReportStatus",
      data: data,
    });

    verifyUserPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }
  function getAllReportedUsers(userId) {
    let deferred = $q.defer(),
      verifyUserPromise;

    verifyUserPromise = $http({
      method: "get",
      url: appConstants.baseUrl + "/api/admins/getAllReportedUsers/" + userId,
    });

    verifyUserPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function getAllAffiliates() {
    let deferred = $q.defer(),
      getAllAffiliatesPromise;

    getAllAffiliatesPromise = $http({
      method: "GET",
      url: appConstants.baseUrl + "/api/admins/getAllAffiliates",
    });

    getAllAffiliatesPromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function approveAffiliate(userId, adminAction) {
    let deferred = $q.defer(),
      approveAffiliatePromise;

    approveAffiliatePromise = $http({
      method: "POST",
      url: appConstants.baseUrl + "/api/admins/approveAffiliate",
      data: { id: userId, action: adminAction },
    });

    approveAffiliatePromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function getConfirmedCancellations(page) {
    let deferred = $q.defer(),
      getAllCancellationsPromise,
      queryParams;

    queryParams = {
      page: page,
    };

    getAllCancellationsPromise = $http({
      method: "GET",
      url: appConstants.baseUrl + "/api/admins/getConfirmedCancellations",
      params: queryParams,
    });

    getAllCancellationsPromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }
  function getUserCalendarSettings(userId) {
    let deferred = $q.defer(),
      userCalendarSettingPromise;

    userCalendarSettingPromise = $http({
      method: "get",
      url: basePath + "getUserCalendarSettings/" + userId,
    });

    userCalendarSettingPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }
  function setCalendarView(userId, view) {
    let deferred = $q.defer(),
      userCalendarSettingPromise;

    userCalendarSettingPromise = $http({
      method: "post",
      url: calendarBasePath + "setCalendarView/" + userId,
      data: { user: { calendarView: view } },
    });

    userCalendarSettingPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function getPendingCancellations(page) {
    let deferred = $q.defer(),
      getAllCancellationsPromise,
      queryParams;

    queryParams = {
      page: page,
    };

    getAllCancellationsPromise = $http({
      method: "GET",
      url: appConstants.baseUrl + "/api/admins/getPendingCancellations",
      params: queryParams,
    });

    getAllCancellationsPromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function cancelUserSubscription(data) {
    let deferred = $q.defer(),
      cancelSubscriptionPromise;

    cancelSubscriptionPromise = $http({
      method: "POST",
      url: appConstants.baseUrl + "/api/users/cancelSubscription",
      data: data,
    });

    cancelSubscriptionPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function confirmUserCancellation(user, adminAction, type) {
    let deferred = $q.defer(),
      confirmCancellationPromise;

    confirmCancellationPromise = $http({
      method: "POST",
      url: appConstants.baseUrl + "/api/admins/confirmUserCancellation",
      data: { user: user, action: adminAction, requestType: type },
    });

    confirmCancellationPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function getFreeMonthRequests(page) {
    let deferred = $q.defer(),
      getRequestsPromise,
      queryParams;

    queryParams = {
      page: page,
    };

    getRequestsPromise = $http({
      method: "GET",
      url: appConstants.baseUrl + "/api/admins/getfreeMonthRequests",
      params: queryParams,
    });

    getRequestsPromise
      .then((response) => {
        deferred.resolve(response.data.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }

  function setCalendarAssignmentsStatus(status) {
    let deferred = $q.defer(),
      userCalendarSettingPromise;

    userCalendarSettingPromise = $http({
      method: "post",
      url: calendarBasePath + "setCalendarAssignmentsStatus/",
      data: { status: status },
    });

    userCalendarSettingPromise
      .then((response) => {
        deferred.resolve(response.data);
      })
      .catch((err) => {
        deferred.reject(err);
      });

    return deferred.promise;
  }
  function getDragOrder() {
    let defer = $q.defer();

    $http({
      method: "get",
      url: basePath + "getDragOrder/",
    })
      .then(function (response) {
        defer.resolve(response);
      })
      .catch(function (err) {
        defer.reject(err);
      });
    return defer.promise;
  }
}

export default userService;
