let angular = window.angular;
import studentService from "./studentService";
import studentViewController from './studentViewController';
import userViewController from './userViewController';
import studentAddController from './studentAddController';
import studentEditController from './studentEditController'
import helperAddController from './helperAddController';
import helperEditController from './helperEditController';
import { studentConstants } from './studentConstants';

export const STUDENT_MODULE = angular.module('student', []);
STUDENT_MODULE.service("studentService", studentService);
STUDENT_MODULE.controller("studentViewController", studentViewController);
STUDENT_MODULE.constant("studentConstants", studentConstants);
STUDENT_MODULE.controller("userViewController", userViewController);
STUDENT_MODULE.controller("studentAddController", studentAddController);
STUDENT_MODULE.controller("studentEditController", studentEditController);
STUDENT_MODULE.controller("helperAddController", helperAddController);
STUDENT_MODULE.controller("helperEditController", helperEditController);


