// ((angular) => {
//     'use strict';

//     angular
//         .module('common')
//         .directive('hspFileUpload', hspFileUpload)
//         .controller('FileUploadController', FileUploadController);
let angular = window.angular;

//hspFileUpload.$inject = [];

export const hspFileUpload = () => {
  let directive = {
    restrict: "E",
    template: require("../../pug/components/file-upload.pug").default,
    scope: {
      canChange: "@",
      canRemove: "@",
      fileToUpload: "=",
      onFileUpload: "&",
      maxUploadSize: "@",
      model: "@",
      fileUploadError: "&",
      fileUploadSuccess: "&",
    },
    controller: "FileUploadController",
    controllerAs: "fileUploadCtrl",
    transclude: {
      uploadTemplate: "uploadTemplate",
      changeTemplate: "?changeTemplate",
      removeTemplate: "?removeTemplate",
    },
  };
  return directive;
};

export function FileUploadController(
  $scope,
  _,
  toastService,
  appConstants,
  fileService
) {
  let vm = this;

  vm.allowedMimeTypes = {
    user: [
      "image/gif",
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/bmp",
      "image/pjpeg",
      "image/vnd.microsoft.icon",
      "image/x-icon",
    ],
    student: [
      "image/gif",
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/bmp",
      "image/pjpeg",
      "image/vnd.microsoft.icon",
      "image/x-icon",
    ],
    book: [
      "image/gif",
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/bmp",
      "image/pjpeg",
      "image/vnd.microsoft.icon",
      "image/x-icon",
    ],
    transaction: [
      "image/gif",
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/bmp",
      "image/pjpeg",
      "image/vnd.microsoft.icon",
      "image/x-icon",
    ],
    studentassignment: "*",
  };

  vm.fileCanBeRemoved = false;
  vm.fileCanBeChanged = false;
  vm.maxUploadSize = $scope.maxUploadSize || appConstants.maxUploadSize;

  checkStates();

  angular.extend(vm, {
    selectFile,
    removeFile,
  });

  $scope.$on("fileUploadComplete", removeFile);

  function checkStates() {
    vm.fileSelected = _.isEmpty($scope.fileToUpload) ? false : true;
    if (vm.fileSelected) {
      if ($scope.canChange) {
        vm.fileCanBeChanged = true;
      } else if ($scope.canRemove) {
        vm.fileCanBeRemoved = true;
      }
    }
  }

  function selectFile(file, invalidFiles) {
    if (invalidFiles.length) {
      let message = appConstants.messages.selectValidFile;
      if (
        invalidFiles[0].$error === "minWidth" ||
        invalidFiles[0].$error === "minHeight"
      ) {
        message = appConstants.messages.errorFileDimension;
      }
      if (invalidFiles[0].$error === "maxSize") {
        message = appConstants.messages.errorFileSize;
      }
      toastService.toast({
        message: message,
        type: "error",
        delay: 8000,
      });
      return;
    }

    if (!vm.allowedMimeTypes.user.includes(file.type)) {
      $scope.fileUploadError();
      // toastService.toast({
      //     message: appConstants.messages.enterValidFile,
      //     type: 'error',
      //     delay: 8000
      // });
      return removeFile();
    }

    if (file) {
      $scope.fileUploadSuccess();

      $scope.fileToUpload = file;
      vm.fileSelected = true;
      if ($scope.onFileUpload instanceof Function) {
        $scope.onFileUpload({ media: file });
      }

      if ($scope.canChange) {
        vm.fileCanBeChanged = true;
      } else if ($scope.canRemove) {
        vm.fileCanBeRemoved = true;
      }
    }
  }

  function removeFile() {
    vm.fileSelected = false;
    if ($scope.canChange) {
      vm.fileCanBeChanged = false;
    } else if ($scope.canRemove) {
      vm.fileCanBeRemoved = false;
    }

    if (!$scope.fileToUpload || !$scope.fileToUpload.hash) {
      return;
    }

    return fileService
      .removeFile($scope.fileToUpload.hash)
      .then(fileRemoveSuccess)
      .catch(fileRemoveFailure);
  }

  function fileRemoveSuccess(response) {
    $scope.fileSelected = {};
  }

  function fileRemoveFailure(error) {
    // console.log('fileRemoveFailure', error);
  }
}
FileUploadController.$inject = [
  "$scope",
  "_",
  "toastService",
  "appConstants",
  "fileService",
];
