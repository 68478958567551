let angular = window.angular;
helperAddController.$inject = ['$rootScope', '$scope', 'commonService', '$state', 'studentService', 'fileService', '$q', 'moment', 'toastService', 'appConstants', 'studentConstants'];

function helperAddController($rootScope, $scope, commonService, $state, studentService, fileService, $q, moment, toastService, appConstants, studentConstants) {

    let vm = this;
    let sortedAcademicYearArray = [];
    vm.student = {
        countryCode: 1,
        parentCountryCode: 1,
        academicDays: 180
    };
    vm.addStudentId;
    vm.defaultImage = appConstants.defaultAvatar;
    vm.colors = appConstants.colors;
    vm.submitClicked = false;
    vm.student.academics = [{
        'academicYearStart': '',
        'academicYearEnd': '',
        'grade': '',
        'academicDays': '',
        //'goals': '',
        //'reflections': ''
    }];
    let newStudent = {};

    vm.addAcademicDetails = function () {
        vm.student.academics.push({
            'academicYearStart': '',
            'academicYearEnd': '',
            'grade': '',
            'academicDays': '',
            //'goals': '',
            //'reflections': ''
        });
    }

    vm.checkMultigrade = function ($index) {
        let multiGrade = false;
        vm.gradeCheck = {};
        for (let i = 0; i < vm.student.academics.length; i++) {
            let grd = vm.student.academics[i].grade;
            if (grd && grd in vm.gradeCheck) {
                multiGrade = true;
                break;
            } else {
                if (grd !== 'Grade' || grd || grd !== null) {
                    vm.gradeCheck[grd] = i;
                }
            }
        }
        if (multiGrade) {
            vm.student.academics[$index].invalidGrade = true;
            toastService.toast({
                message: studentConstants.messages.multiGradeError,
                type: 'error',
                delay: 2000
            });
        } else {
            vm.student.academics[$index].invalidGrade = false;
        }
        return;
    }

    vm.removeAcademicDetails = function ($index) {
        let index = commonService.convertToInteger($index);
        if (index < 0) {
            return false;
        }
        if (vm.student.academics.length <= 1) {
            toastService.toast({
                message: studentConstants.messages.academicYearRequire,
                type: 'error',
                delay: 2000
            });
            return;
        }

        let confirmData = {
            message: studentConstants.messages.deleteAcademicDetailsWarning,
            modalSize: 'md',
            type: 'danger'
        };

        commonService.confirm(confirmData)
            .then(function (confirmation) {
                if (confirmation.result) {
                    vm.student.academics.splice(index, 1);
                }
            });
    }

    vm.checkAcademicYearInteraction = function ($index) {
        sortedAcademicYearArray = commonService.sortDateByStartDate(vm.student.academics);

        let isIntersected = false,
            exitFunc = false;
        for (let i = 1; i < sortedAcademicYearArray.length; i++) {
            let currentStartDate = sortedAcademicYearArray[i].academicYearStart;
            let previousEndDate = sortedAcademicYearArray[i - 1].academicYearEnd;
            // if(!currentStartDate || !previousEndDate){
            //     return;
            // }
            if (currentStartDate < previousEndDate) {
                isIntersected = true;
                break;
            }
        }

        if (isIntersected) {
            vm.student.academics[$index].invalid = true;
            toastService.toast({
                message: studentConstants.messages.academicYearIntersection,
                type: 'error',
                delay: 2000
            });
        } else {
            vm.student.academics[$index].invalid = false;
        }

        return;
    }
    vm.addHelper = function (student) {
        let exitFunc = false,
            multiGrade = false,
            dobCheck = false;
        /**
         * Validate Student info
         */
        if (!vm.student.firstName || !vm.student.lastName || !vm.student.email || !vm.student.password || !vm.student.relation) {
            vm.show = true;
            exitFunc = true;
            return;
        }
        if (!vm.student.dob === "" && (exitFunc || !vm.form.$valid)) {
            return;
        }

        $rootScope.loadingBtn('save', 'loading');
        vm.submitClicked = true;

        let data = {};
        data = _.clone(vm.student);
        studentService.addUser(data)
            .then(addStudentSuccess)
            .then(fileUploadResponse)
            .catch(function (error) {
                console.error(error);
                toastService.toast({
                    message: error.data.message || 'Please fill the form and try again',
                    type: 'error',
                    delay: 2000
                });
                $rootScope.loadingBtnComplete('save');
            })
            .finally(() => {
                $rootScope.loadingBtnComplete('save', 'Save');
            });
    };

    vm.setStudentLabelColor = function (color) {
        vm.student.color = color;
    };

    vm.selectStudentPic = function (media) {
        return fileService.imageUploadWithCrop(media)
            .then(selectStudentPicSuccess)
            .catch(selectStudentPicError);

        function selectStudentPicSuccess(response) {
            if (response.file) {
                vm.studentImageFile = response.file;
            }
        }

        function selectStudentPicError(error) {
            // console.log('Closed modal', error);
        }
    };

    function addStudentSuccess(response) {
        let deferred = $q.defer();

        studentService.students.push(response.data.data.Student);
        newStudent = response.data.data.Student;
        vm.addStudentId = newStudent.id;
        // Upload Image, if selected by User
        if (_.isObject(vm.studentImageFile) && _.isString(vm.studentImageFile.name) && (vm.studentImageFile.size > 0)) {
            $rootScope.loadingBtn('save', 'Uploading Profile Picture');
            return fileService.uploadFile(vm.studentImageFile, newStudent.id, 'user');
        } else {
            deferred.resolve();
        }

        return deferred.promise;
    }

    function fileUploadResponse(response) {
        updatemediaUrl(response);
        $state.go('userInfo', { studentId: newStudent.id });
    }

    function updatemediaUrl(response) {
        let index = studentService.students.map(student => student.id).indexOf(parseInt(vm.addStudentId));
        if (index && index >= 0) {
            studentService.students[index].name = studentService.students[index].firstName + " " + studentService.students[index].lastName;
            if (response) {
                studentService.students[index].mediaUrl = response.data.data[0].mediaUrl;
            } else {
                studentService.students[index].mediaUrl = undefined;
            }
        }
    }

    vm.permissionChange = function (type, action) {
        if (vm.student.permission[type].edit && !vm.student.permission[type].view && action === 'edit')
            vm.student.permission[type].view = true;
        else if (vm.student.permission[type].edit && !vm.student.permission[type].view && action === 'view')
            vm.student.permission[type].edit = false;
    }

    /**
     * If a state change is detected, when the task controller is active, then it is checked whether the modal window is open, if yes then it is closed
     */
    $scope.$on("$stateChangeStart", function (evt, toState, toParams, fromState, fromParams) {
        // console.log("args",arguments);
        let isFillingForm = false;

        // Check if the form has some data
        for (let i in vm.student) {
            if (vm.student[i] && vm.form[i] && !vm.form[i].$pristine) {
                isFillingForm = true;
                break;
            }
        }

        if (isFillingForm && !vm.submitClicked) {

            evt.preventDefault();
            $rootScope.isLoading = false;
            let confirmData = {
                message: studentConstants.messages.leavePageConfirmation,
                modalSize: 'md',
                type: 'danger'
            };

            commonService.confirm(confirmData)
                .then(function (confirmation) {
                    if (confirmation.result) {
                        vm.submitClicked = true;
                        $state.go(toState, toParams);
                    }
                });
        }
    });

}
export default helperAddController;
