let angular = window.angular;

collaborateService.$inject = ['$rootScope', '_', '$q', 'webSocketService', 'commonService', '$timeout', '$state', 'orderByFilter', '$stateParams', '$document', 'Store', '$http','appConstants'];

function collaborateService($rootScope, _, $q, webSocketService, commonService, $timeout, $state, orderBy, $stateParams, $document, Store, $http,appConstants) {

    let chatFactory = {
        chat: {},
        chatMessageLimit: 50,
        onlineUsers: [],
        unreadChats: []
    };

    const chatSound = angular.element(document.querySelector('#chatSound'))[0];
    const basePath = '/api/chats/';

    const webSocket = webSocketService.socket;

    chatFactory.chatsList = [];
    chatFactory.sideMenuChatId = 1234;

    angular.extend(chatFactory, {
        typing,
        toggleChatBox,
        createChat,
        createMessage,
        deleteMessage,
        setChatSeen,
        getMessages,
        viewChat,
        findChatById,
        ifUserHasChat,
        removeVisitedFlag,
        removeUnreadCount,
        saveChat,
        removeChat,
        getUnreadChatsCount,
        convertForMediaMessage,
        orderChat,
        updateGroupChatName,
        deleteChat,
        updateContactChat,
        updateContactChatAgain,
        deleteChatConversation,
        unfriendUser,
        unfriendUserConversation,
        changeRole,
        removeUser,
        friendSearch,
        addGroupMember,
        exitUser,
        updateGroupMedia,
        followUserFromConversation,
    });

    function updateGroupChatName(chat) {
        let deferred = $q.defer();
        let url = basePath + 'changeChatName';
        let data = { chat: chat };

        const headers = {
            'Accept': 'application/json',
            'X-Access-Token': window.Store.get('authorizationToken')
        };

        webSocketService.socket.request({ url, headers, method: 'post', data }, (response, jwres) => {
            if (jwres.error) {
                return deferred.reject(response);
            }

            let index = findChatById(response.data.id);
            if (angular.isDefined(chatFactory.chatsList) && angular.isDefined(chatFactory.chatsList[index])) {
                chatFactory.chatsList[index].name = response.data.name;
            }

            deferred.resolve(response.data);
        });

        return deferred.promise;
    }

    function updateGroupMedia(chatId, mediaUrl) {
        let index = findChatById(response.data.id);
        if (angular.isDefined(chatFactory.chatsList) && angular.isDefined(chatFactory.chatsList[index])) {
            chatFactory.chatsList[index].mediaUrl = mediaUrl;
        }
    }

    function typing(data) {
        let deferred = $q.defer();

        let url = basePath + 'typing';
        const headers = {
            'Accept': 'application/json',
            'X-Access-Token': window.Store.get('authorizationToken')
        };

        webSocketService.socket.request({ url, headers, method: 'post', data }, (response, jwres) => {
            if (jwres.error) {
                return deferred.reject(response);
            }

            deferred.resolve(response.data);
        });

        return deferred.promise;
    }

    function removeUnreadChatId(chatId) {
        let chatIndex = _.indexOf(chatFactory.unreadChats, chatId);
        if (chatIndex >= 0) {
            chatFactory.unreadChats.splice(chatIndex, 1);
        }
    }

    function addUnreadChatId(chatId) {
        let chatIndex = _.indexOf(chatFactory.unreadChats, chatId);
        if (chatIndex < 0) {
            chatFactory.unreadChats.push(chatId);
        }
    }

    function getUnreadChatsCount() {
        return chatFactory.unreadChats.length;
    }

    function findChatById(chatId) {
        return commonService.findInArray(chatId, 'id', chatFactory.chatsList);
    }

    function orderChat() {
        let chatsList;

        function sortFunction(chat) {

            //Checks for unread chats
            if (angular.isDefined(chat.unread) && chat.unread > 0) {
                addUnreadChatId(chat.id);
            }

            if (chat.isBlocked && $rootScope.user.id === chat.blockedBy) {
                return new Date();
            }

            if (chat.messages && chat.messages[0]) {
                return chat.messages[chat.messages.length - 1].createdAt;
            }
            if (chat.noRecord) {
                return false;
            }



            return chat.updatedAt;	// Returns start of epoch time
        }

        chatsList = orderBy(chatFactory.chatsList, sortFunction, true);
        //Since orderBy filter returns a copy of the array and assigning it directly doesnot updates all the referecne of the array.
        for (let i = chatsList.length - 1; ~i; i--) {
            chatFactory.chatsList[i] = chatsList[i];
        }
    }

    function saveChat(chat) {
        if (chat.id) {
            commonService.saveInArray(chat.id, 'id', chatFactory.chatsList, chat);

            orderChat();
        }
    }

    function removeChat(friendId, type = 'userId') {
        if (friendId) {
            let removeIndex = chatFactory.chatsList.map(function (chat) { return chat[type]; }).indexOf(friendId);
            chatFactory.chatsList.splice(removeIndex, 1);
            orderChat();
        }
    }

    function deleteChatConversation(chatId) {

        if (chatId) {
            let findIndex = chatFactory.chatsList.findIndex(obj => obj.id === chatId);
            if (findIndex !== -1) {
                chatFactory.chatsList[findIndex].chatStatus = false;
                chatFactory.chatsList[findIndex].messages = [];

            }

        }
    }

    function unfriendUserConversation(chatId) {
        if (chatId) {

            let chatIndex = _.findIndex(chatFactory.chatsList, { id: chatId });
            if (chatIndex < 0) {
                return;
            }
            chatFactory.chatsList.splice(chatIndex, 1);
        }
    }

    function followUserFromConversation(chatId, type) {
        if (chatId) {
            let chatIndex = _.findIndex(chatFactory.chatsList, { id: chatId });
            if (chatIndex < 0) {
                return;
            }
            chatFactory.chatsList[chatIndex].follow = type;
        }
    }

    function checkIfChatExists(userIds) {
        let i, length;
        userIds = _.clone(userIds);
        userIds.push($rootScope.user.id);
        userIds = _.chain(userIds).sortBy().sortedUniq().value();
        length = chatFactory.chatsList.length
        for (let i = 0; i < length; i++) {
            let chatUsers = _.chain(chatFactory.chatsList[i].userchats).map((userChat) => {
                return userChat.userId;
            }).sortBy().value();

            if (_.isEqual(chatUsers, userIds)) {
                return i;
            }
        }
        return -1;
    }

    function toggleChatBox(userIds, canSendMessage) {
        let index = checkIfChatExists(userIds);
        if (~index) {
            chatFactory.chatsList[index].canSendMessage = canSendMessage;
        }
    }

    function createChat(userIds, type, name) {

        let index, deferred = $q.defer(),
            payload = {
                type: type,
                userchats: userIds,
                name: name
            };
        // index = checkIfChatExists(userIds);
        // if (~index) {
        // 	deferred.resolve(chatFactory.chatsList[index]);
        // 	return deferred.promise;
        // }
        let url = basePath + 'add';
        webSocket.request({ url: url, headers: webSocketService.headers, method: 'post', data: payload }, (response, jwres) => {
            if (jwres.error) {
                return deferred.reject(response);
            }
            saveChat(response.data);
            deferred.resolve(response.data);
        });
        return deferred.promise;
    }
    function addGroupMember(member, chatId, oldMessagesView) {
        let index, deferred = $q.defer(),
            payload = {
                chatId: chatId,
                oldMessagesView: oldMessagesView
            };
        // index = checkIfChatExists(userIds);
        // if (~index) {
        // 	deferred.resolve(chatFactory.chatsList[index]);
        // 	return deferred.promise;
        // }
        let url = basePath + 'groupAddMember/' + member.id;
        webSocket.request({ url: url, headers: webSocketService.headers, method: 'post', data: payload }, (response, jwres) => {
            if (jwres.error) {
                return deferred.reject(response);
            }
            let chatId = response.data.chatId;
            if (chatId) {
                let chatIndex = chatFactory.chatsList.findIndex(obj => obj.id === chatId);
                if (chatIndex != -1) {
                    chatFactory.chatsList[chatIndex].userchats.push(response.data);
                    chatFactory.chatsList[chatIndex].users.push(member);
                }
            }
            deferred.resolve(response.data);
        });
        return deferred.promise;
    }
    function changeRole(chatId, role) {

        let index, deferred = $q.defer(),
            payload = {
                role: role,
            };
        let url = basePath + 'changeRole/' + chatId;
        webSocket.request({ url: url, headers: webSocketService.headers, method: 'post', data: payload }, (response, jwres) => {
            if (jwres.error) {
                return deferred.reject(response);
            }
            let chatId = response.data.chatId;
            if (chatId) {
                let chatIndex = chatFactory.chatsList.findIndex(obj => obj.id === chatId);
                if (chatIndex != -1) {
                    let userChatIndex = chatFactory.chatsList[chatIndex].userchats.findIndex(obj => obj.id === response.data.id);
                    if (userChatIndex != -1) {
                        chatFactory.chatsList[chatIndex].userchats[userChatIndex] = response.data;
                    }
                }
            }
            deferred.resolve(response.data);
        });
        return deferred.promise;
    }
    function removeUser(chatId, role) {

        let index, deferred = $q.defer(),
            payload = {
                role: role,
            };
        let url = basePath + 'removeUser/' + chatId;
        webSocket.request({ url: url, headers: webSocketService.headers, method: 'delete', data: payload }, (response, jwres) => {
            if (jwres.error) {
                return deferred.reject(response);
            }
            let chatId = response.data.chatId;
            if (chatId) {
                let chatIndex = chatFactory.chatsList.findIndex(obj => obj.id === chatId);
                if (chatIndex != -1) {
                    let userChatIndex = chatFactory.chatsList[chatIndex].userchats.findIndex(obj => obj.id === response.data.id);
                    if (userChatIndex != -1) {
                        // chatFactory.chatsList[chatIndex].userchats[userChatIndex]=response.data;
                        chatFactory.chatsList[chatIndex].userchats.splice(userChatIndex, 1);
                    }
                }
            }
            deferred.resolve(response.data);
        });
        return deferred.promise;
    }
    function exitUser(chatId, role) {

        let index, deferred = $q.defer(),
            payload = {
                role: role,
            };
        let url = basePath + 'removeUser/' + chatId + '?type=leave';
        webSocket.request({ url: url, headers: webSocketService.headers, method: 'delete', data: payload }, (response, jwres) => {
            if (jwres.error) {
                return deferred.reject(response);
            }
            let chatId = response.data.chatId;
            if (chatId) {
                let chatIndex = chatFactory.chatsList.findIndex(obj => obj.id === chatId);
                if (chatIndex != -1) {
                    chatFactory.chatsList.splice(chatIndex, 1);
                    // let userChatIndex = chatFactory.chatsList[chatIndex].userchats.findIndex(obj => obj.id === response.data.id);
                    // if(userChatIndex!=-1){
                    // 	// chatFactory.chatsList[chatIndex].userchats[userChatIndex]=response.data;
                    // 	chatFactory.chatsList[chatIndex].userchats.splice(userChatIndex,1);
                    // }
                }
            }
            deferred.resolve(response.data);
        });
        return deferred.promise;
    }

    function createMessage(message) {

        let url = '/api/messages/add';
        let deferred = $q.defer();
        webSocket.request({ url: url, headers: webSocketService.headers, method: 'post', data: message }, (response, jwres) => {
            if (jwres.error) {
                return deferred.reject(response);
            }
            orderChat();
            deferred.resolve(response);
        });
        return deferred.promise;
    }

    function deleteMessage(messageId, chatId) {
        let url = '/api/messages/delete/' + messageId + '?me=true&chatId=' + chatId;
        let deferred = $q.defer();
        webSocket.request({ url: url, headers: webSocketService.headers, method: 'delete' }, (response, jwres) => {
            if (jwres.error) {
                return deferred.reject(response);
            }
            orderChat();
            removeMessage(response.data.message);
            deferred.resolve(response);
        });
        return deferred.promise;
    }

    function removeMessage(message) {

        let chatIndex = _.findIndex(chatFactory.chatsList, { id: message.chatId });
        if (chatIndex < 0) {
            return;
        }

        let messageIndex = _.findIndex(chatFactory.chatsList[chatIndex].messages, { id: message.id });
        if (messageIndex < 0) {
            return;
        }

        chatFactory.chatsList[chatIndex].messages.splice(messageIndex, 1);

    }

    function subscribe() {
        let deferred = $q.defer();
        let url = basePath + 'subscribe';
        webSocket.request({ url: url, headers: webSocketService.headers, method: 'post', data: { 'clientType': 'BROWSER', 'deviceId': Store.get('subscriptionToken') } }, (response, jwres) => {
            if (jwres.error) {
                return deferred.reject(response);
            }
            $timeout(() => {
                chatFactory.onlineUsers = response.data.onlineUsers;
            }, 0);
            deferred.resolve(response);
        });
        return deferred.promise;
    }

    function setChatSeen(chatId) {
        removeUnreadChatId(chatId);
        let url = basePath + 'setChatSeen';
        let data = { chatId: chatId };
        webSocket.request({ url: url, headers: webSocketService.headers, method: 'post', data: data }, (response, jwres) => {
        });
    }


    function getAllChat() {

        let deferred = $q.defer();
        let url = basePath + 'getAllChatContacts?messageLimit=1';
        webSocket.request({ url: url, headers: webSocketService.headers, method: 'get' }, (response, jwres) => {

            if (jwres.error) {
                return deferred.reject(response);
            }
            chatFactory.chatsList = _.map(response.data, function (chat) {
                chat.typing = false;
                chat.selfTyping = false;
                chat.typedBy = 0;
                return chat;
            });
            orderChat();
            deferred.resolve(response);
        });
        $rootScope.chatIndexPromise = deferred.promise;
        return deferred.promise;
    }

    function getMessages(type, chatId, limit, skip) {
        let deferred = $q.defer();
        let url = '/api/messages?chatId=' + chatId;

        if (limit && (limit > 0)) {
            url = url + '&limit=' + limit;
        }

        if (skip && (skip >= 0)) {
            url = url + '&skip=' + skip;
        }

        webSocket.request({ url: url, headers: webSocketService.headers, method: 'get' }, (response, jwres) => {
            if (jwres.error) {
                return deferred.reject(response);
            }

            if (response.data) {
                if (response.data.length === 0) {
                    let data = {};
                    data.firstMessage = true;
                    return deferred.resolve(data);
                }
                if (response.data.length > 0) {
                    let length = response.data.length;
                    for (let i = 0; i < length; i++) {
                        addMessageToChat(chatId, response.data[i], true);
                    }
                    return deferred.resolve(true);
                }
            }
            return deferred.reject(response);
        });
        return deferred.promise;
    }

    function viewChat(type, entityId) {
        let index;
        let deferred = $q.defer();
        let url = basePath + 'view/';
        removeUnreadChatId(entityId);
        index = findChatById(entityId);
        if (angular.isDefined(chatFactory.chatsList) && angular.isDefined(chatFactory.chatsList[index]) && chatFactory.chatsList[index].typing) {
            chatFactory.chatsList[index].typing = false;
            let message = { type: 'typing', createdAt: new Date(), userId: chatFactory.chatsList[index].typedBy };
            chatFactory.chatsList[index].messages.push(message);
        }

        if (chatFactory.chatsList[index] && chatFactory.chatsList[index].isVisited) {
            deferred.resolve(index);
            return deferred.promise;
        }

        url = url + entityId + '?messageLimit=' + chatFactory.chatMessageLimit;

        webSocket.request({ url: url, headers: webSocketService.headers, method: 'get' }, (response, jwres) => {
            if (jwres.error) {
                return deferred.reject(response);
            }
            if (~index) {
                chatFactory.chatsList[index].messages = response.data.messages;
                chatFactory.chatsList[index].isVisited = true;
                if (response.data.type === "individual") {
                    chatFactory.chatsList[index].follow = response.data.follow;
                }
            } else {
                chatFactory.chatsList.push(response);
                index = chatFactory.chatsList.length - 1;
                chatFactory.chatsList[index].isVisited = true;
            }
            chatFactory.currentChatId = chatFactory.chatsList[index].id;
            deferred.resolve(index);
        });
        return deferred.promise;
    }

    chatFactory.addMessageIfNotInArray = _.debounce((message, array) => {
        for (let i = 0; i < array.length; i++) {
            if (array[i].id === message.id) {
                return true;
            }
        }
        array.push(message);
        return true;
    }, 100);

    function convertForMediaMessage(message) {
        if (message.type === 'media') {
            message.file = {};
            message.file.mediaUrl = message.metaData.mediaUrl;
            message.file.thumbUrl = message.metaData.thumbUrl;
            if (message.metaData.mediaMeta) {
                message.file.meta = message.metaData.mediaMeta;
            }

            message.file.hash = message.metaData.mediaHash;
            message.file.name = message.metaData.mediaName;
            message.file.id = message.metaData.mediaId;
        }
        return message;
    }

    function addMessageToChat(entityId, message, isUnshift) {
        message = convertForMediaMessage(message);

        let index = findChatById(entityId);

        if (~index) {
            if (isUnshift) {
                chatFactory.chatsList[index].messages.unshift(message);
            } else {

                if (chatFactory.chatsList[index].messages[chatFactory.chatsList[index].messages.length - 1].type === 'typing') {
                    chatFactory.chatsList[index].messages.pop();
                }

                if (chatFactory.chatsList[index].typing) {
                    chatFactory.chatsList[index].typing = false;
                    chatFactory.chatsList[index].typedBy = 0;
                }
                chatFactory.chatsList[index].messages.push(message);
            }
        }

        if (Store.get('collaborateWindowCount') <= 0 && message.userId !== $rootScope.user.id) {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.getRegistrations().then(function (registrations) {
                    if (registrations.length > 0) {
                        const title = message.title;
                        const options = {
                            body: message.name + " - " + message.messageBody,
                            icon: '../../favicon.png',
                            badge: '../../favicon.png',
                            data: "/collaborate/chat/" + message.chatId,
                            tag: message.chatId
                        };
                        // $state.go('collaborateViewChat', {chatId: message.chatId});
                        registrations[0].showNotification(title, options);
                    }
                });
            }
        }

        return index;
    }

    let DEFAULT_CLUB_DIFF = 3600000;

    function checkDiffDaysToClub(dateTime1, dateTime2) {
        let result = false;
        if (dateTime1 && dateTime2) {
            let dateEpoch1 = Date.parse(dateTime1);
            let dateEpoch2 = Date.parse(dateTime2);
            if ((isNaN(dateEpoch1) === false) && (isNaN(dateEpoch2) === false)) {
                let difference = dateEpoch2 - dateEpoch1;
                if (difference < 0) {
                    difference = difference * (-1);
                }
                if (difference < DEFAULT_CLUB_DIFF) {
                    result = true;
                }
            }
        }
        return result;
    }

    $rootScope.isClubMessage = function (message1, message2) {
        let result = false;
        if (message1 && message2 &&
            (message1.creator_id === message2.creator_id) &&
            checkDiffDaysToClub(message1.createdAt, message2.createdAt)) {
            result = true;
        }
        return result;
    };

    $rootScope.insertDayDivision = function (date1, date2) {
        if (date1 && date2) {
            date1 = new Date(date1.createdAt).getDate();
            date2 = new Date(date2.createdAt).getDate();
            if (date1 !== date2) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    $rootScope.addClubbedMessageClass = function (message1, message2) {
        return $rootScope.isClubMessage(message1, message2) && !$rootScope.insertDayDivision(message1, message2);
    };

    function ifUserHasChat() {
        let result = true;
        if (chatFactory.chatsList && chatFactory.chatsList.length === 0) {
            result = false;
        }
        return result;
    }

    function removeVisitedFlag() {
        for (let i = 0; i < chatFactory.chatsList.length; i++) {
            delete chatFactory.chatsList[i].isVisited;
        }
    }

    function removeUnreadCount(index, type) {
        if (~index && chatFactory.chatsList[index]) {
            chatFactory.chatsList[index].unread = 0;
        }
    }

    // List of functions to be called when the service is initialized
    getAllChat();
    subscribe();

    function chatClosedCallback(chatIndex, obj) {
        if (!$stateParams.chatId || $stateParams.chatId !== obj.chatId || document.hidden) {
            chatFactory.chatsList[chatIndex].unread++;
            chatSound.play();
        }

    }

    webSocket.on('chatcreate', (obj) => {
        $timeout(() => {
            saveChat(obj);
        }, 0);
    });

    webSocket.on('oldchatcreate', (obj) => {
        $timeout(() => {

            let chatIndex = _.findIndex(chatFactory.chatsList, {
                friend: {
                    id: obj.friend
                }
            });

            if (angular.isDefined(chatFactory.chatsList[chatIndex])) {
                chatFactory.chatsList[chatIndex] = obj;
            }
            else {
                chatIndex = _.findIndex(chatFactory.chatsList, { friend: obj.friend });
                if (angular.isDefined(chatFactory.chatsList[chatIndex])) {
                    chatFactory.chatsList[chatIndex] = obj;
                }
            }
        }, 0);
    });

    webSocket.on('reinitiateConversation', (obj) => {
        $timeout(() => {
            /**
             * This code was assigning old message obj to the chat
             * That was the problem if new message arrives then it won't update the latest message
             */
            // let chatIndex = _.findIndex(chatFactory.chatsList, {friend:obj.friend});
            // if(angular.isDefined(chatFactory.chatsList[chatIndex])){
            // 	chatFactory.chatsList[chatIndex] = obj;
            // }
        }, 0);
    });

    webSocket.on('messagecreate', (obj) => {

        $timeout(() => {
            let index = addMessageToChat(obj.chatId, obj);

            $rootScope.$broadcast('message:scroll');
            if (~index) {
                if (obj.userId === $rootScope.user.id) {
                    // If the message received is actually generated by the same user, but in another session, then reset the chats unread count
                    removeUnreadCount(chatFactory.chatsList[index]);
                } else {
                    chatClosedCallback(index, obj);
                }
                orderChat();
            }
        }, 0);
    });

    webSocket.on('messagedelete', (obj) => {
        $timeout(() => {
            removeMessage(obj);
        }, 0);
    });

    webSocket.on('deleteConversation', (obj) => {
        $timeout(() => {
            deleteChatConversation(obj.chatId);
        }, 0);
    });
    webSocket.on('unfriendUser', (obj) => {
        $timeout(() => {
            unfriendUserConversation(obj.id);
        }, 0);
    });
    webSocket.on('roleGroupChange', (obj) => {
        $timeout(() => {
            let chatId = obj.chatId;
            if (chatId) {
                let chatIndex = chatFactory.chatsList.findIndex(objV => objV.id === chatId);
                if (chatIndex != -1) {
                    let userChatIndex = chatFactory.chatsList[chatIndex].userchats.findIndex(objV => objV.id === obj.id);
                    if (userChatIndex != -1) {
                        chatFactory.chatsList[chatIndex].userchats[userChatIndex] = obj;
                    }
                }
            }
        }, 0);
    });
    webSocket.on('memberGroupRemove', (obj) => {
        $timeout(() => {
            let chatId = obj.chatId;
            if (chatId) {
                let chatIndex = chatFactory.chatsList.findIndex(objV => objV.id === chatId);
                if (chatIndex != -1) {
                    chatFactory.chatsList.splice(chatIndex, 1);
                    $rootScope.$broadcast('group:remove');
                }
            }
        }, 0);
    });
    webSocket.on('memberGroupLeave', (obj) => {
        $timeout(() => {
            let chatId = obj.chatId;
            if (chatId) {
                let chatIndex = chatFactory.chatsList.findIndex(objV => objV.id === chatId);
                if (chatIndex != -1) {
                    let userChatIndex = chatFactory.chatsList[chatIndex].userchats.findIndex(objV => objV.userId === obj.userId);
                    let usersIndex = chatFactory.chatsList[chatIndex].users.findIndex(objV => objV.id === obj.userId);
                    if (userChatIndex != -1) {
                        chatFactory.chatsList[chatIndex].userchats.splice(userChatIndex, 1);
                    }
                    if (usersIndex != -1) {
                        chatFactory.chatsList[chatIndex].users.splice(usersIndex, 1);
                    }
                }
            }
        }, 0);
    });
    webSocket.on('changeChatName', (obj) => {
        $timeout(() => {
            let chatId = obj.id;
            if (chatId) {
                let chatIndex = chatFactory.chatsList.findIndex(objV => objV.id === chatId);
                if (chatIndex != -1) {
                    chatFactory.chatsList[chatIndex].name = obj.name;
                }
            }
        }, 0);
    });
    webSocket.on('memberGroupAdd', (obj) => {
        $timeout(() => {
            // let chatId=obj.chatId;
            if (obj) {
                chatFactory.chatsList.push(obj)
            }
        }, 0);
    });

    webSocket.on('typingStatus', (obj) => {
        $timeout(() => {
            let chatIndex = _.findIndex(chatFactory.chatsList, { id: obj.chatId });
            if (angular.isDefined(chatFactory.chatsList[chatIndex])) {

                if (chatFactory.chatsList[chatIndex].isBlocked) {
                    chatFactory.chatsList[chatIndex].typing = false;
                    chatFactory.chatsList[chatIndex].typedBy = 0;
                    return;
                }

                if (chatFactory.chatsList[chatIndex].id !== chatFactory.currentChatId) {
                    chatFactory.chatsList[chatIndex].typing = obj.typingStatus;
                    if (chatFactory.chatsList[chatIndex].typing) {
                        chatFactory.chatsList[chatIndex].typedBy = obj.userId;
                    } else {
                        chatFactory.chatsList[chatIndex].typedBy = 0;
                    }

                } else {
                    let chat = chatFactory.chatsList[chatIndex];
                    let lastMessageTypeofTyping = (chat.messages.length > 0) ? (chat.messages[chat.messages.length - 1]) ? chat.messages[chat.messages.length - 1].type === 'typing' : false : false;
                    if (obj.typingStatus && !lastMessageTypeofTyping) {
                        let message = { type: 'typing', createdAt: new Date(), userId: obj.userId };
                        chatFactory.chatsList[chatIndex].messages.push(message);
                    } else if (!obj.typingStatus && lastMessageTypeofTyping) {
                        chatFactory.chatsList[chatIndex].messages.pop();
                    }
                }

            }
        }, 0);
    });

    webSocket.on('blockChat', blockUnblockChat);
    webSocket.on('unblockChat', blockUnblockChat);
    function blockUnblockChat(obj) {
        $timeout(() => {
            if (angular.isDefined(obj.chat) && angular.isDefined(obj.friend) && obj.chat.id) {
                let chatIndex = _.findIndex(chatFactory.chatsList, { id: obj.chat.id });
                if (angular.isDefined(chatFactory.chatsList[chatIndex])) {
                    chatFactory.chatsList[chatIndex].isBlocked = obj.friend.isBlocked;
                    chatFactory.chatsList[chatIndex].blockedBy = obj.friend.blockedBy;
                }
            }
        }, 0);

    }

    webSocket.on('connect', () => {
        if ($state.current.name === 'collaborateViewChat') {
            $state.reload($state.current.name);
        }

        $timeout(() => {
            subscribe();
        }, 0);
    });



    webSocket.on('disconnect', () => {
        $timeout(() => {
            removeVisitedFlag()
        }, 0);
    });


    function deleteChat(id, friendId) {
        let deferred = $q.defer();
        let url = basePath + 'delete/' + id + "?friendId=" + friendId;
        const headers = {
            'Accept': 'application/json',
            'X-Access-Token': window.Store.get('authorizationToken')
        };
        webSocket.request({ url: url, headers, method: 'DELETE' }, (response, jwres) => {
            if (jwres.error) {
                return deferred.reject(response);
            }

            if (response.data) {
                return deferred.resolve(response.data);
            }
            return deferred.reject(response);
        });

        return deferred.promise;


    }
    function updateContactChat(data) {
        let deferred = $q.defer(),
            savePromise, options;

        options = {
            method: 'POST',
            url: basePath + 'updateContactChat',
            data: data
        };

        savePromise = $http(options);
        savePromise
            .then((response) => {
                deferred.resolve(response.data);
            })
            .catch((response) => {
                deferred.reject(response);
            });

        return deferred.promise;
    }
    function updateContactChatAgain(data) {
        let deferred = $q.defer();
        let url = basePath + 'updateContactChat';
        const headers = {
            'Accept': 'application/json',
            'X-Access-Token': window.Store.get('authorizationToken')
        };
        webSocket.request({ url: url, headers, method: 'post', data }, (response, jwres) => {
            if (jwres.error) {
                return deferred.reject(response);
            }

            if (response.data) {
                return deferred.resolve(response.data);
            }
            return deferred.reject(response);
        });
        return deferred.promise;
    }

    function unfriendUser(data) {
        let deferred = $q.defer();
        let url = "/api/friends/" + 'unfriend';
        const headers = {
            'Accept': 'application/json',
            'X-Access-Token': window.Store.get('authorizationToken')
        };
        webSocket.request({ url: url, headers, method: 'DELETE', data }, (response, jwres) => {
            if (jwres.error) {
                return deferred.reject(response);
            }

            if (response.data) {
                return deferred.resolve(response.data);
            }
            return deferred.reject(response);
        });
        return deferred.promise;
    }

    function friendSearch(name) {
        let deferred = $q.defer(),
            savePromise, options;

        options = {
            method: 'GET',
            url: '/api/friends/friendSearch',
            params: { name: name }
        };

        savePromise = $http(options);
        savePromise
            .then((response) => {
                deferred.resolve(response.data);
            })
            .catch((response) => {
                deferred.reject(response);
            });

        return deferred.promise;
    }


    return chatFactory;
}
export default collaborateService;
