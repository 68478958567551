let angular = window.angular;
infiniteScroll.$inject = ["$timeout", "_", "$window", "commonService"];
function infiniteScroll($timeout, _, $window, commonService) {
  return function (scope, elm, attr) {
    let getScrollTop = function () {
      return commonService.checkIfMozilla() || commonService.checkIfIe()
        ? document.documentElement.scrollTop
        : element.scrollTop;
    };

    let getScollY = function () {
      return commonService.checkIfIe() ? window.pageYOffset : window.scrollY;
    };

    let scrollElm = angular.element($window);
    let element = document.body;
    let scrollTop = getScrollTop();
    let old_scrolltop = scrollTop;

    let inifiniteScrollCall = function () {
      let offset = commonService.convertToInteger(attr.threshold) || 100;
      let isAtBottom =
        window.innerHeight + getScollY() + offset >= document.body.offsetHeight;
      if (isAtBottom && attr.canLoad) {
        old_scrolltop = getScrollTop();
        scope.$apply(attr.whenScrolled);
      }
    };

    let scrollCallback = _.debounce(function () {
      if (getScrollTop() > old_scrolltop) {
        inifiniteScrollCall();
      }
      old_scrolltop = getScrollTop();
    }, 50);

    let handleDestroy = function () {
      elm.remove();
      scrollElm.unbind("scroll", scrollEventBind);
      angular.element(element).unbind("scroll", scrollEventBind);
    };

    let handleScroll = function (event, data) {
      scope.$apply(scrollCallback);
    };

    let scrollEventBind = function () {
      scope.$apply(scrollCallback);
    };

    //both event listeners are added because browser compatibility issue
    if (commonService.checkIfMozilla() || commonService.checkIfIe()) {
      angular.element(element).bind("scroll", scrollEventBind);
    }

    scrollElm.bind("scroll", scrollEventBind);

    scope.$on("$destroy", handleDestroy);
    scope.$on("broadcast-for-scroll", handleScroll);
  };
}
export default infiniteScroll;
