hspTyping.$inject = ["$window"];

function hspTyping($window) {
  let directive = {
    restrict: "E",
    template: require("../../pug/components/typing-dots.pug").default,
    scope: {
      typingClass: "@",
    },
  };

  return directive;
}

export default hspTyping;
