// (function () {
// 	'use strict';

// 	angular
// 		.module('HSP')
// 		.factory('redirectWithoutSubscription', redirectWithoutSubscription)
// 		.factory('redirectIfNotAdmin', redirectIfNotAdmin);

//add this function to state resolve in order to protect routes from users if subscription is expired or null
export function redirectWithoutSubscription(
  $state,
  $timeout,
  $q,
  $rootScope,
  $location
) {
  let defer = $q.defer();
  if (
    $rootScope.user.userRole === "PARENT" &&
    ($rootScope.user.payment == null ||
      $rootScope.user.payment.currStatus == "Expired")
  ) {
    defer.reject();
    $timeout(function () {
      $state.go("paymentPlan", {
        isRedirectedWithoutSubscription: true,
      });
    });
  } else if (
    $rootScope.user.userRole === "PARENT" &&
    $rootScope.user.payment &&
    $rootScope.user.payment.planId.detail == "basic"
  ) {
    let locationPath = $location.$$path;

    if (
      locationPath.includes("lesson") ||
      locationPath.includes("calendar") ||
      locationPath.includes("report") ||
      locationPath.includes("budget") ||
      locationPath.includes("book") ||
      locationPath.includes("student")
    ) {
      defer.reject();
      $timeout(function () {
        $state.go("dashboard");
      });
    }
    defer.resolve();
  } else {
    defer.resolve();
  }

  return defer.promise;
}

//add this function to state resolve in order to protect admin routes
export function redirectIfNotAdmin($state, $timeout, $q, $rootScope) {
  let defer = $q.defer();
  if ($rootScope.user.isAdmin == null || !$rootScope.user.isAdmin === true) {
    defer.reject();
    $timeout(function () {
      $state.go("dashboard");
    });
  } else defer.resolve();

  return defer.promise;
}

redirectWithoutSubscription.$inject = [
  "$state",
  "$timeout",
  "$q",
  "$rootScope",
  "$location",
];
redirectIfNotAdmin.$inject = ["$state", "$timeout", "$q", "$rootScope"];
