let angular = window.angular;
	userSubscriptionCancelReasonController.$inject = ['userService', 'toastService', '$uibModalInstance'];

	function userSubscriptionCancelReasonController(userService, toastService, $uibModalInstance) {
        let vm = this;
        vm.userNote = "";
        vm.checkBox1 = {key: false, value: "I just wanted to see what Homeschool Panda does"}
        vm.checkBox2 = {key: false, value: "Homeschool Panda did not have the features we need"}
        vm.checkBox3 = {key: false, value: "The pricing is too expensive"}
        vm.checkBox4 = {key: false, value: "I couldn’t set up the lesson plans"}
        vm.checkBox5 = {key: false, value: "I needed more tutorials and knowledge articles to help"};

		angular.extend(vm, {
            cancelSubscription,
            close,
        });

        function cancelSubscription() {
            vm.userNoteError = null;
            vm.presetError = null;

            if(!vm.checkBox1.key && !vm.checkBox2.key && !vm.checkBox3.key && !vm.checkBox4.key & !vm.checkBox5.key){
				vm.presetError = "Please specify your reason."
				return false;
            }

            if(!vm.userNote || vm.userNote == ""){
                vm.userNoteError = "Please provide your feedback"
                return false;
            }

            var preset_reason = presetReason();

            let reason = { userNote: vm.userNote, preset_reason: preset_reason}
            userService.cancelUserSubscription(reason)
                .then((response) => {
                    toastService.toast({
                        message: 'Request submitted, We will confirm it and reach out to you.',
                        type: 'success',
                        delay: 2000
                    });
                    close();
                })
                .catch((response) => {
					if(response.data.message == 'Subscription is already cancelled') {
						toastService.toast({
                            message: "You have already submitted a cancellation request.",
                            type: 'error',
                            delay: 4000
                        });
                    }
                    else if(response.data.message == 'Free trial is already requested'){
                        toastService.toast({
                            message: "You have already submmited a free month subscription request.",
                            type: 'error',
                            delay: 4000
                        });
                    }
                    else{
                        toastService.toast({
                            message: "Some error occured, try again!",
                            type: 'error',
                            delay: 4000
                        });
                    }
                })
        }

        function presetReason(){
            var preset_reason = "";
            if(vm.checkBox1.key)
                preset_reason += vm.checkBox1.value + ", "
            if(vm.checkBox2.key)
                preset_reason += vm.checkBox2.value + ", "
            if(vm.checkBox3.key)
                preset_reason += vm.checkBox3.value + ", "
            if(vm.checkBox4.key)
                preset_reason += vm.checkBox4.value + ", "
            if(vm.checkBox5.key)
                preset_reason += vm.checkBox5.value + ", "

            return preset_reason;
        }

        function close() {
            $uibModalInstance.close();
        }

        function init() {

		}

		this.$onInit = init;
    }

export default userSubscriptionCancelReasonController;
