let angular = window.angular;

export const TODO_MODULE = angular.module('task', []);

TODO_MODULE.service('taskService', taskService);
    taskService.$inject = ['$http', '$q', 'commonService', '_',"appConstants"];

    function taskService($http, $q, commonService, _,appConstants) {

        const self = this;
        const basePath = appConstants.baseUrl+'/api/todos/';

        this.tasks = [];
        // this.tasks = [{}];

        /**
         * Multiple Todos
         */
        this.editCategory = function (categoryId, category){
            let deferred = $q.defer(), getTasksPromise;
            let data = {
                id: categoryId,
                category: category
            }
            getTasksPromise = $http.post(basePath + 'editCategory', data);

            getTasksPromise
                .then((response) => {
                    // Array.prototype.push.apply(self.tasks, response.data.data);
                    // deferred.resolve(self.tasks);
                    deferred.resolve(response);

                })
                .catch((response) => {
                    deferred.reject(response.data);
                });

            return deferred.promise;
        }

        this.editTodo = function (todoId, categoryId, title){
            let deferred = $q.defer(), getTasksPromise;
            let data = {
                id: todoId,
                categoryId: categoryId,
                title: title
            }
            getTasksPromise = $http.post(basePath + 'edit', data);

            getTasksPromise
                .then((response) => {
                    // Array.prototype.push.apply(self.tasks, response.data.data);
                    // deferred.resolve(self.tasks);
                    deferred.resolve(response);

                })
                .catch((response) => {
                    deferred.reject(response.data);
                });

            return deferred.promise;
        }

        this.getCategory = function (queryParams) {
            let deferred = $q.defer(), getTasksPromise;

            getTasksPromise = $http.get(basePath + 'getAll', {params: queryParams});

            getTasksPromise
                .then((response) => {
                    // Array.prototype.push.apply(self.tasks, response.data.data);
                    // deferred.resolve(self.tasks);
                    deferred.resolve(response);

                })
                .catch((response) => {
                    deferred.reject(response.data);
                });

            return deferred.promise;
        };

        this.addCategory = function (task) {
            let deferred = $q.defer(), url = basePath + 'addCategory', addCategory,
                data = {
                    category: task
                };

            addCategory = $http.post(url, data);
            addCategory
                .then((response) => {
                    // self.tasks.unshift(response.data.data);
                    deferred.resolve(response);
                })
                .catch((response) => {
                    deferred.reject(response);
                })
            return deferred.promise;
        };


        this.addTodo = function (title, categoryId) {
            let deferred = $q.defer(), url = basePath + 'add', addCategory,
                data = {
                    todo:{
                        title: title,
                        categoryId: categoryId
                    }
                };

            addCategory = $http.post(url, data);
            addCategory
                .then((response) => {
                    // self.tasks.unshift(response.data.data);
                    deferred.resolve(response);
                })
                .catch((response) => {
                    deferred.reject(response);
                })
            return deferred.promise;
        };


        this.deleteCategory = function (categoryId){
            let deferred = $q.defer(), deleteCategoryPromise;
            deleteCategoryPromise = $http.delete(basePath + 'removeCategory/'+ categoryId);

            deleteCategoryPromise
                .then((response) => {
                    // commonService.removeFromArray(index, 'id', self.tasks);
                    deferred.resolve(response.data);
                })
                .catch((response) => {
                    deferred.reject(response.data);
                });

            return deferred.promise;
        }

        this.deleteTodo = function (listId){
            let deferred = $q.defer(), deleteCategoryPromise;

            deleteCategoryPromise = $http.delete(basePath + 'remove/' + listId);

            deleteCategoryPromise
                .then((response) => {
                    // commonService.removeFromArray(index, 'id', self.tasks[categoryId]['list']);
                    deferred.resolve(response.data);
                })
                .catch((response) => {
                    deferred.reject(response.data);
                });

            return deferred.promise;
        }

        this.getTasksById = function (taskIds) {
            let i, length = self.tasks.length;
            let taskList = [];
            taskIds = _.isArray(taskIds) ? taskIds : [taskIds];
            for (i = length - 1; ~i; --i) {
                if (~taskIds.indexOf(self.tasks[i].id)) {
                    taskList.push(self.tasks[i]);
                }
            }
            return taskList;
        };

        this.getTasks = function (queryParams) {
            let deferred = $q.defer(), getTasksPromise;

            getTasksPromise = $http.get(basePath + 'index', {params: queryParams});

            getTasksPromise
                .then((response) => {
                    Array.prototype.push.apply(self.tasks, response.data.data);
                    deferred.resolve(self.tasks);
                })
                .catch((response) => {
                    deferred.reject(response.data);
                });

            return deferred.promise;
        };

        this.addTask = function (task) {
            let deferred = $q.defer(), url = basePath + 'add', addTaskPromise,
                data = {
                    todo: {
                        title: task
                    }
                };

            addTaskPromise = $http.post(url, data);
            addTaskPromise
                .then((response) => {
                    self.tasks.unshift(response.data.data);
                    deferred.resolve(response);
                })
                .catch((response) => {
                    deferred.reject(response);
                })
            return deferred.promise;
        };

        this.editTask = function (taskId, title) {
            let deferred = $q.defer(),
                data = {
                    todo: {
                        title: title
                    }
                },
                editTaskPromise;

            editTaskPromise = $http.post(basePath + 'edit/' + taskId, data)
            editTaskPromise
                .then((response) => {
                    commonService.saveInArray(taskId, 'id', self.tasks, response.data.data);
                    deferred.resolve(response);
                })
                .catch((response) => {
                    deferred.reject(response);
                });
            return deferred.promise;
        };

        this.deleteTask = function (taskId) {
            let deferred = $q.defer(), deleteTaskPromise;

            deleteTaskPromise = $http.delete(basePath + 'remove/' + taskId);

            deleteTaskPromise
                .then((response) => {
                    commonService.removeFromArray(taskId, 'id', self.tasks);
                    deferred.resolve(response.data);
                })
                .catch((response) => {
                    deferred.reject(response.data);
                });

            return deferred.promise;
        };

        this.completeTask = function (taskId) {
            let deferred = $q.defer(), taskPromise;

            taskPromise = $http.post(basePath + 'markComplete/' + taskId);

            taskPromise
                .then((response) => {
                    deferred.resolve(response.data);
                })
                .catch((response) => {
                    deferred.reject(response.data);
                });

            return deferred.promise;
        };
    }
