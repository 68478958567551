const angular = window.angular;
assignmentController.$inject = [
  "$uibModalInstance",
  "$q",
  "$timeout",
  "assignmentService",
  "viewAssignmentParams",
  "$state",
  "studentService",
  "commonService",
  "lessonConstants",
  "fileService",
  "_",
  "$uibModal",
  "calendarService",
  "$rootScope",
  "toastService",
  "moment",
  "lessonService",
  "calendarConstants",
  "$window",
  "$sce",
  "$scope",
];

function assignmentController(
  $uibModalInstance,
  $q,
  $timeout,
  assignmentService,
  viewAssignmentParams,
  $state,
  studentService,
  commonService,
  lessonConstants,
  fileService,
  _,
  $uibModal,
  calendarService,
  $rootScope,
  toastService,
  moment,
  lessonService,
  calendarConstants,
  $window,
  $sce,
  $scope
) {
  let vm = this,
    returnData = {};
  vm.activeTab = "assignment";
  vm.attendance = lessonConstants.attendance.other;
  vm.assignment = {};
  vm.lesson = {};
  vm.student = {};
  vm.name = null;
  vm.allDayEvent = false;
  vm.isDeletedBook = false;
  vm.statusChanged = false;
  vm.attendanceOptions = {
    absent: lessonConstants.attendance.absent,
    present: lessonConstants.attendance.present,
  };
  vm.statuses = lessonConstants.statuses;
  vm.averageCompletedAssignments = 0;
  vm.totalAssignmentCount = 0;
  vm.completedAssignmentsCount = 0;
  vm.isEdit = false;
  vm.copyIndex = null;
  vm.resultType = null;
  vm.copyStudents = [];
  vm.previousType = "";
  vm.gradeObtainedMarks = 0;
  vm.gradeTotalMarks = 0;
  vm.gradepercentage = 0;
  vm.isMarksDefault = false;
  vm.isStatusChanged = false;
  vm.isGradeChanged = false;
  vm.isGradeChangedAll = false;
  vm.changedStatus = false;
  vm.showAssignmentEditor = false;
  vm.showDailyNoteEditor = false;
  vm.showNotepadEditor = false;
  vm.assignmentPlaceholder = "Compose three sentences about assignment";
  vm.dailyNotePlaceholder = "Add some notes or points";
  vm.notepadPlaceholder = "Add description about the assignmemnt";
  vm.reschedule = angular.isDefined(viewAssignmentParams.reschedule)
    ? viewAssignmentParams.reschedule
    : false;
  vm.allResources = [];
  vm.newResources = [];
  vm.userId;
  vm.assignmentEditor;
  vm.dailyNoteEditor;

  vm.gradeCustomError = false;

  vm.seeMoreGrading = false;

  vm.gradeChecked = false;
  vm.gradeCheckedModal = false;
  vm.triggerModalClick = false;

  vm.inputGradeCheck = false;
  vm.modalGradeClose = false;
  vm.updatedAssignmentTitle;

  vm.openAssignmentAccordion = false;
  vm.openDailyNoteAccordion = false;
  vm.openNotepadAccordion = false;
  vm.openResourcesAccordion = false;
  vm.openFilesAccordion = false;
  vm.assignmentInfo = {};

  //design Class condition
  vm.customCollapse = false;

  vm.elements = [];
  vm.studentEdit = false;

  vm.UpdateTitleForAll = {
    entityIds: [],
  };
  vm.groupAssignment = false;
  vm.displayAssignmentType;

  vm.assignmentIds = [];

  angular.extend(vm, {
    close,
    close_new,
    toggleTab,
    // markAttendance,
    downloadFile,
    editAssignment,
    getStatusClass,
    updateGrades,
    selectStatus,
    selectStudentStatus,
    deleteAssignment,
    unassignBook,
    changeImageUrl,
    editGrade,
    addGrade,
    calculatePercentage,
    openSeries,
    deleteSeries,
    deleteGrade,
    calculateCustomPercentage,
    addCustomGrade,
    seeMore,
    checkGradingLimit,
    clearStudentGrade,
    checkDisabled,
    checkClearDisabled,
    editorFocusHandler,
    addResources,
    getBookIdFromResources,
    getDefaultResourceData,
    cancelEditor,
    saveEditor,
    filterEditorText,
    emptyTextCheck,
    unassignElectronicMedia,
    showAngularAccordion,
    updateDragList,
    elementDargStart,
    enableGrading,
    editAssignmentLesson,
    gradingEnableCheck,
  });

  function gradingEnableCheck() {
    if (
      (vm.assignment.isGradingEnable &&
        $rootScope.user.userRole !== "STUDENT") ||
      (vm.assignment.isGradingEnable &&
        $rootScope.checkStudentRolePermission("grade") &&
        $rootScope.user.userRole === "STUDENT")
    ) {
      return false;
    } else {
      return true;
    }
  }

  function enableGrading() {
    saveEditor(".gradingEnable");
  }

  function getBookIdFromResources() {
    vm.resourceBookIds = _.map(vm.books, function (book) {
      return {
        id: book.id,
        assignId: book.assignId,
      };
    });
  }

  function getDefaultResourceData(books) {
    let willAddBooks = [],
      finalBooks = [];
    for (let i = 0; i < books.length; i++) {
      let index = _.findIndex(vm.books, {
        bookId: books[i].bookId,
      });
      if (index < 0) {
        let object = books[i];

        // object.startDay = 1;
        // object.startWeek=  1,
        // object.endDay = vm.days,
        // object.endWeek = vm.weeks;
        willAddBooks.push(object);
      }
    }
    finalBooks = vm.books.concat(willAddBooks);
    return finalBooks;
  }

  function addResources() {
    if (
      $rootScope.user.userRole == "STUDENT" &&
      !vm.assignment.hasEditPermissions
    ) {
      return;
    }
    getBookIdFromResources();
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "lessonBookAndElectronicMediaController",
      controllerAs: "bookAndElectronicMediaCtrl",
      template: require("../../pug/lesson/book-electronic-resources.pug")
        .default,
      resolve: {
        bookData: {
          bookIds: vm.resourceBookIds,
          electronicMedia: vm.newResources,
          assignmentId: viewAssignmentParams.assignmentId,
          assignment: vm.assignment,
          userId: vm.userId,
        },
      },
    });
    modelInstance.result
      .then(function (data) {
        vm.books = angular.copy(data.books);
        let mappedBooks = vm.books.map((book) => {
          book.type = "book";
          return book;
        });
        let mappedElectronicMedia = data.electronicMedia.map((elem) => {
          elem.type = "electronicMedia";
          return elem;
        });

        vm.newResources = [...mappedElectronicMedia];
        vm.allResources = [...mappedBooks, ...mappedElectronicMedia];
      })
      .catch(function () {
        modelInstance.close();
      });
  }

  function editorFocusHandler(type) {
    // $.trumbowyg.btns = [
    // 	["undo", "redo"], // Only supported in Blink browsers
    // 	["strong", "em"],
    // 	["superscript", "subscript"],
    // 	["link"],
    // 	["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
    // 	["unorderedList", "orderedList"],
    // 	["fullscreen"],
    // ];
    if (
      $rootScope.user.userRole == "STUDENT" &&
      (!vm.assignment.hasEditPermissions || vm.assignment.studentLesson.isGroup)
    ) {
      return;
    }

    if (type === "assignment") {
      vm.showAssignmentEditor = true;
      $timeout(() => {
        $(".assignmentTitle").trumbowyg({
          btns: lessonConstants.btns,
          minimalLinks: true,
          defaultLinkTarget: "_blank",
        });
      }, 5);
    }
    if (type === "dailyNote") {
      vm.showDailyNoteEditor = true;
      $timeout(() => {
        $(".dailyNoteTitle").trumbowyg({
          btns: lessonConstants.btns,
          minimalLinks: true,
          defaultLinkTarget: "_blank",
        });
      }, 5);
    }
    if (type === "notepad") {
      vm.showNotepadEditor = true;
      $timeout(() => {
        $(".notepad").trumbowyg({
          btns: lessonConstants.btns,
          minimalLinks: true,
          defaultLinkTarget: "_blank",
        });
      }, 5);
    }
  }

  function clearStudentGrade(student, index) {
    if (!student.assignmentId) {
      return;
    }
    if (!student.grade) {
      toastService.toast({
        message: "No Grade is assigned",
        type: "error",
        delay: 3000,
      });
      return;
    }

    assignmentService
      .deleteGrade(student.assignmentId)
      .then((response) => {
        if (response.status == 200) {
          vm.assignment.students[index].customValue = "";
          vm.assignment.students[index].grade = null;
          vm.assignment.students[index].percentage = 0;
          vm.assignment.students[index].disabled = false;
          toastService.toast({
            message: "Student Grade is Cleared",
            type: "success",
            delay: 2000,
          });

          if (viewAssignmentParams && viewAssignmentParams.metadata) {
            vm.statusChanged = true;
            Store.set("assignmentOptions", JSON.stringify(vm.statusChanged));
            updateCalendarEvent();
            if (student.assignmentId == viewAssignmentParams.metadata.id) {
              viewAssignmentParams.metadata.grade = null;
              viewAssignmentParams.metadata.percentage = 0;
              viewAssignmentParams.metadata.customValue = "";
            }
            if (
              viewAssignmentParams.metadata.entityIds &&
              viewAssignmentParams.metadata.entityIds.length
            ) {
              let findStudentParamIndex = _.findIndex(
                viewAssignmentParams.metadata.entityIds,
                (obj) => obj.entityId == student.assignmentId
              );
              if (findStudentParamIndex !== -1) {
                // viewAssignmentParams.metadata.entityIds[findStudentParamIndex].status = "complete";
                viewAssignmentParams.metadata.entityIds[
                  findStudentParamIndex
                ].grade = null;
                viewAssignmentParams.metadata.entityIds[
                  findStudentParamIndex
                ].percentage = 0;
                viewAssignmentParams.metadata.entityIds[
                  findStudentParamIndex
                ].customValue = "";
              }
            }

            updateMultipleStudent(
              {
                grade: null,
                obtainedMarks: 0,
                percentage: 0,
                totalMarks: 0,
              },
              index,
              student.assignmentId,
              response.data.data
            );

            getAssignmentDetails();
          }
        }
      })
      .catch((error) => {
        let message = angular.isDefined(error.data.message)
          ? error.data.message
          : "Some error occured, please try again";
        toastService.toast({
          message: message,
          type: "error",
          delay: 5000,
        });
      });
  }

  function seeMore() {
    vm.seeMoreGrading = !vm.seeMoreGrading;
  }

  function checkGradingLimit(length) {
    if (vm.seeMoreGrading) {
      return length;
    } else {
      return 2;
    }
  }

  function openSeries(lessonId) {
    $uibModalInstance.close();
    $state.go("studentLessonEdit", {
      lessonId: lessonId,
    });
  }

  function deleteSeries(studentLessonId) {
    if (!studentLessonId) {
      return;
    }
    let modalInstance = $uibModal.open({
      animation: true,
      size: "md",
      controller: "EditLessonConfirmationController",
      controllerAs: "confirmLessonCtrl",
      template: require("../../pug/calendar/edit-lesson-confirmation.pug")
        .default,
    });

    let confirmPromise = modalInstance.result
      .then(
        function (option) {
          if (option) {
            let assignmentEndDate = moment(vm.assignment.endDate).format(
              "YYYY-MM-DD"
            );

            $rootScope.loadingBtn("delete-btn", "Delete");

            if (
              viewAssignmentParams.metadata.lessonStatus &&
              viewAssignmentParams.metadata.lessonStatus == "multiple"
            ) {
              studentLessonId = _.map(
                viewAssignmentParams.metadata.entityIds,
                "studentLessonId"
              );
            }

            studentService
              .removeAssignment(studentLessonId, option, assignmentEndDate)
              .then((response) => {
                close({
                  refetchEvents: true,
                });
              })
              .catch((response) => {
                toastService.toast({
                  message: response.message,
                  type: "error",
                  delay: 2000,
                });
              })
              .finally(() => {
                $rootScope.loadingBtnComplete("delete-btn", "Delete");
                // $rootScope.loadingBtnComplete('unassignBtn-' + studentLessonId, 'Update');
              });
          }
          modalInstance.close({
            refetchEvents: true,
          });
        },
        function () {
          return $q.reject(false);
        }
      )
      .catch(function () {
        modalInstance.close();
      });
  }

  function changeImageUrl(img) {
    if (img) {
      img = img.replace("http:", "https:");
    }
    return img;
  }

  function unassignBook(bookAssignId, resourceIndex) {
    if ($rootScope.user.userRole == "STUDENT") {
      return;
    }
    let confirmData = {
      message: calendarConstants.messages.deleteBookConfirmation,
      type: "danger",
      modalSize: "md",
      confirmLabel: "Yes",
    };

    commonService.confirm(confirmData).then(function (confirmation) {
      if (confirmation.result) {
        // return assignmentService.deleteAssignment(id)
        //     .then(deleteAssignmentSuccess)
        //     .catch(deleteAssignmentFailure);

        return lessonService
          .unassignBook(bookAssignId)
          .then((response) => {
            let index = _.findIndex(vm.books, {
              assignId: bookAssignId,
            });

            if (index > -1) {
              vm.books.splice(index, 1);
            }

            vm.isDeletedBook = true;

            let filteredResources = vm.allResources.filter(
              (book, index) => index !== resourceIndex
            );
            vm.allResources = [...filteredResources];

            toastService.toast({
              message: calendarConstants.messages.deleteResource,
              type: "success",
              delay: 2000,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        return;
      }
    });
  }

  function selectStudentStatus(status, entityId, index) {
    let statusPromise, newStatus;
    if (status == "complete") {
      newStatus = "not started";
      statusPromise = selectStatus("not started", entityId, "one");
    } else {
      newStatus = "complete";
      statusPromise = selectStatus("complete", entityId, "one");
    }

    statusPromise.then((response) => {
      vm.student[index].status = newStatus;
      vm.assignment.students[index].status = newStatus;

      vm.assignment.status = isAssignmentComplete()
        ? "complete"
        : "not started";
    });
  }

  function selectStatus(
    status,
    entityId = vm.assignment.id,
    assignmentCount = "all"
  ) {
    if (
      assignmentCount == "all" &&
      viewAssignmentParams.metadata &&
      viewAssignmentParams.metadata.lessonStatus == "multiple"
    ) {
      let entityIds = _.map(vm.student, "entityId");
      entityId = entityIds;
    }

    vm.isStatusChanged = true;
    return assignmentService
      .changeStatus(status, entityId)
      .then(function (response) {
        vm.statusChanged = true;
        getAssignmentprogress();

        Store.set("assignmentOptions", JSON.stringify(vm.statusChanged));

        if (assignmentCount == "all") {
          for (let i = 0; i < vm.student.length; i++) {
            vm.student[i].status = status;
            vm.assignment.students[i].status = status;
          }
        }

        if (
          viewAssignmentParams.metadata &&
          viewAssignmentParams.metadata.lessonStatus == "multiple"
        ) {
          vm.assignment.status = isAssignmentComplete()
            ? "complete"
            : "not started";
        } else {
          vm.assignment.status = status;
        }

        updateCalendarEvent();

        toastService.toast({
          message: response.data.message,
          type: "success",
          delay: 8000,
        });

        vm.isStatusChanged = false;
        vm.changedStatus = true;
      })
      .catch(function (error) {
        vm.isStatusChanged = false;
        console.log("ERROR", error);
      });
  }

  function updateCalendarEvent() {
    let currentEvent = calendarService.getEventFromCalendarList(
      vm.assignment.id,
      "studentAssignment"
    );
    currentEvent.metaData.status = vm.assignment.status;
    calendarService.saveEventToList(
      vm.assignment.id,
      "studentAssignment",
      currentEvent
    );
  }

  function isAssignmentComplete() {
    for (let i = 0; i < vm.student.length; i++) {
      if (vm.student[i].status != "complete") {
        return false;
      }
    }
    return true;
  }

  function updateGrades(id, grade, index) {
    let defaultMarks = {
      obtainedMarks: 0,
      totalMarks: 0,
      percentage: 0,
    };

    vm.statusChanged = true;

    return assignmentService
      .gradeStudent(id, grade.id, defaultMarks)
      .then(function (response) {
        init();
        vm.assignment.status = "complete";
        vm.assignment.students[index].percentage =
          grade.minValue + " " + grade.maxValue;

        Store.set("assignmentOptions", JSON.stringify(vm.statusChanged));
        updateCalendarEvent();

        toastService.toast({
          message: "Grade updated successfully",
          type: "success",
          delay: 8000,
        });
        vm.isEdit = false;
        vm.copyIndex = null;
        vm.resultType = null;

        /**
         * Update for multiple students
         */
        updateMultipleStudent(
          {
            grade: grade.id,
            obtainedMarks: defaultMarks.obtainedMarks,
            percentage: defaultMarks.percentage,
            totalMarks: defaultMarks.totalMarks,
          },
          index,
          id,
          response.data.data
        );
        if (
          viewAssignmentParams &&
          viewAssignmentParams.metadata &&
          viewAssignmentParams.metadata.entityIds &&
          viewAssignmentParams.metadata.entityIds.length
        ) {
          let findStudentParamIndex = _.findIndex(
            viewAssignmentParams.metadata.entityIds,
            (obj) => obj.entityId == id
          );
          if (findStudentParamIndex !== -1) {
            viewAssignmentParams.metadata.entityIds[
              findStudentParamIndex
            ].status = "complete";
          }
        }
      })
      .catch(function (error) {
        console.log("ERROR", error);
      });
  }

  function toggleTab(tab) {
    vm.activeTab = tab;
  }

  function close(data = returnData) {
    if (viewAssignmentParams.reschedule) {
      data.entity = vm.assignment;
    }

    /**
     * Manually update the status, instead of reloading whole page
     */
    if (vm.isDeletedBook || vm.statusChanged) {
      if (
        viewAssignmentParams.metadata &&
        viewAssignmentParams.metadata.lessonStatus == "multiple"
      ) {
        angular.extend(data, {
          refetchEvents: true,
        });
      } else {
        angular.extend(data, {
          updateEvent: true,
        });
      }
    }

    let currentEvent = calendarService.getEventFromCalendarList(
      vm.assignment.id,
      "studentAssignment"
    );

    angular.extend(data, {
      entityId: vm.assignment.id,
      event: currentEvent,
      model: "studentAssignment",
      isGradeChanged: vm.isGradeChanged,
      isGradeChangedAll: vm.isGradeChangedAll,
      changedStatus: vm.changedStatus,
      title: vm.updatedAssignmentTitle,
      updateTitleForAll: vm.UpdateTitleForAll,
    });

    $uibModalInstance.close(data);
  }

  function editAssignment() {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "editAssignmentController",
      controllerAs: "editAssignmtCtrl",
      template: require("../../pug/calendar/edit-assignment.pug").default,
      resolve: {
        editAssignmentParams: {
          assignmentId: viewAssignmentParams.assignmentId,
          search: viewAssignmentParams.search,
          reschedule: vm.reschedule,
          students: vm.assignment.students,
          studentsLesson: vm.assignment.studentLesson,
          lessonStatus: viewAssignmentParams.metadata.lessonStatus || null,
          entityIds: viewAssignmentParams.metadata.entityIds || [],
        },
        // assignmentId: vm.assignment.id
      },
    });
    vm.close(modelInstance.result);
  }

  function editAssignmentLesson() {
    $uibModalInstance.close();
    let lessonId = vm.lesson.lessonId;
    $state.go("lessonEdit", {
      lessonId: lessonId,
      origin: "calendar",
      isGroup: vm.lesson.isGroup,
      studentId: vm.assignment.studentId,
    });
  }

  function downloadFile(hash) {
    fileService.downloadFile(hash);
  }

  function emptyTextCheck(text) {
    if (!text) {
      return false;
    }
    text = text.replace(/(\s*<.*?>\s*)+/g, "");
    text = text.replace(/&nbsp;/g, "").trim();
    let res = text !== "" ? true : false;
    return res;
  }

  function setViewData(assignment) {
    let student;
    vm.assignment = assignment;
    vm.groupAssignment = vm.assignment.studentLesson.isGroup === true;
    vm.lesson = vm.assignment.studentLesson;
    vm.assignmentEditor = assignment.title;
    vm.userId = assignment.studentLesson.userId;
    vm.openAssignmentAccordion = emptyTextCheck(assignment.title);
    vm.openDailyNoteAccordion = emptyTextCheck(assignment.noteTitle);
    vm.openNotepadAccordion = emptyTextCheck(vm.lesson.notepad);

    vm.dailyNoteEditor = assignment.noteTitle;
    vm.notepadEditor = vm.lesson.notepad;
    vm.books = assignment.books.map((book) => {
      book.type = "book";
      return book;
    });
    // let studentId = _.isArray(vm.assignment.studentId) ? vm.assignment.studentId[0] : vm.assignment.studentId;
    vm.displayAssignmentType = vm.assignment.studentLesson.isGroup
      ? "Group Assignment"
      : "Single Assignment";
    student = studentService.getStudentsById(vm.assignment.studentId);

    if (student && student.length > 0) {
      student[0].entityId = vm.assignment.id;
      student[0].status = vm.assignment.status;
    }

    vm.media = vm.assignment.media;
    vm.modelName = "studentassignment";
    vm.averageCompletedAssignments =
      assignment.averageCompletedAssignments.toFixed(2);
    vm.totalAssignmentCount = assignment.totalAssignmentCount;
    vm.completedAssignmentsCount = assignment.totalCompletedAssignments;

    if (assignment.newResources) {
      vm.newResources = assignment.newResources.map((elem) => {
        return Object.assign({ type: "electronicMedia" }, elem);
      });

      vm.allResources = [...vm.books, ...vm.newResources];
    } else {
      vm.allResources = [...vm.books];
    }
    vm.openResourcesAccordion = vm.allResources.length ? true : false;
    vm.multipleFiles = {
      toUpload: [],
      uploaded: vm.media,
      toDelete: [],
    };
    vm.openFilesAccordion = vm.multipleFiles.uploaded.length ? true : false;
    /**
     * Check total students in the calendar
     */
    if (
      viewAssignmentParams.metadata &&
      viewAssignmentParams.metadata.lessonStatus == "multiple"
    ) {
      let studentList = viewAssignmentParams.metadata.entityIds,
        studentGrade = [],
        studentInfo = [];

      for (let i = 0; i < studentList.length; i++) {
        if (vm.assignment.studentId.indexOf(studentList[i].studentId) < 0) {
          let details = studentService.getStudentsById(
            studentList[i].studentId
          );

          if (details && details.length > 0) {
            let customValue = studentList[i].customValue;
            if (!customValue && studentList[i].grade) {
              customValue = studentList[i].grade.title;
            }
            studentGrade.push({
              studentId: studentList[i].studentId,
              studentInfo: details[0],
              assignmentId: studentList[i].entityId,
              grade: studentList[i].grade,
              obtainedMarks: studentList[i].obtainedMarks,
              totalMarks: studentList[i].totalMarks,
              percentage: studentList[i].percentage,
              customValue: customValue,
            });
            details[0].entityId = studentList[i].entityId;
            details[0].status = studentList[i].status;
            studentInfo.push(details[0]);
          }
        }
      }

      student = student.concat(studentInfo);
      vm.assignment.students = vm.assignment.students.concat(studentGrade);

      if (student.length) {
        vm.student = student;
      }

      vm.assignment.status = isAssignmentComplete()
        ? "complete"
        : "not started";
    }

    if (student.length) {
      vm.student = student;
    }

    if (!_.isEmpty(vm.assignment.students)) {
      _.map(vm.assignment.students, (student, index) => {
        let studentData = studentService.getStudentsById(student.studentId);
        studentData = _.isArray(studentData) ? studentData[0] : studentData;
        vm.assignment.students[index].studentInfo = studentData;
      });
    }

    vm.grade = vm.assignment.students ? vm.assignment.students : undefined;
    vm.name = vm.assignment.title;
    vm.assignment.title = $sce.trustAsHtml(vm.assignment.title);
    vm.assignment.noteTitle = $sce.trustAsHtml(vm.assignment.noteTitle);

    // Add customvalue to grade if there is no percentage and no custom value for old graded assignments

    if (vm.assignment.students && vm.assignment.students.length) {
      _.forEach(vm.assignment.students, function (student) {
        if (!student.customValue && student.grade && !student.percentage) {
          student.customValue = student.grade.title;
        }
        let checkStudent = _.find(
          vm.student,
          (obj) => obj.id === student.studentId
        );
        student.status = "not started";
        if (checkStudent) {
          student.status = checkStudent.status;
        }
        if (student.grade) {
          student.disabled = true;
        }
      });
    }

    setAssigmentDetails();
  }

  function checkDisabled(student) {
    if (student.disabled) {
      return true;
    } else {
      if ($rootScope.user.userRole == "STUDENT") {
        if (
          $rootScope.user.rolePermission.grade &&
          $rootScope.user.rolePermission.grade === "edit"
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  }

  function checkClearDisabled(student) {
    if ($rootScope.user.userRole == "STUDENT") {
      if (
        $rootScope.user.rolePermission.grade &&
        $rootScope.user.rolePermission.grade === "edit" &&
        student.disabled
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  function getStatusClass(status) {
    //getAssignmentDetails();
    return _.camelCase(status);
  }

  function getGrades() {
    return assignmentService
      .getGrades()
      .then((grades) => {
        vm.grades = grades;
      })
      .catch((error) => {
        vm.grades = [];
      });
  }

  function setAssigmentDetails() {
    if (
      moment(vm.assignment.startDate).format("HH:mm") === "00:00" &&
      moment(vm.assignment.endDate).format("HH:mm") === "23:59"
    ) {
      vm.allDayEvent = true;
    }

    /**
     * For the daytime saving: ends 4th November and starts on 10th March
     * Check the assignment created date before 4th november
     * And assignmen is after 4th November
     * In that case, all day is 11:00 to 10:59
     */
    let firstOccurrence = vm.assignment.studentLesson.startDate || null;

    if (
      firstOccurrence &&
      moment(firstOccurrence).isDST() &&
      !moment(vm.assignment.startDate).isDST()
    ) {
      vm.assignment.startDate = moment(vm.assignment.startDate).add(1, "hours");
      vm.assignment.endDate = moment(vm.assignment.endDate).add(1, "hours");

      if (
        moment(vm.assignment.startDate).format("HH:mm") == "00:00" &&
        moment(vm.assignment.endDate).format("HH:mm") == "23:59"
      ) {
        vm.allDayEvent = true;
      }
    } else if (
      firstOccurrence &&
      !moment(firstOccurrence).isDST() &&
      moment(vm.assignment.startDate).isDST()
    ) {
      vm.assignment.startDate = moment(vm.assignment.startDate).subtract(
        1,
        "hours"
      );
      vm.assignment.endDate = moment(vm.assignment.endDate).subtract(
        1,
        "hours"
      );
      if (
        moment(vm.assignment.startDate).format("HH:mm") == "00:00" &&
        moment(vm.assignment.endDate).format("HH:mm") == "23:59"
      ) {
        vm.allDayEvent = true;
      }
    }
    vm.assignmentInfo.lessonName = vm.assignment.subject;
    vm.assignmentDetails = [
      {
        label: "Lesson Name",
        value: vm.assignment.subject,
      },
      // { label: 'Assignment', value: vm.assignment.title || '-' }
    ];

    if (vm.assignment.courseName) {
      vm.assignmentInfo.course = vm.assignment.courseName;
      vm.assignmentDetails.push({
        label: "Course",
        value: vm.assignment.courseName,
      });
    }

    if (vm.lesson && vm.lesson.assignmentDays) {
      let assignmentMessage = assignmentService.getWeekdayString(
        vm.lesson.assignmentDays
      );

      if (assignmentMessage) {
        vm.assignmentInfo.days = assignmentMessage;
        vm.assignmentDetails.push({
          label: "Days",
          value: assignmentMessage,
        });
      }
    }

    if (vm.allDayEvent) {
      if (
        moment(vm.assignment.startDate).format("L") ==
        moment(vm.assignment.endDate).format("L")
      ) {
        vm.assignmentInfo.date =
          moment(vm.assignment.startDate).format("L") + " (All Day)";
        vm.assignmentDetails.push({
          label: "Date",
          value: moment(vm.assignment.startDate).format("L") + " (All Day)",
        });
      } else {
        vm.assignmentInfo.startDateAllDay =
          moment(vm.assignment.startDate).format("L") + " (All Day)";
        vm.assignmentInfo.endDateAllDay =
          moment(vm.assignment.endDate).format("L") + " (All Day)";
        vm.assignmentDetails.push({
          label: "Start",
          value: moment(vm.assignment.startDate).format("L") + " (All Day)",
        });
        vm.assignmentDetails.push({
          label: "End",
          value: moment(vm.assignment.endDate).format("L"),
        });
      }
    } else {
      vm.assignmentInfo.startDate = moment(vm.assignment.startDate).format(
        "LT, L"
      );
      vm.assignmentInfo.endDate = moment(vm.assignment.endDate).format("LT, L");
      vm.assignmentDetails.push({
        label: "Start",
        value: moment(vm.assignment.startDate).format("LT, L"),
      });
      vm.assignmentDetails.push({
        label: "End",
        value: moment(vm.assignment.endDate).format("LT, L"),
      });
    }

    // vm.assignmentDetails.push({ label: 'Students', value: studentName });
  }

  function getAssignmentprogress() {
    assignmentService
      .viewAssignment(viewAssignmentParams.assignmentId)
      .then((response) => {
        let assignment = response.data;
        vm.averageCompletedAssignments =
          assignment.averageCompletedAssignments.toFixed(2);
      })
      .catch((error) => {
        close();
        $state.go(
          "404",
          {},
          {
            location: "replace",
          }
        );
      })
      .finally(() => {
        vm.isFetchingData = false;
      });
  }

  function getAssignmentDetails() {
    vm.isFetchingData = true;
    assignmentService
      .viewAssignment(viewAssignmentParams.assignmentId)
      .then((response) => {
        setViewData(response.data);
        vm.isFetchingData = true;
      })
      .catch((error) => {
        close();
        $state.go("404", {}, { location: "replace" });
      })
      .finally(() => {
        vm.isFetchingData = false;
      });
  }

  function deleteAssignment(id) {
    let confirmData = {
      message:
        "Are you sure you want to delete <span class='confirm-text-style'>" +
        vm.assignment.subject +
        "<span> assignment ?",
      type: "danger",
      modalSize: "md",
      confirmLabel: "Yes",
    };

    commonService.confirm(confirmData).then(function (confirmation) {
      if (confirmation.result) {
        if (
          viewAssignmentParams.metadata.lessonStatus &&
          viewAssignmentParams.metadata.lessonStatus == "multiple"
        ) {
          let entityIds = _.map(
            viewAssignmentParams.metadata.entityIds,
            "entityId"
          );
          return assignmentService
            .deleteAssignment(entityIds)
            .then(deleteAssignmentSuccess)
            .catch(deleteAssignmentFailure);
        } else {
          return assignmentService
            .deleteAssignment(id)
            .then(deleteAssignmentSuccess)
            .catch(deleteAssignmentFailure);
        }
      }
    });
  }

  function deleteAssignmentSuccess(response) {
    // remove-reminder-notification
    let removeNotificationsCriteria = {
      entityId: vm.assignment.id,
      model: "studentAssignment",
    };

    $rootScope.$broadcast(
      "remove-reminder-notification",
      removeNotificationsCriteria
    );

    toastService.toast({
      message: lessonConstants.messages.deleteAssignmentSuccess,
      type: "success",
      delay: 5000,
    });
    close({
      removeEvent: true,
      search: viewAssignmentParams.search,
      deleteAssignmentId: vm.assignment.id,
    });
  }

  function deleteAssignmentFailure(error) {
    let message = angular.isDefined(error.data.message)
      ? error.data.message
      : "Some error occured, please try again";
    toastService.toast({
      message: message,
      type: "error",
      delay: 5000,
    });
  }

  function marksDefault(index) {
    vm.isMarksDefault = false;
    vm.isShowDefaultGrade = false;
    vm.gradeObtainedMarks = 0;
    vm.gradeTotalMarks = 0;
    vm.assignment.students[index].obtainedMarks = vm.gradeObtainedMarks;
    vm.assignment.students[index].totalMarks = parseInt(vm.gradeTotalMarks);
    vm.assignment.students[index].percentage = vm.gradeObtainedMarks;
  }

  function editGrade(type, value, index) {
    vm.isGradeAssign = true;
    if (!value) {
      vm.gradeFlag = false;
      vm.isGradeAssign = false;
    }

    if (type == "marks") {
      vm.gradeObtainedMarks = vm.assignment.students[index].obtainedMarks;
      vm.gradeTotalMarks = vm.assignment.students[index].totalMarks;
    }
    if (vm.copyIndex != index) {
      vm.percentageError = "";
      vm.resultType = type;
      vm.copyIndex = index;
      vm.isEdit = value;
      vm.copyStudents = JSON.parse(JSON.stringify(vm.assignment.students));
    } else {
      vm.percentageError = "";
      vm.isEdit = false;
      vm.copyIndex = null;
      vm.resultType = null;
      vm.assignment.students = JSON.parse(JSON.stringify(vm.copyStudents));
    }
  }

  function calculateCustomPercentage(index) {
    let customValue = vm.assignment.students[index].customValue;
    vm.gradeCustomError = false;
    vm.assignment.students[index].gradeCustomError = false;
    let numbersCheck = /^(?:\d*\.\d{1,2}|\d+)$/;
    if (customValue) {
      customValue = customValue.trim();
      customValue = customValue.replace(" ", "");
      if (customValue.includes("/")) {
        let splitCustomValue = customValue.split("/");
        if (splitCustomValue.length >= 2) {
          if (
            !isNaN(parseFloat(splitCustomValue[0])) &&
            !isNaN(parseFloat(splitCustomValue[1]))
          ) {
            let percentage =
              (parseFloat(splitCustomValue[0]) /
                parseFloat(splitCustomValue[1])) *
              100;
            vm.assignment.students[index].percentage = Math.round(
              parseFloat(percentage.toFixed(2))
            );
          } else {
            vm.gradeCustomError = true;
            vm.assignment.students[index].gradeCustomError = true;
          }
        } else {
          vm.gradeCustomError = true;
          vm.assignment.students[index].gradeCustomError = true;
        }
      } else if (customValue.includes("%")) {
        let countOccurence = customValue.split("%").length - 1;
        if (countOccurence > 1) {
          vm.gradeCustomError = true;
          vm.assignment.students[index].gradeCustomError = true;
          return;
        }
        let splitPercent = customValue.split("%");
        if (splitPercent.length) {
          let valueAfterReplace = customValue.replace(
            splitPercent[splitPercent.length - 1],
            ""
          );
          vm.assignment.students[index].customValue = valueAfterReplace;
          customValue = valueAfterReplace;
        }

        let valueAfterReplace = customValue.replace("%", "");
        if (!isNaN(parseFloat(valueAfterReplace))) {
          valueAfterReplace = parseFloat(valueAfterReplace);
          if (valueAfterReplace && isFinite(valueAfterReplace)) {
            vm.assignment.students[index].percentage = Math.round(
              parseFloat(valueAfterReplace.toFixed(2))
            );
          } else {
            vm.gradeCustomError = true;
            vm.assignment.students[index].gradeCustomError = true;
          }
        } else {
          vm.gradeCustomError = true;
          vm.assignment.students[index].gradeCustomError = true;
        }
      } else {
        let checkInGrades = _.find(vm.grades, function (grade) {
          if (grade.title.toLowerCase() === customValue.toLowerCase()) {
            return true;
          }
        });
        if (checkInGrades) {
          vm.assignment.students[index].percentage = checkInGrades.maxValue;
        } else {
          let customPercentage = parseFloat(customValue);

          if (
            customValue.match(numbersCheck) &&
            isFinite(customPercentage) &&
            !isNaN(customPercentage)
          ) {
            vm.assignment.students[index].percentage = Math.round(
              parseFloat(customPercentage.toFixed(2))
            );
          } else {
            vm.gradeCustomError = true;
            vm.assignment.students[index].gradeCustomError = true;
          }
        }
      }
      let checkGradeFound = false;
      _.forEach(vm.grades, (grade, n) => {
        if (
          vm.assignment.students[index].percentage >= grade.minValue &&
          vm.assignment.students[index].percentage <= grade.maxValue
        ) {
          vm.assignment.students[index].grade = grade;
          checkGradeFound = true;
        }
      });
      if (!checkGradeFound && vm.assignment.students[index].percentage > 100) {
        vm.assignment.students[index].grade = vm.grades[0];
      }
      let findFgrade = _.find(vm.grades, (obj) => obj.title === "F");

      if (customValue == "0" && findFgrade) {
        vm.assignment.students[index].grade = findFgrade;
        vm.assignment.students[index].percentage = 0;
      }

      vm.inputGradeCheck = true;
    } else {
      vm.inputGradeCheck = false;
      vm.assignment.students[index].grade = null;
      vm.assignment.students[index].percentage = null;
    }
  }

  function calculatePercentage(index) {
    vm.gradeFlag = false;
    if (vm.resultType == "percentage") {
      _.forEach(vm.grades, (grade, n) => {
        if (
          vm.assignment.students[index].percentage >= grade.minValue &&
          vm.assignment.students[index].percentage <= grade.maxValue
        ) {
          vm.assignment.students[index].grade = grade;
        }
      });
    } else if (vm.resultType == "marks") {
      vm.assignment.students[index].obtainedMarks = vm.gradeObtainedMarks;
      vm.assignment.students[index].totalMarks = parseInt(vm.gradeTotalMarks);

      if (vm.gradeObtainedMarks == 0 && vm.gradeTotalMarks == 0) {
        vm.gradeFlag = true;
      }
      let percentage = (vm.gradeObtainedMarks / vm.gradeTotalMarks) * 100;
      if (isFinite(percentage) && !isNaN(percentage)) {
        vm.assignment.students[index].percentage = Math.ceil(percentage);
        _.forEach(vm.grades, (grade, n) => {
          if (
            vm.assignment.students[index].percentage > grade.minValue - 1 &&
            vm.assignment.students[index].percentage <= grade.maxValue
          ) {
            vm.assignment.students[index].grade = grade;
          }
        });
      } else {
        vm.assignment.students[index].percentage = 0;
      }
    }
  }

  function addGrade(id, index) {
    let gradeId = null;
    _.forEach(vm.grades, (grade, n) => {
      if (
        vm.assignment.students[index].percentage > grade.minValue - 1 &&
        vm.assignment.students[index].percentage <= grade.maxValue
      ) {
        gradeId = grade.id;
      }
    });

    if (
      !gradeId ||
      (vm.resultType == "percentage" &&
        vm.assignment.students[index].percentage == 0) ||
      vm.assignment.students[index].percentage > 100 ||
      vm.gradeObtainedMarks > vm.gradeTotalMarks ||
      (vm.resultType == "marks" &&
        vm.gradeObtainedMarks == 0 &&
        vm.gradeTotalMarks == 0)
    ) {
      vm.percentageError = "Marks or percentage not correct";
      return null;
    }
    let data = {};
    if (vm.resultType == "marks") {
      data = {
        obtainedMarks: vm.gradeObtainedMarks,
        totalMarks: vm.gradeTotalMarks,
        percentage: vm.assignment.students[index].percentage,
        grade: gradeId,
        status: "complete",
      };

      vm.assignment.status = "complete";
    } else if ((vm.resultType = "percentage")) {
      // vm.assignment.students[index].percentage = vm.assignment.students[index].percentage;
      _.forEach(vm.grades, (grade, n) => {
        if (
          vm.assignment.students[index].percentage >= grade.minValue &&
          vm.assignment.students[index].percentage <= grade.maxValue
        ) {
          gradeId = grade.id;
        }
      });
      data = {
        percentage: vm.assignment.students[index].percentage,
        grade: gradeId,
        status: "complete",
        obtainedMarks: 0,
        totalMarks: 0,
      };

      vm.assignment.status = "complete";
    } else if (vm.resultType == "grade") {
      vm.isEdit = false;
      vm.copyIndex = index;
      vm.resultType = null;
      getAssignmentDetails();
      return false;
    } else if (vm.resultType == "custom") {
      _.forEach(vm.grades, (grade, n) => {
        if (
          vm.assignment.students[index].percentage >= grade.minValue &&
          vm.assignment.students[index].percentage <= grade.maxValue
        ) {
          gradeId = grade.id;
        }
      });
      data = {
        percentage: vm.assignment.students[index].percentage,
        grade: gradeId,
        status: "complete",
        customValue: vm.assignment.students[index].customValue,
        obtainedMarks: 0,
        totalMarks: 0,
      };
    }

    vm.statusChanged = true;

    assignmentService
      .addGrade(data, id)
      .then((response) => {
        vm.isEdit = false;
        vm.copyIndex = null;
        vm.resultType = null;
        vm.isGradeChanged = true;
        Store.set("assignmentOptions", JSON.stringify(vm.statusChanged));

        updateCalendarEvent();
        /**
         * Update for multiple students
         */
        updateMultipleStudent(data, index, id, response.data.data[0]);
        if (
          viewAssignmentParams &&
          viewAssignmentParams.metadata &&
          viewAssignmentParams.metadata.entityIds &&
          viewAssignmentParams.metadata.entityIds.length
        ) {
          let findStudentParamIndex = _.findIndex(
            viewAssignmentParams.metadata.entityIds,
            (obj) => obj.entityId == id
          );
          if (findStudentParamIndex !== -1) {
            viewAssignmentParams.metadata.entityIds[
              findStudentParamIndex
            ].status = "complete";
          }
        }

        getAssignmentDetails();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function addCustomGrade(id, index, type, grade) {
    let data = null;
    let gradeId = null;
    if (type === "custom") {
      if (
        !vm.assignment.students[index].customValue ||
        vm.assignment.students[index].gradeCustomError
      ) {
        return;
      }
      let checkGradeFound = false;
      _.forEach(vm.grades, (gradee, n) => {
        if (
          vm.assignment.students[index].percentage >= gradee.minValue &&
          vm.assignment.students[index].percentage <= gradee.maxValue
        ) {
          gradeId = gradee.id;
          grade = gradee;
          checkGradeFound = true;
        }
      });
      if (!checkGradeFound && vm.assignment.students[index].percentage > 100) {
        gradeId = vm.grades[0].id;
        grade = vm.grades[0];
      }

      let findFgrade = _.find(vm.grades, (obj) => obj.title === "F");

      if (vm.assignment.students[index].customValue == "0" && findFgrade) {
        vm.assignment.students[index].grade = findFgrade;
        vm.assignment.students[index].percentage = 0;
        gradeId = findFgrade.id;
        grade = findFgrade;
      }
    }
    if (type === "dropdown") {
      gradeId = grade.id;
      vm.assignment.students[index].percentage = grade.maxValue;
      vm.assignment.students[index].customValue = grade.title;
    }

    data = {
      percentage: vm.assignment.students[index].percentage,
      grade: gradeId,
      status: "complete",
      customValue: vm.assignment.students[index].customValue,
      obtainedMarks: 0,
      totalMarks: 0,
    };

    if (!gradeId) {
      toastService.toast({
        message: "Some error occured",
        type: "error",
        delay: 5000,
      });
    }

    vm.statusChanged = true;
    vm.gradeChecked = true;
    assignmentService
      .addGrade(data, id)
      .then((response) => {
        Store.set("assignmentOptions", JSON.stringify(vm.statusChanged));

        updateCalendarEvent();
        /**
         * Update for multiple students
         */
        updateMultipleStudent(data, index, id, response.data.data[0]);
        if (viewAssignmentParams && viewAssignmentParams.metadata) {
          //Update single student
          if (id == viewAssignmentParams.metadata.id) {
            viewAssignmentParams.metadata.status = "complete";
            viewAssignmentParams.metadata.grade = grade;
            viewAssignmentParams.metadata.customValue = data.customValue;
            viewAssignmentParams.metadata.percentage = data.percentage;
          }

          if (
            viewAssignmentParams.metadata.entityIds &&
            viewAssignmentParams.metadata.entityIds.length
          ) {
            let findStudentParamIndex = _.findIndex(
              viewAssignmentParams.metadata.entityIds,
              (obj) => obj.entityId == id
            );
            if (findStudentParamIndex !== -1) {
              viewAssignmentParams.metadata.entityIds[
                findStudentParamIndex
              ].status = "complete";
            }
          }
        }

        getAssignmentDetails();

        toastService.toast({
          message: "Grade successfully added",
          type: "success",
          delay: 5000,
        });

        if (vm.inputGradeCheck && vm.modalGradeClose) {
          close();
        }
        vm.inputGradeCheck = false;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function close_new() {
    // vm.gradeCheckedModal=true;
    // if(!vm.gradeChecked){
    // 	close();
    // }
    // else{
    // 	// wait for grading api to response;
    // 	$rootScope.$emit('triggerClick');
    // }
    // $rootScope.$emit('triggerModalClick');

    vm.modalGradeClose = true;

    if (!vm.inputGradeCheck) {
      close();
    } else {
      // wait
    }
  }

  $rootScope.$on("triggerModalClick", function () {
    // this will be executed when you trigger from finalController
    // InsertFunction code

    if (vm.gradeChecked) {
      close();
    }

    vm.triggerModalClick = true;
  });

  function updateMultipleStudent(data, index, entityId, responseData) {
    vm.assignment.students[index].grade = responseData.grade || data.grade;
    vm.assignment.students[index].percentage =
      responseData.percentage || data.percentage;
    vm.assignment.students[index].obtainedMarks =
      responseData.obtainedMarks || data.obtainedMarks;
    vm.assignment.students[index].totalMarks =
      responseData.totalMarks || data.totalMarks;
    vm.assignment.students[index].customValue =
      responseData.customValue || responseData.customValue;

    if (viewAssignmentParams.metadata.entityIds) {
      let studentList = viewAssignmentParams.metadata.entityIds;

      for (let i = 0; i < studentList.length; i++) {
        if (studentList[i].entityId == entityId) {
          studentList[i].grade = responseData.grade || data.grade;
          studentList[i].obtainedMarks =
            responseData.obtainedMarks || data.obtainedMarks;
          studentList[i].totalMarks =
            responseData.totalMarks || data.totalMarks;
          studentList[i].percentage =
            responseData.percentage || data.percentage;
          studentList[i].customValue =
            responseData.customValue || responseData.customValue;
          break;
        }
      }
    }
  }

  function deleteGrade(id, index) {
    if (!id) {
      return;
    }

    let confirmData = {
      message: lessonConstants.messages.deleteGradeConfirmation,
      type: "danger",
      modalSize: "md",
      prompt: false,
      promptMessage: lessonConstants.messages.deleteGradeConfirmation,
    };

    commonService.confirm(confirmData).then((confirmation) => {
      if (!confirmation.result) {
        return;
      }

      assignmentService
        .deleteGrade(id)
        .then(() => {
          vm.isEdit = false;
          vm.copyIndex = null;
          vm.resultType = null;
          vm.isGradeChanged = true;
          Store.set("assignmentOptions", JSON.stringify(vm.statusChanged));
          getAssignmentDetails();
        })
        .catch((err) => {
          console.log(err);
        });
    });

    // updateMultipleStudent(data, index, id, response.data.data[0]);
    // if(viewAssignmentParams && viewAssignmentParams.metadata && viewAssignmentParams.metadata.entityIds && viewAssignmentParams.metadata.entityIds.length) {
    // 	let findStudentParamIndex = _.findIndex(viewAssignmentParams.metadata.entityIds, obj => obj.entityId == id);
    // 	if (findStudentParamIndex !== -1) {
    // 		viewAssignmentParams.metadata.entityIds[findStudentParamIndex].status = "complete";
    // 	}
    // }
  }

  function filterEditorText(type, text) {
    if (type !== ".notepad") {
      var regex =
        /(https?:\/\/([-\w\.]+)+(:\d+)?(\/([\w\/\-_\.]*(\?\S+)?)?)?)/gi;
      var replaced_text = text.replace(
        regex,
        "<a href='$1' target='_blank'>$1</a>"
      );
      text = replaced_text;
    }
    return $sce.trustAsHtml(text);
  }

  function cancelEditor(type) {
    if (type === "assignment") {
      vm.showAssignmentEditor = false;
    } else if (type === "notepad") {
      vm.showNotepadEditor = false;
    } else {
      vm.showDailyNoteEditor = false;
    }
    vm.studentEdit = false;
  }

  function assignmentConfirmModalWindow(data) {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "md",
      controller: "EditAssignmentConfirmationController",
      controllerAs: "confirmAssignmtCtrl",
      resolve: {
        isGroupAssignment: () => vm.groupAssignment,
      },
      template:
        require("../../pug/calendar/edit-assignment-to-lesson-confirmation.pug")
          .default,
    });

    return modelInstance.result
      .then(function (option) {
        data.isLessonChange = option || "one";
        return Promise.resolve(true);
      })
      .catch(function () {
        modelInstance.close();

        return Promise.resolve(false);
      });
  }
  function checkUseRoleForConfirmationModal(assignmentData) {
    vm.studentEdit = true;
    return $q(function (resolve, reject) {
      assignmentData.isLessonChange = "one";
      resolve(true);
    });
  }
  function updatedAssignmentTitleAndNote(type, text, filteredText) {
    let assignmentData = {
      type: type,
      text: text,
      deviceType: "Web",
      lessonId: vm.assignment.studentLesson.lessonId,
      students: _.map(vm.assignment.students, (student) => {
        return {
          id: student.assignmentId,
          studentId: student.studentId,
        };
      }),
    };

    let confirmationModalPromise;
    if ($rootScope.user.userRole === "STUDENT") {
      confirmationModalPromise =
        checkUseRoleForConfirmationModal(assignmentData);
    } else {
      confirmationModalPromise = assignmentConfirmModalWindow(assignmentData);
    }
    // assignmentConfirmModalWindow(assignmentData)
    confirmationModalPromise
      .then((modelConfimation) => {
        if (modelConfimation || vm.studentEdit) {
          return assignmentService.assignmentTitleAndDailyNote(assignmentData);
        } else {
          if (type === ".gradingEnable") {
            vm.assignment.isGradingEnable = !vm.assignment.isGradingEnable;
          }
          return cancelEditor(assignmentData.type);
        }
      })
      .then((response) => {
        if (response) {
          if (response.data.data.isLessonChange === "all") {
            vm.UpdateTitleForAll = {
              entityIds: response.data.data.studentAssignments.map(
                (element) => {
                  return element.id;
                }
              ),
            };
          }

          if (type === ".assignmentTitle") {
            vm.assignmentEditor = $(type).trumbowyg("html");
            vm.assignment.title = filteredText;
            vm.updatedAssignmentTitle = text
              .replace(/(\s*<.*?>\s*)+/g, " ")
              .trim();
            vm.showAssignmentEditor = false;
          } else if (type === ".gradingEnable") {
            viewAssignmentParams.metadata.isGradingEnable = vm.assignment
              .isGradingEnable
              ? 1
              : 0;
            if (response.data.data.isLessonChange === "all") {
              vm.isGradeChangedAll = true;
            } else {
              vm.isGradeChanged = true;
            }
          } else {
            vm.dailyNoteEditor = $(type).trumbowyg("html");
            vm.assignment.noteTitle = filteredText;
            vm.showDailyNoteEditor = false;
          }
          toastService.toast({
            message: "Updated Successfully",
            type: "success",
            delay: 2000,
          });
        }
      })
      .catch((err) => {
        toastService.toast({
          message: "Update failed",
          type: "error",
          delay: 2000,
        });
      });
  }

  function saveEditor(type) {
    let text = $(type).trumbowyg("html").toString();
    let filteredText = filterEditorText(type, text);
    if (type === ".assignmentTitle" || type === ".dailyNoteTitle") {
      updatedAssignmentTitleAndNote(type, text, filteredText);
    }
    if (type === ".gradingEnable") {
      updatedAssignmentTitleAndNote(type, vm.assignment.isGradingEnable, "");
    }
    if (type === ".notepad") {
      let notepadData = {};
      if (
        viewAssignmentParams.metadata.entityIds &&
        viewAssignmentParams.metadata.lessonStatus
      ) {
        notepadData = {
          studentLessonPlanIds: _.map(
            viewAssignmentParams.metadata.entityIds,
            (elem) => elem.studentLessonId
          ),
          text: text,
          lessonId: vm.assignment.studentLesson.lessonId,
        };
      } else {
        notepadData = {
          studentLessonPlanIds: [viewAssignmentParams.metadata.lessonId],
          text: text,
          lessonId: vm.assignment.studentLesson.lessonId,
        };
      }

      let confirmationModalPromise;
      if ($rootScope.user.userRole === "STUDENT") {
        confirmationModalPromise =
          checkUseRoleForConfirmationModal(notepadData);
      } else {
        confirmationModalPromise = assignmentConfirmModalWindow(notepadData);
      }
      confirmationModalPromise
        .then((modelConfimation) => {
          if (modelConfimation) {
            return lessonService.editNotepad(notepadData);
          } else {
            return cancelEditor("notepad");
          }
        })
        .then((res) => {
          if (type === ".notepad" && res) {
            vm.notepadEditor = $(type).trumbowyg("html");
            vm.lesson.notepad = filteredText;
            vm.showNotepadEditor = false;
            toastService.toast({
              message: "Updated Successfully",
              type: "success",
              delay: 2000,
            });
          }
        })
        .catch((err) => {
          toastService.toast({
            message: "operation failed",
            type: "error",
            delay: 2000,
          });
        });
    }
  }

  function unassignElectronicMedia(data, resourceIndex) {
    if ($rootScope.user.userRole == "STUDENT") {
      return;
    }
    let confirmData = {
      message:
        "Are you sure you want to delete this electronic media resource?",
      type: "danger",
      modalSize: "md",
      confirmLabel: "Yes",
    };
    commonService.confirm(confirmData).then(function (confirmation) {
      if (confirmation.result) {
        let studentIds = vm.assignment.students.map((student) => {
          return student.assignmentId;
        });
        let payload = {
          studentIds,
          name: data.name,
        };
        return assignmentService
          .deleteElectronicMedia(payload)
          .then((response) => {
            let index = _.findIndex(vm.newResources, {
              id: data.id,
            });
            if (index > -1) {
              vm.newResources.splice(index, 1);
            }
            let filteredResources = vm.allResources.filter(
              (book, index) => index !== resourceIndex
            );
            vm.allResources = [...filteredResources];
            toastService.toast({
              message: "resource deleted successfully",
              type: "success",
              delay: 2000,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        return;
      }
    });
  }

  function showAngularAccordion(type) {
    switch (type) {
      case "title":
        vm.openAssignmentAccordion = !vm.openAssignmentAccordion;
        vm.showAssignmentEditor = false;
        break;
      case "dailyNote":
        vm.openDailyNoteAccordion = !vm.openDailyNoteAccordion;
        vm.showDailyNoteEditor = false;
        break;
      case "notepad":
        vm.openNotepadAccordion = !vm.openNotepadAccordion;
        vm.showNotepadEditor = false;
        break;
      case "resources":
        vm.openResourcesAccordion = !vm.openResourcesAccordion;
        break;
      case "files":
        vm.openFilesAccordion = !vm.openFilesAccordion;
        break;
      default:
        return;
    }
  }

  function elementDargStart(element) {
    switch (element.template) {
      case "assignment-title":
        vm.showAssignmentEditor = false;
        break;
      case "daily-note":
        vm.showDailyNoteEditor = false;
        break;
      case "assignment-notepad":
        vm.showNotepadEditor = false;
        break;
      default:
        return;
    }
  }

  function updateDragList(index) {
    vm.elements.splice(index, 1);
    let mappedElements = vm.elements.map((element) => {
      return {
        template: element.template,
      };
    });
    let dragAndDropData = {
      dragOrder: JSON.stringify(mappedElements),
    };
    assignmentService
      .dragOrder(dragAndDropData)
      .then((response) => {
        $rootScope.user.dragList = response.data.data;
        toastService.toast({
          message: "Assignment view updated",
          type: "success",
          delay: 2000,
        });
      })
      .catch((err) => {
        console.log("err", err);
        toastService.toast({
          message: "operation failed",
          type: "error",
          delay: 2000,
        });
      });
  }

  // async function initialData() {
  //   vm.isFetchingData = true;
  //   try {
  //     let time = moment();
  //     const response = await Promise.all([
  //       assignmentService.viewAssignment(viewAssignmentParams.assignmentId),
  //       assignmentService.getGrades(),
  //     ]);
  //     console.log(response[1]);
  //     vm.grades = response[1];
  //     setViewData(response[0].data);
  //     vm.isFetchingData = false;
  //     console.log(vm.isFetchingData);
  //     console.log(moment().diff(time, "s"));
  //   } catch (err) {
  //     console.log(err);
  //     vm.isFetchingData = false;
  //     console.log(vm.isFetchingData);
  //     close();
  //   }
  // }

  function init() {
    setTimeout(() => {
      let modalContainer = document.getElementsByClassName("modal-content");
      modalContainer[0].style.padding = "0 1px 15px 1px";
    }, 200);
    vm.elements = $rootScope.user.dragList
      ? $rootScope.user.dragList.dragOrder
      : [
          {
            template: "assignment-title",
          },
          {
            template: "daily-note",
          },
          {
            template: "assignment-notepad",
          },
          {
            template: "assignment-resources",
          },
          {
            template: "assignment-files",
          },
        ];

    if (
      viewAssignmentParams &&
      viewAssignmentParams.metadata &&
      viewAssignmentParams.metadata.lessonStatus == "multiple" &&
      viewAssignmentParams.metadata.entityIds
    ) {
      if (viewAssignmentParams.metadata.entityIds.length) {
        let entityIds = _.map(
          viewAssignmentParams.metadata.entityIds,
          "entityId"
        );
        entityIds = _.filter(
          entityIds,
          (obj) => obj != viewAssignmentParams.assignmentId
        );
        vm.assignmentIds = entityIds;
      }
    }
    console.log(viewAssignmentParams.metadata);
    getAssignmentDetails();
    getGrades();
  }
  this.$onInit = init;
}
export default assignmentController;
