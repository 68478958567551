let angular = window.angular;

    bookSearchController.$inject = ['toastService', 'bookConstants', '$uibModal', '$scope', '$http', '$rootScope', 'bookService', 'commonService'];

    function bookSearchController(toastService, bookConstants, $uibModal, $scope, $http, $rootScope, bookService, commonService) {

        let vm = this;
        let KEY = 'AIzaSyDVLpO_0FWnLWeM7X0kchNrjeVk3fz2gA8'; // Google  api key
        let fixed = '&key=' + KEY + '&maxResults=40';
        $scope.todos = [];
        $scope.books = null;
        vm.options = bookConstants.bookSearchOptions;
        vm.type = null;
        vm.data = null;
        vm.activeTab = 'assigned-book';
        vm.isFetchingData = false;
        vm.loadMoreCount = 2;
        vm.loadMoreBookStatus = false;
        vm.loadMoreFetchingData = false;
        vm.isFoundData = true;
        let book;

        this.$onInit = function() {
            vm.activeTab = 'explore';
        }

        angular.extend(vm, {
            isTouched,
            toggleTab,
            viewBook,
            close,
            search,
            addBook,
            removeBook,
            getTodos,
            loadMoreBooks,
            edit,
            view,
            deleteBook
        });

        function isTouched(field) {
            if (angular.isDefined(field)) {
                return field.$touched || vm.form.$submitted;
            } else {
                return false;
            }
        }

        function close() {
            $uibModalInstance.close(true);
        }

        function addBook(book) {
            let modelInstance = $uibModal.open({
                animation: true,
                size: 'lg',
                controller: 'bookEditController',
                controllerAs: 'bookEditCtrl',
                template:require('../../pug/book/edit-book.pug').default,
                resolve: {
                    bookData: {
                        book,
                        addGlobally: true
                    }
                }
            });

            modelInstance.result.then(afterAddBook).catch(function() {
                modelInstance.close();
            });
        }

        function viewBook(book) {
            let modelInstance = $uibModal.open({
                animation: true,
                size: 'lg',
                controller: 'bookDetailController',
                controllerAs: 'bookDetailCtrl',
                template:require('../../pug/book/view-book.pug').default,
                resolve: {
                    bookData: {
                        book: book,
                        // model: 'search'
                    }
                }
            });

            modelInstance.result.then(afterAddBook).catch(function() {
                modelInstance.close();
            });
        }

        function afterAddBook(options) {
            let addedBookId = options.book.bookId;
            let existingBookIndex = _.findIndex(vm.books, { bookId: addedBookId });

            if (existingBookIndex > -1) {
                vm.books[existingBookIndex] = options.book;
                vm.books[existingBookIndex].assignStudents = options.studentsAssigned || [];
                if (options.removeEvent) {
                    vm.books.splice(existingBookIndex, 1);
                    // vm.books[existingBookIndex].id = undefined;
                }

            }

        }


        function deleteBook(book, bookId) {
            if (angular.isDefined(book.assignments) && book.assignments.length) {
                let modelInstance = $uibModal.open({
                    animation: true,
                    size: 'md',
                    controller: 'confirmationUnassignEntireBookModalController',
                    controllerAs: 'confirmationUnassignEntireBookModalCtrl',
                    template:require('../../pug/book/unassign-book-confirmation.pug').default,
                    resolve: {
                        bookData: {
                            assignment: {
                                assignId: bookId
                            }
                        }
                    }
                });
                modelInstance.result
                    .then(function(options) {
                        afterView(options);
                    })
                    .catch(function() {
                        modelInstance.close();
                    });
            } else {
                commonService.confirm({
                        message: bookConstants.messages.deleteBookConfirmation,
                        type: 'danger',
                        modalSize: "md"
                    })
                    .then((confirmation) => {
                        if (confirmation.result === true) {
                            bookService.deleteBook(bookId, false)
                                .then((deleted) => {
                                    toastService.toast({
                                        message: bookConstants.messages.bookDeleted,
                                        type: 'success',
                                        delay: 3000
                                    });

                                    let removeBookIndex = _.findIndex(vm.books, { id: bookId })
                                    if (removeBookIndex > -1) {
                                        // vm.books[removeBookIndex].id = undefined;
                                        vm.books.splice(removeBookIndex, 1);
                                    }
                                })
                                .catch((err) => {
                                    // console.log("errror", err);
                                });
                        } else return;
                    })
                    .catch((err) => {
                        console.log("errror", err);
                    });
            }
        }

        function view(book, activeTab) {
            let bookId = book.id;
            if (!angular.isDefined(bookId) || !bookId) {

                viewBook(book);

            } else {
                let modelInstance = $uibModal.open({
                    animation: true,
                    size: 'lg',
                    controller: 'bookAssignController',
                    controllerAs: 'bookAssignCtrl',
                    template:require('../../pug/book/assign-book.pug').default,
                    resolve: {
                        bookData: {
                            bookId,
                            book,
                            activeTab: activeTab
                        }
                    }
                });

                modelInstance.result.then(afterView).catch(function() {
                    modelInstance.close();
                });
            }

        }

        function afterView(options) {

            if (options) {
                if (!options.removeEvent) {
                    if (options.isEditModal) {
                        let bookUpdateIndex = _.findIndex(vm.books, { bookId: options.book.bookId });
                        /**
                         * update already added book in the book list
                         */
                        if (bookUpdateIndex > -1) {
                            vm.books[bookUpdateIndex] = options.book;
                            vm.books[bookUpdateIndex].assignStudents = options.studentsAssigned || [];
                        }
                        /**
                         * add a new book in the book list
                         */
                        else {
                            vm.books.push(options.book);
                        }
                    }
                    /**
                     * update only students review data
                     */
                    else {
                        /**
                         * replace older reviews with updated ones
                         */
                        let updatedReviewBookIndex = _.findIndex(vm.books, { id: options.bookId });
                        if (updatedReviewBookIndex > -1) {
                            vm.books[updatedReviewBookIndex].assignStudents = options.studentsAssigned;
                        }
                    }
                } else {
                    let removeBookId = options.bookData.bookId;
                    let removeBookIndex = _.findIndex(vm.books, { id: removeBookId });
                    if (removeBookIndex > -1) {
                        vm.books.splice(removeBookIndex, 1);
                        // vm.books[removeBookIndex].id = undefined;
                    }
                }
            }
        }

        function toggleTab(tab) {
            vm.activeTab = tab;
        }

        function search(queryParam) {
            let skip;

            if (!vm.data) {
                vm.isFetchingData = false;
                toastService.toast({
                    message: 'Please enter more than two characters to find books',
                    type: 'error',
                    delay: 3000
                });

                return false;
            }

            vm.disableSearch = {
                'disable': true
            }

            if (!queryParam) {
                skip = 0;
                vm.books = [];
            } else {
                skip = queryParam.skip;
            }

            let query = { searchBy: vm.type.title, values: vm.data, skip: skip };
            //console.log("search query", query)
            if (!vm.isFetchingData) {
                vm.isFetchingData = true;
                bookService.search(query)
                    .then((resp) => {
                        if (!resp.data) {

                            toastService.toast({
                                message: 'There are no books under this criteria',
                                type: 'error',
                                delay: 3000
                            });
                            vm.isFoundData = false;
                            vm.loadMoreFetchingData = false;
                            vm.isFetchingData = false;
                            return false;
                        }

                        if (resp.data.data.books.length) {
                            _.map(resp.data.data.books, function(book) {
                                vm.books.push(book);
								vm.books=_.uniqBy(vm.books, 'bookId')
                            })
                        } else {
                            toastService.toast({
                                message: 'There are no books under this criteria',
                                type: 'error',
                                delay: 3000
                            });
                            vm.isFoundData = false;
                        }

                        vm.loadMoreBookStatus = resp.data.data.isLoadMore;

                    })
                    .catch((er) => {
                        console.log(er);
                    })
                    .finally(() => {
                        vm.loadMoreFetchingData = false;
                        vm.isFetchingData = false;
                        vm.disableSearch = {
                            'disable': false
                        }
                    });
            }

        }

        function edit(book) {
            let modelInstance = $uibModal.open({
                animation: true,
                size: 'lg',
                controller: 'bookEditController',
                controllerAs: 'bookEditCtrl',
                template:require('../../pug/book/edit-book.pug').default,
                resolve: {
                    bookData: {
                        book
                    }
                }
            });
            modelInstance.result.then(afterView).catch(function() {
                modelInstance.close();
            });
        }

        function loadMoreBooks() {
            let queryParam;
            vm.loadMoreFetchingData = true;
            if (!vm.books.length) {
                queryParam = { skip: 0 };
            } else {
                queryParam = { skip: vm.books.length };
            }

            search(queryParam);
        }

        function getTodos() {
            let getPromise;
            bookService.getTodos().then(function(response) {
                vm.saved_books = response.data;
            });
        }

        //  function addBook (book) {
        //     bookService.addBook(book)
        //         .then(function(response) {
        //             // $scope.todos.push($scope.formData)
        //             $scope.formData = {};
        //             $scope.todos = response;
        //             toastService.toast({
        //                 message: bookConstants.messages.bookAdded,
        //                 type: 'success',
        //                 delay: 3000
        //             });
        //             console.log(response);
        //         })
        //         .catch((err)=>{
        //             toastService.toast({
        //                 message: bookConstants.messages.bookAlready,
        //                 type: 'success',
        //                 delay: 3000
        //             });
        //         });
        //   }

        function removeBook(todo) {
            bookService.removeBook(todo).then(function(response) {
                $scope.todos.splice($scope.todos.indexOf(todo), 1)
            });
        }
    }
export default bookSearchController;
