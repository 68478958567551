let angular = window.angular;

studentAddController.$inject = [
  "$rootScope",
  "$scope",
  "commonService",
  "$state",
  "studentService",
  "fileService",
  "$q",
  "moment",
  "toastService",
  "appConstants",
  "studentConstants",
  "_",
];

function studentAddController(
  $rootScope,
  $scope,
  commonService,
  $state,
  studentService,
  fileService,
  $q,
  moment,
  toastService,
  appConstants,
  studentConstants,
  _
) {
  let vm = this;

  vm.dateOptions = {
    formatYear: "yy",
    maxDate: new Date(),
    startingDay: 1,
    showWeeks: false,
  };
  vm.popup1 = {
    opened: false,
  };
  vm.open1 = function () {
    vm.popup1.opened = true;
  };

  let sortedAcademicYearArray = [];
  vm.student = {
    countryCode: 1,
    parentCountryCode: 1,
    academicDays: 180,
  };
  vm.addStudentId;
  vm.defaultImage = appConstants.defaultAvatar;
  vm.colors = appConstants.newColors;
  vm.submitClicked = false;
  vm.student.academics = [
    {
      academicYearStart: "",
      academicYearEnd: "",
      grade: "",
      academicDays: 180,
      //'goals': '',
      //'reflections': '',
      semesters: [],
    },
  ];
  let newStudent = {};

  vm.addAcademicDetails = function () {
    vm.student.academics.push({
      academicYearStart: "",
      academicYearEnd: "",
      grade: "",
      academicDays: "",
      //'goals': '',
      //'reflections': ''
      semesters: [],
    });
  };

  vm.checkMultigrade = function ($index) {
    let multiGrade = false;
    vm.gradeCheck = {};
    for (let i = 0; i < vm.student.academics.length; i++) {
      let grd = vm.student.academics[i].grade;
      if (grd && grd in vm.gradeCheck) {
        multiGrade = true;
        break;
      } else {
        if (grd !== "Grade" || grd || grd !== null) {
          vm.gradeCheck[grd] = i;
        }
      }
    }
    if (multiGrade) {
      vm.student.academics[$index].invalidGrade = true;
      toastService.toast({
        message: studentConstants.messages.multiGradeError,
        type: "error",
        delay: 2000,
      });
    } else {
      vm.student.academics[$index].invalidGrade = false;
    }
    return;
  };

  vm.removeAcademicDetails = function ($index) {
    let index = commonService.convertToInteger($index);
    if (index < 0) {
      return false;
    }
    if (vm.student.academics.length <= 1) {
      toastService.toast({
        message: studentConstants.messages.academicYearRequire,
        type: "error",
        delay: 2000,
      });
      return;
    }

    let confirmData = {
      message: studentConstants.messages.deleteAcademicDetailsWarning,
      modalSize: "md",
      type: "danger",
    };

    commonService.confirm(confirmData).then(function (confirmation) {
      if (confirmation.result) {
        vm.student.academics.splice(index, 1);
      }
    });
  };

  vm.checkAcademicYearInteraction = function ($index) {
    sortedAcademicYearArray = commonService.sortDateByStartDate(
      vm.student.academics
    );

    let isIntersected = false,
      exitFunc = false;
    for (let i = 1; i < sortedAcademicYearArray.length; i++) {
      let currentStartDate = sortedAcademicYearArray[i].academicYearStart;
      let previousEndDate = sortedAcademicYearArray[i - 1].academicYearEnd;
      // if(!currentStartDate || !previousEndDate){
      //     return;
      // }
      if (currentStartDate < previousEndDate) {
        isIntersected = true;
        break;
      }
    }

    if (isIntersected) {
      vm.student.academics[$index].invalid = true;
      toastService.toast({
        message: studentConstants.messages.academicYearIntersection,
        type: "error",
        delay: 2000,
      });
    } else {
      vm.student.academics[$index].invalid = false;
    }

    return;
  };

  vm.addStudent = function (student) {
    // if(vm.student.dob) {
    // 	vm.student.dob = vm.student.dob.toISOString();
    // }
    // console.log("student",vm.student)
    let exitFunc = false,
      multiGrade = false,
      dobCheck = false;
    /**
     * Validate Student info
     */
    if (!vm.student.firstName || !vm.student.lastName) {
      vm.show = true;
      exitFunc = true;
      return;
    }
    /**
     * Validate academic deatails info
     */
    let studentAcademicsArray = vm.student.academics,
      academicsValidationArray = [
        "grade",
        "academicYearStart",
        "academicYearEnd",
        "academicDays",
      ];
    //console.log("hello", studentAcademicsArray);

    _.forEach(vm.student.academics, function (value, key) {
      _.forEach(academicsValidationArray, (academicValue) => {
        let value = studentAcademicsArray[key][academicValue];

        if (value === "" || value === null || !value) {
          vm.show = true;
          toastService.toast({
            message: "Please complete the Academic Details section.",
            type: "error",
            delay: 2000,
          });

          exitFunc = true;
          return false;
        } else {
          vm.show = false;
        }
        // else {
        // if(vm.gradeCheck[value] > 1){
        //     multiGrade = true;
        // } else{
        //     vm.gradeCheck[value]++;
        // }
        // }
      });
    });

    vm.gradeCheck = {};

    // Check if dob is null ..
    if (!vm.student.dob || vm.student.dob === null) {
      delete vm.student.dob;
    } else {
      for (let i = 0; i < vm.student.academics.length; i++) {
        if (
          moment(vm.student.academics[i].academicYearStart).isBefore(
            moment(vm.student.dob)
          )
        ) {
          dobCheck = true;
          break;
        }
      }
    }

    if (dobCheck) {
      toastService.toast({
        message: studentConstants.messages.dobWarning,
        type: "error",
        delay: 2000,
      });
      exitFunc = true;
    }

    if ((!vm.student.dob === "" && (exitFunc || !vm.form.$valid)) || vm.show) {
      return;
    }

    for (let i = 0; i < vm.student.academics.length; i++) {
      if (!studentService.validateSemesterDates(vm.student.academics[i])) {
        return toastService.toast({
          message: "Invalid Semester Dates",
          type: "error",
          delay: 2000,
        });
      }
    }

    $rootScope.loadingBtn("save", "loading");
    vm.submitClicked = true;

    let data = {};
    data = _.clone(vm.student);

    //Check if country-code and number both are set.
    if (!data.countryCode || !data.contactNumber) {
      data.countryCode = 0;
      data.contactNumber = null;
    }

    if (!data.parentCountryCode || !data.parentContactNumber) {
      data.parentCountryCode = 0;
      data.parentContactNumber = null;
    }
    studentService
      .addStudent(data)
      .then(addStudentSuccess)
      .then(fileUploadResponse)
      .catch(function (error) {
        console.error(error);
        toastService.toast({
          message: error.data.message || "Please fill the form and try again",
          type: "error",
          delay: 2000,
        });
        $rootScope.loadingBtnComplete("save");
      })
      .finally(() => {
        $rootScope.loadingBtnComplete("save", "Save");
      });
  };

  vm.setStudentLabelColor = function (color) {
    vm.student.color = color;
  };

  vm.selectStudentPic = function (media) {
    return fileService
      .imageUploadWithCrop(media)
      .then(selectStudentPicSuccess)
      .catch(selectStudentPicError);

    function selectStudentPicSuccess(response) {
      if (response.file) {
        vm.studentImageFile = response.file;
      }
    }

    function selectStudentPicError(error) {
      // console.log('Closed modal', error);
    }
  };

  function addStudentSuccess(response) {
    let deferred = $q.defer();

    studentService.students.push(response.data.data.Student);
    newStudent = response.data.data.Student;
    vm.addStudentId = newStudent.id;
    // Upload Image, if selected by User
    if (
      _.isObject(vm.studentImageFile) &&
      _.isString(vm.studentImageFile.name) &&
      vm.studentImageFile.size > 0
    ) {
      $rootScope.loadingBtn("save", "Uploading Profile Picture");
      return fileService.uploadFile(
        vm.studentImageFile,
        newStudent.id,
        "student"
      );
    } else {
      deferred.resolve();
    }
    toastService.toast({
      message: "Student added",
      type: "success",
      delay: 3000,
    });

    return deferred.promise;
  }

  function fileUploadResponse(response) {
    updatemediaUrl(response);
    $state.go("studentInfo", { studentId: newStudent.id });
  }

  function updatemediaUrl(response) {
    let index = studentService.students
      .map((student) => student.id)
      .indexOf(parseInt(vm.addStudentId));
    if (index && index >= 0) {
      studentService.students[index].name =
        studentService.students[index].firstName +
        " " +
        studentService.students[index].lastName;
      if (response) {
        studentService.students[index].mediaUrl =
          response.data.data[0].mediaUrl;
      } else {
        studentService.students[index].mediaUrl = undefined;
      }
    }
  }

  vm.addSemesters = function (index) {
    console.log(vm.form);
    const copy = angular.copy(vm.student.academics);
    vm.student.academics = copy.map((academic, i) => {
      if (i === index && academic.semesters.length < 4) {
        academic.semesters.push({
          semesterStart: "",
          semesterEnd: "",
        });
      }
      return academic;
    });
  };
  vm.removeSemester = function (index, upperIndex) {
    const copy = angular.copy(vm.student.academics);
    vm.student.academics = copy.map((academic, i) => {
      if (i === upperIndex) {
        academic.semesters = academic.semesters.filter((el, I) => I !== index);
      }
      return academic;
    });
  };

  vm.checkSemesterInteraction = function (semesterIndex, academicIndex) {
    vm.form.semesterStart.$invalid = true;
    const semester =
      vm.student.academics[academicIndex].semesters[semesterIndex];

    if (!semester.semesterStart || !semester.semesterEnd) {
      return;
    }
    if (semester.semesterStart > semester.semesterEnd) {
      vm.student.academics[academicIndex].semesters[
        semesterIndex
      ].invalid = false;
    }
  };
  /**
   * If a state change is detected, when the task controller is active, then it is checked whether the modal window is open, if yes then it is closed
   */
  $scope.$on(
    "$stateChangeStart",
    function (evt, toState, toParams, fromState, fromParams) {
      // console.log("args",arguments);
      let isFillingForm = false;

      // Check if the form has some data
      for (let i in vm.student) {
        if (vm.student[i] && vm.form[i] && !vm.form[i].$pristine) {
          isFillingForm = true;
          break;
        }
      }
      if (isFillingForm && !vm.submitClicked) {
        evt.preventDefault();
        $rootScope.isLoading = false;
        let confirmData = {
          message: studentConstants.messages.leavePageConfirmation,
          modalSize: "md",
          type: "danger",
        };

        commonService.confirm(confirmData).then(function (confirmation) {
          if (confirmation.result) {
            vm.submitClicked = true;
            $state.go(toState, toParams);
          }
        });
      }
    }
  );
}

export default studentAddController;
