let angular = window.angular;

import budgetConstants from "./budgetConstants";
import budgetService from "./budgetService";
import customAutofocus from "./customAutofocusDirective";
import dashboardController from "./dashboardController";
import gradebookModalController from "./gradebookModalController";
import todoController from "./todoController";

export const DASHBOARD_MODULE = angular.module("dashboard", []);

DASHBOARD_MODULE.directive("customAutofocus", customAutofocus);
DASHBOARD_MODULE.service("budgetService", budgetService);

DASHBOARD_MODULE.controller("dashboardController", dashboardController);
DASHBOARD_MODULE.controller(
  "gradebookModalController",
  gradebookModalController
);
DASHBOARD_MODULE.controller("todoController", todoController);
DASHBOARD_MODULE.constant("budgetConstants", budgetConstants);

DASHBOARD_MODULE.filter("titleCase", function () {
  return function (input) {
    input = input || "";
    return input
      .split(" ")
      .map((word, i) => {
        if (i == 0) {
          return word;
        } else {
          return word.charAt(0).toUpperCase() + word.substr(1);
        }
      })
      .join("");
  };
});

DASHBOARD_MODULE.filter("titleCase", function () {
  return function (input) {
    input = input || "";
    return input
      .split(" ")
      .map((word, i) => {
        if (i == 0) {
          return word;
        } else {
          return word.charAt(0).toUpperCase() + word.substr(1);
        }
      })
      .join("");
  };
});
DASHBOARD_MODULE.constant("dashboardConstants", {
  chatLimit: 4,
  agendaLimit: 4,
  taskLimit: 20,
  assignmentLimit: 4,
  widgets: [
    {
      template: "agenda-card",
      displayName: "Agenda",
    },
    {
      template: "chore-card",
      displayName: "Chore List",
    },
    {
      template: "budget-card",
      displayName: "Budget & Expenses",
    },
    {
      template: "collaborate-card",
      displayName: "Panda Messenger",
    },
    {
      template: "todo-card",
      displayName: "Task List",
    },
    //  {
    //     template: "overdue-card",
    //     displayName: "Overdue Assignments"
    // },
    // {
    //     template: "gradebook-card",
    //     displayName: "Gradebook"
    // }
  ],
  content: {
    welcome: "Welcome to Homeschool Panda.",
    sidenav:
      "This is main navigation bar. Click the three lines to expand or collapse based on your viewing preferences.",
    agendaCard:
      "The “Agenda” is a snapshot of your day. You can view your appointments and assigned lesson plans. You can also use the “arrows” to navigate to different days for convenience.",
    budgetCard:
      "Create a budget and start tracking and managing your expenses.",
    collaborateCard:
      'Connect and start collaborating with other <abbr title="Homeschool Panda">HSP</abbr> users through the Panda Messenger.',
    todoCard:
      "Create your personalized “To Do” list, manage your tasks and check off items as they are completed.",
    gradeBookCard: "This is the gradebook.",
  },

  messages: {
    category: {
      categoryCreate: "Category created.",
      categoryUpdated: "Category title updated.",
      categoryNotUpdated: "Category not updated.",
      deleteCategoryConfirmation: "Delete selected category?",
    },
    todo: {
      todoCreate: "Task created.",
      todoUpdated: "Task updated.",
      todoNotUpdated: "Task not updated.",
      deleteToDoConfirmation: "Are you sure you want to delete this task item.",
    },
  },
});
