let angular = window.angular;
editAssignmentController.$inject = [
  "$scope",
  "$rootScope",
  "$uibModalInstance",
  "assignmentService",
  "editAssignmentParams",
  "$state",
  "studentService",
  "calendarService",
  "$uibModal",
  "commonService",
  "calendarConstants",
  "toastService",
  "moment",
  "$q",
  "lessonService",
  "lessonConstants",
];

function editAssignmentController(
  $scope,
  $rootScope,
  $uibModalInstance,
  assignmentService,
  editAssignmentParams,
  $state,
  studentService,
  calendarService,
  $uibModal,
  commonService,
  calendarConstants,
  toastService,
  moment,
  $q,
  lessonService,
  lessonConstants
) {
  let startDate,
    endDate,
    vm = this,
    isRequestSent = false,
    previousAssignmentType = null;

  vm.isFetchingData = false;
  vm.activeTab = "assignment";
  vm.assignment = {};

  vm.lesson = {};
  vm.student = {};
  let students_array = _.filter(
    studentService.students,
    (obj) => obj.role === "STUDENT"
  );
  students_array = _.map(students_array, (student, index) => {
    index++;
    return {
      type: "student",
      id: student.id,
      displayName: student.name,
      name: student.name + " (S" + index + ")",
      mediaUrl: student.mediaUrl || null,
    };
  });
  vm.students = students_array;
  vm.studentsName = [];

  vm.selectedStudents = [];

  vm.dateDuration = 0;
  vm.timeDuration = moment.duration(1, "hour").asSeconds();
  vm.allDayEvent = false;

  vm.startDate = null;
  vm.startTime = null;

  vm.endDate = null;
  vm.endTime = null;
  vm.isNote = editAssignmentParams.isNote || false;

  vm.resourceBookIds = [];
  vm.books = [];

  vm.dateError = "";

  vm.newResources = [];
  vm.assignmentEndDataBeforeStartErrorMessage = "";
  vm.assignmentType = [];

  vm.defaultAssignmentTypeCondition =
    lessonConstants.defaults.defaultAssignmentTypeCondition;

  angular.extend(vm, {
    close,
    getAssignmentDetails,
    toggleTab,
    editAssignment,
    isTouched,
    onEndDateChange,
    onStartDateChange,
    addStudentsTag,
    openBookSearchModal,
    removeBook,
    changeImageUrl,
    openResourceModal,
    editNewResource,
    removeNewResource,
    addCustomType,
  });

  function openResourceModal() {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "md",
      controller: "lessonElectronicMediaController",
      controllerAs: "lessonElectronicMediaCtrl",
      template: require("../../pug/lesson/electronic-media.pug").default,
      resolve: {
        resourceData: {
          newResource: null,
          mediaNames: vm.electronicMediaNames,
        },
      },
    });
    modelInstance.result
      .then(function (resource) {
        if (resource) {
          resource.isCustomAdd = true;
          vm.newResources.push(angular.copy(resource));
          // $rootScope.$broadcast('newResourcesAdd',vm.newResources);
        }
      })
      .catch(function () {
        modelInstance.close();
      });
  }

  function editNewResource(editNewResource, index) {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "md",
      controller: "lessonElectronicMediaController",
      controllerAs: "lessonElectronicMediaCtrl",
      template: require("../../pug/lesson/electronic-media.pug").default,
      resolve: {
        resourceData: {
          newResource: {
            editNewResource,
            type: "edit",
          },
        },
      },
    });
    modelInstance.result
      .then(function (resource) {
        if (resource && vm.newResources.length) {
          if (resource) {
            vm.newResources[index] = resource;
          }
        }
      })
      .catch(function () {
        modelInstance.close();
      });
  }

  function removeNewResource(index) {
    if (vm.newResources.length) {
      vm.newResources.splice(index, 1);
    }
  }

  function changeImageUrl(img) {
    if (img) {
      img = img.replace("http:", "https:");
    }
    return img;
  }

  function getBookIdFromResources() {
    vm.resourceBookIds = _.map(vm.books, "bookId");
  }

  function getDefaultResourceData(books) {
    let willAddBooks = [],
      finalBooks = [];
    for (let i = 0; i < books.length; i++) {
      let index = _.findIndex(vm.books, { bookId: books[i].bookId });
      if (index < 0) {
        let object = books[i];

        // object.startDay = 1;
        // object.startWeek=  1,
        // object.endDay = vm.days,
        // object.endWeek = vm.weeks;
        willAddBooks.push(object);
      }
    }
    finalBooks = vm.books.concat(willAddBooks);
    return finalBooks;
  }

  function removeBook(bookId) {
    let index = _.findIndex(vm.books, { id: bookId });
    if (index >= 0) {
      vm.books.splice(index, 1);
      // toastService.toast({
      //     message: calendarConstants.messages.deleteResource,
      //     type: 'success',
      //     delay: 2000
      // });
    }
  }

  function openBookSearchModal() {
    getBookIdFromResources();
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "lessonBookSearchController",
      controllerAs: "lessonBookSearchCtrl",
      template: require("../../pug/lesson/search-book.pug").default,
      resolve: {
        bookData: {
          bookIds: vm.resourceBookIds,
          // addGlobally: true
        },
      },
    });
    modelInstance.result
      .then(function (books) {
        books = getDefaultResourceData(books);
        vm.books = angular.copy(books);
      })
      .catch(function () {
        modelInstance.close();
      });
  }

  function addStudentsTag(studentQuery) {
    if (!studentQuery) {
      var deferred = $q.defer();
      deferred.resolve(vm.students);
      return deferred.promise;
    }
    let newStudentList = [];
    let findStudent = _.map(vm.students, function (students) {
      if (_.includes(students.name.toLowerCase(), studentQuery.toLowerCase())) {
        newStudentList.push(students);
      }
    });

    var deferred = $q.defer();
    deferred.resolve(newStudentList);

    return deferred.promise;
  }

  function onEndDateChange() {
    vm.dateDuration = moment(vm.endDate, "Y-MM-DD").diff(
      moment(vm.startDate, "Y-MM-DD"),
      "days"
    );
    vm.timeDuration = moment(vm.endTime, "HH:mm").diff(
      moment(vm.startTime, "HH:mm"),
      "seconds"
    );
  }

  function onStartDateChange() {
    if (!vm.startDate) {
      vm.startDate = moment().format("Y-MM-DD");
    }

    if (!vm.startTime) {
      vm.startTime = calendarConstants.defaultStartTime;
    }

    vm.endDate = moment(vm.startDate, "Y-MM-DD")
      .add(vm.dateDuration, "day")
      .format("Y-MM-DD");
    vm.endTime = moment(vm.startTime, "HH:mm")
      .add(vm.timeDuration, "seconds")
      .format("HH:mm");
  }

  function isTouched(field) {
    if (angular.isDefined(field)) {
      return field.$touched || vm.form.$submitted;
    } else {
      return false;
    }
  }

  function toggleTab(tab) {
    vm.activeTab = tab;
  }

  function close(data = {}) {
    angular.extend(data, {
      model: editAssignmentParams.model || "studentAssignment",
      assignment: vm.updatedAssignment,
    });

    $uibModalInstance.close(data);
  }

  function setAssignmentData() {
    let data;

    data = {
      studentAssignment: vm.assignment,
    };

    /**
     * Get student assignment Ids
     */
    let studentIds = [];
    if (!_.isEmpty(vm.selectedStudents)) {
      _.map(vm.selectedStudents, function (student) {
        studentIds.push(student.id);
      });
    }

    data.studentAssignment.books = vm.books;
    data.studentAssignment.studentId = studentIds;

    data.studentAssignment.startDate = commonService.convertToUTC(
      vm.startDate + " " + (vm.allDayEvent ? "00:00" : vm.startTime)
    );
    data.studentAssignment.endDate = commonService.convertToUTC(
      vm.endDate + " " + (vm.allDayEvent ? "23:59" : vm.endTime)
    );

    /**
     * For the daytime saving: ends 4th November and starts on 10th March
     * Check the assignment created date before 4th november
     * And assignmen is after 4th November
     * In that case, all day is 11:00 to 10:59
     */
    let calendarAssignment = data.studentAssignment;
    let firstOccurrence = calendarAssignment.studentLesson.startDate || null;

    if (
      firstOccurrence &&
      moment(firstOccurrence).isDST() &&
      !moment(calendarAssignment.startDate.format("Y-MM-DD")).isDST()
    ) {
      data.studentAssignment.startDate = moment(
        data.studentAssignment.startDate
      ).subtract(1, "hours");
      data.studentAssignment.endDate = moment(
        data.studentAssignment.endDate
      ).subtract(1, "hours");
    } else if (
      firstOccurrence &&
      !moment(firstOccurrence).isDST() &&
      moment(calendarAssignment.startDate.format("Y-MM-DD")).isDST()
    ) {
      data.studentAssignment.startDate = moment(
        data.studentAssignment.startDate
      ).add(1, "hours");
      data.studentAssignment.endDate = moment(
        data.studentAssignment.endDate
      ).add(1, "hours");
    }

    /**
     * rich text format update
     */
    data.studentAssignment.title = $(".assignmentTitle").html().toString();

    data.studentAssignment.noteTitle = $(".noteTitle").html().toString();
    if (vm.assignmentId) {
      data.studentAssignment.id = vm.assignmentId;
    }

    /**
     * Add student Ids
     */
    data.studentIds = data.studentAssignment.studentId;

    return data;
  }

  function rescheduleModalWindow(data) {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "md",
      controller: "EditAssignmentConfirmationController",
      controllerAs: "confirmAssignmtCtrl",
      template: require("../../pug/calendar//edit-assignment-confirmation.pug")
        .default,
    });

    modelInstance.result
      .then(function (option) {
        data.reschedule = option || "one";
        saveAssignment(data);
      }, angular.noop)
      .catch(function () {
        modelInstance.close();
      });
  }

  function saveAssignment(data) {
    isRequestSent = true;
    $rootScope.loadingBtn("updateAssignment", "Updating");
    data.model = "studentAssignment";
    let currentAssignment = data.studentAssignment.id,
      responseData;

    assignmentService
      .saveAssignment(data)
      .then((response) => {
        let transformedEvent = {};
        vm.updatedAssignment = response.data.data;
        responseData = response.data.data;

        if (responseData.displayTitle == null) {
          responseData.displayTitle = data.studentAssignment.studentLesson.name;
        }

        if (editAssignmentParams.search || editAssignmentParams.reschedule) {
          calendarService.editedLesson = responseData;
        }

        toastService.toast({
          message: calendarConstants.messages.assignmentUpdated,
          type: "success",
          delay: 3000,
        });

        vm.form.$setSubmitted();
        /**
         * Check for multiple students
         */
        let assignmentPromise;
        if (
          editAssignmentParams.lessonStatus &&
          editAssignmentParams.lessonStatus == "multiple"
        ) {
          assignmentPromise = Promise.all(
            _.map(editAssignmentParams.entityIds, (entityId) => {
              if (entityId.entityId != currentAssignment) {
                let additionalLesson = angular.copy(data);
                additionalLesson.studentAssignment.id = entityId.entityId;
                additionalLesson.studentAssignment.studentLessonPlanId =
                  entityId.studentLessonId;
                additionalLesson.studentAssignment.studentId = [
                  entityId.studentId,
                ];
                delete additionalLesson.studentAssignment.students;
                delete additionalLesson.studentAssignment.studentLesson;

                return assignmentService.saveAssignment(additionalLesson);
              }
            })
          );
        } else {
          return Promise.resolve(true);
        }

        return assignmentPromise;
      })
      .then((additionalLesson) => {
        let transformedEvent = {};
        switch (data.reschedule) {
          case "one":
            let event = calendarService.calendarDataTransform(
              "studentAssignment",
              responseData
            );
            calendarService.saveEventToList(event.entityId, event.model, event);
            transformedEvent = {
              // entityId: event.entityId,
              // event: event,
              // updateEvent: true,
              refetchEvents: true,
              search: editAssignmentParams.search,
            };
            // vm.close(transformedEvent);
            break;
          default:
            transformedEvent = {
              refetchEvents: true,
              search: editAssignmentParams.search,
            };
        }

        /**
         * Check if there is no date change then we don't have to refetch the whole calendar data
         */
        if (
          data.studentAssignment.startDate.isSame(
            moment(vm.originalStartDate)
          ) &&
          data.studentAssignment.endDate.isSame(moment(vm.originalEndDate))
        ) {
          let event = calendarService.calendarDataTransform(
            "studentAssignment",
            responseData
          );
          event = calendarService.updateEventTitleToList(
            event.entityId,
            event.model,
            event
          );

          if (event) {
            transformedEvent.entityId = event.entityId;
            transformedEvent.event = event;
            transformedEvent.updateEvent = true;
            transformedEvent.refetchEvents = false;
          }
        }

        /**
         * Check if title is changed
         */
        if (
          data.studentAssignment.studentLesson &&
          data.studentAssignment.studentLesson.studentLessonPlanId != 0 &&
          data.studentAssignment.studentLesson.name !=
            data.studentAssignment.subject
        ) {
          transformedEvent.refetchEvents = true;
          lessonService.removeAllLessonsFetchedStatus();
        }

        vm.close(transformedEvent);
      })
      .catch((error) => {
        toastService.toast({
          message: error.data.message,
          type: "error",
          delay: 3000,
        });
      })
      .finally(() => {
        isRequestSent = false;
        $rootScope.loadingBtnComplete("updateAssignment", "Update");
      });
  }

  function editAssignment() {
    let data;
    if (!vm.form.$valid || isRequestSent) {
      return;
    }

    /**
     * check for multiple students
     */
    if (
      _.isEmpty(vm.selectedStudents) &&
      $rootScope.user.userRole == "PARENT"
    ) {
      toastService.toast({
        message: calendarConstants.messages.assignmentStudentNotAdded,
        type: "error",
        delay: 3000,
      });
      return;
    } else if (
      _.isEmpty(vm.selectedStudents) &&
      $rootScope.user.userRole == "STUDENT"
    ) {
      vm.selectedStudents = [$rootScope.user];
    }

    data = setAssignmentData();
    if (
      moment(data.studentAssignment.endDate).isBefore(
        data.studentAssignment.startDate
      )
    ) {
      // toastService.toast({
      //     message: calendarConstants.messages.assignmentEndDateError,
      //     type: 'error',
      //     delay: 3000
      // });
      vm.assignmentEndDataBeforeStartErrorMessage =
        calendarConstants.messages.assignmentEndDateError;
      return false;
    }

    if (
      data.studentAssignment.studentLessonPlanId != 0 &&
      (!data.studentAssignment.startDate.isSame(moment(vm.originalStartDate)) ||
        !data.studentAssignment.endDate.isSame(moment(vm.originalEndDate)))
    ) {
      rescheduleModalWindow(data);
    } else {
      saveAssignment(data);
    }
  }

  function setViewData(assignment) {
    let student;

    vm.assignment = assignment;
    vm.lesson = assignment.studentLesson;
    vm.books = assignment.books;
    vm.originalStartDate = assignment.startDate;
    vm.originalEndDate = assignment.endDate;

    previousAssignmentType = assignment.category;

    /**
     * change in the rich text
     */

    $(".assignmentTitle").html(vm.assignment.title);
    $(".noteTitle").html(vm.assignment.noteTitle);

    /**
     * For the daytime saving: ends 4th November and starts on 10th March
     * Check the assignment created date before 4th november
     * And assignmen is after 4th November
     * In that case, all day is 11:00 to 10:59
     */
    let firstOccurrence = assignment.studentLesson.startDate || null;

    if (
      firstOccurrence &&
      moment(firstOccurrence).isDST() &&
      !moment(assignment.startDate).isDST()
    ) {
      assignment.startDate = moment(assignment.startDate).add(1, "hours");
      assignment.endDate = moment(assignment.endDate).add(1, "hours");

      if (
        moment(assignment.startDate).format("HH:mm") == "00:00" &&
        moment(assignment.endDate).format("HH:mm") == "23:59"
      ) {
        vm.allDayEvent = true;
      }
    } else if (
      firstOccurrence &&
      !moment(firstOccurrence).isDST() &&
      moment(assignment.startDate).isDST()
    ) {
      assignment.startDate = moment(assignment.startDate).subtract(1, "hours");
      assignment.endDate = moment(assignment.endDate).subtract(1, "hours");
      if (
        moment(assignment.startDate).format("HH:mm") == "00:00" &&
        moment(assignment.endDate).format("HH:mm") == "23:59"
      ) {
        vm.allDayEvent = true;
      }
    }

    vm.startDate = moment(assignment.startDate).format("Y-MM-DD");
    vm.startTime = moment(assignment.startDate).format("HH:mm a");

    vm.endDate = moment(assignment.endDate).format("Y-MM-DD");
    vm.endTime = moment(assignment.endDate).format("HH:mm a");

    onEndDateChange();

    student = studentService.getStudentsById(assignment.studentId);

    if (assignment.newResources) {
      vm.newResources = assignment.newResources;
    }

    if (
      moment(assignment.startDate).format("HH:mm") === "00:00" &&
      moment(assignment.endDate).format("HH:mm") === "23:59"
    ) {
      vm.allDayEvent = true;
    }

    if (student.length) {
      vm.student = student;
      vm.selectedStudents = student;
    }

    if (student.length > 0 && assignment.studentLessonPlanId != 0) {
      vm.student = student[0];
    }

    vm.studentsName = [vm.student.name];

    if (
      editAssignmentParams.lessonStatus &&
      editAssignmentParams.lessonStatus == "multiple"
    ) {
      vm.studentsName = [];
      if (editAssignmentParams.entityIds) {
        for (let i = 0; i < editAssignmentParams.entityIds.length; i++) {
          let student = studentService.getStudentsById(
            editAssignmentParams.entityIds[i].studentId
          );
          vm.studentsName.push(student[0].name);
        }
      }
    }
  }

  function addCustomType(assignmentType, action) {
    previousAssignmentType = vm.assignment.category;
    if (action == "add" && assignmentType.name != "custom") {
      vm.assignment.category = assignmentType.name;
      return true;
    }

    let modelInstance = $uibModal.open({
      animation: true,
      size: "md",
      controller: "assignmentTypeController",
      controllerAs: "assignmentTypeCtrl",
      template: require("../../pug/lesson/add-assignment-type.pug").default,
      backdrop: "static",
      keyboard: false,
      resolve: {
        resourceData: {
          lessonId: editAssignmentParams.studentsLesson.lessonId,
          assignmentType: {
            name: assignmentType.name,
            lessonId: assignmentType.lessonId,
            id: assignmentType.id,
            action: action,
          },
        },
      },
    });

    modelInstance.result
      .then(function (newAssignmentType) {
        if (newAssignmentType) {
          if (action == "add") {
            vm.assignmentType.push(newAssignmentType);

            /**
             * Update assignment category
             */
            vm.assignment.category = newAssignmentType.name;
            previousAssignmentType = newAssignmentType.name;
          } else if (action == "edit") {
            if (newAssignmentType.assignmentTypeStatus == "delete") {
              commonService.removeFromArray(
                newAssignmentType.id,
                "id",
                vm.assignmentType
              );
              if (previousAssignmentType == newAssignmentType.name) {
                vm.assignment.category = "assignment";
                previousAssignmentType = "assignment";
              }
            } else {
              for (let i = 0; i < vm.assignmentType.length; i++) {
                if (vm.assignmentType[i].id == newAssignmentType.id) {
                  vm.assignmentType[i].name = newAssignmentType.name;
                  vm.assignmentType[i].lessonId = newAssignmentType.lessonId;
                }
              }
              /**
               * Update assignment category
               */
              vm.assignment.category = newAssignmentType.name;
              previousAssignmentType = newAssignmentType.name;
            }
          }
        } else {
          vm.assignment.category = previousAssignmentType;
        }
      })
      .catch(function () {
        modelInstance.close();
      });
  }

  function getAssignmentDetails() {
    lessonService
      .getElectronicMediaName()
      .then((data) => {
        vm.electronicMediaNames = data;

        if (
          editAssignmentParams.studentsLesson &&
          editAssignmentParams.studentsLesson.lessonId
        ) {
          lessonService
            .getAddAssignmentDefaultData(
              editAssignmentParams.studentsLesson.lessonId
            )
            .then((defaultAssignmentData) => {
              vm.assignmentType = defaultAssignmentData.assignmentType;
            });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });

    vm.isFetchingData = true;
    if (vm.isNote) {
      vm.activeTab = "note";
    }

    vm.paramStudent = editAssignmentParams.students;
    assignmentService
      .viewAssignment(editAssignmentParams.assignmentId)
      .then((response) => {
        setViewData(response.data);
      })
      .catch((error) => {
        close();
        $state.go("404");
      })
      .finally(() => {
        vm.isFetchingData = false;
      });
  }

  this.$onInit = getAssignmentDetails;

  $scope.$on("modal.closing", function (evt) {
    if (
      vm.form.$dirty &&
      !vm.form.$pristine &&
      !vm.form.$submitted &&
      ((!vm.lesson.id ? !vm.form.displayTitle.$pristine : false) ||
        !vm.form.assignmentTitle.$pristine ||
        !vm.form.noteTitle.$pristine)
    ) {
      evt.preventDefault();
      $rootScope.isLoading = false;
      let confirmData = {
        message: calendarConstants.messages.leavePageConfirmation,
        type: "danger",
        modalSize: "md",
      };

      commonService.confirm(confirmData).then(function (confirmation) {
        if (confirmation.result) {
          vm.form.$submitted = true;
          vm.close();
        }
      });
    }
  });
}
export default editAssignmentController;
