//mentionDirective.$inject = [];

export const mentionDirective = () => {
  // return {
  // 	require: 'uiMention',
  // 	link: function link($scope, $element, $attrs, uiMention) {
  // 		console.log("hello")
  // 		/**
  // 		 * $mention.findChoices()
  // 		 *
  // 		 * @param  {regex.exec()} match    The trigger-text regex match object
  // 		 * @todo Try to avoid using a regex match object
  // 		 * @return {array[choice]|Promise} The list of possible choices
  // 		 */
  // 		uiMention.findChoices = function (match, mentions) {
  // 			console.log("mentions")
  // 			return choices
  // 				// Remove items that are already mentioned
  // 				.filter(function (choice) {
  // 					return !mentions.some(function (mention) {
  // 						return mention.id === choice.id;
  // 					});
  // 				})
  // 				// Matches items from search query
  // 				.filter(function (choice) {
  // 					return ~(choice.first + ' ' + choice.last).indexOf(match[1]);
  // 				});
  // 		};
  // 	}
  // };
  return {
    require: "uiMention",
    link: function ($scope, $element, $attrs, uiMention) {
      console.log("uiMention", uiMention);
      /**
       * Converts a choice object to a human-readable string
       *
       * @param  {mixed|object} choice The choice to be rendered
       * @return {string}              Human-readable string version of choice
       */
      // uiMention.label = function(choice) {
      // 	console.log("hi brother",choice)
      // 	return choice.first + " " + choice.last;
      // };

      /**
       * Retrieves choices
       *
       * @param  {regex.exec()} match    The trigger-text regex match object
       * @return {array[choice]|Promise} The list of possible choices
       */
      uiMention.findChoices = function (match, mentions) {
        var choices = [
          { first: "bob", last: "barker", id: 11123 },
          { first: "kenny", last: "logins", id: "123ab-123" },
          { first: "kyle", last: "corn", id: "123" },
          { first: "steve", last: "rodriguez", id: "hi" },
          { first: "steve", last: "holt", id: "0-9" },
          { first: "megan", last: "burgerpants", id: "ab-" },
        ];

        return choices;
      };
    },
  };
};
