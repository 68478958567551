const moment = window.moment;

export const lessonConstants = {
  resource: {
    weekDayError: "Select End week after Start week.",
    //decimalValueError: 'Decimal values are not accepted in this field.'
    decimalValueError: "Please enter valid value",
  },
  course: {
    defaultPage: 1,
    defaultOffset: 10,
  },
  btns: [
    ["undo", "redo"],
    ["strong", "em"],
    ["fontsize"],
    ["link"],
    ["superscript", "subscript"],
    ["justifyLeft", "justifyCenter", "justifyRight", "justifyFull"],
    ["unorderedList", "orderedList"],
  ],
  gradesInfo: [
    {
      grade: "A+",
      weight: 6,
      percentage: 100,
      minValue: 97,
      maxValue: 100,
    },
    {
      grade: "A",
      weight: 4,
      percentage: 96,
      minValue: 93,
      maxValue: 96,
    },
    {
      grade: "A-",
      weight: 3.67,
      percentage: 92,
      minValue: 90,
      maxValue: 92,
    },
    {
      grade: "B+",
      weight: 3.33,
      percentage: 89,
      minValue: 87,
      maxValue: 89,
    },
    {
      grade: "B",
      weight: 3,
      percentage: 86,
      minValue: 83,
      maxValue: 86,
    },
    {
      grade: "B-",
      weight: 2.67,
      percentage: 82,
      minValue: 80,
      maxValue: 82,
    },
    {
      grade: "C+",
      weight: 2.33,
      percentage: 79,
      minValue: 77,
      maxValue: 79,
    },
    {
      grade: "C",
      weight: 2,
      percentage: 76,
      minValue: 73,
      maxValue: 76,
    },
    {
      grade: "C-",
      weight: 1.67,
      percentage: 72,
      minValue: 70,
      maxValue: 72,
    },
    {
      grade: "D+",
      weight: 1.33,
      percentage: 69,
      minValue: 67,
      maxValue: 69,
    },
    {
      grade: "D",
      weight: 1,
      percentage: 66,
      minValue: 63,
      maxValue: 66,
    },
    {
      grade: "D-",
      weight: 0.67,
      percentage: 62,
      minValue: 60,
      maxValue: 62,
    },
    {
      grade: "F",
      weight: 0,
      percentage: 59,
      minValue: 0,
      maxValue: 59,
    },
    { grade: "-", weight: -1, percentage: 0, minValue: 0, maxValue: 0 },
  ],
  defaults: {
    credits: "0",
    weeks: "1",
    maxWeeks: "52",
    days: "1",
    positions: "0",
    duration: "01:00",
    startTime: "00:00",
    allDayDuration: "23:59",
    defaultCourseList: [
      "Mathematics",
      "Science",
      "History",
      "Social Studies",
      "Geography",
      "Fine Arts",
      "Foreign Language",
      "Language Arts",
      "Reading",
      "Health",
      "Electives",
    ],
    defaultAssignmentType: ["Assignment", "Test", "Activity", "Reading"],
    defaultAssignmentTypeCondition: [
      "assignment",
      "test",
      "activity",
      "reading",
      "custom",
    ],
  },
  attendance: {
    present: "P",
    absent: "A",
    other: "O",
  },
  statuses: ["not started", "complete"],
  exportUrlRegex: new RegExp(window.location.origin + "/lessons/export/(\\w+)"),
  socialSharingMessage: {
    partOne: "Hey, I created a",
    partTwo:
      "lesson plan on Homeschool Panda to share with you. It is available at",
  },
  socialSharingHashTag: "Homeschooling",
  validationMessages: {
    underflow:
      "Please note that the total number of days selected are less than the number of lessons/chapters/pages.",
    overflow:
      "Please note that the number of lessons/chapters/pages are less than the total number of days selected.",
  },
  messages: {
    deleteAssignmentSuccess: "Assignment deleted successfully",
    leavePageConfirmation:
      "The changes you made will be lost if you leave this page.\nContinue with unsaved changes?",
    updateLessonConfirmation:
      "Do you want to update the changed lesson details for the assigned students as well?",
    urlNotFound: "URL not found",
    assignedSuccessfuly: "Lesson assigned successfully",
    assignErrorStartDate: "Please select a start date for lesson",
    assignErrorStartTime: "Please select starting time for lesson",
    assignErrorDays:
      "Please select at least ${days} days to assign lesson to the student.",
    invalidUrl: "Invalid URL provided",
    lessonImported: "Lesson imported",
    lessonCopy: "Lesson copied successfully",
    deleteLessonConfirmation: "Are you sure you want to delete this lesson?",
    archiveLessonConfirmation: "Are you sure you want to archive this lesson?",
    unarchiveLessonConfirmation:
      "Are you sure you want to unarchive this lesson?",
    deleteAssignedLessonConfirmation: "Delete assigned lessons as well",
    archiveAssignedLessonConfirmation: "Archive assigned lessons as well",
    unarchiveAssignedLessonConfirmation: "Unarchive assigned lessons as well",
    deleteGradeConfirmation: "Are you sure you want to delete this grade?",
    lessonUpdated: "Lesson updated",
    emptyAssignment: "Please select atleast one assignment",
    emptyDate: "Please select date",
    emptyAssignmentDate: "Please select assignment and date",
    completeAddLessonData: "Please complete lesson form",
    addStudentToLessonData: "Please add at least one student to lesson",
    completeBookData: "Please complete required field",
    completeBookTitle: "Please enter title of the book",
    lessonStudentNotSelected:
      "Please select at least one student for the lesson",
    endDateIsBeforeStartDate:
      "The start date cannot be greater than the end date. Please update and try again",
    endDateIsAtleastOneWeek:
      "The end date cannot be less than the one week. Please update End Date and try again",
    endDateNotMoreThanYear:
      "Lesson can not be more than year. Please update and try again",
    addedExtraAssignment: "Additional assignment added to fit the lesson plan",
    emptyDays: "Select at least one number of 'Days' ",
    endTimeIsBeforeStartError:
      "The start time cannot come after the end date. Please update and try again",
    updateLessonGradingConfirm:
      "If you Apply to All Lesson Plans then it might effect other lesson plans",
  },
  days: { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 },
  tabsList: ["assignment", "note", "files", "notepad", "resources", "students"],
  academicDays: 180,
  startOfyear: moment().startOf("year"),
  endOfYear: moment().endOf("year"),
  color: "#01579B",
};
