let angular = window.angular;

editAppointmentController.$inject = [
  "$scope",
  "friendService",
  "$uibModalInstance",
  "appointmentService",
  "editAppointmentParams",
  "calendarConstants",
  "calendarService",
  "commonService",
  "toastService",
  "moment",
  "$uibModal",
  "studentService",
  "$timeout",
  "_",
  "$rootScope",
  "$q",
  "chatConstants",
];

function editAppointmentController(
  $scope,
  friendService,
  $uibModalInstance,
  appointmentService,
  editAppointmentParams,
  calendarConstants,
  calendarService,
  commonService,
  toastService,
  moment,
  $uibModal,
  studentService,
  $timeout,
  _,
  $rootScope,
  $q,
  chatConstants
) {
  /* jshint validthis:true */
  let vm = this;
  let appointment = {},
    recurrence = {};

  vm.isFetchingData = false;
  vm.endOfRecurrenceInvalid = false;

  vm.dateDuration = 0;
  vm.timeDuration = moment.duration(1, "hour").asSeconds();
  vm.allDayEvent = false;

  vm.recurrence = {};
  vm.reminders = [];
  vm.recurrenceOptions = _.clone(calendarConstants.recurrenceOptions);
  vm.reminderOptions = _.clone(calendarConstants.reminderOptions);
  vm.repeatEnd = "never";

  vm.media = [];
  vm.modelName = "appointment";
  vm.comingFrom = editAppointmentParams.comingFrom;
  // vm.category = 'event';

  /////////////////////OLD working code chunk///////////////////
  // let students_array=_.filter(studentService.students,(obj)=>obj.role==='STUDENT');
  // students_array = _.map(students_array, (student, index) => {
  //     index++;
  //     return { type: 'student', id: student.id, displayName: student.name, name: student.name + ' (S' + index + ')', mediaUrl: student.mediaUrl || null, rsvp: 'yes' };
  // });
  // let friends_array = _.map(friendService.getFriends(), (friend, index) => {
  //     index++;
  //     return { type: 'friend', id: friend.id, displayName: friend.name, name: friend.name + ' (F' + index + ')', mediaUrl: friend.mediaUrl || null };
  // })

  // vm.students = students_array.concat(friends_array);
  // vm.attendees = _.clone(vm.students);
  // vm.selectedAttendees = [];

  ////////////////////////////////////////////////////
  ///////////////////////////////////////////////

  ////////For Family Helper
  let helpers_array = _.filter(
    studentService.students,
    (obj) => obj.role === "HELPER"
  );
  let family_helpers_array = _.filter(
    helpers_array,
    (obj) => obj.relation === "Family"
  );

  family_helpers_array = _.map(family_helpers_array, (helper, index) => {
    index++;
    return {
      type: "helper",
      id: helper.id,
      displayName: helper.name,
      name: helper.name + " (H" + index + ")",
      mediaUrl: helper.mediaUrl || null,
      refId: helper.id,
    };
  });
  //console.log(family_helpers_array);

  let students_array = _.filter(
    studentService.students,
    (obj) => obj.role === "STUDENT"
  );
  students_array = _.map(students_array, (student, index) => {
    index++;
    return {
      type: "student",
      id: student.id,
      displayName: student.name,
      name: student.name + " (S" + index + ")",
      mediaUrl: student.mediaUrl || null,
      rsvp: "yes",
      refId: student.studentUserId,
    };
  });
  let friends_array = _.map(friendService.getFriends(), (friend, index) => {
    index++;
    return {
      type: "friend",
      id: friend.id,
      displayName: friend.name,
      name: friend.name + " (F" + index + ")",
      mediaUrl: friend.mediaUrl || null,
    };
  });

  let std_helper_array = [];
  std_helper_array = students_array.concat(family_helpers_array);

  for (var i = 0, len = std_helper_array.length; i < len; i++) {
    for (var j = 0, len2 = friends_array.length; j < len2; j++) {
      if (std_helper_array[i].refId === friends_array[j].id) {
        friends_array.splice(j, 1);
        len2 = friends_array.length;
      }
    }
  }

  //console.log('Modified student & helper list is:', std_helper_array);
  //console.log('Modified only frineds list is:', friends_array);
  vm.students = [];
  if (vm.comingFrom != null && vm.comingFrom == "chore") {
    if (students_array.length > 0) {
      //only students & helpers
      vm.students = std_helper_array;
      //vm.students = students_array.concat(family_helpers_array);
    } else {
      //replacing studetns with friends
      vm.students = friends_array;
    }
  } else {
    if (students_array.length > 0) {
      //merging students with friends
      vm.students = std_helper_array.concat(friends_array);
    } else {
      //replacing studetns with friends
      vm.students = friends_array;
    }
  }

  //vm.students = students_array.concat(friends_array);

  vm.attendees = _.clone(vm.students);
  vm.selectedAttendees = [];

  //////////////////////////////////////////////////////////////
  vm.eventWeekdays = [];
  vm.days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  /**
   * Chore todo tasks
   */
  vm.todos = [];
  vm.todoRemoved = [];
  vm.ngEmbeddedOption = chatConstants.ngEmbeddedOption;
  vm.editTodoIndex = -1;

  //For Date Error
  vm.dateError = "";

  angular.extend(vm, {
    close,
    isTouched,
    checkEndOfRecurrence,
    editAppointment,
    onStartDateChange,
    onEndDateChange,
    addReminder,
    removeReminder,
    getReminderTitle,
    onRecurrenceOptionChange,
    onRepeatEndOptionChange,
    setFormDirtyForDateTime,
    addStudentsTag,
    toggleEditTodoForm,
    deleteTodo,
    saveTodo,
    addTodoToList,
    editChore,
  });

  function addStudentsTag(studentQuery) {
    /**
     * remove attendees from the vm,students list
     */
    vm.students = [];
    for (let i = 0; i < vm.attendees.length; i++) {
      let flag = false;
      for (let j = 0; j < vm.selectedAttendees.length; j++) {
        if (
          vm.attendees[i].type == vm.selectedAttendees[j].type &&
          vm.attendees[i].id == vm.selectedAttendees[j].id
        ) {
          flag = true;
          break;
        }
      }

      if (!flag) {
        vm.students.push(vm.attendees[i]);
      }
    }

    let newStudentList = [];
    let findStudent = _.map(vm.students, function (students) {
      if (_.includes(students.name.toLowerCase(), studentQuery.toLowerCase())) {
        newStudentList.push(students);
      }
    });
    var deferred = $q.defer();
    deferred.resolve(newStudentList);

    return deferred.promise;
  }

  function filesUpdateCallback(id) {
    if (vm.updatedLesson) {
      vm.updatedLesson.media = vm.media;
    }
    if (id) {
      $state.go("lessonPlannerAssign", { lessonId: id });
    }

    isRequestSent = false;
    $rootScope.loadingBtnComplete("save", "Create");
  }

  function checkEndOfRecurrence(date) {
    if (date) {
      vm.endOfRecurrenceInvalid = moment(date).isBefore(
        moment(vm.appointmentStartDate)
      );
    } else {
      vm.endOfRecurrenceInvalid = false;
    }
  }

  function isTouched(field) {
    if (angular.isDefined(field)) {
      return field.$touched || vm.form.$submitted;
    } else {
      return false;
    }
  }

  function addReminder(selectedId, original) {
    let id = vm.reminders.length
        ? vm.reminders[vm.reminders.length - 1].id + 1
        : 1,
      options = _.clone(vm.reminderOptions),
      value = selectedId ? selectedId : vm.reminders.length + 1;

    let reminder = { id, options, value };

    if (angular.isDefined(original)) {
      angular.extend(reminder, { original });
    }

    vm.reminders.push(reminder);
  }

  function getReminderTitle(index) {
    return commonService.stringifyNumber(index + 1);
  }

  function removeReminder(index) {
    vm.reminders.splice(index, 1);
  }

  function close(data = {}) {
    angular.extend(data, {
      // entityId: appointment.id,
      model: "appointment",
    });
    $uibModalInstance.close(data);
  }

  function getAppointmentDetails() {
    vm.isFetchingData = true;
    let timezone = -1 * new Date().getTimezoneOffset();
    vm.appointment = {};
    if (editAppointmentParams.editOccurrence) {
      vm.modelName = "occurrence";
    } else {
      vm.modelName = "original";
    }
    vm.appointment.id = editAppointmentParams.appointmentId;
    appointmentService
      .viewAppointment(
        editAppointmentParams.appointmentId,
        editAppointmentParams.editOccurrence
          ? editAppointmentParams.occurrenceId
          : 1,
        timezone
      )
      .then((response) => {
        appointment = response.appointment;
        recurrence = response.recurrence;
        if (recurrence && recurrence.id) {
          recurrence.endOfRecurrence = commonService.convertFromUTC(
            recurrence.endOfRecurrence
          );
          //Weekday recurrence
          vm.eventWeekdays = recurrence.weekDay;
          vm.oldMaxOccurrences = recurrence.maxOccurrences;
        }

        vm.multipleFiles = {
          toUpload: [],
          uploaded: response.media,
          toDelete: [],
        };

        vm.appointmentTitle = appointment.title;
        vm.appointmentDesc = appointment.description;
        vm.media = response.media;

        vm.multipleFiles = {
          toUpload: [],
          uploaded: vm.media,
          toDelete: [],
        };

        /**
         * update category for the appointment
         */
        vm.category = response.appointment.category;

        if (vm.category == "chores") {
          let todoList = response.appointment.todos;
          vm.todos = [];
          _.map(todoList, (todos, studentId) => {
            vm.todos = todos;
          });
        }

        /**
         * update attendes for the appointment
         */
        // vm.selectedAttendees = response.appointment.attendees;

        vm.selectedAttendees = _.map(
          response.appointment.attendees,
          (attendees, index) => {
            index++;
            let attendeesName = attendees.name;
            let attendeesType = attendees.type
              ? attendees.type.charAt(0).toUpperCase()
              : "U";
            attendees.displayName = attendeesName;
            attendees.name = attendeesName + " (" + attendeesType + index + ")";
            return attendees;
          }
        );

        vm.oldAttendees = response.attendees;
        // Convert the time from UTC to local time

        let startDateTime, endDateTime;
        vm.occurrenceId = editAppointmentParams.occurrenceId || 0;

        /**
         * For the daytime saving: ends 4th November and starts on 10th March
         * Check the assignment created date before 4th november
         * And assignmen is after 4th November
         * In that case, all day is 11:00 to 10:59
         */
        let firstOccurrence = appointment.firstOccurrence || null;
        if (
          firstOccurrence &&
          moment(firstOccurrence).isDST() &&
          !moment(appointment.startDate).isDST()
        ) {
          appointment.startDate = moment(appointment.startDate).add(1, "hours");
          appointment.endDate = moment(appointment.endDate).add(1, "hours");

          if (
            moment(appointment.startDate).format("HH:mm") == "00:00" &&
            moment(appointment.endDate).format("HH:mm") == "23:59"
          ) {
            vm.allDayEvent = true;
          }
        } else if (
          firstOccurrence &&
          !moment(firstOccurrence).isDST() &&
          moment(appointment.startDate).isDST()
        ) {
          appointment.startDate = moment(appointment.startDate).subtract(
            1,
            "hours"
          );
          appointment.endDate = moment(appointment.endDate).subtract(
            1,
            "hours"
          );
          if (
            moment(appointment.startDate).format("HH:mm") == "00:00" &&
            moment(appointment.endDate).format("HH:mm") == "23:59"
          ) {
            vm.allDayEvent = true;
          }
        }

        startDateTime = commonService.convertFromUTC(appointment.startDate);
        endDateTime = commonService.convertFromUTC(appointment.endDate);

        vm.appointmentStartDate = startDateTime.format("Y-MM-DD");
        vm.appointmentStartTime = startDateTime.format("HH:mm");

        vm.appointmentEndDate = endDateTime.format("Y-MM-DD");
        vm.appointmentEndTime = endDateTime.format("HH:mm");

        if (
          vm.appointmentStartTime === "00:00" &&
          vm.appointmentEndTime === "23:59"
        ) {
          vm.allDayEvent = true;
        }

        onEndDateChange();

        vm.appointmentLocation = appointment.location;
        vm.locationLatitude = appointment.latitude;
        vm.locationLongitude = appointment.longitude;

        vm.editRecurrence =
          !editAppointmentParams.editOccurrence && appointment.parentId <= 0;

        // setRepeatOptions(vm.appointmentStartDate);
        if (vm.editRecurrence && !_.isEmpty(recurrence)) {
          vm.recurrence = appointmentService.getRecurrenceOption(recurrence);

          if (vm.recurrence.dynamic) {
            vm.recurrence.title = appointmentService.getRecurrenceString(
              vm.recurrence,
              vm.appointmentStartDate
            );
          }

          vm.repeatEnd = recurrence.endsWith;

          vm.recurrence.separationCount = recurrence.separationCount;
          onRecurrenceOptionChange();
        }

        if (response.reminders.length) {
          _.each(response.reminders, function (reminder) {
            let reminderId = appointmentService.getReminderOption(reminder).id;
            addReminder(reminderId, reminder);
          });
        }
      })
      .catch((error) => {
        console.error("Appointment Details Error", error);
      })
      .finally(() => {
        vm.isFetchingData = false;
      });
  }

  function editChore() {
    if (!vm.selectedAttendees.length) {
      toastService.toast({
        message: "Please add atleast one student to chore",
        type: "error",
        delay: 3000,
      });
      return;
    }

    if (vm.todos.length) {
      vm.todos = vm.todos.filter((o) => o.title != "");
    }

    editAppointment();
  }

  function editAppointment() {
    vm.dateError = "";
    if (
      vm.repeatEnd !== "endOfRecurrence" &&
      !vm.form.$valid &&
      vm.form.$error.parse
    ) {
      vm.form.$valid = vm.form.$error.parse[0].$name === "endOfRecurrence";
    }

    if (vm.form.$valid && !vm.endOfRecurrenceInvalid) {
      let data = {
        appointment: {},
        recurrence: {},
        reminders: [],
      };

      let startDateTime, endDateTime;
      appointment.title = vm.appointmentTitle;
      appointment.description = vm.appointmentDesc;

      appointment.location = vm.appointmentLocation;
      appointment.latitude = vm.locationLatitude;
      appointment.longitude = vm.locationLongitude;
      // appointment.attendees = vm.selectedAttendees;

      //add category of event
      appointment.category = vm.category;
      if (vm.category == "chores") {
        appointment.todos = vm.todos || [];
        appointment.todoRemoved = vm.todoRemoved || [];
      }

      for (let i = 0; i < vm.selectedAttendees.length; i++) {
        if (vm.selectedAttendees[i].type == undefined) {
          vm.selectedAttendees[i].type = "user";
          vm.selectedAttendees[i].id = vm.selectedAttendees[i].name;
        }
      }
      //console.log("Attendees in edit ", vm.selectedAttendees);
      appointment.attendees = vm.selectedAttendees;
      //add attendees to the event

      // Convert the time and date to UTC
      startDateTime = commonService.convertToUTC(
        vm.appointmentStartDate +
          " " +
          (vm.allDayEvent ? "00:00" : vm.appointmentStartTime)
      );
      endDateTime = commonService.convertToUTC(
        vm.appointmentEndDate +
          " " +
          (vm.allDayEvent ? "23:59" : vm.appointmentEndTime)
      );

      // Make sure end datetime is not before start datetime
      if (moment(endDateTime).isBefore(startDateTime)) {
        // toastService.toast({
        //     message: calendarConstants.messages.eventEndDateError,
        //     type: 'error',
        //     delay: 3000
        // });
        vm.dateError = calendarConstants.messages.eventEndDateError;
        return false;
      }
      vm.dateError = "";
      appointment.startDate = startDateTime.format("Y-MM-DD HH:mm:ss");
      appointment.endDate = endDateTime.format("Y-MM-DD HH:mm:ss");

      data.appointment = _.clone(appointment);

      if (!_.isEmpty(vm.recurrence && vm.recurrence.type)) {
        data.recurrence = _.assign(
          recurrence,
          _.pick(vm.recurrence, [
            "type",
            "maxOccurrences",
            "endOfRecurrence",
            "separationCount",
          ]),
          { endsWith: vm.repeatEnd }
        );
        /*
         *  Get the value of endOfRecurrence, convert it into a datetime for endOfDay and then convert it into UTC
         */
        let endOfRecurrence = data.recurrence.endOfRecurrence;
        endOfRecurrence = moment(endOfRecurrence).endOf("day");
        endOfRecurrence = commonService.convertToUTC(
          endOfRecurrence,
          "Y-MM-DD"
        );
        data.recurrence.endOfRecurrence =
          endOfRecurrence.format("Y-MM-DD HH:mm:ss");
      }

      data.appointment.oldMaxOccurrences = vm.oldMaxOccurrences;

      if (!_.isEmpty(vm.reminders)) {
        data.reminders = _.map(vm.reminders, function (reminder) {
          let newReminder = reminder.original || {};
          let selectedOption = _.find(reminder.options, { id: reminder.value });
          return _.assign(
            newReminder,
            _.pick(selectedOption, ["value", "unit"])
          );
        });
      }
      //console.log('atttendees in edit frontend', data);
      let editType = editAppointmentParams.editOccurrence
        ? "onlyOccurrence"
        : "original";
      $rootScope.loadingBtn("editAppointment", "updating");
      let timezone = -1 * new Date().getTimezoneOffset();
      appointmentService
        .editAppointment(
          appointment.id,
          data,
          editType,
          vm.occurrenceId,
          timezone
        )
        .then((response) => {
          if (editAppointmentParams.search) {
            calendarService.editedLesson = data;
            calendarService.editedLesson.appointment.startDate =
              response.data.data.appointment.startDate;
            calendarService.editedLesson.appointment.endDate =
              response.data.data.appointment.endDate;
          }
          if (response.data.data.appointment) {
            vm.form.$setSubmitted();
          }
          if (editType === "original") {
            $rootScope.$broadcast(
              "$hsp-update-files",
              appointment.id,
              "appointment"
            );
          } else {
            $rootScope.$broadcast(
              "$hsp-update-files",
              response.data.data.appointment.id,
              "appointment"
            );
          }

          if (editType !== "original" || _.isEmpty(data.recurrence)) {
            let event = calendarService.calendarDataTransform(
              "appointment",
              response.data.data.appointment
            );
            calendarService.saveEventToList(event.entityId, event.model, event);

            let transformedEvent = {
              entityId: event.entityId,
              event: event,
              updateEvent: true,
              search: editAppointmentParams.search,
            };

            if (editAppointmentParams.editOccurrence) {
              transformedEvent = _.assign(transformedEvent, {
                occurrenceId: vm.occurrenceId,
                entityId: appointment.id,
                search: editAppointmentParams.search,
              });
            }
            toastService.toast({
              message: calendarConstants.messages.eventUpdated,
              type: "success",
              delay: 3000,
            });
            vm.close(transformedEvent);
          } else {
            toastService.toast({
              message: calendarConstants.messages.eventUpdated,
              type: "success",
              delay: 3000,
            });
            vm.close({
              refetchEvents: true,
              search: editAppointmentParams.search,
            });
          }
        })
        .catch((response) => {
          console.error(response);
          toastService.toast({
            message: error.data.message,
            type: "error",
            delay: 3000,
          });
          $rootScope.loadingBtnComplete("createAppointment", "create");
        })
        .finally(() => {
          $rootScope.loadingBtnComplete("editAppointment", "update");
        });
    }
  }

  function onEndDateChange() {
    vm.dateDuration = moment(vm.appointmentEndDate, "Y-MM-DD").diff(
      moment(vm.appointmentStartDate, "Y-MM-DD"),
      "days"
    );
    vm.timeDuration = moment(vm.appointmentEndTime, "HH:mm").diff(
      moment(vm.appointmentStartTime, "HH:mm"),
      "seconds"
    );
  }

  function onStartDateChange() {
    setFormDirtyForDateTime("Start");
    if (!vm.appointmentStartDate) {
      vm.appointmentStartDate = moment().format("Y-MM-DD");
    }

    if (!vm.appointmentStartTime) {
      vm.appointmentStartTime = calendarConstants.defaultStartTime;
    }

    vm.appointmentEndDate = moment(vm.appointmentStartDate, "Y-MM-DD")
      .add(vm.dateDuration, "day")
      .format("Y-MM-DD");
    vm.appointmentEndTime = moment(vm.appointmentStartTime, "HH:mm")
      .add(vm.timeDuration, "seconds")
      .format("HH:mm");
    setRepeatOptions(vm.appointmentStartDate);
  }

  function onRecurrenceOptionChange() {
    if (vm.recurrence) {
      if (angular.isDefined(vm.recurrence.type) && !vm.recurrence.shorthand) {
        vm.recurrence.separationCount =
          angular.isDefined(recurrence) && recurrence.separationCount
            ? recurrence.separationCount
            : 0;
      }

      vm.repeatEveryOptions = [];
      for (let i = 0; i < 30; i++) {
        let option = {
          title:
            i + 1 + " " + _.capitalize(vm.recurrence.type) + (i > 0 ? "s" : ""),
          separationCount: i,
        };
        vm.repeatEveryOptions.push(option);
      }
      onRepeatEndOptionChange();
    }
  }

  function onRepeatEndOptionChange() {
    if (vm.repeatEnd === "maxOccurrences") {
      vm.recurrence.maxOccurrences =
        angular.isDefined(recurrence) && recurrence.maxOccurrences
          ? recurrence.maxOccurrences
          : calendarConstants.defaultMaxOccurrences;
    } else {
      delete vm.recurrence.maxOccurrences;
    }

    if (vm.repeatEnd === "endOfRecurrence") {
      if (angular.isDefined(recurrence) && recurrence.endOfRecurrence) {
        vm.recurrence.endOfRecurrence = moment(
          recurrence.endOfRecurrence
        ).format("Y-MM-DD");
      } else {
        let offset =
          (vm.recurrence.separationCount + 1) *
          calendarConstants.defaultMaxOccurrences;
        vm.recurrence.endOfRecurrence = moment(vm.appointmentStartDate)
          .add(offset, vm.recurrence.type)
          .format("Y-MM-DD");
      }
    } else {
      delete vm.recurrence.endOfRecurrence;
    }
  }

  function setRepeatOptions(date) {
    let dynamicWeekOptionIndex = _.findIndex(vm.recurrenceOptions, {
      type: "week",
    });
    let dynamicMonthOptionIndex = _.findIndex(vm.recurrenceOptions, {
      type: "month",
      dynamic: true,
    });
    vm.recurrenceOptions[dynamicWeekOptionIndex].title =
      "Every " + moment(date).format("dddd");
    vm.recurrenceOptions[dynamicMonthOptionIndex].title =
      "Every " + moment(date).format("Do");
  }

  this.$onInit = getAppointmentDetails;

  function setFormDirtyForDateTime(element) {
    if (angular.isDefined(vm.form) && angular.isDefined(vm.form.$pristine)) {
      vm.form.$pristine = false;
      vm.form.$dirty = true;

      if (
        angular.isDefined(
          vm.form["appointment" + element + "Date"] &&
            vm.form["appointment" + element + "Time"]
        )
      ) {
        vm.form["appointment" + element + "Date"].$pristine = false;
        vm.form["appointment" + element + "Time"].$dirty = true;
      }
    }
  }

  // $timeout(function () {
  // 	vm.form.$pristine = true;
  // 	vm.form.$dirty = false;
  // }, 1000);

  /**
   * CHORE features
   */
  function toggleEditTodoForm($index, $todoIndex, isEditCancel, taskId) {
    vm.editTodoIndex = angular.isNumber($index)
      ? commonService.convertToInteger($index)
      : -1;
    if (~vm.editTodoIndex) {
      vm.editTodoIndex = $index;
    } else {
      vm.editCategoryIndex = -1;
    }

    if (!vm.todos[$todoIndex].title || vm.todos[$todoIndex].title == "") {
      if (!taskId) {
        vm.todos.splice($todoIndex, 1);
        return true;
      } else {
        deleteTodo($todoIndex, todoId);
      }
    }

    toggleEditTodo($todoIndex, isEditCancel);
  }

  function toggleEditTodo($todoIndex, isEditCancel) {
    isEditCancel
      ? (vm.todos[$todoIndex].title = vm.todos[$todoIndex].oldTitle)
      : (vm.todos[$todoIndex].oldTitle = vm.todos[$todoIndex].title);
  }

  function deleteTodo($todoIndex, todoId) {
    let confirmData = {
      message: "Are you sure you want to delete this task for all students?",
      modalSize: "md",
      type: "danger",
    };
    commonService.confirm(confirmData).then(function (confirmation) {
      if (confirmation.result) {
        vm.todoRemoved.push(vm.todos[$todoIndex]);
        vm.todos.splice($todoIndex, 1);
      }
    });
  }

  function saveTodo($todoIndex, todo) {
    vm.editTodoIndex = -1;
  }

  function addTodoToList() {
    let todoReferenceId;
    if (!vm.todos.length) {
      todoReferenceId = vm.todos.length + 1;
    } else {
      todoReferenceId = vm.todos[vm.todos.length - 1]
        ? vm.todos[vm.todos.length - 1].todoReferenceId + 1
        : vm.todos.length + 1;
    }
    vm.todos.push({ title: "", todoReferenceId: todoReferenceId });
    vm.editTodoIndex = vm.todos.length - 1;
  }

  $scope.$on("modal.closing", function (evt) {
    if (vm.form.$dirty && !vm.form.$pristine && !vm.form.$submitted) {
      evt.preventDefault();
      let confirmData = {
        message: calendarConstants.messages.leavePageConfirmation,
        modalSize: "md",
        type: "danger",
      };
      commonService.confirm(confirmData).then(function (confirmation) {
        if (confirmation.result) {
          vm.form.$submitted = true;
          vm.close();
        }
      });
    }
  });
}
export default editAppointmentController;
