agendaDateFilter.$inject = ["$rootScope", "appConstants", "moment"];
function agendaDateFilter($rootScope, appConstants, moment) {
  function formatDate(date) {
    let format = "MMMM Do, YYYY";
    if (moment().isSame(date, "year")) {
      format = "MMMM Do";
    }

    return $rootScope.dateFormat(date, format);
  }

  return function (startDate, endDate, currentDate) {
    let eventDuration, startTime, endTime, startOfDay, endOfDay;

    startDate = $rootScope.dateFormat(startDate);
    endDate = $rootScope.dateFormat(endDate);

    startOfDay = moment(currentDate).startOf("d");
    endOfDay = moment(currentDate).endOf("d");

    startTime = $rootScope.dateFormat(startDate, "h:mm A");
    endTime = $rootScope.dateFormat(endDate, "h:mm A");

    eventDuration = moment(endDate).diff(startDate);
    if (eventDuration >= appConstants.DAY) {
      let eventStartsBeforeCurrentDate = false,
        eventEndsAfterCurrentDate = false;
      // Check if the event starts before currentDate- 1
      eventStartsBeforeCurrentDate = moment(startDate).isBefore(startOfDay);
      eventEndsAfterCurrentDate = moment(endOfDay).isBefore(endDate);

      if (eventStartsBeforeCurrentDate && eventEndsAfterCurrentDate) {
        return "All Day";
      } else if (eventStartsBeforeCurrentDate && !eventEndsAfterCurrentDate) {
        return formatDate(startDate) + " - " + endTime;
      } else if (!eventStartsBeforeCurrentDate && eventEndsAfterCurrentDate) {
        return startTime + " - " + formatDate(endDate);
      }
    }
    return startTime + " - " + endTime;
  };
}

export default agendaDateFilter;
