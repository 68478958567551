let angular = window.angular;
	userSubscriptionCancelController.$inject = ['userService', 'toastService', '$uibModalInstance', '$uibModal'];

	function userSubscriptionCancelController(userService, toastService, $uibModalInstance, $uibModal) {
        let vm = this;

		angular.extend(vm, {
            requestTrial,
            openUserSubscriptionCancelModal,
            close,
		});

		function requestTrial(){
            let data = { trial_request: true }
            userService.cancelUserSubscription(data)
                .then((response) => {
                    toastService.toast({
                        message: 'Request submitted, We will confirm it and reach out to you.',
                        type: 'success',
                        delay: 2000
                    });

                    close();
                })
                .catch((response) => {
					if(response.data.message == 'Subscription is already cancelled') {
						toastService.toast({
                            message: "You have already submitted a cancellation request.",
                            type: 'error',
                            delay: 4000
                        });
                    }
                    else if(response.data.message == 'Free trial is already requested'){
                        toastService.toast({
                            message: "You have already submmited a free month subscription request.",
                            type: 'error',
                            delay: 4000
                        });
                    }
                    else{
                        toastService.toast({
                            message: "Some error occured, try again!",
                            type: 'error',
                            delay: 4000
                        });
                    }
                })
        }

        function close(){
            $uibModalInstance.close();
        }

        function openUserSubscriptionCancelModal()  {
            close();

            let modelInstance = $uibModal.open({
				animation: true,
				size: "md",
				controller: "userSubscriptionCancelReasonController",
				controllerAs: "userSubscriptionCancelReasonCtrl",
                template : require('../../pug/user/user-subscription-cancel-reason.pug').default,
				resolve: null,
            });

			modelInstance.result.catch(function () {
				modelInstance.close();
			});
        }

        function init() {

		}

		this.$onInit = init;
    }

    export default userSubscriptionCancelController;
