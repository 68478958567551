let angular = window.angular;

studentViewController.$inject = [
  "$scope",
  "bookService",
  "studentService",
  "commonService",
  "$state",
  "$stateParams",
  "_",
  "lessonService",
  "appConstants",
  "toastService",
  "studentConstants",
  "moment",
  "$uibModal",
  "$rootScope",
  "$q",
];

function studentViewController(
  $scope,
  bookService,
  studentService,
  commonService,
  $state,
  $stateParams,
  _,
  lessonService,
  appConstants,
  toastService,
  studentConstants,
  moment,
  $uibModal,
  $rootScope,
  $q
) {
  let vm = this;
  let studentId;
  vm.studentId;

  vm.colors = appConstants.newColors;
  vm.groupedAssignedLessons = [];
  vm.isFetchingAssignedLessons = false;
  vm.isFetchingAssignedLessonsFilter = false;

  vm.isEditAcademicInformation = false;
  vm.viewEditAcademicInformation = true;
  vm.viewAddAcademicInformation = false;
  vm.ishideEditDeleteButtons = false;
  vm.studentPasswordType = "password";

  // vm.student = {
  //     countryCode: 1,
  //     parentCountryCode: 1,
  //     academicDays: 180
  // };
  vm.academics = {
    academicYearStart: null,
    academicYearEnd: null,
    grade: null,
    academicDays: null,
    goals: null,
    reflections: null,
    semesters: [],
  };
  vm.availableLessons = [];
  vm.student = {};
  vm.currentEdited = {
    id: 0,
  };

  vm.isFormInvalid = false;
  vm.formErrorMessage = "";

  vm.days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  vm.assignmentAllDay = false;
  $scope.maxRating = 5;

  vm.currentAcademicYearIndex = null;
  vm.copyBooks = [];
  vm.studentEmail = "";
  vm.studentPassword = "";
  angular.extend(vm, {
    editFeedback,
    rate,
    readStatusChange,
    removeBookAssignment,
    toggleBook,
    deleteStudent,
    toggleTab,
    assignLesson,
    removeLessonAssignment,
    editLessonAssignment,
    onSelectDay,
    setStudentLabelColor,
    setStudentLessonLabelColor,
    loginForStudent,
    loginForStudentToggle,
    changeImageUrl,
    editAcademicInformation,
    updateAcademicInformation,
    cancelAcademicInformation,
    deleteAcademicInformation,
    selectLesson,
    changePermissionStatus,
    changeSearchPermission,
    selectLessonList,
    viewPassword,
    handleStudentAccount,
    // addStudentAcademicInformation,
    // checkMultigrade,
    // checkAcademicYearInteraction
  });
  function handleStudentAccount(type) {
    if (type === "email") {
      vm.studentEmail = event.target.value;
    } else {
      vm.studentPassword = event.target.value;
    }
  }
  function viewPassword(eventType) {
    if (eventType == "DOWN") {
      vm.studentPasswordType = "text";
    } else if (eventType == "UP") {
      vm.studentPasswordType = "password";
    } else {
      vm.studentPasswordType = "password";
    }
  }

  function selectLesson(selectedLesson) {
    if (selectedLesson.duration == "23:59") {
      vm.assignmentTime = "00:00";
    }
  }

  function changeImageUrl(img) {
    if (img) {
      img = img.replace("http:", "https:");
    }
    return img;
  }

  function readStatusChange(bookId, status) {
    let id = commonService.convertToInteger(bookId);
    for (let i = 0; i < vm.books.length; i++) {
      if (vm.books[i].book.id == bookId) vm.books[i].isRead = status;
    }
  }

  function editFeedback(bookId, comment, rating, status) {
    let student = [
      {
        studentId: studentId,
        comment: comment,
        rating: rating,
        isRead: status,
      },
    ];
    bookService
      .addReview(bookId, student)
      .then((completed) => {
        toggleBook();
        // close();
      })
      .catch((err) => {
        // console.log("Studens not assigned");
      });
  }

  function toggleBook(index, checkEdit) {
    if (checkEdit) {
      vm.editBookIndex = angular.isNumber(index)
        ? commonService.convertToInteger(index)
        : -1;
    } else {
      if (!index && !checkEdit) {
        vm.editBookIndex = -1;
        return;
      }
      let id = commonService.convertToInteger(index);
      let findCopyBook = _.find(vm.copyBooks, (obj) => obj.book.id === index);
      if (findCopyBook) {
        for (let i = 0; i < vm.books.length; i++) {
          if (vm.books[i].book.id == id) {
            vm.books[i].rating = findCopyBook.rating;
            vm.books[i].isRead = findCopyBook.isRead;
            vm.books[i].comment = findCopyBook.comment;
          }
        }
      }
      vm.editBookIndex = -1;
    }
  }

  function rate(bookId, star) {
    let id = commonService.convertToInteger(bookId);
    vm.editBookIndex = id;
    for (let i = 0; i < vm.books.length; i++) {
      if (vm.books[i].book.id == id) vm.books[i].rating = star;
      vm.books[i].isRead = true;
    }
  }

  function removeBookAssignment(bookId) {
    bookService
      .setStudentBook(bookId, studentId)
      .then((res) => {
        // vm.books = null;
        toastService.toast({
          message: "Unassigned Successfully",
          type: "success",
          delay: 2000,
        });
        getUserBooks();
      })
      .catch((err) => {
        // console.log("err", err);
      });
  }

  function toggleTab(tab) {
    vm.activeTab = tab;
  }

  function removeAssignedLessonToList(studentLessonId) {
    for (let i = vm.groupedAssignedLessons.length - 1; ~i; --i) {
      let oldlength = vm.groupedAssignedLessons[i].lesson.length;
      commonService.removeFromArray(
        studentLessonId,
        "id",
        vm.groupedAssignedLessons[i].lesson
      );
      if (oldlength !== vm.groupedAssignedLessons[i].lesson.length) {
        // If the course doesnot have any lesson in the course, remove the course
        !vm.groupedAssignedLessons[i].lesson.length &&
          vm.groupedAssignedLessons.splice(i, 1);
        return true;
      }
    }
    return false;
  }

  function saveAssignedLessonToList(studentLessonId, lesson) {
    lesson = correctEndLessonDate(lesson);

    removeAssignedLessonToList(studentLessonId);
    let index = commonService.findInArray(
      lesson.courseId,
      "id",
      vm.groupedAssignedLessons
    );
    if (!~index) {
      // angular.copy is used to prevent circular dependency
      vm.groupedAssignedLessons.push(angular.copy(lesson.course));
      index = vm.groupedAssignedLessons.length - 1;
    }
    if (~index) {
      vm.groupedAssignedLessons[index].lesson = angular.isArray(
        vm.groupedAssignedLessons[index].lesson
      )
        ? vm.groupedAssignedLessons[index].lesson
        : [];
      return commonService.saveInArray(
        studentLessonId,
        "id",
        vm.groupedAssignedLessons[index].lesson,
        lesson
      );
    }
    return false;
  }

  function setAssignLessonDefaults() {
    vm.isAssigningLesson = false;
    vm.isMaximumDaysSelected = false;
    vm.assignmentDate = null;
    vm.assignmentTime = null;
    vm.assigningLesson = false;
    vm.assignmentDays = [];
    vm.isFormInvalid = false;
    vm.formErrorMessage = "";
  }

  function deleteStudent(studentId) {
    if (!studentId) {
      return;
    }

    let confirmData = {
      message: `All data associated with ${vm.student.name} will be removed if you delete a student. Are you sure you want to delete this student?`,
      type: "danger",
      modalSize: "md",
    };

    commonService.confirm(confirmData).then((confirmation) => {
      if (confirmation.result) {
        studentService
          .deleteStudent(studentId)
          .then((response) => {
            // Go to the first student after the student is deleted or go to student add page
            if (studentService.students.length) {
              $state.go(
                "studentInfo",
                {
                  studentId: studentService.students[0].id,
                },
                {
                  location: "replace",
                }
              );
            } else {
              $state.go(
                "studentAdd",
                {},
                {
                  location: "replace",
                }
              );
            }
          })
          .catch((response) => {
            console.error(response);
          });
      }
    });
  }

  function onSelectDay() {
    if (vm.assigningLesson) {
      if (vm.assignmentDays.length >= vm.assigningLesson.days) {
        vm.isMaximumDaysSelected = true;
      } else {
        vm.isMaximumDaysSelected = false;
      }
    }
  }

  function validateAssignLessonForm() {
    if (!vm.assigningLesson) {
      vm.isFormInvalid = true;
      vm.formErrorMessage = studentConstants.messages.selectStudentToAssign;
      return false;
    }

    if (!vm.assignmentDate) {
      vm.isFormInvalid = true;
      vm.formErrorMessage = studentConstants.messages.selectStartDateLesson;
      return false;
    }

    if (!vm.assignmentTime) {
      vm.isFormInvalid = true;
      vm.formErrorMessage = studentConstants.messages.selectStartTimeLesson;
      return false;
    }

    if (vm.assignmentDays.length !== vm.assigningLesson.days) {
      vm.isFormInvalid = true;
      vm.formErrorMessage =
        "Please select at least " +
        vm.assigningLesson.days +
        " days to assign lesson to the student.";
      return false;
    }

    return true;
  }

  function assignLesson() {
    if (!validateAssignLessonForm()) {
      return;
    }

    let data = {},
      dateTime;
    data.studentId = vm.student.id;
    data.lessonId = vm.assigningLesson.id;
    data.assignmentDays = vm.assignmentDays;
    data.startDate = vm.assignmentDate;

    dateTime = commonService.convertToUTC(
      vm.assignmentDate + " " + vm.assignmentTime
    );
    data.utcStartDateTime = dateTime.format("Y-MM-DD HH:mm:00");

    lessonService
      .assignStudent(data.lessonId, data)
      .then((response) => {
        saveAssignedLessonToList(response.id, response);
        setAssignLessonDefaults();
      })
      .catch((response) => {
        console.error(response);
      });
  }

  function editLessonAssignment(studentLessonPlanId) {
    $state.go("studentLessonEdit", {
      lessonId: studentLessonPlanId,
    });
    close();
  }

  function removeLessonAssignment(studentLessonId) {
    if (!studentLessonId) {
      return;
    }

    let modalInstance = $uibModal.open({
      animation: true,
      size: "md",
      controller: "EditLessonConfirmationController",
      controllerAs: "confirmLessonCtrl",
      template: require("../../pug/calendar/edit-lesson-confirmation.pug")
        .default,
    });

    let confirmPromise = modalInstance.result
      .then(
        function (option) {
          if (option) {
            $rootScope.loadingBtn("unassignBtn-" + studentLessonId, "Updating");
            studentService
              .removeAssignment(studentLessonId, option)
              .then((response) => {
                if (option == "future") {
                  response.message = "Future lessons removed from course";
                } else {
                  removeAssignedLessonToList(studentLessonId);
                }
                toastService.toast({
                  message: response.message,
                  type: "success",
                  delay: 2000,
                });

                // close({ removeEvent: true, model: 'lesson', search: true, deleteLessonId: studentLessonId });
              })
              .catch((response) => {
                toastService.toast({
                  message: response.message,
                  type: "error",
                  delay: 2000,
                });
              })
              .finally(() => {
                $rootScope.loadingBtnComplete(
                  "unassignBtn-" + studentLessonId,
                  "Update"
                );
              });
          }
          modalInstance.close();
        },
        function () {
          return $q.reject(false);
        }
      )
      .catch(function () {
        modalInstance.close();
      });

    // let confirmData = {
    // 	message: 'Are you sure to unassign this lesson from ' + vm.student.firstName + '\'s Lesson Plan?',
    // 	type: 'danger',
    // 	modalSize: 'md',
    // };

    // commonService.confirm(confirmData)
    // 	.then((confirmation) => {
    // 		if (confirmation.result) {
    // 			studentService.removeAssignment(studentLessonId)
    // 				.then((response) => {
    // 					removeAssignedLessonToList(studentLessonId);
    // 					toastService.toast({message: response.message, type: 'success', delay: 2000});
    // 				})
    // 				.catch((response) => {
    // 					toastService.toast({message: response.message, type: 'error', delay: 2000});
    // 				});
    // 		}
    // 	});
  }

  function setStudentLabelColor(color) {
    vm.student.color = color;
    studentService
      .editStudent(vm.student)
      .then((response) => {
        studentService.saveStudent(
          response.data.data.Student.id,
          response.data.data.Student
        );
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  function setStudentLessonLabelColor(studentLessonId, color) {
    lessonService
      .editStudentLessonColor(studentLessonId, color)
      .then((studentLesson) => {
        if (!vm.groupedAssignedLessons) {
          toastService.toast({
            message: "Something went wrong, please try again",
            type: "error",
            delay: 5000,
          });
          return;
        }

        for (let j = 0; j < vm.groupedAssignedLessons.length; j++) {
          let studentLessonList = vm.groupedAssignedLessons[j].lesson;
          for (let i = 0; i < studentLessonList.length; i++) {
            if (studentLessonList[i].id == studentLesson.id) {
              studentLessonList[i].color = studentLesson.color;
            }
          }
        }

        toastService.toast({
          message: "Successfully changed lesson color",
          type: "success",
          delay: 5000,
        });
      })
      .catch(function (error) {
        console.error(error);
      });
  }

  function getStudentDetails() {
    studentService
      .getStudentDetail(studentId)
      .then((response) => {
        vm.student = response;
        vm.studentEmail = vm.student.email;
        vm.studentPassword = vm.student.password;
        if (response.rolePermission) {
          vm.student.editEvent = response.rolePermission.event === "edit";
          vm.student.editAssignment =
            response.rolePermission.assignment === "edit";
          vm.student.editGrade = response.rolePermission.grade === "edit";
        }
        vm.studentImage = vm.student.mediaUrl || appConstants.defaultAvatar;
        if (!response.chatPermission) {
          vm.student.chatPermission = "PRIVATE";
        }

        if (
          vm.student &&
          vm.student.academicDetails &&
          vm.student.academicDetails.length
        ) {
          let academicDetails = vm.student.academicDetails;
          let currentAcademicYearIndex = -1;
          let currentDate = moment().format("YYYY-MM-DD");
          _.map(academicDetails, function (academic, index) {
            if (
              currentAcademicYearIndex === -1 &&
              academic.academicYearStart &&
              academic.academicYearEnd
            ) {
              if (
                moment(academic.academicYearStart).isSameOrBefore(
                  moment(currentDate)
                ) &&
                moment(academic.academicYearEnd).isSameOrAfter(
                  moment(currentDate)
                )
              ) {
                currentAcademicYearIndex = index;
              }
            }
          });
          if (currentAcademicYearIndex !== -1) {
            let currentAcademicYear = academicDetails[currentAcademicYearIndex];
            getLessonDetails(
              currentAcademicYear.academicYearStart,
              currentAcademicYear.academicYearEnd
            );
            vm.currentAcademicYearIndex = currentAcademicYearIndex;
          } else {
            let currentAcademicYear = academicDetails[0];
            getLessonDetails(
              currentAcademicYear.academicYearStart,
              currentAcademicYear.academicYearEnd
            );
            vm.currentAcademicYearIndex = 0;
          }
        } else {
          getLessonDetails();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function selectLessonList() {
    if (vm.currentAcademicYearIndex == "all") {
      getLessonDetails();
    } else if (vm.currentAcademicYearIndex !== -1) {
      let currentAcademicYear =
        vm.student.academicDetails[vm.currentAcademicYearIndex];
      getLessonDetails(
        currentAcademicYear.academicYearStart,
        currentAcademicYear.academicYearEnd
      );
    }
  }

  function correctEndLessonDate(lesson) {
    // lesson.endDate = lessonService.getEndDateOfLesson(lesson.assignmentDays, lesson.startDate, lesson.weeks, lesson.days, lesson.startTime, lesson.duration);

    if (moment(lesson.startTime, "HH:mm").isValid()) {
      lesson.startTime = commonService.convertFromUTC(
        lesson.startDate + " " + lesson.startTime + ":00"
      );
      lesson.endTime = moment(lesson.startTime)
        .add(lesson.duration.split(":")[0], "hour")
        .add(lesson.duration.split(":")[1], "minute");
    }

    return lesson;
  }

  function sortLessons() {
    vm.groupedAssignedLessons.sort((prev, next) => {
      return prev.name > next.name;
    });
    for (let i = 0; i < vm.groupedAssignedLessons.length; i++) {
      vm.groupedAssignedLessons[i].lesson.sort((prev, next) => {
        return prev.name > next.name;
      });
    }
  }

  function getLessonDetails(startDate, endDate) {
    vm.isFetchingAssignedLessonsFilter = true;

    studentService
      .getLessons(studentId, startDate, endDate)
      .then((response) => {
        vm.groupedAssignedLessons = _.map(response, (groupLesson) => {
          groupLesson.lesson = _.map(groupLesson.lesson, correctEndLessonDate);

          for (let i = 0; i < groupLesson.lesson.length; i++) {
            if (!groupLesson.lesson[i].color) {
              groupLesson.lesson[i].color =
                appConstants.defaultColor.studentAssignment;
            }
          }
          return groupLesson;
        });
        sortLessons();
      })
      .catch((response) => {
        console.error(response);
      })
      .finally(() => {
        vm.isFetchingAssignedLessonsFilter = false;
      });
  }

  function getUserBooks() {
    // vm.isFetchingData = true;
    bookService
      .getStudentBook(studentId)
      .then((response) => {
        vm.books = response;
        for (let i = 0; i < vm.books.length; i++) {
          if (vm.books[i].book.description) {
            if (vm.books[i].book.description.length > 170) {
              vm.books[i].book.desc =
                vm.books[i].book.description
                  .toLowerCase()
                  .substring(0, 170)
                  .trim() + "...  ";
            } else {
              vm.books[i].book.desc = vm.books[i].book.description;
            }
          }
          // console.log("length",vm.books[i].description.length);
        }
        vm.copyBooks = angular.copy(vm.books);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        vm.isFetchingData = false;
      });
  }

  function getLessonsToAssign() {
    lessonService
      .getAllLessons()
      .then((response) => {
        // Flatten all the lessons

        let allLessons = response;
        for (let i = 0; i < response.length; i++) {
          vm.availableLessons.push(...allLessons[i].lesson);
        }
      })
      .catch((response) => {
        console.error(response);
      });
  }

  function loginForStudentToggle(studentData, loginStatus) {
    vm.student.loginStatus = loginStatus;

    if (
      !loginStatus ||
      (loginStatus && studentData.email && studentData.password)
    ) {
      studentData.chatPermission = "PRIVATE";
      let data = angular.copy(studentData);
      let message = "";
      if (loginStatus) {
        data.loginStatus = "ENABLE";
        message = "student login is enabled";
      } else {
        data.loginStatus = "DISABLE";
        message = "student login is disabled";
      }
      studentService
        .loginStatusChange(data)
        .then((res) => {
          vm.student.loginStatus = loginStatus;
          toastService.toast({
            message: message,
            type: "success",
            delay: 1000,
          });
        })
        .catch((err) => {
          toastService.toast({
            message: "operation failed",
            type: "error",
            delay: 2000,
          });
        });
    }

    // if (!vm.student.loginStatus) {
    // 	loginForStudent(studentData, loginStatus);
    // }
  }

  function loginForStudent(data, loginStatus) {
    let studentData = angular.copy(data);
    /**
     * Validate student input
     */
    if (!vm.studentEmail) {
      toastService.toast({
        message: "Please enter correct email address.",
        type: "error",
        delay: 5000,
      });
      exitFunc = true;
      return;
    }

    /**
     * Password is only for the add user
     */
    if (loginStatus == "ENABLE") {
      if (!vm.studentPassword) {
        toastService.toast({
          message: "Please enter password",
          type: "error",
          delay: 5000,
        });
        exitFunc = true;
        return;
      } else if (
        vm.studentPassword.length < 6 ||
        vm.studentPassword.length > 30
      ) {
        toastService.toast({
          message: "Password should be more than 6 characters",
          type: "error",
          delay: 5000,
        });
        exitFunc = true;
        return;
      }
    } else {
      vm.studentPassword = "";
    }

    $rootScope.loadingBtn("save", "loading");
    studentData.loginStatus = "ENABLE";
    studentData.chatPermission = "PRIVATE";
    studentData.email = vm.studentEmail;
    studentData.password = vm.studentPassword;

    studentService
      .loginStatusChange(studentData)
      .then((studentDataResponse) => {
        vm.student.email = studentDataResponse.data.Student.email;
        vm.student.password = studentDataResponse.data.Student.passwordToken;
        vm.student.chatPermission = "PRIVATE";

        // if (vm.student && vm.student.loginStatus == "ENABLE") {
        // 	vm.student.loginStatus = true;
        // } else {
        // 	vm.student.loginStatus = false;
        // }

        $rootScope.loadingBtnComplete("save");
        toastService.toast({
          message: "student login is enabled",
          type: "success",
          delay: 1000,
        });
      })
      .catch((error) => {
        toastService.toast({
          message: error.message || "Please fill the form and try again",
          type: "error",
          delay: 5000,
        });
        $rootScope.loadingBtnComplete("save");
      });
  }

  vm.isTouched = function (field) {
    if (angular.isDefined(field)) {
      return field.$touched || vm.form.$submitted;
    } else {
      return false;
    }
  };

  //addStudentAcademicInformation
  vm.addStudentAcademicInformation = function () {
    let data = {};

    if (!studentService.validateSemesterDates(vm.academics)) {
      return toastService.toast({
        message: "Invalid Semester Dates",
        type: "error",
        delay: 2000,
      });
    }
    data = _.clone(vm.academics);
    data.semesters = JSON.stringify(data.semesters);
    data.studentId = $stateParams.studentId;

    if (!vm.form.$valid) {
      toastService.toast({
        message: "Please fill the form and try again",
        type: "error",
        delay: 4000,
      });
      return;
    }

    studentService
      .addStudentAcademics(data)
      .then(addStudentAcademicInformationSuccess)
      .catch(function (error) {
        toastService.toast({
          message: error.data.message || "Please fill the form and try again",
          type: "error",
          delay: 2000,
        });
        $rootScope.loadingBtnComplete("save");
      });
  };

  function setAcademicInformationDefaults() {
    vm.academics.academicYearStart = null;
    vm.academics.academicYearEnd = null;
    vm.academics.grade = null;
    vm.academics.academicDays = null;
    vm.academics.goals = null;
    vm.academics.reflections = null;
  }

  function addStudentAcademicInformationSuccess(response) {
    let deferred = $q.defer();
    setAcademicInformationDefaults();
    studentService.academics.push(response.data.data.studentAcademcis);
    vm.form.$setSubmitted();
    $rootScope.loadingBtnComplete("save", "Save");
    vm.viewAddAcademicInformation = false;
    getStudentDetails();
    toastService.toast({
      message: "Academic detail added",
      type: "success",
      delay: 2000,
    });
    return deferred;
  }

  function editAcademicInformation(acad) {
    vm.academics.academicYearStart = acad.academicYearStart;
    vm.academics.academicYearEnd = acad.academicYearEnd;
    vm.academics.grade = acad.grade;
    vm.academics.academicDays = acad.academicDays;
    vm.academics.goals = acad.goals;
    vm.academics.reflections = acad.reflections;
    vm.academics.semesters = acad.semesters;

    vm.academicInformationId = acad.id;
    vm.viewAddAcademicInformation = true;
    vm.isEditAcademicInformation = true;
  }

  vm.removeSemester = function (index) {
    const acadCopy = angular.copy(vm.academics);

    const semesters = acadCopy.semesters.filter((e, i) => i !== index);
    vm.academics.semesters = semesters;
  };

  vm.addSemesters = function () {
    const acadCopy = angular.copy(vm.academics);
    if (!acadCopy.semesters) {
      acadCopy.semesters = [];
    }
    acadCopy.semesters.push({
      semesterStart: "",
      semesterEnd: "",
    });
    vm.academics = acadCopy;
  };

  vm.createNewAcad = function () {
    vm.academics.semesters = [];
  };

  function updateAcademicInformation() {
    if (!vm.form.$valid) {
      toastService.toast({
        message: "Please fill the form and try again",
        type: "error",
        delay: 4000,
      });
      return;
    }


    let data = {};
    data = _.clone(vm.academics);
    vm.errorIndex = undefined;;
    vm.errorOverlapping = undefined;
    const validateSemestor = studentService.validateSemesterDatesIndex(vm.academics)

    if (!validateSemestor.condition) {
      vm.errorIndex = validateSemestor.errorIndex;
      vm.errorOverlapping = validateSemestor.errorOverlapping;
      if (vm.errorIndex) {
        return toastService.toast({
          message: "Semester Dates are not between Academic Year",
          type: "error",
          delay: 2000,
        });
      }
      else if (vm.errorOverlapping) {
        return toastService.toast({
          message: "Semester Dates are overlaping between Semesters",
          type: "error",
          delay: 2000,
        });
      }
      else {
        return toastService.toast({
          message: "Invalid Semester Dates",
          type: "error",
          delay: 2000,
        });
      }
    }

    data.semesters = JSON.stringify(data.semesters);
    data.studentId = $stateParams.studentId;
    data.academicId = vm.academicInformationId;
    $rootScope.loadingBtn("updateAcademicTabButton", "Loading");
    studentService
      .updateStudentAcademics(data)
      .then(updateAcademicInformationSuccess)
      .catch(function (error) {
        toastService.toast({
          message: error.data.message || "Please fill the form and try again",
          type: "error",
          delay: 4000,
        });
      })
      .finally(function () {
        $rootScope.loadingBtnComplete("updateAcademicTabButton", "Updated");
      });
  }

  function updateAcademicInformationSuccess(response) {
    let deferred = $q.defer();
    setAcademicInformationDefaults();
    studentService.academics.push(response.data.data.academicInfo);
    $rootScope.loadingBtnComplete("save", "Save");
    vm.viewAddAcademicInformation = false;
    getStudentDetails();
    vm.student.academicDetails = response.data.data.academicInfo;
    _.map(vm.student.academicDetails, (academic, i) => {
      academic.ishideEditDeleteButtons = false;
      return academic;
    });
    toastService.toast({
      message: "Academic detail updated",
      type: "success",
      delay: 4000,
    });
    vm.form.$setSubmitted();
    return deferred;
  }

  function cancelAcademicInformation() {
    _.map(vm.student.academicDetails, (academic, i) => {
      academic.ishideEditDeleteButtons = false;
    });
    vm.viewAddAcademicInformation = false;
    setAcademicInformationDefaults();
  }

  function deleteAcademicInformation(acad) {
    let confirmData = {
      message: studentConstants.messages.deleteAcademicDetailsWarning,
      modalSize: "md",
      type: "danger",
    };

    commonService.confirm(confirmData).then(function (confirmation) {
      if (confirmation.result) {
        studentService
          .deleteStudentAcademics(acad)
          .then(deleteAcademicInformationSuccess)
          .catch(function (error) {
            console.error(error);
            toastService.toast({
              message:
                error.data.message || "Please fill the form and try again",
              type: "error",
              delay: 4000,
            });
            $rootScope.loadingBtnComplete("save");
          })
          .finally(() => {
            getStudentDetails();
          });
      }
    });
  }

  function deleteAcademicInformationSuccess(response) {
    let deferred = $q.defer();
    studentService.academics.push(response.data.data.academicInfo);
    toastService.toast({
      message: "Academic detail deleted",
      type: "success",
      delay: 2000,
    });
    return deferred;
  }

  function changePermissionStatus(permissionType) {
    studentService
      .permissionChange(vm.student, permissionType)
      .then((data) => {
        toastService.toast({
          message: data.message,
          type: "success",
          delay: 3000,
        });
      })
      .catch(() => {
        toastService.toast({
          message: "Error Occured",
          type: "error",
          delay: 3000,
        });
      });
  }
  function changeSearchPermission() {
    let inputData = {
      searchPermission: vm.student.searchPermission,
    };
    studentService
      .studentPermission(vm.student.id, inputData)
      .then((data) => {
        toastService.toast({
          message: data.message,
          type: "success",
          delay: 3000,
        });
      })
      .catch(() => {
        toastService.toast({
          message: "Error Occured",
          type: "error",
          delay: 3000,
        });
      });
  }

  function init() {
    /**
     * Varify permissions
     */
    if (!commonService.validRequest(studentConstants.studentModel)) {
      return false;
    }

    studentId = commonService.convertToInteger($stateParams.studentId);
    vm.studentId = studentId;
    vm.activeTab = "lesson";

    if (!studentId) {
      $state.go(
        "studentAdd",
        {},
        {
          location: "replace",
        }
      );
      return;
    }

    setAssignLessonDefaults();
    getStudentDetails();
    getUserBooks();
    // getLessonDetails();
    getLessonsToAssign();

    // HelpCrunch('updateUser', $rootScope.helpCrunchuser, 'e8aa8af95cd6a05b80308dcb3dd6901d', function() {});
  }

  this.$onInit = init;
}

export default studentViewController;
