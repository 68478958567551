export function fromNow() {
  return fromNowFilter;

  function fromNowFilter(input) {
    if (moment(input).isValid()) {
      return moment(input).fromNow();
    } else {
      return input;
    }
  }
}

export function momentFilter() {
  return function (input, momentFn /*, param1, param2, ...param n */) {
    var args = Array.prototype.slice.call(arguments, 2),
      momentObj = moment(input);
    return momentObj[momentFn].apply(momentObj, args);
  };
}

//momentFilter prevoius name was moment;
