require("oclazyload");

import "regenerator-runtime/runtime";
import "react-day-picker/lib/style.css";
import "./sass/main.scss";
import "angular-ui-bootstrap/dist/ui-bootstrap-csp.css";
import "./less/reactmain.less";

import {
  redirectIfNotAdmin,
  redirectWithoutSubscription,
} from "./js/common/redirectPolicies";

import { BOOK_MODULE } from "./js/book/book.module";
import { COLLABORATE_MODULE } from "./js/collaborate/collaborate.module";
import { COMBINE_MODULE } from "./js/combine/combine.module";
import { COMMON_MODULE } from "./js/common/common.module";
import { DASHBOARD_MODULE } from "./js/dashboard/dashboard.module";
import { FRIEND_MODULE } from "./js/friend/friend.module";
import { NOTIFICATION_MODULE } from "./js/notification/notification.module";
import { REFFERAL_MODULE } from "./js/referralplan/referralplan.module";
import { STUDENT_MODULE } from "./js/student/student.module";
import { TODO_MODULE } from "./js/task/task.module";
import { USER_MODULE } from "./js/user/user.module";
import academicYearController from "./js/common/academicYearController";
import appController from "./js/common/appController";
import configModule from "./js/common/config2";
import hspShareController from "./js/common/hspShareController";
import inviteFriendsController from "./js/common/inviteFriendController";
import * as Sentry from '@sentry/browser';
import { Angular as AngularIntegration } from '@sentry/integrations';

Sentry.init({
  dsn: process.env.NODE_ENV === 'development' ? process.env.SENTRY_DSN_TEST : process.env.SENTRY_DSN_PROD,
  integrations: [
    new AngularIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

((angular) => {
  let app = angular.module("HSP", [
    "ui.router",
    "ngAnimate",
    "ui.bootstrap",
    "oc.lazyLoad",
    COMBINE_MODULE.name,
    DASHBOARD_MODULE.name,
    COMMON_MODULE.name,
    USER_MODULE.name,
    STUDENT_MODULE.name,
    COLLABORATE_MODULE.name,
    FRIEND_MODULE.name,
    NOTIFICATION_MODULE.name,
    BOOK_MODULE.name,
    TODO_MODULE.name,
    REFFERAL_MODULE.name,
    "checklist-model",
    "angular-intro",
    "ngFileUpload",
    "ngImgCrop",
    "dndLists",
    "ngSanitize",
    "ngEmbed",
    "ngCookies",
    "720kb.socialshare",
    "angularjs-dropdown-multiselect",
    "ngTagsInput",
    "ui.select",
    "chart.js",
    "ngSentry"
  ]);

  app.config(configModule);

  app.controller("appController", appController);
  app.controller("academicYearController", academicYearController);
  app.controller("hspShareController", hspShareController);
  app.controller("inviteFriendsController", inviteFriendsController);

  app.factory("redirectWithoutSubscription", redirectWithoutSubscription);
  app.factory("redirectIfNotAdmin", redirectIfNotAdmin);

  app.run(init);
  init.$inject = [
    "$rootScope",
    "Store",
    "$window",
    "stateHistory",
    "userService",
    "$http",
    "_",
    "appConstants",
  ];

  function init(
    $rootScope,
    Store,
    $window,
    stateHistory,
    userService,
    $http,
    _,
    appConstants
  ) {
    /**
     * Make sure that the following function is declared before its used, since functions assigned to variables are not hoisted
     * by the javascript compiler unlike the normal javascript functions.
     */
    $rootScope.noInternetConnection = false;

    $rootScope.defaultImage =
      "https://s3-us-west-2.amazonaws.com/pandanewbucket/uploads/default-avatar.png";

    let userData,
      applicationServerPublicKey,
      isSubscribed = false,
      swRegistration = null;

    $rootScope.logout = function () {
      userService.logout().then(function () {
        window.location.href = "/login";
      });
    };

    // Redirect to login on no authorization token found
    $window.addEventListener("storage", (event) => {
      if (!Store.get("authorizationToken")) {
        redirectToLogin();
      }
    });

    try {
      // userService.loginUser();

      userData = JSON.parse(Store.get("userData"));
      if (!_.isEmpty(userData)) {
        $rootScope.user = userData;

        let id = $rootScope.user.helperId
          ? $rootScope.user.helperId
          : $rootScope.user.id;
        userService
          .view(id)
          .then((userData) => {
            $rootScope.user = userData;
            if ($rootScope.user.userRole === "HELPER") {
              $rootScope.user.helperId = $rootScope.user.id;
              $rootScope.user.id = $rootScope.user.payment.userId;
            }

            /**
             * Send helpcrunch user data
             */
            // $rootScope.helpCrunchuser = {
            //     email: userData.email,
            //     name: userData.name,
            //     user_id: userData.id.toString()
            //   };

            let data = {
              dayTimeHours: userData.dayTimeHours.toString(),
              startDay: userData.startDay,
              weekends: userData.weekends.toString(),
              timeDuration: userData.timeDuration,
              startTime: moment(userData.startTime).utc().format("YYYY-MM-DD HH:mm"),
              endTime: moment(userData.endTime).utc().format("YYYY-MM-DD HH:mm"),
            };

            $rootScope.user.remainintTrialDays = 0;

            if ($rootScope.user.payment) {
              var trialEndDate = moment($rootScope.user.payment.endDate);
              var todaysdate = moment();
              $rootScope.user.remainintTrialDays = trialEndDate.diff(
                todaysdate,
                "days"
              );

              // $rootScope.helpCrunchuser.custom_data = {
              //     plan_is_trial: $rootScope.user.trial,
              //     user_role: $rootScope.user.userRole,
              //     plan_title: $rootScope.user.payment.planId ? $rootScope.user.payment.planId.title : '',
              //     plan_detail: $rootScope.user.payment.planId ? $rootScope.user.payment.planId.detail : '',
              //     plan_price: $rootScope.user.payment.planId ? $rootScope.user.payment.planId.price : '',
              //     plan_next_payment: $rootScope.user.payment.nextPayment
              // }
            }

            $rootScope.$broadcast("calendarView", userData.calendarView);
            Store.set("calendarSettings", JSON.stringify(data));
            Store.set(
              "defaultCalendarView",
              userData.calendarView || "basicWeek"
            );
            Store.set("userData", JSON.stringify($rootScope.user));

            return $http.get(appConstants.baseUrl + "/api/users/getPublicKey");
          })
          .then(function (res) {
            applicationServerPublicKey = res.data.message;
            if ("serviceWorker" in navigator && "SyncManager" in window) {
              navigator.serviceWorker.ready
                .then(function (reg) {
                  return reg.sync.register("tag-name");
                })
                .catch(function () {
                  // system was unable to register for a sync,
                  // this could be an OS-level restriction
                  console.error("system was unable to register for a sync");
                });
            }

            if ("serviceWorker" in navigator && "PushManager" in window) {
              if (
                applicationServerPublicKey !==
                Store.get("applicationServerPublicKey")
              ) {
                navigator.serviceWorker
                  .getRegistrations()
                  .then(function (registrations) {
                    Promise.all(
                      registrations.map(function (registration) {
                        return registration.unregister();
                      })
                    ).then(function () {
                      Store.set(
                        "applicationServerPublicKey",
                        applicationServerPublicKey
                      );

                      navigator.serviceWorker
                        .register("/assets/js/sw.min.js")
                        .then(function (swReg) {
                          swRegistration = swReg;
                          initializeUI();
                        })
                        .catch(function (error) {
                          console.error("Service Worker Error", error);
                        });
                    });
                  });
              } else {
                navigator.serviceWorker
                  .register("/assets/js/sw.min.js")
                  .then(function (swReg) {
                    swRegistration = swReg;
                    initializeUI();
                  })
                  .catch(function (error) {
                    // console.error('Service Worker Error', error);
                  });
              }
            } else {
              // console.warn('Push messaging is not supported');
            }
          })
          .catch((error) => {
            // If there is an error in fetching user data then send user to login page
            $rootScope.logout();
          })
          .finally(() => {
            /**
             * Send HelpCrunch Data metrics
             */
            // HelpCrunch('updateUser', $rootScope.helpCrunchuser, 'e8aa8af95cd6a05b80308dcb3dd6901d', function() {});
            if (!$rootScope.user.agreeTerms) {
              return userService.showAgreeTerms();
            }
          });
      } else {
        redirectToLogin();
      }

      // Update user data using view API.
      // sw.js file
    } catch (err) {
      // When user data is not available send user to login page
      $rootScope.logout();
    }

    function redirectToLogin() {
      let pathname = window.location.pathname;
      let redirect_url =
        pathname === "/" || pathname === "/index"
          ? "/login"
          : "/login?redirect_url=" + pathname;
      window.location.href = redirect_url;

      // userService.loginUser();
    }

    function initializeUI() {
      // Set the initial subscription value
      swRegistration.pushManager
        .getSubscription()
        .then(function (subscription) {
          isSubscribed = !(subscription === null);
          updateSubscriptionOnServer(subscription);
          if (!isSubscribed) {
            Notification.requestPermission().then(function (permission) {
              if (permission == "granted") {
                subscribeUser();
              }
            });
          }
        });
    }

    function unsubscribeUser() {
      swRegistration.pushManager
        .getSubscription()
        .then(function (subscription) {
          if (subscription) {
            return subscription.unsubscribe();
          }
        })
        .catch(function (error) {
          // console.log('Error unsubscribing', error);
        })
        .then(function () {
          updateSubscriptionOnServer(null);
          isSubscribed = false;
        });
    }

    function subscribeUser() {
      const applicationServerKey = urlB64ToUint8Array(
        applicationServerPublicKey
      );
      swRegistration.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: applicationServerKey,
        })
        .then(function (subscription) {
          updateSubscriptionOnServer(subscription);
          isSubscribed = true;
        })
        .catch(function (err) {
          // console.log('Failed to subscribe the user: ', err);s
        });
    }

    function urlB64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");

      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    }

    function updateSubscriptionOnServer(subscription) {
      if (subscription) {
        $http
          .post(
            appConstants.baseUrl + "/api/devices/subscribeForNotification",
            {
              subscription,
            }
          )
          .then(function (res) {
            Store.set("subscriptionToken", res.data.data.deviceId);
          });
      }
    }
  }

  angular.element(document).ready(function () {
    angular.bootstrap(document.body, ["HSP"]);
  });
})(window.angular);
