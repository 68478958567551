let angular = window.angular;

import "angular-ui-calendar";

import EditAssignmentConfirmationController from "./editAssignmentConfirmationController";
import EditLessonConfirmationController from "./editLessonConfirmationController";
import addBookAndElectronicMediaController from "./addBookAndElectronicMediaController";
import appointmentService from "./appointmentService";
import assignmentController from "./assignmentController";
import assignmentService from "./assignmentService";
import { calendarConstants } from "./calendarConstants";
import calendarService from "./calendarService";
import createAppointmentController from "./createAppointmentController";
import createAssignmentController from "./createAssignmentController";
import editAppointmentController from "./editAppointmentController";
import editAssignmentController from "./editAssignmentController";
import feedService from "./feedService";
import { lessonConstants } from "./lessonConstants";
import lessonElectronicMediaController from "./lessonElectronicMediaController";
import lessonService from "./lessonService";
import rescheduleAssignmentController from "./rescheduleAssignmentController";
import viewAppointmentController from "./viewAppointmentController";

import lessonAssignController from "../lesson/lessonAssignController";
import lessonListAssignController from "../lesson/lessonListAssignController";
import lessonAssignModalController from "./lessonAssignModalController";
import lessonPreviewController from "./lessonPreviewController";
export const COMBINE_MODULE = angular.module("combine", ["ui.calendar"]);

// COMBINE_MODULE.service("webSocketService", websocketService);
COMBINE_MODULE.constant("calendarConstants", calendarConstants);
COMBINE_MODULE.service("appointmentService", appointmentService);
COMBINE_MODULE.service("calendarService", calendarService);

COMBINE_MODULE.service("lessonService", lessonService);
COMBINE_MODULE.service("assignmentService", assignmentService);
COMBINE_MODULE.constant("lessonConstants", lessonConstants);
COMBINE_MODULE.controller("assignmentController", assignmentController);
COMBINE_MODULE.controller("lessonPreviewController", lessonPreviewController);
COMBINE_MODULE.controller(
  "createAssignmentController",
  createAssignmentController
);
COMBINE_MODULE.controller(
  "lessonElectronicMediaController",
  lessonElectronicMediaController
);
COMBINE_MODULE.controller(
  "rescheduleAssignmentController",
  rescheduleAssignmentController
);
COMBINE_MODULE.controller("editAssignmentController", editAssignmentController);
COMBINE_MODULE.controller(
  "EditAssignmentConfirmationController",
  EditAssignmentConfirmationController
);
COMBINE_MODULE.controller(
  "EditLessonConfirmationController",
  EditLessonConfirmationController
);
COMBINE_MODULE.controller(
  "lessonBookAndElectronicMediaController",
  addBookAndElectronicMediaController
);
COMBINE_MODULE.service("feedService", feedService);
COMBINE_MODULE.controller(
  "lessonListAssignController",
  lessonListAssignController
);

COMBINE_MODULE.controller("lessonAssignController", lessonAssignController);
COMBINE_MODULE.controller(
  "createAppointmentController",
  createAppointmentController
);
COMBINE_MODULE.controller(
  "viewAppointmentController",
  viewAppointmentController
);
COMBINE_MODULE.controller(
  "editAppointmentController",
  editAppointmentController
);

COMBINE_MODULE.controller(
  "lessonAssignModalController",
  lessonAssignModalController
);
