// ((angular) => {
//     'use strict';

//     angular
//         .module('common')
//         .directive('hspMultipleFiles', hspMultipleFiles)
//         .controller('HspMultipleFilesController', HspMultipleFilesController);

/**
 * @param {[1-9]+} instanceId
 * @param {['assignment', 'appointment']} modelName
 * @param {filesToUpload:[type:files], filesToDelete:[type:ids], medias:[type:media]}  modelName
 */

export function hspMultipleFiles($compile,$timeout) {
  let directive = {
    link: link,
    restrict: "E",
    template: require("../../../pug/components/hsp-multiple-files.pug").default,
    scope: {
      instanceId: "@",
      modelName: "@",
      files: "=",
      updateCallback: "&",
      state: "@", //update or create or edit or view
      uploadToInclude: "@", // 'true' or 'false'
      uploadPosition: "@", //'start' 'end'
      uploadAlignment: "@", //'attach' 'alone',
      assignmentIds: "<",
      isGroup: "<",
    },
    controller: "HspMultipleFilesController",
    controllerAs: "hspMultipleFilesCtrl",
    transclude: true,
  };

  return directive;

  function link(scope, element, attrs, controllers, transclude) {
    if (scope.uploadToInclude) {
      let uploadDirectiveTemplate =
        '<hsp-add-multiple-files files="hspMultipleFilesCtrl.toUploadFiles" allow-multiple="true" ng-if="hspMultipleFilesCtrl.uploadLimit" limit="hspMultipleFilesCtrl.uploadLimit" add-files-callback="hspMultipleFilesCtrl.filesAdded(files)">';

      let transcludingTemplate = transclude().parent().html();
      uploadDirectiveTemplate += transcludingTemplate;
      uploadDirectiveTemplate += "</hsp-add-multiple-files>";

      let compiledElement = $compile(uploadDirectiveTemplate)(scope);
      if (scope.uploadAlignment == "attach") {
        if (scope.uploadPosition == "start") {
          element.children().prepend(compiledElement);
          if(scope.modelName==="feed") {
            $timeout(() => {
              $('.feed-images').detach().prependTo('.feed-footer');
            }, 500)
          }

        } else if (scope.uploadPosition == "parent") {
          $timeout(()=>{
            element.children().prepend(compiledElement);
            // element.children().prepend(compiledElement);
            // $(element).children().append(compiledElement);
            // element
            //     .parent()
            //     .parent()
            //     .children(".feed-footer")
            //     .prepend(compiledElement);

          },2000);

        } else {
          element.children().append(compiledElement);
        }
      } else {
        if (scope.uploadPosition == "start") {
          element.prepend(compiledElement);
        } else {
          element.append(compiledElement);
        }
      }
    }
  }
}
hspMultipleFiles.$inject = ['$compile',"$timeout"];

export function HspMultipleFilesController(
  $scope,
  _,
  fileService,
  $rootScope,
  $timeout,
  $q,
  appConstants,

) {
  let vm = this;
  let totalLimit = appConstants.maxMultipleFilesAllowed;
  vm.limit = totalLimit;
  vm.removeIndex = -1;
  vm.state = $scope.state;

  $scope.$watch("files", function () {
    if (angular.isDefined($scope.files)) {
      vm.totalFiles = _.map($scope.files.uploaded, function (data) {
        return {
          state: "view",
          data: data,
          type: "uploaded",
          disableDelete: $scope.state == "view",
        };
      });

      setLimit();
    }
  });

  if ($scope.state != "update") {
    initializeUpdateVariables();
  }

  angular.extend(vm, {
    filesAdded: filesAdded,
    removeFile: removeFile,
    fileUploaded: fileUploaded,
  });

  $rootScope.$on("$hsp-update-files", updateFiles);
  $rootScope.appt_image_ids = [];

  function initializeUpdateVariables() {
    vm.updateChecks = {
      upload: 0,
      remove: 0,
    };

    vm.filesCount = {
      upload: 0,
      remove: 0,
    };
  }

  function updateFiles(event, instanceId, modelName) {
    $scope.instanceId = instanceId;
    $scope.modelName = modelName;

    vm.filesCount = {
      upload: $scope.files.toUpload ? $scope.files.toUpload.length : 0,
      remove: $scope.files.toDelete ? $scope.files.toDelete.length : 0,
    };

    if (!_.isEmpty($scope.files.toUpload)) {
      $scope.$broadcast("upload-multiple-files", instanceId, modelName);
    }

    if (!_.isEmpty($scope.files.toDelete)) {
      removeAllFiles();
    }

    if (
      _.isEmpty($scope.files.toDelete) &&
      _.isEmpty($scope.files.toUpload && _.isEmpty($scope.files.uploaded))
    ) {
      updateCallbackCheck(null);
    }
  }

  function updateCallbackCheck(type) {
    if (type !== null && angular.isDefined(vm.updateChecks[type])) {
      vm.updateChecks[type] += 1;
    }

    if (
      vm.updateChecks !== undefined &&
      vm.filesCount.upload == vm.updateChecks.upload &&
      vm.filesCount.remove == vm.updateChecks.remove
    ) {
      $scope.updateCallback({ id: $scope.instanceId });
      initializeUpdateVariables();
    }
  }

  function filesAdded(files) {
    $rootScope.appt_image_ids = [];
    if ($scope.modelName === "occurrence") {
      for (let i = 0; i < $scope.files.uploaded.length; i++) {
        $scope.files.toUpload.push($scope.files.uploaded[i]);
      }
      $scope.files.uploaded = [];
    }

    _.forEach(files, function (data) {
      vm.totalFiles.unshift({
        state: "temp",
        data: data,
        type: "toUpload",
      });

      // if($scope.modelName === "appointment"){
      //     let uploadInstance = fileService.uploadApptFile(data, "appointment");
      // }
      if ($scope.modelName === "editAppointment") {
        // let editInstance = fileService.uploadFile(data, $scope.instanceId, "appointment")
      }
      $scope.files.toUpload.push(data);
    });
    // if($scope.modelName === "appointment"){
    //     console.log("appointme")
    //     uploadInstance = fileService.uploadApptFile(vm.file, "appointment");
    //     uploadInstance
    //     	.then(uploadSuccess, uploadFailure, uploadInProgress);
    // }

    if ($scope.modelName === "occurrence") {
      for (let i = 0; i < vm.totalFiles.length; i++) {
        fileUploaded("upload", i, vm.totalFiles[i]);
      }
    }

    if (
      $scope.state == "update" &&
      $scope.instanceId &&
      $scope.modelName !== "occurrence"
    ) {
      $timeout(function (params) {
        $scope.$broadcast(
          "upload-multiple-files",
          $scope.instanceId,
          $scope.modelName
        );
      }, 100);
    } else if (
      $scope.state == "edit" &&
      $scope.instanceId &&
      $scope.modelName == "studentLesson"
    ) {
      // $scope.modelName = 'lesson';
      if (!_.isEmpty($scope.files.toUpload)) {
        $timeout(function (params) {
          $scope.$broadcast(
            "upload-multiple-files",
            parseInt($scope.instanceId),
            $scope.modelName
          );
        }, 100);
      }

      if (!_.isEmpty($scope.files.toDelete)) {
        removeAllFiles();
      }

      if (
        _.isEmpty($scope.files.toDelete) &&
        _.isEmpty($scope.files.toUpload && _.isEmpty($scope.files.uploaded))
      ) {
        updateCallbackCheck(null);
      }
    }
    setLimit();
  }

  function removeFile(fileState, index) {
    vm.removeIndex = index;
    if (fileState == "temp" || fileState == "upload") {
      removeFromArray(index);
    }

    if (fileState == "view" && $scope.state == "edit") {
      if (
        vm.totalFiles[index].data.ids &&
        vm.totalFiles[index].data.ids.length > 0
      ) {
        fileService
          .removeFileFromSearchLesson(vm.totalFiles[index].data.ids)
          .then((response) => {
            // console.log("FILE DELETE", response);
          })
          .catch((er) => {
            // console.log("error file delete", er);
          });
      }
      $scope.files.toDelete.push(vm.totalFiles[index].data);
      removeFromArray(index);
    }

    if (fileState == "view" && $scope.state == "update") {
      if ($scope.modelName && $scope.modelName === "studentassignment") {
        let data = {
          entityId: vm.totalFiles[index].data.entityId,
          hash: vm.totalFiles[index].data.hash,
          model: vm.totalFiles[index].data.model,
          isGroup: $scope.isGroup,
          assignmentIds: $scope.assignmentIds,
        };
        fileService
          .removeAssignmentMedia(data)
          .then(deleteFileSuccess)
          .catch(deleteFileError);
      } else {
        fileService
          .removeFile(vm.totalFiles[index].data.hash)
          .then(deleteFileSuccess)
          .catch(deleteFileError);
      }
    }
  }

  function removeAllFiles(event) {
    let removePromises = $q.all(
      _.map($scope.files.toDelete, function (file) {
        return fileService
          .removeFile(file.hash)
          .then(deleteFileSuccess)
          .catch(deleteFileError);
      })
    );
  }

  function fileUploaded(fileState, index, file) {
    if (vm.totalFiles && vm.totalFiles.length > 0) {
      let toRemoveIndex = _.findIndex(
        $scope.files.toUpload,
        vm.totalFiles[index].data
      );

      if (toRemoveIndex >= 0) {
        $scope.files.toUpload.splice(toRemoveIndex, 1);
      }

      if ($scope.modelName != "feed") {
        vm.totalFiles[index].data = file;
        vm.totalFiles[index].type = "uploaded";
        vm.totalFiles[index].state = "view";
      }
    }
    $scope.files.uploaded.unshift(file);

    /**
     * Remove file from the list
     */
    if ($scope.modelName == "feed") {
      vm.totalFiles = [];
    }

    if ($scope.state != "update") {
      updateCallbackCheck("upload");
    }
  }

  function deleteFileSuccess(response) {
    if (vm.removeIndex >= 0 && $scope.state == "update") {
      removeFromArray(vm.removeIndex);
    }

    if ($scope.state == "edit") {
      removeFromArray(-1, "toDelete");
    }

    updateCallbackCheck("remove");
  }

  function deleteFileError(error) {
    //Show error
  }

  function removeFromArray(index, type) {
    let toRemoveFile = null;
    if (angular.isUndefined(type) && angular.isDefined(vm.totalFiles[index])) {
      type = vm.totalFiles[index].type;
      toRemoveFile = vm.totalFiles[index].data;
      vm.totalFiles.splice(index, 1);
      setLimit();
    }

    if (angular.isDefined(type)) {
      let toRemoveIndex = _.findIndex($scope.files[type], toRemoveFile);
      if (toRemoveIndex >= 0) {
        $scope.files[type].splice(toRemoveIndex, 1);
      }
    }
  }

  function setLimit() {
    vm.uploadLimit = totalLimit - vm.totalFiles.length;
    // let increaseLimit = false;
    // _.forEach(vm.totalFiles, (file, n) => {
    // 	if(file.data.model == "studentassignment") {
    // 		increaseLimit = true;
    // 	}
    // })
    // if(increaseLimit) {
    // 	vm.uploadLimit = 10 - vm.totalFiles.length;
    // } else {
    // 	vm.uploadLimit = totalLimit - vm.totalFiles.length;
    // }
  }
}
HspMultipleFilesController.$inject = [
  "$scope",
  "_",
  "fileService",
  "$rootScope",
  "$timeout",
  "$q",
  "appConstants",
];
