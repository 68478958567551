import {userConstants} from "./userConstants";

let angular = window.angular;
import userController from "./userController";
import userService from "./userService";
import agreeTermsController from "./agreeTermsController";
import approvedAffiliatesController from "./approvedAffiliatesController";
import changePasswordController from "./changePasswordController";
import confirmedCancellationsController from "./confirmedCancellationsController";
import freeMonthRequestsController from "./freeMonthRequestsController";
import inviteReferralsController from "./inviteReferralsController";
import lessonViewModalController from "./lessonViewModalController";
import passwordVerifyController from "./passwordVerifyController";
import pendingAffiliatesController from "./pendingAffiliatesController";
import pendingCancellationsController from "./pendingCancellationsController";
import reportedUserController from "./reportedUserController";
import userAdminController from "./userAdminController";
import userAffiliateController from "./userAffiliateController";
import userApprovalController from "./userApprovalController";
import userDisapprovalController from "./userDisapprovalController";
import userEditController from "./userEditController";
import userPaymentHistoryController from "./userPaymentHistoryController";
import userProfileInfoController from "./userProfileInfoController";
import userReferralController from "./userReferralController";
import userReferralListController from "./userReferralListController";
import userReportPostsController from "./userReportPostsController";
import userSubscriptionCancelController from "./userSubscriptionCancelController";
import userSubscriptionCancelReasonController from "./userSubscriptionCancelReasonController";

export const USER_MODULE = angular.module('user', []);

USER_MODULE.controller("userController", userController);
USER_MODULE.service("userService", userService);
USER_MODULE.constant("userConstants", userConstants);
USER_MODULE.controller("agreeTermsController", agreeTermsController);
USER_MODULE.controller("approvedAffiliatesController", approvedAffiliatesController);
USER_MODULE.controller("changePasswordController", changePasswordController);
USER_MODULE.controller("confirmedCancellationsController", confirmedCancellationsController);
USER_MODULE.controller("freeMonthRequestsController", freeMonthRequestsController);
USER_MODULE.controller("inviteReferralsController", inviteReferralsController);
USER_MODULE.controller("lessonViewModalController", lessonViewModalController);
USER_MODULE.controller("passwordVerifyController", passwordVerifyController);
USER_MODULE.controller("pendingAffiliatesController", pendingAffiliatesController);
USER_MODULE.controller("pendingCancellationsController", pendingCancellationsController);
USER_MODULE.controller("reportedUserController", reportedUserController);
USER_MODULE.controller("userAdminController", userAdminController);
USER_MODULE.controller("userAffiliateController", userAffiliateController);
USER_MODULE.controller("userApprovalController", userApprovalController);
USER_MODULE.controller("userDisapprovalController", userDisapprovalController);
USER_MODULE.controller("userEditController", userEditController);
USER_MODULE.controller("userPaymentHistoryController", userPaymentHistoryController);
USER_MODULE.controller("userProfileInfoController", userProfileInfoController);
USER_MODULE.controller("userReferralController", userReferralController);
USER_MODULE.controller("userReferralListController", userReferralListController);
USER_MODULE.controller("userReportPostsController", userReportPostsController);
USER_MODULE.controller("userSubscriptionCancelController", userSubscriptionCancelController);
USER_MODULE.controller("userSubscriptionCancelReasonController", userSubscriptionCancelReasonController);
