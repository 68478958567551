export const studentConstants = {
    messages: {
        academicYearRequire: 'Atleast one academic required.',
        leavePageConfirmation: 'The changes you made will be lost if you leave this page.\nContinue with unsaved changes?',
        academicYearOrderError: 'Please enter End Date that is after the Start Date',
        selectStudentToAssign: 'Please select a lesson to assign to the student',
        selectStartDateLesson: 'Please select a start date for lesson',
        selectStartTimeLesson: 'Please select starting time for lesson',
        academicYearIntersection: 'There is another Academic Year in that duration.',
        multiGradeError: 'Multiple Grades are not allowed.',
        deleteAcademicDetailsWarning: 'Are you sure you want to delete this academic details.',
        dobWarning: 'Student Date of Birth should be before the Academic Year starts.'
    },
    studentModel: "student"
}