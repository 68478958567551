
export  const bookConstants ={
    messages: {
        bookAdded: 'Book list successfully updated',
            bookAlready: 'Book Already Exists',
            deleteBookConfirmation: 'Are you sure you want to delete this book?',
            bookDeleted: 'Book Successfully Deleted',
            reviewDeleted: 'Review deleted successfully',
            bookUpdated: 'Bokk Updated Successfully',
            deleteReviewConfirmation: 'Are you sure you want to remove this review?',
            unassignBookConfirmation: 'Do you want to unassign an assignment too?',
            bookUnassigned: 'Book unassigned successfully',
            checkSearchWithCategory: 'Please check atleast one \'Search with\' category',
            noBookFound: 'There are no books under this criteria',
            minCharacterKeywordError: 'Please enter minimum two characters to find books',
            enterKeywordForSearchWith: 'Please enter what do you want to search with',
            cantUncheckSearchWithCategory: 'You cannot uncheck all three \'Search with\' categories',
            maxCharacterKeywordError: 'You cannot enter more than 100 characters',
            enterStartEndDate: 'Please select \'from\' and \'to\' date both in date range'
    },
    studentBookNotStartedStatus:"NOTSTARTED",
        studentBookInProgressStatus:"INPROGRESS",
        studentBookOnHoldStatus:"ONHOLD",
        studentBookCompletdStatus:"COMPLETED",
        bookSearchOptions: [{
        title: 'Title',
        value: 'false'
    }, {
        title: 'Author',
        value: 'false'
    }, {
        title: 'ISBN',
        value: 'false'
    }]
};
