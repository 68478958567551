let angular = window.angular;
	userReferralController.$inject = ['userService', 'toastService','Socialshare','appConstants','$uibModal','$rootScope','userConstants'];

	function userReferralController(userService, toastService, Socialshare, appConstants,$uibModal,$rootScope,userConstants) {
		let vm = this;

		vm.referralData={
			invitations:[],
			creditUsers:[],
			invitationCount:0,
			creditEarned:0,
			creditRadeem:0,
			availableCredits:0,
			creditRadeemList:[],
		};

		vm.user = {};
		let copyLinkAddress = "https://app.homeschoolpanda.com/referral/5300?isType=false&link=";
		vm.copyLink='';
		vm.referralMessage = '';

		angular.extend(vm, {
		copyReferralLink,
		shareOnFb,
		shareOnTwitter,
		openInviteReferralsModal,
		getUserInfo,
		openUserReferralListModal,
		});

		function verifyReferrals() {

			getReferralData();

			getUserInfo();
			let search_param = window.location.search;


			if (!search_param) {
				return false;
			}

			search_param = search_param.slice(1).split('&');

			var search_query = {};
			search_param.forEach(function(pair) {
				pair = pair.split('=');
				search_query[pair[0]] = decodeURIComponent(pair[1] || '');
			});

			if (!search_param) {
				return false;
			}


			userService.addReferrals(search_query)
				.then(response => {

					window.history.replaceState({}, "", "/" + "referrals");
					toastService.toast({
                        message: "Successfully added as referral",
                        type: 'success',
                        delay: 3000
                    });
				})
				.catch((error) => {
					window.history.replaceState({}, "", "/" + "referrals");
					toastService.toast({
                        message: error.data.message,
                        type: 'error',
                        delay: 3000
                    });
                })


		}
		function copyReferralLink(){
			/* Get the text field */
			var copyText = document.getElementById("copyLink")

			/* Select the text field */
			copyText.select();
			copyText.setSelectionRange(0, 99999);

			 /*For mobile devices*/
			/* Copy the text inside the text field */
			document.execCommand("copy");
		}

		function shareOnFb() {
			Socialshare.share({
				'provider': 'facebook',
				'attrs': {
					'socialshareUrl': appConstants.shareUrl,
					'socialshareVia': appConstants.fbAppId,
					'socialshareType': 'feed',
					'socialshareQuote': vm.referralMessage + '\xa0' + '#' + appConstants.socialSharingHashTag,
					'socialshareDisplay': 'popup'
				}
			});
			userService.shareHSP('facebook');
		}

		function shareOnTwitter() {
			Socialshare.share({
				'provider': 'twitter',
				'attrs': {
					'socialshareText': vm.referralMessage
				}
			});
			userService.shareHSP('twitter');
		}


		function getReferralData(){
			userService.getReferrals().then(res=>{
				let data=res.data;
				if(data.invitations.length){
					vm.referralData.invitations=data.invitations;
				}
				if(data.creditUsers.length){
					vm.referralData.creditUsers=data.creditUsers;
				}
				if(data.invitationCount) vm.referralData.invitationCount=data.invitationCount;
				if(data.creditEarned) vm.referralData.creditEarned=data.creditEarned;
				if(data.creditRadeem) vm.referralData.creditRadeem=data.creditRadeem;
				if(data.availableCredits) vm.referralData.availableCredits=data.availableCredits;
				if(data.creditRadeemList) vm.referralData.creditRadeemList=data.creditRadeemList;
			});
		}


		function openInviteReferralsModal(referralLink) {
            let modelInstance = $uibModal.open({
                animation: true,
                size: 'lg',
                controller: 'inviteReferralsController',
                controllerAs: 'inviteFriendsCtrl',
				template : require('../../pug/layout/invite-referrals.pug').default,
				resolve : {
					shareData: {
						link: referralLink
					}
				}
            });

            modelInstance.result.then((value)=>{
				if(value){
					vm.referralData.invitationCount=vm.referralData.invitationCount+1;
				}
			},function () {
			}).catch(function() {
                modelInstance.close();
            });
		}

		function getUserInfo() {


			// In-case is current user id is provided or no id is provided, then return the current users profile
				vm.user = $rootScope.user;
				copyLinkAddress=userConstants.referralUrl+vm.user.id+"?isType="+$rootScope.user.isAffiliate+"&link=";
				vm.userPlan = vm.user.payment;
				vm.copyLink = copyLinkAddress + vm.user.profileLink;
				vm.referralMessage = appConstants.messages.referralMessage + vm.copyLink;
		}

		function openUserReferralListModal(activeTab){

			let modelInstance = $uibModal.open({
                animation: true,
                size: 'lg',
                controller: 'userReferralListController',
                controllerAs: 'userRefListCtrl',
				template : require('../../pug/user/user-referral-list.pug').default,
				resolve : {
					shareData: {
						referralData: vm.referralData,
						activeTab:activeTab
					}
				}
            });

            modelInstance.result.catch(function() {
                modelInstance.close();
            });
		}

		this.$onInit = verifyReferrals;
	}
export default userReferralController;
