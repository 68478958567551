import { func } from "prop-types";

let angular = window.angular;

appController.$inject = [
  "$rootScope",
  "studentService",
  "$state",
  "$scope",
  "collaborateService",
  "friendService",
  "userService",
  "trackEvents",
  "webSocketService",
  "Store",
  "$uibModal",
  "commonService",
  "$window",
  "notificationService",
  "appConstants",
  "$stateParams",
  "$timeout",
  "toastService",
  "$cookies",
  "$location",
];

function appController(
  $rootScope,
  studentService,
  $state,
  $scope,
  collaborateService,
  friendService,
  userService,
  trackEvents,
  webSocketService,
  Store,
  $uibModal,
  commonService,
  $window,
  notificationService,
  appConstants,
  $stateParams,
  $timeout,
  toastService,
  $cookies,
  $location
) {
  let vm = this;
  vm.userRole = $rootScope.user.userRole;
  // Sidebar status variable
  if (Store.get("isSidebarOpen") !== null) {
    vm.isSidebarOpen = Store.get("isSidebarOpen") === "true" ? true : false;
  } else {
    vm.isSidebarOpen = appConstants.sidebarOpenByDefault;
  }

  // Students associated with logged in user
  //
  vm.students = [];
  vm.firstStudentId = 0;
  vm.activeUserTab = "dashboard";
  // vm.remainintTrialDays = 0;

  // Friends associated with the user
  vm.friends = [];

  // Notifications for appointments
  vm.notificationSelectedTab = appConstants.defaultNotificationTab;
  vm.notifications = [];

  // Email matching pattern
  vm.emailPattern = appConstants.emailPattern;
  vm.isNotificationOpen = false;
  // Get unread chats count
  vm.getUnreadChatsCount = collaborateService.getUnreadChatsCount;

  vm.currentDate = moment(new Date()).format("ddd, MMM DD, YYYY");
  vm.weather = "";
  vm.isShowSidebar = false;
  vm.trailModal = Store.get("trailModal") === "true" ? true : false;

  vm.widgetList = appConstants.widgets;

  angular.extend(this, {
    toggleSidebar,
    goToCollaborateState,
    isStateActive,
    isStateActiveInner,
    updateStatus,
    updateFriendStatus,
    getFriendRequestCount,
    getNotificationCount,
    openShareHspModal,
    openInviteFriendsModal,
    changeNotificationTab,
    readNotification,
    getNotifications,
    removeNotification,
    fireIntercomEvent,
    clearAllNotifications,
    mouseLeaveEvent,
    mouseEnterEvent,
    openInfoModal,
    openTrialModal,
    openInviteReferralsModal,
    toggleTab,
    userProfile,
    userTab,
    createAssignment,
    createAppointment,
    createVacation,
    addAcademicYear,
    openShortcutLink,
    getDragOrder,
  });

  $rootScope.checkFromReact = function (params) {};

  $rootScope.ungradedAssignmentsCount = function () {
    ungradedAssignmentsCount();
  };

  function ungradedAssignmentsCount() {
    commonService
      .getUngradedAssignmentsCount()
      .then((res) => {
        if (
          (res &&
            res.data &&
            res.data.data &&
            res.data.data.getUngradedAssignmentCount) ||
          (res &&
            res.data &&
            res.data.data &&
            res.data.data.getUngradedAssignmentCount == 0)
        ) {
          vm.ungradedAssignments = res.data.data.getUngradedAssignmentCount;
        }
      })
      .catch((error) => console.error(error));
  }

  function createAssignment() {
    let param = {
      model: "studentAssignment",
      status: "create",
    };
    openCalendar(param);
  }

  function createVacation() {
    let param = {
      model: "vacation",
      status: "create",
    };
    openCalendar(param);
  }

  function createAppointment() {
    let param = {
      model: "appointment",
      status: "create",
    };
    openCalendar(param);
  }

  function openShortcutLink(url) {
    if ($rootScope.checkForBasicPlan()) {
      $state.go(url);
    }
  }

  function openCalendar(param) {
    if ($rootScope.checkForBasicPlan()) {
      if ($state.current.name == "calendar") {
        $scope.$broadcast("createNewAssignmentOrAppointment", param);
      } else {
        $state.go("calendar", param);
      }
    }
  }

  function checkSidebarPermission() {
    let locationPath = $location.$$path;
    vm.isShowSidebar =
      locationPath.includes("dashboard") || locationPath.includes("chores");
  }

  $scope.$on("$locationChangeStart", function (event, next, current) {
    checkHelperPermission();
    checkSidebarPermission();
    checkPlanPermission();
  });

  function checkHelperPermission() {
    let userRole = $rootScope.user.userRole;
    let userRelation = $rootScope?.user?.relation;

    let locationPath = $location.$$path;
    if (userRole === "HELPER") {
      let rolePermissionHelper = $rootScope.user.rolePermission;
      //If these urls are hit ignore permissions
      if (
        locationPath.includes("user") ||
        locationPath.includes("404") ||
        locationPath.includes("payment") ||
        locationPath.includes("profile") ||
        (locationPath.includes("chores"))
      ) {
        return;
      }

      let location = locationPath.split("/");
      let permissionArray = [];
      for (let permission in rolePermissionHelper) {
        permissionArray.push(permission);
      }
      let result = permissionArray.find((element) => element === location[1]);
      if (!result) {
        toastService.toast({
          message: "Access Denied",
          type: "error",
          delay: 2000,
        });
      }

      /*  ||
                locationPath.includes("pandapost") ||
                locationPath.includes("collaborate")*/

      let rolePermission = $rootScope.user.rolePermission;
      if (rolePermission) {
        const roles = Object.keys(rolePermission);
        if (roles.length) {
          let path = locationPath.split("/");
          if (path[1]) {
            path = path[1];
            let checkRole = roles.find((obj) => obj === path);
            if (!checkRole) {
              if (path === "student" || path === "user") {
                checkRole = roles.find((obj) => obj === "studentInfo");
                if (!checkRole) {
                  goToHelperRoutes(roles);
                }
              } else {
                goToHelperRoutes(roles);
              }
            }
          } else {
            goToHelperRoutes(roles);
          }
        } else {
          $state.go("permissionDenied");
        }
      }
    } else if (userRole === "STUDENT") {
      if (
        locationPath.includes("dashboard") ||
        locationPath.includes("calendar") ||
        locationPath.includes("book") ||
        locationPath.includes("user/profile") ||
        locationPath.includes("chores") ||
        locationPath.includes("collaborate")
      ) {
        return;
      } else {
        $state.go("dashboard");
        return;
      }
    }
    // if(userRole==="STUDENT"){
    // 	if (
    // 		locationPath.includes("lesson") ||
    // 		locationPath.includes("report") ||
    // 		locationPath.includes("budget") ||
    // 		locationPath.includes("pandapost")
    // 	) {
    // 		$state.go("dashboard");
    // 	}
    // 		return;
    // }
  }

  function goToHelperRoutes(roles) {
    let checkDashboard = roles.find((obj) => obj === "dashboard");
    if (checkDashboard) {
      $state.go("dashboard");
      return;
    }
    let checkCalendar = roles.find((obj) => obj === "calendar");
    if (checkCalendar) {
      $state.go("calendar");
      return;
    }
    let checkLesson = roles.find((obj) => obj === "lesson");
    if (checkLesson) {
      $state.go("lessonList");
      return;
    }
    let checkStudentInfo = roles.find((obj) => obj === "studentInfo");
    if (checkStudentInfo) {
      $state.go("studentAdd");
      return;
    }
    let checkBudget = roles.find((obj) => obj === "budget");
    if (checkBudget) {
      $state.go("overAllBudgetDetails");
      return;
    }
    let checkReport = roles.find((obj) => obj === "report");
    if (checkReport) {
      $state.go("gradeReport");
      return;
    }
    let checkPandaPost = roles.find((obj) => obj === "pandapost");
    if (checkPandaPost) {
      $state.go("feedList");
      return;
    }
    let checkCollaborate = roles.find((obj) => obj === "collaborate");
    if (checkCollaborate) {
      // $state.go("collaborate");
      goToCollaborateState();
      return;
    }
  }

  function checkPlanPermission() {
    if (
      $rootScope.user.userRole === "PARENT" &&
      $rootScope.user.payment &&
      $rootScope.user.payment.planId.detail == "basic"
    ) {
      let locationPath = $location.$$path;
      if (
        locationPath.includes("lesson") ||
        locationPath.includes("calendar") ||
        locationPath.includes("report") ||
        locationPath.includes("budget") ||
        locationPath.includes("book") ||
        locationPath.includes("student")
      ) {
        $state.go("dashboard");
      }
    }
  }

  function openInviteReferralsModal() {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "inviteReferralsController",
      controllerAs: "inviteFriendsCtrl",
      template: require("../../pug/layout/invite-referrals.pug").default,
      resolve: {
        shareData: {
          link: $rootScope.user.profileLink,
        },
      },
    });

    modelInstance.result
      .then(
        (value) => {},
        function () {}
      )
      .catch(function () {
        modelInstance.close();
      });
  }

  function openInfoModal() {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "hspInfoModalController",
      controllerAs: "hspInfoModalCtrl",
      template: require("../../pug/components/hsp-info-modal.pug").default,
      resolve: {
        infoParam: {
          data: `<div class="info-modal">
								<h4>Student Information</h4>
								<ul>
									<li>The Student Information stores all details of your student (Academic Year, Days Attended School, Lesson Plan Detail, Student Portal Login and more. You can add up to 10 students per account</li>
								</ul>
								<div>
									<h4 class="ul-heading">Adding a Student</h4>
								</div>
								<ul class="ul">
									<li>Adding a student requires you to fill the biographical details of the student, upload a photo, add in the academic details and optionally select a color for the student. This color will be depicted in your calendar when you assign the specific student lessons.</li>
									<li>You can keep on adding academic details by clicking the (+) next to Academic details. At any given time, you can remove the details by clicking on the trash can icon next to any details.</li>
									<li>Clicking on the save button will redirect you back to the Student Profile screen. Over here, you’ll be able to view each student’s information.</li>
									<li>Once you’ve assigned the students lessons and books, that information will also appear under the Lesson Information and Books segmented tab on the Student Profile Screen.</li>
									<li>Books and Lessons plans can be assigned directly from here.</li>
									<li>Edit assigned lesson plan and Un-Assign to remove from calendar from here.</li>
								</ul>
								<div>
									<h4 class="ul-heading">Student Portal</h4>
								</div>
								<ul>
									<li>You can make accounts for students by filling in their data in the student portal section. Using those credentials, the students will be able to log in HSP Kids and view, edit or perform both actions on their assignments, lessons, and books.</li>
								</ul>
							  </div>`,
        },
      },
    });
  }

  function openTrialModal() {
    if (
      $location.$$path != "/payment" ||
      $location.$$path != "/payment/paypalWebhook"
    ) {
      /**
       * Prompt to trial plan expiry message
       */
      // if ($rootScope.user.trial) {
      // 	setTimeout(function () {
      //         vm.trailModal=((Store.get('trailModal') === 'true') ? true : false);
      // 		if(!vm.trailModal) {
      // 			let modelInstance=$uibModal.open({
      // 				animation: true,
      // 				size: 'md',
      // 				controller: 'hspInfoTrailModalController',
      // 				controllerAs: 'hspInfoTrailModalCtrl',
      // 				templateUrl: '/partials/components/hsp-trail-info-modal.html',
      // 				resolve: {
      // 					infoParam: {
      // 						data: $rootScope.user.remainintTrialDays
      // 					}
      // 				}
      // 			});
      // 			modelInstance.result.then(function () {
      // 				Store.set('trailModal', JSON.stringify(true));
      // 			},function () {
      // 				Store.set('trailModal', JSON.stringify(true));
      // 			}).catch(function() {
      // 				modelInstance.close();
      // 			});
      // 		}
      // 	}, 2000);
      // }
    }
  }

  if (commonService.getQueryString().notificationType === "requests") {
    vm.isNotificationOpen = true;
    changeNotificationTab("requests");
  }

  $rootScope.checkInternetConnection = function () {
    if (navigator.onLine) {
      toastService.hideNoInternetToast();
    } else {
      toastService.showNoInternetToast();
    }
  };
  $rootScope.checkForBasicPlan = function () {
    if (
      $rootScope.user.payment &&
      $rootScope.user.payment.planId.detail != "basic"
    ) {
      return true;
    }
    toastService.toast({
      message: appConstants.messages.planUpgrade,
      type: "error",
      delay: 4000,
    });
    return false;
  };

  $rootScope.checkEventSameDate = function (startDate, endDate) {
    startDate = moment(startDate);
    endDate = moment(endDate);
    if (
      moment(startDate.format("MM-DD-YYYY")).isSame(
        endDate.format("MM-DD-YYYY")
      )
    ) {
      return true;
    }
    return false;
  };

  $rootScope.checkInternetConnection();
  $rootScope.goToLesson = function () {
    $state.go("lessonAdd");
  };

  $window.addEventListener("online", function () {
    $timeout(function () {
      toastService.hideNoInternetToast();
    });
  });

  $window.addEventListener("offline", function () {
    $timeout(function () {
      toastService.showNoInternetToast();
    });
  });

  //Notification Functions

  function fireIntercomEvent(eventName) {
    commonService.fireIntercomEvent(eventName);
  }

  function changeNotificationTab(tabName) {
    vm.notificationSelectedTab = tabName;
  }

  $rootScope.checkRolePermission = function (type) {
    if ($rootScope.user.userRole === "PARENT") {
      return true;
    }
    if (
      !$rootScope.user.rolePermission[type] &&
      $rootScope.user.userRole === "HELPER"
    ) {
      return false;
    } else if ($rootScope.user.userRole === "HELPER") {
      let rolePermission = $rootScope.user.rolePermission[type];
      if (rolePermission === "VIEW") {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  $rootScope.checkStudentRolePermission = function (type) {
    if ($rootScope.user.userRole === "PARENT") {
      return true;
    }
    if (
      !$rootScope.user.rolePermission[type] &&
      $rootScope.user.userRole === "HELPER"
    ) {
      return false;
    } else if ($rootScope.user.userRole === "HELPER") {
      let rolePermission = $rootScope.user.rolePermission[type];
      if (rolePermission === "VIEW") {
        return false;
      } else {
        return true;
      }
    } else if ($rootScope.user.userRole === "STUDENT") {
      if (
        $rootScope.user.rolePermission[type] &&
        $rootScope.user.rolePermission[type] === "edit"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  function updateStatus(status) {
    userService.updateUserStatus(status).catch((error) => {
      console.error(error);
    });
  }

  function userTab(value) {
    vm.activeUserTab = value;
  }

  function isStateActive(stateName) {
    return (
      $state.current.name === stateName || $state.current.parent === stateName
    );
  }
  function isStateActiveInner(stateName) {
    let url = $state.current.url.split("/");
    return url.includes(stateName);
  }

  /**
   * Mouse event for the sidebar collapse
   * @param {*} eventName : name of the actionable event
   */
  function mouseLeaveEvent(eventName) {
    if (!eventName) {
      vm.reportMenuIsCollapsed = true;
      vm.helpMenuIsCollapsed = true;
      vm.collaborateMenuIsCollapsed = true;
      vm.statusMenuIsCollapsed = true;
    }

    switch (eventName) {
      case "report":
        if (isStateActive(eventName)) {
          vm.reportMenuIsCollapsed = false;
        } else {
          vm.reportMenuIsCollapsed = true;
        }
        break;

      case "collaborate":
        if (isStateActive(eventName)) {
          vm.collaborateMenuIsCollapsed = false;
        } else {
          vm.collaborateMenuIsCollapsed = true;
        }
        break;

      case "help":
        if (isStateActive(eventName)) {
          vm.helpMenuIsCollapsed = false;
        } else {
          vm.helpMenuIsCollapsed = true;
        }
        break;
      case "setStatus":
        if (isStateActive(eventName)) {
          vm.statusMenuIsCollapsed = false;
        } else {
          vm.statusMenuIsCollapsed = true;
        }
        break;

      default:
        vm.reportMenuIsCollapsed = true;
        vm.collaborateMenuIsCollapsed = true;
        vm.helpMenuIsCollapsed = true;
        vm.statusMenuIsCollapsed = true;
    }
  }

  /**
   * Mouse event for the sidebar collapse
   * @param {*} eventName
   */
  function mouseEnterEvent(eventName) {
    if (!eventName) {
      return false;
    }

    switch (eventName) {
      case "report":
        vm.reportMenuIsCollapsed = false;
        break;

      case "collaborate":
        vm.collaborateMenuIsCollapsed = false;
        break;
      case "help":
        vm.helpMenuIsCollapsed = false;
        break;

      case "setStatus":
        vm.statusMenuIsCollapsed = false;
        break;

      default:
    }
  }

  function readNotification(index, markAllRead, event) {
    let notification = vm.notifications[index],
      params;

    if (!notification.isSeen || markAllRead) {
      if (markAllRead) {
        for (let i = 0; i < vm.notifications.length; i++) {
          vm.notifications[i].isSeen = true;
        }
        $rootScope.reminderNotificationsCount = 0;
      } else {
        vm.notifications[index].isSeen = true;
        $rootScope.reminderNotificationsCount--;
      }

      notificationService
        .markRead(notification.id, markAllRead)
        .then((response) => {
          vm.notifications = notificationService.orderNotifications(
            vm.notifications
          );
        })
        .catch((error) => {
          // console.log('Mark read error', error);
        });
    }

    if (!markAllRead) {
      params = {
        entityId: notification.entityId,
        model: notification.model,
      };

      notification &&
        notification.meta &&
        notification.meta.occurrenceId &&
        (params.occurrenceId = notification.meta.occurrenceId);

      if (params.model == "bookAssignment") {
        $state.go("bookList", { reload: true });
      } else if (params.model == "feed") {
        $state.go(
          "feedViewSingle",
          { feedId: notification.entityId },
          { reload: true }
        );
      } else if (params.model == "feedUser") {
        $state.go(
          "feedUserView",
          { userId: notification.entityId },
          { reload: true }
        );
      } else if (params.model == "feedGroup") {
        $state.go(
          "feedGroupView",
          { groupId: notification.entityId },
          { reload: true }
        );
      } else if (params.model == "feedGroupAdmin") {
        $state.go(
          "feedGroupView",
          { groupId: notification.entityId, memberTab: true },
          { reload: true }
        );
      } else if (params.model == "eventInvite") {
        $state.go(
          "eventViewSingle",
          { eventId: notification.entityId },
          { reload: true }
        );
      } else if (params.model == "eventUpdate") {
        $state.go(
          "eventViewSingle",
          { eventId: notification.entityId },
          { reload: true }
        );
      } else if (params.model == "eventPost") {
        $state.go(
          "eventViewSingle",
          { eventId: notification.entityId },
          { reload: true }
        );
      } else if (params.model == "groupInvite") {
        $state.go(
          "collaborateViewChat",
          { chatId: notification.meta.id },
          { reload: true }
        );
      } else if (params.model == "eventReminder") {
        $state.go(
          "eventViewSingle",
          { eventId: notification.entityId },
          { reload: true }
        );
      } else {
        $state.go("calendar", params, { reload: true });
      }
    } else {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  function removeNotification(index, event) {
    let notification = vm.notifications[index];
    event.stopPropagation();
    event.preventDefault();

    notificationService
      .remove(notification.id)
      .then((response) => {
        if (!notification.isSeen) {
          $rootScope.reminderNotificationsCount--;
        }
        vm.notifications.splice(index, 1);
      })
      .catch((error) => {
        // console.log('Failure remove notification', error);
      });
  }

  function clearAllNotifications(event) {
    $rootScope.reminderNotificationsCount = 0;
    event.stopPropagation();
    event.preventDefault();
    vm.notifications = [];
    notificationService
      .clearAllNotifications()
      .then((response) => {
        // console.log('Success in clearing notifications');
      })
      .catch((error) => {
        // console.log('Error in clearing notifications', error);
      });
  }

  // Toggle the sidebar status
  function toggleSidebar() {
    vm.isSidebarOpen = !vm.isSidebarOpen;
    Store.set("isSidebarOpen", vm.isSidebarOpen);
  }

  // Fetch all students associated with logged in user

  function goToCollaborateState() {
    if (collaborateService.ifUserHasChat()) {
      if (collaborateService.chatsList[0].id) {
        $state.go("collaborateViewChat", {
          chatId: collaborateService.chatsList[0].id,
        });
        // commonService.fireIntercomEvent("collaborate-entered");
      } else if (collaborateService.chatsList[0].noRecord) {
        collaborateService
          .updateContactChatAgain({
            friend: collaborateService.chatsList[0].friend,
          })
          .then((res) => {
            setTimeout(() => {
              $state.go("collaborateViewChat", {
                chatId: res.id,
              });
              collaborateService.sideMenuChatId = res.id;
            }, 10);
          });
      } else {
        $state.go("collaborateCreate");
      }
    } else {
      $state.go("collaborateCreate");
    }
  }

  function getFriendRequestCount() {
    let i,
      friendRequestCount = 0;
    for (i = vm.friends.length - 1; ~i; i--) {
      if (
        vm.friends[i].acceptorId === $rootScope.user.id &&
        vm.friends[i].friendRequestStatus === "I"
      ) {
        friendRequestCount++;
      }
    }
    return friendRequestCount;
  }

  /**
   * The notification count is a total of the number of unseen reminders and friend requests
   * @returns {undefined}
   */
  function getNotificationCount() {
    return (
      getFriendRequestCount() +
      (vm.notifications.length ? $rootScope.reminderNotificationsCount : 0)
    );
  }

  function updateFriendStatus(user, status) {
    if ($rootScope.user.userRole == "PARENT" && user.requestMessage) {
      friendService.updateStatus(user.id, status, "updateStatusByParent");
    } else {
      friendService.updateStatus(user.id, status, "updateStatus");
    }
  }

  function openShareHspModal() {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "md",
      controller: "hspShareController",
      controllerAs: "hspShareCtrl",
      template: require("../../pug/layout/share-hsp.pug").default,
    });

    modelInstance.result.catch(function () {
      modelInstance.close();
    });
  }

  function openInviteFriendsModal() {
    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "inviteFriendsController",
      controllerAs: "inviteFriendsCtrl",
      template: require("../../pug/layout/invite-friends.pug").default,
      resolve: {
        shareData: {},
      },
    });

    modelInstance.result.catch(function () {
      modelInstance.close();
    });
  }

  function getWeatherDetail() {
    userService.getWeather(vm.lonlat).then((res) => {
      let weatherCity = res.name;
      let temprature = Math.round((res.main.temp - 273) * (9 / 5) + 32);
      vm.weather = weatherCity + " " + temprature + "°F";
    });
  }

  $scope.$watch(
    function () {
      return $cookies.get("paypal");
    },
    function (newValue) {
      if ($cookies.get("paypal")) {
        $rootScope.user.trial = false;
      }
    }
  );

  function toggleTab(tab) {
    if (!$rootScope.checkForBasicPlan()) {
      return;
    }
    if (vm.activeTab !== tab || vm.activeTab === "studentInfo") {
      if (tab === "user") {
        let users = studentService.students.filter(
          (user) => user.userRole === "HELPER"
        );
        if (users && users.length > 0) {
          $state.go(
            "userInfo",
            {
              studentId: users[0].id,
            },
            {
              location: "replace",
            }
          );
        } else {
          $state.go(
            "userAdd",
            {},
            {
              location: "replace",
            }
          );
        }
        vm.activeTab = tab;
      } else {
        let students = studentService.students.filter(
          (student) => student.userRole !== "HELPER"
        );
        if (students && students.length > 0) {
          $state.go(
            "studentInfo",
            {
              studentId: students[0].id,
            },
            {
              location: "replace",
            }
          );
        } else {
          $state.go(
            "studentAdd",
            {},
            {
              location: "replace",
            }
          );
        }
        vm.activeTab = "student";
      }
    } else {
      vm.activeTab = tab;
    }
  }

  function userProfile() {
    if ($rootScope.user.userRole === "HELPER" && $rootScope.user.helperId) {
      $state.go(
        "userProfile",
        { userId: $rootScope.user.helperId },
        {
          location: "replace",
        }
      );
    } else {
      $state.go(
        "userProfile",
        { userId: $rootScope.user.id },
        {
          location: "replace",
        }
      );
    }
  }

  async function getDragOrder() {
    try {
      const res = await userService.getDragOrder();
      $rootScope.user.dragList = res.data.data;
    } catch (err) {
      console.log(err);
    }

    // .then((res) => {
    //   $rootScope.user.dragList = res.data.data;
    //   console.log($rootScope.user.dragList);
    // })
    // .catch((err) => {
    //   console.log("error", err);
    // });
  }
  // Controller initialization functions

  function getNotifications(getMore) {
    return notificationService
      .getAllNotifications(getMore)
      .then((notifications) => {
        vm.notifications = notifications;
      })
      .catch((error) => {
        // console.log('notifications error', error);
      });
  }

  function getFriendList() {
    $rootScope.getFriendListPromise.then((data) => {
      vm.friends = data;
    });
  }
  // function getAllStudents() {
  //   studentService
  //     .getStudents()
  //     .then((response) => {
  //       vm.students = response;
  //     })
  //     .catch((error) => {
  //       // console.log(error);
  //     });
  // }

  async function allApiCalls() {
    const notificationApi = notificationService.getAllNotifications();

    const allStudentsApi = studentService.getStudents();
    try {
      const notificationResponse = await notificationApi;
      const studentResponse = await allStudentsApi;
      vm.notifications = notificationResponse;
      vm.students = studentResponse;
    } catch (err) {
      console.log(err);
    }
  }
  function init() {
    checkSidebarPermission();
    checkHelperPermission();
    vm.activeTab = $location.$$path.split("/")[1];
    ungradedAssignmentsCount();
    mouseLeaveEvent();
    allApiCalls();
    // getAllStudents();
    getFriendList();
    // getNotifications();
    // getDragOrder();
    // getWeatherDetail();

    vm.userProfileName = $rootScope.user.firstName;
    if ($rootScope.user && $rootScope.user.trial) {
      openTrialModal();
    }
  }

  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function addAcademicYear(date) {
    let startDate,
      options = {};

    if (!date) {
      date = moment();
    }

    if (date.isValid()) {
      startDate = date.format("Y-MM-DD");
      options.startDate = startDate;
    }

    let modelInstance = $uibModal.open({
      animation: true,
      size: "lg",
      controller: "academicYearController",
      controllerAs: "academicYearCrtl",
      template: require("../../pug/layout/academic-year.pug").default,
      resolve: { options },
    });

    modelInstance.result.then(afterCreate).catch(function () {
      modelInstance.close();
    });
  }

  function afterCreate(options) {}

  $scope.$on(
    "$stateChangeStart",
    function (evt, toState, toParams, fromState, fromParams) {
      if (toState.parent !== "report") {
        vm.reportMenuIsCollapsed = true;
      }
      if (toState.parent !== "collaborate") {
        vm.collaborateMenuIsCollapsed = true;
      }
      if (toState.parent !== "status") {
        vm.statusMenuIsCollapsed = true;
      }
    }
  );
  $scope.$on("studentDeselect", function () {
    vm.students.forEach(function (student) {
      student.selected = false;
    });
  });

  $scope.$watchCollection(
    () => this.students,
    (students) => {
      if (students.length) {
        vm.firstStudentId = vm.students[0].id;
      } else {
        vm.firstStudentId = 0;
      }
    }
  );

  this.$onDestroy = function () {
    webSocketService.socket.removeAllListeners();
  };

  this.$onInit = init;
}
export default appController;
