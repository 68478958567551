
	pendingAffiliatesController.$inject = ['userService', 'toastService','Socialshare','appConstants','$uibModal','$rootScope'];

	function pendingAffiliatesController(userService, toastService, Socialshare, appConstants,$uibModal,$rootScope) {
		let vm = this;

		vm.affiliatesList=[];

		angular.extend(vm, {
            approveUser,
            disApproveUser
		});

        function approveUser(id){
            return userService.approveAffiliate(id, 'approve')
            .then(response => {
                if (response) {
                    let updateUserIndex = _.findIndex(vm.affiliates, function (el) {

                        return el.userId.id === response.data.id;

                    });
                    if (updateUserIndex > -1) {
                        vm.affiliates[updateUserIndex].userId = response.data;
                    }
                    toastService.toast({
                        message: response.message,
                        type: 'success',
                        delay: 2000
                    });
                }
            })
            .catch(err => {
                console.log(err)
            })
        }

        function disApproveUser(id){
            return userService.approveAffiliate(id, 'disapprove')
            .then(response => {
                if (response) {
                    let updateUserIndex = _.findIndex(vm.affiliates, function (el) {

                        return el.userId.id === response.data.id;

                    });
                    if (updateUserIndex > -1) {
                        vm.affiliates[updateUserIndex].userId = response.data;
                    }
                    toastService.toast({
                        message: response.message,
                        type: 'success',
                        delay: 2000
                    });
                }
            })
            .catch(err => {
                console.log(err)
            })
        }

        function getApprovedAffiliates() {
            vm.isFetchingAffiliatesData = true;
            userService.getAllAffiliates(vm.page - 1)
                .then(response => {
                    if (response) {
                        vm.affiliates = response.affiliates.filter(function (el) {
                            return !el.userId.isAffiliate
                        });
                    }
                })
                .catch(() => {
                    toastService.toast({message: 'Affiliates Not Found', type: 'error', delay: 2000});
                })
                .finally(() => {
                    vm.isFetchingAffiliatesData = false;
                })
        }

		function init() {
			return getApprovedAffiliates();
		}

		this.$onInit = init;
	}
export default pendingAffiliatesController;
