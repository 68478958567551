let angular = window.angular;
	userReferralListController.$inject = ['userService', 'toastService','Socialshare','appConstants','$uibModal','$rootScope','shareData'];

	function userReferralListController(userService, toastService, Socialshare, appConstants,$uibModal,$rootScope, shareData) {
		let vm = this;
        vm.activeTab = 'invitation';
        vm.referralData={};
		angular.extend(vm, {
            toggleTab,
        });

        function toggleTab(tab) {
            vm.activeTab = tab;
        }

        function init() {
        	if(shareData && shareData.activeTab ){
        		vm.activeTab=shareData.activeTab;
			}
        	if(shareData && shareData.referralData ){
        		vm.referralData=shareData.referralData;
        		if(vm.referralData.invitations){
        			_.forEach(vm.referralData.invitations,(invitation)=>{
        				invitation.date=moment(invitation.createdAt).format("MM-DD-YYYY");
					});
        			_.forEach(vm.referralData.creditUsers,(creditUser)=>{
						creditUser.date=moment(creditUser.createdAt).format("MM-DD-YYYY");
					});
        			_.forEach(vm.referralData.creditRadeemList,(creditRadeem)=>{
						creditRadeem.date=moment(creditRadeem.subscribe_date).format("MM-DD-YYYY");
					});
				}
			}
		}

		this.$onInit = init;
    }

export default userReferralListController;
